import { useMount } from 'ahooks';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useMe } from '../../profile/hooks';
import store from '../../shared/Store';
import { Achievement, Category, UserAchievement } from '../../types/achievements.types';
import { fetchAchievements, fetchUserAchievements } from './achievements.actions';

type AchievementStore = {
  achievements: Achievement[];
  categories: Category[];
  userAchievements: UserAchievement[];
  userAchievementsByAchievement: Record<string, UserAchievement>;
};

type StateType = {
  achievements: AchievementStore;
};

export function useQueryAchievements(): void {
  const me = useMe();
  useMount(() => {
    store.reduxStore.dispatch(fetchAchievements());
    store.reduxStore.dispatch(fetchUserAchievements(me._id));
  });
}

export function useAchievements(): {
  achievements: Achievement[];
  categories: Category[];
} {
  return useSelector((state: StateType) => ({
    achievements: state.achievements.achievements,
    categories: state.achievements.categories,
  }));
}

export function useUserAchievements(): {
  userAchievements: UserAchievement[];
  userAchievementsByAchievementId: AchievementStore['userAchievementsByAchievement'];
} {
  return useSelector((state: StateType) => {
    const { userAchievements } = state.achievements;
    return {
      userAchievements,
      userAchievementsByAchievementId: keyBy(userAchievements, 'achievementId'),
    };
  });
}

export function useSelectedAchievements(achievementsIds: string[]): Achievement[] {
  const achievements = useSelector((state: StateType) => state.achievements.achievements);
  return useMemo(() => {
    const achievementsById = keyBy(achievements, '_id');
    return achievementsIds?.map((id) => achievementsById[id]).filter((v) => v);
  }, [achievements, achievementsIds]);
}
