import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import WorkshopsBlock from './WorkshopsBlock';

function filterList(workshops, field, value) {
  if (!workshops) return workshops;
  return workshops.filter((workshop) => {
    const workshopValue = workshop[field];
    if (workshopValue === value) return true;
    if (Array.isArray(workshopValue)) return workshopValue.includes(value);
    return false;
  });
}

const WorkshopsMatchingBlock = ({
  workshops,
  workshopsSessions,
  header,
  headerLevel,
  headerKey,
  itemProps,
  template,
}) => {
  const { mode = 'session' } = itemProps || {};

  let workshopsOrSessions = workshops;
  if (mode !== 'workshop' && workshopsSessions?.length > 0) {
    workshopsOrSessions = workshopsSessions;
  }

  if (!workshopsOrSessions || workshopsOrSessions.length === 0) return null;
  const orderedWorkshops = orderBy(workshopsOrSessions, 'startDate', 'asc');
  return (
    <WorkshopsBlock
      header={header}
      headerLevel={headerLevel}
      headerKey={headerKey}
      workshopList={orderedWorkshops}
      itemProps={itemProps}
      template={template || itemProps?.template}
    />
  );
};

WorkshopsMatchingBlock.defaultProps = {
  header: undefined,
  headerLevel: undefined,
  headerKey: undefined,
  itemProps: undefined,
  template: undefined,
};

WorkshopsMatchingBlock.propTypes = {
  workshops: PropTypes.array.isRequired,
  workshopsSessions: PropTypes.array.isRequired,
  header: PropTypes.string,
  headerLevel: PropTypes.string,
  headerKey: PropTypes.string,
  itemProps: PropTypes.object,
  template: PropTypes.string,
};

export default connect((state, { field, value }) => ({
  workshops: filterList(state.workshops.workshops, field, value),
  workshopsSessions: filterList(state.workshopSessions.workshopSessions, field, value),
}))(WorkshopsMatchingBlock);
