/* eslint-disable react/require-default-props */
import get from 'lodash/get';

import CountdownBlock from '../../home/blocks/CountdownBlock';

type ItemCountdownBlockProps = {
  item: { _id: string } & Record<string, unknown>;
  countdownOptions?: {
    autoHide?: boolean;
    background?: string;
    color?: string;
  };
  container?: {
    header: string;
  };
  field?: string;
};

const ItemCountdownBlock = (props: ItemCountdownBlockProps): JSX.Element | null => {
  const { container, item, field = 'startDate', countdownOptions = {} } = props;
  const date = get(item, field, '');

  if (!date) return null;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <CountdownBlock title={container?.header} date={date} autoHide {...countdownOptions} />;
};

export default ItemCountdownBlock;
