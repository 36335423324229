import React from 'react';
import { useSelector } from 'react-redux';
import { userAgenda } from '../store/agenda.selectors';
import DayCalendar from '../../components/DayCalendar';

function UserColoredCalendarBlock(props) {
  const events = useSelector(userAgenda);
  return <DayCalendar events={events} {...props} />;
}

UserColoredCalendarBlock.propTypes = {};

export default UserColoredCalendarBlock;
