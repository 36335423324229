import orderBy from 'lodash/orderBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

import BlockContainer from '../../components/BlockContainer';
import CdnImage from '../../components/CdnImage';
import EntityLink from '../../components/EntityLink';
import useTranslations from '../../hooks/useTranslations';
import { workshopProptypes } from '../../propTypes';

import { useSyncedCollectionWorkshops } from '../store/workshops.hooks';

function NextSessionBlock(props) {
  const { item: workshop, container, image } = props;
  const { t } = useTranslations('workshops.workshop');
  const { collection, startDate } = workshop;
  const workshops = orderBy(useSyncedCollectionWorkshops(collection), 'startDate', 'asc');
  const nextSession = workshops.filter((w) => moment(w.startDate).isAfter(startDate))?.[0];

  if (!nextSession) return null;
  const component = image ? (
    <CdnImage className="image" src={image} maxWidth={500} style={{ margin: 'auto' }} />
  ) : (
    <Header style={{ textAlign: 'center' }} as="h2" className="text">
      {t('see-next-session')}
      <Icon name="chevron right" />
    </Header>
  );

  return (
    <BlockContainer className="workshop--nextSession" {...container}>
      <EntityLink entity={nextSession}>{component}</EntityLink>
    </BlockContainer>
  );
}

NextSessionBlock.defaultProps = {
  container: {},
  image: undefined,
};

NextSessionBlock.propTypes = {
  container: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  item: PropTypes.shape(workshopProptypes).isRequired,
};

export default NextSessionBlock;
