export type MeetingRequest = {
  date: string;
  startTime: string;
  endTime: string;
  location?: string;
  message?: string;
};

export type UserAppointment = {
  userId: string;
  status: string;
  firstName?: string;
  lastName?: string;
};

export type Appointment = {
  startDate: string;
  ownerId: string;
  endDate: string;
  users: UserAppointment[];
} & Record<string, any>;

// eslint-disable-next-line no-shadow
export enum MeetingStatus {
  'CONFIRMED' = 'confirmed',
  'CANCELED' = 'canceled',
  'REFUSED' = 'refused',
}
