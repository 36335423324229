import PropTypes from 'prop-types';
import React, { FC } from 'react';

import { evalCondition } from '../../../../utils/conditionUtils';
import WorkshopRegistrationButton from '../../../../workshops/components/WorkshopRegistrationButton';
import { AddToCalendarButtons } from './AddToCalendarButtons';
import { AnimatorAccessButton } from './AnimatorAccessButton';
import { EventStatusButton } from './EventStatusButton';
import { ReplayModalButton } from './ReplayModalButton';
import { SeeMoreButton } from './SeeMoreButton';
import { SeeMoreLiveButton } from './SeeMoreLiveButton';
import { SeeParentButton } from './SeeParentButton';
import { SessionsButton } from './SessionsButton';
import { SessionsReplayButton } from './SessionsReplayButton';
import { UnregisterButton } from './UnregisterButton';
import { WorkshopLiveButton } from './WorkshopLiveButton';
import { WorkshopAction } from './action.type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionButtons: Record<string, React.FC<any>> = {
  'animator-access': AnimatorAccessButton,
  'registration': WorkshopRegistrationButton,
  'event-status': EventStatusButton,
  'see-more': SeeMoreButton,
  'see-parent': SeeParentButton,
  'replay': ReplayModalButton,
  'workshop-live': WorkshopLiveButton,
  'see-more-live': SeeMoreLiveButton,
  'sessions': SessionsButton,
  'add-to-calendar': AddToCalendarButtons,
  'replay-sessions': SessionsReplayButton,
  'unregister': UnregisterButton,
};

type WorkshopActionsProps = {
  workshop: any;
  actions?: WorkshopAction[];
  itemProps?: any;
  style?: any;
};

const WorkshopActions: FC<WorkshopActionsProps> = (props) => {
  const { workshop, actions = [], itemProps, style } = props;

  if (!actions.length) {
    return null;
  }

  return (
    <div className="actions" style={style}>
      {actions.map(({ _id, type, condition, ...rest }) => {
        const Component = actionButtons[type];
        if (!evalCondition(condition, workshop)) return null;

        if (!Component) return null;
        return <Component key={_id} workshop={workshop} itemProps={itemProps} {...rest} />;
      })}
    </div>
  );
};

WorkshopActions.defaultProps = {
  actions: [],
  itemProps: {},
  style: undefined,
};

WorkshopActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.any.isRequired),
  workshop: PropTypes.object.isRequired,
  itemProps: PropTypes.object,
  style: PropTypes.object,
};

export { WorkshopActions };
