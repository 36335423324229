/* eslint-disable @typescript-eslint/no-explicit-any */
import useUrlState from '@ahooksjs/use-url-state';
import React, { useMemo, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useLocation } from 'react-router-dom';
import { Icon, Message } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import BlockContainer from '../../BlockContainer';
import { Html } from '../HtmlBlock';
import { BlockProps, ContainerProps } from '../types';
import './FaqBlock.scss';
import FaqModal from './components/FaqModal';
import QuestionRender from './components/QuestionRender';
import { useQuestionsWithHighlightedAnswers } from './utils/hooks';
import { Question, SingleQuestionType } from './utils/types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Highlighter = require('react-highlight-words');

const translationPrefix = 'blocks.faq';
const css = bem('FaqBlock');

const SingleQuestion = ({ question, open, styles = {}, searchWords = [] }: SingleQuestionType) => {
  const [isOpen, setIsOpen] = useState(open);
  useMemo(() => setIsOpen(open), [open]);
  const { title = '', answer = '' } = question;
  const {
    bgColor,
    rowTitleColor,
    arrowColor,
    rowContentColor,
    answerBgColor,
    plusIcon = 'plus',
    minusIcon = 'minus',
    showModalOnMobile = true,
  } = styles;
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const [urlState, setUrlState] = useUrlState();

  if (mobile && showModalOnMobile) {
    const openFaqModal = () => setUrlState({ question: question._id, blockId: question.blockId });
    return (
      <>
        <QuestionRender
          title={title}
          icon="chevron right"
          styles={styles}
          onClick={openFaqModal}
          searchWords={searchWords}
        />
        {urlState.question === question._id && urlState.blockId === question.blockId && (
          <FaqModal
            question={question}
            onClose={() => setUrlState({ question: undefined })}
            searchWords={searchWords}
          />
        )}
      </>
    );
  }

  return (
    <Collapsible
      open={isOpen}
      trigger={
        <div className={css('question')} style={{ backgroundColor: bgColor }}>
          <button type="button" className="title" style={{ color: rowTitleColor }}>
            <Highlighter searchWords={searchWords} autoEscape textToHighlight={title} />
          </button>
          <Icon
            style={{ color: arrowColor || rowTitleColor }}
            name={!isOpen ? plusIcon : minusIcon}
          />
        </div>
      }
      transitionTime={200}
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
    >
      <Html
        className={css('answer')}
        style={{ color: rowContentColor, backgroundColor: answerBgColor }}
        content={answer}
      />
    </Collapsible>
  );
};

type FaqBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  collapsed: boolean;
  blocks?: Question[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles?: Record<string, any>;
} & typeof FaqBlockDefaultProps;

const FaqBlockDefaultProps = {
  container: {},
  className: '',
  blocks: [],
  styles: {},
};

const FaqBlock = ({
  _id,
  className,
  container,
  collapsed,
  blocks: questions,
  styles,
}: FaqBlockProps): JSX.Element | null => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const query = new URLSearchParams(search);
  const faqSearchText = query.get('faq') as string;

  const questionsWithHighlightedAnswers = useQuestionsWithHighlightedAnswers(
    questions,
    faqSearchText,
  );

  const matchingQuestions = useMemo(() => {
    if (faqSearchText)
      return questionsWithHighlightedAnswers.filter(
        (question) =>
          question.matched ||
          (question.title || '').toLowerCase().indexOf(faqSearchText.toLowerCase()) !== -1,
      );
    return questionsWithHighlightedAnswers;
  }, [faqSearchText, questionsWithHighlightedAnswers]);

  if (faqSearchText && !matchingQuestions.length)
    return (
      <Message icon negative className={css('noResult').toString()}>
        <Icon name="search" />
        <Message.Content>{t(`${translationPrefix}.no-results`)}</Message.Content>
      </Message>
    );

  if (questions.length === 0) return null;

  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      {matchingQuestions.map((question) => (
        <SingleQuestion
          styles={styles}
          key={question._id}
          question={{ ...question, blockId: _id }}
          open={collapsed || question.matched}
          searchWords={[faqSearchText]}
        />
      ))}
    </BlockContainer>
  );
};

FaqBlock.defaultProps = FaqBlockDefaultProps;

export default FaqBlock;
