/* eslint-disable class-methods-use-this */
import wretch from 'wretch';

import { getJson, postJson } from '../../utils/fetch';

const UserCollections = {
  PARTICIPANTS: 'participants',
};

class AuthService {
  constructor() {
    this.api = window.__DATA__.endpoints.platform;
    this.wretch = wretch(this.api);
  }

  async login(login, password, collection = UserCollections.PARTICIPANTS, options = {}) {
    const { method, includesFirebase = true, validatedLegalNotice } = options;
    return postJson(`${this.api}/login`, {
      login,
      password,
      method,
      validatedLegalNotice,
      collection,
      includesFirebase,
    });
  }

  async renewFirebaseToken(token) {
    return getJson(`${this.api}/auth/renewFirebaseToken`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  }

  async forgottenPassword(login) {
    return postJson(`${this.api}/auth/forgottenPassword`, { login });
  }

  async sendOneTimePassword(login) {
    return postJson(`${this.api}/auth/sendOneTimePassword`, { login });
  }

  async sendEmailLink(login) {
    return this.wretch
      .url('/auth/sendEmailLink')
      .post({ login })
      .error(403, (error) => {
        console.log('error', JSON.stringify(error));
        try {
          return { status: error.status, error: JSON.parse(error.text).error };
        } catch (e) {
          return { success: false };
        }
      })
      .json();
  }

  async resetPassword(
    token,
    password,
    collection = UserCollections.PARTICIPANTS,
    { autoLogin = false, includesFirebase = true } = {},
  ) {
    return postJson(`${this.api}/auth/resetPassword`, {
      token,
      password,
      collection,
      autoLogin,
      includesFirebase,
    });
  }

  async checkResetPasswordToken(token) {
    return postJson(`${this.api}/auth/checkResetPasswordToken`, {
      token,
    });
  }

  async autoLogin(
    token,
    collection = UserCollections.PARTICIPANTS,
    { includesFirebase = true } = {},
  ) {
    return postJson(`${this.api}/auth/autoLogin`, {
      token,
      collection,
      includesFirebase,
    });
  }

  async impersonate(token, options = {}) {
    const { collection = UserCollections.PARTICIPANTS } = options;
    return postJson(`${this.api}/auth/impersonate`, { token, collection });
  }

  async signInAnonymously(anonymousId) {
    return postJson(`${this.api}/auth/signInAnonymously`, { anonymousId });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async registerAccount(userData, collection) {
    return postJson(`${this.api}/auth/register/${collection || UserCollections.PARTICIPANTS}`, {
      user: userData,
    });
  }
}

export default new AuthService();
