/* eslint-disable react/require-default-props */
import React from 'react';

/** @jsxImportSource @emotion/react */
import { bem } from '../../../core/design/bem';
import { OrderBy, useOrderBy } from '../../../utils/hooks';
import { defaultActions, defaultInfos } from '../data';
import { ActionItem, ContactItem, InfoProps } from '../types';
import { ContactVariants } from '../variants';
import Contact from './Contact';

const cssBlock = bem('Contacts');

export type ContactsProps = {
  className?: string;
  variant: ContactVariants;
  items: ContactItem[];
  actions: ActionItem[];
  infos: InfoProps[];
};

const defaultOrder: OrderBy[] = [
  { field: 'order', order: 'asc' },
  { field: 'firstName', order: 'asc' },
];

const Contacts = ({
  items = [],
  emotionCss,
  className,
  variant = 'default',
  actions = defaultActions,
  infos = defaultInfos,
}: ContactsProps & { emotionCss?: any }): JSX.Element | null => {
  const orderedContact = useOrderBy(items, defaultOrder);
  if (!items?.length) return null;

  return (
    <div css={emotionCss} className={cssBlock({ variant }).mix(className)}>
      {orderedContact.map((item) => (
        <Contact key={item._id} item={item} variant={variant} actions={actions} infos={infos} />
      ))}
    </div>
  );
};

export default Contacts;
