import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import FilesBlockComponent from '../../components/cms/FilesBlock';
import { containerTypePropTypes } from '../../propTypes';

function FilesBlock({ item, name, ...rest }) {
  const files = get(item, name) || [];
  const { title, type } = rest;
  return <FilesBlockComponent files={files} containerType={type} title={title} item={item} />;
}

FilesBlock.defaultProps = {
  name: 'files',
  type: 'basic',
};

FilesBlock.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string,
  type: containerTypePropTypes,
};
export default FilesBlock;
