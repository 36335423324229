/* eslint-disable operator-linebreak */
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Icon, Message } from 'semantic-ui-react';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { InviteStatuses } from '../blocks/NetworkingParticipantList.helpers';
import { InviteModes } from '../InviteModes';
import { useInviteMode } from '../InviteModes.hooks';
import { chatHashFromId } from '../store/networking.selectors';

const ConnectButton = ({ onConnect }) => {
  const inviteMode = useInviteMode();

  return (
    <Button primary onClick={onConnect} basic className="NetworkingParticipantList--Message">
      {inviteMode === InviteModes.REQUEST
        ? getString('btn.connect')
        : getString('networking.buttons.start-conversation')}
      <Icon name="right chevron" />
    </Button>
  );
};
ConnectButton.propTypes = {
  onConnect: PropTypes.func.isRequired,
};

const AwaitingMessage = () => (
  <Message className="NetworkingParticipantList--Message">
    {getString('networking.status.invited')}
  </Message>
);
const RefusedMessage = () => (
  <Message className="NetworkingParticipantList--Message">
    {getString('networking.status.refused')}
  </Message>
);
const ConnectedMessage = ({ userId }) => {
  const inviteMode = useInviteMode();
  const hash = useSelector(chatHashFromId([userId, store.userId].sort().join('-')));
  return (
    <Button
      as={NavLink}
      to={`/networking/chats/${hash}`}
      color="green"
      basic
      className="NetworkingParticipantList--Message"
    >
      {inviteMode === InviteModes.REQUEST
        ? getString('networking.status.accepted')
        : getString('networking.status.accepted-immediate')}
      <Icon name="right chevron" />
    </Button>
  );
};

export const InviteButtonByStatus = {
  [InviteStatuses.ableToInvite]: ConnectButton,
  [InviteStatuses.awaiting]: AwaitingMessage,
  [InviteStatuses.invitedRefused]: RefusedMessage,
  [InviteStatuses.connected]: ConnectedMessage,
};

const InviteButton = ({ status, ...props }) => {
  const ContactButton = InviteButtonByStatus[status];
  return <ContactButton {...props} />;
};

export default InviteButton;
