import cx from 'classnames';
import flow from 'lodash/fp/flow';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon, Menu } from 'semantic-ui-react';

import { useTracking } from '../../../Context';
import { useConfig } from '../../../config/config.context';
import { getString } from '../../../utils';
import { eventTags } from '../../trackers/events';
import Count from './Count';

const applyOrder = (order) => (options) => {
  if (!order) return options;
  if (order === 'count') return orderBy(options, 'count', 'desc');
  return orderBy(options, 'count');
};

const applyLimit = (limit, showMore) => (options) => {
  if (!limit || showMore) return options;
  return options.slice(0, limit);
};

const SimpleFilter = ({ filter, value, onSelect, pageId }) => {
  const [showMore, setShowMore] = useState(false);
  const { screens } = useConfig();
  const { trackEvent } = useTracking();
  const {
    title,
    values,
    countForAll,
    showAll,
    hideCount,
    orderBy: orderByField,
    limit,
    showCloseIcon,
    horizontal,
    menuProps,
  } = filter;
  const screen = get(screens, pageId);
  const options = flow(applyOrder(orderByField), applyLimit(limit, showMore))(values);
  if (options.length === 0) return null; // Nothing to show
  if (options.length === 1 && options[0].count === countForAll) return null; // Useless filter
  const MenuComponent = horizontal ? Menu : Menu.Menu;
  return (
    <Menu.Item className={cx('menu-filter--simple', filter.dataKey || '')}>
      {!horizontal && <Menu.Header>{title}</Menu.Header>}

      <MenuComponent text={horizontal} {...menuProps}>
        {horizontal && <Menu.Item header>{title}</Menu.Item>}
        {showAll && (
          <Menu.Item
            name=""
            active={value === undefined || value === null}
            onClick={() => onSelect(filter, value)}
            label={100}
          >
            {!hideCount && <Count value={countForAll} />}
            {getString('filters.all')}
          </Menu.Item>
        )}
        {options.map((option) => {
          const { value: v, label, count } = option;
          const isActive = v === value;
          return (
            <Menu.Item
              key={v}
              active={isActive}
              onClick={() => {
                if (!isActive) {
                  trackEvent(eventTags.FILTER_MENU_CLICK, {
                    pageId: screen?.name || pageId,
                    name: filter?.name || filter.dataKey,
                    ...option,
                  });
                }
                onSelect(filter, v);
              }}
            >
              {!hideCount && <Count value={count} />}
              {label}
              {isActive && showCloseIcon && <Icon name="times" />}
            </Menu.Item>
          );
        })}
        {limit && values.length > limit && (
          <Menu.Item onClick={() => setShowMore(!showMore)} style={{ fontSize: '0.9em' }}>
            {getString(showMore ? 'filters.show-less' : 'filters.show-more')}
          </Menu.Item>
        )}
      </MenuComponent>
    </Menu.Item>
  );
};

SimpleFilter.defaultProps = {
  pageId: PropTypes.string,
  value: undefined,
};

SimpleFilter.propTypes = {
  filter: PropTypes.shape({
    countForAll: PropTypes.number,
    dataKey: PropTypes.string.isRequired,
    hideCount: PropTypes.bool,
    horizontal: PropTypes.bool,
    limit: PropTypes.number,
    menuProps: PropTypes.object,
    name: PropTypes.string,
    orderBy: PropTypes.string,
    showAll: PropTypes.bool,
    showCloseIcon: PropTypes.bool,
    title: PropTypes.string.isRequired,
    values: PropTypes.array,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  pageId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default SimpleFilter;
