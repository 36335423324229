import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Item, Label, Menu, Tab } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useScreenConfig } from '../../config/screens.context';
import { getString } from '../../utils';
import { invitesByStatus } from '../store/networking.selectors';
import NetworkingUserItem from '../blocks/NetworkingUserItem';
import ChatStatus from '../store/ChatStatus';
import { InviteStatuses } from '../blocks/NetworkingParticipantList.helpers';
import { acceptInvite, refuseInvite } from '../store/networking.actions';
import { UserInvite } from '../blocks/UserInvite';
import { extractOtherProfile } from '../store/networking.helpers';

const translationRoot = 'networking.my-invites';
const t = (key, ...props) => getString(`${translationRoot}.${key}`, ...props);

const inviteNeedsAction = (type, status) => status === ChatStatus.invited && type === 'received';

const chatStatusToInviteStatus = {
  [ChatStatus.accepted]: InviteStatuses.connected,
  [ChatStatus.invited]: InviteStatuses.awaiting,
  [ChatStatus.rejected]: InviteStatuses.invitedRefused,
};

const defaultInviteConfigs = [
  {
    type: 'all',
    status: 'accepted',
  },
  {
    type: 'received',
    status: 'invited',
  },
  {
    type: 'received',
    status: 'rejected',
  },
  {
    type: 'sent',
    status: 'invited',
  },
  {
    type: 'sent',
    status: 'rejected',
  },
];

const SimpleListPane = ({ title, children }) => (
  <Tab.Pane>
    <Header as="h3">{title}</Header>
    {children}
  </Tab.Pane>
);
SimpleListPane.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

const InviteTabPane = ({ title, status, active, invites, onRefuse, onAccept }) => {
  if (!invites.length)
    return (
      <Tab.Pane>
        <Header as="h3">{title}</Header>
        <p>{t('no-invite')}</p>
      </Tab.Pane>
    );

  if (active) {
    return (
      <SimpleListPane title={title}>
        <Item.Group divided>
          {invites.map((invite) => {
            return (
              <UserInvite
                item={invite.inviterProfile}
                message={invite.message}
                onRefuse={() => onRefuse(invite)}
                onAccept={() => onAccept(invite)}
              />
            );
          })}
        </Item.Group>
      </SimpleListPane>
    );
  }

  return (
    <SimpleListPane title={title}>
      <Item.Group divided>
        {invites.map((invite) => (
          <NetworkingUserItem
            key={invite.id}
            onConnect={() => null}
            item={extractOtherProfile(invite)}
            status={chatStatusToInviteStatus[status]}
          />
        ))}
      </Item.Group>
    </SimpleListPane>
  );
};

InviteTabPane.defaultProps = {
  active: false,
};

InviteTabPane.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  active: PropTypes.bool,
  invites: PropTypes.array.isRequired,
  onRefuse: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

/**
 * Returns both received and sent if no type specified
 * @param {*} invites
 * @param {*} type
 * @param {*} status
 */
function getInvites(invites, type, status) {
  if (type === 'all') {
    // Fetch from both types
    return [...(invites.received?.[status] || []), ...(invites.sent?.[status] || [])];
  }
  return invites[type]?.[status] || [];
}

const NetworkingInvites = () => {
  const { invitesConfig = defaultInviteConfigs } = useScreenConfig('networking');
  const allInvites = useSelector(invitesByStatus);
  const dispatch = useDispatch();
  const accept = (invite) => dispatch(acceptInvite(invite.id));
  const refuse = (invite) => dispatch(refuseInvite(invite.id));

  const tabs = invitesConfig.map(({ type, status }) => {
    const invites = getInvites(allInvites, type, status);
    const active = inviteNeedsAction(type, status);
    const title = t(`${type}.${status}`);
    return {
      menuItem: (
        <Menu.Item key={`menu-${type}-${status}`}>
          {title}
          {invites.length > 0 && <Label color={active ? 'blue' : 'grey'}>{invites.length}</Label>}
        </Menu.Item>
      ),
      render: () => {
        return (
          <InviteTabPane
            title={title}
            type={type}
            status={status}
            active={active}
            invites={invites}
            onRefuse={refuse}
            onAccept={accept}
          />
        );
      },
    };
  });

  return (
    <Grid>
      <Grid.Column width={16}>
        <Tab menu={{ vertical: true, fluid: true }} panes={tabs} renderActiveOnly />
      </Grid.Column>
    </Grid>
  );
};

export default NetworkingInvites;
