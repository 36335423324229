import cx from 'classnames';
import React, { FC } from 'react';
import { Button, Card } from 'semantic-ui-react';

import { useSelectedAchievements } from '../../../../core/achievements/achievements.hook';
import { Achievement } from '../../../../types/achievements.types';
import { getString } from '../../../../utils';
import AchievementBadge from '../../../AchievementBadge';
import CdnImage from '../../../CdnImage';
import { isImage, isPDF } from '../../utils';
import { linkable } from '../utils';
import './FileCard.scss';

type FileCardProps = {
  downloadOnClick?: boolean; // Disables download button, always downloads
  file: Record<string, any>;
  onClickFile: () => void;
  onDownload: () => void;
};

const LinkableCard = linkable(Card);
const LinkableContent = linkable(Card.Content);

const FileCard: FC<FileCardProps> = (props) => {
  const { file, onClickFile, onDownload, downloadOnClick } = props;
  const {
    achievements: achievementList,
    title,
    originalFilename,
    uri,
    thumbnail,
    description,
    image,
  } = file || {};

  const width = 260;
  const isCardClickable = !downloadOnClick && (isImage(uri) || isPDF(uri));
  const { ids: achievementsIds } = achievementList || {};
  const blockAchievements = useSelectedAchievements(achievementsIds);
  if (!file) return null;
  return (
    <LinkableCard
      className="FileCard"
      href={!isCardClickable && uri}
      onClick={isCardClickable ? onClickFile : () => onDownload()}
    >
      <Card.Content className="content--image">
        <CdnImage
          src={thumbnail || image?.uri || uri}
          maxWidth={width}
          maxHeight={Math.round((width / 21) * 29.7)}
          imageType="jpg"
        />
      </Card.Content>
      <Card.Content className="content">
        <Card.Header className={!title ? 'break-all' : undefined}>
          {title || originalFilename}
          {blockAchievements?.map((a: Achievement) => (
            <AchievementBadge
              key={a._id}
              className={cx('file-achievement-image', a._id)}
              achievement={a}
              height={30}
            />
          ))}
        </Card.Header>
        {description && <Card.Meta>{description}</Card.Meta>}
      </Card.Content>
      {!downloadOnClick && (
        <LinkableContent
          href={isCardClickable && uri}
          style={{ flex: 0 }}
          onClick={isCardClickable && uri ? () => onDownload() : undefined}
        >
          <Button fluid>{getString('btn.download')}</Button>
        </LinkableContent>
      )}
    </LinkableCard>
  );
};

FileCard.defaultProps = {};

export default FileCard;
