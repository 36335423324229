import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Container } from 'semantic-ui-react';

import Styles from '../Styles';
import PageBackground from '../components/PageBackground';
import { useUpdateConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { PreviewMode } from '../config/preview.context';
import { Program } from '../programs/Program';
import store from '../shared/Store';

const ProgramPreview = () => {
  const { homeBackground, mobileHomeBackground } = useDesignConfig();
  const [item, setItem] = useState(null);
  const updateConfig = useUpdateConfig();
  store.isPreview = true;

  useEffect(() => {
    const listener = (event) => {
      const { sponsor: data, lang } = event.data;
      if (data) {
        updateConfig({
          lang,
        });
        setItem(data);
      }
    };
    window.addEventListener('message', listener, false);
    return () => {
      window.removeEventListener('message', listener, false);
    };
  }, [updateConfig]);
  if (!item) return null;

  return (
    <PreviewMode>
      <Provider store={store.reduxStore}>
        <Styles />
        <Container style={{ marginTop: 16 }}>
          <PageBackground image={homeBackground} mobile={mobileHomeBackground} />
          <Program program={item} />
        </Container>
      </Provider>
    </PreviewMode>
  );
};

export default ProgramPreview;
