import cx from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMedia } from 'react-use';
import { Modal } from 'semantic-ui-react';

import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../components/ConfirmationModal/ConfirmationModal';
import GlobalModalManager from '../../../components/GlobalModalManager';
import socialWallService from '../../../core/services/social-wall.service';
import { useBackButton } from '../../../hooks/useBackButton';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import { useSocialWallEmotes } from '../../hooks/useSocialWallEmotes';
import { useSocialWallPublication } from '../../hooks/useSocialWallPublications';
import { useSocialWallPublicationsComments } from '../../hooks/useSocialWallPublicationsComments.hook';
import { SocialWallConfigProvider, useSocialWallConfig } from '../../social-wall-config.context';
import { Media, SocialWallConfigEntry } from '../../types/SocialWallV2.types';
import { ReportPublicationModal } from '../ReportPublicationModal/ReportPublicationModal';
import { SocialWallPublicationModal } from '../SocialWallPublicationModal';
import { DesktopDetailsPublication } from './DesktopDetailsPublication';
import './DetailedPublicationModal.scss';
import { MobileDetailsPublication } from './MobileDetailsPublication';

export interface DetailedPublicationModalProps {
  publicationId: string;
  userId: string;
  userCollection: string;
  onCancel?: () => void;
  config: SocialWallConfigEntry;
  onGoNext: () => void;
  onGoPrev: () => void;
}

export const DetailedPublicationModal = ({
  publicationId,
  onCancel,
  userId,
  userCollection,
  config,
  onGoNext,
  onGoPrev,
}: DetailedPublicationModalProps): JSX.Element => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  const mobile = useMedia(GLOBAL_MEDIA_QUERIES.mobile);
  const {
    comments,
    addPublicationComment,
    deletePublicationComment,
  } = useSocialWallPublicationsComments(publicationId);

  const { publication, deletePost, editPost } = useSocialWallPublication(publicationId);

  const { toggleInteraction, userInteractions } = useSocialWallEmotes({ userId, userCollection });

  const onDeletePublication = async () => {
    const confirm = await GlobalModalManager.open(ConfirmationModal, {
      header: <span>{t('social-wall.publication-deletion')}</span>,
      children: <span>{t('social-wall.confirm-publication-deletion')}</span>,
    } as ConfirmationModalProps);
    if (!confirm) return;
    await deletePost();

    if (onCancel) onCancel();
  };

  const onEditPublication = async () => {
    if (onCancel) onCancel();
    await GlobalModalManager.open(SocialWallPublicationModal, {
      initialPublication: publication,
      createPublication: async (message?: string, media?: Media, thumbnail?: Media) => {
        const confirm = await GlobalModalManager.open(ConfirmationModal, {
          header: <span>{t('social-wall.publication-edition')}</span>,
          children: <span>{t('social-wall.confirm-publication-edition')}</span>,
          buttonOptions: {
            color: 'blue',
          },
        } as ConfirmationModalProps);
        if (!confirm) return undefined;

        return editPost({ message, media, thumbnail });
      },
    });
  };

  const onReportPublication = async () => {
    const result = await GlobalModalManager.open(ReportPublicationModal, { id: publicationId });
    if (result) {
      const reportData = {
        ...result,
        postId: publicationId,
      };
      const { report } = await socialWallService.reportMessage(publicationId, reportData);

      if (report) {
        toast.success(t('social-wall.report.success'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        toast.error(t('social-wall.report.error'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  };

  const onDeleteComment = async (commentId: string) => {
    const confirm = await GlobalModalManager.open(ConfirmationModal, {
      header: <span>{t('social-wall.comment-deletion')}</span>,
      children: <span>{t('social-wall.confirm-comment-deletion')}</span>,
    } as ConfirmationModalProps);
    if (!confirm) return;
    await deletePublicationComment(commentId);
  };

  const PublicationComponent = mobile ? MobileDetailsPublication : DesktopDetailsPublication;

  const onClose = onCancel || (() => setOpen(false));
  useBackButton(onClose);

  const hasMedia = useMemo(() => {
    return !!publication?.data?.media;
  }, [publication]);
  return (
    <SocialWallConfigProvider {...config}>
      <Modal
        open={open}
        className={cx(
          `publication-details publication-details--${mobile ? 'mobile' : 'desktop'}`,
          hasMedia && 'publication-details--media',
          !publication && 'publication-details--empty',
        )}
        onClose={onClose}
        dimmer={{ className: mobile ? 'animated-modal' : '' }}
        closeIcon={!publication}
      >
        {publication ? (
          <PublicationComponent
            publication={publication}
            comments={comments}
            onClose={onClose}
            onDelete={onDeletePublication}
            onEdit={onEditPublication}
            onReport={onReportPublication}
            onAddComment={addPublicationComment}
            onDeleteComment={onDeleteComment}
            userId={userId}
            onInteractionClick={(emote) => toggleInteraction(publicationId, emote)}
            userInteractions={userInteractions[publicationId]}
            onNext={onGoNext}
            onPrev={onGoPrev}
          />
        ) : (
          <div>{t('social-wall.post-doesnt-exist')}</div>
        )}
      </Modal>
    </SocialWallConfigProvider>
  );
};

DetailedPublicationModal.open = async (
  props: DetailedPublicationModalProps,
): Promise<boolean | null> => {
  return GlobalModalManager.open(DetailedPublicationModal, props);
};
