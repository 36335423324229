import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import NavMenuItem from '../../../components/menu/NavMenuItem';
import { getString } from '../../../utils';
import Count from './Count';

const RouterParamFilter = ({ filter, horizontal, fitted }) => {
  const {
    title,
    values,
    showAll,
    dataKey,
    routerPath,
    countForAll,
    hideCount,
    menuProps,
    showCloseIcon,
  } = filter;

  const { pathname } = useLocation();

  function generatePath(name) {
    return routerPath.replace(`:${dataKey}`, name);
  }

  const MenuComponent = horizontal ? Menu : Menu.Menu;
  return (
    <Menu.Item fitted={fitted} className={cx('menu-filter--router-param', filter.dataKey || '')}>
      {!horizontal && <Menu.Header>{title}</Menu.Header>}
      <MenuComponent text={horizontal} {...menuProps}>
        {horizontal && <Menu.Item header>{title}</Menu.Item>}
        {showAll && (
          <NavMenuItem exact to={generatePath('')}>
            {!hideCount && <Count value={countForAll} />}
            {getString('filters.all')}
          </NavMenuItem>
        )}
        {values.map(({ label, value, count }) => {
          const isActive = routerPath.replace(`:${dataKey}`, value) === pathname;
          return (
            <NavMenuItem
              key={value}
              exact
              name={value}
              active={isActive}
              to={generatePath(isActive && showCloseIcon ? '' : value)}
            >
              {!hideCount && <Count value={count} />}
              {label}
              {isActive && showCloseIcon && <Icon name="times" />}
            </NavMenuItem>
          );
        })}
      </MenuComponent>
    </Menu.Item>
  );
};

RouterParamFilter.defaultProps = {
  fitted: false,
  horizontal: false,
};

RouterParamFilter.propTypes = {
  fitted: PropTypes.bool,
  horizontal: PropTypes.bool,
  filter: PropTypes.shape({
    countForAll: PropTypes.number,
    title: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string.isRequired })),
    showAll: PropTypes.bool,
    routerPath: PropTypes.string.isRequired,
    hideCount: PropTypes.bool,
    menuProps: PropTypes.object,
    showCloseIcon: PropTypes.bool,
  }).isRequired,
};

export default RouterParamFilter;
