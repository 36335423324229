/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

import { useMe } from '../../profile/hooks';
import store from '../../shared/Store';

const translationPrefix = 'sponsors.sponsor';

type Exhbitor = {
  _id: string;
  collection: string;
  name: string;
  kind?: string;
};

type Props = {
  allowFavorites: boolean;
  exhibitor: Exhbitor;
  size: IconSizeProp;
};

export const ExhibitorFavorite = (props: Props): JSX.Element | null => {
  const { exhibitor, allowFavorites, size } = props;
  const { name, collection, _id, kind = 'users' } = exhibitor;
  const { t } = useTranslation();
  const user = useMe();
  const favoriteKey = `private.favorites.${kind}.${collection}.${_id}`;
  const isExhibitorSaved = !!get(user, favoriteKey);

  const saveExhibitor = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    await store.updateUser({ [favoriteKey]: !isExhibitorSaved });
    toast(
      t(
        `${translationPrefix}.${
          isExhibitorSaved ? 'removed-from-favorites' : 'saved-in-favorites'
        }`,
        { name },
      ),
    );
  };
  if (!allowFavorites) return null;

  return (
    <div className="exhibitor favorite">
      <Icon
        link
        size={size}
        name={`star ${isExhibitorSaved ? '' : 'outline'}` as SemanticICONS}
        onClick={saveExhibitor}
      />
    </div>
  );
};
