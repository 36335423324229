import React, { memo } from 'react';
import { Button, Header, Item } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Lazy from 'react-lazy-load';
import { getString } from '../../utils';
import './NetworkingParticipantList.scss';
import useAlreadyInContact from '../hooks/useAlreadyInContact';
import NetworkingUserItem from './NetworkingUserItem';
import { InviteStatuses } from './NetworkingParticipantList.helpers';

const translationPrefix = 'networking';

const NetworkingParticipantList = memo(({ contacts, totalCount, onConnect, onLoad, canLoad }) => {
  const chatStatus = useAlreadyInContact();
  return (
    <>
      <Header as="h3">
        {totalCount} {getString(`${translationPrefix}.users`)}
      </Header>
      <Item.Group divided>
        {contacts.map((item) => (
          <NetworkingUserItem
            key={item._id}
            item={item}
            onConnect={onConnect}
            status={chatStatus[item._id] ?? InviteStatuses.ableToInvite}
          />
        ))}
        {canLoad && (
          <Lazy onContentVisible={onLoad} className="NetworkingParticipantList--LoadMore">
            <Button primary onClick={onLoad} className="NetworkingParticipantList--LoadMore">
              {getString(`${translationPrefix}.load-more`)}
            </Button>
          </Lazy>
        )}
      </Item.Group>
    </>
  );
});

NetworkingParticipantList.propTypes = {
  contacts: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  canLoad: PropTypes.bool.isRequired,
  onConnect: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default NetworkingParticipantList;
