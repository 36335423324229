/* eslint-disable @typescript-eslint/explicit-module-boundary-types, class-methods-use-this */
import pubsubService from '../pubsub.service';
import WsFirestoreCollection from './WsFirestoreCollection';

export default class WsFirestoreDocument {
  // eslint-disable-next-line no-useless-constructor
  constructor(private path: string) {}

  collection(col: string): WsFirestoreCollection {
    return new WsFirestoreCollection(`${this.path}/${col}`);
  }

  update(patch: unknown, options: unknown): Promise<void> {
    return new Promise((resolve) => {
      pubsubService.update(this.path, patch, options);
      setTimeout(resolve, 100);
    });
  }

  set(patch: unknown, options: unknown): Promise<void> {
    return new Promise((resolve) => {
      pubsubService.set(this.path, patch, options);
      setTimeout(resolve, 100);
    });
  }
}
