import keyBy from 'lodash/keyBy';
import { ReactText } from 'react';
import { toast } from 'react-toastify';

// @question : je ne comprends pas pourquoi il faut une erreur esling  Dependency cycle ici
// et dans achievement.reducers...
// eslint-disable-next-line import/no-cycle
import CdnImage from '../../components/CdnImage';
import {
  Achievement,
  AchievementState,
  RawAchievement,
  UserAchievement,
} from '../../types/achievements.types';
import { getString } from '../../utils';

export function computeAchievement(
  rawAchievement: RawAchievement,
  userAchievement: UserAchievement,
): Achievement {
  const isFinished = userAchievement && userAchievement?.state === AchievementState.COMPLETED;
  const achievement = {
    ...rawAchievement,
    state: userAchievement ? userAchievement.state : AchievementState.NOT_STARTED,
    media: rawAchievement.data?.lockedMedia,
  };
  if (isFinished) {
    achievement.label = userAchievement.unlockedLabel || achievement.label;
    achievement.media = userAchievement.unlockedMedia || achievement.media;
  }
  return achievement;
}

export function computeAchievements(
  achievements: RawAchievement[],
  userAchievements: UserAchievement[],
): Achievement[] {
  const userAchievementsById = keyBy(userAchievements, 'achievementId');

  return achievements.map((a) => {
    const ua = userAchievementsById[a._id];
    return computeAchievement(a, ua);
  });
}

export const notifyAchievement = (achievement: Achievement): ReactText | null => {
  const { media } = achievement || {};
  if (!media) return null;
  return toast(
    <div className="achievement-notify">
      <CdnImage className="unlock-image" src={media} maxHeight={160} />
      <span className="text">
        {getString('achievements.notification.unlock-message', { achievement })}
      </span>
    </div>,
    { containerId: 'achievement-toast-container' },
  );
};
