/* eslint-disable @typescript-eslint/no-explicit-any */
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { FC } from 'react';
import { Card, Header } from 'semantic-ui-react';

import { Workshop, WorkshopSession } from '../../../../../../types/workshop.types';
import { WorkshopItems } from '../../../WorkshopItems';

type ListTemplate = {
  variant?: string;
  minHeight?: number;
};

type TabDayProps = {
  id: string;
  groupBy?: string;
  items: Workshop[] | WorkshopSession[];
  centered?: boolean;
  template?: ListTemplate;
  filteredWorkshopIds?: string[];
  itemProps: any;
};

function fullDateValue(item: WorkshopSession) {
  const format = (d: string) => moment(d, moment.ISO_8601).format('HH[_]mm');
  return `${format(item.startDate)}_${format(item.endDate)}`;
}

function fullDateLabel(item: WorkshopSession) {
  const format = (d: string) => moment(d, moment.ISO_8601).format('LT');
  if (!item.endDate) return format(item.startDate);
  return `${format(item.startDate)} - ${format(item.endDate)}`;
}

function computeGroups(items: WorkshopSession[], groupByField: string) {
  if (groupByField === 'fulltime') {
    const groups = groupBy(items, fullDateValue);
    return Object.keys(groups)
      .sort()
      .map((date) => {
        const sessions = groups[date];
        return {
          id: date,
          label: fullDateLabel(sessions[0]),
          items: sessions,
        };
      });
  }
  return [
    {
      id: 'all',
      label: '',
      items,
    },
  ];
}

const defaultTemplate: ListTemplate = { variant: 'card' };
const TabDay: FC<TabDayProps> = (props) => {
  const {
    id,
    items,
    centered,
    filteredWorkshopIds,
    itemProps,
    template = defaultTemplate,
    groupBy: groupByField,
  } = props;

  if (!groupByField) {
    return (
      <div className={`workshops--group workshops--group--${id}`}>
        <Card.Group className="workshops--container" itemsPerRow={3} centered={centered}>
          <WorkshopItems
            workshops={items}
            filteredWorkshopIds={filteredWorkshopIds}
            template={template}
            itemProps={itemProps}
          />
        </Card.Group>
      </div>
    );
  }

  const groups = computeGroups(items, groupByField);

  return (
    <div className={`workshops--group workshops--group--${id}`}>
      {groups.map((group) => {
        return (
          <div className={`workshops--subgroup workshops--subgroup--${group.id}`}>
            <Header as="h4" className="header--time">
              {group.label}
            </Header>
            <Card.Group className="workshops--container" itemsPerRow={3} centered={centered}>
              <WorkshopItems
                workshops={group.items}
                filteredWorkshopIds={filteredWorkshopIds}
                template={template}
                itemProps={itemProps}
              />
            </Card.Group>
          </div>
        );
      })}
    </div>
  );
};

export default TabDay;
