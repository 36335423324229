import React from 'react';
import { Header, Item, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { getString } from '../../utils';
import { UserInvite } from './UserInvite';

const translationPrefix = 'networking';
const AwaitingInvites = ({ invites, onAccept, onRefuse }) => (
  <Segment className="NetworkingChat--PendingInvites">
    <Header as="h3"> {getString(`${translationPrefix}.pending-invites`)}</Header>
    {invites?.length ? (
      <Item.Group divided>
        {invites.map((invite) => {
          return (
            <UserInvite
              item={invite.inviterProfile}
              message={invite.message}
              onRefuse={() => onRefuse(invite)}
              onAccept={() => onAccept(invite)}
            />
          );
        })}
      </Item.Group>
    ) : (
      <p>{getString(`${translationPrefix}.no-invites`)}</p>
    )}
  </Segment>
);

AwaitingInvites.propTypes = {
  invites: PropTypes.array.isRequired,
  onAccept: PropTypes.func.isRequired,
  onRefuse: PropTypes.func.isRequired,
};

export default AwaitingInvites;
