import React from 'react';
import PropTypes from 'prop-types';
import { bem } from '../../core/design/bem';
import BlockContainer from '../../components/BlockContainer';
import WorkshopList from '../../components/workshops/list/WorkshopList';

const css = bem('UserScheduleWorkshopTemplate');

const UserScheduleWorkshopTemplate = (props) => {
  const { _id, className, container, events, itemProps } = props;
  return (
    <BlockContainer
      className={css({
        id: _id,
      }).mix(className)}
      type="basic"
      {...container}
    >
      <WorkshopList
        workshopList={events}
        template={{ variant: itemProps?.variant }}
        itemProps={itemProps}
      />
    </BlockContainer>
  );
};

UserScheduleWorkshopTemplate.defaultProps = {
  className: undefined,
  container: undefined,
  itemProps: undefined,
};
UserScheduleWorkshopTemplate.propTypes = {
  _id: PropTypes.string.isRequired,
  className: PropTypes.string,
  container: PropTypes.object,
  events: PropTypes.array.isRequired,
  itemProps: PropTypes.object,
};

export default UserScheduleWorkshopTemplate;
