import cx from 'classnames';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useSelector } from 'react-redux';
import { Button, Grid, Modal } from 'semantic-ui-react';

import { userAgenda } from '../../../agenda/store/agenda.selectors';
// eslint-disable-next-line import/no-cycle
import UserRegistrationModal from '../../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import CdnImage from '../../../components/CdnImage';
import EntityLink from '../../../components/EntityLink';
import { bem } from '../../../core/design/bem';
import PageMeta from '../../../core/metadata/PageMeta';
import { trackModal } from '../../../core/trackers';
import { workshopArrayProptypes, workshopProptypes } from '../../../propTypes';
import { useModalEntries } from '../../../store/reducers/modalEntries.hooks';
import Images from '../../../utils/Images';
import { validatesCondition } from '../../../utils/conditionUtils';
import { useOrderBy } from '../../../utils/hooks';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import { useSyncedCollectionWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';
import { useSyncedCollectionWorkshops } from '../../store/workshops.hooks';
// eslint-disable-next-line import/no-cycle
import WorkshopActions from '../WorkshopActions';
import './WorkshopModal.scss';
import { workshopModalblockComponents } from './components';
import { useWorkshopToRegister } from './hooks';

const translationPrefix = 'workshops.workshop.modal';
const css = bem('WorkshopModal');

function asWorkshop(entity) {
  if (!entity) return entity;
  return { kind: 'workshops', ...entity };
}

const FooterButton = ({ type, icon, entity }) => {
  const { t } = useTranslation();
  const label = t(`${translationPrefix}.${type}`);
  const isNext = type === 'next';
  const disabled = !entity;

  const Component = entity ? EntityLink : 'div';

  return (
    <Component entity={entity} className={cx(type, { disabled })}>
      {isNext && label}
      <Button
        circular
        icon={icon}
        style={{ marginLeft: isNext ? 10 : 0, marginRight: isNext ? 0 : 10 }}
        disabled={disabled}
      />
      {!isNext && label}
    </Component>
  );
};

FooterButton.defaultProps = {
  entity: undefined,
};

FooterButton.propTypes = {
  type: PropTypes.string.isRequired,
  entity: PropTypes.bool,
  icon: PropTypes.string.isRequired,
};

const defaultOrder = [
  { field: 'startDate', order: 'asc' },
  { field: 'title', order: 'asc' },
];

const defaultBlocks = [{ type: 'details' }, { type: 'description' }, { type: 'speakers' }];

const WorkshopModalBlocks = (props) => {
  const { sessions, session, registration, config, blocks } = props;
  return (
    <>
      {blocks.map((block) => {
        const { type } = block;
        const Component = get(workshopModalblockComponents, type);
        if (!Component) return null;
        return (
          <Component
            key={block.id}
            {...block}
            session={session}
            config={config}
            workshopSessions={sessions}
            registration={registration}
          />
        );
      })}
    </>
  );
};

WorkshopModalBlocks.propTypes = {
  blocks: PropTypes.array,
  config: PropTypes.object,
  registration: PropTypes.shape(workshopProptypes),
  session: PropTypes.shape(workshopProptypes).isRequired,
  sessions: workshopArrayProptypes.isRequired,
};

WorkshopModalBlocks.defaultProps = {
  blocks: [],
  config: {},
  registration: undefined,
};

function WorkshopModal(props) {
  const {
    blocks,
    collection,
    orderBy: orderByConfig,
    workshopId,
    onClose,
    itemProps,
    metadata,
    sidebar,
    ...modalConfig
  } = props;

  const { size, showControls = true } = modalConfig;
  const { actions = [], saveOnSessionsOnly = false, imageProps = {} } = itemProps;
  const { t } = useTranslation();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const isDesktop = matches.desktop;

  const modalEntries = useModalEntries('workshops', collection);
  const syncedWorkshops = useOrderBy(useSyncedCollectionWorkshops(collection), orderByConfig);

  const session = syncedWorkshops.find((w) => w._id === workshopId);
  useEffect(() => {
    if (session) {
      trackModal(modalConfig, session);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConfig.type, modalConfig.modalKey, workshopId, !!session]);
  const syncedWorkshopSessions = useOrderBy(
    useSyncedCollectionWorkshopSessions(collection),
    orderByConfig,
  );
  const [isOpen, setIsOpen] = useState(false);
  const workshopSessions = useMemo(
    () => syncedWorkshopSessions.filter((s) => s.workshopId === workshopId),
    [syncedWorkshopSessions, workshopId],
  );
  const workshopToRegister = useWorkshopToRegister(
    workshopId,
    syncedWorkshops,
    workshopSessions,
    saveOnSessionsOnly,
  );
  const events = useSelector(userAgenda);
  const registration = events.find((e) => e.workshopId === workshopId);
  if (!session) return null;

  const { image, startDate, endDate } = session;
  const hasImage = Images.exists(image);

  const index = findIndex(modalEntries, (w) => w.workshopId === workshopId);
  const showSidebar = sidebar?.condition ? validatesCondition(sidebar.condition, session) : true;

  return (
    <>
      <Modal
        className={css({ collection }).toString()}
        open
        onClose={() => {
          onClose({ workshopId: undefined, modal: undefined, startDate: undefined });
        }}
        size={size}
        closeIcon
      >
        {metadata && <PageMeta {...metadata} item={session} />}
        <Modal.Header as="h3" className={css('header').toString()}>
          {startDate && t(`${translationPrefix}.date-time`, { startDate, endDate })}
        </Modal.Header>
        <Modal.Content className={css('content').toString()} scrolling>
          <div style={{ flex: 1 }}>
            {hasImage && (
              <div className="image banner" style={{ flexShrink: 0 }}>
                <CdnImage
                  key={image?.uri}
                  src={image}
                  maxWidth={900}
                  maxHeight={280}
                  func="crop"
                  style={{ width: '100%' }}
                  {...imageProps}
                />
              </div>
            )}
            <div className="container">
              <Grid relaxed divided stackable inverted stretched columns="equal" className="main">
                <Grid.Row>
                  <Grid.Column>
                    <WorkshopModalBlocks
                      blocks={blocks}
                      session={session}
                      config={itemProps}
                      sessions={workshopSessions}
                      registration={registration}
                    />
                  </Grid.Column>
                  {showSidebar && sidebar?.blocks?.length > 0 && (
                    <Grid.Column width={sidebar?.width || 4} className="sidebar">
                      <WorkshopModalBlocks
                        blocks={sidebar.blocks}
                        session={session}
                        config={itemProps}
                        sessions={workshopSessions}
                        registration={registration}
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
            </div>
          </div>
          <div className="footer">
            {!isDesktop && (
              <WorkshopActions
                actions={actions}
                workshop={workshopToRegister}
                onClick={setIsOpen}
              />
            )}
            {showControls && (
              <div className="controls">
                <FooterButton
                  type="previous"
                  icon="chevron left"
                  entity={asWorkshop(modalEntries?.[index - 1])}
                />
                {isDesktop && (
                  <WorkshopActions
                    actions={actions}
                    workshop={workshopToRegister}
                    onClick={setIsOpen}
                  />
                )}
                <FooterButton
                  type="next"
                  icon="chevron right"
                  entity={asWorkshop(modalEntries?.[index + 1])}
                />
              </div>
            )}
          </div>
        </Modal.Content>
      </Modal>
      {isOpen && (
        <UserRegistrationModal
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
}

WorkshopModal.defaultProps = {
  blocks: defaultBlocks,
  itemProps: {},
  metadata: undefined,
  onChange: undefined,
  orderBy: defaultOrder,
  sessionId: undefined,
  workshopId: undefined,
  workshops: [],
  sidebar: undefined,
};

WorkshopModal.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  collection: PropTypes.string.isRequired,
  itemProps: PropTypes.object,
  metadata: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  orderBy: PropTypes.array,
  sessionId: PropTypes.string,
  workshopId: PropTypes.string,
  workshops: workshopArrayProptypes,
  sidebar: PropTypes.object,
};

export default WorkshopModal;
