/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useMemo } from 'react';

type PostId = string;
type PostReactions = Record<string, any>;
type UserLikes = Record<PostId, PostReactions[]>;

const UserLikesContext = createContext<UserLikes>({});

export function usePostUserLikes(postId: string): PostReactions[] {
  const userLikes = useContext(UserLikesContext);
  return useMemo(() => (userLikes || {})[postId] || [], [userLikes, postId]);
}

export const UserLikesProvider = ({
  likes,
  children,
}: {
  likes: UserLikes;
  children: any;
}): JSX.Element => {
  return <UserLikesContext.Provider value={likes}>{children}</UserLikesContext.Provider>;
};
