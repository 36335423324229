import { useRef, useState } from 'react';
import { useEvent } from 'react-use';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useScrollEnd = () => {
  const [isAtTheBottom, setIsAtTheBottom] = useState<boolean>(false);
  const ref = useRef<HTMLElement | null>(null);

  useEvent(
    'scroll',
    () => {
      window.addEventListener('scrollend', () => {
        if (window.scrollY + window.innerHeight >= (ref?.current?.clientHeight as number) - 10) {
          setIsAtTheBottom(true);
        } else {
          setIsAtTheBottom(false);
        }
      });
    },
    window,
    { capture: true },
  );

  return { isAtTheBottom, targetRef: ref };
};

export default useScrollEnd;
