export function clamp(num: number, min: number, max: number): number {
  if (min > max) return clamp(num, max, min);
  return Math.min(Math.max(num, min), max);
}

export function mapNumber(
  number: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
): number {
  const value = clamp(number, inMin, inMax);
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}
