import React, { useMemo } from 'react';

import i18n from '../../translations/i18n';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const IOSPWAPrompt = require('react-ios-pwa-prompt').default;

const PWAPrompt = (): JSX.Element | null =>
  // Note : there is a bug with IOSPWAPrompt, useMemo is here to NOT rerender the component
  useMemo(
    () => (
      <IOSPWAPrompt
        promptOnVisit={1}
        timesToShow={5}
        permanentlyHideOnDismiss={false}
        {...i18n.t('components.PWAPrompt', { returnObjects: true })}
      />
    ),
    [],
  );

export default React.memo(PWAPrompt);
