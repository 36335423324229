/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { bem } from '../../../core/design/bem';
import ClickableMap from '../../../home/components/ClickableMap';
import { ClickableMapProps } from '../../../home/components/ClickableMap/ClickableMap';
import BlockContainer from '../../BlockContainer';
import { BlockProps, ContainerProps } from '../types';

const css = bem('ClickableMapBlock');

type ClickableMapBlockProps = BlockProps & {
  _id: string;
  className?: string;
  container?: ContainerProps;
} & ClickableMapProps &
  typeof ClickableMapBlockDefaultProps;

const ClickableMapBlockDefaultProps = {
  container: {},
  className: '',
};

const ClickableMapBlock = ({
  _id,
  className,
  container,
  backgroundImage,
  overlayImage,
  items,
}: ClickableMapBlockProps): JSX.Element | null => {
  const history = useHistory();
  const blockClassName = css({ id: _id }).mix(className);

  const handleItemClick = useCallback((item) => history.push(item.path), [history]);

  return (
    <BlockContainer {...container} className={blockClassName.toString()}>
      <ClickableMap
        backgroundImage={backgroundImage}
        overlayImage={overlayImage}
        items={items}
        onClick={handleItemClick}
      />
    </BlockContainer>
  );
};

ClickableMapBlock.defaultProps = ClickableMapBlockDefaultProps;

export default ClickableMapBlock;
