/* eslint-disable react/require-default-props */
import get from 'lodash/get';

import BlockContainer from '../../components/BlockContainer';

type ItemHtmlBlockProps = {
  item: { _id: string } & Record<string, any>;
  field?: string;
  container?: {
    header?: string;
    type: string;
  };
};

const ItemTextBlock = ({ item, field, container }: ItemHtmlBlockProps): JSX.Element | null => {
  if (!field) return null;

  const text = get(item, field);
  if (!text) return null;
  return (
    <BlockContainer
      className="block--item-text"
      type={container?.type || 'segment'}
      header={container?.header}
    >
      {text}
    </BlockContainer>
  );
};

export default ItemTextBlock;
