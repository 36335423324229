import PropTypes from 'prop-types';
import React from 'react';
import { Label, Menu } from 'semantic-ui-react';

const FilterMenu = ({ value, options, onChange, ...props }) => (
  <Menu {...props}>
    {options.map((item) => (
      <Menu.Item
        key={item.value}
        active={value === item.value}
        onClick={() => onChange(item.value)}
      >
        {item.text} {item.count ? <Label>{item.count}</Label> : null}
      </Menu.Item>
    ))}
  </Menu>
);

FilterMenu.defaultProps = {
  options: [],
  value: undefined,
};

FilterMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, text: PropTypes.string.isRequired }),
  ),
  value: PropTypes.string,
};

export default FilterMenu;
