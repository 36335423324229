import { useLocalStorageState } from 'ahooks';
import React from 'react';
import { Modal } from 'semantic-ui-react';

import { useDesignConfig } from '../../config/design.context';
import { bem } from '../../core/design/bem';
import { useIsAnonymous } from '../../profile/hooks';
import CdnImage from '../CdnImage';

const css = bem('WelcomePopup');

const { eventId } = window.__DATA__;

const WelcomePopup = (): JSX.Element | null => {
  const { welcomePopup } = useDesignConfig();
  const { image, displayFor } = welcomePopup || {};
  const isAnonymous = useIsAnonymous();

  // TODO: for mobile apps ?
  const [lastPopup, setLastPopup] = useLocalStorageState(`platform-${eventId}-welcomePopup`, '');
  if (!image?.uri || lastPopup === image?.uri || (isAnonymous && displayFor === 'connected')) {
    return null;
  }

  const handleClose = () => setLastPopup(image.uri);

  return (
    <Modal open basic className={css.toString()} onClose={handleClose}>
      <Modal.Content>
        <CdnImage src={image} onClick={handleClose} />
      </Modal.Content>
    </Modal>
  );
};

export default WelcomePopup;
