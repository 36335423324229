import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useWorkshopRelatedEvent } from '../../../../workshops/utils/workshopRegistrationUtils';
import EntityLink from '../../../EntityLink';
import { bem } from '../../../../core/design/bem';

type EventStatusButtonProps = {
  workshop: any;
};

const css = bem('event-status-button');
const translationPrefix = 'workshops.workshop';
const EventStatusButton: FC<EventStatusButtonProps> = (props) => {
  const { workshop } = props;
  const { eventId: relatedEventId, status } = useWorkshopRelatedEvent(workshop);
  const { t } = useTranslation();
  const isEventStatusDisplayed = !!relatedEventId;
  if (!isEventStatusDisplayed) {
    return null;
  }

  const label = t(`${translationPrefix}.event-status.${status}`);
  const details =
    status === 'accept' || status === 'refuse' ? (
      <span className={css({ status }, 'details').toString()}>
        {t(`${translationPrefix}.event-status.${status}-details`)}
      </span>
    ) : null;

  return (
    <Button
      className={css({ status }).toString()}
      as={EntityLink}
      entity={workshop}
      content={
        <>
          {label}
          {details}
        </>
      }
    />
  );
};

EventStatusButton.propTypes = {
  workshop: PropTypes.object.isRequired,
};

export { EventStatusButton };
