/* eslint-disable @typescript-eslint/no-var-requires */
import escapeRegExp from 'lodash/escapeRegExp';

const { decode, encode } = require('he');

const openMarkRegex = new RegExp(escapeRegExp('&#x3C;mark class=&#x22; &#x22;&#x3E;'), 'g');
const closeMarkRegex = new RegExp(escapeRegExp('&#x3C;/mark&#x3E;'), 'g');

function applyHighlight(innerText: string, searchString: string): string {
  return (
    encode(
      decode(innerText).replace(
        new RegExp(`(${escapeRegExp(searchString)})`, 'gi'),
        '<mark class=" ">$1</mark>',
      ),
    )
      // Fix mark
      .replace(openMarkRegex, '<mark class=" ">')
      .replace(closeMarkRegex, '</mark>')
  );
}

export function highlightHtmlText(html: string, searchString: string | null): string {
  if (!html || !searchString) return html || '';

  return html.replace(
    new RegExp(`>([^<]*)<`, 'gi'),
    (_v, group1) => `>${applyHighlight(group1, searchString)}<`,
  );
}
