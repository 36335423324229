import { useMemo } from 'react';

import store from '../../shared/Store';
import { useFirebase } from '../../utils/useFirebase';

export function useUpdateReactions(): {
  removeLike(postId: string, type: string): Promise<void>;
  addLike(postId: string, type: string): Promise<void>;
} {
  // TODO: correct types
  const firebase: any = useFirebase();
  return useMemo(() => {
    const userLikesRef = firebase
      ?.firestore()
      .collection('social-wall')
      .doc(store.eventId)
      .collection('users')
      .doc(store.userId)
      .collection('likes');
    return {
      async removeLike(postId: string, type: string) {
        await userLikesRef.doc(`${postId}-${type}`).delete();
      },
      async addLike(postId: string, type: string) {
        const reaction = {
          messageId: postId,
          type,
          createdAt: new Date().toISOString(),
          userId: store.userId,
        };
        await userLikesRef.doc(`${postId}-${type}`).set(reaction, { merge: true });
      },
    };
  }, [firebase]);
}
