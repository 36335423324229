/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Forked from https://github.com/rexxars/react-hexagon

import React from 'react';

function getSize(props: any) {
  let width = props.diagonal;
  let height = props.diagonal;

  if (props.flatTop) {
    width *= 1.05;
    height *= 1.05;
  }

  if (props.backgroundScale) {
    width *= props.backgroundScale;
    height = width;
  } else if (props.backgroundWidth) {
    width = props.backgroundWidth;
    height = props.backgroundHeight;
  } else if (props.backgroundSize) {
    width = props.backgroundSize;
    height = props.backgroundSize;
  }

  return { width, height };
}

function BackgroundDef(props: any): JSX.Element {
  const { id, diagonal, yOffset = 0, backgroundImage } = props;
  const { width, height } = getSize(props);

  const hasSize = width !== diagonal;
  const imgWidth = hasSize ? width : '100%';
  const imgHeight = hasSize ? height : '100%';
  // eslint-disable-next-line react/destructuring-assignment
  const xOffset = typeof props.xOffset === 'undefined' && props.flatTop ? 0 - imgWidth * 0.065 : 0;

  return (
    <defs>
      <pattern id={id} width={width} height={height} patternUnits="userSpaceOnUse">
        <image
          x={xOffset}
          y={yOffset}
          width={imgWidth}
          height={imgHeight}
          xlinkHref={backgroundImage}
          preserveAspectRatio="xMidYMid slice"
        />
      </pattern>
    </defs>
  );
}

export default BackgroundDef;
