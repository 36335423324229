/* eslint-disable react/require-default-props */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react';

import BlockContainer from '../../components/BlockContainer';
import store from '../../shared/Store';
import { sweetAlert } from '../../utils/popupUtils';
import UploadFileBlock from '../components/UploadFileBlock';

type Container = {
  type?: string;
  header?: string;
  className?: string;
};

type Field = {
  key: string;
  label: string;
  type: 'file';
  editable?: boolean;
  allowMultiple?: boolean;
};

const ProfileFiles = ({
  container,
  field,
  user,
}: {
  container?: Container;
  field: Field;
  user: Record<string, any>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [userPatch, setUserPatch] = useState({ [field.key]: user[field.key] });
  async function handlePatch(patch: Record<string, any>) {
    setUserPatch(patch);
    try {
      await store.updateUser(patch);
      toast(t('profile.confirm.update-success'), {
        toastId: 'profile-update',
      });
    } catch (e) {
      await sweetAlert({
        text: t('profile.errors.update-profile'),
        icon: 'error',
      });
    }
  }
  return (
    <Grid.Row className="user-profile__files" stretched columns="equal">
      <Grid.Column>
        <BlockContainer
          {...container}
          className={`block--profile-files ${container?.className || ''}`}
        >
          <UploadFileBlock
            fieldKey={field.key}
            onChange={handlePatch}
            value={userPatch[field.key]}
          />
        </BlockContainer>
      </Grid.Column>
    </Grid.Row>
  );
};

export default ProfileFiles;
