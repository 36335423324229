import keyBy from 'lodash/keyBy';
import trimEnd from 'lodash/trimEnd';
import values from 'lodash/values';
import { useMemo } from 'react';

import { Screen } from '../types';
import { useConfig } from './config.context';

export const useScreensConfig = (): Record<string, Screen> => {
  return useConfig()?.screens;
};

export function useScreenConfig(name: string): Screen {
  const screenConfig = useScreensConfig() || {};
  // Backwards-compatible
  if (name === 'workshop') {
    return screenConfig[name] ?? screenConfig.workshops?.[name] ?? ({} as Screen);
  }
  return screenConfig[name] ?? {};
}

export function useLoginPath(): string {
  const loginConfig = useScreenConfig('login');
  return loginConfig?.path || '/login';
}

export function useAvailableScreenPaths(): Record<string, Screen> {
  const screens = useScreensConfig();
  return useMemo(() => {
    const allScreens = values(screens).filter((s) => s.path);
    return keyBy(allScreens, (s): string => {
      if (s.path !== '/') {
        return trimEnd(s.path, '/');
      }
      return s.path;
    });
  }, [screens]);
}

export function useScreenByType(type: string): Screen | undefined {
  const screens = useScreensConfig();
  return useMemo(() => {
    return values(screens).find((s) => s.type === type);
  }, [screens, type]);
}
