import i18n from 'i18next';
import isEqual from 'lodash/isEqual';
import { initReactI18next } from 'react-i18next';

// néerlandais
import br from './br.json';
import de from './de.json';
// allemand
import en from './en.json';
// anglais
import es from './es.json';
// slovaque
import { stringFormatter } from './formatUtils';
import fr from './fr.json';
import it from './it.json';
// bresilien
import mo from './mo.json';
// espagnol
import nl from './nl.json';
// moldave
import pl from './pl.json';
// polonais
import pt from './pt.json';
// portugais
import ro from './ro.json';
// roumain
import sk from './sk.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: en,
        custom: window.__DATA__.strings,
      },
      fr: {
        common: fr,
        custom: window.__DATA__.strings,
      },
      de: {
        common: de,
        custom: window.__DATA__.strings,
      },
      es: {
        common: es,
        custom: window.__DATA__.strings,
      },
      it: {
        common: it,
        custom: window.__DATA__.strings,
      },
      nl: {
        common: nl,
        custom: window.__DATA__.strings,
      },
      br: {
        common: br,
        custom: window.__DATA__.strings,
      },
      mo: {
        common: mo,
        custom: window.__DATA__.strings,
      },
      pl: {
        common: pl,
        custom: window.__DATA__.strings,
      },
      pt: {
        common: pt,
        custom: window.__DATA__.strings,
      },
      ro: {
        common: ro,
        custom: window.__DATA__.strings,
      },
      sk: {
        common: sk,
        custom: window.__DATA__.strings,
      },
    },
    ns: ['common', 'custom'],
    defaultNS: 'custom',
    fallbackNS: 'common',
    fallbackLng: ['en'],
    lng: window.__DATA__.lang || 'en',

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: '|',

      // eslint-disable-next-line no-unused-vars
      format: stringFormatter,
    },
  });

export function updateCustomTranslations(language, translations) {
  if (
    language !== i18n.language ||
    !isEqual(i18n.getResourceBundle(language, 'custom'), translations)
  ) {
    // TODO: check that removing a key works
    i18n.addResourceBundle(language, 'custom', translations, true, true);
    i18n.changeLanguage(language); // Forces page refresh
  }
}

export const translateWithPrefix = (t, prefix) => (key) => t(`${prefix}.${key}`);

export default i18n;
