/* eslint-disable react/require-default-props */
import get from 'lodash/get';

import HtmlBlock from '../../components/cms/HtmlBlock';

type ItemHtmlBlockProps = {
  item: { _id: string } & Record<string, any>;
  field?: string;
  containerType?: string; // deprecated
  header?: string; // deprecated
  container?: {
    header?: string;
    type: string;
  };
};

const ItemHtmlBlock = ({
  item,
  field,
  header,
  container,
  containerType,
}: ItemHtmlBlockProps): JSX.Element | null => {
  if (!field) return null;

  const content = get(item, field);
  if (!content) return null;
  return (
    <HtmlBlock
      _id={item._id}
      title={container?.header || header}
      containerType={container?.type || containerType || 'segment'}
      content={content}
    />
  );
};

export default ItemHtmlBlock;
