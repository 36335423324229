/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { Icon } from 'semantic-ui-react';

type GroupCollapsibleProps = {
  config?: Record<string, unknown>;
  label: string;
  children: any;
} & typeof GroupCollapsibleDefaultProps;

const GroupCollapsibleDefaultProps = {
  config: {},
};

const GroupCollapsible = ({ config, label, children }: GroupCollapsibleProps): JSX.Element => {
  const { open = false } = config;
  const [isOpen, setIsOpen] = useState(open);
  return (
    <Collapsible
      {...config}
      trigger={
        <>
          {label}
          <Icon name={`chevron ${!isOpen ? 'down' : ('up' as any)}` as any} />
        </>
      }
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
    >
      {children}
    </Collapsible>
  );
};

GroupCollapsible.defaultProps = GroupCollapsibleDefaultProps;

export default GroupCollapsible;
