/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Icon, Message, Modal } from 'semantic-ui-react';

import { bem } from '../../../../core/design/bem';
import ResizableIframe from '../../../../iframe/ResizableIframe';
import { useMe } from '../../../../profile/hooks';
import store from '../../../../shared/Store';
import { fetchRegistrations } from '../../../../store/actions';
import { Workshop } from '../../../../types/workshop.types';
import { replaceValues } from '../../../../utils/stringUtils';
import { useWorkshopSessions } from '../../../../workshop-session/store/workshopSessions.hooks';
import { AddWorkshopToCalendar } from '../../../blocks/WorkshopAddToCalendarBlock';
import './IframeRegistration.scss';

const translationPrefix = 'workshops.workshop.modal.registration-iframe';
const css = bem('WorkshopIframeRegistration');

type IframeRegistrationProps = {
  allowUpdate?: boolean;
  autoResize?: boolean;
  height?: number;
  iframeProps?: Record<string, any>;
  session: Workshop;
  scrolling?: boolean;
  url: string;
  size?: 'small' | 'mini' | 'tiny' | 'large' | 'fullscreen' | undefined;
};

const IframeRegistration = (props: IframeRegistrationProps): JSX.Element | null => {
  const { session, url, iframeProps, height, autoResize, allowUpdate, size, scrolling } = props;
  const { _id: workshopId, collection, title } = session;
  const me = useMe();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const registrations = useSelector((state: any) => state.registrations.registrations);
  const registration = registrations.find((r: any) => r.workshopId === workshopId);
  const { data: sessions = [] } = useWorkshopSessions(collection, workshopId);
  if (!sessions.length) return null;
  const isRegistered = !!registration;
  if (!workshopId || !url) return null;
  const iframeUrl = `${replaceValues(url, { ...me, userId: me._id })}?workshopId=${workshopId}`;
  const handleClose = () => {
    store.reduxStore.dispatch(fetchRegistrations());
    setIsOpen(false);
  };
  return (
    <div className={css()}>
      {isRegistered ? (
        <div className={css('registered')}>
          <Message>
            <Message.Header>{t(`${translationPrefix}.user-already-registered`)}</Message.Header>
            <Message.Content>
              <div className="datetime">
                <Icon name="calendar alternate" />
                {t(`${translationPrefix}.session-datetime`, registration)}
              </div>
              <div className="actions">
                {allowUpdate && (
                  <Button primary className="update" onClick={() => setIsOpen(true)}>
                    <Icon name="pencil" />
                    {t(`${translationPrefix}.update-registration`)}
                  </Button>
                )}
                <AddWorkshopToCalendar workshop={registration} />
              </div>
            </Message.Content>
          </Message>
        </div>
      ) : (
        <Button primary className={css('register').toString()} onClick={() => setIsOpen(true)}>
          {t('btn.register')}
        </Button>
      )}
      {isOpen && (
        <Modal open onClose={handleClose} closeIcon size={size}>
          <Modal.Header>{session.title}</Modal.Header>
          <Modal.Content scrolling={scrolling}>
            <ResizableIframe
              // @ts-ignore
              className={css('iframe')}
              autoResize={autoResize}
              title={title}
              src={iframeUrl}
              height={height}
              style={{ border: 0, height, width: '100%' }}
              {...iframeProps}
            />
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
};

IframeRegistration.defaultProps = {
  allowUpdate: true,
  autoResize: true,
  height: 600,
  iframeProps: {},
  scrolling: false,
  size: undefined,
};

export default IframeRegistration;
