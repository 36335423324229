import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SponsorCard from './SponsorCard';
import { getString } from '../../utils';
import BlockHeader from '../BlockHeader';

const translationPrefix = 'blocks';

const SponsorsBlock = ({ sponsors, header }) => {
  if (!sponsors || sponsors.length === 0) return null;
  return (
    <>
      <BlockHeader title={header || getString(`${translationPrefix}.sponsors`)} />
      <Card.Group centered>
        {sponsors.map((sponsor) => (
          <SponsorCard key={sponsor._id} item={sponsor} />
        ))}
      </Card.Group>
    </>
  );
};
SponsorsBlock.propTypes = {
  sponsors: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect((state, props) => {
  const { collection = 'sponsors' } = props.dataSource;
  return {
    sponsors: (props.ids || [])
      .map((id) => state.users.collections[collection]?.usersById[id])
      .filter((v) => v),

    // sponsors: (props.ids || []).map((id) => state.sponsors.sponsorsById[id]).filter((v) => v),
  };
})(SponsorsBlock);
