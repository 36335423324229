import get from 'lodash/get';
import keys from 'lodash/keys';
import { useSelector } from 'react-redux';

import { useConfig } from '../../../config/config.context';
import { useMe } from '../../../profile/hooks';
import { generateWorkshopStatus } from '../../../utils/groupUtils';
import { useSyncedCollectionWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';
import {
  findPossibleRequiredSlots,
  formatWorkshops,
  useRequiredTimeSlots,
} from '../../../workshops/components/WorkshopTable/utils';

export function useUserHasRequireAgendaEntries(collection) {
  const user = useMe();
  const { timezones, defaultTimezone } = useConfig();
  const workshopList = useSyncedCollectionWorkshopSessions(collection);
  const registrations = useSelector((state) => state.registrations.registrations);
  const workshops = generateWorkshopStatus(workshopList);
  const userTimezone = get(user, 'timezone', defaultTimezone);
  const timezone = userTimezone || timezones?.[0]?.key || 'Europe/Paris';

  const { formattedWorkshops } = formatWorkshops(workshops, timezone);
  const requiredChoiceTimeSlots = useRequiredTimeSlots(formattedWorkshops);

  if (keys(requiredChoiceTimeSlots).length > 0) {
    const { hasRequiredSlots } = findPossibleRequiredSlots(requiredChoiceTimeSlots, registrations);
    return { hasRequiredSlots };
  }
  return { hasRequiredSlots: true };
}
