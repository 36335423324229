/* eslint-disable @typescript-eslint/no-empty-function */
import { Publication } from '../types/SocialWallV2.types';

export class MediaPageMeasurer {
  medias: Publication[];

  defaultWidth: number;

  defaultHeight: number;

  renderedKeys: Record<string, boolean> = {};

  constructor({ medias, columnWidth }: { medias: Publication[]; columnWidth: number }) {
    this.medias = medias;
    this.defaultWidth = columnWidth;
    this.defaultHeight = this.getHeight(0);
  }

  hasFixedWidth = (): boolean => false;

  hasFixedHeight = (): boolean => false;

  has = (rowIndex: number, _columnIndex: number): boolean => {
    const key = this.medias[rowIndex]._id;
    if (key in this.renderedKeys) {
      return true;
    }
    this.renderedKeys[key] = true;
    return false;
  };

  setColumnWidth = (columnWidth: number): void => {
    this.defaultWidth = columnWidth;
  };

  set = (_rowIndex: number, _columnIndex: number, _width: number, _height: number): void => {};

  getHeight = (index: number): number => {
    const media = this.medias[index];
    if (!media) return this.defaultWidth * 0.75;
    const { data } = media;
    const { width: imgWidth, height: imgHeight } = data.media!;
    const isPortrait = !!imgWidth && !!imgHeight && imgWidth >= imgHeight;
    const cardWidth = this.defaultWidth;
    return isPortrait ? cardWidth * 0.75 : cardWidth * 1.5 + 12;
  };

  getWidth = (): number => this.defaultWidth;
}
