import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const FormAutoSubmit = ({ uri, data, onSubmit }) => {
  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.submit();
    if (onSubmit) onSubmit();
  }, []);
  return (
    <form ref={formRef} action={uri} method="post" target="_blank">
      {Object.entries(data).map(([key, value]) => (
        <input key={key} type="hidden" value={value} name={key} />
      ))}
    </form>
  );
};

FormAutoSubmit.defaultProps = {
  onSubmit: undefined,
};

FormAutoSubmit.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  uri: PropTypes.string.isRequired,
};

export async function postDownload(uri, postData) {
  return new Promise((resolve) => {
    const node = document.createElement('div');
    document.body.appendChild(node);

    function handleSubmit() {
      ReactDOM.unmountComponentAtNode(node);
      document.body.removeChild(node);
      resolve();
    }
    ReactDOM.render(<FormAutoSubmit uri={uri} data={postData} onSubmit={handleSubmit} />, node);
  });
}
