import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Icon } from 'semantic-ui-react';
import { bem } from '../../core/design/bem';
import './ConnexionTestBlock.scss';

const block = bem('connexion-test-block');

const ConnexionTestBlock = () => {
  const { t } = useTranslation();
  return (
    <Card className={block.toString()}>
      <Card.Header>{t('appointments.test-connexion-title')}</Card.Header>
      <Card.Content>
        <Card.Description>{t('appointments.test-connexion-description')}</Card.Description>
        <Card.Description className="button">
          <Button
            primary
            size="tiny"
            style={{ width: '100%', marginTop: 4 }}
            target="_blank"
            href="https://utilities.clickmeeting.com/connection-tester"
          >
            <Icon name="microphone" />
            {t('appointments.test-my-connexion')}
          </Button>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

ConnexionTestBlock.propTypes = {};

export default ConnexionTestBlock;
