import React from 'react';
import PropTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';
import cx from 'classnames';

const NotFoundBlock = (props) => {
  const { className, header, icon, subheader } = props;
  return (
    <div
      className={cx('not-found', className)}
      style={{ margin: '64px auto', textAlign: 'center' }}
    >
      <Header as="h2" icon>
        <Icon name={icon} />
        {header}
        {subheader && <Header.Subheader>{subheader}</Header.Subheader>}
      </Header>
    </div>
  );
};

NotFoundBlock.defaultProps = {
  className: undefined,
  icon: 'search',
  subheader: undefined,
};
NotFoundBlock.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  icon: PropTypes.string,
  subheader: PropTypes.string,
};

export default NotFoundBlock;
