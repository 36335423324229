import { useSetState } from 'ahooks';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Header, Icon, Modal, TextArea } from 'semantic-ui-react';

import ImageUploader from '../../components/ImageUploader';
import SquareImage from '../../components/SquareImage';
import DeprecatedSocialWallService from '../../core/services/deprecated-social-wall.service';
import { useBackButton } from '../../hooks/useBackButton';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useMe } from '../../profile/hooks';
import { sweetConfirmAlert } from '../../utils/popupUtils';
import { showBlacklistError } from '../socialWallUtils';

function hasChanged(prevPost, newPost) {
  if (!prevPost) {
    const { image, message } = newPost;
    return image || !!message;
  }
  return !isEqual(pick(prevPost, ['image', 'message', 'location']), newPost);
}

async function putPost(user, post, prevPost) {
  if (prevPost) {
    return DeprecatedSocialWallService.patchMessage(post.id, post);
  }

  return DeprecatedSocialWallService.postMessage({
    ...post,
    userId: user._id,
    thumbnail: user.thumbnail,
    firstName: user.firstName,
    lastName: user.lastName,
  });
}

function NewSocialWallPostModal({ onClose, post: prevPost }) {
  const { t } = useTranslation();
  const { mobile } = useMediaQuery();
  const [post, updatePost] = useSetState(() => prevPost || {});
  const me = useMe() || {};
  const { firstName, lastName, thumbnail } = me;
  const onCancel = async () => {
    if (hasChanged(prevPost, post)) {
      const response = await sweetConfirmAlert({
        title: t('social-wall.cancel-without-posting-message'),
      });
      if (!response) return;
    }
    onClose();
  };
  useBackButton(onCancel);
  const onPost = async () => {
    const res = putPost(me, post, prevPost);
    if (res.errors) {
      showBlacklistError(res, post.message);
    } else onClose();
  };
  return (
    <Modal className="SociaWall--Modal" size="large" open>
      <Modal.Content scrolling className="SocialWall--Modal__Content">
        <div className="SocialWall--Modal__Content--Columns">
          <div className="SocialWall--Modal__Content--Image">
            <ImageUploader
              field="image"
              onChange={(image) => updatePost({ image })}
              placeholder={t('social-wall.upload-image', {
                context: mobile ? 'mobile' : undefined,
              })}
              size="big"
              style={{ height: '100%', width: '100%' }}
              value={post.image || undefined}
            />
          </div>
          <div className="SocialWall--Modal__Content--Message">
            <Header as="h4" className="user">
              <SquareImage src={thumbnail} imageSize={32} circular style={{ width: 32 }} />
              {firstName} {lastName}
            </Header>
            <Form style={{ flex: 1 }}>
              <TextArea
                className="message"
                placeholder={t('social-wall.add-content')}
                value={post.message || ''}
                onChange={(e) => updatePost({ message: e.target.value })}
              />
            </Form>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{t(`social-wall.cancel`)}</Button>
        <Button primary onClick={onPost} disabled={!post.image && !post.message}>
          <Icon name="send" />
          {t('social-wall.post-message')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

NewSocialWallPostModal.defaultProps = {
  post: undefined,
};

NewSocialWallPostModal.propTypes = {
  post: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default NewSocialWallPostModal;
