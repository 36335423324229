import cx from 'classnames';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { SemanticICONS } from 'semantic-ui-react';

import { ExtendableButton } from '../../components/ExtendableButton/ExtendableButton';
import { useIsScrolled } from '../../hooks/useIsScrolled';
import { useSocialWallConfig } from '../social-wall-config.context';

interface SocialWallV2ContentProps {
  className?: string;
  children: ReactElement;
  buttonOptions: {
    onClick?: () => void;
    color: string;
    label: string;
    icon: SemanticICONS;
    higher?: boolean;
  };
}

export const SocialWallFeedPageContent: FC<SocialWallV2ContentProps> = ({
  className,
  children,
  buttonOptions,
}) => {
  const { color, onClick, label, icon, higher } = buttonOptions;
  const [buttonIsOpen, setButtonIsOpen] = useState(true);

  const contentRef = useRef(null);
  const isScrolled = useIsScrolled(contentRef);

  useEffect(() => setButtonIsOpen(!isScrolled), [isScrolled]);

  const { publicationsEnabled } = useSocialWallConfig();

  return (
    <div ref={contentRef} className={className}>
      {children}
      {onClick && publicationsEnabled && (
        <ExtendableButton
          isOpen={buttonIsOpen}
          label={label}
          onClick={onClick}
          className={cx('social-wall-button', higher && 'social-wall-button__high')}
          color={color}
          icon={icon}
        />
      )}
    </div>
  );
};
