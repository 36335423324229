/* eslint-disable class-methods-use-this */
import { StoredData } from './WebStorage';

declare global {
  interface Window {
    mobileApp?: {
      version: string;
      event?: {
        eventId: string;
        userId: string;
        user: any;
        token: string;
        sessionId: string;
      };
    };
    mobileStorage?: MobileStorage;

    ReactNativeWebView?: {
      postMessage: (data: string) => void;
    };
  }
}

type EventMessage = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: Record<string, any>;
};

const wait = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

class MobileStorage {
  // eslint-disable-next-line no-useless-constructor
  constructor(private eventId: string) {
    window.mobileStorage = this;
  }

  getLocalStorageKey(): string {
    return `platform-${this.eventId}`;
  }

  postMessage(message: EventMessage): void {
    if (!window.ReactNativeWebView?.postMessage) {
      console.warn('Missing window.ReactNativeWebView?.postMessage');
      return;
    }
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  }

  removeItem(reason?: string): void {
    if (!window.ReactNativeWebView?.postMessage) {
      return;
    }
    this.postMessage({ type: 'storage.removeItem', payload: { reason } });
  }

  async waitForReady(): Promise<void> {
    for (let n = 0; n < 1000; n++) {
      if (window.mobileApp) return;
      // eslint-disable-next-line no-await-in-loop
      await wait(500);
    }
  }

  getRgpdRules(): StoredData | undefined {
    return undefined;
  }

  getItem(): StoredData | undefined {
    if (!window.mobileApp) {
      console.warn('missing mobileApp data');
      return undefined;
    }
    const { user, token, sessionId } = window.mobileApp?.event ?? {};
    // console.log('getItem', user, token, sessionId);
    return { user, token, sessionId };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setItem(value: StoredData): void {
    if (!window.ReactNativeWebView?.postMessage) {
      return;
    }
    this.postMessage({ type: 'storage.setItem', payload: value });
  }
}

export default MobileStorage;
