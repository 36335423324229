import { useMedia } from 'react-use';

import { GLOBAL_MEDIA_QUERIES } from '../utils/mediaQueries';

export const useMediaQuery = (): { desktop: boolean; mobile: boolean } => {
  const isMobile = useMedia(GLOBAL_MEDIA_QUERIES.mobile);
  return { desktop: !isMobile, mobile: isMobile };
};

export const useIsMobile = (): boolean => {
  return useMedia(GLOBAL_MEDIA_QUERIES.mobile);
};

export const useIsDesktop = (): boolean => {
  return useMedia(GLOBAL_MEDIA_QUERIES.desktop);
};
