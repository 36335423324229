import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

interface UseEmotes {
  formattedInteractions: string[];
}

export const useFormattedEmotes = (
  selectedEmotes: Record<string, number> = {},
  defaultEmotes: string[] = [],
  options: {
    displayDefaultEmotes?: boolean;
    displayAllEmotes?: boolean;
    maxDisplayed: number;
  },
): UseEmotes => {
  const { displayDefaultEmotes, displayAllEmotes, maxDisplayed } = options || {};

  const formattedInteractions = useMemo(() => {
    let displayedEmotes: string[] = [];

    const zeroFiltered = Object.keys(selectedEmotes).filter((emote) => selectedEmotes[emote] > 0);

    // only adds default emote if there's less emote than maxDisplayed
    let emotesWithDefault = [];
    if (zeroFiltered?.length >= maxDisplayed) {
      emotesWithDefault = zeroFiltered;
    } else {
      const emptySlots = maxDisplayed - zeroFiltered?.length;
      const defaultNotIn = defaultEmotes.filter((e) => !zeroFiltered.includes(e));
      emotesWithDefault = [...defaultNotIn.slice(0, emptySlots), ...zeroFiltered];
    }

    if (displayDefaultEmotes) {
      displayedEmotes = displayAllEmotes ? emotesWithDefault : defaultEmotes;
    } else if (displayAllEmotes) {
      displayedEmotes = zeroFiltered;
    }
    return orderBy(displayedEmotes, (e) => selectedEmotes[e] ?? 0, 'desc');
  }, [selectedEmotes, defaultEmotes, displayAllEmotes, displayDefaultEmotes, maxDisplayed]);

  return { formattedInteractions };
};
