import { SyntheticEvent } from 'react';

export const eventHandler = <T extends SyntheticEvent, K = void>(
  e: T,
  callback: (params: T) => K,
): void => {
  e.stopPropagation();
  e.preventDefault();
  callback(e);
};
