/* eslint-disable operator-linebreak */
import { useBoolean } from 'ahooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'semantic-ui-react';

import MenuFilters from './MenuFilters';

const FilterModal = (props) => {
  const { hideLabel, className, ...filterProps } = props;
  const [open, { setFalse: setClosed, setTrue: setOpened }] = useBoolean(false);
  const { t } = useTranslation();
  return (
    <Modal
      className="filter--modal"
      onClose={setClosed}
      onOpen={setOpened}
      open={open}
      trigger={
        <Button
          className={`filters--button ${hideLabel ? 'icon' : undefined} ${className || ''}`}
          style={
            hideLabel
              ? undefined
              : {
                  width: '100%',
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }
          }
        >
          <Icon name="filter" />
          {!hideLabel && (
            <p className="label" style={{ marginLeft: 10 }}>
              {t('filters.label')}
            </p>
          )}
        </Button>
      }
    >
      <Modal.Content style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <MenuFilters {...filterProps} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t('btn.validate')}
          labelPosition="right"
          icon="checkmark"
          onClick={setClosed}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

FilterModal.defaultProps = {
  className: undefined,
  hideLabel: false,
};

FilterModal.propTypes = {
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
};

export default FilterModal;
