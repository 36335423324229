import React, { FC } from 'react';
import { Button, Form } from 'semantic-ui-react';

type Option = {
  key: string;
  text: string;
  value: string;
};

type SelectFieldProps = {
  dataKey: string;
  displayMode: string;
  label: string;
  options: Option[];
  selected: Record<string, string>;
  onClick: () => void;
};

const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const { dataKey, label, options, displayMode, selected, onClick } = props;

  return (
    <Form.Field key={dataKey}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>{label}</label>
      <div>
        {displayMode === 'buttons' &&
          options.map((option) => {
            const { key, value, text } = option;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const isActive = (selected[dataKey] || [])?.includes(value);
            return (
              <Button
                key={key}
                basic={!isActive}
                content={text}
                value={value}
                name={dataKey}
                type="button"
                primary={isActive}
                onClick={onClick}
              />
            );
          })}
      </div>
    </Form.Field>
  );
};

SelectField.defaultProps = {};

export default SelectField;
