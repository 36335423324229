import React, { FC } from 'react';
import { Workshop } from '../../../../types/workshop.types';
import { WorkshopActions } from '../../workshop-templates/actions/WorkshopActions';

type AccordionActionsProps = {
  workshop: Workshop;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemProps?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>;

const AccordionActions: FC<AccordionActionsProps> = (props: AccordionActionsProps) => {
  const { workshop, itemProps } = props;
  return <WorkshopActions workshop={workshop} actions={itemProps.actions} itemProps={itemProps} />;
};

AccordionActions.defaultProps = {
  itemProps: {},
};

export { AccordionActions };
