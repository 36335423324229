/* eslint-disable react/prop-types */
import get from 'lodash/get';
import React from 'react';
import CTAPagesBlock from './CTAPagesBlock/CTAPagesBlock';

const ItemCTAPagesBlock = ({ item, field, ...props }) => {
  const blocks = get(item, field);
  return <CTAPagesBlock blocks={blocks} {...props} />;
};

export default ItemCTAPagesBlock;
