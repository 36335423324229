/*eslint-disable operator-linebreak*/
import {
  addMinutes,
  formatDistance,
  isAfter,
  isBefore,
  isToday,
  isTomorrow,
  parseISO,
  subMinutes,
} from 'date-fns';
import padStart from 'lodash/padStart';
import moment, { Moment } from 'moment';
import { TFunction } from 'react-i18next';

import { debugNowTime } from '../hooks/useAutoRefresh';
import i18n from '../translations/i18n';
import { dateFNSLocale } from './date';

interface Dates {
  startDate: string;
  endDate: string;
}
interface DateOptions {
  minutesBefore: number;
  minutesAfter: number;
}

export function isNow({ startDate, endDate }: Dates, options: DateOptions): boolean {
  const { minutesBefore = 5, minutesAfter = 5 } = options || {};
  const now = new Date();

  return (
    isBefore(parseISO(startDate), addMinutes(now, minutesBefore)) &&
    isAfter(parseISO(endDate), subMinutes(now, minutesAfter))
  );
}

export function isUpcoming({ startDate }: { startDate: string }): boolean {
  const now = new Date();
  return isAfter(parseISO(startDate), now);
}

export function isFinished({ endDate }: { endDate: string }): boolean {
  const now = new Date();
  return isBefore(parseISO(endDate), now);
}

export function startsToday({ startDate }: { startDate: string }): boolean {
  return isToday(parseISO(startDate));
}

export function getWorkshopStatus(workshop: Dates, options: DateOptions): string {
  if (isFinished({ endDate: workshop.endDate })) return 'finished';
  if (isNow(workshop, options)) return 'now-soon';
  if (isTomorrow(parseISO(workshop.startDate))) return 'tomorrow';
  if (isUpcoming(workshop)) return 'upcoming';
  return 'upcoming';
}

export function fromNow(date: string): string | null {
  const now = debugNowTime || new Date();
  if (!date) return null;
  return formatDistance(parseISO(date), now, {
    addSuffix: true,
    locale: dateFNSLocale(),
  });
}

export function formatTimezoneDate(date: string, timezone: string): Moment | null {
  if (!date) return null;
  if (timezone) return moment(parseISO(date)).tz(timezone);
  return moment(parseISO(date));
}

export function durationInMinutes(startDate: string, endDate: string): number {
  return Math.floor((parseISO(endDate).getTime() - parseISO(startDate).getTime()) / (60 * 1000));
}

export function roundMinutesToQuarter(minutes: number): number {
  return Math.round(minutes / 15) * 15;
}

function formatDurationMinutes(minutes: number, t: TFunction<'translation'>) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes - hours * 60;
  return t('dates.duration', { hours, minutes: padStart(minutesLeft.toString(), 2, '0') });
}

export function formatDateRangeDuration(
  startDate: string,
  endDate: string,
  t: TFunction<'translation'>,
): string | null {
  if (!startDate || !endDate) return null;
  const minutes = durationInMinutes(startDate, endDate);
  return formatDurationMinutes(minutes, t);
}

export const formatDateAgo = (
  date: Date | string,
  t: TFunction<'translation'>,
  long?: boolean,
): string | null => {
  const currentDate = moment();
  const formattedDate = moment(date);

  const diffMin = { key: 'min', value: currentDate.diff(formattedDate, 'm') };
  const diffHours = { key: 'hour', value: currentDate.diff(formattedDate, 'h') };
  const diffDays = { key: 'day', value: currentDate.diff(formattedDate, 'd') };
  const diffMonth = { key: 'month', value: currentDate.diff(formattedDate, 'M') };
  const diffYear = { key: 'year', value: currentDate.diff(formattedDate, 'y') };

  const timeDiff = [diffYear, diffMonth, diffDays, diffHours, diffMin];

  const relativeTime = timeDiff.find((diff) => diff.value > 0);

  return relativeTime
    ? t(`common.${long ? 'long-' : ''}relative-time.${relativeTime.key}`, {
        count: relativeTime.value,
      })
    : t('common.relative-time.min', { count: 1 });
};
