import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { stringify } from 'querystring';
import platformService from './platform.service';

const { endpoints } = window.__DATA__;

type User = { _id: string; firstName?: string; lastName?: string };

export type VisioSession = {
  sessionId: string;
};

class MeetingRoomService {
  // eslint-disable-next-line class-methods-use-this
  api(path: string): string {
    return `${endpoints.meetings || '/meet'}${path}`;
  }

  async createVisioRoom(user: User) {
    return platformService.securePost(this.api('/von/visio-room'), {
      user,
    });
  }

  getVisioRoomUrl(session: VisioSession, user: User): string {
    const params = omitBy(
      {
        userId: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      isUndefined,
    );
    return this.api(`/von/visio-room/${session.sessionId}?${stringify(params)}`);
  }
}

export default new MeetingRoomService();
