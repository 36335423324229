/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable operator-linebreak */
/* eslint-disable react/require-default-props */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css as applyCss, jsx } from '@emotion/react';
import React, { useMemo } from 'react';
import { useDesignConfig } from '../../../../config/design.context';
import { bem } from '../../../../core/design/bem';
import ItemComponent, { ItemConfig } from '../components/ItemComponent';
import { computeShadow } from '../styles';
import './CircularIconVariant.scss';
import { IconGridVariantProps } from './variant.types';

const listCss = bem('IconGridItems');

// type Options = {
//   itemsPerRow: number;
//   border: string;
// };

function computeItemStyles(className: string, id: string, styles: Record<string, any>) {
  return { [`.IconGridItem[data-id="${id}"]`]: styles };
}

const CircularIconVariant = (
  props: IconGridVariantProps & { itemConfig?: ItemConfig },
): JSX.Element => {
  const design = useDesignConfig();
  const { variant, items, itemConfig } = props;

  const emotionCss = useMemo(() => {
    const { primaryColor } = design;
    const { gridGap = 8, itemsPerRow = 3, shadow, cssStyle, css: cssCustom } = itemConfig || {};

    const itemStyles = items.map((item) =>
      computeItemStyles('.IconGridItem', item._id, {
        '.IconGridItem__Icon': {
          backgroundColor: item.design?.color,
        },
      }),
    );

    return applyCss(
      cssCustom,
      cssStyle,
      {
        '&.IconGridItems': {
          'grid-template-columns': `repeat(${itemsPerRow}, 1fr)`,
          gridGap,
        },
        '.IconGridItem__Icon': {
          backgroundColor: primaryColor,
          width: 240 / itemsPerRow,
          height: 240 / itemsPerRow,
          fontSize: `${9 / itemsPerRow}em`,
          boxShadow: computeShadow(shadow),
        },
        '.IconGridItem__Title': {
          fontSize: `${1.25 - itemsPerRow * 0.1}em`,
        },
      },
      ...itemStyles,
    );
  }, [design, itemConfig, items]);

  const forcedItemIcons = useMemo(() => items.map((i) => ({ ...i, icon: i.icon || ' ' })), [items]);

  return (
    <div css={emotionCss} className={listCss({ variant })}>
      {forcedItemIcons.map((item) => (
        <ItemComponent
          key={item._id}
          variant={variant}
          item={item}
          itemConfig={{ ...itemConfig, showBackground: false, showIcon: true }}
        />
      ))}
    </div>
  );
};

CircularIconVariant.defaultProps = {
  itemConfig: {},
};

export { CircularIconVariant };
