import { useMount } from 'ahooks';
import { parseISO } from 'date-fns';
import { useState } from 'react';
import { getParameterValueByName } from '../utils';

const debugNow = getParameterValueByName('debugNow');
export const debugNowTime = debugNow ? parseISO(debugNow).getTime() : undefined;

export function useAutoRefresh(milliseconds: number): number {
  const [now, setNow] = useState(Date.now());

  useMount(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, milliseconds);
    return () => clearInterval(interval);
  });
  return debugNowTime || now;
}
