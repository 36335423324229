/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import matches from 'lodash/matches';
import values from 'lodash/values';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Icon } from 'semantic-ui-react';

import { DataItem } from '../../../core-data/types';
import { useDataCollection } from '../../../core/data/data.hooks';
import { bem } from '../../../core/design/bem';
import { useMe } from '../../../profile/hooks';
import store from '../../../shared/Store';
import { OrderBy, useOrderBy } from '../../../utils/hooks';
import { replaceValues } from '../../../utils/stringUtils';
import './DataVote.scss';

const translationPrefix = 'blocks.data-votes';
const css = bem('DataVoteBlock');

type DataVoteProps = {
  collection: string;
  description?: string;
  filter: Record<string, any>;
  nameTemplate?: string;
  title: string;
  orderBy?: OrderBy[];
};

function useUserVoteForCategory(collection: string, filter: Record<string, any>) {
  const me = useMe();
  return useMemo(() => {
    const voteKey = `${collection}-votes`;
    const userVotes = get(me, voteKey);
    const field = values(filter)[0];
    const value = get(userVotes, field);
    const hasValue = !!value;
    return { field, voteKey, userVotes, value, hasValue };
  }, [me, collection, filter]);
}

const DataVoteBlock = ({
  collection,
  filter,
  title,
  nameTemplate,
  description,
  orderBy,
}: DataVoteProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { field, voteKey, userVotes, value, hasValue } = useUserVoteForCategory(collection, filter);
  const [selectedId, setSelectedId] = useState<string | undefined>(value);

  useEffect(() => {
    if (!value) return;
    setSelectedId(value);
  }, [value]);

  const items = useDataCollection(collection).filter(matches(filter)) as DataItem[];
  const orederedItems = useOrderBy(items, orderBy);
  if (!items.length) return null;

  const handleSelect = (itemId: string, isSelected: boolean) => {
    if (hasValue) return undefined;
    if (isSelected) return setSelectedId(undefined);
    return setSelectedId(itemId);
  };

  const handleVote = async (vote: Record<string, any>) => {
    await store.updateUser({
      [voteKey]: { ...userVotes, ...vote },
      updatedByUserAt: moment().toISOString(),
    });
  };

  return (
    <Card className={css('group').has({ value: hasValue }).toString()}>
      <Card.Content>
        <Card.Header as="h2">{title}</Card.Header>
        {description && <Card.Description className="subtitle">{description}</Card.Description>}
      </Card.Content>
      <Card.Content>
        {t(`${translationPrefix}.${!value ? 'vote-for-item' : 'already-voted'}`)}
        {orederedItems?.map((item) => {
          const { _id: itemId } = item;
          const name = replaceValues(nameTemplate, item);
          const isSelected = selectedId === itemId;
          return (
            <div
              key={itemId}
              className={css('item').is({ selected: isSelected })}
              onClick={() => handleSelect(itemId, isSelected)}
            >
              {name}
              {hasValue && isSelected && <Icon name="check" className="checked" />}
            </div>
          );
        })}
      </Card.Content>
      {!value && (
        <div className={css('vote')}>
          <Button
            disabled={!selectedId}
            onClick={() => handleVote({ [field]: selectedId })}
            primary
          >
            {t(`${translationPrefix}.vote`)}
          </Button>
        </div>
      )}
    </Card>
  );
};

DataVoteBlock.defaultProps = {
  description: undefined,
  nameTemplate: '{name}',
  orderBy: [{ field: 'name', order: 'asc' }],
};

export default DataVoteBlock;
