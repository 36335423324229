import wretch, { Wretcher } from 'wretch';

import store from '../../shared/Store';
import { UploadedVideo, VideoStatus } from '../../social-wall-v2/types/SocialWallV2.types';

export const uploadApiVideo = (file: File): Promise<UploadedVideo> => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    const url = `${window.__DATA__.endpoints.platform}/social-wall-v2/posts/videos`;
    const req = new XMLHttpRequest();
    req.open('POST', url);
    req.setRequestHeader('Authorization', `JWT ${store.token}`);

    req.addEventListener(
      'load',
      () => {
        if (req.status >= 200 && req.status <= 299) {
          resolve(JSON.parse(req.responseText));
        } else {
          reject(req.status);
        }
      },
      false,
    );

    req.addEventListener('error', (e) => {
      console.error('upload error', e);
      reject(e);
    });

    req.send(formData);
  });
};

class ApiVideoService {
  api(): Wretcher {
    return wretch().url(window.__DATA__.endpoints.files).url('/v1').auth(`JWT ${store.token}`);
  }

  private getApiVideoRef(): Wretcher {
    return this.api().url(`/${store.eventId}/videos`);
  }

  async getVideos(): Promise<UploadedVideo[]> {
    return this.getApiVideoRef().get().json();
  }

  async uploadVideo(file: File): Promise<UploadedVideo> {
    return uploadApiVideo(file);
  }

  async uploadVideoUrl(source: File): Promise<UploadedVideo> {
    return this.getApiVideoRef().post({ source }).json();
  }

  async checkUploadStatus(videoId: string): Promise<VideoStatus> {
    return this.getApiVideoRef().url(`/uploadStatus/${videoId}`).get().json();
  }
}

export default new ApiVideoService();
