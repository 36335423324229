/* eslint-disable @typescript-eslint/no-explicit-any */
import useUrlState from '@ahooksjs/use-url-state';
import keyBy from 'lodash/keyBy';
import { FC } from 'react';
import { Menu, SemanticWIDTHS } from 'semantic-ui-react';

import { Workshop, WorkshopSession } from '../../../../../types/workshop.types';
import './TabList.scss';
import TabDate from './components/TabDate';
import TabDay from './components/TabDay';

type ListTemplate = {
  variant?: string;
  minHeight?: number;
};

type Group = {
  id: string;
  label: string;
  items: Workshop[] | WorkshopSession[];
};

type TabListsProps = {
  groups?: Group[];
  groupByConfig: { type: string; subGroupBy?: string };
  template?: ListTemplate;
  filteredWorkshopIds?: string[];
  itemProps: any;
  iconConfig: any;
};

const TabList: FC<TabListsProps> = (props) => {
  const { groups = [], filteredWorkshopIds, itemProps, template, groupByConfig } = props;
  const [urlState, setUrlState] = useUrlState({ tab: groups[0]?.id }, { navigateMode: 'replace' });
  const { tab } = urlState;

  const groupsById = keyBy(groups, 'id');
  const activeGroup = groupsById[tab];

  return (
    <div className="workshops--container workshops--container--tabs">
      <Menu pointing secondary fluid widths={groups.length as SemanticWIDTHS}>
        {groups.map(({ id, label }) => (
          <Menu.Item key={id} active={id === tab} onClick={() => setUrlState({ tab: id })}>
            {groupByConfig?.type === 'date' ? <TabDate value={id} /> : label}
          </Menu.Item>
        ))}
      </Menu>
      {!!activeGroup && (
        <TabDay
          id={activeGroup.id}
          items={activeGroup.items}
          filteredWorkshopIds={filteredWorkshopIds}
          template={template}
          itemProps={itemProps}
          groupBy={groupByConfig.subGroupBy}
        />
      )}
    </div>
  );
};

TabList.defaultProps = {
  itemProps: undefined,
  filteredWorkshopIds: undefined,
  template: { variant: 'card' },
  groups: [],
};

export default TabList;
