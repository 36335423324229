import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import { useTracking } from '../../../../Context';
import { eventTags } from '../../../../core/trackers/events';
import Store from '../../../../shared/Store';
import { replaceValues } from '../../../../utils/stringUtils';
import { useRegistrationsById } from '../../../../workshops/utils/workshopRegistrationUtils';

type WorkshopLiveButtonProps = {
  workshop: Record<string, any>;
} & Record<string, any>;

const translationPrefix = 'workshops.workshop';
const WorkshopLiveButton: FC<WorkshopLiveButtonProps> = (props) => {
  const { workshop, target, ...rest } = props;
  const { t } = useTranslation();
  const { _id, collection, workshopId, mandatory } = workshop;
  const { trackEvent } = useTracking();

  const registrations = useRegistrationsById(!!workshopId);
  const userRegistration = registrations[workshopId] || registrations[_id];

  const liveStreams = userRegistration?.liveStreams || workshop.liveStreams;
  const firstLive =
    liveStreams && liveStreams.length > 0 && liveStreams?.find((live: any) => !!live.uri)?.uri;

  const uri = replaceValues(firstLive, Store.user);

  function handleClick() {
    trackEvent(eventTags.WEBINAR_ENTER, {
      entityType: collection,
      entityCollection: collection,
      entityId: _id,
      workshopId: workshopId || _id,
      workshopSessionId: workshopId ? _id : undefined,
      roomUri: uri,
    });
    trackEvent(eventTags.WORKSHOP_CLICK_LIVE, {
      userId: Store.userId,
      workshop,
    });
  }

  if (!firstLive || (!mandatory && !userRegistration)) {
    return null;
  }

  return (
    <Button
      as="a"
      href={uri}
      target={target || '_blank'}
      primary
      icon="play"
      content={t(`${translationPrefix}.btn.live`)}
      style={{ width: '100%', marginTop: 15 }}
      onClick={handleClick}
      {...rest}
    />
  );
};

WorkshopLiveButton.defaultProps = {
  target: '_blank',
};

WorkshopLiveButton.propTypes = {
  workshop: PropTypes.object.isRequired,
  target: PropTypes.string,
};

export { WorkshopLiveButton };
