import cx from 'classnames';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

import { CmsPageLayout } from '../../cms/CmsPage';
import Speakers from '../Speakers';

function SpeakersPage(props) {
  const { pageId, design, header, pageFooter, pageHeader, metadata, ...rest } = props;
  return (
    <CmsPageLayout
      className={cx('page', 'page--speakers', pageId)}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <Speakers {...rest} pageId={pageId} />
      </Container>
    </CmsPageLayout>
  );
}

SpeakersPage.defaultProps = {
  pageHeader: undefined,
  design: {},
  header: {},
  metadata: undefined,
  pageFooter: undefined,
  pageId: 'speakers',
};

SpeakersPage.propTypes = {
  pageHeader: PropTypes.object,
  design: PropTypes.object,
  header: PropTypes.object,
  metadata: PropTypes.object,
  pageFooter: PropTypes.object,
  pageId: PropTypes.string,
};

export default SpeakersPage;
