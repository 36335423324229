import moment from 'moment';
import React, { FC } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import QuotaField from '../../components/workshops/QuotaField';
import { WorkshopSession } from '../../types/workshop.types';
import { getString } from '../../utils';
import './SessionRegistrationButton.scss';

type SessionRegistrationButtonProps = {
  session: WorkshopSession;
  handleClick: (s: WorkshopSession) => void;
};

const SessionRegistrationButton: FC<SessionRegistrationButtonProps> = (
  props: SessionRegistrationButtonProps,
) => {
  const { session, handleClick } = props;
  const isSessionFull = session.quota - session.usersCount <= 0;

  const handleRegistration = async () => {
    handleClick(session);
  };

  return (
    <Button
      className="registration-button"
      primary
      onClick={() => handleRegistration()}
      disabled={isSessionFull}
    >
      <div className="registration-button__hour">{moment(session.startDate).format('HH:mm')}</div>
      <>
        <div className="registration-button__infos">
          <div>
            <Icon name="clock" />
            {moment
              .duration(
                moment(session.endDate, 'YYYY-MM-DD HH:mm').diff(
                  moment(session.startDate, 'YYYY-MM-DD HH:mm'),
                ),
              )
              .asMinutes()}
            min
          </div>
          {session.quota && (
            <div>
              <Icon name="user" />
              {Math.max(session.quota, session.quota - session.usersCount)} / {session.quota} places
            </div>
          )}
        </div>
        <div className="registration-button__quota">
          {isSessionFull ? (
            <div className="registration-button__full">
              {getString(`workshops.workshop.workshop-full`)}
            </div>
          ) : (
            <QuotaField quota={session.quota} usersCount={session.usersCount} />
          )}
        </div>
      </>
    </Button>
  );
};

export default SessionRegistrationButton;
