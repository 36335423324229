import cx from 'classnames';
import React, { FC } from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import './ExtendableButton.scss';

interface ExtendableButtonProps {
  label: string;
  isOpen: boolean;
  onClick: () => any;
  className?: string;
  textColor?: string;
  color?: string;
  size?: string;
  icon?: SemanticICONS;
}

export const ExtendableButton: FC<ExtendableButtonProps> = (props: ExtendableButtonProps) => {
  const {
    label,
    isOpen,
    onClick,
    className,
    textColor = 'white',
    color,
    size = '40px',
    icon,
  } = props;
  return (
    <button
      type="button"
      className={cx(className, 'extendable-button', isOpen && 'extendable-button__open')}
      onClick={onClick}
      style={{ backgroundColor: color, color: textColor, minHeight: size, minWidth: size }}
    >
      {icon && <Icon name={icon} />}
      <div className="extendable-button-label">{label}</div>
    </button>
  );
};
