import memoizeOne from 'memoize-one';

import { ConditionContext } from '../../../../utils/conditionUtils';
import { WorkshopAction } from './action.type';

const rawComputeActions = (config?: Record<string, undefined>): WorkshopAction[] => {
  const {
    showActions = true,
    showDetails = true,
    showReplay = true,
    showEventStatus = true,
    showAnimatorAccessButton = true,
    actions = [],
  } = config || {};

  if (actions.length) {
    return actions;
  }

  const computedActions = [];

  if (showAnimatorAccessButton) {
    // TODO: move condition to config, done here temporarily to avoid crashes in preloaded apps
    computedActions.push({
      _id: 'animator-access',
      type: 'animator-access',
      condition: {
        op: 'isAnyOf',
        key: 'category',
        value: ['BBB', 'BBD', 'BBL'],
      } as ConditionContext,
    });
  }

  if (showActions) {
    computedActions.push({ _id: 'registration', type: 'registration' });
  }

  if (showReplay) {
    computedActions.push({ _id: 'replay', type: 'replay' });
  }

  if (showEventStatus) {
    computedActions.push({ _id: 'event-status', type: 'event-status' });
  }

  if (showDetails) {
    computedActions.push(
      { _id: 'see-more', type: 'see-more' },
      { _id: 'see-more-live', type: 'see-more-live' },
    );
  }

  return computedActions;
};

export const computeActions = memoizeOne(rawComputeActions);
