import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDesignConfig } from '../../config/design.context';

export function useLayout() {
  const { layout } = useDesignConfig();
  if (typeof layout === 'string') {
    return { type: layout };
  }
  if (typeof layout === 'object') return layout;
  return {
    type: 'default',
  };
}

export function useIsFirstLoad() {
  const { pathname } = useLocation();
  const initialPath = useRef(pathname);

  if (initialPath.current && pathname !== initialPath.current) {
    initialPath.current = null;
  }

  return !!initialPath.current;
}
