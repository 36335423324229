/* eslint-disable prettier/prettier */
import { CmsBlocks } from '../../cms/CmsScreen';
import BrowserCheckBlock from '../../components/cms/BrowserCheckBlock';
import HtmlBlock from '../../components/cms/HtmlBlock';
import ImageBlock from '../../components/cms/ImageBlock';
import ItemCTATilesBlock from '../../components/cms/ItemCTATilesBlock';
import ItemAddressBlock from '../../exhibitors/blocks/ItemAddressBlock';
import CTABlock from '../../sidebar/blocks/CTABlock';
import ContactsBlock from '../../sidebar/blocks/ContactsBlock';
import FilesBlock from '../../sidebar/blocks/FilesBlock';
import WorkshopActionsBlock from '../../sidebar/blocks/WorkshopActionsBlock';
import ChildWorkshopsBlock from './ChildWorkshopsBlock';
import CollectionAndCategoryWorkshops from './CollectionAndCategoryWorkshops';
import FeedbackPopupBlock from './FeedbackPopupBlock';
import ItemCmsBlock from './ItemCmsBlock';
import ItemCountdownBlock from './ItemCountdownBlock';
import ItemGalleryBlock from './ItemGalleryBlock';
import ItemHtmlBlock from './ItemHtmlBlock';
import ItemImageBlock from './ItemImageBlock';
import ItemTextBlock from './ItemTextBlock';
import NextSessionBlock from './NextSessionBlock';
import WorkshopReplayBlock from './ReplayBlock';
import { SuggestedWorkshops } from './SuggestedWorkshops/SuggestedWorkshops';
import WorkshopBlogsBlock from './WorkshopBlogsBlock';
import WorkshopCommentsBlock from './WorkshopCommentsBlock';
import WorkshopDetailsBlock from './WorkshopDetailsBlock';
import WorkshopFeebdackBlock from './WorkshopFeedbackBlock';
import WorkshopLiveBlock from './WorkshopLiveBlock';
import WorkshopQuestionsBlock from './WorkshopQuestionsBlock';
import WorkshopRegistrationBlock from './WorkshopRegistrationBlock/WorkshopRegistrationBlock';
import WorkshopSeeAlsoBlock from './WorkshopSeeAlsoBlock';
import WorkshopSessionsRegistrationBlock from './WorkshopSessionsRegistrationBlock';
import WorkshopSpeakersBlock from './WorkshopSpeakersBlock';
import WorkshopSponsorsBlock from './WorkshopSponsorsBlock';
import WorkshopVisioRoomBlock from './WorkshopVisioBlock';

export const mainBlockComponents = {
  'browserCheck': BrowserCheckBlock,
  'details': WorkshopDetailsBlock,
  'html': ItemHtmlBlock,
  'cms/html': HtmlBlock,
  'countdown': ItemCountdownBlock,
  'gallery': ItemGalleryBlock,
  'image': ItemImageBlock,
  'live': WorkshopLiveBlock,
  'replay': WorkshopReplayBlock,
  'speakers': WorkshopSpeakersBlock,
  'sponsors': WorkshopSponsorsBlock,
  'feedbackPopup': FeedbackPopupBlock,
  'comments': WorkshopCommentsBlock,
  'feedbacks': WorkshopFeebdackBlock,
  'blogs': WorkshopBlogsBlock,
  'nextSession': NextSessionBlock,
  'childWorkshops': ChildWorkshopsBlock,
  'cms/image': ImageBlock,
  'item/address': ItemAddressBlock,
  'item/ctaTiles': ItemCTATilesBlock,
  'item/cms': ItemCmsBlock,
  'item/text': ItemTextBlock,
  'item/html': ItemHtmlBlock,
  'collectionAndCategoryWorkshops': CollectionAndCategoryWorkshops,
};

export const sidebarBlockComponents = {
  'details': WorkshopDetailsBlock,
  'speakers': WorkshopSpeakersBlock,
  'actionsBlock': WorkshopActionsBlock,
  'registrationBlock': WorkshopRegistrationBlock,
  'sessionsRegistration': WorkshopSessionsRegistrationBlock,
  'files': FilesBlock,
  'seeAlso': WorkshopSeeAlsoBlock,
  'cms': CmsBlocks,
  'questions': WorkshopQuestionsBlock,
  'visioRoom': WorkshopVisioRoomBlock,
  'collectionAndCategoryWorkshops': CollectionAndCategoryWorkshops,
  'feedbacks': WorkshopFeebdackBlock,
  'nextSession': NextSessionBlock,
  'sponsors': WorkshopSponsorsBlock,
  'cta': CTABlock,
  'contacts': ContactsBlock,
  'suggestedWorkshops': SuggestedWorkshops,
  'item/text': ItemTextBlock,
  'item/html': ItemHtmlBlock,
  'item/address': ItemAddressBlock,
};
