import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { workshopProptypes } from '../../propTypes';
import IframeBlock from '../../components/cms/IframeBlock';

function WorkshopQuestionsBlock(props) {
  const { item: workshop, field } = props;
  const liveQuestions = get(workshop, field);
  const hasQuestions = liveQuestions?.length > 0;
  if (!hasQuestions) return null;
  const liveQuestion = liveQuestions[0];
  return <IframeBlock {...props} src={liveQuestion?.uri} />;
}

WorkshopQuestionsBlock.defaultProps = {
  field: 'liveQuestions',
};

WorkshopQuestionsBlock.propTypes = {
  field: PropTypes.string,
  item: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopQuestionsBlock;
