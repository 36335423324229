import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { userAgenda } from '../../../../agenda/store/agenda.selectors';
import { TimeSlot } from '../types';
import { checkUserAvailability } from '../../../../utils/agendaUtils';
import { StepContainer } from '../../Step';
import { localeFormat } from '../../../../utils/date';
import { getString } from '../../../../utils';

const translationPrefix = 'appointments.steps.select-slot';
const t = (key: string, data?: any) => getString(`${translationPrefix}.${key}`, data);

type StepSlotProps = {
  active: boolean;
  finish: boolean;
  freeTimeSlots: TimeSlot[];
  onSelectStep: (nextStep: string, { slot }: { slot: TimeSlot }) => void;
  orderNumber: string;
  setStep: (selection: string) => void;
};

const StepSlot = (props: StepSlotProps): JSX.Element | null => {
  const { active, finish, freeTimeSlots, onSelectStep, orderNumber, setStep } = props;
  const agenda = useSelector(userAgenda);
  const nextSlot = freeTimeSlots.find((slot) => {
    const otherEvents = agenda.filter((evt) => evt.slotId !== slot._id);
    return checkUserAvailability(otherEvents, slot);
  });
  if (finish) {
    return null;
  }
  if (!active) {
    return (
      <StepContainer label={orderNumber}>
        <Button className="link" onClick={() => setStep('select-slot')}>
          {t('sidebar-label')}
        </Button>
      </StepContainer>
    );
  }
  return (
    <StepContainer label={orderNumber} active>
      <Header as="h4">{t('sidebar-label')}</Header>
      {nextSlot && (
        <div className="Step__nextFreeSlot">
          <p>{t('next-free')}</p>
          <Button
            primary
            size="tiny"
            compact
            onClick={() => onSelectStep('confirm', { slot: nextSlot })}
          >
            {localeFormat(nextSlot.startDate, 'PPPp')}
          </Button>
        </div>
      )}
      {!nextSlot && <p>{t('no-slot-left')}</p>}
    </StepContainer>
  );
};

StepSlot.defaultProps = {};

export default StepSlot;
