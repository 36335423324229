import cx from 'classnames';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Checkbox, Container, Header, Icon, Segment, Tab } from 'semantic-ui-react';

import { useTracking } from '../Context';
import { CmsPageLayout } from '../cms/CmsPage';
import { CmsBlocks } from '../cms/CmsScreen';
import { useConfig } from '../config/config.context';
import { useAvailableScreenPaths, useScreenConfig } from '../config/screens.context';
import { eventTags } from '../core/trackers/events';
import store from '../shared/Store';
import { getString } from '../utils';
import './NetworkingScreen.scss';
import useNetworkingTabs from './hooks/useNetworkingTabs';

const translationRoot = 'networking';
const tabMenuConfig = { style: { overflowX: 'auto', overflowY: 'hidden' } };
const NetworkingScreen = ({ pageId }) => {
  const { tabs, activePane } = useNetworkingTabs(pageId);

  return (
    <Tab
      panes={tabs}
      menu={tabMenuConfig}
      renderActiveOnly
      activeIndex={activePane}
      className="NetworkingScreen"
    />
  );
};

function useProfilePagePath(activeTab) {
  const screensByPath = useAvailableScreenPaths();
  return useMemo(() => {
    const screens = values(screensByPath);
    const profileV2 = screens.find(({ type }) => type === 'profile-v2');
    if (profileV2?.path) return `${profileV2.path}?activeTab=${activeTab}`; // redirect to actions tab
    return screens.find(({ type }) => type === 'path')?.path || '/profile';
  }, [screensByPath, activeTab]);
}

const ChatShield = ({ pageId, pageHeader, headerBlocks, design }) => {
  const { trackEvent } = useTracking();
  const { networking } = useConfig();
  const { enableNetworkingFromScreen = false, enableForAll: enable } = useScreenConfig(pageId);
  // DEPRECATED networking?.enableForAll
  const enableForAll = enable || networking?.enableForAll;

  const accepted = useSelector((state) => state.user.user?.networking);
  const profilePath = useProfilePagePath('actions');

  function trackNetworkingStatus(status) {
    const tag = status ? eventTags.NETWORKING_ENABLE : eventTags.NETWORKING_DISABLE;
    trackEvent(tag, {
      userId: store.userId,
    });
  }

  if (!accepted && !enableForAll) {
    return (
      <CmsPageLayout
        className={cx('page', 'page--cms', 'networking')}
        pageHeader={pageHeader}
        designOverride={design}
      >
        <Container>
          {headerBlocks && <CmsBlocks blocks={headerBlocks.blocks || []} />}
          <Segment placeholder style={{ textAlign: 'center' }}>
            <Header icon>
              <Icon name="share alternate" />
              {getString(`${translationRoot}.not-accepted`)}
            </Header>
            <p>{getString(`${translationRoot}.not-accepted-message`)}</p>
            <Segment.Inline>
              {enableNetworkingFromScreen ? (
                <Checkbox
                  toggle
                  label={
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label>{getString(`profile.enableNetworking`)}</label>
                  }
                  onClick={(e, { checked }) => {
                    trackNetworkingStatus(checked);
                    store.updateUser({ networking: checked });
                  }}
                  checked={accepted}
                />
              ) : (
                <Button
                  primary
                  as={NavLink}
                  to={profilePath}
                  onClick={() => {
                    trackEvent(eventTags.CTA_NETWORKING_ENABLE);
                  }}
                >
                  {getString('profile.enableNetworking')}
                </Button>
              )}
            </Segment.Inline>
          </Segment>
        </Container>
      </CmsPageLayout>
    );
  }

  return (
    <>
      <CmsPageLayout
        className={cx('page', 'page--cms', 'networking')}
        pageHeader={pageHeader}
        designOverride={design}
      >
        <Container>
          {headerBlocks && <CmsBlocks blocks={headerBlocks.blocks || []} />}
          <NetworkingScreen pageId={pageId} />
        </Container>
      </CmsPageLayout>
    </>
  );
};

ChatShield.defaultProps = {
  headerBlocks: undefined,
  pageHeader: undefined,
};

ChatShield.propTypes = {
  headerBlocks: PropTypes.object,
  pageHeader: PropTypes.object,
};

export default ChatShield;
