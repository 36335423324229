/* eslint-disable operator-linebreak */

/* eslint-disable @typescript-eslint/no-unused-vars */
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { useIsDigital } from '../../../../profile/hooks';
import { workshopArrayProptypes } from '../../../../propTypes';
import { formatDateRangeDuration, formatTimezoneDate } from '../../../../utils/dateUtils';
import QuotaIndicator from './QuotaIndicator';

const translationPrefix = 'workshops.workshop.modal';

const SessionDates = ({
  registration,
  workshopSessions,
  showDuration,
  showLocation,
  showQuota,
  filterDates,
}) => {
  const { t } = useTranslation();
  const isDigitally = useIsDigital();

  const computeQuota = (sessions) => {
    let sessionsUsersCount = 0;
    let sessionsQuota = 0;
    sessions.forEach((session) => {
      if (session.quota) {
        sessionsQuota += session.quota;
        sessionsUsersCount += session.usersCount;
      }
    });
    return { sessionsUsersCount, sessionsQuota };
  };
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryStartDate = query.get('startDate');

  const filteredSessions =
    filterDates && !!queryStartDate
      ? workshopSessions.filter((s) => s.startDate === queryStartDate)
      : workshopSessions;

  if (!filteredSessions || filteredSessions.length === 0) return null;
  const hasDuration =
    showDuration && filteredSessions[0]?.startDate && filteredSessions[0]?.endDate;
  const hasLocation = showLocation && filteredSessions[0]?.location;

  const { sessionsUsersCount, sessionsQuota } = computeQuota(filteredSessions);

  const hasQuota = showQuota && sessionsQuota > 0;

  return (
    <div className="session-dates">
      <Icon name="clock outline" style={{ marginRight: 8 }} />

      {filteredSessions.map((workshopSession, idx) => {
        const {
          _id: sessionId,
          startDate: sessionStartDate,
          endDate: sessionEndDate,
          timezone,
          usersCount,
          quota,
        } = workshopSession;

        const formattedStartDate = formatTimezoneDate(sessionStartDate, timezone);
        const formattedEndDate = formatTimezoneDate(sessionEndDate, timezone);
        const isRegistered = registration?._id === sessionId;
        return (
          <span style={{ marginRight: 4 }} className={cx('date', { active: isRegistered })}>
            {t(`${translationPrefix}.session-date`, {
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              timezone,
            })}
            {idx !== filteredSessions.length - 1 && '  /  '}
          </span>
        );
      })}
      {!!hasDuration && (
        <div className="sesion-duration">
          <Icon name="hourglass outline" style={{ marginRight: 8 }} />
          {t(`${translationPrefix}.session-duration`, {
            duration: formatDateRangeDuration(
              filteredSessions[0]?.startDate,
              filteredSessions[0]?.endDate,
              t,
            ),
          })}
        </div>
      )}
      {!!hasLocation && !isDigitally && (
        <div className="session-location">
          <Icon name="map marker alternate" style={{ marginRight: 8 }} />
          {filteredSessions[0].location}
        </div>
      )}
      {hasQuota && (
        <div className="session-quota">
          <QuotaIndicator usersCount={sessionsUsersCount} quota={sessionsQuota} />
        </div>
      )}
    </div>
  );
};

SessionDates.defaultProps = {
  filterDates: false,
  registration: undefined,
  showDuration: false,
  showLocation: false,
  showQuota: false,
  workshopSessions: [],
};

SessionDates.propTypes = {
  filterDates: PropTypes.bool,
  registration: PropTypes.object,
  showDuration: PropTypes.bool,
  showLocation: PropTypes.bool,
  showQuota: false,
  workshopSessions: workshopArrayProptypes,
};

export default SessionDates;
