import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Container } from 'semantic-ui-react';

import { routeComponents } from '../Layouts/Default/DefaultRoutes';
import Styles from '../Styles';
import CmsPage from '../cms/CmsPage';
import PageBackground from '../components/PageBackground';
import { useConfig, useUpdateConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { PreviewMode } from '../config/preview.context';
import { useScreenConfig } from '../config/screens.context';
import { getCurrentPage } from '../core/trackers/utils';
import { setUserCollections, setUsers } from '../core/users/users.actions';
import { setPrograms } from '../programs/store/programs.actions';
import store from '../shared/Store';
import { setSponsors } from '../sponsors/store/sponsors.actions';
import { setWorkshops } from '../workshops/store/workshops.actions';

const PagePreview = ({ match }) => {
  const { name } = match.params;
  const [screenUpdate, setScreenUpdate] = useState({});
  const { homeBackground, mobileHomeBackground } = useDesignConfig();
  const { screens = {} } = useConfig();
  const screenConfig = useScreenConfig(name);
  const updateConfig = useUpdateConfig();
  const { page: screen, pageId } = getCurrentPage(screens, `/${name}`) || screenConfig;
  const page = screen || screenConfig;

  store.isPreview = true;

  useEffect(() => {
    const listener = (event) => {
      const { type, payload } = event.data;
      if (type === 'platform' && payload) {
        const { config, workshops, sponsors, programs, speakers, users, currentUpdate } = payload;
        updateConfig(config);
        if (workshops) store.reduxStore.dispatch(setWorkshops(workshops));
        if (sponsors) store.reduxStore.dispatch(setSponsors(sponsors));
        if (programs) store.reduxStore.dispatch(setPrograms(programs));
        if (speakers) store.reduxStore.dispatch(setUsers('speakers', speakers));
        if (users) {
          store.reduxStore.dispatch(setUserCollections(users));
        }
        if (currentUpdate) setScreenUpdate(currentUpdate);
      }
    };
    window.addEventListener('message', listener, false);
    return () => {
      window.removeEventListener('message', listener, false);
    };
  }, [updateConfig]);

  const finalScreen = { ...page, ...screenUpdate };
  const Component = get(routeComponents, finalScreen?.type, CmsPage);

  return (
    <PreviewMode>
      <Provider store={store.reduxStore}>
        <Styles />
        <Container style={{ marginTop: 16 }}>
          <PageBackground
            image={homeBackground}
            mobile={mobileHomeBackground}
            {...finalScreen?.design}
          />
          <Component name={name} {...finalScreen} pageId={pageId} />
        </Container>
      </Provider>
    </PreviewMode>
  );
};

PagePreview.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PagePreview;
