import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import BlockContainer from '../../components/BlockContainer';
import ImageBlock from '../../components/cms/ImageBlock';

const ItemImageBlock = ({ item, field, containerType, header }) => {
  const image = get(item, field);
  if (!image) return null;

  return (
    <BlockContainer className="block--image" type={containerType} header={header}>
      <ImageBlock image={image} />
    </BlockContainer>
  );
};

ItemImageBlock.defaultProps = {
  containerType: 'headerless',
  field: 'image',
  header: undefined,
};

ItemImageBlock.propTypes = {
  containerType: BlockContainer.propTypes.type,
  field: PropTypes.string,
  header: PropTypes.string,
  item: PropTypes.object.isRequired,
};

export default ItemImageBlock;
