import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useTracking } from '../Context';
import EntityLink from './EntityLink';

const ExternalLink = ({ to, children, className, ...props }) => (
  <a {...props} href={to} className={className} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

ExternalLink.defaultProps = {
  className: '',
  to: undefined,
};

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
};

const OptLink = ({ entity, to, trackClick, children, className, ...props }) => {
  const { trackEvent } = useTracking();

  function trackGoToPage() {
    trackEvent(trackClick, { ...props, item: entity, url: to });
  }

  if (entity) {
    return (
      <EntityLink entity={entity} onClick={trackClick ? trackGoToPage : undefined}>
        {children}
      </EntityLink>
    );
  }

  if (!to) return <>{children}</>;
  const isExternal = to.indexOf('https://') !== -1 || to.indexOf('http://') !== -1;
  const Component = isExternal ? ExternalLink : Link;
  return (
    <Component
      className={className}
      to={to}
      onClick={trackClick ? trackGoToPage : undefined}
      {...props}
    >
      {children}
    </Component>
  );
};

OptLink.defaultProps = {
  className: '',
  entity: undefined,
  to: undefined,
  trackClick: undefined,
};

OptLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  entity: PropTypes.object,
  to: PropTypes.string,
  trackClick: PropTypes.string,
};

export default OptLink;
