import hasher from 'hash-string';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';
import omit from 'lodash/fp/omit';
import set from 'lodash/fp/set';
import update from 'lodash/fp/update';

import {
  CHAT_LIST_LOADED,
  CHAT_LOADED,
  INVITE_RECEIVED,
  MESSAGES_LOADED,
  MESSAGE_DELETED,
} from './networking.types';

const initialState = {
  chats: {},
  routeIdsMap: {},
};

const updateChat = (chatId, newChat) => {
  const hash = hasher(chatId).toString(16);
  return flow(
    update(`chats.${chatId}`, (chat) => ({
      ...chat,
      ...newChat,
      hash,
    })),
    set(`routeIdsMap.${hash}`, chatId),
  );
};
const updateMessages = (chatId, newMessages) =>
  update(`chats.${chatId}.messages`, (messages) => ({
    ...messages,
    ...keyBy('id')(newMessages),
  }));
const deleteMessage = (chatId, messageId) =>
  update(`chats.${chatId}.messages`, (messages) => omit(messageId)(messages));

export default function reduceNetworking(state = initialState, event) {
  const { chatId } = event.payload || {};
  switch (event.type) {
    case CHAT_LOADED:
    case INVITE_RECEIVED:
      return updateChat(chatId, event.payload.chat)(state);
    case MESSAGES_LOADED:
      return updateMessages(chatId, event.payload.messages)(state);
    case MESSAGE_DELETED:
      return deleteMessage(chatId, event.payload.messageId)(state);
    case CHAT_LIST_LOADED:
      return event.payload.chats.reduce((acc, chat) => updateChat(chat.id, chat)(acc), state);
    default:
      return state;
  }
}
