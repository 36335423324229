/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { bem } from '../../../../core/design/bem';
import i18n from '../../../../translations/i18n';
import { WorkshopSession } from '../../../../types/workshop.types';
import { sweetConfirmAlert } from '../../../../utils/popupUtils';
import { replaceValues } from '../../../../utils/stringUtils';
import { useWorkshopSessionRegistration } from '../../../../workshop-session/store/workshopSessions.hooks';
import QuotaField from '../../QuotaField';
import './SessionButton.scss';

const css = bem('WorkshopSessionButton');

type SessionButtonProps = {
  options: Record<string, any>;
  session: WorkshopSession;
  registeredSession?: WorkshopSession;
  itemProps?: any;
};

type ActionConfig = { label: string; registeredLabel: string };

const getButtonLabel = (
  isUserRegistered: boolean,
  sessionCategory: string,
  categoryConfig: ActionConfig,
): string => {
  const label = isUserRegistered ? categoryConfig.registeredLabel : categoryConfig.label;
  return `${label || sessionCategory}`;
};

export async function confirmRegistration(
  sessionTitle: string,
  { registeredSession }: { registeredSession: WorkshopSession | undefined },
): Promise<boolean> {
  return sweetConfirmAlert({
    title: i18n.t(
      `workshops.workshop.${registeredSession ? 'switch-register-confirm' : 'register-confirm'}`,
      {
        name: sessionTitle,
      },
    ),
    confirmButtonText: i18n.t(`alert.confirm`),
    cancelButtonText: i18n.t(`alert.cancel`),
  });
}

export const SessionQuota = ({ session }: { session: WorkshopSession }): JSX.Element | null => {
  const { usersCount, quota } = session;
  return <QuotaField quota={quota} usersCount={usersCount} />;
};

const SessionButton: FC<SessionButtonProps> = (props: SessionButtonProps) => {
  const { session, options = {}, itemProps = {}, registeredSession } = props;
  const { allowOverlappingSessions = false } = itemProps;
  const {
    registerWorkshopSession,
    unregisterWorkshopSession,
  } = useWorkshopSessionRegistration(session, { allowOverlappingSessions });
  const { sessionCategory, _id } = session;
  const sessionTitle = replaceValues(options.sessionTitle, session) || session?.title;
  const categoryConfig = get(options, ['categories', sessionCategory], {});
  const { icon } = categoryConfig;

  const registrations = useSelector((state: any) => state.registrations.registrationsBySessionId);
  const isUserRegistered = !!registrations[_id];

  const handleRegistration = async () => {
    if (!isUserRegistered) {
      const confirm = await confirmRegistration(sessionTitle, { registeredSession });

      if (confirm) {
        await registerWorkshopSession(
          registeredSession ? [{ _id: registeredSession?._id }] : undefined,
        );
      }
      return;
    }

    await unregisterWorkshopSession();
  };

  return (
    <Button
      primary
      basic={!isUserRegistered}
      className={css({
        id: _id,
        category: sessionCategory,
        registered: isUserRegistered,
      }).toString()}
      onClick={handleRegistration}
    >
      {icon && <Icon name={icon} />}
      {getButtonLabel(isUserRegistered, sessionCategory, categoryConfig)}
      {!isUserRegistered && <SessionQuota session={session} />}
    </Button>
  );
};

SessionButton.defaultProps = {
  registeredSession: undefined,
  itemProps: {},
};

export { SessionButton };
