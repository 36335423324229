/* eslint-disable prettier/prettier */
import { areIntervalsOverlapping, parseISO } from 'date-fns';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getAgendaWorkshops } from '../../../agenda/store/agenda.hooks';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { useConfig } from '../../../config/config.context';
import { useAutoRefresh } from '../../../hooks/useAutoRefresh';
import { matchDefinedValues } from '../../../utils';
import { useSyncedWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';
import { useSyncedWorkshops } from '../../../workshops/store/workshops.hooks';
import {
  currentLiveSession,
  hasActiveLive,
  isAutomaticSession,
  isSessionNow,
} from '../../../workshops/utils/session.utils';

export function useTimezoneAdjustementForSession(currentSession) {
  const { timezones } = useConfig();
  if (timezones && currentSession?.timezone) {
    const timezone = timezones.find((tz) => tz.value === currentSession?.timezone);
    if (timezone?.delayMinutes) {
      return timezone?.delayMinutes * 60 * 1000;
    }
  }
  return 0;
}

export function useCloudTvSessions({ mode = 'agenda', workshopFilter }) {
  const sessions = useSyncedWorkshopSessions();
  const workshops = useSyncedWorkshops();
  const agendaEvents = useSelector(userAgenda);
  const agenda = getAgendaWorkshops(agendaEvents, workshops, sessions);
  return useMemo(() => {
    let userSessions = { sessions, workshops, agenda }[mode] ?? [];
    if (workshopFilter) {
      userSessions = userSessions.filter(matchDefinedValues(workshopFilter));
    }
    return orderBy(userSessions, ['startDate', 'endDate', '_id']);
  }, [mode, sessions, workshops, agenda, workshopFilter]);
}

const injectLiveStream = (entity, liveStream) => {
  return { ...entity, liveStreams: [liveStream] };
};

function useAvailableWorkshopsOrSessions(options = {}) {
  const { workshopFilter } = options;
  const sessions = useSyncedWorkshopSessions();
  const workshops = useSyncedWorkshops();

  return useMemo(() => {
    const workshopsById = keyBy(workshops, '_id');
    const filter = matchDefinedValues(workshopFilter);
    return [
      ...workshops.filter(hasActiveLive),
      ...sessions.filter(hasActiveLive).map((s) => ({ ...workshopsById[s.workshopId], ...s })),
    ].filter(filter);
  }, [sessions, workshops, workshopFilter]);
}

export function formatTimeRanges(event) {
  const { startDate, endDate } = event || {};
  if (!startDate || !endDate) return null;
  return { start: parseISO(startDate), end: parseISO(endDate) };
}

export function useOtherCurrentLives(currentLive, options = {}) {
  const { workshopFilter, otherCurrentLivesConfig = {} } = options;
  const { showOverlappingSessions } = otherCurrentLivesConfig;

  const availableSessions = useAvailableWorkshopsOrSessions({
    workshopFilter,
  });
  return useMemo(() => {
    const filteredSessions = availableSessions.filter((event) => event._id !== currentLive?._id);
    if (showOverlappingSessions) {
      const rightInterval = formatTimeRanges(currentLive);
      return {
        currentLives: availableSessions,
        otherCurrentLives: filteredSessions.filter((event) => {
          const leftInterval = formatTimeRanges(event);
          return (
            rightInterval && leftInterval && areIntervalsOverlapping(leftInterval, rightInterval)
          );
        }),
      };
    }
    return {
      currentLives: availableSessions,
      otherCurrentLives: filteredSessions.filter(
        (a) => a.startDate === currentLive?.startDate && a.endDate === currentLive?.endDate,
      ),
    };
  }, [availableSessions, currentLive, showOverlappingSessions]);
}

export function useCurrentCloudTvSession(
  mode,
  { forceLiveStream, workshopFilter, refreshRate, enableRegistration, automaticSessionsConfig },
) {
  const now = useAutoRefresh(refreshRate || 10000);
  const agenda = useCloudTvSessions({ mode, workshopFilter });
  const availableSessions = useAvailableWorkshopsOrSessions({ workshopFilter });

  const currentLive = currentLiveSession(agenda, now);
  const nowAdjustement = useTimezoneAdjustementForSession(currentLive);

  return useMemo(() => {
    let upcomingLive = currentLive;
    if (nowAdjustement) {
      upcomingLive = currentLiveSession(agenda, now - nowAdjustement);
    }

    if (forceLiveStream) {
      return {
        upcomingLive: injectLiveStream(upcomingLive, forceLiveStream),
        hasCurrentLive: true,
      };
    }
    const hasCurrentLive = hasActiveLive(upcomingLive);

    if (!hasCurrentLive && isAutomaticSession(upcomingLive)) {
      const { openBefore = 5, closeAfter = 0 } = automaticSessionsConfig || {};
      if (
        moment(now).isBetween(
          moment(upcomingLive.startDate).subtract(openBefore, 'minutes'),
          moment(upcomingLive.endDate).add(closeAfter, 'minutes'),
        )
      ) {
        return { upcomingLive, hasCurrentLive: true, isAutomatic: true };
      }
      return { upcomingLive: undefined, hasCurrentLive: false };
    }

    if (!hasCurrentLive && enableRegistration) {
      const currentSessions = availableSessions.filter(
        (s) => isSessionNow(s, now) || hasActiveLive(s),
      );
      return {
        hasCurrentLive: false,
        upcomingLive,
        availableSessions: currentSessions,
      };
    }

    if (!hasCurrentLive) {
      const currentSession = availableSessions.find(
        (s) => s.fallbackLive && (isSessionNow(s, now) || hasActiveLive(s)),
      );
      if (currentSession) {
        return { hasCurrentLive: true, upcomingLive: currentSession };
      }
    }

    return { upcomingLive, hasCurrentLive };
  }, [
    now,
    nowAdjustement,
    currentLive,
    forceLiveStream,
    agenda,
    enableRegistration,
    availableSessions,
  ]);
}
