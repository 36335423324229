/* eslint-disable operator-linebreak */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Icon } from 'semantic-ui-react';

import Blocks from '../components/Blocks';
import SidebarBlocks from '../components/SidebarBlocks';
import { useScreenConfig } from '../config/screens.context';
import { GLOBAL_MEDIA_QUERIES } from '../utils/mediaQueries';
import { useStoreVersionSync } from '../utils/useVersionSync';
import WorkshopSessionContext from '../workshop-session/workshopSession.context';
import { mainBlockComponents, sidebarBlockComponents } from './blocks';
import { fetchWorkshop } from './store/workshops.actions';
import './workshop.scss';

const translationPrefix = 'workshops.workshop';

export const defaultMainBlocks = [
  { type: 'details' },
  { type: 'live', containerType: 'segment' },
  { type: 'replay', containerType: 'segment' },
  { type: 'speakers', field: 'speakers' },
  { type: 'sponsors', field: 'sponsors' },
];

export const defaultRightBlocks = [
  { type: 'registrationBlock' },
  { type: 'seeAlso', field: 'seeAlso', containerType: 'segment' },
  { type: 'files', field: 'files', containerType: 'segment' },
];

export const WorkshopPageContent = ({ workshop, otherSessions, ...screenConfig }) => {
  useStoreVersionSync({
    id: workshop?._id,
    actualVersion: workshop?.version,
    resourceType: 'workshops',
    collection: workshop?.collection,
    fetcher: fetchWorkshop,
  });
  const history = useHistory();
  const { t } = useTranslation();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const {
    mainBlocks = defaultMainBlocks,
    leftSidebar,
    rightSidebar,
    rightBlocks = defaultRightBlocks,
    showGoBack = matches.mobile,
    constraints,
  } = screenConfig;

  const context = useMemo(
    () => ({
      workshop,
      constraints,
    }),
    [workshop, constraints],
  );

  if (!workshop) return null;
  const marginBottom = matches.mobile ? 120 : 0;

  function getRightSidebar() {
    if (rightSidebar) return { blocks: defaultRightBlocks, ...rightSidebar };
    return { width: 4, blocks: rightBlocks };
  }

  return (
    <WorkshopSessionContext.Provider value={context}>
      <div className={`screen--type-workshop workshop--${workshop?._id}`} style={{ marginBottom }}>
        {showGoBack && (
          <div className="go-back" onClick={() => history.goBack()}>
            <Icon name="chevron left" />
            {t('sponsors.sponsor.back')}
          </div>
        )}
        {workshop && (
          <Grid columns="equal" stackable>
            <SidebarBlocks
              sidebar={leftSidebar}
              blockComponents={sidebarBlockComponents}
              sharedProps={{
                item: workshop,
                translationPrefix,
              }}
            />
            <Grid.Column>
              <Blocks
                blocks={mainBlocks}
                sharedProps={{
                  item: workshop,
                  translationPrefix,
                }}
                blockComponents={mainBlockComponents}
              />
            </Grid.Column>
            <SidebarBlocks
              sidebar={getRightSidebar()}
              blockComponents={sidebarBlockComponents}
              sharedProps={{
                item: workshop,
                translationPrefix,
                otherSessions,
              }}
            />
          </Grid>
        )}
      </div>
    </WorkshopSessionContext.Provider>
  );
};

export function usePageWorkshop(collection) {
  const { slug } = useParams();
  const workshops = useSelector((state) => state.workshops.workshops);
  if (collection) {
    return workshops.find(
      (s) => s.collection === collection && (s.slug === slug || s._id === slug),
    );
  }
  return workshops.find((s) => s.slug === slug || s._id === slug);
}

const Workshop = () => {
  const workshop = usePageWorkshop();
  const screenConfig = useScreenConfig('workshop');
  return <WorkshopPageContent {...screenConfig} pageId="workshop" workshop={workshop} />;
};
export default Workshop;
