/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '../../shared/Store';
import { ConditionContext } from '../../utils/conditionUtils';
import { postJson } from '../../utils/fetch';
import '../types/window';
import platformService from './platform.service';

type NotificationToSend = {
  title: string;
  body: string;
  sendTo: { type: 'condition'; condition: ConditionContext } | null;
};

const { endpoints } = window.__DATA__;

export type UserGroup = {
  fieldType: string;
  groupCategory: string;
  groupName: string;
  userKey: string;
  userValue: string | number;
};

class NotificationsService {
  // eslint-disable-next-line class-methods-use-this
  async securePost(url: string, json: any) {
    return postJson(url, json, {
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
  }

  async registerToken(token: string) {
    if (!token) {
      console.warn('Missing token');
      return null;
    }
    return this.securePost(`${endpoints.platform}/notifications/_register`, { token });
  }

  async unregisterToken(token: string) {
    if (!token) {
      console.warn('Missing token');
      return null;
    }
    return this.securePost(`${endpoints.platform}/notifications/_unregister`, { token });
  }

  getNotifications = (): any => {
    return platformService.secureGet(`${endpoints.platform}/notifications`);
  };

  getNotificationGroups = (): Promise<{ groups: UserGroup[] }> => {
    return platformService.secureGet(`${endpoints.platform}/notifications/groups`);
  };

  sendNotification = (notification: NotificationToSend): any => {
    return platformService.securePost(`${endpoints.platform}/notifications`, { notification });
  };
}

export default new NotificationsService();
