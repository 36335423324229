import get from 'lodash/get';

// eslint-disable-next-line import/no-cycle
import store from '../../shared/Store';

export function extractDataFromDoc(doc) {
  const data = doc.data();
  data.id = doc.id;
  return data;
}

export async function getInitialDocsAndStartQueryAfter(query) {
  const allSnap = await query.get();
  const allDocs = allSnap.docs;

  return {
    docs: allDocs,
    query: allDocs.length ? query.startAfter(allDocs.slice(-1)[0]) : query,
  };
}

export function extractOtherProfile(invite) {
  if (invite.invitedProfile._id === store.userId) {
    return invite.inviterProfile;
  }
  return invite.invitedProfile;
}

export function getUserUnreadCount(chat) {
  return get(chat, `unreadCount.${store.userId}`, 0);
}
