import { FC } from 'react';
import { Divider } from 'semantic-ui-react';

import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { Icons } from '../../../components/Icons/material-icon.types';
import { eventHandler } from '../../../utils/event.util';
import './PublicationActions.scss';

interface PublicationAction {
  label: string;
  onClick: () => void;
  icon?: Icons;
}
interface PublicationActionsProps {
  actions: PublicationAction[];
  callback?: () => void;
}

export const PublicationActions: FC<PublicationActionsProps> = (props) => {
  const { actions, callback } = props;
  const handleOnClick = (action: () => void) => {
    action();
    callback?.();
  };

  return (
    <div className="actions-wrapper">
      {actions.map(({ label, icon, onClick }, index) => (
        <div key={index.toString()}>
          <button onClick={(e) => eventHandler(e, () => handleOnClick(onClick))} type="button">
            <span>{label}</span>
            {icon && <MaterialIcon name={icon} className="actions-icons" />}
          </button>
          {index !== actions.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
};
