import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { workshopProptypes } from '../../../../propTypes';
import EntityLink from '../../../EntityLink';

type SeeMoreLiveButtonProps = {
  workshop: any;
};

const translationPrefix = 'workshops.workshop';
const SeeMoreLiveButton: FC<SeeMoreLiveButtonProps> = (props) => {
  const { workshop } = props;
  const { _id: workshopId, pageHidden, pageLiveOnlyForRegistered } = workshop;
  const { t } = useTranslation();

  const isUserRegistered = useSelector(
    (state: any) => state.registrations.registrationsById[workshopId],
  );

  const hasLive =
    workshop.liveStreams && workshop.liveStreams.length > 0 && !!workshop.liveStreams[0].uri;

  const isDisplayed = !pageHidden && (isUserRegistered || !pageLiveOnlyForRegistered);
  if (!isDisplayed || !hasLive) {
    return null;
  }

  return (
    <Button
      className="live-button"
      as={EntityLink}
      entity={workshop}
      primary
      icon="play"
      content={t(`${translationPrefix}.btn.live`)}
    />
  );
};

SeeMoreLiveButton.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export {
  SeeMoreLiveButton
}
