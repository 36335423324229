import { useConfig } from '../../config/config.context';
import { DisconnectionMode } from '../../types/config.types';

export { DisconnectionMode };

export function useDisconnectionMode(): Record<string, boolean> {
  const platformConfig = useConfig();
  const { disconnectionMode = DisconnectionMode.POSSIBLE } = platformConfig ?? {};
  return {
    isPossible: disconnectionMode === DisconnectionMode.POSSIBLE,
    isImpossible: disconnectionMode === DisconnectionMode.IMPOSSIBLE,
  };
}
