/* eslint-disable operator-linebreak */

/* eslint-disable react/sort-comp */
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Header, Input, Pagination } from 'semantic-ui-react';

import { CmsBlocks } from '../cms/CmsScreen';
import ScreenIntro from '../components/screens/ScreenIntro';
import SpeakerCard from '../components/speakers/SpeakerCard';
import { useScreenConfig } from '../config/screens.context';
import MenuFilter from '../core/filter/MenuFilter';
import { applySearchFilter, generateFilters } from '../core/filter/utils';
import { fetchUsersByCollection } from '../core/users/users.actions';
import { useSpeakers } from '../core/users/users.hooks';
import store from '../shared/Store';
import { getString, throttleDispatchFetch } from '../utils';
import { useFilter, useOrderBy, usePagination } from '../utils/hooks';

const throttledFetch = throttleDispatchFetch(() =>
  store.reduxStore.dispatch(fetchUsersByCollection('speakers')),
);

const SpeakerGrid = ({ className, items, template, variant, itemsPerRow, width, ...rest }) => {
  const {
    showSearchBar = false,
    fields = ['firstName', 'lastName', 'role', 'company'],
    autoFocus = false,
  } = rest.search || {};
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState(undefined);
  const filteredItems = applySearchFilter(items, searchText, fields);
  return (
    <>
      {showSearchBar && (
        <Input
          fluid
          autoFocus={autoFocus}
          placeholder={t('filters.search')}
          className="speakers__search"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText || ''}
          icon={
            searchText
              ? {
                  name: 'close',
                  style: { cursor: 'pointer', pointerEvents: 'all' },
                  onClick: () => setSearchText(undefined),
                }
              : 'search'
          }
        />
      )}
      <Card.Group className={className} itemsPerRow={width ? undefined : itemsPerRow}>
        {filteredItems.map((speaker) => (
          <SpeakerCard
            key={speaker._id}
            speaker={speaker}
            width={width}
            variant={variant || template} // keep template for legacy
            {...rest}
          />
        ))}
      </Card.Group>
    </>
  );
};

SpeakerGrid.defaultProps = {
  className: undefined,
  itemsPerRow: 3,
  showLink: true,
  showModal: false,
  template: undefined,
  variant: undefined,
  width: undefined,
};
SpeakerGrid.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  itemsPerRow: PropTypes.number,
  showLink: PropTypes.bool,
  showModal: PropTypes.bool,
  template: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.number,
};

const defaultOrder = [
  { field: 'order', order: 'asc' },
  { field: 'lastName', order: 'asc' },
  { field: 'firstName', order: 'asc' },
];

const Speakers = ({ pageId }) => {
  const {
    itemConfig: speakerItemConfig = {},
    groupBy: itemGroupBy,
    orderBy: itemOrderBy = defaultOrder,
    filters: fieldsToFilter = [],
    filterConfig,
    pagination,
    header,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    footer,
  } = useScreenConfig(pageId || 'speakers');

  const speakers = useSpeakers();

  useEffect(() => {
    throttledFetch();
  }, []);

  const [filteredData, currentFilters, setFilters] = useFilter(speakers, fieldsToFilter);
  const orderedSpeakers = useOrderBy(filteredData, itemOrderBy);

  // const orderedSpeakers = useOrderBy(filteredData, itemOrderBy);
  const { maxItems, showPreviousAndNextNav } = pagination || {};
  const { pageItems, setActivePage, totalPages } = usePagination(orderedSpeakers, maxItems);

  function renderItems() {
    if (itemGroupBy?.field) {
      const itemsByGroup = groupBy(pageItems, itemGroupBy.field);
      return (
        itemGroupBy.options || Object.keys(itemsByGroup).map((v) => ({ label: v, value: v }))
      ).map((opt) => {
        const { value, label, itemConfig = {} } = opt;
        const items = itemsByGroup[value];
        if (!items || items.length === 0) return null;
        return (
          <React.Fragment key={value}>
            <Header as="h3">{getString(`speakers.${itemGroupBy.field}.${value}`) || label}</Header>
            <SpeakerGrid
              className={`speakers-grid speakers-grid--${value}`}
              items={items}
              {...speakerItemConfig}
              {...itemConfig}
            />
          </React.Fragment>
        );
      });
    }
    return <SpeakerGrid className="speakers-grid" items={pageItems} {...speakerItemConfig} />;
  }

  return (
    <div className="page--speakers">
      <Grid stackable columns="equal" style={{ marginTop: 10 }}>
        {fieldsToFilter && fieldsToFilter.length > 0 && (
          <Grid.Column className="sidebar--left" width={4}>
            <div style={{ paddingBottom: 10 }}>
              <MenuFilter
                filterList={generateFilters(speakers, fieldsToFilter, currentFilters)}
                filters={currentFilters}
                onChange={setFilters}
                filterConfig={filterConfig}
                pageId={pageId}
              />
            </div>
          </Grid.Column>
        )}
        <Grid.Column className="mainContent">
          {header && <CmsBlocks blocks={header.blocks} />}
          <ScreenIntro type="speakers" category="speakers" />
          {renderItems()}
          {maxItems && totalPages > 1 && (
            <div style={{ textAlign: 'center', marginTop: 15 }}>
              <Pagination
                className="paginator"
                boundaryRange={0}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                prevItem={showPreviousAndNextNav ? undefined : null}
                nextItem={showPreviousAndNextNav ? undefined : null}
                siblingRange={2}
                defaultActivePage={1}
                totalPages={totalPages}
                pointing
                secondary
                {...pagination}
                onPageChange={(_e, data) => {
                  const { activePage: i } = data;
                  setActivePage(i);
                }}
              />
            </div>
          )}
          {footer && <CmsBlocks blocks={footer.blocks} />}
        </Grid.Column>
      </Grid>
    </div>
  );
};

Speakers.propTypes = { pageId: PropTypes.string };
Speakers.defaultProps = { pageId: 'speakers' };
export default Speakers;
