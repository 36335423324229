import { FC, memo } from 'react';

import MaterialIcon, { MaterialIconProps } from '../../components/Icons/MaterialIcon';
import { bem } from '../../core/design/bem';
import './Label.scss';

export type LabelProps = {
  flat?: boolean;
  value: string;
  icon?: MaterialIconProps;
};

const css = bem('ac-label');

export const Label: FC<LabelProps> = memo(({ value, flat = false, icon }) => {
  return (
    <div className={css({ flat })}>
      {icon ? <MaterialIcon {...icon} /> : null}
      <span className={css('value')}>{value}</span>
    </div>
  );
});
