/* eslint-disable operator-linebreak */

/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line import/no-extraneous-dependencies
import Firebase from '@firebase/app';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useConfig } from '../config/config.context';
import store from '../shared/Store';

export function useDisableFirebase() {
  const config = useConfig();
  return config?.options?.disableFirebase || config.lang === 'en-cn';
}

const Context = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [firebase, setFirebase] = useState(null);
  const disableFirebase = useDisableFirebase();

  useEffect(() => {
    if (disableFirebase) return;

    store.getFirebase().then((fb) => {
      setFirebase(fb);
    });
  }, [disableFirebase]);
  return <Context.Provider value={firebase}>{children}</Context.Provider>;
};

export const useFirebase = () => {
  return useContext(Context);
};

export const useFirestoreRef = (baseCollection, resolver, deps = []) => {
  const firebase = useFirebase();
  const memoResolver = useCallback(resolver, [...deps]);

  const ref = useMemo(() => {
    return !firebase || !deps.every((dep) => !!dep)
      ? null
      : memoResolver(firebase.firestore().collection(baseCollection));
  }, [firebase, memoResolver, baseCollection, ...deps]);

  return ref;
};

export const useFirestoreSubRef = (baseRef, resolver, deps = []) => {
  const memoResolver = useCallback(resolver, [...deps]);

  const ref = useMemo(() => {
    return !baseRef || !deps.every((dep) => !!dep) ? null : memoResolver(baseRef);
  }, [baseRef, memoResolver, ...deps]);

  return ref;
};

export const useFirestoreQuery = (query) => {
  const [data, setData] = useState([]);

  useEffect(
    () =>
      query?.onSnapshot({
        next(snapshot) {
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(docs);
        },
      }),
    [query],
  );

  return data;
};

const identity = (v) => v;
export const useFirestoreCollection = (ref, queryFilter = identity) => {
  const query = useMemo(() => queryFilter(ref), [queryFilter, ref]);
  const data = useFirestoreQuery(query);

  return useMemo(
    () => ({
      list: data,
      async create(newOne) {
        await ref.add(newOne);
      },
      async update(id, update) {
        await ref.doc(id).update(update);
      },
      async set(id, next) {
        await ref.doc(id).set(next);
      },
      async remove(id) {
        await ref.doc(id).delete();
      },
    }),
    [ref, data],
  );
};

export const useFirestoreDocument = (ref) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    return ref?.onSnapshot({
      next(snapshot) {
        if (!snapshot.exists) {
          setData(null);
          return;
        }

        const doc = {
          ...snapshot.data(),
          id: snapshot.id,
        };

        setData(doc);
      },
    });
  }, [ref]);

  return useMemo(
    () => ({
      item: data,
      async remove() {
        await ref.delete();
      },
      async set(next, options) {
        await ref.set(next, options);
      },
      async update(update) {
        await ref.update(update);
      },
    }),
    [ref, data],
  );
};

export const useFirebaseIncrement = (value) => {
  return useMemo(() => Firebase.firestore.FieldValue.increment(value), [value]);
};
