import keyBy from 'lodash/keyBy';

const defaultState = { registrations: [], registrationsById: {}, registrationsBySessionId: {} };

const registartionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_REGISTRATIONS':
      return {
        registrations: action.registrations,
        registrationsById: keyBy(action.registrations, '_id'),
        registrationsBySessionId: keyBy(
          action.registrations.filter((r) => r.sessionId),
          'sessionId',
        ),
      };
    default:
      return state;
  }
};

export function setRegistrations(registrations) {
  return {
    type: 'SET_REGISTRATIONS',
    registrations,
  };
}

export default registartionsReducer;
