import moment from 'moment';
import { useState } from 'react';

import Store from '../../../shared/Store';

const LOCAL_STORAGE_KEY = `platform-${Store.eventId}-pwaPrompt`;

type LocalStoragePromptType = {
  lastPrompt?: string;
  disablePrompt?: boolean;
};

export const getInstallPrompt = (): LocalStoragePromptType =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');

export const setDisablePrompt = (value: boolean): void => {
  const installPrompt = getInstallPrompt();
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({ ...installPrompt, disablePrompt: value }),
  );
};

const setInstallPromptSeenToday = (disablePrompt: boolean): void => {
  const today = moment().toISOString();
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ lastPrompt: today, disablePrompt }));
};

function getUserShouldBePromptedToInstall(daysToWaitBeforePromptingAgain: number): boolean {
  const { lastPrompt } = getInstallPrompt();
  const daysSinceLastPrompt = moment().diff(moment(lastPrompt), 'days');

  return (
    !lastPrompt ||
    Number.isNaN(daysSinceLastPrompt) ||
    daysSinceLastPrompt > daysToWaitBeforePromptingAgain
  );
}

const useShouldShowPrompt = (
  daysToWaitBeforePromptingAgain = 0,
): [boolean, (disablePrompt?: boolean) => void] => {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(
    getUserShouldBePromptedToInstall(daysToWaitBeforePromptingAgain),
  );

  const handleUserSeeingInstallPrompt = (disablePrompt?: boolean) => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday(disablePrompt || false);
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useShouldShowPrompt;
