import PropTypes from 'prop-types';
import React from 'react';

import { BlockContainerPropType } from '../../components/BlockContainer/BlockContainer';
import SpeakersBlock from '../../components/speakers/SpeakersBlock';

const WorkshopSpeakersBlock = (props) => {
  const { item, blockConfig, field, header, container } = props;

  const entries = item[field];
  if (!entries || entries.length === 0) return null;

  return (
    <SpeakersBlock
      container={{ header, ...container }}
      field={field}
      ids={entries}
      {...blockConfig}
    />
  );
};

WorkshopSpeakersBlock.defaultProps = {
  blockConfig: { openMode: 'link' },
  header: undefined,
  field: 'speakers',
  container: undefined,
};

WorkshopSpeakersBlock.propTypes = {
  blockConfig: PropTypes.object,
  item: PropTypes.object.isRequired,
  field: PropTypes.string,
  header: PropTypes.string, // Deprecated
  container: BlockContainerPropType,
};

export default WorkshopSpeakersBlock;
