import get from 'lodash/get';

import { useDesignConfig } from '../config/design.context';
import { Entity } from './hooks';

export function useItemDesign(kind: string, item: Entity): Record<string, any> {
  const design = useDesignConfig();
  return design?.options?.[kind]?.[item.collection!] || {};
}

export function useItemValueColor(
  kind: string,
  item: Entity,
  key: string,
): { color?: string; style: { backgroundColor?: string; color?: string } } {
  const itemDesign = useItemDesign(kind, item);
  const value = get(item, key);
  const { color } = itemDesign?.[key]?.choices?.[value] ?? {};
  const style = {
    backgroundColor: color,
    color: color ? 'white' : undefined,
  };
  return { color, style };
}
