/* eslint-disable react/prop-types */

/* eslint-disable @typescript-eslint/no-unused-vars */
import Player from '@vimeo/player';
import omit from 'lodash/omit';
import { useEffect, useRef } from 'react';
import { Embed } from 'semantic-ui-react';

import { useVideoSectionStats } from '../hooks/useVideoSectionStats';

// eslint-disable-next-line react/prop-types
export const VimeoVideoPlayer = ({
  _id: videoId,
  url,
  iframe,
  item,
  block,
  onPlay,
  onSectionStat,
  onVideoEvent,
  ...props
}) => {
  const { play, stop, updatetime } = useVideoSectionStats(
    url,
    { item, block, videoId },
    onSectionStat,
  );

  const iframeRef = useRef(null);

  useEffect(() => {
    const player = new Player(iframeRef.current);

    player.on('play', (evt) => {
      const { seconds, duration } = evt;
      play(seconds, duration);

      if (onPlay) {
        onPlay();
      }
    });

    player.on('pause', (evt) => {
      const { seconds } = evt;
      stop(seconds, false);
    });

    player.on('timeupdate', (evt) => {
      const { seconds, duration } = evt;
      updatetime(seconds, duration);
    });
    player.on('ended', (evt) => {
      const { seconds } = evt;
      stop(seconds, true);
    });
  }, []);

  return (
    <Embed
      {...omit(props, ['visible'])}
      url={url}
      iframe={{
        ...iframe,
        // src:
        //   'https://player.vimeo.com/video/644356730?h=800dde230e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
        src: url?.replace(/&amp;/g, '&'), // Unescape....
        ref: iframeRef,
      }}
    />
  );
};
