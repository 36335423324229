import React from 'react';
import { useConfig } from './config.context';

export const useDesignConfig = () => {
  return useConfig()?.design ?? {};
};

export const  useComponentOptions = (name) => {
  return useDesignConfig()?.components?.[name] || {};
};

export function withDesign(Component) {
  return function DesignComponent(props) {
    const design = useDesignConfig();
    return <Component {...props} design={design} />;
  };
}
