import { useEvent } from 'react-use';

import store from '../shared/Store';
import { fetchUser } from '../store/actions';

export function useRefreshUserOnRegistration(): any {
  return useEvent('message', (evt: MessageEvent<{ type: string; payload: any }>) => {
    if (!evt.data) return;
    const { type, payload } = evt.data;
    if (type === 'registration:step') {
      if (payload?.step === 'confirmation') {
        store.reduxStore.dispatch(fetchUser());
      }
    }
  });
}
