import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useAutoRefresh } from '../../../hooks/useAutoRefresh';

const dateFormat = 'YYYY-MM-DD';

function extractDate(datetime: string) {
  return moment(datetime, moment.ISO_8601).format(dateFormat);
}

export function dateToTime(datetime: string): number {
  return moment(datetime, moment.ISO_8601).toDate().getTime();
}

export type OpeningSlot = {
  startDate: string;
  endDate: string;
};

export type OpeningSlotOptions = {
  timeTolerance?: number; // How much time to stay open if already open...
};

export type OpeningSlotsReturn<T extends OpeningSlot> = {
  open: boolean;
  currentSlot: T | null;
  nextSlot: T | null;
  todaySlots: T[];
};

export function filterDaySlots<T extends OpeningSlot>(slots: T[], dayString: string): T[] {
  return orderBy(
    (slots || []).filter((slot) => extractDate(slot.startDate) === dayString),
    'startDate',
  );
}

/**
 *
 * @param slots
 * @param now
 * @param timeToleranceSeconds
 */
export function findCurrentAndNextSlots<T extends OpeningSlot>(
  slots: T[],
  now: number | string,
  timeToleranceSeconds?: number,
): { currentSlot: T | null; nextSlot: T | null } {
  const nowDate = typeof now === 'string' ? dateToTime(now) : now;
  let currentSlot = null;
  let nextSlot = null;
  const toleranceMs = (timeToleranceSeconds ?? 0) * 1000;
  const orderedSlots = orderBy(slots, 'startDate');

  // eslint-disable-next-line no-restricted-syntax
  for (const slot of orderedSlots) {
    const startTime = dateToTime(slot.startDate);
    if (startTime <= nowDate && nowDate < dateToTime(slot.endDate) + toleranceMs) {
      currentSlot = slot;
    }
    if (nowDate <= dateToTime(slot.startDate)) {
      nextSlot = slot;
      break; // Found next slot, stop...
    }
  }
  return { currentSlot, nextSlot };
}

/**
 * Returns if the current entry is open or not
 * - `timeTolerance`
 *      used only when the slot was already open and the time
 *      is coming to an end, to give some extra time just in case...
 * @param slots
 * @param options
 * @returns
 */
export function useOpeningSlots<T extends OpeningSlot>(
  slots: T[],
  options?: OpeningSlotOptions,
): OpeningSlotsReturn<T> {
  const { timeTolerance } = options || {};
  const now = useAutoRefresh(5 * 1000);
  const today = moment(now).format(dateFormat);
  const todaySlots = useMemo(() => filterDaySlots(slots, today), [slots, today]);
  const [open, setOpen] = useState<boolean>(() => {
    const { currentSlot } = findCurrentAndNextSlots(slots, now, 0);
    return !!currentSlot; // Open by default if already in a slot
  });

  const { currentSlot, nextSlot } = useMemo(
    () => findCurrentAndNextSlots(slots, now, open ? timeTolerance : 0),
    [slots, now, open, timeTolerance],
  );

  useEffect(() => {
    if (open && !currentSlot) {
      setOpen(false);
    } else if (!open && currentSlot) {
      setOpen(true);
    }
  }, [currentSlot, open]);

  return { open: !!currentSlot, currentSlot, nextSlot, todaySlots };
}
