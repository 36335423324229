import { Image } from '../components/cms/types';

export enum AchievementState {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
}

export enum AchievementStatus {
  'PUBLISHED' = 'PUBLISHED',
  'UNPUBLISHED' = 'UNPUBLISHED',
  'IN_REVIEW' = 'IN_REVIEW',
  'READ_ONLY' = 'READ_ONLY',
}

type AchievementData = {
  lockedMedia: Image;
  order?: number;
};

export type RawAchievement = {
  _id: string;
  eventId: string;
  categoryId: string;
  label: string;
  points?: number;
  status: AchievementStatus;
  level?: string;
  data?: AchievementData;
};

export type Achievement = RawAchievement & {
  media: Image | undefined;
  state: AchievementState;
};

type CategoryData = {
  description?: string;
} & Record<string, any>;

export type Category = {
  _id: string;
  id: number;
  eventId: string;
  label: string;
  data?: CategoryData;
};

export type UserAchievement = {
  _id: string;
  userId: string;
  eventId: string;
  achievementId: string;
  unlockedLabel: string;
  unlockedMedia: Image;
  state: AchievementState;
  progression: Record<string, any>[];
};

export type AchievementAndCategory = {
  achievements: Achievement[];
  categories: Category[];
};
