import PropTypes from 'prop-types';
import React from 'react';

import BlockContainer from '../../components/BlockContainer';
import ListDetails from '../../components/ListDetails.js';
import { useExhibitorOpportunities } from '../../matching/store/matching.hooks';
import { containerTypePropTypes } from '../../propTypes';

const OpportunitiesBlock = ({ item, type, title, ...props }) => {
  const opportunities = useExhibitorOpportunities(item.collection, item._id);
  return (
    <BlockContainer className="offers" type={type} header={title}>
      <ListDetails items={opportunities} {...props} />
    </BlockContainer>
  );
};
OpportunitiesBlock.defaultProps = {
  type: 'basic',
  title: undefined,
};

OpportunitiesBlock.propTypes = {
  item: PropTypes.object.isRequired,
  title: PropTypes.string,
  type: containerTypePropTypes,
};

export default OpportunitiesBlock;
