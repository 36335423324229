const classesFromType = {
  body: 'html, body, .header, .ui.menu, .ui.button',
};

const FONT_EXTENSIONS = {
  '.ttf': 'truetype',
  '.otf': 'opentype',
  '.woff': 'woff',
  '.woff2': 'woff2',
};

interface FontFile {
  uri: string;
  originalFilename?: string;
}

export interface CustomFont {
  name: string;
  classes: string;
  fallbacks?: string;
  files: Record<string, FontFile>;
}

export const extension = (filename: string): string => {
  const matches = filename.match(/\.[0-9a-z]+$/i);
  if (matches) return matches[0];
  return '';
};

export const fontFormatFromName = (filename: string): string => {
  const ext = extension(filename) as keyof typeof FONT_EXTENSIONS;
  return FONT_EXTENSIONS[ext];
};

export const fontFace = (name: string, type: string, file?: FontFile): string => {
  if (!name || !file || !file.uri) return '';
  const format = fontFormatFromName(file.uri);
  if (!format) return '';

  return `
/* ${type} */
@font-face {
    font-family: "${name}";
    src: url("${file.uri}") format("${format}");
${type === 'italic' || type === 'boldItalic' ? '    font-style: italic;' : ''}
${type === 'bold' || type === 'boldItalic' ? '    font-weight: bold;' : ''}
}`;
};

export const fontFaces = (name: string, files: Record<string, FontFile>): string => {
  if (!files) return '';

  return Object.keys(files)
    .map((type) => fontFace(name, type, files[type]))
    .join('\n');
};

export const cssForFont = (type: keyof typeof classesFromType, font: CustomFont): string | null => {
  if (!font) return null;
  const { name, classes = classesFromType[type], fallbacks, files } = font;
  if (!classes) return null;

  return `
${fontFaces(name, files)}

${classes} {
    font-family: "${name}", ${fallbacks || 'sans-serif'} !important;
}
`;
};
