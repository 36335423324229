import cx from 'classnames';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import CdnImage from '../../../components/CdnImage';
import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { isVideoMedia } from '../../../utils/videoUtils';
import { Media } from '../../types/SocialWallV2.types';
import './FilesThumbnails.scss';

type ImagePreviewProps = { image: Media; isHorizontal?: boolean };

export const ImagePreview: FC<ImagePreviewProps> = memo(({ image, isHorizontal }) => {
  return (
    <div
      className={cx(
        'image-thumbnail',
        isHorizontal ? 'image-thumbnail__horizontal' : 'image-thumbnail__vertical',
      )}
    >
      <CdnImage src={image} maxWidth={600} maxHeight={400} />
    </div>
  );
});

type VideoPreviewProps = { video: Media; isHorizontal?: boolean };

export const VideoPreview: FC<VideoPreviewProps> = ({ video, isHorizontal }) => {
  const { t } = useTranslation();

  const iFrameWrapperStyle = isHorizontal
    ? {
        width: video.width || 0,
        maxWidth: 500,
      }
    : {
        maxWidth: (400 * (video.width || 0)) / (video.height || 1),
        maxHeight: 400,
      };
  return (
    <div
      className={cx(
        'video-thumbnail',
        isHorizontal ? 'video-thumbnail__horizontal' : 'video-thumbnail__vertical',
      )}
    >
      {video.player ? (
        <div
          className="iframe-wrapper"
          style={{
            ...iFrameWrapperStyle,
            aspectRatio:
              video.width && video.height ? `${video.width} / ${video.height}` : undefined,
          }}
        >
          <iframe
            src={`${video.player}#hide-title`}
            title="video-player"
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
          />
        </div>
      ) : (
        <video
          playsInline
          disableRemotePlayback
          disablePictureInPicture
          x-webkit-airplay="deny"
          autoPlay
          controls
          width={250}
        >
          <source src={video.uri} type={video.mimeType || 'video/mp4'} />
          {t('common.no-video')}
        </video>
      )}
    </div>
  );
};

type FilesThumbnailsProps = {
  handleOnDelete: () => void;
  media: Media;
};

export const FilesThumbnails: FC<FilesThumbnailsProps> = ({ handleOnDelete, media }) => {
  const isVideo = isVideoMedia({ mimeType: media.mimeType, filename: media.filename || '' });
  const isHorizontal = (media.width || 0) > (media.height || 0);

  return (
    <div className="file-thumbnail-container">
      {isVideo ? (
        <VideoPreview video={media} isHorizontal={isHorizontal} />
      ) : (
        <ImagePreview image={media} isHorizontal={isHorizontal} />
      )}
      <button type="button" onClick={handleOnDelete}>
        <MaterialIcon className="delete-icon" name="close" color="white" />
      </button>
    </div>
  );
};
