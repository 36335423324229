/* eslint-disable react/prop-types */
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import vCardFactory from 'vcards-js';

type VCardUser = {
  eventId: string;
  _id: string;
  firstName: string;
  lastName: string;
  email?: string;
  company?: string;
  phoneNumber?: string;
} & Record<string, any>;

function user2vcard(user: VCardUser, _config: unknown) {
  const card = vCardFactory();
  card.version = '3.0';

  // Generate uid ?
  card.uid = `${user.eventId}-${user._id}`;
  card.firstName = user.firstName;
  card.lastName = user.lastName;
  if (user.email) card.workEmail = user.email;
  if (user.company) card.organization = user.organization || user.company;
  if (user.phoneNumber) card.workPhone = user.phoneNumber;
  if (user.socialNetworks) card.socialUrls = user.socialNetworks;

  return card;
}

function useVcardLink(user: VCardUser, config: unknown) {
  return useMemo(() => {
    const card = user2vcard(user, config);
    const file = card.getFormattedString();
    // const link = textToBase64Url(file, 'text/vcard');
    const filename = `${user.firstName}-${user.lastName}.vcf`;
    return `${window.__DATA__.endpoints.platform}/vcard/dl/${filename}?file=${encodeURIComponent(
      file,
    )}`;
  }, [user, config]);
}

const VCardLink: FC<{ user: VCardUser; config: unknown }> = ({ user, config }) => {
  const { t } = useTranslation();
  const link = useVcardLink(user, config);

  return (
    <a className="vcard" href={link}>
      <Icon name="vcard" /> {t('speakers.card.vcard')}
    </a>
  );
};

export default memo(VCardLink);
