import React from 'react';
import PropTypes from 'prop-types';
import ClassicSpeakerHeader from './variants/ClassicSpeakerHeader';
import SquareSpeakerHeader from './variants/SquareSpeakerHeader';

const SpeakerHeader = (props) => {
  const { variant, ...headerProps } = props;
  switch (variant) {
    case 'square':
      return <SquareSpeakerHeader {...headerProps} />;
    case 'classic':
    default:
      return <ClassicSpeakerHeader {...headerProps} />;
  }
};

SpeakerHeader.defaultProps = {
  variant: undefined,
};
SpeakerHeader.propTypes = {
  variant: PropTypes.string,
};

export default SpeakerHeader;
