import { FC } from 'react';

import { useMe } from '../../profile/hooks';
import i18n from '../../translations/i18n';
import BlockContainer from '../BlockContainer';
import { ContainerProps } from './types';

type Props = {
  id: string;
  container: ContainerProps;
};

const RoommateBlock: FC<Props> = (props) => {
  const { id, container } = props;
  const me = useMe();
  const rooms = me?.private?.accommodations?.rooms;

  if (!rooms || rooms?.length < 1) {
    return null;
  }

  return (
    <BlockContainer id={id} {...container}>
      <div>
        {rooms?.map((room: any, index: number) => {
          return (
            <>
              <p>
                {i18n.t('blocks.rooming.roommates')} : <b>{room.room.mates}</b>
              </p>
              <p>
                {i18n.t('common.hotel')} : <b>{room.accommodation.name}</b>
              </p>
              {rooms.length !== index + 1 && <hr />}
            </>
          );
        })}
      </div>
    </BlockContainer>
  );
};

export default RoommateBlock;
