/* eslint-disable react/require-default-props */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

import FilterModal from './FilterModal';
import { applyFilterChange } from './utils';

type Filter = {
  type: string;
  dataKey: string;
  fields?: string[];
};

/**
 * Displays either a filter button or a search filter + button depending on the filters
 * available for the current screen
 */
const MobileFilters = (props: { filterList?: Filter[] } & Record<string, any>): JSX.Element => {
  const { t } = useTranslation();
  const { filterList, filters = {}, onChange } = props;

  const searchFilter = filterList?.find((filter) => filter.type === 'search');
  if (searchFilter) {
    // Show search with filter button
    const hasOtherFields = (filterList?.length || 0) > 1;
    const searchString = filters[searchFilter.dataKey];
    return (
      <div>
        <Input
          icon="search"
          iconPosition="left"
          placeholder={t('filters.search')}
          fluid
          value={searchString || ''}
          onChange={(_e, { value }) =>
            onChange(applyFilterChange(filters, searchFilter.dataKey, value))
          }
          action={hasOtherFields ? <FilterModal hideLabel {...props} /> : undefined}
        />
      </div>
    );
  }

  return (
    <div>
      <FilterModal hideLabel={false} {...props} />
    </div>
  );
};

export default MobileFilters;
