import i18n from '../../../translations/i18n';
import { UserAction } from './types';

export const disconnectAction = {
  key: 'disconnect',
  icon: 'power off',
  label: i18n.t('profile-v2.actions.disconnect'),
  default: true,
};

export const userActions: UserAction[] = [
  {
    key: 'update-password',
    icon: 'lock',
    label: i18n.t('profile-v2.actions.update-password'),
    default: true,
  },
  {
    key: 'edit-profile',
    icon: 'pencil',
    label: i18n.t('profile-v2.actions.edit-profile'),
    default: true,
  },
  {
    key: 'display-qrcode',
    icon: 'qrcode',
    label: i18n.t('profile-v2.actions.display-qrcode'),
  },
  {
    key: 'download-badge',
    icon: 'id badge outline',
    label: i18n.t('profile-v2.actions.download-badge'),
  },
  {
    key: 'download-plane-tickets',
    icon: 'plane',
    label: i18n.t('profile-v2.actions.download-plane-tickets'),
  },
  {
    key: 'download-agenda',
    icon: 'calendar alternate outline',
    label: i18n.t('profile-v2.actions.download-agenda'),
  },
  { key: 'download-file', icon: 'file outline', label: i18n.t('profile-v2.actions.download-file') },
  {
    key: 'enable-networking',
    icon: 'comments outline',
    label: i18n.t('profile-v2.actions.enable-networking'),
  },
  {
    key: 'download-template-document',
    icon: 'file outline',
    label: i18n.t('profile-v2.actions.download-file'),
  },
];
