import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Header } from 'semantic-ui-react';
import { InfoWindow } from '@react-google-maps/api';
import get from 'lodash/get';
import EntityLink from '../../../EntityLink';
import { replaceUriValues, replaceValues } from '../../../../utils/stringUtils';
import { trackEvent } from '../../../../core/trackers';
import { eventTags } from '../../../../core/trackers/events';

type Info = {
  label: string;
  field: string;
  uri: string;
};
type Config = {
  infos: Info[];
  coordinates: { latKey: number; lngKey: number };
  logo: { field: string; showInSidebar: boolean; showInInfocard: boolean };
};

const InfoCard = (props: {
  options: any;
  workshop: any;
  config: Config;
  hideInfoCard: () => void;
}): JSX.Element => {
  const {
    options,
    workshop = {},
    hideInfoCard,
    config = { infos: [], coordinates: { latKey: 45, lngKey: -2 }, logo: undefined },
  } = props;
  const { t } = useTranslation();

  const center = {
    lat: Number(get(workshop, config?.coordinates?.latKey)),
    lng: Number(get(workshop, config?.coordinates?.lngKey)),
  };
  return (
    <InfoWindow options={options} position={center} onCloseClick={hideInfoCard}>
      <div className="info-window">
        <Button onClick={hideInfoCard} className="close">
          <Icon name="close" />
        </Button>
        <div style={{ background: '#F0F0F0', padding: 4 }}>
          <Header as="h2">{workshop.title}</Header>
          {config?.logo?.showInInfocard && (
            <img
              src={workshop[config?.logo?.field]?.uri}
              alt=""
              style={{ height: '4em', padding: '0.3em 0 0 0', float: 'right' }}
            />
          )}
          {config?.infos?.map((info: Info) => {
            const { label, field, uri } = info;
            const content = get(workshop, field);
            let correctedUri;
            if (uri?.indexOf(':') === -1) {
              correctedUri = replaceUriValues(uri, workshop);
            } else {
              correctedUri = replaceValues(uri, workshop);
            }

            return (
              content && (
                <div key={info.field} className={`info info--${info.field.replace(/\./g, '-')}`}>
                  <p className="label">{label}</p>
                  <p className="content">
                    {uri ? (
                      <a
                        href={correctedUri}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() => trackEvent(eventTags.ITEM_ACTION_CLICK, { field, workshop })}
                      >
                        {content}
                      </a>
                    ) : (
                      <>{content}</>
                    )}
                  </p>
                </div>
              )
            );
          })}
        </div>
        <Button primary size="small" as={EntityLink} entity={workshop}>
          {t('global-search.see-more')}
        </Button>
      </div>
    </InfoWindow>
  );
};

export default InfoCard;
