import cx from 'classnames';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Linkifier from 'react-linkifier';
import { Button, Feed, Icon, Image, Segment } from 'semantic-ui-react';

import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { ensureName } from '../../networking/utils/chat-profile.utils';
import { useMe } from '../../profile/hooks';
import { getString } from '../../utils';
import Images from '../../utils/Images';
import { avatarWithFallbackWhenMissing } from '../../utils/avatarUtils';
import { dateFNSLocale } from '../../utils/date';
import { sweetConfirmAlert } from '../../utils/popupUtils';
import { UserFlag } from '../Avatars/UserFlag';
import { ChatMeetingCard } from './Appointments/ChatMeetingCard';
import './ChatFeed.scss';

const { anonymousUserName = '' } = window.__DATA__;

function formatLastName(name) {
  return name ? `${name.slice(0, 1)}.` : '';
}

function shortName(profile) {
  if (!profile?.firstName && !profile?.firstName) return anonymousUserName;
  return `${profile?.firstName} ${formatLastName(profile?.lastName)}`;
}
function longName(profile) {
  return `${profile?.firstName || ''} ${profile?.lastName || ''}`;
}

function formatProfileName(profile) {
  return profile?.isSponsor ? longName(profile) : shortName(profile);
}

const AppointmentUpdateMessage = ({ message, isSelf, profile }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx('Chat--Message', {
        [`event--type-${message.type}`]: !!message.type,
        'event--self': isSelf,
        'event--other': !isSelf,
        'event--sponsor': profile?.isSponsor,
      })}
    >
      {t(`networking.appointment.message-${isSelf ? 'you' : 'other'}-${message.action}`, {
        user: profile,
      })}
    </div>
  );
};

const VisioMessage = ({ message, isSelf, onEnterVisio }) => {
  const visio = message.visio?.visio || message.visio;
  return (
    <Feed.Content className="Chat--Feed__VisioRequest">
      <Segment.Group raised style={{ width: 280 }}>
        <Segment>
          <Icon bordered inverted size="large" color="blue" name="call" />{' '}
          {isSelf ? 'Appel sortant' : 'Appel entrant'}
        </Segment>
        <Segment textAlign="right">
          {visio.state === 'started' && (
            <Button primary onClick={() => onEnterVisio(visio)}>
              Rejoindre l'appel
            </Button>
          )}
          {visio.state !== 'started' && <Button disabled>Appel terminé</Button>}
        </Segment>
      </Segment.Group>
    </Feed.Content>
  );
};

const ChatFeed = ({
  feedHeader,
  messages,
  me,
  sponsorImage,
  onEnterVisio,
  onDelete,
  onUpdateMeeting,
}) => {
  useAutoRefresh(60 * 1000); // Refresh messages times...
  const user = useMe();
  const removeMessage = useCallback(
    async (id) => {
      if (!onDelete) return;
      const response = await sweetConfirmAlert({
        title: getString('chat.delete-message'),
        confirmButtonText: getString(`alert.confirm`),
        cancelButtonText: getString(`alert.cancel`),
      });
      if (!response) return;
      onDelete(id);
    },
    [onDelete],
  );

  return (
    <Feed className="feed--chat Chat--Feed" key="Chat--Feed">
      <div className="Chat--PushDown" />
      {feedHeader && feedHeader.text && (
        <div className="Chat--HeaderMessage">{feedHeader.text}</div>
      )}
      {messages &&
        messages.map((message) => {
          const {
            appointment,
            type,
            text,
            emitter,
            profile: messageProfile,
            createdAt,
            id: messageId,
          } = message;
          const isSelf = emitter === me;
          const profile = isSelf ? ensureName(user) : messageProfile;
          const avatar = (
            <Feed.Label>
              <Image src={avatarWithFallbackWhenMissing(profile?.thumbnail)} avatar size="tiny" />
              <UserFlag user={profile} />
            </Feed.Label>
          );

          if (type === 'appointment-update') {
            return <AppointmentUpdateMessage message={message} isSelf={isSelf} profile={profile} />;
          }

          return (
            <Feed.Event
              className={cx('Chat--Message', {
                [`event--type-${type}`]: !!type,
                'event--self': isSelf,
                'event--other': !isSelf,
                'event--sponsor': profile?.isSponsor,
              })}
              key={messageId}
            >
              {avatar}
              <Feed.Content>
                <Feed.Summary>
                  {profile?.isSponsor && sponsorImage && (
                    <img
                      className="image--sponsor"
                      alt="sponsor"
                      {...Images.srcSet.maxWidth(sponsorImage, 200)}
                    />
                  )}
                  {/* TODO : simplify once ovh ends name is for retro compatibility */}
                  <span>{profile?.name ? profile?.name : formatProfileName(profile)}</span>
                </Feed.Summary>

                {!type &&
                  text &&
                  text
                    .filter((msg) => msg.text)
                    .map(({ id, text: rawText }) => (
                      <Feed.Content key={`message-${id}`} className="Chat--Feed__TextContent">
                        <Linkifier target="_blank">
                          <p>{rawText} </p>
                          {isSelf && onDelete && (
                            <Button
                              circular
                              negative
                              compact
                              size="mini"
                              className="Chat--Feed__DeleteButton"
                              onClick={() => removeMessage(id)}
                              icon="delete"
                            />
                          )}
                        </Linkifier>
                      </Feed.Content>
                    ))}
                {type === 'appointment' && !!onUpdateMeeting && (
                  <ChatMeetingCard
                    appointment={appointment}
                    messageId={messageId}
                    onUpdateMeeting={onUpdateMeeting}
                  />
                )}
                {!!message.visio && !!onEnterVisio && (
                  <VisioMessage message={message} isSelf={isSelf} onEnterVisio={onEnterVisio} />
                )}
                <Feed.Meta>
                  <Feed.Date
                    content={formatDistanceToNow(createdAt, {
                      addSuffix: true,
                      locale: dateFNSLocale(),
                    })}
                  />
                </Feed.Meta>
              </Feed.Content>
            </Feed.Event>
          );
        })}
    </Feed>
  );
};

ChatFeed.defaultProps = {
  feedHeader: undefined,
  sponsorImage: undefined,
  onDelete: undefined,
  onEnterVisio: undefined,
  onUpdateMeeting: undefined,
};

const profilePropTypes = {
  isSponsor: PropTypes.bool,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  name: PropTypes.string, // TODO : retro compatibility should be deleted once ovh ends
  thumbnail: PropTypes.object,
};

const messagePropTypes = {
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  emitter: PropTypes.string.isRequired,
  profile: PropTypes.shape(profilePropTypes),
};

const textMessagePropTypes = {
  ...messagePropTypes,
  text: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const visioRequestPropTypes = {
  ...messagePropTypes,
  type: PropTypes.exact('visio-request'),
  session: PropTypes.shape({
    type: PropTypes.string,
    sessionId: PropTypes.string,
  }),
};

ChatFeed.propTypes = {
  feedHeader: PropTypes.shape({
    text: PropTypes.string,
  }),
  messages: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(textMessagePropTypes),
      PropTypes.shape(visioRequestPropTypes),
    ]),
  ).isRequired,
  me: PropTypes.string.isRequired,
  sponsorImage: PropTypes.shape({ uri: PropTypes.string }),
  onDelete: PropTypes.func,
  onEnterVisio: PropTypes.func,
  onUpdateMeeting: PropTypes.func,
};

export default ChatFeed;
