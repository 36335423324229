/* eslint-disable no-restricted-syntax, guard-for-in */
import { parseISO } from 'date-fns';
import countBy from 'lodash/countBy';
import every from 'lodash/every';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import some from 'lodash/some';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import moment from 'moment';
import { useMemo } from 'react';

import { findOverlappingEvent } from '../../../utils/agendaUtils';

export function findPossibleRequiredSlots(requiredChoiceTimeSlots, combinedSelectedSessions) {
  const sessions = values(combinedSelectedSessions);
  const allRequiredTimeSlots = Object.keys(requiredChoiceTimeSlots);

  const requiredSelectedCount = values(pick(combinedSelectedSessions, allRequiredTimeSlots)).length;

  // Some time slots are mutually exclusive... Remove those slots
  const requiredTimeSlots = allRequiredTimeSlots.filter((date) => {
    const { slots, startDate } = requiredChoiceTimeSlots[date];
    // Keep validates times
    if (startDate in combinedSelectedSessions) return true;

    // If 1 session overlaps all slots, this timeSlot can't be selected
    return !every(slots, (s) => findOverlappingEvent(sessions, s));
  });

  const remainingToPickCount = requiredTimeSlots.length - requiredSelectedCount;
  const hasRequiredSlots = requiredSelectedCount >= requiredTimeSlots.length;
  return {
    requiredTimeSlots,
    remainingToPickCount,
    requiredSelectedCount,
    hasRequiredSlots,
  };
}

export function formatDate(date, timezone, format) {
  return moment(parseISO(date)).tz(timezone).format(format);
}

export function formatWorkshops(workshops, timezone) {
  const groupByFormat = (date) => formatDate(date, timezone, 'HH:mm');
  const formattedWorkshops = workshops.map((item) => ({
    ...item,
    startDateTz: moment(parseISO(item?.startDate)).tz(timezone),
    endDateTz: moment(parseISO(item?.endDate)).tz(timezone),
    groupByTime: `${groupByFormat(item?.startDate)} - ${groupByFormat(item?.endDate)}`,
    day: formatDate(item?.startDate, timezone, 'YYYY-MM-DD'),
  }));

  const days = Object.keys(
    groupBy(workshops, (item) => {
      const value = get(item, 'startDate');
      if (!value) return '';
      return formatDate(item?.startDate, timezone, 'YYYY-MM-DD');
    }),
  );
  return { days, formattedWorkshops };
}

/**
 * If we have a mandatory workshop, hide the other ones
 * @param {*} workshops
 */
export function hideNonRegistrableWorkshops(workshops) {
  const hasMandatory = some(workshops, (w) => w.mandatory);
  if (hasMandatory) {
    // Keep only mandatory workshops
    return workshops.filter((w) => w.mandatory);
  }
  return workshops;
}

function numberOrUndefined(value) {
  if (typeof value === 'number') return value;
  if (Number.isNaN(value)) return undefined;
  return parseInt(value, 10);
}

export function generateDateGroups(workshops) {
  const groups = groupBy(
    orderBy(
      workshops,
      ['startDate', 'endDate', (w) => numberOrUndefined(w.order)],
      ['asc', 'desc', 'asc'],
    ),
    (item) => {
      const value = get(item, 'groupByTime');
      if (!value) return '';
      return value;
    },
  );

  const times = Object.keys(groups);
  return times.map((key) => ({
    id: key,
    label: key,
    items: groups[key],
    itemsByDay: groupBy(groups[key], 'day'),
  }));
}

export function useRequiredTimeSlots(workshops) {
  return useMemo(() => {
    const required = {};
    const workshopsBySlots = groupBy(workshops, (w) => `${w.startDate} ${w.endDate}`);
    Object.entries(workshopsBySlots).forEach(([, items]) => {
      const visibleItems = hideNonRegistrableWorkshops(items);
      if (some(visibleItems, (w) => w.choiceRequired)) {
        const { startDate } = visibleItems[0];
        const slots = uniq(visibleItems.map((i) => i.endDate)).map((endDate) => ({
          startDate,
          endDate,
        }));
        if (required[startDate]) {
          required[startDate].slots.push(...slots);
        } else {
          required[startDate] = {
            required: true,
            startDate,
            slots,
          };
        }
      }
    });
    return required;
  }, [workshops]);
}

export function useMinCountTotal(constraints) {
  return useMemo(() => {
    if (isEmpty(constraints?.minCount)) return 0;
    return sum(
      Object.keys(constraints.minCount).map((key) => sum(values(constraints.minCount[key]))),
    );
  }, [constraints]);
}

export function useCheckConstraints(sessions, constraints) {
  return useMemo(() => {
    if (isEmpty(constraints?.minCount)) return { valid: true, totalSelected: 0 };
    let totalSelected = 0;
    const selected = {};
    for (const key in constraints.minCount) {
      const sessionsGroupedByKey = countBy(sessions, key);
      const keyValues = Object.keys(constraints.minCount[key]);
      for (const value of keyValues) {
        const selectedCount = sessionsGroupedByKey[value] ?? 0;
        totalSelected += selectedCount;
        selected[`${key}.${value}`] =
          !!selectedCount && selectedCount >= constraints.minCount[key][value];
      }
    }
    return { valid: !values(selected).includes(false), totalSelected };
  }, [constraints, sessions]);
}
