import React, { memo, useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import GlobalModalManager from '../../../components/GlobalModalManager';
import { Publication } from '../../types/SocialWallV2.types';
import {
  DetailedPublicationModal,
  DetailedPublicationModalProps,
} from './DetailedPublicationModal';

export const LoadingDetailedPublicationModal = memo(
  (detailedProps: DetailedPublicationModalProps & { posts: Publication[] }) => {
    const { publicationId, posts, ...restProps } = detailedProps;

    const modalKey = 'detailed-publication';
    useEffect(() => {
      if (!posts?.length || !publicationId) return;
      const isModalOpen = GlobalModalManager.isModalOpen({ key: modalKey });

      if (!isModalOpen) {
        GlobalModalManager.open(
          DetailedPublicationModal,
          {
            publicationId,
            ...restProps,
          },
          modalKey,
        );
      } else {
        GlobalModalManager.update(modalKey, {
          publicationId,
        });
      }
    }, [posts, publicationId, restProps]);

    return posts ? (
      <></>
    ) : (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  },
);
