import { FC, createContext, useContext, useMemo } from 'react';

type CurrentScreenContextProps = {
  screenId: string;
  screen: any;
};

export const CurrentScreenContext = createContext<CurrentScreenContextProps>({
  screenId: '',
  screen: {},
});

export const ProvideCurrentScreen: FC<CurrentScreenContextProps> = ({
  screenId,
  screen,
  children,
}) => {
  const value = useMemo(() => ({ screenId, screen }), [screenId, screen]);
  return <CurrentScreenContext.Provider value={value}>{children}</CurrentScreenContext.Provider>;
};

export function useCurrentScreen(): CurrentScreenContextProps {
  return useContext(CurrentScreenContext);
}
