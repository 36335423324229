/* eslint-disable operator-linebreak */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import some from 'lodash/some';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Grid, Header, Icon, Message, Modal, Table } from 'semantic-ui-react';

import EntityLink from '../../../components/EntityLink';
import { bem } from '../../../core/design/bem';
import { useAutoRefresh } from '../../../hooks/useAutoRefresh';
import { workshopArrayProptypes, workshopProptypes } from '../../../propTypes';
import { generateWorkshopStatus } from '../../../utils/groupUtils';
import { orderByItems } from '../../../utils/hooks';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import { sweetAlert } from '../../../utils/popupUtils';
import WorkshopLineFields, {
  WorkshopLineActions,
  findOverlappingSession,
  isSessionDisabled,
} from './WorkshopLineFields';
import WorkshopMultipleValidation from './WorkshopMultipleValidation';
import './WorkshopTable.scss';
import {
  findPossibleRequiredSlots,
  formatWorkshops,
  generateDateGroups,
  hideNonRegistrableWorkshops,
  useRequiredTimeSlots,
} from './utils';

const css = bem('WorkshopTable');
const translationPrefix = 'workshops.workshop';

export function showErrors(errors) {
  sweetAlert({
    icon: 'warning',
    width: '60%',
    html: <Message warning header="Attention !" list={errors?.map((e) => e.message)} />,
  });
}

const registrationFields = [
  'title',
  'collection',
  'startDate',
  'endDate',
  'constraints',
  'category',
];

const Actions = ({ workshop }) => {
  const { replays = [] } = workshop;
  const { t } = useTranslation();
  return (
    <div className="actions">
      <Button size="tiny" className="watch-later">
        {t(`${translationPrefix}.watch-later`)}
      </Button>
      {replays.length > 0 && (
        <Button size="tiny" className="replay">
          {t(`${translationPrefix}.replay`)}
        </Button>
      )}
    </div>
  );
};

Actions.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

function removeImpossibleSessions(sessions) {
  const mandatorySessions = sessions.filter((s) => s.mandatory);
  return sessions.filter((session) => {
    return !findOverlappingSession(session, mandatorySessions);
  });
}

function WorkshopTable(props) {
  const { t } = useTranslation();
  const [selectedSessions, setSelectedSessions] = useState({});
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { desktop } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const registrations = useSelector((state) => state.registrations.registrations);
  const now = useAutoRefresh(10000);
  const registrationsByDate = mapValues(keyBy(registrations, 'startDate'), (r) => {
    return {
      ...pick(r, registrationFields),
      workshopId: r.workshopId,
      sessionId: r.sessionId || r._id,
    };
  });

  const {
    filteredWorkshopIds,
    workshopList,
    workshopUnfilteredList,
    timezone,
    fields: tableFields,
    actions,
    actionsConfig,
    splitDays,
    ...rest
  } = props;
  const { position = 'right' } = actionsConfig;

  const {
    registerOncePerSlot,
    groupHoursInCell = true,
    validationConfig,
    orderBy: orderByConfig,
  } = rest;
  const { days, formattedWorkshops, workshops, workshopsByDay } = useMemo(() => {
    const workshopsWithStatus = generateWorkshopStatus(workshopList);
    const data = formatWorkshops(workshopsWithStatus, timezone);
    const byDay = groupBy(data.formattedWorkshops, 'day');
    return {
      workshops: workshopsWithStatus,
      days: data.days,
      formattedWorkshops: data.formattedWorkshops,
      workshopsByDay: mapValues(byDay, removeImpossibleSessions),
    };
  }, [workshopList, timezone]);

  const sessionsById = useMemo(() => keyBy(workshops, '_id'), [workshops]);
  const requiredChoiceTimeSlots = useRequiredTimeSlots(workshopUnfilteredList);

  const handleSelect = (session) => {
    const { startDate, _id, workshopId } = session;
    const isSession = !!workshopId;
    const sessionId = workshopId || _id;
    const newSelectedSessions =
      startDate in selectedSessions
        ? omit(selectedSessions, startDate)
        : {
            ...selectedSessions,
            [startDate]: {
              ...pick(session, registrationFields),
              workshopId: isSession ? workshopId : sessionId,
              sessionId: isSession ? _id : undefined,
            },
          };
    setSelectedSessions(newSelectedSessions);
  };

  const fields = desktop ? tableFields : orderBy(tableFields, (f) => f?.mobile?.order, 'asc');

  // Combine registration and current selection, and omit empty registrations
  const combinedSelectedSessions = omitBy(
    {
      ...registrationsByDate,
      ...selectedSessions,
    },
    (v) => !v,
  );

  const { hasRequiredSlots } = findPossibleRequiredSlots(
    requiredChoiceTimeSlots,
    combinedSelectedSessions,
  );

  if (!workshops.length && !Object.keys(combinedSelectedSessions).length) return null;

  if (splitDays) {
    return (
      <>
        {days.map((day) => {
          const dayTimes = generateDateGroups(get(workshopsByDay, day, []));
          return (
            <Grid key={day} stackable className={css().toString()}>
              <Header className="day">
                {t('program.table.day', { day: dayTimes?.[0]?.items?.[0]?.startDate || day })}
              </Header>
              <Table stackable structured>
                <Table.Header>
                  <Table.Row>
                    {position === 'left' && <Table.HeaderCell className="actions" />}
                    {fields.map((field) => (
                      <Table.HeaderCell key={field.key} className={field.key}>
                        {field.label}
                      </Table.HeaderCell>
                    ))}
                    {position === 'right' && <Table.HeaderCell className="actions" />}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {dayTimes.map(({ id, items }) => {
                    const visibleItems = orderByItems(
                      hideNonRegistrableWorkshops(items),
                      orderByConfig,
                    );
                    const choiceRequired = some(visibleItems, (w) => w.choiceRequired);
                    return (
                      <React.Fragment key={id}>
                        {visibleItems.map((session, index) => {
                          const { startDate, endDate } = session;
                          const isSelected =
                            combinedSelectedSessions[startDate]?.sessionId === session._id;
                          const isDisabled = isSessionDisabled(session, {
                            sessionIds: combinedSelectedSessions,
                            registerOncePerSlot,
                            sessionsById,
                          });
                          const workshopLineProps = {
                            index,
                            isDisabled,
                            visibleItems,
                            choiceRequired,
                            fields,
                            groupHoursInCell,
                            now,
                          };
                          return (
                            <React.Fragment key={session._id}>
                              {!groupHoursInCell && index === 0 && (
                                <Table.Row className="hour">
                                  <Table.Cell colSpan={fields.length}>
                                    <Header as="h4">
                                      {t('program.table.time', { startDate, endDate })}
                                    </Header>
                                  </Table.Cell>
                                </Table.Row>
                              )}
                              <Table.Row
                                className={cx(
                                  'session',
                                  index === 0 ? 'new-slot' : undefined,
                                  `category--${session?.category || session?.category?.[0] || ''}`,
                                )}
                              >
                                {position === 'left' && (
                                  <WorkshopLineActions
                                    actions={actions}
                                    isDisabled={isDisabled}
                                    isSelected={isSelected}
                                    session={session}
                                    onSelect={handleSelect}
                                  />
                                )}
                                <WorkshopLineFields session={session} {...workshopLineProps} />
                                {position === 'right' && (
                                  <WorkshopLineActions
                                    actions={actions}
                                    session={session}
                                    isSelected={isSelected}
                                    isDisabled={isDisabled}
                                    onSelect={handleSelect}
                                  />
                                )}
                              </Table.Row>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid>
          );
        })}
        {((Object.keys(selectedSessions).length > 0 && registerOncePerSlot) ||
          !hasRequiredSlots) && (
          <WorkshopMultipleValidation
            collection={rest.collection}
            config={validationConfig}
            selectedSessions={selectedSessions}
            combinedSelectedSessions={combinedSelectedSessions}
            requiredChoiceTimeSlots={requiredChoiceTimeSlots}
            onSelect={() => setSelectedSessions({})}
          />
        )}
      </>
    );
  }

  const times = generateDateGroups(
    desktop
      ? formattedWorkshops
      : formattedWorkshops.filter((w) => w.day === (selectedDay || days?.[0])),
  );

  return (
    <Grid stackable className={css().toString()}>
      {!desktop && (
        <Dropdown
          icon="chevron down"
          className={css('days').toString()}
          value={selectedDay || days?.[0]}
          fluid
          selection
          onChange={(_e, { value }) => setSelectedDay(value)}
          options={days.map((day) => ({
            key: day,
            text: t('program.table.day', { day }),
            value: day,
          }))}
        />
      )}
      <Table unstackable striped>
        {desktop && (
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="time" style={{ minWidth: 130, visibility: 'hidden' }} />
              {days.map((day) => (
                <Table.Cell key={day}>{t('program.table.day', { day })}</Table.Cell>
              ))}
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>
          {times.map(({ id, items, itemsByDay }) => {
            const session = items[0];
            return (
              <Table.Row key={id}>
                <Table.Cell className="time">
                  {t('program.table.time', {
                    startDate: session?.startDateTz,
                    endDate: session?.endDateTz,
                  })}
                </Table.Cell>
                {days.map((day) => {
                  const dayItems = get(itemsByDay, day, []);
                  return (
                    <Table.Cell
                      className={css('workshops').toString()}
                      style={{ cursor: 'pointer', padding: 0 }}
                      key={day}
                    >
                      {dayItems.map((workshop) => {
                        const { _id, workshopId, title } = workshop;
                        const sessionId = workshopId || _id;
                        const isHighlighted = filteredWorkshopIds?.includes(sessionId) || false;
                        return (
                          <div
                            key={sessionId}
                            className={css('workshop', { workshopId: sessionId })
                              .state({ highlighted: isHighlighted })
                              .toString()}
                          >
                            <EntityLink
                              className="title"
                              entity={{ ...workshop, kind: 'workshops' }}
                            >
                              {title}
                            </EntityLink>
                            {desktop && <Actions workshop={workshop} />}
                            {!desktop && (
                              <>
                                <div
                                  style={{ width: 30 }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIsOpen(sessionId);
                                  }}
                                >
                                  <Icon className="ellipsis" name="ellipsis vertical" />
                                </div>
                                {isOpen === sessionId && (
                                  <Modal
                                    className={css('options').toString()}
                                    open
                                    closeIcon
                                    onClose={() => setIsOpen(false)}
                                  >
                                    <Modal.Header>Options</Modal.Header>
                                    <Modal.Content>
                                      <Actions workshop={workshop} />
                                    </Modal.Content>
                                  </Modal>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Grid>
  );
}

WorkshopTable.defaultProps = {
  actions: [],
  actionsConfig: {},
  fields: [{ key: 'title' }],
  filteredWorkshopIds: undefined,
  groupBy: undefined,
  splitDays: true,
  timezone: 'Europe/London',
  workshopList: [],
  workshopUnfilteredList: [],
};

WorkshopTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  actionsConfig: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.object),
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string),
  groupBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  splitDays: PropTypes.bool,
  timezone: PropTypes.string,
  workshopList: workshopArrayProptypes,
  workshopUnfilteredList: workshopArrayProptypes,
};

export default WorkshopTable;
