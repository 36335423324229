import React, { useMemo } from 'react';
import { Segment, Statistic } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { evalValue } from '../../utils/constraints';
import { bem } from '../../core/design/bem';
import './AgendaStats.scss';

const cxStats = bem('agenda-stats');

function makeFilter(filter) {
  if (!filter) return () => true;
  if (filter.type === 'jsep') return (item) => evalValue(filter, item);
  return true;
}
function computeStats(items, stats) {
  return (
    stats.map((stat) => ({
      ...stat,
      value: items.filter(makeFilter(stat.filter)).length,
    })) || []
  );
}

function AgendaStats(props) {
  const { events, stats } = props;
  const statistics = useMemo(() => computeStats(events, stats), [events, stats]);
  if (statistics.length === 0) return null;
  return (
    <Segment className={cxStats()}>
      <Statistic.Group className={cxStats('statistics').toString()}>
        {statistics.map(({ key, label, value }) => {
          return (
            <Statistic key={key}>
              <Statistic.Value>{value}</Statistic.Value>
              <Statistic.Label>{label}</Statistic.Label>
            </Statistic>
          );
        })}
      </Statistic.Group>
    </Segment>
  );
}

AgendaStats.defaultProps = {
  events: [],
  stats: [],
};

AgendaStats.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  stats: PropTypes.arrayOf(PropTypes.object),
};

export default AgendaStats;
