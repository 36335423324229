/* eslint-disable no-restricted-syntax */

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable class-methods-use-this */
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import omitBy from 'lodash/omitBy';

import pubsubService from '../pubsub.service';
import WsFirestoreDocument from './WsFirestoreDocument';

type FirestoreDoc = { id: string; data: () => Record<string, any> };

type FirestoreDocChange = {
  type: 'removed' | 'added' | 'modified';
  doc: FirestoreDoc;
};

type CollectionSnapshot = {
  docs: FirestoreDoc[];
  docChanges: () => FirestoreDocChange[];
};
type CollectionSnapshotCallback =
  | { next: (snapshot: CollectionSnapshot) => void }
  | ((snapshot: CollectionSnapshot) => void);

const noop = () => {};

function computeChanges(prevDocs: FirestoreDoc[], newDocs: FirestoreDoc[]) {
  const prevById = keyBy(prevDocs, 'id');
  const newById = keyBy(newDocs, 'id');
  const changes: FirestoreDocChange[] = [];

  // Handle removed
  for (const prev of prevDocs) {
    if (!(prev.id in newById)) {
      changes.push({ type: 'removed', doc: prev });
    }
  }

  // Handle added
  for (const next of newDocs) {
    if (!(next.id in prevById)) {
      changes.push({ type: 'added', doc: next });
    } else {
      const prev = prevById[next.id];
      if (!isEqual(prev.data(), next.data())) {
        changes.push({ type: 'modified', doc: next });
      }
    }
  }

  return changes;
}

export default class WsFirestoreCollection {
  // eslint-disable-next-line no-useless-constructor
  constructor(private path: string, private options = {}) {}

  doc(docId: string): WsFirestoreDocument {
    return new WsFirestoreDocument(`${this.path}/${docId}`);
  }

  topic(): string {
    if (isEmpty(this.options)) return this.path;
    return `${this.path}?options=${encodeURIComponent(JSON.stringify(this.options))}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cloneWithOptions(newOptions: any): WsFirestoreCollection {
    const options = omitBy({ ...this.options, ...newOptions }, isUndefined);
    return new WsFirestoreCollection(this.path, options);
  }

  where(key: string, op: string, value: unknown): WsFirestoreCollection {
    return this.cloneWithOptions({ where: { key, op, value } });
  }

  orderBy(key: string, order: 'asc' | 'desc'): WsFirestoreCollection {
    return this.cloneWithOptions({ order: { key, order } });
  }

  startAfter(doc: any): WsFirestoreCollection {
    return this.cloneWithOptions({ startAfter: doc.id });
  }

  limit(limit: number): WsFirestoreCollection {
    return this.cloneWithOptions({ limit });
  }

  onSnapshot(callback: CollectionSnapshotCallback): any {
    if (!callback) return noop;

    const fun =
      (typeof callback === 'object' && callback.next) ||
      (typeof callback === 'function' && callback);

    if (!fun) {
      console.error('Invalid callback function', callback);
      return noop;
    }

    let latestDocs: FirestoreDoc[] = [];

    // Subscribe :)
    return pubsubService.onCollection(this.topic(), (res: any[]) => {
      // Wrap like it would be in firebase
      const prevDocs = latestDocs;
      const newDocs = res.map((d) => ({ id: d.id, data: () => d }));
      latestDocs = newDocs;

      const snapshot = {
        docs: latestDocs,
        docChanges: () => {
          // compute changes from new vs prev...
          return computeChanges(prevDocs, newDocs);
        },
      } as CollectionSnapshot;
      fun(snapshot);
    });
  }

  get(): Promise<CollectionSnapshot> {
    return new Promise((resolve) => {
      // Build snapshot and exit
      const exit = this.onSnapshot((res) => {
        exit();
        resolve(res);
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(document: any): Promise<void> {
    return new Promise((resolve) => {
      pubsubService.add(this.path, document);
      setTimeout(resolve, 100);
    });
  }
}
