/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMount } from 'ahooks';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';

import { fetchAgenda } from '../../../agenda/store/agenda.actions';
import '../../../home/blocks/WorkshopsBlock.scss';
import store from '../../../shared/Store';
import { fetchRegistrations } from '../../../store/actions';
import { getString, throttleDispatchFetch } from '../../../utils';
import { buildMatch } from '../../../utils/filter.utils';
import {
  useGroupWorkshopsWithSessions,
  useSyncedCollectionWorkshopSessions,
} from '../../../workshop-session/store/workshopSessions.hooks';
import { workshopListComponents } from '../../../workshops/blocks/workshopListComponents';
import { fetchWorkshops } from '../../../workshops/store/workshops.actions';
import { useSyncedCollectionWorkshops } from '../../../workshops/store/workshops.hooks';
import { useRegistrationsById } from '../../../workshops/utils/workshopRegistrationUtils';
import BlockContainer from '../../BlockContainer';
import NotFoundBlock from '../../NotFoundBlock';
import WorkshopList, { filterRegistered } from '../../workshops/list/WorkshopList';
import { ContainerProps } from '../types';
import './WorkshopsBlock.scss';

enum WorkshopsBlockFilterMode {
  IDS = 'ids',
  FILTER = 'filter',
  ALL = 'all',
}

type ListTemplate = {
  variant?: string;
  minHeight?: number;
};

type WorkshopsBlockProps = {
  workshopsIds?: string[];
  filters?: any;
  hasSessions?: boolean;
  showOnlyRegistered?: boolean;
  filterMode?: WorkshopsBlockFilterMode;
  itemProps?: any;
  template?: ListTemplate;
  collection?: string;
  container?: ContainerProps;
  orderBy?: string[];
  // eslint-disable-next-line react/require-default-props
  groupBy?: any;
  mode?: 'grid' | 'table' | 'with-registration';
};

const filterWorkshops = (
  workshopByIds: Record<string, any>,
  filterMode?: WorkshopsBlockFilterMode,
  workshopIds?: string[],
  filters?: any,
) => {
  switch (filterMode) {
    case WorkshopsBlockFilterMode.IDS:
      return workshopIds?.map((w) => workshopByIds[w]) || [];
    case WorkshopsBlockFilterMode.FILTER:
      return values(workshopByIds).filter(buildMatch(filters));
    default:
      return values(workshopByIds);
  }
};

const throttledFetch = throttleDispatchFetch(() => {
  store.reduxStore.dispatch(fetchWorkshops());
  store.reduxStore.dispatch(fetchRegistrations());
  store.reduxStore.dispatch(fetchAgenda());
});

const WorkshopsBlock = (props: WorkshopsBlockProps): JSX.Element => {
  const {
    workshopsIds,
    filters,
    filterMode = WorkshopsBlockFilterMode.ALL,
    hasSessions,
    showOnlyRegistered,
    template,
    itemProps,
    mode,
    groupBy,
    collection = 'workshops',
    container,
    orderBy: orderByConfig = ['startDate', 'order', 'title'],
  } = props;

  useMount(() => throttledFetch());
  let workshops = useSyncedCollectionWorkshops(collection);

  const sessions = useSyncedCollectionWorkshopSessions(collection);
  workshops = useGroupWorkshopsWithSessions(workshops, hasSessions ? sessions : undefined);
  workshops = filterWorkshops(workshops, filterMode, workshopsIds, filters);
  workshops = orderBy(workshops, orderByConfig);

  const registrations = useRegistrationsById(hasSessions);
  const filteredWorkshops = filterRegistered(workshops, registrations, showOnlyRegistered);

  const WorkshopsComponent = get(workshopListComponents, mode!, WorkshopList);

  return (
    <BlockContainer {...container} className="block--WorkshopsBlock">
      <WorkshopsComponent
        centered
        workshopList={filteredWorkshops}
        itemProps={{ ...itemProps, hasSessions }}
        template={template}
        // hasSessions={hasSessions}
        groupBy={groupBy}
      />
      {!filteredWorkshops.length && <NotFoundBlock {...getString(`workshops.not-found`)} />}
    </BlockContainer>
  );
};

WorkshopsBlock.defaultProps = {
  workshopsIds: [],
  filterMode: WorkshopsBlockFilterMode.IDS,
  filters: undefined,
  hasSessions: false,
  showOnlyRegistered: false,
  itemProps: {},
  template: { variant: 'card' },
  collection: 'workshops',
  container: {},
  orderBy: ['startDate', 'order', 'title'],
  mode: 'grid',
};

export default WorkshopsBlock;
