/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Image } from 'semantic-ui-react';

import avatar from '../../../../assets/avatar-neutre.png';
import ExpiringNavLink from '../../../../components/ExpiringNavLink';
import ImageIcon from '../../../../components/ImageIcon';
import SquareImage from '../../../../components/SquareImage';
import { useConfig } from '../../../../config/config.context';
import { useDisconnectionMode } from '../../../../networking/hooks/useDisconnectionMode';
import { useIsAnonymous, useMe } from '../../../../profile/hooks';

type BurgerUserProps = {
  config?: any;
  onDisconnect: any;
};

const BurgerUserDefaultProps = {
  config: {},
};

export const BurgerUser = ({ config, onDisconnect }: BurgerUserProps): JSX.Element | null => {
  const user = useMe();
  const { t } = useTranslation();
  const isAnonymous = useIsAnonymous();
  const { isPossible } = useDisconnectionMode();
  const { showUser, logoutIcon = 'log out', path = '/profile' } = config;
  if (!showUser) return null;
  const { thumbnail, firstName, lastName } = user;
  if (!showUser || isAnonymous) return null;
  return (
    <ExpiringNavLink className="sidebar--burger-menu__container--header__user" to={path}>
      {isAnonymous || !thumbnail ? (
        <Image
          className="thumbnail"
          src={avatar}
          style={{ maxHeight: 80, minWidth: 80 }}
          circular
        />
      ) : (
        <SquareImage
          as="img"
          src={thumbnail}
          alt={`${firstName} ${lastName}`}
          imageSize={80}
          className="thumbnail"
        />
      )}
      {!isAnonymous && isPossible && (
        <div className="nameAndLogout">
          <div className="fullname">
            {firstName || ''} {lastName || ''}
          </div>
          <div className="logout" onClick={onDisconnect}>
            <ImageIcon icon={logoutIcon} maxHeight={25} />
            <span>{t('menu.logout')}</span>
          </div>
        </div>
      )}
    </ExpiringNavLink>
  );
};

BurgerUser.defaultProps = BurgerUserDefaultProps;

export default BurgerUser;
