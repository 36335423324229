import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useScreenConfig } from '../../config/screens.context';
import { SlotDay } from '../components/Slots/SlotDay';
import { useStepContext } from './StepContext';

export const StepSelectSlotContent = () => {
  const { components = {}, allowOverlappingSessions } = useScreenConfig('appointments');
  const { days, onSelectStep } = useStepContext();
  return (
    <Grid
      className="step-select-slot-grid"
      columns={days.length}
      centered
      style={{ marginTop: 0, flexWrap: 'wrap' }}
      stackable
    >
      {days.map((day, index) => {
        const dayColumnStyle = days.length < 3 ? { minWidth: 320, flexGrow: 1 } : { flexGrow: 1 };
        return (
          <Grid.Column key={String(index)} style={dayColumnStyle}>
            <SlotDay
              {...day}
              onSelect={(slot) => onSelectStep('confirm', { slot })}
              {...components.slotDay}
              allowOverlappingSessions={allowOverlappingSessions}
            />
          </Grid.Column>
        );
      })}
    </Grid>
  );
};
