import times from 'lodash/times';
import React, { Fragment } from 'react';
import { aspectRatioToPercentage } from '../utils';

const SquareGridSpacer = ({
  rowCount,
  aspectRatio,
}: {
  rowCount: number;
  aspectRatio: string;
}): JSX.Element => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    {times(rowCount, (y) => (
      <div
        key={`row-${y}`}
        style={{
          paddingBottom: aspectRatioToPercentage(aspectRatio || '1 / 1'),
          gridColumn: `1 / 2`,
          gridRow: `${y + 1} / ${y + 2}`,
        }}
      />
    ))}
  </Fragment>
);

export default SquareGridSpacer;
