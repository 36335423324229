export const defaultStats = {
  VIDEO_VIEW_SECTION: {
    tag: 'VIDEO_VIEW_SECTION',
  },
  WEBINAR_ENTER: {
    tag: 'WEBINAR_ENTER',
  },
  QRCODE_SCAN: {
    tag: 'QRCODE_SCAN',
  },
  AGENDA_COMPLETE: {
    tag: 'AGENDA_COMPLETE',
  },
  ITEM_CLICK: {
    tag: 'CLICK',
  },
  NETWORKING_SEND_MESSAGE: {
    tag: 'NETWORKING_SEND_MESSAGE',
  },
  CTA_GO_TO: {
    tag: 'CLICK',
    payloadMapping: {
      actionType: 'cta_go_to',
      blockId: '{item._id}',
      blockType: '{item.type}',
      url: '{url}',
    },
  },
  DOWNLOAD_FILE: {
    tag: 'DOWNLOAD_FILE',
    payloadMapping: {
      actionType: 'download_file',
      entityType: 'file',
      blockId: '{item._id}',
      fileId: '{file._id}',
      fileField: '{field}',
      url: '{uri}',
      originalName: '{originalFilename}',
      title: '{title}',
    },
  },
  ITEM_CONTACT_CLICK: {
    tag: 'CLICK',
    payloadMapping: {
      actionType: 'item_contact_click',
      entityId: '{item._id}',
      entityName: '{item.name}',
      entityKind: '{item.kind}',
      entityCollection: '{item.collection}',
      entityCategory: '{item.category}',
      contactEmail: '{contact.email}',
      contactFirstName: '{contact.firstName}',
      contactLastName: '{contact.lastName}',
    },
  },
  ITEM_CTA_GO_TO: {
    tag: 'CLICK',
    payloadMapping: {
      actionType: 'item_cta_go_to',
      entityId: '{item._id}',
      entityName: '{item.name}',
      entityKind: '{item.kind}',
      entityCollection: '{item.collection}',
      entityCategory: '{item.category}',
      url: '{url}',
    },
  },
  ITEM_DOWNLOAD_FILE: {
    tag: 'DOWNLOAD_FILE',
    payloadMapping: {
      actionType: 'item_download_file',
      entityType: 'file',
      entityId: '{item._id}',
      entityName: '{item.name}',
      entityKind: '{item.kind}',
      entityCollection: '{item.collection}',
      entityCategory: '{item.category}',
      fileId: '{file._id}',
      fileField: '{field}',
      url: '{uri}',
      originalName: '{originalFilename}',
      title: '{title}',
    },
  },
  ITEM_VIDEO_DOWNLOAD: {
    tag: 'DOWNLOAD',
    payloadMapping: {
      actionType: 'item_video_download',
      entityType: 'video',
      entityId: '{item._id}',
      entityName: '{item.name}',
      entityKind: '{item.kind}',
      entityCollection: '{item.collection}',
      entityCategory: '{item.category}',
      title: '{videoTitle}',
      videoUrl: '{videoUrl}',
      originalName: '{originalFilename}',
    },
  },
  ITEM_VIEW_FILE: {
    tag: 'VIEW_FILE',
    payloadMapping: {
      actionType: 'item_view_file',
      entityType: 'file',
      entityId: '{item._id}',
      entityName: '{item.name}',
      entityKind: '{item.kind}',
      entityCollection: '{item.collection}',
      entityCategory: '{item.category}',
      fileId: '{file._id}',
      fileField: '{field}',
      url: '{uri}',
      originalName: '{originalFilename}',
      title: '{title}',
    },
  },
  ITEM_VIDEO_VIEW: {
    tag: 'VIEW',
    payloadMapping: {
      actionType: 'item_video_view',
      entityId: '{item._id}',
      entityType: 'video',
      title: '{videoTitle}',
      videoUrl: '{videoUrl}',
      originalName: '{originalFilename}',
      entityCollection: '{item.collection}',
      entityCategory: '{item.category}',
      entityName: '{item.name}',
      userVideoSession: '{userVideoSession}',
    },
  },
  LIST_DETAIL_ITEMS: {
    tag: 'VIEW',
    payloadMapping: {
      actionType: 'click_item',
      entityCollection: '{item.collection}',
      entityId: '{item._id}',
      entityName: '{item.name}',
      entityParentCategory: '{item.parent.category}',
      entityParentId: '{item.ownerId}',
      entityParentName: '{item.parent.company}',
      entityParentType: '{item.parent.collection}',
    },
  },
  LIST_DETAIL_ITEMS_GO_TO_PAGE: {
    tag: 'VIEW',
    payloadMapping: {
      actionType: 'click_parent_item',
      entityCollection: '{item.parent.collection}',
      entityCategory: '{item.parent.category}',
      entityId: '{item.parent._id}',
      entityName: '{item.parent.company}',
    },
  },
  REPLAY_VIDEO_LAUNCHED: {
    tag: 'REPLAY_VIDEO_LAUNCHED',
    payloadMapping: {
      entityType: 'workshops',
      entityId: '{workshop._id}',
      entityCategory: '{workshop.category}',
      replayId: '{replay._id}',
    },
  },
  SPONSOR_GO_TO_PAGE: {
    tag: 'VIEW',
    payloadMapping: {
      actionType: 'go_to_item_page',
      entityId: '{item._id}',
      entityName: '{item.name}',
      entityKind: '{item.kind}',
      entityCollection: '{item.collection}',
      entityCategory: '{item.category}',
    },
  },
  VIDEO_VIEW: {
    tag: 'VIEW',
    payloadMapping: {
      actionType: 'video_view',
      entityType: 'video',
      title: '{videoTitle}',
      videoUrl: '{videoUrl}',
      originalName: '{originalFilename}',
      userVideoSession: '{userVideoSession}',
    },
  },
  VIDEO_VIEW_TICK: {
    tag: 'VIDEO_VIEW_TICK',
    payloadMapping: {
      entityType: 'live',
      entityKind: '{item.kind}',
      entityId: '{item._id}',
      entityCollection: '{item.collection}',
      entityCategory: '{item.category}',
      userVideoSession: '{userVideoSession}',
      videoUrl: '{video.uri}',
      videoType: '{videoType}',
    },
    condition: {
      videoType: 'live',
    },
  },
  VIDEO_DOWNLOAD: {
    tag: 'DOWNLOAD',
    payloadMapping: {
      actionType: 'video_download',
      entityType: 'video',
      title: '{videoTitle}',
      videoUrl: '{videoUrl}',
      originalName: '{originalFilename}',
    },
  },
  VIEW_FILE: {
    tag: 'VIEW_FILE',
    payloadMapping: {
      actionType: 'view_file',
      entityType: 'file',
      blockId: '{item._id}',
      blockType: '{item.type}',
      fileId: '{file._id}',
      fileField: '{field}',
      url: '{uri}',
      originalName: '{originalFilename}',
      title: '{title}',
    },
  },
  WORKSHOP_VIEW_LIVE: {
    tag: 'LIVE_CLICK',
    payloadMapping: {
      workshopSessionId: '{workshop._id}',
      workshopId: '{workshop.workshopId}',
      entityCollection: '{workshop.collection}',
      entityCategory: '{workshop.category}',
    },
  },
};
