/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { bem } from '../../core/design/bem';
import { useMe } from '../../profile/hooks';
import { replaceValues } from '../../utils/stringUtils';
import CdnImage from '../CdnImage';
import { Image } from '../cms/types';
import AccessBlocks, { OpenConfig } from './components/AccessBlocks';
import { OpeningSlot, OpeningSlotOptions, useOpeningSlots } from './hooks/useOpeningSlots';
import './RestrictOpeningTimesBlock.scss';

const css = bem('RestrictOpeningTimesBlock');

type ClosedConfig = {
  leftImage: Image;
  message?: string;
  openTimesMessage?: string;
  showOpenTimes?: boolean;
  // countdown: // TODO ?
};

export type RestrictOpeningTimesProps = {
  blocks: any;
  slots: OpeningSlot[];
  slotOptions?: OpeningSlotOptions;
  openConfig?: OpenConfig;
  closedConfig?: ClosedConfig;
};

export const RestrictOpeningTimesBlock = ({
  blocks,
  slots,
  slotOptions,
  openConfig,
  closedConfig,
}: RestrictOpeningTimesProps): JSX.Element | null => {
  const user = useMe();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { open, todaySlots, currentSlot } = useOpeningSlots(slots, slotOptions);
  if (!open) {
    if (!closedConfig?.message) return null; // Don't show anything
    const { leftImage, message, openTimesMessage, showOpenTimes } = closedConfig;
    return (
      <Container text className={css({ closed: true }).toString()}>
        <Segment>
          <Grid className={css('Content').toString()} columns="equal">
            {leftImage && (
              <Grid.Column
                className={css('Image').toString()}
                width={5}
                textAlign="center"
                verticalAlign="middle"
              >
                <CdnImage src={leftImage} maxWidth={150} />
              </Grid.Column>
            )}
            <Grid.Column className={css('Message').toString()} verticalAlign="middle">
              <p>{replaceValues(message, { user })}</p>
              {showOpenTimes && todaySlots.length > 0 && (
                <>
                  {openTimesMessage && (
                    <div className={css('OpenTimes').toString()}>{openTimesMessage}</div>
                  )}
                  <div className={css('Slots').toString()}>
                    {todaySlots.map((slot, index) => (
                      <div className={css('Slot').toString()} key={index}>
                        {t('blocks.restrictOpeningTimes.daily-slot-times', slot)}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </Container>
    );
  }
  return <AccessBlocks blocks={blocks} openConfig={openConfig} />;
};

export default RestrictOpeningTimesBlock;
