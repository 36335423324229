import { useWhyDidYouUpdate } from 'ahooks';
import { useContext, useEffect, useRef } from 'react';

import { ConfigContext, useConfig } from '../config/config.context';
import platformService from '../core/services/platform.service';
import { useRefreshUserOnRegistration } from '../hooks/useRefreshUserOnRegistrayion';
import { useMe } from '../profile/hooks';
import { useVersionSync } from './useVersionSync';

const GlobalConfigSync = () => {
  const ref = useRef({ isFirst: true });
  const me = useMe();
  const { config, update } = useContext(ConfigContext);
  const disableConfigSync = config?.debug?.disableConfigSync;

  useEffect(() => {
    if (disableConfigSync) return;
    // if (!me.version) return; // Not the real user yet, just the placeholder...

    // Don't refresh on mount...
    if (ref.current.isFirst) {
      ref.current.isFirst = false;
      return;
    }
    platformService.fetchConfig('GlobalConfigSync.user').then(update);
  }, [disableConfigSync, update, me]);

  useVersionSync({
    resourceType: 'config',
    id: 'global',
    lazy: true,
    refresh: async ({ refresh } = {}) => {
      if (!refresh) return;
      const newConfig = await platformService.fetchConfig('useVersionSync');
      update(newConfig);
    },
  });

  return null;
};

const GlobalConfigSyncDebugShield = () => {
  const state = useRef({ hasShownWarning: false });
  const config = useConfig();
  useRefreshUserOnRegistration();
  if (config?.debug?.disableConfigSync) {
    if (!state.current.hasShownWarning) {
      // eslint-disable-next-line no-console
      console.warn('Automated configuration synchronization is disabled.');
      state.current.hasShownWarning = true;
    }
    return null;
  }

  return <GlobalConfigSync />;
};

export default GlobalConfigSyncDebugShield;
