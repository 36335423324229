/* eslint-disable react/prop-types */
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Popup } from 'semantic-ui-react';

import './Slots.scss';

const translationPrefix = 'appointments.steps';

const LineWrapper = ({ children, left, right }) => (
  <div className="LineWrapper">
    <div className={cx('LineWrapper__line', left && 'show')} />
    {children}
    <div className={cx('LineWrapper__line', right && 'show')} />
  </div>
);

LineWrapper.defaultProps = {
  left: true,
  right: true,
};

const SlotLine = ({ allowOverlappingSessions = false, startTime, slots, onSelect }) => {
  const { t } = useTranslation();
  return (
    <Grid
      className="SlotGrid__Columns"
      columns={slots.length + 1}
      verticalAlign="middle"
      textAlign="center"
    >
      <Grid.Column>
        {t('common.time', { date: startTime, context: 'slot_line_header' })}
      </Grid.Column>
      {slots.map((slot, index) => {
        let buttonColor;
        let buttonDisable;
        if (allowOverlappingSessions) {
          buttonColor = undefined;
          buttonDisable = !slot.isAvailable;
        } else if (!slot.isUserAvailable && slot.isAvailable && !slot.canBeOverlaped) {
          buttonColor = 'red';
          buttonDisable = true;
        } else if (!slot.isUserAvailable && slot.isAvailable && slot.canBeOverlaped) {
          buttonColor = 'orange';
          buttonDisable = false;
        } else {
          buttonColor = undefined;
          buttonDisable = !slot.isAvailable || !slot.isUserAvailable;
        }

        const btn = (
          <Button
            compact
            size="mini"
            color={buttonColor}
            disabled={buttonDisable}
            onClick={() => onSelect(slot)}
          >
            {t('common.time', { date: slot.startDate, context: 'slot_line' })}
          </Button>
        );

        const popup = !slot.isUserAvailable && slot.isAvailable && (
          <Popup
            content={t(`${translationPrefix}.select-slot.user-not-available-explanation`)}
            trigger={<span>{btn}</span>} // Wrap in span so that popup works even on 'disabled' button
          />
        );

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid.Column key={index}>
            <LineWrapper left={index > 0} right={index < slots.length - 1}>
              {popup || btn}
            </LineWrapper>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};

export default SlotLine;
