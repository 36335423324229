import { useSetState } from 'ahooks';
import every from 'lodash/every';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';

import { getString } from '../../utils';
import { toggleArrayValue } from '../utils';
import { useStepContext } from './StepContext';
import SelectField from './components/SelectField';

export const StepSelectOptionsContent = () => {
  const { onSelectStep, steps } = useStepContext();

  const [selectedFilters, setSelectedFilters] = useSetState({}); //selectedOptions

  const selectOptionStep = steps.find((s) => s.type === 'select-options');
  const { filters } = selectOptionStep;

  const isValid = every(filters, (filter) => selectedFilters[filter.dataKey]?.length);

  const handleConfirm = () => {
    if (!isValid) return;
    onSelectStep('select-slot', { filters: selectedFilters });
  };

  const handleClick = (e, { name, value }) => {
    const filter = filters.find((f) => f.dataKey === name);
    if (!filter) {
      console.error('Failed to find filter for', name);
      return;
    }

    const { type } = filter;

    switch (type) {
      case 'multiselect': {
        const currentValues = selectedFilters?.[name] || [];
        setSelectedFilters({ [name]: toggleArrayValue(currentValues, value) });
        break;
      }
      case 'select':
      default: {
        setSelectedFilters({ [name]: [value] });
        break;
      }
    }
  };

  return (
    <div className="step-content__select-options">
      <Form onSubmit={handleConfirm}>
        {filters.map((filter) => {
          const { dataKey, label, options, displayMode } = filter;
          return (
            <>
              <SelectField
                dataKey={dataKey}
                displayMode={displayMode}
                label={label}
                options={options}
                selected={selectedFilters}
                onClick={handleClick}
              />
            </>
          );
        })}
        <Button primary disabled={!isValid} style={{ position: 'relative', right: '0' }}>
          {getString('btn.next')}
        </Button>
      </Form>
    </div>
  );
};
