import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';

import BlockContainer from '../../components/BlockContainer';
import { containerTypePropTypes, workshopProptypes } from '../../propTypes';

function WorkshopVisioRoomBlock(props) {
  const { item: workshop, field, containerType } = props;
  const rooms = get(workshop, field);
  const hasRooms = rooms?.length > 0;
  if (!hasRooms) return null;
  const [visioRoom] = rooms;
  const { uri, title, description, buttonLabel } = visioRoom;
  return (
    <BlockContainer className="workshop--visioRoomBlock" header={title} type={containerType}>
      <div style={{ textAlign: 'center' }}>
        {description && <p className="description">{description}</p>}
        <Button primary className="room-acsess-button" as="a" href={uri} target="_blank">
          {buttonLabel}
        </Button>
      </div>
    </BlockContainer>
  );
}

WorkshopVisioRoomBlock.defaultProps = {
  containerType: 'segment',
  field: 'rooms',
};

WorkshopVisioRoomBlock.propTypes = {
  containerType: containerTypePropTypes,
  field: PropTypes.string,
  item: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopVisioRoomBlock;
