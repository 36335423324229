/* eslint-disable operator-linebreak */
import { isAfter, parseISO } from 'date-fns';
import orderBy from 'lodash/orderBy';
import { useQuery } from 'react-query';

import notificationsService from '../core/services/notifications.service';
import { useIsAnonymous, useMe } from '../profile/hooks';
import { NotificationStatus } from '../types/Notification.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useNotifications = (): any => {
  const user = useMe();
  const isAnonymous = useIsAnonymous();
  const enabled = !(!user || isAnonymous);
  const res = useQuery(
    ['notifications', user?._id],
    async () => {
      const notifications = await notificationsService.getNotifications();
      return orderBy(notifications, 'createdAt', 'desc') || [];
    },
    {
      enabled,
    },
  );
  // @Patrick - Utilisation de websockets pour gérer le polling ?
  const { data = [], status } = res;
  const lastReadDate = user?.dates?.notificationsReadAt;
  const formattedNotifications = data.map((notif) => ({
    ...notif,
    status:
      !lastReadDate || (lastReadDate && isAfter(parseISO(notif.createdAt), parseISO(lastReadDate)))
        ? notif.status
        : 'read',
  }));
  return {
    notifications: formattedNotifications,
    unreadNotifications: formattedNotifications.filter(
      (notif) => notif.status !== NotificationStatus.READ,
    ),
    loading: status === 'loading',
    enabled,
  };
};

export { useNotifications };
