import React from 'react';
import BlockContainer from '../../../components/BlockContainer';
import TiltedMenu from '../../components/TiltedMenu';
import { TiltedMenuProps } from '../../components/TiltedMenu/TiltedMenu';

type TiltedMenuBlockProps = {
  container?: {
    header?: string;
    type: 'segment' | 'segment-light' | 'basic' | 'headerless';
  };
} & TiltedMenuProps;

const TiltedMenuBlock = ({ container, ...props }: TiltedMenuBlockProps): JSX.Element => {
  return (
    <BlockContainer className="block--tilted-menu" type="headless" {...container}>
      <TiltedMenu {...props} />
    </BlockContainer>
  );
};

TiltedMenuBlock.defaultProps = {
  container: undefined,
};

export default TiltedMenuBlock;
