import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useConfig } from '../config/config.context';
import { useScreenConfig } from '../config/screens.context';
import { ProvideCurrentScreen } from '../core/hooks/useCurrentScreen.hook';
import NetworkingScreen from './NetworkingScreen';

const networkingRoutes = [
  {
    key: 'networking',
    path: '',
  },
  {
    key: 'networking-chats',
    path: 'chats',
  },
  {
    key: 'networking-chat',
    path: 'chats/:chatId',
  },
  {
    key: 'networking-invites',
    path: 'invites',
  },
];

const NetworkingRouter = ({ forceRedirectTo, path, pageId, ...rest }) => {
  const screen = useScreenConfig(pageId);
  return (
    <Switch>
      {networkingRoutes.map(({ key, path: routePath }) => {
        return (
          <Route
            key={key}
            path={`${path}/${routePath}`}
            render={(props) => {
              if (forceRedirectTo) {
                // TODO: rename to `redirectTo` after OVH
                return <Redirect redirectTo to={forceRedirectTo} />;
              }
              return (
                <ProvideCurrentScreen screenId={pageId} screen={screen}>
                  <NetworkingScreen {...props} {...rest} pageId={pageId} />
                </ProvideCurrentScreen>
              );
            }}
          />
        );
      })}
    </Switch>
  );
};

NetworkingRouter.defaultProps = {
  forceRedirectTo: undefined,
};

NetworkingRouter.propTypes = {
  forceRedirectTo: PropTypes.bool,
  path: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default NetworkingRouter;
