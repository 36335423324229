import keys from 'lodash/keys';
import { Socket, io } from 'socket.io-client';

const { endpoints } = window.__DATA__;

export type Subscription = {
  topic: string;
  type: string;
  callback: (type: string, message: any) => void;
};

type TopicName = string;
class PubsubService {
  private socket?: Socket;

  private userId = 'invalid';

  private subscriptions: Record<TopicName, Subscription[]> = {};

  stopSession = () => {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.subscriptions = {};
  };

  startSession = (userId: string, token: string) => {
    if (!endpoints.push) {
      return;
    }

    if (userId !== this.userId) {
      this.stopSession();

      this.userId = userId;
      this.socket = io(endpoints.push, {
        path: '/push',
        auth: {
          token, //: store.token,
        },
        // transports: ['websocket', 'polling'],
      });

      this.socket.on('connect', () => {
        this.socket?.emit('subscribe', { topics: keys(this.subscriptions) });
      });
    }
  };

  subscribe = (subscriptions: Subscription[] = []) => {
    if (!subscriptions?.length || !this.socket) {
      return;
    }

    if (this.socket.connected) {
      this.socket.emit('subscribe', {
        topics: subscriptions.map((s) => s.topic),
      });
    }

    subscriptions.forEach((s: Subscription) => {
      if (!this.subscriptions[s.topic]) {
        this.subscriptions[s.topic] = [s];
      } else {
        this.subscriptions[s.topic].push(s);
      }

      this.socket?.on(s.type, (message: any) => s.callback(s.type, message));
    });
  };

  unsubscribe = (subscriptions: Subscription[]) => {
    if (!this.socket) {
      return;
    }

    if (this.socket.connected) {
      this.socket.emit('unsubscribe', { topics: subscriptions.map((s) => s.topic) });
    }

    subscriptions.forEach((s: Subscription) => {
      this.socket?.off(s.type);
    });
  };
}

export default new PubsubService();
