import React from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Menu, Label } from 'semantic-ui-react';
import ExpiringNavLink from '../ExpiringNavLink';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import { useIsPreview } from '../../config/preview.context';

const NavMenuItem = (props) => {
  const { children, exact, label, color, to, onClick, ...rest } = props;
  const { trackEvent } = useTracking();
  const isPreview = useIsPreview();
  const as = to && !isPreview ? ExpiringNavLink : undefined;
  return (
    <Menu.Item
      as={as}
      exact={exact}
      activeClassName="active"
      color={color}
      to={to}
      onClick={(e) => {
        trackEvent(eventTags.MENU_CLICK, omit(props, ['translations', 'children']));
        onClick?.(e);
      }}
      {...rest}
    >
      {label !== undefined && (
        <Label size="tiny" basic={false}>
          {label}
        </Label>
      )}
      {children}
    </Menu.Item>
  );
};

NavMenuItem.defaultProps = {
  exact: false,
  name: undefined,
  label: undefined,
  color: undefined,
  to: undefined,
  onClick: undefined,
};

NavMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  exact: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavMenuItem;
