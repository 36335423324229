/* eslint-disable @typescript-eslint/no-explicit-any, react/require-default-props */
import get from 'lodash/get';

import { CmsBlocks } from '../../cms/CmsScreen';

type ItemCmsBlockProps = {
  item: { _id: string } & Record<string, unknown>;
  field?: string;
};

const ItemCmsBlock = (props: ItemCmsBlockProps): JSX.Element | null => {
  const { item, field = 'blocks' } = props;
  const blocks = get(item, field, []) as any[];

  if (!blocks?.length) return null;

  return <CmsBlocks blocks={blocks} />;
};

export default ItemCmsBlock;
