/* eslint-disable react/require-default-props */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SemanticSIZES } from 'semantic-ui-react';

import { useMe } from '../../../../profile/hooks';
import { Workshop } from '../../../../types/workshop.types';

type AnimatorAccessButtonProps = {
  workshop: Workshop;
  size?: SemanticSIZES;
  primary?: boolean;
};

const AnimatorAccessButton = memo(
  ({ workshop, size, primary }: AnimatorAccessButtonProps): JSX.Element | null => {
    const me = useMe();
    const { t } = useTranslation();

    // Hide if not animator
    if (!workshop.presenters?.includes(me._id)) {
      return null;
    }

    const { endpoints } = window.__DATA__;
    const url = `${endpoints.meetings}/cm/w/workshops/${workshop._id}/u/participants/${me._id}/iframe`;

    return (
      <Button
        className="button--animator-access-button"
        as="a"
        href={url}
        target="_blank"
        size={size}
        primary={primary}
        content={t('workshops.workshop.actions.animator-access')}
      />
    );
  },
);

export { AnimatorAccessButton };
