/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Button, Container, Segment } from 'semantic-ui-react';
import { CmsBlocks } from '../../../cms/CmsScreen';
import { bem } from '../../../core/design/bem';
import { useMe } from '../../../profile/hooks';
import { replaceValues } from '../../../utils/stringUtils';

const css = bem('RestrictOpeningTimesBlock');

export type OpenConfig = {
  requireConfirmation?: boolean;
  confirmationMessage?: string;
  confirmationButton?: string;
};

const AccessBlocks = ({
  openConfig,
  blocks,
}: {
  openConfig?: OpenConfig;
  blocks: any;
}): JSX.Element => {
  const user = useMe();
  const [open, setOpen] = useState(() => !openConfig?.requireConfirmation);

  if (openConfig?.requireConfirmation && !open) {
    return (
      <Container text className={css({ opening: true }).toString()}>
        <Segment className={css('Content').toString()}>
          <div className={css('Message').toString()}>
            <p>{replaceValues(openConfig?.confirmationMessage, { user })}</p>
            <Button inverted onClick={() => setOpen(true)}>
              {openConfig?.confirmationButton}
            </Button>
          </div>
        </Segment>
      </Container>
    );
  }
  return (
    <div className={css({ open: true })}>
      {/* {currentSlot && <div>ok</div>} */}
      <CmsBlocks blocks={blocks} />
    </div>
  );
};

AccessBlocks.defaultProps = {
  openConfig: {},
};

export default AccessBlocks;
