import { useConfig } from '../../../../config/config.context';
import { useDesignConfig } from '../../../../config/design.context';
import './LoginBanner.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CdnImage = require('../../../../components/CdnImage').default;

const LoginBanner = (): JSX.Element | null => {
  const design = useDesignConfig();
  const { basename } = useConfig();
  const { banner } = design;
  if (!banner) return null;

  return (
    <div className="LoginBanner">
      <a href={basename || '/'} target="_blank" rel="noreferrer noopener">
        <CdnImage src={banner} alt="salon-banner" maxWidth={1200} />
      </a>
    </div>
  );
};

export default LoginBanner;
