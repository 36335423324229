/* eslint-disable @typescript-eslint/no-explicit-any */
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { Card, Header } from 'semantic-ui-react';

import ShowMore from '../../../ShowMore';
import { WorkshopItems } from '../WorkshopItems';

type ListTemplate = {
  variant?: string;
  minHeight?: number;
};

type HorizontalListGroupProps = {
  group: Record<string, any>;
  centered?: boolean;
  template?: ListTemplate;
  filteredWorkshopIds?: string[];
  itemProps: any;
};

const defaultTemplate: ListTemplate = { variant: 'card' };
const HorizontalListsGroup: FC<HorizontalListGroupProps> = (props) => {
  const { group, centered, filteredWorkshopIds, itemProps, template = defaultTemplate } = props;
  const { id, items, label } = group;
  const { maxItems } = itemProps;
  const [showAll, setShowAll] = useState(false);
  const groupItems = maxItems && !showAll ? items.slice(0, maxItems) : items;
  return (
    <div className={`workshops--group workshops--group--${id}`}>
      <Header as="h3" className="date">
        {label}
      </Header>
      <Card.Group className="workshops--container" itemsPerRow={3} centered={centered}>
        <WorkshopItems
          workshops={groupItems}
          filteredWorkshopIds={filteredWorkshopIds}
          template={template}
          itemProps={itemProps}
        />
      </Card.Group>
      {!!maxItems && items.length > maxItems && (
        <ShowMore showAll={showAll} onChange={setShowAll} />
      )}
    </div>
  );
};

type HorizontalListsProps = {
  groups?: Record<string, any>[];
  centered?: boolean;
  template?: ListTemplate;
  filteredWorkshopIds?: string[];
  itemProps: any;
  Component: FC;
};

const HorizontalLists: FC<HorizontalListsProps> = (props) => {
  const { groups = [], ...rest } = props;
  const [showAll, setShowAll] = useState(false);
  const { maxGroups } = rest.itemProps;
  const groupItems = maxGroups && !showAll ? groups.slice(0, maxGroups) : groups;

  return (
    <>
      {groupItems?.map((group: Record<string, any>) => (
        <HorizontalListsGroup {...rest} key={group.id} group={group} />
      ))}
      {!!maxGroups && groups.length > maxGroups && (
        <ShowMore showAll={showAll} onChange={setShowAll} />
      )}
    </>
  );
};

HorizontalLists.defaultProps = {
  itemProps: undefined,
  centered: false,
  filteredWorkshopIds: undefined,
  template: defaultTemplate,
  groups: [],
};
HorizontalLists.propTypes = {
  centered: PropTypes.bool,
  itemProps: PropTypes.object,
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  // eslint-disable-next-line react/forbid-prop-types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  template: PropTypes.shape({
    variant: PropTypes.string,
    minHeight: PropTypes.number,
  }),
  groups: PropTypes.arrayOf(PropTypes.object.isRequired),
};

HorizontalListsGroup.defaultProps = {
  itemProps: undefined,
  centered: false,
  filteredWorkshopIds: undefined,
  template: defaultTemplate,
};

HorizontalListsGroup.propTypes = {
  centered: PropTypes.bool,
  itemProps: PropTypes.object,
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  template: PropTypes.shape({
    variant: PropTypes.string,
    minHeight: PropTypes.number,
  }),
  group: PropTypes.object.isRequired,
};

export { HorizontalLists, HorizontalListsGroup };
