export type Extremes = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
};

export type Point = [number, number];

const hexRatio = 0.868217054;
const numSides = 6;
const centerAng = (2 * Math.PI) / numSides;

function round(num: number) {
  return Number(num.toFixed(3));
}

function toRadians(degs: number) {
  return (Math.PI * degs) / 180;
}

export function getPoints(diagonal: number, offset: number): Point[] {
  const cy = diagonal / 2;
  const cx = (diagonal * hexRatio) / 2;

  const startAng = toRadians(90);
  const radius = cy;

  const vertex = [];
  for (let i = 0; i < numSides; i++) {
    const ang = startAng + i * centerAng;
    vertex.push([
      offset / 2 + cx + radius * Math.cos(ang), // X
      offset / 1.5 + cy - radius * Math.sin(ang), // Y
    ]);
  }

  return vertex.map((point) => point.map(round)) as Point[];
}

export function getFlatTopPoints(diagonal: number): Point[] {
  const y = diagonal / 2;
  const cx = (hexRatio * diagonal) / 2;
  const x = cx + (y - cx);
  const radius = y;

  const cos = 0.866 * radius;
  const sin = 0.5 * radius;

  return [
    [x - sin, y - cos],
    [x + sin, y - cos],
    [x + radius, y],
    [x + sin, y + cos],
    [x - sin, y + cos],
    [x - radius, y],
  ].map((point) => point.map(round) as Point);
}

export function substractMinBounds(extremes: Extremes): Extremes {
  return {
    maxX: extremes.maxX - extremes.minX,
    maxY: extremes.maxY - extremes.minY,
    minX: extremes.minX,
    minY: extremes.minY,
  };
}

export function computeViewbox(diagonal: number, flatTop: boolean, strokeWidth?: number): string {
  const baseBounds: Extremes = {
    maxX: -Infinity,
    maxY: -Infinity,
    minX: +Infinity,
    minY: +Infinity,
  };

  const offset: number = strokeWidth || 0;
  const halfStroke = Math.ceil(offset / 2);
  const points = flatTop ? getFlatTopPoints(diagonal) : getPoints(diagonal, offset);

  function reduceBounds(extremes: Extremes, point: Point) {
    return {
      maxX: Math.ceil(Math.max(extremes.maxX, point[0] + halfStroke)),
      maxY: Math.ceil(Math.max(extremes.maxY, point[1] + halfStroke)),
      minX: Math.floor(Math.min(extremes.minX, point[0] - halfStroke)),
      minY: Math.floor(Math.min(extremes.minY, point[1] - halfStroke)),
    };
  }

  // Note : typescript signature for reduce is more restricted than actual implementation ?
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const bounds = substractMinBounds(points.reduce(reduceBounds, baseBounds));
  return [
    bounds.minX,
    bounds.minY,
    bounds.maxX + (bounds.minX < 0 ? Math.abs(bounds.minX) : 0),
    bounds.maxY + (bounds.minY < 0 ? Math.abs(bounds.minY) : 0),
  ].join(' ');
}
