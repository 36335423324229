import PropTypes from 'prop-types';
import React from 'react';

import WorkshopsWithRegistrationBlock from '../../home/blocks/WorkshopsWithRegistrationBlock';
import { getString } from '../../utils';
import BlockHeader from '../BlockHeader';
import WorkshopList from './list/WorkshopList';

const translationPrefix = 'blocks';

const gridTemplates = {
  'grid': WorkshopList,
  'registration': WorkshopsWithRegistrationBlock,
  'with-registration': WorkshopsWithRegistrationBlock,
};

const WorkshopsBlock = ({ workshopList, header, headerLevel, headerKey, itemProps, template }) => {
  const GridComponent = gridTemplates[itemProps?.gridTemplate] || gridTemplates.grid;
  return (
    <>
      <BlockHeader title={header || getString(headerKey)} headerLevel={headerLevel} />
      <GridComponent
        centered
        workshopList={workshopList}
        itemProps={itemProps}
        template={template || itemProps}
        collection="workshops"
      />
    </>
  );
};

WorkshopsBlock.defaultProps = {
  header: undefined,
  headerLevel: undefined,
  headerKey: `${translationPrefix}.sessions`,
  itemProps: undefined,
  template: undefined,
};

WorkshopsBlock.propTypes = {
  header: PropTypes.string,
  headerLevel: PropTypes.string,
  headerKey: PropTypes.string,
  itemProps: PropTypes.object,
  template: PropTypes.object,
  workshopList: PropTypes.array.isRequired,
};

export default WorkshopsBlock;
