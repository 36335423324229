import orderBy from 'lodash/orderBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import WorkshopImageItem from '../../../../components/workshops/workshop-templates/image-item/WorkshopImageItem';
import './CloudTvRegistration.scss';

type CloudTvSession = {
  _id: string;
  title: string;
  startDate: string;
};

type CloudTvRegistrationProps = {
  sessions: CloudTvSession[];
};

const CloudTvRegistration = ({ sessions }: CloudTvRegistrationProps): JSX.Element | null => {
  const { t } = useTranslation();

  if (!sessions?.length) return null;
  return (
    <div className="CloudTvRegistration">
      <Header as="h3" textAlign="center">
        {t('blocks.cloudtv.registration.select-session')}
      </Header>
      {/* <Card.Group style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
      <div className="WorkshopsRegistrationBlock image">
        {orderBy(sessions, 'order').map((session) => (
          <WorkshopImageItem workshop={session} />
        ))}
      </div>
      {/* </Card.Group> */}
    </div>
  );
};

export default CloudTvRegistration;
