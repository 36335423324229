/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useMedia } from 'react-media';
import { Card, SemanticWIDTHS } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { useSpeakerIds } from '../../../core/users/users.hooks';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import BlockContainer from '../../BlockContainer';
import SpeakerCard from '../../speakers/SpeakerCard';
import CarouselBlock from '../CarouselBlock';
import { BlockProps, ContainerProps } from '../types';

const css = bem('SpeakersBlock');

type SpeakersBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  itemsPerRow?: SemanticWIDTHS;
  speakerIds?: string[];
  variant?: 'classic' | 'square';
  [x: string]: any;
} & typeof SpeakersBlockDefaultProps;

const SpeakersBlockDefaultProps = {
  container: {},
  className: '',
  itemsPerRow: 4,
  speakerIds: [],
  variant: 'classic',
};

const SpeakersBlock = ({
  _id,
  className,
  container,
  speakerIds,
  variant,
  itemsPerRow,
  ...rest
}: SpeakersBlockProps): JSX.Element | null => {
  const speakers = useSpeakerIds(speakerIds);
  const { mobile = {} } = rest;
  const { variant: mobileVariant, carousel } = mobile;
  const { desktop } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  if (!speakers || speakerIds.length === 0) return null;
  const blockClassName = css({ id: _id, variant }).mix(className);
  if (!desktop && mobileVariant === 'carousel') {
    return (
      <CarouselBlock
        _id={_id}
        carousel={carousel}
        className={blockClassName}
        container={container}
        items={speakers}
        component={(speaker: any) => (
          <SpeakerCard key={speaker._id} variant={variant} {...{ speaker, ...rest }} />
        )}
      />
    );
  }
  return (
    <BlockContainer {...container} className={blockClassName}>
      <Card.Group itemsPerRow={itemsPerRow || 6} centered>
        {speakers.map((speaker: any) => (
          <SpeakerCard key={speaker._id} variant={variant} {...{ speaker, ...rest }} />
        ))}
      </Card.Group>
    </BlockContainer>
  );
};

SpeakersBlock.defaultProps = SpeakersBlockDefaultProps;

export default SpeakersBlock;
