import Carousel from 'nuka-carousel';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import { useSocialWallPublications } from '../../../social-wall-v2/hooks/useSocialWallPublications';
import { useGetScreenPathByType } from '../../../utils/hooks';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import { SliderItem } from './SliderItem';
import './SocialWallWidget.scss';
import BlockContainer from '../../BlockContainer';
import { bem } from '../../../core/design/bem';

const css = bem('SocialWallWidget')

type SocialWallWidgetProps = {
  _id: string;
  type: string;
  className?: string;
  container: {
    header: string;
    type: 'segment';
  };
  view?: 'grid' | 'list';
  slideCount?: number;
  slidesToShow?: number;
  cellSpacing?: number;
};

const SocialWallFeedWidget: React.FunctionComponent<SocialWallWidgetProps> = (props) => {
  const { t } = useTranslation();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const { _id, container, className= '', view = 'grid', slideCount = 5, slidesToShow = 2, cellSpacing = 10 } = props;
  const { publications: posts } = useSocialWallPublications();
  const GRID_VIEW_HEIGHT = view === 'grid' ? '300px' : '150px';
  const limitView = view === 'list' ? 2 : slidesToShow;
  const visibleSlides = matches.desktop ? limitView : 1;
  const socialWallPath = useGetScreenPathByType('social-wall-v2');
  const postsToShow = useMemo(
    () => posts.filter((post) => (view === 'grid' ? post.data.media : true)).slice(0, slideCount),
    [posts, slideCount, view],
  );

  return (
    <BlockContainer
    {...container}
    className={css({ id: _id }).mix(className)}
  >
    <div className="social-wall-slider">
      <div className="social-wall-slider-header">
        <h3>{container?.header || t('social-wall.news-feed')}</h3>
        <Button href={socialWallPath} className="social-wall-slider-header__button">
          {t('btn.access')}
        </Button>
      </div>
      <Carousel
        cellSpacing={cellSpacing || 1}
        slidesToShow={visibleSlides}
        className="social-wall-slider__container"
        renderCenterLeftControls={({ previousSlide }) => (
          <Icon className="left-control" name="chevron left" onClick={previousSlide} />
        )}
        wrapAround
        renderCenterRightControls={({ nextSlide }) => (
          <Icon className="right-control" onClick={nextSlide} name="chevron right" />
        )}
        renderBottomCenterControls={() => false}
        height={GRID_VIEW_HEIGHT}
      >
        {postsToShow.map((post) => 
            <Link key={post._id} to={`${socialWallPath}?postId=${post._id}`}>
              <SliderItem post={post} view={view} />
            </Link>
          )
        }
      </Carousel>
    </div>
    </BlockContainer>
  );
};

export default SocialWallFeedWidget;
