import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useAutoRefresh } from '../../../hooks/useAutoRefresh';
import flocklerUtils from './flocklerUtils';

const Flockler = ({ projectId, feedId, autoRefresh }) => {
  const now = useAutoRefresh(autoRefresh);

  useEffect(() => {
    const lazySub = flocklerUtils.initialize(projectId, feedId);

    return () => {
      lazySub.then((unsub) => unsub());
    };
  }, [projectId, feedId, now]);

  return <div id={flocklerUtils.domIdFromFeedId(feedId)} />;
};
Flockler.defaultProps = {
  autoRefresh: 15 * 60 * 1000, // 15 minutes by default
};

Flockler.propTypes = {
  projectId: PropTypes.string.isRequired,
  feedId: PropTypes.string.isRequired,
  autoRefresh: PropTypes.number,
};

export default Flockler;
