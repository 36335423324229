import React, { FC } from 'react';

import MaterialIcon from '../Icons/MaterialIcon';
import { Icons } from '../Icons/material-icon.types';
import './NoItemsPlaceholder.scss';

interface NoItemsPlaceholderProps {
  description?: string;
  title?: string;
  icon?: Icons;
  buttonOptions?: {
    onClick?: () => any;
    label?: string;
    icon?: Icons;
  };
}

export const NoItemsPlaceholder: FC<NoItemsPlaceholderProps> = ({
  title,
  description,
  icon,
  buttonOptions,
}: NoItemsPlaceholderProps) => {
  const { onClick, label, icon: buttonIcon } = buttonOptions || {};

  return (
    <div className="no-items-placeholder">
      {icon && <MaterialIcon name={icon} size={150} />}
      <span className="title">{title}</span>
      <span className="description">{description}</span>
      {onClick && (
        <button onClick={onClick} type="button">
          {buttonIcon && <MaterialIcon name={buttonIcon} size={150} />}
          {label}
        </button>
      )}
    </div>
  );
};
