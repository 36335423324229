/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable operator-linebreak */
import { useBoolean } from 'ahooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import hasher from 'hash-string';
import { noop } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Input } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { applySearchFilter } from '../../../core/filter/utils';
import platformService from '../../../core/services/platform.service';
import { sendInvite } from '../../../networking/store/networking.actions';
import { allChats } from '../../../networking/store/networking.selectors';
import { useMe } from '../../../profile/hooks';
import { OrderBy, useOrderBy, usePreFilter } from '../../../utils/hooks';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import Avatars from '../../Avatars';
import BlockContainer from '../../BlockContainer';
import { ContainerProps } from '../types';
import './UsersBlock.scss';

const css = bem('UsersBlock');

type UserBlockProps = {
  collection: string;
  container: ContainerProps;
  preFilters?: Record<string, any>;
  orderBy?: OrderBy[];
  search?: Record<string, any>;
  networking?: Record<string, any>;
  maxItems: number;
  variant?: 'simple' | 'square' | 'details' | 'text';
} & typeof UserBlockDefaultProps;

const UserBlockDefaultProps = {
  orderBy: [],
  preFilters: {},
  variant: 'square',
  search: {},
  networking: {},
};

export const UsersBlock = (props: UserBlockProps): JSX.Element | null => {
  const {
    collection,
    container,
    variant,
    preFilters,
    orderBy = [],
    maxItems,
    search: searchConfig = {},
    networking: networkingConfig = {},
    ...rest
  } = props;
  const { t } = useTranslation();
  const me = useMe();
  const {
    showSearchBar = true,
    fields = ['firstName', 'lastName', 'role', 'company'],
    autoFocus = false,
  } = searchConfig || {};
  const { enabled = false } = networkingConfig;
  const chats = useSelector(allChats);
  const { pathname } = useLocation();
  const history = useHistory();
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [showMore, { setFalse: setHideMore, setTrue: setShowMore }] = useBoolean(false);
  const { data = [] } = useQuery(['users', collection], () =>
    platformService.fetchUsers(collection),
  );
  const prefilteredUsers = usePreFilter(data, preFilters || {}).filter(
    (u: any) => u._id !== me._id,
  );
  const filteredUsers = applySearchFilter(prefilteredUsers, searchText, fields);
  const users = useOrderBy(filteredUsers, orderBy);
  const finalUsers = maxItems && !showMore ? users.slice(0, maxItems) : users;

  if (!prefilteredUsers?.length) return null;

  const handleOpenNetworkingPopup = async (user: Record<string, any>) => {
    const inviter = me._id;
    const invited = user._id;
    const chatId = inviter > invited ? `${invited}-${inviter}` : `${inviter}-${invited}`;
    if (!(chatId in chats)) {
      dispatch(sendInvite(user, '', 'accepted'));
    }
    const chatHash = hasher(chatId).toString(16);
    if (mobile) {
      history.push({ pathname: `networking/chats/${chatHash}` });
    } else {
      history.push({ pathname, search: `?chatHash=${chatHash}`, state: { chatHash } });
    }
  };

  return (
    <BlockContainer className={css().toString()} {...container}>
      {showSearchBar && (
        <Input
          fluid
          iconPosition="left"
          autoFocus={autoFocus}
          placeholder={t('filters.search')}
          className={css('Search').toString()}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText || ''}
          icon={
            searchText
              ? {
                  name: 'close',
                  style: { cursor: 'pointer', pointerEvents: 'all' },
                  onClick: () => setSearchText(undefined),
                }
              : 'search'
          }
        />
      )}
      <div
        className={css('List')}
        style={{ maxHeight: showMore ? 150 * 4 : 'inherit', overflowY: 'scroll' }}
      >
        <Avatars
          users={finalUsers}
          variant={variant}
          {...(rest as any)}
          className={(enabled && 'clickable') || ''}
          onClick={enabled ? handleOpenNetworkingPopup : noop}
        />
      </div>
      {!!maxItems && users.length > maxItems && (
        <div className={css('ShowMore')}>
          <div className="divider" />
          <Button
            basic
            icon={showMore ? 'minus' : 'plus'}
            onClick={showMore ? setHideMore : setShowMore}
          />
          <div className="divider" />
        </div>
      )}
    </BlockContainer>
  );
};

UsersBlock.defaultProps = UserBlockDefaultProps;
