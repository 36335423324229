import PropTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';

import BlockContainer from '../../components/BlockContainer';
import CdnImage from '../../components/CdnImage';
import { EntityTags } from '../../components/EntityTags';
import HtmlTemplate from '../../components/HtmlTemplate';
import WorkshopDate from '../../components/workshops/WorkshopDate';
import WorkshopLogos from '../../components/workshops/WorkshopLogos';
import { bem } from '../../core/design/bem';
import { useIsDigital } from '../../profile/hooks';
import { containerProptypes } from '../../propTypes';
import Images from '../../utils/Images';
import Tag from '../components/Tag';
import { useFieldLabel } from '../components/WorkshopsBlock/WorkshopsBlock';
import { getWorkshopString } from '../utils';

const css = bem('workshop--details');

const WorkshopDetailsBlock = ({ item, container, ...rest }) => {
  const { tagFields = [], showDuration, showEndTime = true, logosProps, header } = rest;
  const {
    endDate,
    image,
    startDate,
    description,
    languages,
    title,
    subtitle,
    technicityLevel,
    productCategories,
    shortDescription,
    logos,
    location,
  } = item;
  const { showDescription } = header ?? {};
  const hasImage = Images.exists(image);
  const isDigitally = useIsDigital();
  const labelProvider = useFieldLabel({ kind: 'workshops', ...item });

  return (
    <>
      <BlockContainer {...container} className="workshop-details-segment">
        <div className={css()}>
          {hasImage && (
            <div style={{ flexShrink: 0 }}>
              <CdnImage className={css('image').toString()} src={image} alt={title} />
            </div>
          )}
          <div className={css('content').toString()} style={{ paddingLeft: hasImage ? 16 : 0 }}>
            <p className={css('content--category')}>{getWorkshopString(item, 'category')}</p>
            <div className="date-title">
              <p style={{ marginBottom: 6 }} className={css('content--date')}>
                <WorkshopDate
                  startDate={startDate}
                  endDate={endDate}
                  showEndTime={showEndTime}
                  showDuration={showDuration}
                />
              </p>
              {location && !isDigitally && (
                <div className="location">
                  <Icon name="map marker alternate" />
                  {location}
                </div>
              )}
              {languages && languages.length > 0 && (
                <div
                  className={css('content--languages')}
                  style={{ marginTop: 0, marginBottom: 14 }}
                >
                  <Icon name="flag" />
                  {languages.map((v) => labelProvider('languages', v)).join(', ')}
                </div>
              )}
              <Header
                className={css('content--title')}
                as={header?.level || 'h3'}
                style={{ marginTop: 2, marginBottom: 14 }}
              >
                <Header.Content content={title} />
                <Header.Subheader content={subtitle} />
              </Header>
              {showDescription && shortDescription && (
                <p className={css('content--shortDescription')}>{shortDescription}</p>
              )}
            </div>
            <WorkshopLogos logos={logos} {...logosProps} />
            <EntityTags tagFields={tagFields} entity={item} type="workshop" />
            <div className="tags">
              {technicityLevel && (
                <Tag value={technicityLevel} translationPrefix="workshop.tag.technicityLevel" />
              )}
              {productCategories &&
                productCategories.length > 0 &&
                productCategories.map((pc) => (
                  <Tag value={pc} translationPrefix="workshop.tag.productCategories" />
                ))}
            </div>
          </div>
        </div>
        {!showDescription && !description && shortDescription && (
          <p className={css('shortDescription')}>{shortDescription}</p>
        )}
        {description && (
          <HtmlTemplate
            className={css('description')}
            style={{ fontSize: '1.2em', marginTop: 16 }}
            template={description}
          />
        )}
      </BlockContainer>
      <div className="clear" style={{ height: 60 }} />
    </>
  );
};

WorkshopDetailsBlock.defaultProps = {
  container: {},
};

WorkshopDetailsBlock.propTypes = {
  container: containerProptypes,
  item: PropTypes.object.isRequired,
};

export default WorkshopDetailsBlock;
