import React from 'react';
import cx from 'classnames';
import './Step.scss';

// eslint-disable-next-line react/prop-types
const Step = ({ label, top, color, active, success, style }) => {
  return (
    <div className="Step vertical" style={style}>
      <div className={cx('Step__line', top && 'show')} />
      <div
        className={cx('Step__step', { active, success })}
        style={{ backgroundColor: active && color }}
      >
        {(active || success) && label}
      </div>
      <div className={cx('Step__line', 'show')} />
    </div>
  );
};

export const StepContainer = (props) => {
  const { children, active, label, success, ...rest } = props;
  return (
    <div className="Step__container">
      <Step active={active} success={success} label={label} {...rest} />
      <div className={cx('Step__child', !active && !success && 'disabled')}>{children}</div>
    </div>
  );
};

export default Step;
