import { useRequest } from 'ahooks';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userAgenda } from '../agenda/store/agenda.selectors';
import { useConfig } from '../config/config.context';
import platformService from '../core/services/platform.service';
import store from '../shared/Store';
import { fetchAppointments } from '../store/actions';
import { checkUserAvailability } from '../utils/agendaUtils';
import { checkOverlappingAllowed } from '../workshop-session/store/workshopSessions.hooks';
import { computeTimeSlots } from './utils';

export function useFreeTimeSlots(collection, userId, options) {
  const { bookingType, slotFilters, mode } = options;
  const { data, loading } = useRequest(
    () => platformService.fetchTimeSlots(collection, userId, bookingType, slotFilters, mode),
    {
      refreshDeps: [collection, userId, bookingType, mode, JSON.stringify(slotFilters)],
    },
  );
  return { ...(data || {}), loading };
}

export function useCurrentAppointment(collection, groupId) {
  useEffect(() => {
    store.reduxStore.dispatch(fetchAppointments());
  }, [collection, groupId]);
  const appointments = useSelector((state) => state.appointments.appointments);
  if (groupId) {
    // check if user has already booked an appointment with the group
    return appointments.find((appointment) => appointment.groupId === groupId);
  }
  // check if user has already booked an appointment of x collection
  return appointments.find((appointment) => appointment.collection === collection);
}

export function useDaysWithSlots(days, freeTimeSlots) {
  const userEvents = useSelector(userAgenda);
  const { agenda } = useConfig();
  return useMemo(() => {
    return days.map((day) => ({
      ...day,
      daySlots: day.halfDays.map((grp) => {
        const slots = computeTimeSlots(
          grp.startDate,
          grp.endDate,
          grp?.duration || 15,
          freeTimeSlots,
        );
        const finalSlots = slots.map((slot) => {
          const isUserAvailable = checkUserAvailability(userEvents, slot);
          let canBeOverlaped;
          if (!isUserAvailable && slot.isAvailable) {
            canBeOverlaped = checkOverlappingAllowed(
              userEvents,
              {
                kind: 'appointments',
                collection: 'exhibitors',
                ...slot,
              },
              agenda,
            )?.isOverlappingAllowed;
          }

          return {
            ...slot,
            isAvailable: slot.isAvailable,
            isUserAvailable,
            canBeOverlaped,
          };
        });
        return {
          ...grp,
          slots: finalSlots,
          finalSlots,
        };
      }),
    }));
  }, [days, freeTimeSlots, userEvents]);
}

export function useSteps(collection, userId, bookingType) {
  const { data, loading } = useRequest(
    () => platformService.fetchAppointmentsModalSteps(collection, userId, bookingType),
    {
      refreshDeps: [collection, userId, bookingType],
    },
  );
  return { ...(data || {}), loading };
}
