/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { bem } from '../../../../core/design/bem';
import { getUserFullname, iconForStatus } from '../utils';
import './UserStatus.scss';

const translationPrefix = 'blocks.rooming';
const css = bem('UserStatus');

type UserStatusProps = {
  user: Record<string, any>;
  usersById: Record<string, any>;
  isOrganizer?: boolean;
};

const UserStatus = ({ user, usersById, isOrganizer }: UserStatusProps): JSX.Element | null => {
  const { t } = useTranslation();
  if (!user) return null;
  const { status, userId } = user;
  const { name, color, backgroundColor } = get(iconForStatus, status);
  return (
    <div className={css()}>
      <div className={css('icon')} style={{ backgroundColor }}>
        <Icon name={name as any} style={{ color }} />
      </div>
      <div className={css('fullname')}>
        {getUserFullname(userId, usersById)}
        {isOrganizer && <div className="organizer">{t(`${translationPrefix}.organizer`)}</div>}
      </div>
    </div>
  );
};

UserStatus.defaultProps = {
  isOrganizer: false,
};

export default UserStatus;
