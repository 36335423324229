import React from 'react';
import { Icon, Item } from 'semantic-ui-react';
import { useTracking } from '../../../Context';
import { eventTags } from '../../../core/trackers/events';
import { ContactsProps } from '../components/Contacts';

const ContactsVariantLegacy = (props: ContactsProps): JSX.Element => {
  const { trackEvent } = useTracking();
  const { items } = props;
  return (
    <Item.Group link>
      {items.map((item) => (
        <Item
          key={item._id}
          as="a"
          href={`mailto:${item.email}`}
          target="_blank"
          onClick={() => trackEvent(eventTags.ITEM_CONTACT_CLICK, { item })}
        >
          <Item.Content>
            <Item.Header style={{ fontSize: '1.2em', marginBottom: 0 }}>
              <Icon name="user circle outline" style={{ marginRight: 4 }} /> {item.firstName}{' '}
              {item.lastName}
            </Item.Header>
            {item.fonction && (
              <Item.Meta>
                <Icon name="briefcase" style={{ marginRight: 4 }} />
                {item.fonction}
              </Item.Meta>
            )}
            {item.phoneNumber && (
              <Item.Extra style={{ color: '#777' }}>
                <Icon name="phone" style={{ marginRight: 4 }} />
                {item.phoneNumber}
              </Item.Extra>
            )}
            <Item.Meta>
              <Icon name="envelope outline" style={{ marginRight: 4 }} />
              {item.email}
            </Item.Meta>
          </Item.Content>
        </Item>
      ))}
    </Item.Group>
  );
};

export default ContactsVariantLegacy;
