/* eslint-disable react/require-default-props */
import get from 'lodash/get';
import matches from 'lodash/matches';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Segment } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import { useMe } from '../../profile/hooks';
import store from '../../shared/Store';
import { sweetConfirmAlert } from '../../utils/popupUtils';

/**
 * Allow voting for the current item
 * - votes are stored directly on the user
 *
 * States :
 * Never voted => vote
 * Already voted for this item => cancel vote ?
 * Already voted for other item => change vote
 */

type ItemVoteBlockProps = {
  item: { _id: string } & Record<string, any>;
  className?: string;
  voteField?: string; // Field used on the user. By default, use `private.votes.${kind}.${collection}`
  strings: Record<string, string>;

  filters: Record<string, string>;
};

const translationPrefix = 'blocks.item-vote';
const ItemVoteBlock: FC<ItemVoteBlockProps> = memo((props) => {
  const { item, className, voteField, strings = {}, filters } = props;
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const me = useMe();
  const userField = voteField || `private.votes.${item.kind || 'users'}.${item.collection}`;
  const common = {
    entityKind: item.kind || 'users',
    entityCollection: item.collection,
    entityName: item.name || item.title,
    entityId: item._id,
    entityCategory: item.category,
  };
  const itemValue = item._id;
  const currentVote = get(me, userField);
  const visible = !filters || matches(filters)(item);
  const getString = (key: string) => get(strings, key, t(`${translationPrefix}.${key}`, ''));

  const handleVote = async () => {
    const confirm = await sweetConfirmAlert({
      title: getString(currentVote ? 'another-vote.change' : 'never-voted.vote-confirm'),
    });

    if (confirm) {
      trackEvent(eventTags.ITEM_CLICK, { actionType: 'vote', ...common });
      store.updateUser({ [userField]: itemValue });
    }
  };

  const handleCancelVote = async () => {
    const confirm = await sweetConfirmAlert({
      title: getString(`voted.cancel-confirm`),
    });

    if (confirm) {
      trackEvent(eventTags.ITEM_CLICK, { actionType: 'unvote', ...common });
      store.updateUser({ [userField]: null });
    }
  };

  const header = getString('header');
  const subheader = getString('subheader');

  if (!visible) {
    return null;
  }

  return (
    <Segment className={className}>
      <Header as="h3">{header}</Header>
      {!!subheader && <p>{subheader}</p>}
      {/* Case 1 : Never voted */}
      {!currentVote && (
        <div>
          <p>{getString('never-voted.title')}</p>
          <Button primary onClick={handleVote} size="small">
            {getString('never-voted.vote')}
          </Button>
        </div>
      )}
      {/* Case 2 : Voted for current item */}
      {currentVote && currentVote === itemValue && (
        <div>
          <p>{getString('voted.title')}</p>
          <Button className="link" size="small" onClick={handleCancelVote}>
            {getString('voted.cancel')}
          </Button>
        </div>
      )}
      {/* Case 3 : Voted for something else */}
      {currentVote && currentVote !== itemValue && (
        <div>
          <p>{getString('another-vote.title')}</p>
          <Button className="link" onClick={handleVote} size="small">
            {getString('another-vote.change')}
          </Button>
        </div>
      )}
    </Segment>
  );
});

export default ItemVoteBlock;
