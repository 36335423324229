/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import { bem } from '../../core/design/bem';
import SquareImage from '../SquareImage';
import './UserAvatarModal.scss';

const css = bem('UserAvatarModal');

type UserAvatarModalProps = {
  avatar?: Record<string, any>;
  onClose: any;
  onDelete: any;
};

export const UserAvatarModal = (props: UserAvatarModalProps): JSX.Element => {
  const { avatar, onClose, onDelete } = props;
  return (
    <Modal size="small" open onClose={onClose} closeIcon className={css().toString()}>
      <Modal.Header as="h4">Photo de profil</Modal.Header>
      <Modal.Content className={css('Content').toString()}>
        <SquareImage src={avatar} imageSize={240} />
      </Modal.Content>
      <Modal.Actions>
        <Button basic inverted onClick={onDelete}>
          <Icon name="trash alternate" />
          Supprimer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
