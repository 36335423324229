import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Header, Segment } from 'semantic-ui-react';
// import { Header, Icon, Item, Segment } from 'semantic-ui-react';

import Contacts from '../../components/ContactsBlock/components/Contacts';
import { variants } from '../../components/ContactsBlock/variants';
import ContactsVariantLegacy from '../../components/ContactsBlock/variants/ContactsVariantLegacy';
import { TrackingContext, useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import { getString } from '../../utils';

const ContactsBlock = ({ item, field, header, variant, actions, infos, ...rest }) => {
  const contacts = item[field];
  const { trackEvent } = useTracking();
  const trackContext = useMemo(() => {
    return {
      trackEvent: (tag, { item: contact }) =>
        trackEvent(tag || eventTags.ITEM_CONTACT_CLICK, { contact, item }),
    };
  }, [item]);
  if (!contacts || contacts.length === 0) return null;
  const ContactsVariant = variant ? variants[variant] || Contacts : ContactsVariantLegacy;
  return (
    <TrackingContext.Provider value={trackContext}>
      <Segment>
        <Header as="h3">{header || getString(`sponsors.sponsor.contact-information`)}</Header>
        <ContactsVariant
          variant={variant}
          actions={actions}
          infos={infos}
          items={contacts}
          {...rest}
        />
      </Segment>
    </TrackingContext.Provider>
  );
};

ContactsBlock.defaultProps = {
  field: 'contacts',
  header: undefined,
};

ContactsBlock.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object.isRequired,
  header: PropTypes.string,
};

export default ContactsBlock;
