import React from 'react';
import { bem } from '../../../core/design/bem';
import { useMe } from '../../../profile/hooks';
import { replaceValues } from '../../../utils/stringUtils';
import BlockContainer from '../../BlockContainer';
import CdnImage from '../../CdnImage';
import { BlockProps, ContainerProps } from '../types';
import './QrcodeBlock.scss';

const css = bem('QrcodeBlock');

type QrcodeBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: Record<string, any>;
  uri?: string;
} & typeof QrcodeBlockDefaultProps;

const QrcodeBlockDefaultProps = {
  className: '',
  container: {},
  style: {},
  uri: 'https://app.appcraft.events/qrcode/?text={_id}',
};

const QrcodeBlock = ({
  _id,
  className,
  container,
  uri,
  style,
}: QrcodeBlockProps): JSX.Element | null => {
  const user = useMe();

  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <CdnImage className={css('qrcode').toString()} src={replaceValues(uri, user)} style={style} />
    </BlockContainer>
  );
};

QrcodeBlock.defaultProps = QrcodeBlockDefaultProps;

export default QrcodeBlock;
