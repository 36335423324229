import cx from 'classnames';
import { FC, SyntheticEvent, createRef } from 'react';
import { useTranslation } from 'react-i18next';

import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { Icons } from '../../../components/Icons/material-icon.types';
import { translateWithPrefix } from '../../../translations/i18n';
import './UploadToolbar.scss';

type UploadToolbarProps = {
  className?: string;
  bordered?: boolean;
  handleOnUpload: (files: File[]) => void;
};

type UploadToolbarItem = {
  icon: {
    name: Icons;
    color: string;
  };
  label: string;
  trigger: () => void;
};

export const UploadToolbar: FC<UploadToolbarProps> = ({
  handleOnUpload,
  bordered = false,
  className,
}) => {
  const { t } = useTranslation();
  const tp = translateWithPrefix(t, 'social-wall.upload-toolbar');
  const inputRef = createRef<HTMLInputElement>();

  const handleOnClickImage = async (ev: SyntheticEvent<HTMLInputElement>) => {
    handleOnUpload((ev.target as HTMLInputElement).files as never);
  };

  const triggerOpenInput = (accept: 'image' | 'video') => {
    if (inputRef.current) {
      inputRef.current.accept = `${accept}/*`;
    }
    inputRef.current?.click();
  };

  const items: UploadToolbarItem[] = [
    //TODO: uncomment this when survey will be available
    // {
    // icon: { name: 'signal_cellular_alt', color: '#A1A1A1' },
    // label: 'survey',
    // trigger: () => ({}),
    // },
    {
      icon: { name: 'image', color: '#A1A1A1' },
      label: 'image',
      trigger: () => triggerOpenInput('image'),
    },
    {
      icon: { name: 'videocam', color: '#A1A1A1' },
      label: 'video',
      trigger: () => triggerOpenInput('video'),
    },
  ];

  return (
    <div className={cx('upload-toolbar-container', className)}>
      <input
        id="input-media-file"
        hidden
        ref={inputRef}
        onChange={handleOnClickImage}
        type="file"
        accept="image/*"
      />
      <div className={cx('upload-toolbar', bordered && 'upload-toolbar--bordered')}>
        <span className="desktop-text">{t('social-wall.upload-toolbar.desktop-text')}</span>
        {items.map(({ icon, label, trigger }, index) => (
          <div key={index.toString()} className="button-wrapper">
            <div role="button" aria-hidden="true" className="upload-toolbar-item" onClick={trigger}>
              <div className="upload-toolbar-item__label">
                <MaterialIcon name={icon.name} color={icon.color} />
                <span>{tp(label)}</span>
              </div>
            </div>
            {index !== items.length - 1 ? <div className="upload-toolbar-items-divider" /> : null}
          </div>
        ))}
      </div>
    </div>
  );
};
