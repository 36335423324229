import { Button, Item, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { getString } from '../../utils';
import { avatarWithFallbackWhenMissing } from '../../utils/avatarUtils';
import store from '../../shared/Store';
import { TrackingContext } from '../../Context';
import { eventTags } from '../../core/trackers/events';

const translationPrefix = 'networking';
export const UserInvite = ({ item, message, onAccept, onRefuse }) => {
  const { trackEvent } = useContext(TrackingContext);

  function trackInvitation(tag, userInvited) {
    trackEvent(tag, {
      userId: store.userId,
      item: userInvited,
    });
  }
  const { company, firstName, lastName, jobTitle, thumbnail } = item;
  return (
    <>
      <Item className="UserInvite">
        <Item.Image
          size="mini"
          src={avatarWithFallbackWhenMissing(thumbnail)}
          circular
          style={{ alignSelf: 'flex-start' }}
        />
        <Item.Content>
          <Item.Header>
            {firstName} {lastName}
          </Item.Header>
          {company && (
            <Item.Meta>
              {getString(`${translationPrefix}.employee-at`)} {company}
            </Item.Meta>
          )}
          {jobTitle && (
            <Item.Extra>
              <Label icon="tag" content={jobTitle} />
            </Item.Extra>
          )}
        </Item.Content>
      </Item>
      <div style={{ marginBottom: 5 }}>
        <p>{message}</p>
        <Button
          size="small"
          onClick={() => {
            trackInvitation(eventTags.NETWORKING_INVITATION_IGNORE, item);
            return onRefuse();
          }}
        >
          {getString('btn.ignore')}
        </Button>
        <Button
          positive
          size="small"
          onClick={() => {
            trackInvitation(eventTags.NETWORKING_INVITATION_ACCEPT, item);

            return onAccept();
          }}
        >
          {getString('btn.accept')}
        </Button>
      </div>
    </>
  );
};

UserInvite.propTypes = {
  item: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    company: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({
      uri: PropTypes.string,
    }),
  }).isRequired,
  message: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onRefuse: PropTypes.func.isRequired,
};
