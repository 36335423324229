import { useMount } from 'ahooks';
import { useSelector } from 'react-redux';
import { fetchAgenda } from '../../agenda/store/agenda.actions';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import store from '../../shared/Store';
import { fetchAppointments } from '../../store/actions';
import { useSyncedWorkshops } from '../../workshops/store/workshops.hooks';
// eslint-disable-next-line import/no-cycle
import Schedule from '../components/Schedule';

const UserSchedule = (props) => {
  const events = useSelector(userAgenda);
  useMount(() => {
    store.reduxStore.dispatch(fetchAppointments());
    store.reduxStore.dispatch(fetchAgenda());
  });
  useSyncedWorkshops();
  // const { startDate, endDate } = props;
  // const events = useSelector(userAgenda);
  const workshopSessions = useSelector((state) =>
    state.workshopSessions.workshopSessions?.filter((s) => !!s.startDate),
  );

  return <Schedule {...props} events={events} workshops={workshopSessions} />;
};

export default UserSchedule;
