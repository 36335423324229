import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useTracking } from '../../../Context';
import { useDesignConfig } from '../../../config/design.context';
import NativeApi from '../../../core/native/NativeApi';
import { useNativeApiMessages } from '../../../core/native/hooks';
import { eventTags } from '../../../core/trackers/events';
import { PageItem } from '../AppSecondaryMenu';

type ScannerItemProps = {
  entry: {
    title?: string;
    subtitle?: string;
  };
  className?: string;
  config: {
    style?: Record<string, any>;
    maxHeight?: number;
  };
};

// Prevent duplicate scans
let latestScan = '';

export const ScannerItem: FC<ScannerItemProps> = ({ entry, config, className }) => {
  const { title, subtitle } = entry;
  const { t } = useTranslation();
  const { primaryColor } = useDesignConfig();
  const [messageType, setMessageType] = useState('');
  const history = useHistory();
  const { trackEvent } = useTracking();

  useNativeApiMessages((type: string, payload: any) => {
    switch (type) {
      case 'scanner:scan': {
        if (payload.data === latestScan) {
          console.log('blocked scan', payload);
          return;
        }
        latestScan = payload.data;
        if (payload.data[0] === '/') {
          history.push(payload.data);
        } else {
          console.warn(payload.data);
        }
        NativeApi.postMessage('scanner:close');
        trackEvent(eventTags.QRCODE_SCAN, {
          qrcode: { data: payload.data },
        });
        toast.success(t('menu.qr-code-scanned'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: 'light',
        });
        break;
      }
      case 'scanner:close': {
        setMessageType('');
        latestScan = '';
        break;
      }
      default: {
        console.warn('ignored message', type, payload);
        break;
      }
    }
  });

  const handleClick = (): void => {
    if (messageType) {
      NativeApi.postMessage('scanner:close');
      setMessageType('');
    } else {
      NativeApi.postMessage('scanner:open', {
        barCodeTypes: ['qr'],
        header: { title, subtitle, color: primaryColor },
      });
    }
  };

  return <PageItem className={className} entry={entry} config={config} onClick={handleClick} />;
};
