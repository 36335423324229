import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import { CmsPageLayout } from '../../cms/CmsPage';
import { bem } from '../../core/design/bem';
import { useApecSessionsSync } from '../../workshop-session/store/workshopSessions.hooks';
import { WorkshopPageContent } from '../Workshop';

const css = bem('page');

export function usePageSession() {
  const { slug, _id } = useParams();
  useApecSessionsSync();
  const workshopSession = useSelector((state) => {
    return state.workshopSessions.workshopSessions?.find(
      (ws) =>
        ws._id === _id || (slug && (ws.slug === slug || ws._id === slug)) || ws.workshopId === _id,
    );
  });

  const otherSessions = useSelector((state) => {
    return state.workshopSessions.workshopSessions?.filter(
      (ws) => ws.workshopId === workshopSession?.workshopId && ws._id !== _id,
    );
  });
  return { workshop: workshopSession, otherSessions };
}

const SessionPage = ({ pageId, ...screenConfig }) => {
  const { design, pageHeader, pageFooter, collection } = screenConfig;
  const { workshop, otherSessions } = usePageSession(collection);
  if (!workshop) return null; // Loading ?
  return (
    <CmsPageLayout
      className={css({
        [pageId]: true,
        'workshop': true,
        'workshop-collection': workshop?.collection,
        'workshop-category': workshop?.category,
        'workshop-id': workshop?._id,
        [`workshop-${workshop?._id}`]: !!workshop?._id, // DEPRECATED
      }).toString()}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <WorkshopPageContent
          {...screenConfig}
          pageId={pageId}
          workshop={workshop}
          otherSessions={otherSessions}
        />
      </Container>
    </CmsPageLayout>
  );
};

SessionPage.propTypes = {
  pageId: PropTypes.string.isRequired,
};

export default SessionPage;
