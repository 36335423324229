import { useRef } from 'react';
import { useEvent } from 'react-use';

export function isDocumentVisible(): boolean {
  const state: string = document.visibilityState;
  return state === undefined || state === 'visible' || state === 'prerender';
}

export function useVisibilityChange(callback: (visible: boolean) => void): void {
  const cb = useRef(callback);
  cb.current = callback;
  useEvent('visibilitychange', () => cb.current(isDocumentVisible()));
}
