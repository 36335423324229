import { Header, Modal } from 'semantic-ui-react';
import { useEvent } from 'react-use';
import GlobalModalManager from '../../../components/GlobalModalManager';
import { ModalProps } from '../../../components/GlobalModalManager/GlobalModalManager';
import { bem } from '../../../core/design/bem';
import store from '../../../shared/Store';

const css = bem('FeedbackModal');

type FeedbackModalProps = {
  uri: string;
  item?: Record<string, any>;
  className?: string;
  autoClose?: boolean;
};

const FeedbackModal = ({
  uri,
  item,
  className,
  autoClose,
  onValidate,
  onCancel,
}: ModalProps<FeedbackModalProps, boolean>): JSX.Element => {
  useEvent('message', (evt: MessageEvent<{ type: string; payload: any }>) => {
    if (!evt.data) return;
    const { type, payload } = evt.data;
    if (type === 'feedback:step') {
      if (payload?.step === 'thanks' && autoClose) {
        onValidate(true);
      }
    }
  });

  return (
    <Modal
      className={css({}).mix(className).toString()}
      open
      basic
      closeIcon
      onClose={onCancel}
      style={{ maxWidth: 600 }}
    >
      <Modal.Content style={{ height: '85vh', padding: '40px 50px' }}>
        {!!item?.title && (
          <Header as="h4" style={{ color: 'white', marginBottom: 6 }}>
            {item.title}
          </Header>
        )}
        <iframe
          title="Feedback"
          allowTransparency
          src={`${uri}&userId=${store.userId}`}
          style={{ border: 0, height: '100%', width: '100%' }}
        />
      </Modal.Content>
    </Modal>
  );
};

FeedbackModal.open = async (props: FeedbackModalProps): Promise<boolean | null> => {
  return GlobalModalManager.open(FeedbackModal, props);
};

export default FeedbackModal;
