import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { useSetState } from 'react-use';
import { Modal, Select } from 'semantic-ui-react';

import GlobalModalManager from '../../../components/GlobalModalManager';
import { ModalProps } from '../../../components/GlobalModalManager/GlobalModalManager';
import { QueryModal } from '../../../components/QueryModal/QueryModal';
import { useBackButton } from '../../../hooks/useBackButton';
import i18n from '../../../translations/i18n';
import { PublicationReportReason, ReasonsType } from '../../types/SocialWallV2.types';
import './ReportPublicationModal.scss';

const reportReasons = [
  'inappropriate-content',
  'spam',
  'misinformation',
  'discrimination',
  'image',
  'other',
];
const formattedReportReasons = reportReasons.map((reason) => ({
  key: reason,
  text: i18n.t(`social-wall.report.reasons.${reason}`),
  value: reason,
}));

type ReportPublicationModalProps = {
  id?: string;
};
export const ReportPublicationModal = (
  props: ModalProps<ReportPublicationModalProps, any>,
): JSX.Element => {
  const { onCancel, onValidate, ...restProps } = props;
  useBackButton(onCancel);

  const { t } = useTranslation();

  const [reason, setReason] = useSetState<PublicationReportReason>({});

  const onReport = () => {
    if (!reason.type) return;
    onValidate(reason);
  };
  return (
    <QueryModal
      draggable={false}
      open
      closeIcon
      onCancel={onCancel}
      initialStep={60}
      size="small"
      scrolling
      {...restProps}
    >
      <div className="report-reasons">
        <div className="title">{t('social-wall.report.report-reason')}</div>
        <Select
          value={reason.type}
          options={formattedReportReasons}
          onChange={(e, { value }) => setReason({ type: value as ReasonsType })}
        />
        {reason.type === 'other' ? (
          <ReactTextareaAutosize
            className="report-reasons__message"
            value={reason.message}
            onChange={(e) => setReason({ message: e.target.value })}
            placeholder={t('social-wall.report.reason-placeholder')}
            maxLength={300}
          />
        ) : (
          <div style={{ flex: 1 }} />
        )}
        <Modal.Actions className="buttons-wrapper">
          <button type="button" className="cancel-button" onClick={onCancel}>
            {t('btn.cancel')}
          </button>
          <button
            type="button"
            disabled={!reason.type}
            className={cx('report-button', !reason.type && 'disabled')}
            onClick={onReport}
          >
            {t('btn.report')}
          </button>
        </Modal.Actions>
      </div>
    </QueryModal>
  );
};

ReportPublicationModal.open = async (
  props: ReportPublicationModalProps,
): Promise<boolean | null> => {
  return GlobalModalManager.open(ReportPublicationModal, props);
};
