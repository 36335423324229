/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import React from 'react';
import { Card, Header, SemanticWIDTHS } from 'semantic-ui-react';
import { bem } from '../../core/design/bem';
import { generateGroups } from '../../utils/groupUtils';
import { DataItem } from '../types';
import './DataItems.scss';
import GroupCollapsible from './GroupCollapisble';
import { DataItemCard } from './variants';

const css = bem('DataItems');

type DataGroupItemsProps = {
  items: DataItem[];
  variant?: 'card';
  itemsPerRow?: SemanticWIDTHS;
} & typeof DataGroupItemsDefaultProps;

const DataGroupItemsDefaultProps = {
  variant: 'card',
  itemsPerRow: 3,
};

type DataItemsProps = {
  groupBy?: Record<string, any>;
  config?: Record<string, any>;
} & DataGroupItemsProps &
  typeof DataItemsDefaultProps;

const DataItemsDefaultProps = {
  ...DataGroupItemsDefaultProps,
  groupBy: undefined,
};

const components = {
  card: DataItemCard,
};

const GroupItems = ({ items, variant, itemsPerRow, ...rest }: DataGroupItemsProps) => {
  const Component = get(components, variant, DataItemCard);
  return (
    <Card.Group itemsPerRow={itemsPerRow}>
      {items?.map((item) => {
        const { _id } = item;
        return <Component key={_id} item={item} config={rest} />;
      })}
    </Card.Group>
  );
};

GroupItems.defaultProps = DataGroupItemsDefaultProps;

const DataItems = ({
  items,
  variant,
  itemsPerRow,
  groupBy: groupByConfig,
  ...rest
}: DataItemsProps): JSX.Element | null => {
  if (!items.length) return null;

  if (groupByConfig) {
    const groups = generateGroups(items, groupByConfig);
    const { variant: groupVariant = 'grid', accordionConfig = {} } = groupByConfig as any;
    const isAccordion = groupVariant === 'accordion';
    return (
      <div className={css()}>
        {groups.map(({ label, id, items: groupItems }: any) => (
          <div key={id} className={css('group', { variant: groupVariant })}>
            {!isAccordion ? (
              <>
                <Header as="h2" className="title">
                  {label}
                </Header>
                <GroupItems
                  items={groupItems}
                  variant={variant}
                  itemsPerRow={itemsPerRow}
                  {...rest}
                />
              </>
            ) : (
              <GroupCollapsible config={accordionConfig} label={label}>
                <GroupItems
                  items={groupItems}
                  variant={variant}
                  itemsPerRow={itemsPerRow}
                  {...rest}
                />
              </GroupCollapsible>
            )}
          </div>
        ))}
      </div>
    );
  }
  return <GroupItems items={items} variant={variant} itemsPerRow={itemsPerRow} />;
};

DataItems.defaultProps = DataItemsDefaultProps;

export default DataItems;
