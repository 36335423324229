import cx from 'classnames';
import { FC } from 'react';
import { Container } from 'semantic-ui-react';

import { CmsPageLayout } from '../cms/CmsPage';
import PlanBlock, { DataRef } from '../components/cms/PlanBlock/PlanBlock';
import { useScreenByType, useScreensConfig } from '../config/screens.context';
import ResizableIframe from '../iframe/ResizableIframe';
import './PlanPage.scss';

type GenericPageProps = {
  design?: Record<string, any>;
  header?: Record<string, any>;
  metadata?: Record<string, any>;
  pageId: string;
  pageHeader?: Record<string, any>;
  pageFooter?: Record<string, any>;
};

type PlanPageProps = GenericPageProps & {
  planConfig?: {
    planId?: string;
    dataRef?: DataRef;
    zoom?: number;
  };
};

const planContainer = { type: 'basic' };

const defaultDataRef = { kind: 'users', collection: 'exhibitors' } as const;

const PlanPage: FC<PlanPageProps> = (props) => {
  const { header, metadata, pageId, design, pageHeader, pageFooter, planConfig = {} } = props;
  const { dataRef } = planConfig;
  const exhibitorScreen = useScreenByType('exhibitor');

  return (
    <CmsPageLayout
      className={cx('page', 'page--plan', pageId)}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <PlanBlock
          planId="default"
          {...planConfig}
          dataRef={{ ...defaultDataRef, target: exhibitorScreen?._id as any, ...dataRef }}
          container={planContainer}
        />
      </Container>
    </CmsPageLayout>
  );
};

PlanPage.defaultProps = {
  design: {},
  header: {},
  planConfig: {},
  pageFooter: undefined,
  pageHeader: undefined,
  pageId: undefined,
};

export default PlanPage;
