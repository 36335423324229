import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { formatISO, parseISO } from 'date-fns';
import groupBy from 'lodash/groupBy';
import Count from './Count';
import { localeFormat } from '../../../utils/date';
import { getString } from '../../../utils';

const dateTimeFilterProps = PropTypes.shape({
  title: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  values: PropTypes.array,
  showAll: PropTypes.bool,
  countForAll: PropTypes.number,
  hideCount: PropTypes.bool,
});

const DayTimeFilter = ({ filter, day, value, times, onSelect }) => {
  const { dateFormat = 'PPP', timeFormat = 'ppp', hideCount } = filter;
  return (
    <>
      <Menu.Header
        key={`${day}-date`}
        style={{ fontSize: '0.95em', fontWeight: 'normal', marginTop: 8 }}
      >
        {localeFormat(parseISO(day), dateFormat)}
      </Menu.Header>
      <Menu.Menu key={`${day}-times`}>
        {times.map(({ value: v, count }) => (
          <Menu.Item key={v} name={v} active={value === v} onClick={() => onSelect(filter, v)}>
            {!hideCount && <Count value={count} />}
            {localeFormat(v && parseISO(v), timeFormat)}
          </Menu.Item>
        ))}
      </Menu.Menu>
    </>
  );
};

DayTimeFilter.defaultProps = {
  value: undefined,
};

DayTimeFilter.propTypes = {
  day: PropTypes.string.isRequired,
  times: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string.isRequired })).isRequired,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filter: dateTimeFilterProps.isRequired,
};

const DateTimeFilter = ({ filter, value, onSelect }) => {
  const { title, values, showAll, countForAll, hideCount } = filter;
  if (!values || values.length === 0) return null;

  // Group by date...
  const byDate = groupBy(values, (v) => formatISO(parseISO(v.value), { representation: 'date' }));
  const days = Object.keys(byDate);
  return (
    <Menu.Item className="menu-filter--date">
      <Menu.Header>{title}</Menu.Header>
      <Menu.Menu>
        {showAll && (
          <Menu.Item name="" active={!value} onClick={() => onSelect(filter, undefined)}>
            {!hideCount && <Count value={countForAll} />}
            {getString('filters.all')}
          </Menu.Item>
        )}
      </Menu.Menu>
      {days.map((day) => (
        <DayTimeFilter
          key={day}
          day={day}
          filter={filter}
          onSelect={onSelect}
          value={value}
          times={byDate[day]}
        />
      ))}
    </Menu.Item>
  );
};

DateTimeFilter.defaultProps = {
  value: undefined,
};

DateTimeFilter.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filter: dateTimeFilterProps.isRequired,
};

export default DateTimeFilter;
