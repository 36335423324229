/* eslint-disable operator-linebreak */

/* eslint-disable jsx-a11y/label-has-associated-control */
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import zipObject from 'lodash/zipObject';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';



import { useTracking } from '../../../Context';
import CdnImage from '../../../components/CdnImage';
import ImageIcon from '../../../components/ImageIcon';
import QRCode from '../../../components/QRCode';
import { useDesignConfig } from '../../../config/design.context';
import { bem } from '../../../core/design/bem';
import { eventTags } from '../../../core/trackers/events';
import { userProptypes } from '../../../propTypes';
import { replaceValues } from '../../../utils/stringUtils';
import FieldInput from '../FieldInput';
import UserCheckboxField from './UserCheckboxField';
import './UserProfileGrid.scss';
import UserProfileAvatar from './components/UserProfileAvatar';

const translationPrefix = 'profile';
const css = bem('UserInfo');

export const PrivateMention = ({ isPrivate, style, text }) => {
  const { t } = useTranslation();
  if (!isPrivate) return null;
  return (
    <span
      className="private"
      style={{ fontWeight: 'normal', color: '#A0A0A0', fontSize: 12, marginLeft: 8, ...style }}
    >
      {text || t(`${translationPrefix}.private`)} <Icon name="lock" />
    </span>
  );
};

PrivateMention.defaultProps = {
  style: {},
  text: undefined,
  isPrivate: false,
};

PrivateMention.propTypes = {
  isPrivate: PropTypes.bool,
  style: PropTypes.object,
  text: PropTypes.string,
};

function UserInfo(props) {
  const {
    user,
    fields,
    actions,
    showResetPasswordButton,
    profileSharing,
    enableAvatar,
    editing,
    onFieldChange,
    onUpdateUser,
    avatarConfig,
    qrcode,
  } = props;
  const { visible, fields: profileSharingFields = [] } = profileSharing || {};
  const { role } = user;
  const { t } = useTranslation();
  const { primaryColor } = useDesignConfig();
  const { trackEvent } = useTracking();
  const values = fields.map((f) => ({ ...f, value: get(user, f.key) }));
  const filteredValues = editing
    ? values.filter((v) => v.editable)
    : values.filter((v) => !v.hidden);
  const isPrivateProfile = !get(user, 'sharing.enabled');
  const userSharingInfo = get(user, 'sharing');
  const mappingInfo = useMemo(() => {
    return keyBy(
      flatten(
        profileSharingFields.map(({ key, fields: sharingFields = [] }) =>
          sharingFields.map((c) => ({ key, field: c })),
        ),
      ),
      'field',
    );
  }, [profileSharingFields]);

  const handleUpdateSharing = (field, checked) => {
    if (field === 'sharing.enabled') {
      const newSharingValues = zipObject(
        Object.keys(userSharingInfo || {}).map((f) => `sharing.${f}`),
        Object.values(userSharingInfo || {}).map(() => false),
      );

      onUpdateUser({ ...newSharingValues, 'sharing.enabled': checked, 'sharing.profile': checked });
    } else onUpdateUser({ [field]: checked });
  };
  return (
    <div className={css()}>
      <Header as="h1" className={css('welcome').toString()}>
        {t(`${translationPrefix}.welcome`, user)}
      </Header>
      <Header as="h3" className={css('header').toString()}>
        {t(`${translationPrefix}.information`)}
      </Header>
      <div className={css('content')}>
        <UserProfileAvatar
          user={user}
          onUpdateUser={onUpdateUser}
          enableChange={avatarConfig ? avatarConfig.enableChange : enableAvatar}
          {...avatarConfig}
        />
        <div className={css('main')} style={{ flex: 2 }}>
          <Header className="fullname" as="h3" style={{ marginBottom: 4 }}>
            {t(`${translationPrefix}.name`, user)} <PrivateMention isPrivate={isPrivateProfile} />
          </Header>
          {role && <div className="role">{role}</div>}
          <Grid columns={2}>
            <Grid.Row className="group" style={{ paddingBottom: 0 }}>
              {filteredValues.map((field) => {
                const { key, icon, value, editable, required } = field;
                const isEditable = editable && editing;
                const hasValue = value || (!value && isEditable);
                if (!hasValue) return null;
                const isPrivate = !get(user, get(mappingInfo, [key, 'key'])) || isPrivateProfile;
                return (
                  <Grid.Column className={css('column').toString()} key={key}>
                    {icon && <ImageIcon icon={icon} maxHeight={20} style={{ marginRight: 10 }} />}
                    <div className={css('field')}>
                      <FieldInput
                        field={field}
                        label={
                          <>
                            {t(`${translationPrefix}.field.${field}.${value}`, value)}{' '}
                            <PrivateMention isPrivate={isPrivate} />
                          </>
                        }
                        value={value}
                        editable={isEditable}
                        error={required && !value}
                        onChange={onFieldChange}
                      />
                    </div>
                  </Grid.Column>
                );
              })}
            </Grid.Row>

            <Grid.Row>
              {(actions || showResetPasswordButton) && (
                <div className={css('actions')}>{actions}</div>
              )}
            </Grid.Row>
          </Grid>
        </div>
        {qrcode?.enabled && (
          <Segment className={css('qrcode').toString()}>
            {!!qrcode.uri && <CdnImage src={replaceValues(qrcode.uri, user)} />}
            {!qrcode.uri && (
              <QRCode
                data={user._id}
                width={qrcode.size}
                height={qrcode.size}
                qrcodeConfig={{
                  color: qrcode.color || primaryColor,
                  backgroundColor: qrcode.backgroundColor || '#FFF',
                  ...qrcode.config,
                }}
              />
            )}
            {qrcode.text && <p>{qrcode.text}</p>}
          </Segment>
        )}
        {profileSharing && visible && (
          <Grid className={css('profileSharing').toString()}>
            <Grid.Row>
              <Header as="h3" className="header">
                {t(`${translationPrefix}.profileSharing.title`)}
              </Header>
              <Form>
                <UserCheckboxField
                  toggle
                  field="sharing.enabled"
                  label={t(`${translationPrefix}.profileSharing.activated`)}
                  user={user}
                  onChange={(field, checked) => {
                    const tag = checked
                      ? eventTags.NETWORKING_ENABLE
                      : eventTags.NETWORKING_DISABLE;
                    trackEvent(tag, {
                      userId: user._id,
                    });
                    handleUpdateSharing(field, checked);
                  }}
                  disabled={false}
                  style={{ display: 'flex' }}
                  value={get(user, 'sharing.enabled') || false}
                />
                <Form.Group style={{ marginTop: 10 }}>
                  {profileSharingFields.map((field) => {
                    const { key, label } = field;
                    const value = get(user, key);
                    return (
                      <UserCheckboxField
                        field={key}
                        label={label}
                        onChange={handleUpdateSharing}
                        value={value}
                        disabled={!get(user, 'sharing.enabled') || key === 'sharing.profile'}
                        style={{ display: 'flex', marginLeft: 8, flex: 1 }}
                      />
                    );
                  })}
                </Form.Group>
              </Form>
              <p>{t(`${translationPrefix}.profileSharing.information-shared-with-users`)}</p>
            </Grid.Row>
          </Grid>
        )}
      </div>
    </div>
  );
}

UserInfo.defaultProps = {
  actions: undefined,
  avatarConfig: undefined,
  qrcode: undefined,
  fields: [],
  editing: false,
  enableAvatar: true,
  profileSharing: undefined,
  showResetPasswordButton: false,
};

UserInfo.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  avatarConfig: PropTypes.shape({
    enableChange: PropTypes.bool,
    showPlaceholder: PropTypes.bool,
    placeholderTemplate: PropTypes.string,
  }),
  qrcode: PropTypes.shape({
    enabled: PropTypes.bool,
    uri: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
  editing: PropTypes.bool,
  enableAvatar: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object),
  onFieldChange: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  profileSharing: PropTypes.object,
  showResetPasswordButton: PropTypes.bool,
  user: PropTypes.shape(userProptypes).isRequired,
};

export default UserInfo;