/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';

import achievementsService from '../../core/services/achievements.service';
import store from '../../shared/Store';

export type UserRanking = {
  userId: string;
  score: number;
  rank: number;
} & Record<string, any>;

export type TeamRanking = {
  teamId: string;
  teamName: string;
  score: number;
  rank: number;
} & Record<string, any>;

export type RankingInOwnTeam = {
  teamId: string;
  userCount: number;
  users: UserRanking[];
  teamName: string;
};

export function useGlobalRanking(): UserRanking[] {
  const { data: ranking = [] } = useQuery(['leaderboard/global-ranking'], () =>
    achievementsService.fetchGlobalRanking(),
  );
  return ranking as UserRanking[];
}

export function useUserRanking(): UserRanking {
  const { data = {} } = useQuery(['leaderboard/user-ranking', store.userId], () =>
    achievementsService.fetchUserRanking(store.userId),
  );
  return data as UserRanking;
}

export function useTeamRanking(teamBy: string): TeamRanking[] {
  const { data = [] } = useQuery(['leaderboard/team-ranking', teamBy], () =>
    achievementsService.fetchRankingByTeam(teamBy),
  );
  return data as TeamRanking[];
}

export function useUserRankingInOwnTeam(
  teamBy: string,
  options: { enabled?: boolean } = {},
): RankingInOwnTeam {
  const { enabled } = options;
  const { data = {} } = useQuery(
    ['leaderboard/user-team-ranking', store.userId, teamBy],
    () => achievementsService.fetchUserRankingInHisTeam(store.userId, teamBy),
    { enabled },
  );
  return data as RankingInOwnTeam;
}
