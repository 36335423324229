/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import React from 'react';
import { Container, Grid } from 'semantic-ui-react';

import { CmsPageLayout } from '../cms/CmsPage';
import ScreenIntro from '../components/screens/ScreenIntro';
import SidebarBlocks from '../components/SidebarBlocks';
import { useDataCollection } from '../core/data/data.hooks';
import { useFilter, useOrderBy, usePreFilter } from '../utils/hooks';
import { MenuFilterBlock } from '../workshops/Workshops';
import DataItems from './components/DataItems';
import { DataItem } from './types';

const sidebarBlockComponents = {
  filters: MenuFilterBlock,
};

const defaultLeftSidebar = { width: 4, blocks: [{ _id: 'filters', type: 'filters' }] };
const defaultOrder = [{ field: 'name', order: 'asc' }];

type DataPagePropTypes = {
  collection: string;
  design?: Record<string, any>;
  header?: Record<string, any>;
  metadata?: Record<string, any>;
  pageId: string;
  pageHeader?: Record<string, any>;
  pageFooter?: Record<string, any>;
  [x: string]: any;
};

function DataPage({
  pageId,
  header,
  metadata,
  design,
  pageFooter,
  pageHeader,
  collection,
  ...rest
}: DataPagePropTypes): JSX.Element {
  const items = useDataCollection(collection);
  const {
    leftSidebar = defaultLeftSidebar,
    filters: fieldsToFilter = [],
    preFilters,
    orderBy: orderByConfig = defaultOrder,
    groupBy,
    ...config
  } = rest;
  const prefilteredData = usePreFilter(items, preFilters);

  const [filteredData, allFilters, setFilters] = useFilter(prefilteredData, fieldsToFilter, {
    collection,
  });

  const finalData = useOrderBy(filteredData, orderByConfig) as DataItem[];
  const sharedSidebarProps = {
    prefilteredData,
    fieldsToFilter,
    allFilters,
    setFilters,
    pageId,
  };
  return (
    <CmsPageLayout
      className={cx('page', 'page--data', pageId)}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <Grid columns="equal" stackable>
          <SidebarBlocks
            className="sidebar--left"
            sidebar={leftSidebar}
            blockComponents={sidebarBlockComponents}
            sharedProps={sharedSidebarProps}
          />
          <Grid.Column>
            <ScreenIntro type="workshops" category={pageId} />
            <DataItems items={finalData} groupBy={groupBy} {...config} />
          </Grid.Column>
        </Grid>
      </Container>
    </CmsPageLayout>
  );
}

DataPage.defaultProps = {
  design: {},
  header: {},
  metadata: undefined,
  pageHeader: undefined,
  pageFooter: undefined,
};

export default DataPage;
