/* eslint-disable @typescript-eslint/no-unused-vars, max-len */

/* eslint-disable no-param-reassign */
import times from 'lodash/times';
import { Card } from 'semantic-ui-react';

import TemplateRenderer, { TemplateProps } from '../../../core/templates/Template';
import { useSpeakerIds } from '../../../core/users/users.hooks';
import { useAutoRefresh } from '../../../hooks/useAutoRefresh';

const workshop = {
  id: 5245,
  eventId: 'IUQD5nIlos1YXc',
  collection: 'workshops',
  _id: 'BpGdTzJtV6Zc',
  groupId: null,
  ownerId: null,
  title: 'Test Joséphine',
  duration: null,
  version: 8,
  createdAt: '2022-11-30T16:16:43.773Z',
  updatedAt: '2022-12-18T23:43:18.940Z',
  usersCount: 1,
  quota: null,
  workshopQuota: null,
  slug: 'test',
  category: 'business-lines',
  visible: true,
  startDate: '2023-01-13 08:00',
  endDate: '2023-01-13 08:30',
  image: {
    id: 62762,
    _id: 'f_62762',
    mimeType: 'image/png',
    size: 17148,
    uri:
      'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001/events/IUQD5nIlos1YXc/files/HvJ0geII3Y1iRT.png',
    width: 512,
    height: 512,
    originalFilename: 'agent.png',
  },
  speakers: ['PYXMfhAbs7LN'],
};
const TemplateBlock = (props: TemplateProps): JSX.Element | null => {
  const now = useAutoRefresh(10000);
  const speakers = useSpeakerIds(workshop.speakers);
  console.log('speakers', workshop.speakers, speakers);
  const workshopWithSpeakers = { ...workshop, speakers };
  console.log('workshopWithSpeakers', workshopWithSpeakers);
  return (
    <div>
      <Card.Group itemsPerRow={3}>
        {times(1, (n) => (
          <TemplateRenderer
            key={n}
            props={{ index: n, now: new Date(now).toISOString(), item: workshopWithSpeakers }}
            template={props}
          />
        ))}
      </Card.Group>
    </div>
  );
};

export default TemplateBlock;
