import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { parseISO } from 'date-fns';
import Count from './Count';
import { localeFormat } from '../../../utils/date';
import { getString } from '../../../utils';

const DateFilter = ({ filter, value, onSelect }) => {
  const { title, values, showAll, dateFormat = 'PPP', countForAll, hideCount } = filter;
  if (!values || values.length === 0) return null;
  return (
    <Menu.Item className="menu-filter--date">
      <Menu.Header>{title}</Menu.Header>

      <Menu.Menu>
        {showAll && (
          <Menu.Item name="" active={!value} onClick={() => onSelect(filter, undefined)}>
            {!hideCount && <Count value={countForAll} />}
            {getString('filters.all')}
          </Menu.Item>
        )}
        {values.map(({ value: v, count }) => (
          <Menu.Item key={v} name={v} active={value === v} onClick={() => onSelect(filter, v)}>
            {!hideCount && <Count value={count} />}
            {localeFormat(v && parseISO(v), dateFormat)}
          </Menu.Item>
        ))}
      </Menu.Menu>
    </Menu.Item>
  );
};

DateFilter.defaultProps = {
  value: undefined,
};

DateFilter.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    dateFormat: PropTypes.string,
    values: PropTypes.array,
    showAll: PropTypes.bool,
    countForAll: PropTypes.number,
    hideCount: PropTypes.bool,
  }).isRequired,
};

export default DateFilter;
