import React from 'react';
import { bem } from '../../../core/design/bem';
import { ActionItem } from '../types';
import Action from './Action';

const css = bem('Actions');

export type ActionProps = {
  className?: string;
  item: any;
  actions?: ActionItem[];
};

const Actions = ({ className, item, actions }: ActionProps): JSX.Element | null => {
  if (!actions) return null;
  return (
    <div className={css.mix(className)}>
      {actions.map((action, index) => (
        <Action key={action._id || index} item={item} action={action} />
      ))}
    </div>
  );
};

export default Actions;
