import { FC } from 'react';

import { UserType } from '../../../core/users/users.types';
import { UserQRCodes } from './actions/UserQRCodes';

export const QRCodeTab: FC<any> = ({
  user,
  qrcode,
}: {
  user: UserType;
  qrcode: Record<string, any>;
}) => <UserQRCodes user={user} qrcode={qrcode} />;
