import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import moment from 'moment';
import { replaceValues } from '../../utils/stringUtils';

export function groupEventsByDate(data, criteria, format) {
  return groupBy(orderBy(data, 'startDate', 'asc'), (item) =>
    moment(get(item, 'startDate')).startOf(criteria).format(format),
  );
}

export function groupEventsByDateAndHour(events, format) {
  const groupedViewsByDate = groupEventsByDate(events, 'day', format);
  return Object.keys(groupedViewsByDate).map((day) => ({
    day,
    dayEvents: groupEventsByDate(groupedViewsByDate[day], 'hour', 'HH:mm'),
  }));
}

export function getEventInfo(types, event) {
  const { collection, type, group } = event;
  const { category } = group || {};
  const key = category ? `${type}:${collection}:${category}` : `${type}:${collection}`;
  const { label, color, title: titleTemplate = '{title}', to } = get(types, key) || {};
  const title = replaceValues(titleTemplate, event);
  const path = replaceValues(to, event) || to;
  return { ...event, label, color, title, to: path };
}
