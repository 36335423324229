import { SocialWallMessage } from '../types/SocialWallTypes';
import Album from './Album';
import Grid from './Grid';
import Masonry from './Masonry';

export { Album, Grid, Masonry };

export const variants = {
  masonry: Masonry,
  grid: Grid,
  album: Album,
};

type SocialWallVariantProps = {
  variant: 'masonry' | 'grid' | 'album';
  items: SocialWallMessage[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  masonryConfig: any;
  openLightbox: (id: string) => void;
};

const SocialWallVariant = ({
  variant,
  items,
  masonryConfig,
  openLightbox,
}: SocialWallVariantProps): JSX.Element => {
  const Component = variants[variant] ?? Masonry;
  return <Component items={items} masonryConfig={masonryConfig} openLightbox={openLightbox} />;
};

export default SocialWallVariant;
