import cx from 'classnames';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import store from '../../shared/Store';
import { replaceUriValues } from '../../utils/stringUtils';
import CdnImage from '../CdnImage';

const injectStrategies = {
  user: () => store.user,
};

function ImageBlock(props) {
  const { image, title, style, inject, href, target, ...rest } = props;
  const injectedValues = useMemo(() => inject?.map((strategy) => injectStrategies[strategy]?.()), [
    inject,
  ]);

  const computedImage = useMemo(() => {
    if (!injectedValues?.length) return image;
    const globalInjectedValue = Object.assign({}, ...injectedValues);

    let res = isObject(image) ? { ...image } : image;
    if (isObject(res)) {
      res.uri = replaceUriValues(res.uri, globalInjectedValue);
    } else {
      res = replaceUriValues(res, globalInjectedValue);
    }

    return res;
  }, [image, injectedValues]);

  if (href) {
    return (
      <a
        className={cx('block--image', rest._id)}
        href={href}
        target="_blank"
        rel="noreferrer noopener"
      >
        <CdnImage
          as="img"
          src={computedImage}
          style={{ maxWidth: '100%', ...style }}
          alt={title || 'img-block'}
          maxWidth={1920}
          {...rest}
        />
      </a>
    );
  }

  return (
    <CdnImage
      className={cx('block--image', rest._id)}
      as="img"
      src={computedImage}
      style={{ maxWidth: '100%', ...style }}
      alt={title || 'img-block'}
      maxWidth={1920}
      {...rest}
    />
  );
}

ImageBlock.defaultProps = {
  image: undefined,
  style: {},
  inject: [],
  title: undefined,
};

ImageBlock.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  inject: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default ImageBlock;
