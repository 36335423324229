import { memo } from 'react';
import { Button, Divider } from 'semantic-ui-react';

const ShowMore = memo(
  ({
    showAll,
    onChange,
  }: {
    showAll: boolean;
    onChange: (value: boolean) => void;
  }): JSX.Element => (
    <Divider horizontal primary className="divider">
      <Button
        style={{ height: 30, width: 30 }}
        icon={showAll ? 'minus' : 'plus'}
        onClick={() => onChange(!showAll)}
      />
    </Divider>
  ),
);

export default ShowMore;
