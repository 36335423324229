/* eslint-disable operator-linebreak */

/* eslint-disable @typescript-eslint/no-explicit-any */
import Leaflet, { LatLngBoundsExpression, LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import get from 'lodash/get';
import { useEffect } from 'react';
import { ImageOverlay, MapContainer, Marker, Tooltip, useMap } from 'react-leaflet';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useScreenConfig } from '../../../config/screens.context';
import { bem } from '../../../core/design/bem';
import platformService from '../../../core/services/platform.service';
import { extractUsers } from '../../../core/users/users.select';
import { getParameterByName } from '../../../shared/Store';
import { replacePath } from '../../../utils/stringUtils';
import WorkshopModal from '../../../workshops/components/WorkshopModal';
import BlockContainer from '../../BlockContainer';
import GlobalModalManager from '../../GlobalModalManager';
import defaultIcon from './marker-icon.png';

const css = bem('PlanBlock');

export type DataRef = {
  kind: 'users' | 'workshops' | 'workshopSessions';
  collection: string;
  locationField?: string;
  target: string;
};

type PlanBlockProps = {
  container?: Record<string, any>;
  planId: string;
  dataRef?: DataRef;
  zoom?: number;
};

type Coordinates = {
  lat: number;
  lng: number;
};

type MarkerType = {
  _id: string;
  coordinates: Coordinates;
  locationId: string;
} & Record<string, any>;

const position = [0, 0];
const bounds = [
  [-0.06, -0.08],
  [0.06, 0.08],
];

const defaultMarker = new Leaflet.Icon({
  iconUrl: defaultIcon,
  iconRetinaUrl: defaultIcon,
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  className: 'leaflet-marker-icon',
});

type PlanMarkerType = {
  marker: MarkerType;
  dataRef?: DataRef;
  icon: any;
};

function getDataRef(state: any, locationId: string, dataRef?: DataRef) {
  if (!dataRef) return null;
  const { kind = 'users', collection, locationField = 'locationId' } = dataRef;
  let data: any[] = [];
  switch (kind) {
    case 'users': {
      const { users = [] } = extractUsers(state, collection);
      data = users;
      break;
    }
    case 'workshops':
      data = state.workshops.workshops;
      break;
    case 'workshopSessions':
      data = state.workshopsSessions.workshopsSessions;
      break;
    default:
      return [];
  }
  const filteredData = data.filter((d) => d.collection === collection);
  return filteredData.find((i: any) => {
    const itemLocationId = i[locationField];
    if (!itemLocationId) return false;
    if (Array.isArray(itemLocationId)) return itemLocationId?.includes(locationId);
    if (typeof itemLocationId === 'string') return itemLocationId === locationId;
    return false;
  });
}

const PlanMarker = ({ marker, icon, dataRef, ...rest }: PlanMarkerType) => {
  const map = useMap();
  const { target } = dataRef || {};
  const { locationId, coordinates } = marker;
  const { modal = {} } = rest as any;
  const standNumber = getParameterByName('locationId');
  const state = useSelector((s) => s);
  const history = useHistory();
  const screen = useScreenConfig(target!);

  const markerPosition = [coordinates.lat, coordinates.lng] as LatLngExpression;

  useEffect(() => {
    if (!standNumber || locationId !== standNumber) return;
    map.flyTo([marker.coordinates.lat, marker.coordinates.lng], 15);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMarkerClick = async () => {
    const data = getDataRef(state, locationId, dataRef);

    if (!data) return;
    if (modal?.show && dataRef?.kind === 'workshops') {
      const modalData = { ...data, workshopId: data._id };
      const { config = {} } = modal;
      await GlobalModalManager.open(
        ({ onCancel }) => <WorkshopModal {...modalData} {...config} onClose={onCancel} />,
        modalData,
      );
    } else {
      if (!screen || !screen?.path) return;
      history.push(replacePath(screen.path, data));
    }
  };

  return (
    <Marker icon={icon} position={markerPosition} eventHandlers={{ click: handleMarkerClick }}>
      <Tooltip>{locationId}</Tooltip>
    </Marker>
  );
};

PlanMarker.defaultProps = {
  dataRef: {},
};

export const PlanBlock = (props: PlanBlockProps): JSX.Element | null => {
  const { container, zoom = 13, planId, dataRef, ...rest } = props;
  const { data: plans = [] } = useQuery(['plans'], () => platformService.fetchCustomData('plans'));
  const plan = plans.find(({ _id }: { _id: string }) => _id === planId);
  if (!plan) return null;

  const { design, markers = [] } = plan;
  const markerPicto = get(design, 'markerPicto');
  const customIcon = markerPicto?.uri
    ? new Leaflet.Icon({
        iconUrl: markerPicto.uri,
        iconRetinaUrl: markerPicto.uri,
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        className: 'leaflet-marker-icon',
      })
    : defaultMarker;
  return (
    <BlockContainer className={css()} {...container}>
      <MapContainer
        center={position as LatLngExpression}
        zoom={zoom}
        attributionControl={false}
        scrollWheelZoom={false}
      >
        {markers.map((marker: MarkerType) => {
          const { _id: markerId } = marker;
          return (
            <PlanMarker
              {...rest}
              key={markerId}
              marker={marker}
              icon={customIcon}
              dataRef={dataRef}
            />
          );
        })}
        <ImageOverlay bounds={bounds as LatLngBoundsExpression} url={design?.image?.uri} />
      </MapContainer>
    </BlockContainer>
  );
};

PlanBlock.defaultProps = {
  container: {},
  dataRef: {},
  zoom: 13,
};

export default PlanBlock;
