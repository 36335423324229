import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import './UserRegistrationModal.scss';
import { bem } from '../../../core/design/bem';

const css = bem('UserRegistrationModal');

function UserRegistrationModalToExplicitForm(props) {
  const { onClose, formUri, title = 'Inscription' } = props;

  return (
    <>
      <Modal size="big" className={css().toString()} open onClose={onClose} closeIcon>
        <Modal.Header className={css('header').toString()}>{title}</Modal.Header>
        <Modal.Content className={css('content content-iframe').toString()}>
          <iframe
            title="form-preview"
            src={formUri}
            border="0"
            frameBorder="0"
            style={{ width: '100%', height: '70vh' }}
          />
        </Modal.Content>
      </Modal>
    </>
  );
}

UserRegistrationModalToExplicitForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  formUri: PropTypes.string.isRequired,
};

export default UserRegistrationModalToExplicitForm;
