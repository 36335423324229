type OnMessage = (type: string, payload: any) => void;

declare global {
  interface Window {
    onNativeMessage?: (type: string, payload: any) => void;
    ReactNativeWebView?: {
      postMessage: (data: string) => void;
    };
  }
}

class NativeApi {
  private subscribers: OnMessage[] = [];

  constructor() {
    window.onNativeMessage = this.handleMessage;
  }

  hasNativeApi() {
    return !!window.ReactNativeWebView?.postMessage;
  }

  postMessage = (type: string, payload?: any) => {
    if (!this.hasNativeApi()) return;
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type, payload }));
  };

  handleMessage = (type: string, payload: any) => {
    this.subscribers.forEach((func) => func(type, payload));
  };

  subscribeToMessages = (callback: OnMessage): (() => void) => {
    this.subscribers.push(callback);
    return () => this.unsubscribeToMessages(callback);
  };

  unsubscribeToMessages = (callback: OnMessage) => {
    this.subscribers = this.subscribers.filter((func) => func !== callback);
  };
}

export default new NativeApi();
