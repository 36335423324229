/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import TemplateRenderer from '../../../core/templates/Template';
import { useSpeakerIds } from '../../../core/users/users.hooks';

const WorkshopTemplate = ({
  workshop,
  //   registration,
  ...rest
}: Record<string, any>): JSX.Element => {
  const speakers = useSpeakerIds(workshop.speakers);
  const workshopWithSpeakers = useMemo(() => ({ ...workshop, speakers }), [workshop, speakers]);

  return <TemplateRenderer props={{ item: workshopWithSpeakers }} template={rest as any} />;
};
export default WorkshopTemplate;
