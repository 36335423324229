import { useMemo } from 'react';

import { highlightHtmlText } from './highlightUtils';
import { Question, QuestionWithMatched } from './types';

export function useQuestionsWithHighlightedAnswers(
  questions: Question[],
  searchString: string | null,
): QuestionWithMatched[] {
  return useMemo(
    () =>
      questions.map((question) => {
        const answer = highlightHtmlText(question.answer, searchString);
        return {
          ...question,
          answer,
          matched: answer.indexOf('<mark class=" ">') !== -1,
        };
      }),
    [questions, searchString],
  );
}
