/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import useShouldShowPrompt from './useShouldShowPrompt';

const useWebInstallPrompt = (): [any, (disablePrompt: boolean) => void, () => void] => {
  const [installPromptEvent, setInstallPromptEvent] = useState();
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt();
  const { mobile } = useMediaQuery();

  useEffect(() => {
    const beforeInstallPromptHandler = (event: any) => {
      event.preventDefault();
      console.warn('userShouldBePromptedToInstall', userShouldBePromptedToInstall);
      // check if user has already been asked
      if (userShouldBePromptedToInstall && mobile) {
        // store the event for later use
        setInstallPromptEvent(event);
      }
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, [userShouldBePromptedToInstall]);

  const handleInstallDeclined = (disablePrompt: boolean) => {
    handleUserSeeingInstallPrompt(disablePrompt);
    // @ts-ignore
    setInstallPromptEvent(null);
  };

  const handleInstallAccepted = () => {
    // show native prompt
    // @ts-ignore
    installPromptEvent.prompt();

    // decide what to do after the user chooses
    // @ts-ignore
    installPromptEvent.userChoice.then((choice) => {
      // if the user declined, we don't want to show the prompt again
      if (choice.outcome !== 'accepted') {
        handleUserSeeingInstallPrompt();
      }
      // @ts-ignore
      setInstallPromptEvent(null);
    });
  };
  return [installPromptEvent, handleInstallDeclined, handleInstallAccepted];
};
export default useWebInstallPrompt;
