/* eslint-disable no-console, max-len */
import Cookies from 'js-cookie';

import store from '../../shared/Store';
import platformService from '../services/platform.service';
import { defaultStats } from './defaultStats';
import { trackEvent as tcTrackEvent, trackPage as tcTrackPage } from './tagCommanderTracker';
import { generateEvent, getCurrentModal, getCurrentPage, splitPath } from './utils';

export function trackEvent(eventName, payload) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`trackEvent ${eventName} - blocked in dev\n`, payload);
    return;
  }

  const currentRgpdRules = store.storage.getRgpdRules();

  if (currentRgpdRules?.status === 'refuse') {
    // console.log(`trackEvent ${eventName} - blocked by rgpd policy`);
    return;
  }

  const isUserConnected = store?.user?._id;
  if (!isUserConnected) return;

  // Future switch on tracker types
  const stats = window.__DATA__?.screens?.stats || {};

  // Prefer config from stats screen if exists
  const statsConfig = stats[eventName] ? stats : defaultStats;

  if (statsConfig[eventName] && stats[eventName] !== false) {
    const event = generateEvent(eventName, payload, statsConfig);
    if (event) {
      // Push to server...
      platformService.stats(event.tag, { ...event.payload, from: window.location.href });
    }
  }

  if (window.trackEvent) {
    window.trackEvent(eventName, payload);
  }

  // Future switch on tracker types
  if (window.__DATA__?.tagCommander?.trackEvents) {
    const event = generateEvent(eventName, payload, window.__DATA__.tagCommander.trackEvents);
    if (event) {
      tcTrackEvent(event);
    }
  }
}

export function trackModal(modalConfig, payload) {
  const currentRgpdRules = store.storage.getRgpdRules();

  if (currentRgpdRules?.status === 'refuse') {
    // console.log(`trackModal ${modalConfig} - blocked by rgpd policy`);
    return;
  }
  if (process.env.NODE_ENV !== 'production') {
    // console.log(`trackModal ${modalConfig} - blocked in dev`);
    return;
  }
  const { screens } = window.__DATA__;
  if (!screens || !modalConfig || !payload) return;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const modal = getCurrentModal(screens, modalConfig);
  if (!modal) return;
  if (window.trackModal) {
    window.trackModal(modal, { ...store, item: payload, params });
  }
}

const isTarteAuCitronAnalyticsCookieEnable = () => {
  const tarteAuCitronCookieName = window.__DATA__?.cookieName || 'tarteaucitron';
  const cookieValue = Cookies.get(tarteAuCitronCookieName);
  if (!cookieValue) {
    return false;
  }
  const isMatch = /analytics=([^!]*).*/.exec(cookieValue)[1] === 'true';
  return isMatch;
};
const isTarteAuCitronGTMCookieEnable = () => {
  const tarteAuCitronCookieName = window.__DATA__?.cookieName || 'tarteaucitron';
  const cookieValue = Cookies.get(tarteAuCitronCookieName);
  if (!cookieValue) {
    return false;
  }
  const isMatch = /googletagmanager=([^!]*).*/.exec(cookieValue)[1] === 'true';
  return isMatch;
};

function trackGA(locationPath) {
  if (window.ga && isTarteAuCitronAnalyticsCookieEnable()) {
    try {
      window.ga('set', 'page', locationPath);
      window.ga('send', 'pageview');
    } catch (e) {
      console.warn('trackGA', e);
    }
  }
}

// ADEME doesn't use this anymore
// function trackGTM(_locationPath) {
//   if (window.dataLayer?.push && isTarteAuCitronGTMCookieEnable()) {
//     try {
//       window.dataLayer.push({
//         // 'event': 'virtualPageview' ?
//         event: 'pageview',
//         pageUrl: window.location.href,
//         pageTitle: window.document.title,
//       });
//     } catch (e) {
//       console.warn('trackGTM', e);
//     }
//   }
// }

export function trackPage(locationPath, screens, retries = 10) {
  const currentRgpdRules = store.storage.getRgpdRules();

  trackGA(locationPath);
  // trackGTM(locationPath);

  if (currentRgpdRules?.status === 'refuse') {
    // console.log(`trackPage ${locationPath} - blocked by rgpd policy`);
    return;
  }
  if (process.env.NODE_ENV !== 'production') {
    // console.log(`trackPage ${locationPath} - blocked in dev`);
    return;
  }
  const stats = window.__DATA__?.screens?.stats || {};
  const { trackPages = true, trackAnonymous = false } = stats;

  // TODO/ERROR : Doesn't work...
  const isUserConnected = !!store?.user?._id;
  if (!isUserConnected) {
    if (retries > 0) {
      // Missing user account, retry. When should be stop ?
      setTimeout(() => trackPage(locationPath, screens, retries - 1), 150);
    }
    return;
  }
  // Should be connected
  const isAnonymous = store.user?.collection === 'anonymous';
  if (isAnonymous && !trackAnonymous) {
    return;
  }

  // Default appcraft events tracking, no await needed
  if (trackPages) {
    platformService.stats('VIEW_PAGE', {
      path: locationPath,
      url: window.location.href,
    });
  }

  const { page } = getCurrentPage(screens, locationPath) || {};
  if (window.trackPage) {
    window.trackPage(page, { ...store, item: { slug: splitPath(locationPath)?.sub } });
  }
  if (!page) return; // Not sure what to track...

  if (window.__DATA__?.tagCommander?.trackPages) {
    tcTrackPage(locationPath, page);
  }
}
