/* eslint-disable react/require-default-props */
import omit from 'lodash/omit';

import WorkshopsMatchingBlock from '../../components/workshops/WorkshopsMatchingBlock';
import { Workshop } from '../../types/workshop.types';

type ChildWorkshopsBlockProps = {
  item: Workshop;
  container?: {
    header?: string;
    headerLevel?: string;
    type?: string;
  };
  itemProps?: {
    gridTemplate?: string;
    template?: string;
    showDetails?: boolean;
    showActions?: boolean;
    groupByDays?: boolean;
    actions?: { _id: string; type: string }[];
    showEndTime?: boolean;
    speakerConfig: { gridProps?: { columns: number }; imageSize?: 42; variant?: string };
  };
};

const ChildWorkshopsBlock = ({
  item,
  container,
  ...block
}: ChildWorkshopsBlockProps): JSX.Element => {
  return (
    <WorkshopsMatchingBlock
      {...omit(block, ['type'])}
      header={container?.header}
      headerLevel={container?.headerLevel}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      field="parentId"
      value={item._id}
    />
  );
};

export default ChildWorkshopsBlock;
