import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import store from '../../shared/Store';

export const participantsById = createSelector([(state) => state.participants], (participants) =>
  keyBy(participants.participants, '_id'),
);

export const currentUserParticipant = createSelector(
  [participantsById],
  (byId) => byId[store.userId],
);
