export function getPWADisplayMode(): 'twa' | 'standalone' | 'browser' {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window.navigator && (navigator as any).standalone) || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}

export function isPWA(): boolean {
  return getPWADisplayMode() === 'standalone';
}

export function hasWebPush(): boolean {
  return !!window.Notification;
}
