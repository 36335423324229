import orderBy from 'lodash/orderBy';
import moment, { Moment } from 'moment';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { useMe } from '../../../profile/hooks';
import { replaceValues } from '../../../utils/stringUtils';
import BlockContainer from '../../BlockContainer';
import { BlockProps, ContainerProps } from '../types';
import './FlightBlock.scss';
import { computeDuration } from './flight.utils';

const css = bem('FlightBlock');

type FlightStep = {
  _id: string;
  flightNumber: string;
  departureIsoDate: string;
  departureTime: string;
  departureDate: string;
  departureAirport: string;
  departureAirportCode: string;
  departureTerminal?: string;
  departureGate?: string;
  departureTimezone?: string;
  arrivalIsoDate: string;
  arrivalTime: string;
  arrivalDate: string;
  arrivalAirport: string;
  arrivalAirportCode: string;
  arrivalTerminal?: string;
  arrivalTimezone?: string;
  isoDuration?: string;
  duration?: string;
};

type Flights = Partial<FlightStep> & {
  steps: FlightStep[];
};

type FlightBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  steps: FlightStep[];
  subtitle?: string;
  title: string;
  fromUserField?: boolean;
};

const FlightBlock: FC<FlightBlockProps> = (props) => {
  const {
    _id,
    className,
    container,
    color = 'var(--ac-color-primary)',
    title,
    subtitle,
    steps = [],
    fromUserField,
    fieldKey = 'flights',
  } = props;

  const { t } = useTranslation();

  const user = useMe();
  const userTravel = user?.[fieldKey] as Flights;

  const travel = fromUserField
    ? orderBy(userTravel?.steps, ['departureDate', 'departureTime'], ['asc', 'asc'])
    : steps;

  const hasConnection = travel.length > 1;

  const computeFlightNumbers = () => {
    if (!hasConnection) {
      return travel[0]?.flightNumber?.match(/[a-z]+|[^a-z]+/gi)?.join(' ');
    }
    return travel.map((flight, index) => `N°${index + 1}: ${flight.flightNumber}`).join(' / ');
  };

  const flightDates = useMemo(() => {
    if (!fromUserField) return '';
    const departureDates: Moment[] = [];
    const arrivalDates: Moment[] = [];

    travel.forEach((flight) => {
      const { departureDate, departureTime, arrivalDate, arrivalTime } = flight;
      departureDates.push(moment(`${departureDate}T${departureTime}`));
      arrivalDates.push(moment(`${arrivalDate}T${arrivalTime}`));
    });

    const minDate = moment.min(departureDates).format('LT');
    const maxDate = moment.max(arrivalDates).format('LT');
    return `${minDate} > ${maxDate}`;
  }, [travel, fromUserField]);

  const flightTitle = useMemo(() => {
    return `${t('blocks.flight.flight-to', {
      count: travel?.length,
      destination: travel[travel.length - 1]?.arrivalAirport,
    })}`;
  }, [travel, t]);

  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      <div className={css('Content')}>
        <div className={css('Header')} style={{ backgroundColor: color }}>
          <Header as="h3" className="title">
            {fromUserField ? (
              flightTitle
            ) : (
              <div dangerouslySetInnerHTML={{ __html: replaceValues(title, { user }) }} />
            )}
          </Header>

          <Header as="h3" className="subtitle">
            {fromUserField ? flightDates : replaceValues(subtitle, { user })}
          </Header>

          <div className="flight-number">
            <span className="label">{t('blocks.flight.flight', { count: travel.length })}</span>
            <div className="divider" />
            <span className="number">{computeFlightNumbers()}</span>
          </div>
        </div>
        <div className={css('Steps')}>
          {travel.map((step, index) => {
            const {
              flightNumber,
              departureTime,
              departureDate,
              departureAirportCode,
              departureAirport,
              departureTerminal,
              departureGate,
              arrivalAirportCode,
              arrivalAirport,
              arrivalTime,
              arrivalDate,
              arrivalTerminal,
              isoDuration,
              duration,
            } = step;
            return (
              <>
                <div className={css('date')}>{moment(departureDate).format('LL')}</div>
                <div className={css('Step')} key={`step ${index + 1}`}>
                  <div className="departure-and-duration">
                    <div className="stopovers">
                      <div className="circle" />
                      <div className="divider" />
                    </div>
                    <div className="infos">
                      <div className="departure">
                        <span className="time" style={{ color }}>
                          {fromUserField
                            ? moment(`${departureDate}T${departureTime}`).format('LT')
                            : departureTime}
                        </span>
                        <span className="airport">
                          <span>
                            {departureAirport} ({departureAirportCode}) - {flightNumber}
                          </span>

                          {departureTerminal && (
                            <span className="airport__infos">
                              {t('blocks.flight.terminal')} : <strong>{departureTerminal}</strong>
                            </span>
                          )}
                          {departureGate && (
                            <span className="airport__infos">
                              {t('blocks.flight.gate')} : <strong>{departureGate}</strong>
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="duration">
                        {(duration || isoDuration) &&
                          t('blocks.flight.duration', {
                            duration: duration || computeDuration(isoDuration || ''),
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="arrival">
                    <div className="circle" />
                    <span className="time" style={{ color }}>
                      {fromUserField
                        ? moment(`${arrivalDate}T${arrivalTime}`).format('LT')
                        : arrivalTime}
                    </span>
                    <span className="airport">
                      <span>
                        {arrivalAirport} ({arrivalAirportCode})
                      </span>

                      {arrivalTerminal && (
                        <span className="airport__infos">
                          {t('blocks.flight.terminal')} : <strong>{arrivalTerminal}</strong>
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </BlockContainer>
  );
};

export default FlightBlock;
