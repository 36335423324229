import PropTypes from 'prop-types';
import React from 'react';
import Media from 'react-media';
import { Menu } from 'semantic-ui-react';

import FilterModal from '../../core/filter/FilterModal';
import { generateFilters } from '../../core/filter/utils';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import './TopMenu.scss';
import MenuFilters from './blocks/MenuFilters';

const TopMenuFilterBlock = React.memo((props) => {
  const { prefilteredData, fieldsToFilter, allFilters, setFilters, filterConfig } = props;
  const filterProps = {
    filterList: generateFilters(prefilteredData, fieldsToFilter, allFilters),
    filters: allFilters,
    onChange: setFilters,
    filterConfig,
  };
  return (
    <Media queries={GLOBAL_MEDIA_QUERIES}>
      {(matches) => (
        <>
          {matches.mobile && <FilterModal {...filterProps} />}
          {matches.desktop && <MenuFilters {...filterProps} />}
        </>
      )}
    </Media>
  );
});

const TopMenu = ({ menu, sharedProps }) => {
  const { blocks = [], menuProps } = menu;
  if (blocks.length === 0) return null;

  return (
    <Menu className="menu--filters-top" {...menuProps}>
      {blocks.map((block) => {
        switch (block.type) {
          case 'filters': {
            return <TopMenuFilterBlock {...sharedProps} key={block._id} />;
          }
          default: {
            console.warn('Unknown menu block type', block.type);
            return null;
          }
        }
      })}
    </Menu>
  );
};

TopMenu.defaultProps = {
  menu: {},
  sharedProps: undefined,
};

TopMenu.propTypes = {
  menu: PropTypes.shape({
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
  }),
  sharedProps: PropTypes.object,
};

export default TopMenu;
