import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'semantic-ui-react';

import { Achievement } from '../../../../types/achievements.types';
import AchievementBadge from '../../../AchievementBadge';
import CdnImage from '../../../CdnImage';
import ExpiringNavLink from '../../../ExpiringNavLink';
import { Image } from '../../types';
import { ensureTarget } from '../utils';
import './CTACard.scss';

const translationPrefix = 'blocks.ctaTiles';

type CTACardProps = {
  achievements?: Achievement[];
  aspectRatio?: boolean | number | string;
  className?: string;
  title?: string;
  to?: string;
  subtitle?: string;
  imageProps?: Record<string, string>;
  image?: Image;
  target?: string;
  showMoreButton?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: any;
};

const CTACard = ({
  achievements,
  className,
  to,
  target,
  image,
  title,
  subtitle,
  aspectRatio,
  imageProps,
  onClick,
  showMoreButton,
}: CTACardProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Card
      className={className}
      as={to ? ExpiringNavLink : undefined}
      to={to}
      target={ensureTarget(target, to)}
      fluid
      onClick={onClick}
    >
      <CdnImage src={image} as="img" maxWidth={500} aspectRatio={aspectRatio} {...imageProps} />
      <Card.Content style={{ display: 'flex', flexDirection: 'column' }}>
        <Card.Header>
          {title}
          {achievements?.map((a) => (
            <AchievementBadge key={a._id} achievement={a} height={25} />
          ))}
        </Card.Header>
        {subtitle && <Card.Description style={{ flex: 1 }}>{subtitle}</Card.Description>}
        {showMoreButton && (
          <Button
            primary
            floated="right"
            as={to ? ExpiringNavLink : undefined}
            to={to}
            target={ensureTarget(target, to)}
            onClick={onClick}
            style={{ marginTop: 10 }}
          >
            {t(`${translationPrefix}.show-more`)}
          </Button>
        )}
      </Card.Content>
    </Card>
  );
};

CTACard.defaultProps = {
  achievements: undefined,
  className: '',
  aspectRatio: undefined,
  subtitle: undefined,
  image: undefined,
  imageProps: {},
  showMoreButton: false,
  target: undefined,
  to: undefined,
  title: '',
};

export default CTACard;
