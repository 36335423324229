import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { usePostUserLikes } from './context/SocialLikes.context';
import { useUpdateReactions } from './hooks/useUpdateReactions';

function SocialWallReaction(props) {
  const { item, post } = props;
  const { id: postId, reactions = {} } = post;
  const { key, color, icon } = item;
  const userReactions = usePostUserLikes(postId);
  const userReactionsByType = keyBy(userReactions, 'type');
  const hasUserReaction = userReactionsByType[key];

  const { addLike, removeLike } = useUpdateReactions();

  const handleLike = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!hasUserReaction) {
      await addLike(postId, key);
    } else {
      await removeLike(postId, key);
    }
  };

  return (
    <div className="SocialWall--Card__Actions--reactions__reaction">
      <Icon
        size="large"
        name={hasUserReaction ? icon : `${icon} outline`}
        color={hasUserReaction ? color : 'grey'}
        onClick={handleLike}
      />
      <p className="count">{reactions[key] || 0}</p>
    </div>
  );
}

SocialWallReaction.propTypes = {
  item: PropTypes.shape({ key: PropTypes.string, color: PropTypes.string, icon: PropTypes.string })
    .isRequired,
  post: PropTypes.object.isRequired,
};

export default SocialWallReaction;
