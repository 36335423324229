import { useMemo } from 'react';
import { SemanticICONS } from 'semantic-ui-react';

import { UserGroup } from '../../core/services/notifications.service';
import { UserType } from '../../core/users/users.types';

type BaseGroupOption = {
  key: string;
  icon: SemanticICONS;
  value: string;
  text: string;
  label?: { content: string; color: string };
  description?: string;
};

export type UserOption = BaseGroupOption & { type: 'user'; user: UserType };
export type GroupOption = BaseGroupOption & { type: 'group'; group: UserGroup };

export type UserGroupOption = UserOption | GroupOption;

function userToOption(user: UserType): UserGroupOption {
  const value = `user-${user._id}`;
  return {
    icon: 'user',
    key: value,
    value,
    text: `${user.firstName || ''} ${user.lastName || ''}`,
    description: user.collection,
    type: 'user',
    user,
  };
}

function groupToOption(group: UserGroup): UserGroupOption {
  const value = `group-${group.userKey}-${group.userValue}`;
  return {
    icon: 'users',
    key: value,
    value,
    text: group.groupName,
    description: group.groupCategory,
    type: 'group',
    group,
  };
}

export function useUserAndGroupOptions(
  users: UserType[] = [],
  groups: UserGroup[] = [],
): UserGroupOption[] {
  return useMemo(() => {
    return [...users.map(userToOption), ...groups.map(groupToOption)];
  }, [users, groups]);
}
