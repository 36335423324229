import noop from 'lodash/noop';
import { useEffect, useMemo } from 'react';

import store from '../../shared/Store';
import PubsubService, { Subscription } from '../services/pubsub.service';

export const useSubscriptions = (subscriptions: Subscription[], enabled: boolean): any => {
  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    PubsubService.subscribe(subscriptions);
    return () => PubsubService.unsubscribe(subscriptions);
  }, [enabled, subscriptions]);
};

export const useSubscription = (subscription: Subscription, enabled: boolean): any => {
  const subscriptions = useMemo(() => [subscription], [subscription]);
  return useSubscriptions(subscriptions, enabled);
};

export const usePushService = (userId: string | null): void => {
  useEffect(() => {
    if (!userId) {
      PubsubService.stopSession();
      return noop;
    }
    PubsubService.startSession(userId, store.token);
    return () => PubsubService.stopSession();
  }, [userId]);
};
