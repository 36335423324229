export type CountdownTimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export type CountdownItem = {
  key: keyof CountdownTimeLeft;
  maxValue: number;
  valueLabel?: string;
  label?: string;
};

export const defaultItemList: CountdownItem[] = [
  { key: 'days', maxValue: 365 },
  { key: 'hours', maxValue: 24 },
  { key: 'minutes', maxValue: 60 },
  { key: 'seconds', maxValue: 60 },
];
