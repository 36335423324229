import { FC } from 'react';
import { Message } from 'semantic-ui-react';

import { getString } from '../../utils';

const translationPrefix = 'login';

type MessagesProps = {
  message?: string | null;
  error?: string | null;
};

const Messages: FC<MessagesProps> = ({ message, error }) => (
  <>
    {message && (
      <div className="Login--MessageWrapper">
        <Message content={message} />
      </div>
    )}
    {error && (
      <div className="Login--MessageWrapper">
        <Message error header={getString(`${translationPrefix}.error`)} content={error} />
      </div>
    )}
  </>
);
export default Messages;
