import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'semantic-ui-react';
import get from 'lodash/get';
import { useMe } from '../../profile/hooks';
import BlockContainer from '../../components/BlockContainer';
import store from '../../shared/Store';

const ParticipantsRgpdBlock = ({ field, container, message }) => {
  const { t } = useTranslation();

  const me = useMe();

  const handleToggle = (_e, { checked }) => {
    store.updateUser({ [field]: checked });
  };

  return (
    <BlockContainer {...container} className="block--ParticipantsRgpdBlock">
      <Checkbox
        toggle
        checked={!!get(me, field)}
        onChange={handleToggle}
        label={message || t('participants.rgpd-block.message')}
      />
    </BlockContainer>
  );
};

export default ParticipantsRgpdBlock;
