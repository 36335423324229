import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Header, Popup } from 'semantic-ui-react';

import BlockContainer from '../../components/BlockContainer';
import CdnImage from '../../components/CdnImage';
import { containerTypePropTypes, imageProptypes } from '../../propTypes';
import './ImageWithIcons.scss';

function ImageWithIcons(props) {
  const { header, containerType, backgroundImage, buttons, showLabel } = props;
  return (
    <BlockContainer className="block--image-with-icons" type={containerType} header={header}>
      <div style={{ position: 'relative' }}>
        <CdnImage
          src={backgroundImage}
          className="image__icons__background"
          style={{ width: '100%' }}
        />
        <div className="image__icons">
          {buttons.map(({ label, link, x, y, width, height, image }) => {
            if (showLabel) {
              return (
                <NavLink
                  className="image__icons__button labeled"
                  to={link || '#'}
                  style={{
                    left: `calc(${x} - ${width}px / 2)`,
                    top: `calc(${y} - ${height}px / 2)`,
                    height,
                  }}
                >
                  <Header as="h4" className="image__icons__button--label">
                    {label}
                  </Header>
                  <CdnImage src={image} maxWidth={width} maxHeight={height} func="crop" />
                </NavLink>
              );
            }

            return (
              <Popup
                content={label}
                basic
                position="bottom center"
                trigger={
                  <NavLink
                    className="image__icons__button"
                    to={link || '#'}
                    style={{
                      left: `calc(${x} - ${width}px / 2)`,
                      top: `calc(${y} - ${height}px / 2)`,
                      width,
                      height,
                    }}
                  >
                    <CdnImage src={image} maxWidth={width * 2} maxHeight={height * 2} func="crop" />
                  </NavLink>
                }
              />
            );
          })}
        </div>
      </div>
    </BlockContainer>
  );
}

ImageWithIcons.defaultProps = {
  containerType: 'segment',
  header: undefined,
  backgroundImage: undefined,
  buttons: [],
  showLabel: false,
};

ImageWithIcons.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  backgroundImage: imageProptypes,
  buttons: PropTypes.array,
  showLabel: PropTypes.bool,
};

export default ImageWithIcons;
