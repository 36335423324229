import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { useScreenConfig } from '../../config/screens.context';
import { useInviteMode } from '../InviteModes.hooks';
import { tabsFromReduxState } from './useNetworkingTabs.helpers';

function useNetworkingTabs(pageId) {
  const page = useScreenConfig(pageId || 'networking');
  const inviteMode = useInviteMode();
  const location = useLocation();
  const tabs = useSelector(tabsFromReduxState(inviteMode, page));
  const activePane = tabs.findIndex(({ menuItem }) => {
    return matchPath(location.pathname, {
      path: menuItem.to,
      exact: menuItem.exact,
    });
  });
  return { tabs, activePane };
}

export default useNetworkingTabs;
