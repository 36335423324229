import React, { FC } from "react";
import { useSyncedCollectionWorkshops } from "../../../workshops/store/workshops.hooks";
import Schedule from "../../../profile/components/Schedule";
import {
  useMergeWorkshopSessions,
  useSyncedCollectionWorkshopSessions
} from "../../../workshop-session/store/workshopSessions.hooks";

type WorkshopAgendaBlockProps = {
  _id: string;
  collection: string;
}
const WorkshopAgendaBlock: FC<WorkshopAgendaBlockProps> = (props) => {
  const { collection } = props;
  const workshops = useSyncedCollectionWorkshops(collection);
  const sessions = useSyncedCollectionWorkshopSessions(collection);
  const merged = useMergeWorkshopSessions(workshops, sessions);
  return <Schedule {...props} events={merged} />;
}

export {
  WorkshopAgendaBlock
}
