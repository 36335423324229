import React from 'react';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { getString } from '../../utils';

const Tag = ({ value, translationPrefix, ...props }) => (
  <Label {...props}>{getString(`${translationPrefix}.${value}`) || value}</Label>
);

Tag.propTypes = {
  value: PropTypes.string.isRequired,
  translationPrefix: PropTypes.string.isRequired,
};

export default Tag;
