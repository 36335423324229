import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';

import { updateOrAppendItem } from '../../utils/arrayUtils';
import { SET_BY_ID, SET_COLLECTION, SET_COLLECTIONS } from './users.types';

const initialState = {
  users: [], // All users, use with care
  usersById: {}, // All collections, use with care
  collections: {
    // collaborators: { collection: "", users: [], usersById: {} }
  },
};

const cleanUser = (user) => ({ kind: 'users', ...user });
const cleanUsers = (users) => users.map(cleanUser);

function updateCollections(state, userCollections) {
  // Update all users
  const collectionStores = mapValues(userCollections, (users, collection) => ({
    collection,
    users,
    usersById: keyBy(users, '_id'),
  }));
  const collections = {
    ...state.collections,
    ...collectionStores,
  };

  const allUsers = flatten(values(collections).map((col) => col.users));
  const usersById = keyBy(allUsers, '_id');
  return {
    ...state,
    collections,
    users: allUsers,
    usersById,
  };
}

export default function reduceUsers(state = initialState, action) {
  switch (action.type) {
    case SET_COLLECTIONS: {
      const { userCollections } = action;
      const users = mapValues(userCollections, cleanUsers);

      return updateCollections(state, users);
    }
    case SET_COLLECTION: {
      const { collection } = action;
      const users = cleanUsers(action.users);
      return updateCollections(state, { [collection]: users });
    }
    case SET_BY_ID: {
      const { collection } = action;
      const user = cleanUser(action.user);
      const users = updateOrAppendItem(state.collections[collection]?.users ?? [], user);
      return updateCollections(state, { [collection]: users });
    }
    default:
      return state;
  }
}
