/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable react/prop-types */
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { Card, Icon, Image } from 'semantic-ui-react';

import SquareImage from '../../components/SquareImage';
import { useScreenConfig } from '../../config/screens.context';
import { avatarWithFallbackWhenMissing } from '../../utils/avatarUtils';
import { fromNow } from '../../utils/dateUtils';
import { defaultReactions } from '../SocialWallCard';
import { usePostUserLikes } from '../context/SocialLikes.context';
import { useUpdateReactions } from '../hooks/useUpdateReactions';

const Thumbnail = ({ src }) => {
  if (src?.uri) {
    return <SquareImage className="thumbnail" src={src} imageSize={35} avatar size="mini" />;
  }

  return (
    <Image className="thumbnail" src={avatarWithFallbackWhenMissing(src)} avatar size="mini" />
  );
};

function FooterCaption(props) {
  const { currentView } = props;
  const { reactions = defaultReactions } = useScreenConfig('socialWall');

  const {
    id: postId,
    firstName,
    lastName,
    thumbnail,
    createdAt,
    message,
    reactions: reactionsCount = {},
  } = currentView;

  const userReactions = usePostUserLikes(postId);
  const userReactionsByType = keyBy(userReactions, 'type');

  const { addLike, removeLike } = useUpdateReactions();

  const toggleReaction = async (e, key) => {
    e.preventDefault();
    e.stopPropagation();

    const hasUserReaction = userReactionsByType[key];
    if (!hasUserReaction) {
      await addLike(postId, key);
    } else {
      await removeLike(postId, key);
    }
  };

  return (
    <div>
      <div className="SocialWall--Card__Actions">
        <div style={{ display: 'flex' }}>
          <div className="SocialWall--Card__Actions--reactions">
            {reactions.map(({ icon, color, key }) => {
              const count = reactionsCount[key] || 0;
              const hasCount = count !== 0;
              return (
                <div
                  type="button"
                  className="SocialWall--Card__Actions--reactions__reaction"
                  onClick={(e) => toggleReaction(e, key)}
                >
                  <Icon
                    size="large"
                    name={hasCount ? icon : `${icon} outline`}
                    color={hasCount ? color : 'grey'}
                  />
                  <p className="count" style={{ color: hasCount ? 'white' : '#777' }}>
                    {count}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="SocialWall--Card__User">
        <Thumbnail src={thumbnail} />
        <div>
          <Card.Description className="fullname">
            {firstName} {lastName}
          </Card.Description>
          <Card.Description className="date">
            <Icon name="clock outline" />
            {fromNow(createdAt)}
          </Card.Description>
        </div>
      </div>
      <Card.Description
        className="SocialWall--Card__Message quoted"
        style={{ fontSize: 14, color: 'white' }}
      >
        {message}
      </Card.Description>
    </div>
  );
}

FooterCaption.propTypes = {
  currentView: PropTypes.object.isRequired,
};

export default FooterCaption;
