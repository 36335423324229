import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import { Message } from 'semantic-ui-react';
import { format, formatISO, parseISO, startOfHour } from 'date-fns';
import SlotLine from './SlotLine';
import { getString } from '../../../utils';

export const SlotGrid = ({ slots, onSelect, displayFullMessage, allowOverlappingSessions }) => {
  const slotsWithTime = orderBy(slots, 'startDate').map((slot) => {
    const { startDate } = slot;
    const date = parseISO(startDate);
    return { ...slot, date, time: format(date, "HH'h'mm") };
  });
  const slotsByHour = groupBy(slotsWithTime, (slot) => formatISO(startOfHour(slot.date)));
  return (
    <div className="SlotGrid">
      {displayFullMessage && (
        <Message
          error
          className="no-availability-message"
          header={getString('appointments.steps.select-slot.no-slot-left')}
        />
      )}
      {Object.entries(slotsByHour).map(([startTime, lineSlots]) => (
        <SlotLine
          allowOverlappingSessions={allowOverlappingSessions}
          key={startTime}
          startTime={startTime}
          slots={lineSlots}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};

SlotGrid.defaultProps = {
  allowOverlappingSessions: false,
  displayFullMessage: false,
};
SlotGrid.propTypes = {
  slots: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  allowOverlappingSessions: PropTypes.bool,
  displayFullMessage: PropTypes.bool,
};
