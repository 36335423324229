import { ConditionContext, evalCondition } from '@appcraft/common-utils';
import { useMount } from 'ahooks';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';

import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { CmsBlocks } from '../../../cms/CmsScreen';
import { bem } from '../../../core/design/bem';
import { useMe } from '../../../profile/hooks';
import store from '../../../shared/Store';
import { evalValue } from '../../../utils/constraints';
import { setIn } from '../../../utils/object.utils';
import { useUserHasRequireAgendaEntries } from './utils';

const LOCAL_STORAGE_KEY = `${store.storage.getLocalStorageKey()}-options`;

const css = bem('CmsModalBlock');

type Block = {
  id: string;
  type: string;
};

enum Mode {
  ONCE = 'once',
  MULTIPLE = 'multiple',
}

function useIsVisible(
  context: Record<string, any>,
  mode: Mode,
  condition?: Record<string, any>,
): boolean {
  if (mode === Mode.ONCE) return true;
  if (!condition) return false;
  const { type } = condition;
  if (type === 'jsep' && !!condition.exp) return evalValue(condition, context);
  return evalCondition(condition as ConditionContext, context.user);
}

type CmsModalBlockProps = {
  blocks: Block[];
  condition?: Record<string, any>;
  config: Record<string, any>;
  header?: string;
  _id: string;
};

const CmsModalBlock: FC<CmsModalBlockProps> = (props) => {
  const { blocks, condition, config, header, _id } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [baseLocation] = useState(location.pathname);
  const [isOpen, setIsOpen] = useState(true);
  const user = useMe();
  const agenda = useSelector(userAgenda);
  const { size, showActions, agendaConfig, mode } = config ?? {};
  const displayMode = !mode && !!condition?.exp ? Mode.MULTIPLE : mode || Mode.ONCE;
  const { collection = 'workshops' } = agendaConfig || {};
  const { hasRequiredSlots } = useUserHasRequireAgendaEntries(collection);
  const optionsStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '{}');
  const isAlreadySeen = useMemo(() => optionsStorage.modals?.[_id]?.seen, [
    optionsStorage.modal,
    _id,
  ]);
  const isVisible = useIsVisible({ user, agenda, hasRequiredSlots }, displayMode, condition);

  useMount(() => {
    if (isVisible || displayMode === Mode.ONCE) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(setIn(optionsStorage, `modals.${_id}`, { seen: true, date: new Date() })),
      );
    }
  });

  useEffect(() => {
    if (baseLocation !== location.pathname) {
      setIsOpen(false);
    }
  }, [baseLocation, location.pathname]);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isVisible || !isOpen || (displayMode === Mode.ONCE && isAlreadySeen)) return null;

  return (
    <Modal
      className={css().toString()}
      open={isOpen}
      size={size}
      closeIcon
      dimmer={{ className: css('Dimmer').toString() }}
      onClose={handleClose}
    >
      {header && <Modal.Header className={css('header').toString()}>{header}</Modal.Header>}
      <Modal.Content className={css('content').toString()}>
        <CmsBlocks blocks={blocks} />
      </Modal.Content>
      {showActions && (
        <Modal.Actions>
          <Button>{t('btn.cancel')}</Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default CmsModalBlock;
