import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import MenuFilter from '../../../core/filter/MenuFilter';
import { generateFilters } from '../../../core/filter/utils';
import { useFilter, useOrderBy } from '../../../utils/hooks';
import { useScreenConfig } from '../../../config/screens.context';
import { useMatchingsWithExhibitors } from '../../../matching/store/matching.hooks';

function FilterMenuBlock(props) {
  const {
    pageId,
    filters: fieldsToFilter,
    config: filterConfig,
    orderBy: orderByConfig,
    onChange,
  } = props;

  const { scoreCriteria = 'positive' } = useScreenConfig(pageId);
  const offers = useMatchingsWithExhibitors('exhibitors', scoreCriteria);
  const [filteredData, currentFilters, setFilters] = useFilter(offers, fieldsToFilter);
  const finalOffers = useOrderBy(filteredData, orderByConfig);

  useEffect(() => {
    onChange?.(finalOffers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, JSON.stringify(finalOffers)]);

  return (
    <>
      {fieldsToFilter && fieldsToFilter.length > 0 && (
        <Grid.Column width={4} className="sidebar--left">
          <MenuFilter
            filterList={generateFilters(finalOffers, fieldsToFilter, currentFilters)}
            filters={currentFilters}
            onChange={setFilters}
            filterConfig={filterConfig}
          />
        </Grid.Column>
      )}
    </>
  );
}

FilterMenuBlock.defaultProps = {
  pageId: 'matching',
  filters: [],
  config: {},
  orderBy: [{ field: 'score', order: 'desc' }],
  onChange: undefined,
};

FilterMenuBlock.propTypes = {
  pageId: PropTypes.string,
  filters: PropTypes.array,
  config: PropTypes.object,
  orderBy: PropTypes.array,
  onChange: PropTypes.func,
};

export default FilterMenuBlock;
