import get from 'lodash/get';
import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import { replaceUriValues, replaceValues } from '../../../utils/stringUtils';
import { ActionItem } from '../types';
import './Action.scss';
import { trackEvent } from '../../../core/trackers';
import { eventTags } from '../../../core/trackers/events';

const css = bem('Action');

export type ContactBlockProps = {
  item: any;
  action: ActionItem;
  onClick?: () => void;
};

const Action = ({ item, action }: ContactBlockProps): JSX.Element | null => {
  const { _id, field, label, displayTemplate, uri, icon } = action;
  const value = get(item, field);
  if (!value) return null;
  return (
    <a
      className={css({ id: _id, field })}
      href={uri?.indexOf(':') === -1 ? replaceUriValues(uri, item) : replaceValues(uri, item)}
      target="_blank"
      rel="noreferrer noopener"
      onClick={() => trackEvent(eventTags.ITEM_CONTACT_CLICK, { field, item })}
    >
      <div className={css('Icon')}>{!!icon && <Icon name={icon as SemanticICONS} />}</div>
      <div className={css('Label')}>{label}</div>
      <div className={css('Value')}>
        {displayTemplate ? replaceValues(displayTemplate, item) : value}
      </div>
    </a>
  );
};

export default Action;
