/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/no-danger */
import React from 'react';
import { Modal } from 'semantic-ui-react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Highlighter = require('react-highlight-words');

const FaqModal = (props: {
  question: Record<any, string>;
  onClose: any;
  searchWords: string[];
}): JSX.Element => {
  const { question, onClose, searchWords } = props;
  const { title, answer } = question;

  return (
    <>
      <Modal open basic closeIcon onClose={onClose} scrolling className="modal-faq">
        <Modal.Header as="h3" className="modal-faq__title">
          <Highlighter searchWords={searchWords} autoEscape textToHighlight={title} />
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: '40vh' }} className="modal-faq__content">
          <div dangerouslySetInnerHTML={{ __html: answer }} className="modal-faq__answer" />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default FaqModal;
