/* eslint-disable @typescript-eslint/no-explicit-any */
import { css as applyCss, SerializedStyles } from '@emotion/react';
import { useMemo } from 'react';
import { useDesignConfig } from '../../config/design.context';
import { ActionItem, ContactsDesign, InfoProps } from './types';

export type ContactsCSSProps = {
  actions: ActionItem[];
  infos: InfoProps[];
  design: ContactsDesign;
};

export function useContactsCSS(
  props: ContactsCSSProps,
  styleGenerator?: (p: ContactsCSSProps) => string[] | string,
): SerializedStyles {
  const { actions, infos, design: componentDesign } = props;
  const appDesign = useDesignConfig();
  const { colors, css, cssStyle } = componentDesign || {};
  const style = useMemo(() => {
    const design = {
      ...appDesign,
      ...componentDesign,
      colors: {
        ...colors,
        primary: colors?.primary || appDesign.primaryColor,
      },
    };
    return applyCss(
      css,
      cssStyle,
      styleGenerator &&
        styleGenerator({
          actions,
          infos,
          design,
        }),
    );
  }, [actions, infos, componentDesign, appDesign, styleGenerator]);
  return style;
}
