import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Icon } from 'semantic-ui-react';

export const PrintPageButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button {...props} onClick={() => window.print()}>
      <Icon name="print" /> {t('btn.print')}
    </Button>
  );
};
