/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import { getString } from '../../utils';
import VideoEmbed from '../VideoEmbed';
import './VideoModal.scss';

const VideoModal = (props) => {
  const { autoPlay, controls, onClose, video, item, videoProps, statsMode } = props;
  const { title, file, value } = video;
  const { trackEvent } = useTracking();
  // eslint-disable-next-line no-extra-boolean-cast
  const videoTag = item ? eventTags.ITEM_VIDEO_VIEW : eventTags.VIDEO_VIEW;
  const videoTitle = video?.title || video?.originalFilename || '';
  const videoUrl = video?.value?.uri || file?.uri;
  const originalFilename =
    video?.originalFilename || file?.originalFilename || video?.value?.originalFilename;

  useEffect(() => {
    if (!autoPlay) return;
    trackEvent(videoTag, { video, item, videoUrl, videoTitle, originalFilename });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onDownload(e) {
    e.stopPropagation();
    trackEvent(item ? eventTags.ITEM_VIDEO_DOWNLOAD : eventTags.VIDEO_DOWNLOAD, {
      video,
      item,
      videoUrl,
      videoTitle,
    });
  }

  return (
    <Modal className="VideoModal" basic closeIcon onClose={onClose} open size="large">
      <Modal.Content>
        {file && file.uri ? (
          <video
            className="VideoModal--Video"
            autoPlay={autoPlay}
            controls={controls}
            src={file.uri}
            {...videoProps}
          />
        ) : (
          <VideoEmbed
            autoPlay={autoPlay}
            className="VideoModal--Video"
            defaultActive
            iframe={{
              allowFullScreen: true,
            }}
            url={value.uri}
            statsMode={statsMode}
            {...video}
            video={video}
            videoProps={videoProps}
            item={item}
          />
        )}
      </Modal.Content>
      {title && (
        <Modal.Header className="VideoModal--Header">
          {title}
          {value?.downloadUri && (
            <Button
              floated="right"
              color="blue"
              as="a"
              target="_blank"
              onClick={onDownload}
              href={value.downloadUri}
            >
              {getString('btn.download')}
            </Button>
          )}
        </Modal.Header>
      )}
    </Modal>
  );
};
VideoModal.defaultProps = {
  autoPlay: true,
  controls: true,
  item: undefined,
  statsMode: undefined,
};
VideoModal.propTypes = {
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.object,
  statsMode: PropTypes.string,
  video: PropTypes.shape({
    originalFilename: PropTypes.string,
    title: PropTypes.string.isRequired,
    file: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default VideoModal;
