import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Modal } from 'semantic-ui-react';
import SpeakerCard from '../../components/speakers/SpeakerCard';
import { useScreenConfig } from '../../config/screens.context';
import UserProfileDetail from '../../profile/components/UserProfileDetail/UserProfileDetail';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { fetchParticipants } from '../store/participants.actions';
import { currentUserParticipant } from '../store/participants.selectors';

export const UserProfileModal = ({ user, form, onClose }) => {
  const [userPatch, setUserPatch] = useState(user);
  const { fields = [] } = form;
  function handleChange(newPatch) {
    setUserPatch((patch) => ({ ...patch, ...newPatch }));
  }
  async function handleSave() {
    await store.updateUser(userPatch);

    if (onClose) {
      onClose();
    }
    // Manually force refresh...
    store.reduxStore.dispatch(fetchParticipants());
  }
  return (
    <Modal open onClose={onClose}>
      <Modal.Content>
        <Grid>
          <UserProfileDetail
            user={{ ...user, ...userPatch }}
            fields={fields}
            onFieldChange={handleChange}
            editing
            onUpdateUser={handleSave}
          />
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{getString('btn.close')}</Button>
        <Button primary disabled={Object.keys(userPatch).length === 0} onClick={handleSave}>
          {getString('btn.save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const UserPreviewBlock = () => {
  const [showModal, setShowModal] = useState(false);
  const { itemConfig = {}, userForm = {} } = useScreenConfig('participants');
  const user = useSelector(currentUserParticipant);
  if (!user) return null;
  const { extraFields = [] } = itemConfig;
  return (
    <>
      <SpeakerCard
        extraFields={extraFields}
        speaker={user}
        imageSize={110}
        showLink={false}
        variant="light"
      >
        <Button icon="pencil" content={getString('btn.edit')} onClick={() => setShowModal(true)} />
      </SpeakerCard>
      {showModal && (
        <UserProfileModal user={user} onClose={() => setShowModal(false)} form={userForm} />
      )}
    </>
  );
};

export default UserPreviewBlock;
