import { bem } from '../../core/design/bem';
import './BottomTabs.scss';
import TabItem from './components/TabItem';
import { TabEntry } from './tab.types';

const css = bem('Tabs');

const BottomTabs = ({ tabs }: { tabs: TabEntry[] }): JSX.Element | null => {
  if (!tabs) return null;
  return (
    <div className={css({ bottom: true })}>
      {tabs.map((tab) => (
        <TabItem key={tab._id} {...tab} />
      ))}
    </div>
  );
};

export default BottomTabs;
