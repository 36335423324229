import { useFullscreen } from 'ahooks';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

import PageBackground from '../../components/PageBackground';
import { useDesignConfig } from '../../config/design.context';
import { bem } from '../../core/design/bem';
import { useMe } from '../../profile/hooks';
import { validatesCondition } from '../../utils/conditionUtils.ts';
import '../SocialWall.scss';
import { UserLikesProvider } from '../context/SocialLikes.context';
import useLastSeen from '../hooks/useLastSeen';
import useLightbox from '../hooks/useLightbox';
import { useSocialWallData } from '../useSocialWallData';
import SocialWallVariant from '../variants';
import NewSocialWallPostModal from './NewSocialWallPostModal';

const translationPrefix = 'social-wall';

const css = bem('SocialWall');

const FullscreenBackground = () => {
  const design = useDesignConfig();
  const { background, mobileHomeBackground } = design;
  const { color: backgroundColor, image: backgroundImage, mobile: backgroundMobileImage } =
    background || {};

  return (
    <PageBackground
      {...background}
      image={backgroundImage || (!backgroundColor && design.homeBackground)}
      mobile={backgroundMobileImage || mobileHomeBackground}
    />
  );
};

function SocialWall(props) {
  const { fullscreen, masonryConfig, postCondition, variant } = props;
  const [isOpen, setIsOpen] = useState(false);
  const socialWallRef = useRef();
  const [isFullscreen, { setFull }] = useFullscreen(socialWallRef);
  const { t } = useTranslation();
  const userProfile = useMe();
  const { messages, likes } = useSocialWallData();
  const { lightbox, openLightbox } = useLightbox(messages);
  const canPost = validatesCondition(postCondition, userProfile);

  useLastSeen(messages);

  return (
    <UserLikesProvider likes={likes}>
      <div className={css.toString()}>
        {lightbox}
        {canPost && (
          <div className="u-mobile-hide" style={{ textAlign: 'right' }}>
            {fullscreen?.available && (
              <Button data-action="expand" className="SocialWall--Button icon" onClick={setFull}>
                <Icon name="expand" />
              </Button>
            )}
            <Button
              data-action="send"
              className="SocialWall--Button"
              onClick={() => setIsOpen(true)}
            >
              <Icon name="send" />
              {t(`${translationPrefix}.add-message`)}
            </Button>
          </div>
        )}
        <div
          ref={socialWallRef}
          className={css('Wrapper').state({ fullscreen: isFullscreen }).toString()}
        >
          {isFullscreen && <FullscreenBackground />}
          <SocialWallVariant
            variant={variant}
            items={messages}
            masonryConfig={masonryConfig}
            openLightbox={openLightbox}
          />
        </div>
        {canPost && isOpen && <NewSocialWallPostModal onClose={() => setIsOpen(false)} />}

        <button
          type="button"
          data-action="send"
          className="SocialWall--Button button--mobile-action u-mobile-only"
          onClick={() => setIsOpen(true)}
        >
          <Icon name="plus" />
          <span className="button--label">{t('social-wall.add-message')}</span>
        </button>
      </div>
    </UserLikesProvider>
  );
}

SocialWall.defaultProps = {
  fullscreen: { available: true },
  masonryConfig: {},
  postCondition: undefined,
  variant: 'masonry',
};

SocialWall.propTypes = {
  fullscreen: PropTypes.shape({ available: PropTypes.bool }),
  masonryConfig: PropTypes.shape({ columnWidth: PropTypes.number, columnGutter: PropTypes.number }),
  postCondition: PropTypes.object,
  variant: PropTypes.string,
};

export default SocialWall;
