/* eslint-disable import/no-cycle */
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import matches from 'lodash/matches';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import trim from 'lodash/trim';
import values from 'lodash/values';
import zipObject from 'lodash/zipObject';
import { MutableRefObject, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { getString } from '.';
import { useTracking } from '../Context';
import { useEntityPath } from '../components/EntityLink';
import { useConfig } from '../config/config.context';
import { useAvailableScreenPaths } from '../config/screens.context';
import { applyFilters } from '../core/filter/utils';
import useUrlState from '../hooks/useUrlState';
import { useMe } from '../profile/hooks';
import store from '../shared/Store';
import { buildSortSanitizer } from './sortUtils';
import { replaceValues, urlJoin } from './stringUtils';

export type Entity = {
  _id: string;
  collection?: string;
  kind?: string;
  category?: string;
} & Record<string, any>;

export type OrderBy = {
  field: string;
  order?: 'asc' | 'desc';
};

export type Filter = {
  dataKey: string;
  type?: string;
};

export type TimezoneDate = {
  timezone: string;
};

type CalendarEvent = any; // TODO

export function orderByItems<T>(items: T[], itemOrderBy?: OrderBy[]): T[] {
  if (!itemOrderBy || itemOrderBy.length === 0) return items;

  const fields = itemOrderBy.map((o) => buildSortSanitizer(o.field));
  const orders = itemOrderBy.map((o) => o.order ?? 'asc');
  return orderBy(items, fields, orders);
}

// eslint-disable-next-line import/prefer-default-export
export function useOrderBy<T>(items: T[], itemOrderBy?: OrderBy[]): T[] {
  return useMemo(() => orderByItems(items, itemOrderBy), [items, itemOrderBy]);
}

export function usePreFilter<T>(data: T[], preFilters: Record<string, any>): T[] {
  const allFilters = useMemo(
    () => preFilters && pickBy(preFilters, (v) => !!v || typeof v === 'boolean'),
    [preFilters],
  );
  const result = useMemo(() => applyFilters(data, allFilters, []), [data, allFilters]);
  return result;
}

function isQueryStringFilter(filter: Filter): boolean {
  return !!filter.dataKey && filter.type !== 'router-param';
}

export function useFilter<T>(data: T, filterList: Filter[], defaultFilters = {}): T[] {
  const [currentFilters, setFilters] = useUrlState({});
  const allFilters = useMemo(() => {
    // Remove junk from url state
    const validUrlFilters = pick(
      currentFilters,
      filterList.map((f) => f.dataKey), // TODO: works for all filters ?
    );
    return pickBy({ ...defaultFilters, ...validUrlFilters }, (v) => v != null);
  }, [currentFilters, filterList, defaultFilters]);

  const result = useMemo(() => applyFilters(data, allFilters, filterList), [
    data,
    allFilters,
    filterList,
  ]);
  return [
    result,
    allFilters,
    (f: any) => {
      // Disable all other filters
      const keys = filterList.map((filter) => filter.dataKey).filter((v) => v);
      const queryParams = filterList.filter(isQueryStringFilter).map((filter) => filter.dataKey);
      const nullFilters = zipObject(
        keys,
        keys.map(() => null),
      );
      setFilters({ ...nullFilters, ...pick(f, queryParams) });
    },
  ];
}

function generateUrl(
  basename: string,
  lang: string,
  canonicalPath: string,
  item: any,
  externalLink: boolean,
): string {
  if (!canonicalPath) return externalLink ? window.location.href : '';

  const baseUrlValue = externalLink ? `${window.location.protocol}//${window.location.host}` : '';
  const path = urlJoin([
    externalLink ? basename : '',
    replaceValues(canonicalPath, {
      ...item,
      lang,
    }),
  ]);
  return `${baseUrlValue}/${trim(path, '/')}`;
}

export const useEntityUrl = (entity: Entity, externalLink = true): string => {
  const { basename, lang, screens } = useConfig();
  const { path } = useEntityPath(entity);
  return useMemo(() => {
    return generateUrl(basename, lang, path, entity, externalLink);
  }, [basename, lang, entity, screens, externalLink]);
};

export function entityForEvent(event: CalendarEvent): Entity {
  const { _type } = event;
  if (_type === 'appointment') {
    return { kind: 'users', ...event.group };
  }
  if (_type === 'session') {
    return { kind: 'workshops', ...event };
  }
  return event;
}

export const useCalendarEventLink = (
  event: CalendarEvent,
  externalLink = true,
): string | undefined => {
  const { lang, screens } = useConfig();
  const url = useEntityUrl(entityForEvent(event), externalLink);
  if (event.workshopId && event._id) {
    // Special treatment for workshop sessions ?
    if (screens.workshop?.ical?.url) {
      return replaceValues(screens.workshop?.ical?.url, { ...event, lang, userId: store.userId });
    }
  }
  return url;
};

export const useWorkshopTracker = (tag: string, entity: Entity): ((currentReplay: any) => void) => {
  const hasClicked = useRef(false);
  const { trackEvent } = useTracking();

  function onInferredClick(currentReplay: any) {
    // Only track the first click to know if launched
    if (!hasClicked.current) {
      trackEvent(tag, { workshop: entity, replay: currentReplay });
      hasClicked.current = true;
    }
  }

  return onInferredClick;
};

export function getTagLabel(type: string, key: string, value: string | number): string {
  if (!value) return '';

  if (type === 'participants') {
    const schema = window.__DATA__.design?.options?.users?.[type];
    const field = schema?.[key];
    if (field?.choices) {
      return field?.choices[value]?.label || value;
    }
    return value as string;
  }

  let translationKey = `${type}.${key}.${value}`;
  if (typeof value !== 'number') {
    translationKey = `${type}.${key}.${value.replace(/[.]/g, '')}`;
  }
  return getString(translationKey) || value;
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function useEntityTags(
  entity: Entity,
  type: string,
  tagFields: string[],
): { field: string; value: string }[] {
  return useMemo(() => {
    if (!tagFields) return [];
    return flatten(
      tagFields
        .map((field) => {
          const value = get(entity, field);
          if (!value) return null;
          const formattedValue = Array.isArray(value) ? value : [value];
          return formattedValue
            .map((v) => ({ field, value: getTagLabel(type, field, v) }))
            .filter((v) => !!v.value);
        })
        .filter(notEmpty),
    );
  }, [entity, type, tagFields]);
}

export function usePagination(
  items: any[],
  maxItems?: number,
): { totalPages?: number; pageItems: any[]; setActivePage?: (page: number) => void } {
  const [activePage, setActivePage] = useState(1);
  return useMemo(() => {
    if (!maxItems || !items) return { pageItems: items };
    const totalPages = Math.ceil(items?.length / (maxItems || 1));
    const startIndex = (activePage - 1) * maxItems;
    const pageItems = maxItems ? items.slice(startIndex, startIndex + maxItems) : items;
    return { totalPages, pageItems, setActivePage };
  }, [items, activePage, maxItems]);
}

export function usePageFromEntity(entity: Entity): any {
  const { screens } = useConfig();
  return values(screens).find((page) => {
    const { path, entities } = page;
    return path && entities && entities.find((pageEntity: any) => matches(pageEntity)(entity));
  });
}

export function useUserTimezone(): any {
  const { timezones, defaultTimezone } = useConfig();
  const user = useMe();
  return useMemo(() => {
    if (!timezones?.length) return defaultTimezone || null;
    return get(user, 'timezone', defaultTimezone);
  }, [user, timezones, defaultTimezone]);
}

function findUserTimezone(
  userTimezone?: string | null,
  timezoneDates?: TimezoneDate[],
): TimezoneDate | undefined {
  if (!timezoneDates || !userTimezone) return undefined;
  return timezoneDates.find((d) => d.timezone === userTimezone);
}

export function useUserTimezoneInfo(
  timezoneValues: TimezoneDate[] | undefined,
  defaultValue: Record<string, any>,
): any {
  const { timezones, defaultTimezone } = useConfig();
  const user = useMe();
  const userTimezone = useMemo(() => {
    if (!timezones?.length) return defaultTimezone || null;
    return get(user, 'timezone', defaultTimezone);
  }, [user, timezones, defaultTimezone]);

  const timezoneData = findUserTimezone(userTimezone, timezoneValues);
  return { ...defaultValue, ...timezoneData };
}

export const useFunctionRef = <T>(fun: T): MutableRefObject<T> => {
  const functionRef = useRef<T>(fun);
  functionRef.current = fun;
  return functionRef;
};

export const useBodyScrollLock = (): void => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.setProperty('overflow', 'hidden', 'important');

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
};

type UseBodyScrollReturnType = {
  disableScrolling: () => void;
  enableScrolling: () => void;
};

export const useBodyScroll = (): UseBodyScrollReturnType => {
  const stopScroll = () => {
    const html = document.querySelector('html') as HTMLElement;
    const body = document.querySelector('body') as HTMLElement;
    body.scrollTop = 0;
    html.scrollTop = 0;
  };

  const disableScrolling = () => {
    document.body.classList.add('disable-scroll');
    document.addEventListener('touchend', stopScroll);
    setTimeout(stopScroll, 10);
  };

  const enableScrolling = () => {
    document.body.classList.remove('disable-scroll');
    document.removeEventListener('touchend', stopScroll);
  };

  return {
    disableScrolling,
    enableScrolling,
  };
};

export const useGetScreenPathByType = (screenType: string): string | undefined => {
  const page = useAvailableScreenPaths();
  return useMemo(() => {
    const allPageValue = Object.values(page);
    const screen = find(allPageValue, (p) => p.type === screenType);
    return screen?.path;
  }, [page, screenType]);
};
