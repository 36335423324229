export const isEllipsisActive = (el?: HTMLElement | null): boolean => {
  if (!el) return false;
  return el.offsetHeight < el.scrollHeight;
};

export const formatNumberToKiloString = (count: number): string => {
  if (count < 1000) {
    return `${count}`;
  }

  if (count < 10000) {
    return `${Math.floor(count / 100) / 10}k`;
  }

  return `${Math.floor(count / 1000)}k`;
};
