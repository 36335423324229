import React, { useState } from 'react';
import { Message, Segment } from 'semantic-ui-react';
import { DateTimeDisplay } from '../../components/DateTimeDisplay';
import { useGroupByDay } from '../../workshop-session/components/WorkshopSessionsModal/WorkshopSessionsModal';
import { Workshop, WorkshopSession } from '../../types/workshop.types';
import UserRegistrationModalToExplicitForm from '../../authentication/components/UserRegistrationModal/UserRegistrationModalToExplicitForm';
import SessionRegistrationButton from '../components/SessionRegistrationButton';
import { useWorkshopSessions } from '../../workshop-session/store/workshopSessions.hooks';

type WorkshopSessionsRegistrationProps = {
  formUri: string;
  item: Workshop;
};

const WorkshopSessionsRegistrationBlock = (
  props: WorkshopSessionsRegistrationProps,
): JSX.Element => {
  const { formUri, item: workshop } = props;
  const sessions = useWorkshopSessions(workshop.collection || 'workshops', workshop._id)?.data;

  const sessionsByDay = useGroupByDay(sessions);
  const days = Object.keys(sessionsByDay);
  const [isOpen, setIsOpen] = useState(false);
  const [sessionId, setSessionId] = useState('');

  const handleClick = (session: WorkshopSession) => {
    setIsOpen(true);
    setSessionId(session._id);
  };

  return (
    <>
      {days.map((day) => {
        return (
          <Segment
            className="workshop--registrationBlock workshop--registrationBlock--session"
            key={day}
          >
            <Message>
              <Message.Header>
                <DateTimeDisplay startDate={day} />
              </Message.Header>
            </Message>
            <div
              className="workshops--registrationBlock__grid"
              style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '5px' }}
            >
              {sessionsByDay[day].map((session: WorkshopSession) => {
                return (
                  <SessionRegistrationButton
                    key={session._id}
                    session={session}
                    handleClick={handleClick}
                  />
                );
              })}
            </div>
            {isOpen && (
              <UserRegistrationModalToExplicitForm
                formUri={`${formUri}?sessionId=${sessionId}`}
                onClose={() => setIsOpen(false)}
              />
            )}
          </Segment>
        );
      })}
    </>
  );
};

export default WorkshopSessionsRegistrationBlock;
