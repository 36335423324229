import React from 'react';

import CdnImage from '../../../components/CdnImage';
import { bem } from '../../../core/design/bem';
import { replaceValues } from '../../../utils/stringUtils';
import { UserRanking } from '../../hooks/leaderboard.hooks';
import './Podium.scss';

const css = bem('Podium');

export const defaultThumbnail = {
  uri: 'https://cdn.appcraft.events/events/r9qr9DyOiTI4pg/files/TARJvWFcPgIaHY.jpg',
};

const crown = {
  uri: 'https://cdn.appcraft.events/events/mcYHiPzRm8nLLq/files/O0gCWuxARrknSy.png',
};

type PodiumUserProps = {
  className: string;
  user: UserRanking;
  subtitleTemplate: string;
};

const PodiumUser = (props: PodiumUserProps): JSX.Element => {
  const { user, className, subtitleTemplate = '{company}' } = props;
  const { rank, score, firstName, lastName, thumbnail } = user;
  const company = replaceValues(subtitleTemplate, user);
  return (
    <div className={`user ${className}`}>
      <div className="thumbnail">
        {rank === 1 && <CdnImage src={crown} className="crown" maxWidth={40} />}
        <CdnImage src={thumbnail || defaultThumbnail} maxWidth={60} className="avatar" />
        <div className="rank">{rank}</div>
      </div>
      <div className="infos">
        <div className="firstName">{firstName}</div>
        <div className="lastName">{lastName}</div>
        {company && <div className="company">{company}</div>}
      </div>
      <div className="score">{score}</div>
    </div>
  );
};

type PodiumProps = {
  users: UserRanking[];
  subtitleTemplate: string;
};

export const Podium = (props: PodiumProps): JSX.Element => {
  const { users = [], subtitleTemplate } = props;
  const [first, second, third] = users;
  return (
    <div className={css()}>
      <PodiumUser className="second" user={second} subtitleTemplate={subtitleTemplate} />
      <PodiumUser className="first" user={first} subtitleTemplate={subtitleTemplate} />
      <PodiumUser className="third" user={third} subtitleTemplate={subtitleTemplate} />
    </div>
  );
};
