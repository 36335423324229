/* eslint-disable class-methods-use-this */
import { AchievementAndCategory, UserAchievement } from '../../types/achievements.types';
// eslint-disable-next-line import/no-cycle
import platformService from './platform.service';

class AchievementsService {
  async fetchAchievements(): Promise<AchievementAndCategory> {
    return platformService.api().url(`/achievements`).get().json();
  }

  async fetchUserAchievements(userId: string): Promise<UserAchievement[]> {
    return platformService.api().url(`/achievements/users/${userId}`).get().json();
  }

  async fetchGlobalRanking() {
    return platformService.api().url(`/leaderboard/users`).get().json();
  }

  async fetchRankingByTeam(teamBy: string) {
    if (!teamBy) return [];
    return platformService.api().url(`/leaderboard/users`).query({ teamBy }).get().json();
  }

  async fetchUserRanking(userId: string) {
    return platformService.api().url(`/leaderboard/users/${userId}`).get().json();
  }

  async fetchUserRankingInHisTeam(userId: string, teamBy: string) {
    if (!teamBy) return undefined;
    return platformService.api().url(`/leaderboard/users/${userId}`).query({ teamBy }).get().json();
  }
}
export default new AchievementsService();
