import decode from 'querystring/decode';
import encode from 'querystring/encode';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { useScreenConfig } from '../../config/screens.context';
import MenuFilter from '../../core/filter/MenuFilter';
import { eventTags } from '../../core/trackers/events';
import { useMe } from '../../profile/hooks';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { InviteModes } from '../InviteModes';
import { useInviteMode } from '../InviteModes.hooks';
import AwaitingInvites from '../blocks/AwaitingInvites';
import InviteModal from '../blocks/InviteModal';
import NetworkingParticipantList from '../blocks/NetworkingParticipantList';
import useContactSearch from '../hooks/useContactSearch';
import ChatStatus from '../store/ChatStatus';
import { acceptInvite, refuseInvite, sendInvite } from '../store/networking.actions';
import { invitesThatIDidntTreat } from '../store/networking.selectors';

function getInvites(state) {
  const mostRecentLast = (a, b) => a.createdAt.localeCompare(b.createdAt);
  return invitesThatIDidntTreat(state).sort(mostRecentLast);
}

const useInitialSearchParams = (filters) => {
  const location = useLocation();
  const [urlParams] = useState(() => {
    const params = decode(location.search);
    const filtersValue = Object.fromEntries(
      filters.map((f) => [f.dataKey, params[f.dataKey] || '']),
    );
    return filtersValue;
  });

  return urlParams;
};

export function getUserFilterValue(entity, key) {
  const value = entity[key];
  if (!value) return '';
  return getString(`filters.${key}.${value}`) || value;
}

const useUpdateUrlForSearch = (filters, page, path) => {
  const history = useHistory();
  useEffect(() => {
    history.replace(`${path}?${encode(filters)}`);
  }, [history, filters, page]);
};

const NetworkingContactSearch = (props) => {
  const { trackEvent } = useTracking();
  const { pageId } = props;

  function trackNetworkingConnection(userInvited) {
    trackEvent(eventTags.NETWORKING_CONNECT_TO_USER, {
      userId: store.userId,
      item: userInvited,
    });
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const invites = useSelector(getInvites);

  const {
    filters: filtersConfig,
    filterField,
    shuffleContacts = true,
    path = '/networking',
  } = useScreenConfig(pageId || 'networking');

  const inviteMode = useInviteMode();
  const filtersValue = useInitialSearchParams(filtersConfig);
  const [currentFilters, setFilters] = useState(filtersValue || {});
  const { contacts, totalCount, loading, next, lastPage, currentPage } = useContactSearch(
    currentFilters,
    shuffleContacts,
  );

  const me = useMe();
  useUpdateUrlForSearch(currentFilters, currentPage, path);
  const [personToInvite, setPersonToInvite] = useState(null);
  const updateFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const gridWidth = inviteMode !== InviteModes.IMMEDIATE ? 8 : 12;

  return (
    <>
      <Grid className="NetworkingContactSearch--Container">
        <Grid.Column width={4} className="NetworkingContactSearch--Filters">
          <MenuFilter
            label={getUserFilterValue(me, filterField)}
            filterList={filtersConfig}
            filters={currentFilters}
            onChange={updateFilters}
          />
        </Grid.Column>
        <Grid.Column
          className="NetworkingContactSearch--ParticipantList"
          width={gridWidth}
          mobile={12}
          computer={gridWidth}
        >
          <Segment>
            <Dimmer active={loading} inverted>
              <Loader />
            </Dimmer>
            <div>
              {/* NOT: this div fixes default header margins when the dimmer is hidden */}
              <NetworkingParticipantList
                contacts={contacts}
                onConnect={setPersonToInvite}
                totalCount={totalCount}
                canLoad={!lastPage && !loading}
                onLoad={next}
              />
            </div>
          </Segment>
        </Grid.Column>
        {inviteMode !== InviteModes.IMMEDIATE && (
          <Grid.Column width={4} only="computer">
            <AwaitingInvites
              invites={invites}
              onRefuse={(invite) => dispatch(refuseInvite(invite.id))}
              onAccept={(invite) => {
                history.push(`/networking/chats/${invite.id}`);
                dispatch(acceptInvite(invite.id));
              }}
            />
          </Grid.Column>
        )}
      </Grid>
      {personToInvite && (
        <InviteModal
          who={personToInvite}
          onSend={(who, message) => {
            // TODO track connect-to-user
            const status =
              inviteMode === InviteModes.IMMEDIATE ? ChatStatus.accepted : ChatStatus.invited;
            trackNetworkingConnection(who);
            dispatch(sendInvite(who, message, status));
            setPersonToInvite(null);
          }}
          onCancel={() => setPersonToInvite(null)}
        />
      )}
    </>
  );
};

export default NetworkingContactSearch;
