import { useEffect } from 'react';

import useEvent from '../hooks/useEvent.hook';
import NativeApi from './NativeApi';

export function useNativeApiMessages(callback: unknown): any {
  const constantCallback = useEvent(callback);

  useEffect(() => NativeApi.subscribeToMessages(constantCallback), [constantCallback]);
}
