import cx from 'classnames';
import { FC } from 'react';

import GlobalModalManager from '../../../../components/GlobalModalManager';
import { QRCodeConfig } from '../../../../components/QRCode';
import { QueryModal } from '../../../../components/QueryModal/QueryModal';
import { bem } from '../../../../core/design/bem';
import { UserType } from '../../../../core/users/users.types';
import { UserQRCodes } from './UserQRCodes';

const css = bem('QRcodeAction');

type QRcodeStatic = {
  open: (props: any) => Promise<boolean | null>;
};

type QRcodeType = {
  uri: string;
  badge?: { enabled?: boolean; templateUrl?: string };
  size: number;
  config?: QRCodeConfig;
  color?: string;
  backgroundColor?: string;
};

type QRcodeProps = {
  user: UserType;
  qrcode: QRcodeType;
  onCancel: () => void;
};

export const QRcode: FC<QRcodeProps> & QRcodeStatic = (props) => {
  const { qrcode, user, onCancel, ...restProps } = props;

  return (
    <QueryModal
      className={cx('Action', 'QRCodeAction')}
      draggable
      open
      onCancel={onCancel}
      initialStep={80}
      closeIcon
      {...restProps}
    >
      <UserQRCodes user={user} qrcode={qrcode} />
    </QueryModal>
  );
};

QRcode.open = async (props: QRcodeProps): Promise<boolean | null> => {
  return GlobalModalManager.open(QRcode, props);
};
