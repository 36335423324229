import merge from 'lodash/merge';
import React, { FC, useMemo } from 'react';

import { withDesign } from './config/design.context';
import { Theme, useDesignSystem } from './hooks/useDesignSystem';
import Images from './utils/Images';
import { CSSVariables } from './utils/designSystemUtils';
import { CustomFont } from './utils/fontUtils';

interface DesignOverride {
  primaryColor: string;
  secondaryColor: string;
  customCSS: string;
}

interface DesignProps {
  design: {
    customCSS?: string;
    fonts: { body: CustomFont & { fallbacks?: 'custom' | 'systemfont' } };
    primaryColor?: string;
    secondaryColor?: string;
    system?: {
      theme?: Theme;
      variables?: CSSVariables;
    };
  };
  designOverride: DesignOverride;
}

const Styles: FC<DesignProps> = (props: DesignProps) => {
  const { design, designOverride } = props;
  const { customCSS = '', fonts, system, primaryColor, secondaryColor } = design;

  const customPrimaryColor =
    designOverride?.primaryColor || system?.variables?.color?.primary || primaryColor;
  const customSecondaryColor =
    designOverride?.secondaryColor ||
    system?.variables?.color?.secondary ||
    secondaryColor ||
    customPrimaryColor;

  const { generateGlobalStyle } = useDesignSystem(system?.theme);

  const globalStyle = useMemo(
    () =>
      generateGlobalStyle({
        additionalVariables: merge(system?.variables || {}, {
          font: {
            family: "Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif",
          },
          color: { primary: customPrimaryColor, secondary: customSecondaryColor },
        }),
        customFont: fonts,
      }),
    [customPrimaryColor, customSecondaryColor, fonts, generateGlobalStyle, system],
  );

  return (
    <style
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
          ${Images.applyCDNToCSS(globalStyle)}
          ${Images.applyCloudImageToCSS(customCSS)}
          ${Images.applyCloudImageToCSS(designOverride?.customCSS || '')}
          `,
      }}
    />
  );
};

export default withDesign(Styles);
