import { CSSProperties, FC } from 'react';
import { Divider } from 'semantic-ui-react';

import { bem } from '../../../../core/design/bem';
import { Label } from '../../../../design-system/Label/Label';
import { formatNumberToKiloString } from '../../../../utils/text.utils';
import { useSocialWallConfig } from '../../../social-wall-config.context';
import { Publication } from '../../../types/SocialWallV2.types';
import { CommentInput } from '../../Comments/CommentInput';
import { Comments } from '../../Comments/Comments';
import { EmoteList } from '../../EmoteList';
import './PublicationTextAndComment.scss';

interface PublicationTextAndCommentProps {
  publication: Publication;
  comments: Publication[];
  userInteractions: string[];
  style?: CSSProperties;
  withEmote?: boolean;
  showEmojiPicker?: boolean;
  disabled?: boolean;
  onAddComment: (mesage: string) => Promise<Publication>;
  onDeleteComment: (commentId: string) => Promise<void>;
  onEmoteClick?: (emote: string) => Promise<void>;
}

const css = bem('publication-content');

export const PublicationTextAndComment: FC<PublicationTextAndCommentProps> = ({
  publication,
  comments,
  userInteractions,
  style,
  withEmote = true,
  showEmojiPicker,
  disabled,
  onAddComment,
  onDeleteComment,
  onEmoteClick,
}) => {
  const { data, interactions } = publication;
  const { message } = data;
  const { commentsEnabled, interactionsEnabled } = useSocialWallConfig();

  const formattedCommentCount = formatNumberToKiloString(publication.commentCount);
  return (
    <div className="publication-content-wrapper" style={style}>
      {message && <div className={css()}>{message}</div>}
      <Divider />
      {withEmote && (
        <>
          <div className={css('emotes-wrapper', { end: !interactionsEnabled })}>
            <EmoteList
              interactions={interactions}
              userInteractions={userInteractions}
              onEmoteClick={onEmoteClick}
            />
            {commentsEnabled !== false && (
              <Label
                flat
                value={formattedCommentCount}
                icon={{ size: 15, name: 'comment', color: 'grey' }}
              />
            )}
          </div>
          <Divider />
        </>
      )}
      {commentsEnabled && (
        <>
          <Comments comments={comments} onDelete={onDeleteComment} />
          {!disabled && (
            <CommentInput onInputSubmit={onAddComment} showEmojiPicker={showEmojiPicker} />
          )}
        </>
      )}
    </div>
  );
};
