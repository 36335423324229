import { useQuery, useQueryClient } from 'react-query';

import socialWallService from '../../core/services/social-wall.service';
import { PublicationInteraction } from '../types/SocialWallV2.types';

export type UseSocialWallEmotes = {
  userInteractions: Record<string, string[]>;
  addInteraction: (publicationId: string, action: string) => Promise<void>;
  removeInteraction: (publicationId: string, action: string) => Promise<void>;
  toggleInteraction: (publicationId: string, action: string) => Promise<void>;
};

const REFETCH_INTERVAL = 20000;
export const useSocialWallEmotes = (user: {
  userId: string;
  userCollection: string;
}): UseSocialWallEmotes => {
  const queryClient = useQueryClient();

  const { data = {} } = useQuery(
    ['social-wall', 'user-interactions'],
    async () => {
      const interactions = await socialWallService.getUserInteractions();
      return formatInteractions(interactions);
    },
    {
      refetchInterval: REFETCH_INTERVAL,
    },
  );

  const formatInteractions = (interactions: PublicationInteraction[]) => {
    const interactionsByPost = {} as Record<string, string[]>;
    interactions.forEach(({ messageId, action }) => {
      const currentPostInteractions = interactionsByPost[messageId];
      if (currentPostInteractions) {
        currentPostInteractions.push(action);
      } else {
        interactionsByPost[messageId] = [action];
      }
    });
    return interactionsByPost;
  };

  const toggleInteraction = async (publicationId: string, action: string) => {
    if (data[publicationId]?.includes(action)) {
      await removeInteraction(publicationId, action);
    } else {
      await addInteraction(publicationId, action);
    }
  };

  const addInteraction = async (publicationId: string, action: string) => {
    const payload = { ...user, action };
    const didAddInteractions = await socialWallService.addInteraction(publicationId, payload);
    if (!didAddInteractions.success) {
      console.error(didAddInteractions.message);
    }
    queryClient.invalidateQueries({ queryKey: ['social-wall'] });
  };

  const removeInteraction = async (publicationId: string, action: string) => {
    const payload = { ...user, action };

    const didRemoveInteractions = await socialWallService.removeInteraction(publicationId, payload);
    if (!didRemoveInteractions.success) {
      console.error(didRemoveInteractions.message);
    }
    queryClient.invalidateQueries({ queryKey: ['social-wall'] });
  };

  return {
    userInteractions: data,
    addInteraction,
    removeInteraction,
    toggleInteraction,
  };
};
