/* eslint-disable react/prop-types */
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Card, Container, Icon, Label } from 'semantic-ui-react';

import './ProfilePage.scss';

import PDFModal from '../../components/cms/FilesBlock/components/PDFModal';

import { useScreenConfig } from '../../config/screens.context';
import { userProptypes } from '../../propTypes';
import { replaceValues } from '../../utils/stringUtils';
import { CmsBlocks } from '../../components/cms';
import { CmsPageLayout } from '../../cms/CmsPage';
import { bem } from '../../core/design/bem';
import CdnImage from '../../components/CdnImage';

const pageBlock = bem('page');

const Title = ({ title, icon }) => {
  return (
    <h2 className="card--title">
      <Icon name={icon} style={{ textAlign: 'left' }} />
      {title}
    </h2>
  );
};

const Subtitle = ({ title, icon }) => {
  return (
    <div className="card--subtitle">
      <p className="title">
        {icon && <Icon name={icon} />}
        {title}
      </p>
      <div className="divider" />
    </div>
  );
};

const Item = ({ title, value }) => {
  if (!value) return null;
  return (
    <p className="card--item__name">
      {title} : <span className="card--item__value">{value}</span>
    </p>
  );
};

const Information = ({ icon, value }) => {
  if (!value) return null;
  return (
    <p>
      {icon && <Icon name={icon} />} {value}
    </p>
  );
};

const Labels = ({ value = [], icon, title, color }) => {
  return (
    <div className="card--labels">
      <div className="title">
        {icon && (
          <>
            <Icon name={icon} color={color} />
            <Icon name={icon} color={color} />
            <Icon name={icon} color={color} />
          </>
        )}
        <p>{title}</p>
        {icon && (
          <>
            <Icon name={icon} color={color} />
            <Icon name={icon} color={color} />
            <Icon name={icon} color={color} />
          </>
        )}
      </div>
      <div style={{ display: 'flex', marginTop: 4 }} className="labels">
        {value.map((v) => (
          <Label key={v} style={{ marginRight: 4, marginBottom: 4 }}>
            {v}
          </Label>
        ))}
      </div>
    </div>
  );
};

const QrCode = ({ uri, data }) => (
  <CdnImage className="card--qrcode" src={replaceValues(uri, data)} />
);

const components = {
  information: Information,
  labels: Labels,
  title: Title,
  subtitle: Subtitle,
  item: Item,
  qrcode: QrCode,
};

function getFieldValue(field, data) {
  const { value, dataType = 'string' } = field;
  const fieldValue = get(data, field.value);
  switch (dataType) {
    case 'array':
      return fieldValue || [];
    case 'date': {
      const { format = 'L' } = field;
      return moment(fieldValue).format(format);
    }
    default:
      return replaceValues(value, data);
  }
}

const BorderedCard = ({ fields = [], data }) => (
  <Card className="card--shadow" style={{ width: '100%' }}>
    <div className="border" />
    {fields.map((field) => {
      const { type, title } = field;
      const value = getFieldValue(field, data);
      const Component = components[type];
      return <Component {...field} title={replaceValues(title, data)} value={value} data={data} />;
    })}
  </Card>
);

export function jsonParseIfNeeded(json) {
  if (!json) return {};
  if (typeof json === 'string') return JSON.parse(json);
  return json;
}

function ProfilePage(props) {
  const { pageId, user } = props;
  const { t } = useTranslation();
  const { header, blocks = [], cvField = 'cv', footer } = useScreenConfig(pageId);
  const cv = get(user, cvField);
  const [pdfToView, setPdfToView] = useState(null);
  const openCV = () => {
    const value = jsonParseIfNeeded(cv);
    const cvValue = { ...value, uri: value?.uri?.replace('/images/', '/fonts/') };
    setPdfToView(cvValue);
  };
  return (
    <CmsPageLayout
      className={pageBlock({
        profile: true,
      }).toString()}
    >
      <Container>
        {header && <CmsBlocks blocks={header.blocks} />}
        {cv && (
          <Button primary floated="right" onClick={openCV} style={{ marginBottom: 10 }}>
            <Icon name="file pdf outline" /> {t('profile.view-cv')}
          </Button>
        )}
        {pdfToView && <PDFModal file={pdfToView} onClose={() => setPdfToView(null)} />}
        {blocks.map((b) => (
          <BorderedCard {...b} data={user} />
        ))}
        {footer && <CmsBlocks blocks={footer.blocks} />}
      </Container>
    </CmsPageLayout>
  );
}

ProfilePage.defaultProps = {
  pageId: 'profile',
};

ProfilePage.propTypes = {
  pageId: PropTypes.string,
  user: PropTypes.shape(userProptypes).isRequired,
};

export default connect((state) => ({
  ...state.user,
}))(ProfilePage);
