import { useMount } from 'ahooks';
import cx from 'classnames';
import get from 'lodash/get';
import matches from 'lodash/matches';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Container, Grid, Header, Item, Label } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { CmsPageLayout } from '../../cms/CmsPage';
import GridContainer from '../../components/GridContainer';
import OptLink from '../../components/OptLink';
import Tabs from '../../components/Tabs';
import { bem } from '../../core/design/bem';
import { eventTags } from '../../core/trackers/events';
import { findUser } from '../../core/users/users.select';
import { fetchUserCollections } from '../../core/users/users.utils';
import { replaceValues } from '../../utils/stringUtils';
import FilesBlock from '../blocks/FilesBlock';
import HtmlBlock from '../blocks/HtmlBlock';
import OpportunitiesBlock from '../blocks/OpportunitiesBlock';
import SlotsBlock from '../blocks/SlotsBlock';
import Video from '../blocks/VideoBlock';
import { SponsorBannerLogo } from './ExhibitorPage';
import './ExhibitorPage.scss';
import './TabbedExhibitorPage.scss';

const pageBlock = bem('page');

const Tags = ({ tags }) => {
  if (!tags.length) return null;
  return (
    <div className="tags">
      {tags.map((tag) => (
        <Label key={tag} style={{ borderRadius: 20 }}>
          {tag}
        </Label>
      ))}
    </div>
  );
};

Tags.defaultProps = {
  tags: [],
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

const blockComponents = {
  html: HtmlBlock,
  files: FilesBlock,
  opportunities: OpportunitiesBlock,
  appointments: SlotsBlock,
  video: Video,
};
function TabbedExhibitorPage(props) {
  const { match, sidebar, tabs, header, ...page } = props;
  const { t } = useTranslation();
  const userMatcher = matches(match.params);
  const user = useSelector((state) => findUser(state, userMatcher));
  const { trackEvent } = useTracking();
  useMount(() => {
    const { entities = [] } = page;
    if (!user && entities) {
      fetchUserCollections(entities.map((e) => e.collection));
    }
  });
  if (!user) return null;
  const {
    banner: defaultBanner,
    logo: logoField = 'logo',
    name: nameTemplate = '{name}',
    tags: tagsField,
  } = header;
  const name = replaceValues(nameTemplate, user);
  const logo = get(user, logoField);
  const tags = get(user, tagsField);
  const website = get(user, 'website');
  const { banner } = user;
  const exhibitorBanner = banner || defaultBanner;
  return (
    <CmsPageLayout
      className={pageBlock({
        exhibitor: true,
        template: 'tabbed',
        [user.collection]: true,
        [user._id]: true,
      }).toString()}
    >
      <Container>
        <GridContainer sidebar={sidebar}>
          <Grid.Column>
            <div className={cx('details__container', { hasBanner: !!exhibitorBanner })}>
              <SponsorBannerLogo banner={banner || defaultBanner} logo={logo} />
              <Item className="header">
                {website && (
                  <Button
                    primary
                    className="website"
                    floated="right"
                    as={OptLink}
                    to={website}
                    onClick={() => trackEvent(eventTags.WEBSITE_CLICK, { item: user })}
                  >
                    {t('sponsors.sponsor.see-website')}
                  </Button>
                )}
                <Header as="h2">{name}</Header>
                <Tags tags={tags} />
              </Item>
            </div>
            <Tabs tabs={tabs} item={user} blockComponents={blockComponents} />
          </Grid.Column>
        </GridContainer>
      </Container>
    </CmsPageLayout>
  );
}

TabbedExhibitorPage.defaultProps = {
  header: {},
  sidebar: {},
  tabs: [],
};

TabbedExhibitorPage.propTypes = {
  header: PropTypes.object,
  match: PropTypes.object.isRequired,
  sidebar: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.object),
};

export default TabbedExhibitorPage;
