import PropTypes from 'prop-types';
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputOnChangeData } from 'semantic-ui-react';

import './PasswordInput.scss';

type ChangePasswordVisibilityProps = {
  passwordVisible: boolean;
  onChangeVisibility: any;
};

const ChangePasswordVisibility: FC<ChangePasswordVisibilityProps> = (
  props: ChangePasswordVisibilityProps,
) => {
  const { passwordVisible, onChangeVisibility } = props;

  return (
    <Button
      as="div"
      icon={passwordVisible ? 'eye slash' : 'eye'}
      type="button"
      tabIndex={-1}
      className="PasswordInput__change-visibility transparent"
      onClick={onChangeVisibility}
    />
  );
};

type PasswordInputType = {
  password?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
};

const PasswordInput: FC<PasswordInputType> = (props: PasswordInputType) => {
  const { password, onChange } = props;
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisibity] = useState<boolean>(false);
  const passwordInputElement = useRef<HTMLInputElement>();

  const changeVisibility = () => {
    setPasswordVisibity((visibility: boolean) => !visibility);
    passwordInputElement.current?.focus();
  };

  return (
    <Form.Input
      className="PasswordInput"
      fluid
      input={{ ref: passwordInputElement }}
      icon="lock"
      iconPosition="left"
      placeholder={t('login.password')}
      type={passwordVisible ? 'text' : 'password'}
      name="password"
      value={password || ''}
      onChange={onChange}
      action={
        <ChangePasswordVisibility
          passwordVisible={passwordVisible}
          onChangeVisibility={changeVisibility}
        />
      }
    />
  );
};

PasswordInput.defaultProps = {
  password: undefined,
  onChange: undefined,
};

PasswordInput.propTypes = {
  password: PropTypes.string,
  onChange: PropTypes.func,
};

export { PasswordInput };
