import React from 'react';

import { useScreensConfig } from '../../../config/screens.context';
import { bem } from '../../../core/design/bem';
import { notEmpty } from '../../../utils/arrayUtils';
import BlockContainer from '../../BlockContainer';
import { ContainerProps } from '../types';
import { IconGridItem, IconGridVariants } from './types';
import { variants } from './variants';
import { Item } from './variants/variant.types';

const css = bem('IconGrid');

export type IconGridBlockProps = {
  _id: string;
  className?: string;
  container: ContainerProps;
  variant: IconGridVariants;
  blocks: IconGridItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemConfig?: Record<string, any>;
};

const IconGridBlock = (props: IconGridBlockProps): JSX.Element | null => {
  const { container, className, _id, variant, blocks, itemConfig } = props;
  const screens = useScreensConfig();
  const blockClassname = css({ id: _id, variant })
    .mix(className || container?.className)
    .toString();

  const items: Item[] = blocks
    ?.map((block) => {
      switch (block.type) {
        case 'page': {
          const { pageId } = block;
          const page = screens[pageId];
          if (!page) return null;
          return {
            ...block,
            url: page.path,
          };
        }
        default: {
          return block;
        }
      }
    })
    .filter(notEmpty);

  if (!items?.length) return null;

  const ItemsVariant = variants[variant] || variants.gridLayout;

  return (
    <BlockContainer {...container} className={blockClassname}>
      <div className="wrapper">
        <ItemsVariant items={items} variant={variant} itemConfig={itemConfig} />
      </div>
    </BlockContainer>
  );
};

IconGridBlock.defaultProps = {
  className: '',
  variant: 'gridLayout',
};

export default IconGridBlock;
