import store from '../../shared/Store';
import { postDownload } from '../../utils/postUtils';

const { endpoints } = window.__DATA__;

class OrdersService {
  // eslint-disable-next-line class-methods-use-this
  async downloadInvoice(orderId: string) {
    return postDownload(`${endpoints.order}/${store.eventId}/orders/${orderId}/invoice`, {
      token: store.token,
    });
  }
}

export default new OrdersService();
