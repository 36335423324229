import React from 'react';
import { Button } from 'semantic-ui-react';
import { StepContainer } from '../../Step';
import { localeFormat } from '../../../../utils/date';
import { getString } from '../../../../utils';

const translationPrefix = 'appointments.steps.confirm';
const t = (key: string, data?: any) => getString(`${translationPrefix}.${key}`, data);

type StepConfirmProps = {
  active: boolean;
  finish: boolean;
  orderNumber: string;
  selectedSlot: any;
};

const StepConfirm = (props: StepConfirmProps): JSX.Element | null => {
  const { active, finish, orderNumber, selectedSlot } = props;
  if (finish) return null;
  if (!active) {
    return (
      <StepContainer label={orderNumber} top>
        {t('sidebar-label')}
      </StepContainer>
    );
  }
  return (
    <StepContainer label={orderNumber} top active>
      {t('sidebar-label')}
      <p style={{ marginTop: '0.5em' }}>
        <Button primary size="tiny" compact>
          {localeFormat(selectedSlot.startDate, 'PPPp')}
        </Button>
      </p>
    </StepContainer>
  );
};

StepConfirm.defaultProps = {};

export default StepConfirm;
