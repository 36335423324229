import React, { FC } from 'react';

import { CmsBlocks } from '../../../components/cms';
import { bem } from '../../../core/design/bem';
import { UserType } from '../../../core/users/users.types';
import './CmsTab.scss';

const css = bem('CmsTab');

type CmsTabProps = {
  user: UserType;
  config?: Record<string, any>;
  blocks?: Record<string, any>;
};

export const CmsTab: FC<CmsTabProps> = ({ blocks }) => (
  <div className={css()}>
    <CmsBlocks blocks={blocks} />
  </div>
);
