import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import { useSetState } from 'react-use';

import { BottomPanel } from '../../../components/BottomPanel/BottomPanel';
import { Icons } from '../../../components/Icons/material-icon.types';
import { KeyboardToolbar } from '../../../components/KeyboardToolbar/KeyboardToolbar';
import { removeFalsy } from '../../../utils/arrayUtils';
import { useSocialWallConfig } from '../../social-wall-config.context';
import { Publication } from '../../types/SocialWallV2.types';
import { CommentInput } from '../Comments/CommentInput';
import { Comments } from '../Comments/Comments';
import { PublicationMedia } from './DetailedPublication/PublicationMedia';
import { PublicationTextAndComment } from './DetailedPublication/PublicationTextAndComment';
import { DetailedPublicationHeader } from './DetailedPublicationHeader';

interface MobileDetailsPublicationProps {
  publication: Publication;
  comments: Publication[];
  userId: string;
  onClose: () => any;
  onDelete: () => void;
  onEdit: () => void;
  onReport: () => void;
  onAddComment: (message: string) => Promise<Publication>;
  onDeleteComment: (commentId: string) => Promise<void>;
  onInteractionClick?: (emote: string) => Promise<void>;
  userInteractions: string[];
  onNext: () => void;
  onPrev: () => void;
}

export const MobileDetailsPublication: FC<MobileDetailsPublicationProps> = ({
  publication,
  comments,
  userId,
  onClose,
  userInteractions,
  onDelete,
  onEdit,
  onReport,
  onAddComment,
  onDeleteComment,
  onInteractionClick,
  onNext,
  onPrev,
}) => {
  const [panelState, setPanelState] = useSetState({ panelPosition: 0, isDraggable: true });
  const [forcePosition, setForcePosition] = useState<number | undefined>();

  const { author, data, createdAt } = publication;
  const { media } = data;
  const { t } = useTranslation();
  const { commentsEnabled, reportEnabled } = useSocialWallConfig();

  const panelElementRef = useRef<HTMLDivElement | null>(null);

  const changePositionCallback = (position: number) => {
    setPanelState({ panelPosition: position });
    if (forcePosition) setForcePosition(undefined);
  };

  const PANEL_STEPS = [20, 60];
  const mediaMinHeight = 100 - PANEL_STEPS[PANEL_STEPS.length - 1];

  const onAddPublicationComment = async (message: string) => {
    setForcePosition(60);
    return onAddComment(message);
  };

  const actions = removeFalsy([
    userId === publication.author.userId && {
      label: t('btn.delete'),
      onClick: onDelete,
      icon: 'delete' as Icons,
    },
    userId === publication.author.userId && {
      label: t('btn.edit'),
      onClick: onEdit,
      icon: 'edit_square' as Icons,
    },
    userId !== publication.author.userId &&
      reportEnabled && {
        label: t('btn.report'),
        onClick: onReport,
        icon: 'report' as Icons,
      },
  ]);

  const swipeHandler = useSwipeable({
    onSwipedRight: onPrev,
    onSwipedLeft: onNext,
    delta: 100,
    preventScrollOnSwipe: false,
    swipeDuration: 250,
  });

  return (
    <div {...swipeHandler} style={{ height: '100%' }}>
      <DetailedPublicationHeader
        user={author}
        date={createdAt}
        onClose={onClose}
        dark={!media}
        withGradient={!!media}
        actions={actions}
        onNext={onNext}
        onPrev={onPrev}
      />
      {media && (
        <>
          <PublicationMedia
            key={publication._id}
            publication={publication}
            panelPosition={panelState.panelPosition}
            minHeight={mediaMinHeight}
            userInteractions={userInteractions}
            onEmoteClick={onInteractionClick}
          />
          {commentsEnabled && (
            <>
              <BottomPanel
                isRounded
                steps={PANEL_STEPS}
                initialStep={PANEL_STEPS[1]}
                forwardedRef={panelElementRef}
                draggable
                disabled={!panelState.isDraggable}
                positionCallback={changePositionCallback}
                forcePosition={forcePosition}
              >
                <Comments comments={comments} onDelete={onDeleteComment} />
              </BottomPanel>
              <KeyboardToolbar>
                <CommentInput
                  showEmojiPicker={false}
                  onInputSubmit={onAddPublicationComment}
                  onInputFocus={() => setPanelState({ isDraggable: false })}
                  onInputFocusOut={() => setPanelState({ isDraggable: true })}
                />
              </KeyboardToolbar>
            </>
          )}
        </>
      )}
      {!media && (
        <PublicationTextAndComment
          publication={publication}
          comments={comments}
          userInteractions={userInteractions}
          onAddComment={onAddComment}
          onDeleteComment={onDeleteComment}
          showEmojiPicker={false}
          style={{
            paddingTop: '60px',
          }}
          onEmoteClick={onInteractionClick}
        />
      )}
    </div>
  );
};
