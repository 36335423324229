/* eslint-disable @typescript-eslint/no-explicit-any */
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUserUnreadCount } from '../../../../networking/store/networking.helpers';
import { activeChats } from '../../../../networking/store/networking.selectors';
import { ensureName } from '../../../../networking/utils/chat-profile.utils';
import { useMe } from '../../../../profile/hooks';

export function useChats(): Record<string, any> {
  const me = useMe();
  const chats: Record<string, any> = useSelector(activeChats);
  return useMemo(() => {
    const conversations = values(chats);
    const finalConversations = orderBy(
      conversations.map((chat) => {
        const profile = (me._id === chat.inviter ? chat.invitedProfile : chat.inviterProfile) || {};
        const fullname = `${profile.firstName} ${profile.lastName}`;
        const unreadCount = getUserUnreadCount(chat);
        return {
          ...chat,
          profile,
          fullname,
          unreadCount,
          updatedAt: chat.updatedAt || chat.createdAt,
          hasMessages: !!chat.updatedAt || !!chat.message,
        };
      }),
      ['updatedAt'],
      ['desc'],
    );
    const unreadConversations = finalConversations.filter((c) => c.unreadCount > 0);
    return { chats, conversations: finalConversations, unreadConversations };
  }, [me._id, chats]);
}

export function useChatMessages(chat?: Record<string, any>): any[] {
  return useMemo(() => {
    if (!chat) return [];
    const inviterProfile = ensureName(chat.inviterProfile);
    const invitedProfile = ensureName(chat.invitedProfile);
    return (values(chat.messages) || []).map((message) => {
      const { emitter } = message;
      const profile = emitter === chat.invited ? invitedProfile : inviterProfile;
      return { ...message, profile };
    });
  }, [chat]);
}
