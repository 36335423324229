/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

type LightBackButtonProps = {
  showGoBack: boolean;
};

const LightBackButton = (props: LightBackButtonProps): JSX.Element | null => {
  const { showGoBack } = props;
  const { t } = useTranslation();
  const history = useHistory();
  if (!showGoBack) return null;
  return (
    <div className="go-back" onClick={() => history.goBack()}>
      <Icon name="chevron left" />
      {t('sponsors.sponsor.back')}
    </div>
  );
};

export default LightBackButton;
