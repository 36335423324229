import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useMemo } from 'react';

const minutesBetweenMessageGroup = 2;

function isMergableType(type: string): boolean {
  return type !== 'appointment' && type !== 'appointment-update' && type !== 'visio-request';
}

export type ChatMessage = {
  id: string;
  createdAt: Date;
  type: 'visio-request' | 'appoitnment' | 'appointment-update' | string;
  emitter: string;
  text: string;
};

export type GroupedChatMessages = Omit<ChatMessage, 'text'> & {
  text: {
    id: string;
    text: string;
  }[];
};

const orderByRecentLast = (a: ChatMessage, b: ChatMessage) =>
  a.createdAt.getTime() - b.createdAt.getTime();

export function useFormatMessages(originalMessages: ChatMessage[]): GroupedChatMessages[] {
  return useMemo(() => {
    return (originalMessages ?? [])
      .map((message) => ({
        ...message,
        createdAt: message.createdAt && new Date(message.createdAt),
      }))
      .sort(orderByRecentLast)
      .reduce((acc, message) => {
        const lastMessage = acc[acc.length - 1];

        const hasMergableTypes = isMergableType(message.type) && isMergableType(lastMessage?.type);

        if (
          hasMergableTypes &&
          lastMessage?.emitter === message.emitter &&
          differenceInMinutes(message.createdAt, lastMessage?.createdAt) <
            minutesBetweenMessageGroup
        ) {
          lastMessage.text = lastMessage?.text.concat({
            id: message.id,
            text: message.text,
          });
          // Date should be last date...
          lastMessage.createdAt = message.createdAt;
          return acc;
        }

        acc.push({
          ...message,
          text: [
            {
              id: message.id,
              text: message.text,
            },
          ],
        });
        return acc;
      }, [] as GroupedChatMessages[]);
  }, [originalMessages]);
}
