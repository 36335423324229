/* eslint-disable react/require-default-props */
// Forked from https://github.com/rexxars/react-hexagon
import memoizeOne from 'memoize-one';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useEvent from '../../core/hooks/useEvent.hook';
import { isExternalLink } from '../ExpiringNavLink';
import BackgroundDef from './components/BackgroundDef';
import { computeViewbox, getFlatTopPoints, getPoints } from './utils';

let bgIndex = 0;

// Note : pattern ids are GLOBAL to the DOM and not scoped to the current SVG
// Each svg needs to have a different id...
function getBackgroundId() {
  return `hexagon-bg-${++bgIndex}`;
}

function useBgId() {
  const [bgId] = useState(getBackgroundId);
  return bgId;
}

const memoizedViewbox = memoizeOne(computeViewbox);

type HexagonProps = {
  backgroundImage?: string;
  diagonal?: number;
  fill?: string;
  flatTop?: boolean;
  stroke?: string;
  strokeWidth?: number;
  onClick?: () => void;
  style?: Record<string, any>;
  className?: string;
  children?: any;
  hexProps?: any;
  target?: string;
  to?: string; // External link or local url
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Hexagon(props: HexagonProps): JSX.Element {
  const {
    backgroundImage,
    diagonal = 200,
    flatTop = false,
    fill,
    stroke,
    strokeWidth = 0,
    onClick,
    style = {},
    to,
    target = '_blank',
    className,
    children,
    hexProps,
  } = props;
  const history = useHistory();
  const bgId = useBgId();

  const polyStyle = {
    fill: backgroundImage ? `url(#${bgId})` : fill,
    stroke,
    strokeWidth, // props.diagonal * 0.02,
    cursor: onClick || to ? 'pointer' : undefined,
    pointerEvents: 'all',
    ...style,
  };

  const offset: number = polyStyle.strokeWidth || 0;
  const points = flatTop ? getFlatTopPoints(diagonal) : getPoints(diagonal, offset);

  const handleOnClick = useEvent(() => {
    if (onClick) {
      onClick();
    } else if (to && !isExternalLink(to)) {
      history.push(to);
    }
  });

  const polygon = (
    <polygon
      {...hexProps}
      onClick={onClick || to ? handleOnClick : undefined}
      style={polyStyle}
      points={points.map((point) => point.join(',')).join(' ')}
    />
  );
  let anchor;

  // let polygonClick = onClick;
  if (isExternalLink(to)) {
    anchor = (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <a xlinkHref={to} target={target} style={{ pointerEvents: 'all' }}>
        {polygon}
      </a>
    );
  }

  const viewBox = memoizedViewbox(diagonal, flatTop, strokeWidth);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className={className} viewBox={viewBox}>
      {backgroundImage && <BackgroundDef id={bgId} {...props} diagonal={diagonal} />}
      {anchor || polygon}
      {children}
    </svg>
  );
}

export default Hexagon;
