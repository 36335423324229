import keyBy from 'lodash/keyBy';

import { LOADING, SET_ACHIEVEMENTS, SET_USER_ACHIEVEMENTS } from './achievements.types';
// eslint-disable-next-line import/no-cycle
import { computeAchievements } from './achievements.utils';

const initialState = {
  rawAchievements: [],
  achievements: [],
  categories: [],
  userAchievements: [],
  userAchievementsByAchievement: {},
};

export default function reduceAchievements(state = initialState, action) {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_ACHIEVEMENTS: {
      const { achievements, categories } = action;
      return {
        ...state,
        loading: false,
        categories,
        rawAchievements: achievements,
        achievements: computeAchievements(achievements, state.userAchievements),
      };
    }
    case SET_USER_ACHIEVEMENTS: {
      const { userAchievements } = action;
      return {
        ...state,
        loading: false,
        userAchievements,
        userAchievementsByAchievement: keyBy(userAchievements, 'achievementId'),
        achievements: computeAchievements(state.rawAchievements, userAchievements),
      };
    }
    default:
      return state;
  }
}
