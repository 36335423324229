/* eslint-disable operator-linebreak */
/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { useCurrentCloudTvSession } from '../cloudtv.hooks';

import BlockContainer from '../../../../components/BlockContainer';
import { bem } from '../../../../core/design/bem';
import WorkshopCommentsBlock from '../../../../workshops/blocks/WorkshopCommentsBlock';
import { debugNowTime } from '../../../../hooks/useAutoRefresh';

const css = bem('CloudTvCommentsBlock');

function CloudTvCommentsBlock(props) {
  const {
    _id,
    mode,
    workshopFilter,
    container,
    className,
    forceLiveStream,
    refreshRate,
    ...commentConfig
  } = props;
  const { upcomingLive, hasCurrentLive } = useCurrentCloudTvSession(mode, {
    forceLiveStream,
    refreshRate,
    workshopFilter,
  });
  const now = debugNowTime || Date.now();

  const startTime = upcomingLive?.startDate
    ? moment(upcomingLive?.startDate, moment.ISO_8601).toDate().getTime()
    : 0;
  const isNow = now > startTime - 2 * 60 * 1000;
  if (!upcomingLive?._id || !hasCurrentLive || !isNow) return null;
  return (
    <BlockContainer {...container} className={css({ id: _id }).mix(className)}>
      <WorkshopCommentsBlock {...commentConfig} item={upcomingLive} />
    </BlockContainer>
  );
}

CloudTvCommentsBlock.defaultProps = {
  className: '',
  mode: 'agenda',
  container: {},
  forceLiveStream: false,
  refreshRate: undefined,
  workshopFilter: {},
};

CloudTvCommentsBlock.propTypes = {
  _id: PropTypes.string.isRequired,
  container: PropTypes.object,
  className: PropTypes.string,
  forceLiveStream: PropTypes.bool,
  refreshRate: PropTypes.number,
  mode: PropTypes.string,
  workshopFilter: PropTypes.object,
};

export default CloudTvCommentsBlock;
