import cx from 'classnames';
import PropTypes from 'prop-types';
import { Card, Header } from 'semantic-ui-react';

import { workshopProptypes } from '../../../../propTypes';
import { getClassName } from '../../../../workshops/utils';
import SpeakerAvatars from '../../../speakers/SpeakerAvatars';
import { WorkshopActions } from '../actions/WorkshopActions';
import { computeActions } from '../actions/actions.utils';
import './WorkshopLightItem.scss';

const WorkshopLightItem = ({ workshop, config }) => {
  const { showSpeakers = true } = config;
  const { shortDescription, speakers, title } = workshop;
  return (
    <Card className={cx('Workshop', getClassName(workshop))}>
      <Card.Content className="Workshop__Container">
        <div className="Workshop__Container--Main">
          {title && (
            <Header className="title" as="h3">
              {title}
            </Header>
          )}
          {shortDescription && (
            <Card.Description className="description">{shortDescription}</Card.Description>
          )}
          {showSpeakers && (
            <div className="speakers" style={{ marginTop: 10 }}>
              <SpeakerAvatars size="mini" speakerIds={speakers} />
            </div>
          )}
        </div>
        <div className="Workshop__Container--Sidebar">
          <WorkshopActions workshop={workshop} actions={computeActions(config)} />
        </div>
      </Card.Content>
    </Card>
  );
};

WorkshopLightItem.defaultProps = {
  config: {},
};

WorkshopLightItem.propTypes = {
  config: PropTypes.object,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopLightItem;
