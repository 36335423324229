import React, { FC } from 'react';
import { AddWorkshopToCalendar } from '../../../../workshops/blocks/WorkshopAddToCalendarBlock';
import { bem } from '../../../../core/design/bem';

const css = bem('WorkshopAddToCalendarButton');
type AddToCalendarButtonProps = {
  item: any;
  options: Record<string, any>;
};

const AddToCalendarButton: FC<AddToCalendarButtonProps> = (props) => {
  const { item, options = {} } = props;
  return (
    <AddWorkshopToCalendar
      workshop={item}
      className={css()}
      labelPosition={false}
      labeled={false}
      floated={false}
      {...options}
    />
  );
};

export { AddToCalendarButton };
