import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { containerTypePropTypes, workshopProptypes } from '../../propTypes';
import BlockContainer from '../../components/BlockContainer';
import IframeBlock from '../../components/cms/IframeBlock';
import { useFeedbackUrl } from '../store/workshops.hooks';

function WorkshopFeebdackBlock(props) {
  const { item: workshop, field, containerType } = props;
  const [feedback] = get(workshop, field) || [];
  const { feedbackUrl, title } = useFeedbackUrl(workshop, feedback);
  if (!feedbackUrl) {
    return null;
  }
  return (
    <BlockContainer className="workshop--visioRoomBlock" header={title} type={containerType}>
      <IframeBlock {...props} src={feedbackUrl} />
    </BlockContainer>
  );
}

WorkshopFeebdackBlock.defaultProps = {
  containerType: 'segment',
  field: 'feedbacks',
};

WorkshopFeebdackBlock.propTypes = {
  containerType: containerTypePropTypes,
  field: PropTypes.string,
  item: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopFeebdackBlock;
