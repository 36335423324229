import { useBoolean } from 'ahooks';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';

import { CmsBlocks } from '../../cms/CmsScreen';
import store from '../../shared/Store';
import { getString } from '../../utils';

const localStorageKey = `${store.storage.getLocalStorageKey()}-allow-cookie`;

const CookieModal = ({ blocks, moreBlocks, className }) => {
  const { t } = useTranslation();
  const showCookiePopup = !localStorage.getItem(localStorageKey);

  const [open, { setTrue: setOpen, setFalse: setClose }] = useBoolean(showCookiePopup);
  const [showMore, setShowMore] = useState(false);
  const hasMoreBlocks = moreBlocks?.length > 0;

  const handleClick = () => {
    localStorage.setItem(localStorageKey, true);
    setClose();
  };

  return (
    <Modal
      className={cx('footer--cookie-modal', className)}
      onClose={setClose}
      onOpen={setOpen}
      open={open}
      dimmer={{ className: 'dimmer--cookie-modal' }}
      size="tiny"
    >
      <Modal.Content scrolling style={{ paddingTop: 5 }}>
        <CmsBlocks blocks={blocks} />
        {hasMoreBlocks && (
          <div className="more-blocks">
            {showMore && (
              <div className="blocks">
                <CmsBlocks blocks={moreBlocks} />
              </div>
            )}
            <Button primary size="tiny" basic onClick={() => setShowMore(!showMore)}>
              {t(showMore ? 'filters.show-less' : 'filters.show-more')}
            </Button>
          </div>
        )}
        <Button primary floated="right" size="mini" onClick={handleClick}>
          {getString('btn.ok')}
        </Button>
      </Modal.Content>
    </Modal>
  );
};

CookieModal.defaultProps = {
  className: undefined,
};
CookieModal.propTypes = {
  className: PropTypes.string,
  blocks: PropTypes.array.isRequired,
};

export default CookieModal;
