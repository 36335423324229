import cx from 'classnames';
import { FC, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';

import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { Publication } from '../../types/SocialWallV2.types';
import { EmotePicker } from '../EmotePicker/EmotePicker';

interface CommentInputProps {
  showEmojiPicker?: boolean;
  onInputSubmit: (message: string) => Promise<Publication>;
  onInputFocus?: () => void;
  onInputFocusOut?: () => void;
}

export const CommentInput: FC<CommentInputProps> = ({
  showEmojiPicker = true,
  onInputSubmit,
  onInputFocus,
  onInputFocusOut,
}) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const disabled = !value.trim().length;

  const submit = async (message: string) => {
    const res = await onInputSubmit(message);
    if (!res) return;
    setValue('');
  };

  const handleKey = (evt: KeyboardEvent) => {
    if (evt.keyCode === 13 && evt.shiftKey && !disabled) {
      evt.preventDefault();
      evt.stopPropagation();
      submit(value);
    }
  };

  return (
    <div className="comment-input-wrapper">
      {!!value.length && (
        <div className="close-header" onClick={() => setValue('')}>
          <button onClick={() => setValue('')} type="button" aria-label="close-button">
            <MaterialIcon name="close" color="grey" size={20} />
          </button>
          <span>{t('social-wall.cancel-comment')}</span>
        </div>
      )}
      <div className={cx('comment-input', showEmojiPicker && 'comment-input--bigger')}>
        <TextareaAutosize
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKey}
          placeholder={`${t('social-wall.add-comment')}...`}
          maxLength={450}
          className={cx('textarea')}
          onFocus={onInputFocus}
          onBlur={onInputFocusOut}
        />
        <div className="comment-input__buttons">
          {showEmojiPicker && (
            <EmotePicker onSelect={(emote) => setValue(`${value}${emote}`)} iconSize={25} />
          )}
          <button
            onClick={() => submit(value)}
            type="button"
            className="send-button"
            disabled={disabled}
          >
            <MaterialIcon name="send" color="black" size={30} />
          </button>
        </div>
      </div>
    </div>
  );
};
