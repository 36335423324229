import '@pqina/flip/dist/flip.min';
import '@pqina/flip/dist/flip.min.css';
import PropTypes from 'prop-types';
import React from 'react';

import { bem } from '../../core/design/bem';

const { Tick } = window;
const css = bem('flip-countdown');

export default class Flip extends React.Component {
  constructor(props) {
    super(props);
    this._tickRef = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;
    this._tickInstance = Tick.DOM.create(this._tickRef.current, {
      value,
    });
  }

  componentDidUpdate() {
    const { value } = this.props;
    if (!this._tickInstance) return;
    this._tickInstance.value = value;
  }

  componentWillUnmount() {
    if (!this._tickInstance) return;
    Tick.DOM.destroy(this._tickRef.current);
  }

  render() {
    const { label, digitStyle } = this.props;
    return (
      <div ref={this._tickRef} className={css()} aria-hidden="true">
        <div className={css('container')} data-repeat={digitStyle === 'single' ? true : undefined}>
          <span data-view="flip" />
        </div>
        <div className="label">{label}</div>
      </div>
    );
  }
}

Flip.defaultProps = {
  digitStyle: 'single',
  label: undefined,
};

Flip.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  digitStyle: PropTypes.oneOf(['single', 'combined']),
};
