/* eslint-disable react/no-danger */
/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import { getEntityString } from '../../../utils';
import { DataItem } from '../../types';
import DataModal from '../modal/DataModal';
import './DataItem.scss';

const css = bem('DataItem');

const DataItemDefaultProps = {
  config: {},
};

type DataItemProps = {
  item: DataItem;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: Record<string, any>;
} & typeof DataItemDefaultProps;

const DataItemCard = ({ item, config = {} }: DataItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { showModal = true } = config;
  const { collection, name, shortDescription, description } = item;

  return (
    <>
      <Card
        className={css('card').has({ showModal }).toString()}
        onClick={() => (!showModal ? noop : setIsOpen(true))}
      >
        <Card.Content>
          <Card.Header className="name">{name}</Card.Header>
          <Card.Description className="category">
            {getEntityString(item, collection, 'category')}
          </Card.Description>
          {!!shortDescription ? (
            <Card.Description className="shortDescription">{shortDescription}</Card.Description>
          ) : (
            <div className="fullDescription">
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          )}
          {showModal && (
            <Button size="tiny" basic primary floated="right" className="showMore">
              {t('blocks.ctaTiles.show-more')}
            </Button>
          )}
        </Card.Content>
      </Card>
      {isOpen && showModal && (
        <DataModal item={item} onClose={() => setIsOpen(false)} {...config} />
      )}
    </>
  );
};

DataItemCard.defaultProps = DataItemDefaultProps;

export default DataItemCard;
