/* eslint-disable no-restricted-syntax */
import orderBy from 'lodash/orderBy';

import { setIn } from '../../../../utils/object.utils';
import { Item } from '../variants/variant.types';

export type ItemWithGridPos = Item & {
  gridConfig: { x: number; y: number; w: number; h: number };
};

function computeHasLines(items: ItemWithGridPos[]): boolean[] {
  const hasLine: boolean[] = [];
  for (const item of items) {
    const { y, h } = item.gridConfig;
    for (let n = y; n < y + h; n++) {
      hasLine[n] = true;
    }
  }
  return hasLine;
}

function computeNewPosition(items: ItemWithGridPos[], hasLine: boolean[]): number[] {
  let currentHeight = 0;
  const newPosition: number[] = [];
  for (let n = 0; n < hasLine.length; n++) {
    newPosition[n] = currentHeight;
    if (hasLine[n]) currentHeight++;
  }
  return newPosition;
}

/** Move items up if whole rows are missing */
export function compactGridItems(items: ItemWithGridPos[]): ItemWithGridPos[] {
  if (!items.length) return items;

  const validItems = orderBy(
    items.filter((i) => i.gridConfig),
    ['gridConfig.y', 'gridConfig.x'],
  );

  const hasLine = computeHasLines(validItems);
  const newPositions = computeNewPosition(validItems, hasLine);

  // Move items up if needed
  return validItems.map((item) => {
    const y = newPositions[item.gridConfig.y];
    if (y === item.gridConfig.y) {
      // Didn't change
      return item;
    }
    return setIn(item, ['gridConfig', 'y'], y);
  });
}
