/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useScroll } from 'ahooks';
import React, { useMemo } from 'react';
import { Icon } from 'semantic-ui-react';

import { bem } from '../../core/design/bem';
import './BackToTopArrow.scss';

const css = bem('BackToTopArrow');

function scrollToTop() {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}

const BackToTopArrow = (): JSX.Element => {
  const { top } = useScroll();
  const visible = top > 32;

  return useMemo(
    () => (
      <div className={css.state({ visible }).mix('button--mobile-action')} onClick={scrollToTop}>
        <Icon name="arrow up" />
      </div>
    ),
    [visible],
  );
};

export default BackToTopArrow;
