/* eslint-disable class-methods-use-this */
import wretch from 'wretch';

import store from '../../shared/Store';
import platformService from './platform.service';

const { eventId } = window.__DATA__;

type CheckWorkshopFeedbackResult =
  | {
      status: 'none';
    }
  | { status: 'display'; uri: string }
  | { status: 'done' };

class FeedbackService {
  private api() {
    const feedbackApi = window.__DATA__.endpoints.feedback || 'https://api.appcraft.events/grade';
    // const feedbackApi = 'http://localhost:3303';
    return wretch().url(feedbackApi).auth(`JWT ${store.token}`);
  }

  async checkWorkshopFeedback(
    feedbackFormUri: string,
    workshopId: string,
  ): Promise<CheckWorkshopFeedbackResult> {
    if (!feedbackFormUri) return { status: 'none' };
    const api = `${feedbackFormUri.replace('/html', '/api')}/workshops/${workshopId}/users/${
      store.userId
    }`;
    const res = await platformService.secureGet(api);
    if (res.status === 'display') {
      return { ...res, uri: `${feedbackFormUri}/workshops/${workshopId}?token=${res.token}` };
    }
    return res;
  }

  async getMissingFeedbacks(userId: string) {
    const res = await this.api()
      .url(`/api/${eventId}/users/${userId}/missing-feedbacks`)
      .get()
      .json();

    return res;
  }
}

export default new FeedbackService();
