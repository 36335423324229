/* eslint-disable @typescript-eslint/no-explicit-any */
import PropTypes from 'prop-types';
import { FC } from 'react';
import Gallery from 'react-photo-gallery';

import CdnImage from '../../components/CdnImage';
import Images from '../../utils/Images';
import '../SocialWall.scss';
import HoverTile from '../components/HoverTile';
import { SocialWallMessage, SocialWallVariant } from '../types/SocialWallTypes';

function formatPhotos(items: SocialWallMessage[]) {
  return items.map((item) => {
    const { id: imageId, image } = item;
    return {
      ...item,
      key: imageId,
      src: image.uri,
      cdn: Images.cdn(image),
      srcSet: [200, 300, 500, 800, 1024, 1600].map(
        (size) => `${Images.maxWidth(image, size)} ${size}w`,
      ),
      width: image.width,
      height: image.height,
    };
  });
}

const Album: FC<SocialWallVariant> = ({ items, openLightbox }): JSX.Element | null => {
  const photos = formatPhotos(items.filter((i) => !!i.image));

  const RenderImage = ({ index, photo }: any) => {
    return (
      <div
        key={index}
        role="button"
        tabIndex={0}
        style={{ margin: 2, cursor: 'pointer' }}
        onClick={() => {
          openLightbox(photo.id);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            openLightbox(photo.id);
          }
        }}
      >
        <HoverTile item={photo} />
        <CdnImage maxWidth={1920} {...photo} />
      </div>
    );
  };

  return (
    <>
      <Gallery
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        photos={photos}
        ImageComponent={RenderImage}
      />
    </>
  );
};

Album.propTypes = {
  items: PropTypes.array.isRequired,
  openLightbox: PropTypes.func.isRequired,
};

export default Album;
