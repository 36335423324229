import { useSetState } from 'ahooks';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useMount } from 'react-use';
import { Button, Dimmer, Form, Loader } from 'semantic-ui-react';

import { useScreenConfig } from '../../config/screens.context';
import authService from '../../core/services/auth.service';
import store, { getParameterByName } from '../../shared/Store';
import { useLoginConfig } from '../hooks';
import './LoginEmailUrl.scss';
import Messages from './Messages';
import SsoLogin from './SsoLogin';

function useLoginErrorString() {
  const { t } = useTranslation();

  return (code: string) =>
    t(`login.errors.${code}`, { defaultValue: t(`login.errors.E_AUTH_ERROR`) });
}

type LoginEmailUrlState = {
  login: string;
  loading: boolean;
  success?: boolean;
  error: string | null;
  message: string | null;
};

const EmailLinkForm = () => {
  const { stayConnected = true } = useScreenConfig('login') ?? {};
  const { t } = useTranslation();
  const errorMsg = useLoginErrorString();
  const [token] = useState(() => getParameterByName('token'));
  const [state, setState] = useSetState<LoginEmailUrlState>({
    loading: !!token,
    login: getParameterByName('email') ?? '',
    success: false,
    error: null,
    message: null,
  });

  const setError = (error: string) => setState({ loading: false, error });

  useMount(async () => {
    if (token) {
      const res = await store.autoLogin(token, stayConnected);
      if (res && res.success) {
        setState({
          loading: false,
          success: true,
        });
      } else if (store.restoreUserIfPossible()) {
        // Redirect to home
        setState({
          loading: false,
          success: true,
        });
      } else {
        setError(t('login.auto-login.error-invalid-token'));
      }
    }
  });

  const { success, login, error, message, loading } = state;
  const isValid = login && login.includes('@');

  function handleChange(_e: any, { name, value, checked }: any) {
    setState({ [name]: value || checked, message: null, error: null });
  }

  async function handleLogin() {
    setState({ loading: true, error: null, message: null });
    try {
      const res = await authService.sendEmailLink(login);
      if (res.error) {
        setError(errorMsg(res.error));
      } else if (res.success) {
        setState({
          loading: false,
          message: t('login.email-link.success'),
        });
      } else {
        console.error(res);
        setError(t('login.error-connection-error'));
      }
    } catch (e: any) {
      console.error(e);
      setError(errorMsg(e.error));
    }
  }

  async function handleLoginClick(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    handleLogin();
  }

  if (success) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login-email-link">
      {loading && (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      )}
      <p>{t('login.email-link.enter-email')}</p>
      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder={t('login.login')}
        name="login"
        value={login || ''}
        onChange={handleChange}
      />
      <Button primary fluid size="large" disabled={!isValid} onClick={handleLoginClick}>
        {t('login.email-link.send-link')}
      </Button>
      <Messages message={message} error={error} />
    </div>
  );
};

const LoginEmailUrl: FC<{ onLogin: () => void; mode: string }> = ({ onLogin, mode }) => {
  const errorMsg = useLoginErrorString();
  const loginConfig = useLoginConfig();

  const [error] = useState(() => {
    const errorCode = getParameterByName('code');
    return errorCode ? errorMsg(errorCode) : null;
  });

  const { sso } = loginConfig;

  return (
    <>
      {sso && <SsoLogin sso={sso} showDivider={mode !== 'none'} />}
      {mode === 'email-link' && <EmailLinkForm />}
      <Messages error={error} />
    </>
  );
};

export default LoginEmailUrl;
