import isArray from 'lodash/isArray';
import throttle from 'lodash/throttle';
// eslint-disable-next-line import/no-cycle
import platformService from '../../core/services/platform.service';
import { SET_ALL, SET_BY_ID } from './workshops.types';

export const setWorkshops = (workshops) => ({
  type: SET_ALL,
  workshops,
});

export const setWorkshop = (workshop) => ({
  type: SET_BY_ID,
  workshop,
});

export function fetchWorkshops() {
  return async (dispatch) => {
    const workshops = await platformService.fetchWorkshops();
    if (isArray(workshops)) {
      dispatch(setWorkshops(workshops));
    }
  };
}

const throttledDispatch = throttle(
  async (dispatch) => {
    const workshops = await platformService.fetchWorkshops();
    if (isArray(workshops)) {
      dispatch(setWorkshops(workshops));
    }
  },
  2000,
  {
    leading: true,
    trailing: true,
  },
);

export function throttledFetchWorkshops() {
  return throttledDispatch;
}

export function fetchWorkshop(workshopId, options = {}, collection) {
  return async (dispatch) => {
    const workshop = await platformService.fetchWorkshop(workshopId, options, collection);
    if (workshop._id) {
      // Ensure valid workshop
      dispatch(setWorkshop(workshop));
    }
  };
}
