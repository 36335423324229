/* eslint-disable operator-linebreak */
import { useMount } from 'ahooks';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';

import { CmsPageLayout } from '../../cms/CmsPage';
import { CmsBlocks } from '../../cms/CmsScreen';
import LightBackButton from '../../components/BackButton/LightBackButton';
import ItemCardGroup from '../../components/ItemCardGroup';
import ScreenIntro from '../../components/screens/ScreenIntro';
import SponsorCard from '../../components/sponsors/SponsorCard';
import { notifyAchievement } from '../../core/achievements/achievements.utils';
import { bem } from '../../core/design/bem';
import MenuFilter from '../../core/filter/MenuFilter';
import { generateFilters } from '../../core/filter/utils';
import { cmsBlocksType, orderByType } from '../../core/platformPropTypes';
import { selectUsers } from '../../core/users/users.select';
import { fetchUserCollections } from '../../core/users/users.utils';
import useUrlState from '../../hooks/useUrlState';
import { useMe } from '../../profile/hooks';
import { throttleDispatchFetch } from '../../utils';
import { useFilter, useOrderBy, usePreFilter } from '../../utils/hooks';

const pageBlock = bem('page');

const throttledFetch = throttleDispatchFetch(fetchUserCollections);

const defaultOrder = [
  { field: 'order', order: 'asc' },
  { field: 'name', order: 'asc' },
];

const ExhibitorsPage = (props) => {
  const {
    collection,
    filters: fieldsToFilter = [],
    filterConfig,
    preFilters,
    groupBy,
    orderBy: orderByConfig = defaultOrder,
    itemProps,
    header,
    footer,
    pageId,
    pageHeader,
    pageFooter,
    metadata,
    design,
    match,
    ...rest
  } = props;

  const category = match?.params?.category;
  const { showOnlyFavorites = false } = itemProps;
  const { showGoBack = false } = rest;
  const { users = [] /* , loaded */ } = useSelector((state) => selectUsers(state, [collection]));
  const filteredUser = usePreFilter(users, preFilters);

  const [filteredData, currentFilters, setFilters] = useFilter(
    filteredUser,
    fieldsToFilter,
    omitBy({ category }, isUndefined),
  );
  const [urlState, setUrlState] = useUrlState({});
  const defaultShowFavorites = showOnlyFavorites ? true : null;
  const { showFavorites = defaultShowFavorites } = urlState;
  const user = useMe();
  const userFavorites = user?.private?.favorites?.users?.[collection] ?? {};
  const finalSponsors = useOrderBy(filteredData, orderByConfig);
  const filteredSponsors = showFavorites
    ? finalSponsors.filter(({ _id }) => !!userFavorites[_id])
    : finalSponsors;

  useMount(() => throttledFetch([collection]));

  const handleToggleFavorites = () => {
    setUrlState({ showFavorites: !showFavorites ? true : null });
  };

  return (
    <CmsPageLayout
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      metadata={metadata}
      designOverride={design}
      className={pageBlock({ exhibitors: true, [pageId]: true, category }).toString()}
    >
      {header && <CmsBlocks blocks={header.blocks} />}
      <Container>
        <LightBackButton showGoBack={showGoBack} />
        <Grid stackable columns="equal">
          {fieldsToFilter && fieldsToFilter.length > 0 && (
            <Grid.Column width={4} className="sidebar--left">
              <div style={{ paddingBottom: 10 }}>
                <MenuFilter
                  filterList={generateFilters(users, fieldsToFilter, currentFilters)}
                  filters={currentFilters}
                  onChange={setFilters}
                  filterConfig={filterConfig}
                  pageId={pageId}
                  onToggleFavorites={handleToggleFavorites}
                  isFavoriteToggled={showFavorites}
                />
              </div>
            </Grid.Column>
          )}
          <Grid.Column>
            <ScreenIntro type="exhibitors" category={collection} />
            <ItemCardGroup
              {...rest}
              groupClassName="sponsors"
              items={filteredSponsors}
              groupBy={groupBy}
              component={(p) => <SponsorCard {...itemProps} {...p} />}
            />
          </Grid.Column>
        </Grid>
      </Container>
      {footer && <CmsBlocks blocks={footer.blocks} />}
    </CmsPageLayout>
  );
};

ExhibitorsPage.defaultProps = {
  collection: 'exhibitors',
  design: {},
  filterConfig: undefined,
  filters: [],
  footer: undefined,
  groupBy: undefined,
  header: undefined,
  itemProps: {},
  orderBy: undefined,
  pageFooter: undefined,
  pageHeader: undefined,
  metadata: undefined,
  preFilter: {},
};

ExhibitorsPage.propTypes = {
  collection: PropTypes.string,
  design: PropTypes.object,
  filterConfig: PropTypes.object,
  filters: PropTypes.array,
  footer: cmsBlocksType,
  groupBy: PropTypes.object,
  itemProps: PropTypes.object,
  header: cmsBlocksType,
  orderBy: orderByType,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string,
    }).isRequired,
  }).isRequired,
  pageId: PropTypes.string.isRequired,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  metadata: PropTypes.object,
  preFilter: PropTypes.object,
};

export default ExhibitorsPage;
