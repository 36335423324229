/* eslint-disable operator-linebreak */

/* eslint-disable no-restricted-syntax */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable no-bitwise */
import React, { FC } from 'react';

import { BinaryOp, Node, UnaryOp, Value } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EvalOptions = { createElement: typeof React.createElement; env: Record<string, any> };

function unop(op: UnaryOp, arg: Value): Value {
  switch (op) {
    case '+':
      return +arg;
    case '-':
      return -arg;
    case '!':
      return !arg;
    case '~':
      return ~arg;
    default: {
      console.error('unhandled unop', op);
      return null;
    }
  }
}

function binop(op: BinaryOp, left: Value, right: Value): Value {
  switch (op) {
    case '+':
      return left + right;
    case '-':
      return left - right;
    case '*':
      return left * right;
    case '/':
      return left / right;
    case '%':
      return left % right;
    case '==':
      // eslint-disable-next-line eqeqeq
      return left == right;
    case '!=':
      // eslint-disable-next-line eqeqeq
      return left != right;
    case '===':
      return left === right;
    case '!==':
      return left !== right;
    case '>':
      return left > right;
    case '>=':
      return left >= right;
    case '<':
      return left < right;
    case '<=':
      return left <= right;
    case '&':
      return left & right;
    case '|':
      return left | right;
    case '^':
      return left ^ right;
    case '<<':
      return left << right;
    case '>>':
      return left >> right;
    case '>>>':
      return left >>> right;
    default: {
      console.error('unhandled binop', op);
      return null;
    }
  }
}

export function jsxEval(
  node: Node,
  components: Record<string, FC | React.Component>,
  props: Record<string, unknown>,
  options: EvalOptions,
): JSX.Element | Value | null {
  const { createElement = React.createElement, env = {} } = options;
  if (!node || !node.type) return null;

  switch (node.type) {
    case 'Identifier':
      return props[node.name] ?? env[node.name];
    case 'Value':
      return node.value;
    case 'TemplateLiteral': {
      let str = '';
      for (let i = 0; i < node.quasis.length; i++) {
        str += node.quasis[i];
        if (i < node.expressions.length) {
          str += jsxEval(node.expressions[i], components, props, options);
        }
      }
      return str;
    }
    case 'UnaryExpression': {
      return unop(node.operator, jsxEval(node.argument, components, props, options));
    }
    case 'BinaryExpression': {
      return binop(
        node.operator,
        jsxEval(node.left, components, props, options),
        jsxEval(node.right, components, props, options),
      );
    }
    case 'LogicalExpression': {
      const left = jsxEval(node.left, components, props, options);
      switch (node.operator) {
        case '&&': {
          if (!left) return false; // Slight semantic change for React Native (simplified {str && <Text>{str}</Text>})
          return jsxEval(node.right, components, props, options);
        }
        case '||': {
          if (left) return left;
          return jsxEval(node.right, components, props, options);
        }
        default: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          console.error('unhandled logicop', node.operator);
          return null;
        }
      }
    }
    case 'ConditionalExpression': {
      const res = jsxEval(node.test, components, props, options);
      if (res) {
        return jsxEval(node.consequent, components, props, options);
      }
      return jsxEval(node.alternate, components, props, options);
    }
    case 'MemberExpression': {
      const { object, property } = node;
      const obj = jsxEval(object, components, props, options);
      if (!obj) return null;
      let name: string;
      if (property.type === 'Identifier') {
        name = property.name;
      } else {
        name = jsxEval(property, components, props, options) as string;
      }

      const res = obj[name];
      if (typeof res === 'function') {
        res._this = obj;
      }
      return res;
    }
    case 'ObjectExpression': {
      const result: Record<string, unknown> = {};
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in node.properties) {
        result[key] = jsxEval(node.properties[key], components, props, options);
      }
      return result;
    }
    case 'ArrayExpression': {
      return node.elements.map((n) => jsxEval(n, components, props, options));
    }
    case 'CallExpression': {
      const callee = jsxEval(node.callee, components, props, options);
      if (!callee) {
        console.warn('Failed to find callee from', node.callee);
        return null;
      }
      return callee.apply(
        callee._this,
        node.arguments.map((arg) => jsxEval(arg, components, props, options)),
      );
    }
    case 'ArrowFunctionExpression': {
      const { body, params } = node;
      return (...args: unknown[]) =>
        jsxEval(
          body,
          components,
          {
            ...props,
            ...params.reduce((acc, param, i) => ({ ...acc, [param.name]: args[i] }), {}),
          },
          options,
        );
    }
    case 'Component': {
      let elementProps: Record<string, any> = {};

      if (node.props.length === 1 && node.props[0].type === 'JSXAttributeValues') {
        // Only prop with all the values in it
        elementProps = node.props[0].values;
      } else {
        for (const prop of node.props) {
          if (prop.type === 'JSXAttributeValues') {
            Object.assign(elementProps, prop.values);
          } else if (prop.type === 'JSXAttributeValue') {
            elementProps[prop.name] = prop.value;
          } else {
            elementProps[prop.name] =
              prop.value !== undefined ? jsxEval(prop.value, components, props, options) : true;
          }
        }
      }
      const children = node.children.map((c) => jsxEval(c, components, props, options));
      return createElement(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (components[node.component] ?? node.component) as any,
        elementProps,
        ...children,
      );
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.warn('Unknown node type', node.type, node);
      return null;
    }
  }
}
