import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Icon, Input, Segment, TextArea } from 'semantic-ui-react';

import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import pick from 'lodash/pick';
import keyBy from 'lodash/keyBy';

import BlockContainer from '../BlockContainer';

import platformService from '../../core/services/platform.service';
import { containerTypePropTypes } from '../../propTypes';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { sweetAlert } from '../../utils/popupUtils';

const prefix = 'contact';

const defaultFields = [
  [
    {
      name: 'firstName',
      placeholder: getString('users.field.firstName'),
      label: getString('users.field.firstName'),
      type: 'string',
      icon: 'user',
      width: 8,
      required: true,
      control: Input,
    },
    {
      name: 'lastName',
      placeholder: getString('users.field.lastName'),
      label: getString('users.field.lastName'),
      type: 'string',
      icon: 'user',
      width: 8,
      required: true,
      control: Input,
    },
  ],
  [
    {
      name: 'email',
      placeholder: getString('users.field.email'),
      label: getString('users.field.email'),
      type: 'email',
      width: 16,
      icon: 'envelope',
      required: true,
      control: Input,
    },
    {
      name: 'phoneNumber',
      placeholder: getString('users.field.phoneNumber'),
      label: getString('users.field.phoneNumber'),
      type: 'string',
      width: 16,
      icon: 'phone',
      required: false,
      control: Input,
    },
  ],
  [
    {
      name: 'subject',
      placeholder: getString(`${prefix}.subject`),
      label: getString(`${prefix}.subject`),
      type: 'string',
      icon: 'question',
      width: 16,
      required: true,
      control: Input,
    },
  ],
  [
    {
      name: 'message',
      label: getString(`${prefix}.message`),
      type: 'string',
      width: 16,
      required: true,
      control: TextArea,
    },
  ],
];

function initFormFromUser() {
  const fields = flattenDeep(defaultFields).map((field) => field.name);
  return pick(store.user, fields);
}

function getErrorMessage(code) {
  switch (code) {
    case 'E_EMPTY_CONTACT_EMAIL':
      return getString(`${prefix}.empty-email-recipient`);
    default:
      return getString(`${prefix}.email-error`);
  }
}

const ContactBlock = (props) => {
  const { containerType, fields: fieldsOverride = [], buttonIcon } = props;


  const [data, setData] = useState(initFormFromUser);
  const [state, setState] = useState('IDLE');
  const handleChange = (e, { name }) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    const newData = { ...data, [name]: value };
    setData(newData);
  };

  const fieldsOverrideByName = keyBy(fieldsOverride, 'name');
  const fieldsAugmented = defaultFields.map((fieldsLine) =>
    fieldsLine.map((field) => ({ ...field, ...get(fieldsOverrideByName, field.name, {}) })),
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState('SENDING');
    try {
      const res = await platformService.sendContactEmail(data);
      if (res.success) {
        setState('DONE');
      } else if (res.errors) {
        const { code } = res.errors[0];
        sweetAlert({ icon: 'error', title: getErrorMessage(code) });
        setState('ERROR');
      }
    } catch (error) {
      sweetAlert({ icon: 'error', title: getString(`${prefix}.email-error`) });
      setState('ERROR');
    }
  };

  if (state === 'DONE') {
    return (
      <Segment>
        <div style={{ textAlign: 'center' }}>{getString(`${prefix}.mail-sent`)}</div>
      </Segment>
    );
  }
  const renderField = (field) => (
    <Form.Field
      key={field.name}
      {...field}
      iconPosition={field.icon && 'left'}
      value={get(data, field.name)}
      onChange={handleChange}
    />
  );
  return (
    <BlockContainer
      className="block--contact"
      header={getString(`${prefix}.contact-us`)}
      type={containerType}
    >
      <Form onSubmit={handleSubmit}>
        {fieldsAugmented.map((field) => (
          <Form.Group key={field.name}>{field.map(renderField)}</Form.Group>
        ))}
        <Button type="submit" primary icon>
          <Icon
            name={state !== 'SENDING' ? buttonIcon || 'send' : 'circle notch'}
            loading={state === 'SENDING'}
          />
          <span style={{ marginLeft: 8, marginRight: 8 }}>{getString(`${prefix}.send`)}</span>
        </Button>
      </Form>
    </BlockContainer>
  );
};

ContactBlock.defaultProps = {
  containerType: 'segment-light',
  fields: [],
  buttonIcon: undefined,
};

ContactBlock.propTypes = {
  containerType: containerTypePropTypes,
  fields: PropTypes.arrayOf(PropTypes.object),
  buttonIcon: PropTypes.string,
};

export default ContactBlock;
