import { useUnmount } from 'ahooks';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModalEntries } from './modalEntries';

export function useModalEntries(kind, collection) {
  return useSelector((state) => state.modalEntries?.[kind]?.[collection]);
}

export function useUpdateModalEntries(kind, collection, items) {
  const dispatch = useDispatch();
  const previousEntries = useModalEntries(kind, collection);

  useEffect(() => {
    if (!isEqual(items, previousEntries)) {
      dispatch(setModalEntries(kind, collection, items));
    }
  }, [dispatch, kind, collection, items, previousEntries]);

  useUnmount(() => {
    dispatch(setModalEntries(kind, collection, []));
  });
}
