import { UserAppointment } from '../components/Chat/Appointments/types';
import { UserType } from '../core/users/users.types';

export function formatUsername(user: UserType | UserAppointment): string {
  if (!user) return '';
  return `${user.firstName || ''} ${user.lastName || ''}`;
}

export function joinUsers(users: UserType[] | UserAppointment[]): string {
  if (!users?.length) return '';
  return users.map(formatUsername).join(', ');
}
