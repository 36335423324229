import { Button, Form, Modal } from 'semantic-ui-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getString } from '../../utils';
import { useInviteMode } from '../InviteModes.hooks';
import { InviteModes } from '../InviteModes';

const translationPrefix = 'networking';
const InviteModal = ({ who, onCancel, onSend }) => {
  const inviteMode = useInviteMode();
  const modalTitleKey =
    inviteMode === InviteModes.REQUEST ? 'send-message-to' : 'send-first-message-to';

  const [message, setMessage] = useState('');
  return (
    <Modal open>
      <Modal.Header>
        {getString(`${translationPrefix}.message-popup.${modalTitleKey}`, { name: who.firstName })}
        {who.contactLastName}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
            label={getString(`${translationPrefix}.message-popup.message`)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{getString('btn.cancel')}</Button>
        <Button primary onClick={() => onSend(who, message)}>
          {getString('btn.send')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
InviteModal.propTypes = {
  who: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default InviteModal;
