import omit from 'lodash/omit';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Menu } from 'semantic-ui-react';

import { CmsBlocks } from '../../components/cms';
import './MenuFilter.scss';
import {
  DateFilter,
  DateTimeFilter,
  RouterParamFilter,
  SearchFilter,
  SelectEntity,
  SimpleFilter,
  TagsFilter,
} from './components';
import { FavoriteFilter } from './components/FavoriteFilter';
import MultiSelectFilter from './components/MultiSelectFilter';
import SelectFilter from './components/SelectFilter';
import { applyFilterChange, handleFilterArray, toggleFilter } from './utils';

const translationPrefix = 'filters';

const MenuComponents = {
  'date': DateFilter,
  'datetime': DateTimeFilter,
  'router-param': RouterParamFilter,
  'simple': SimpleFilter,
  'search': SearchFilter,
  'select-entity': SelectEntity,
  'select': SelectFilter,
  'tags': TagsFilter,
  'multiselect': MultiSelectFilter,
  'favorites': FavoriteFilter,
};

const MenuFilters = (props) => {
  const { t } = useTranslation();
  const {
    filterList,
    filters,
    label,
    filterConfig,
    onToggleFavorites,
    isFavoriteToggled,
    onChange,
    pageId,
  } = props;
  const { header } = filterConfig || {};

  // TODO : Deprecated
  const handleSelect = useCallback(
    (filter, value) => {
      if (filter.type === 'multiselect') {
        onChange(handleFilterArray(filters, filter.dataKey, value));
      }
      onChange(toggleFilter(filters, filter.dataKey, value));
    },
    [filters, onChange],
  );

  const handleChange = useCallback(
    (filter, value) => {
      onChange(applyFilterChange(filters, filter.dataKey, value));
    },
    [filters, onChange],
  );

  const isResetAllDisabled = useMemo(
    () => Object.keys(omit(filters, ['collection'])).length === 0,
    [filters],
  );

  return (
    <Menu vertical fluid className="menu--filter">
      {header && (
        <Menu.Item>
          <CmsBlocks blocks={header?.blocks} />{' '}
        </Menu.Item>
      )}
      <Menu.Item>
        <Header as="h3">{label || t('filters.label')}</Header>
      </Menu.Item>
      <Menu.Item disabled={isResetAllDisabled} className="reset-all" onClick={() => onChange({})}>
        {t(`${translationPrefix}.reset-all`)}
      </Menu.Item>
      {filterList.map((filter) => {
        if (filter.type && !(filter.type in MenuComponents)) {
          // eslint-disable-next-line no-console
          console.error('Unknown filter type', filter.type);
          return null;
        }
        const Component = MenuComponents[filter.type] || SimpleFilter;

        return (
          <Component
            key={filter.dataKey}
            filter={filter}
            value={filters[filter.dataKey]}
            onSelect={handleSelect}
            onChange={handleChange}
            onToggleFavorites={onToggleFavorites}
            isFavoriteToggled={isFavoriteToggled}
            pageId={pageId}
          />
        );
      })}
    </Menu>
  );
};

export default MenuFilters;
