import React, { useState } from 'react';
import { Segment, Sidebar, List, Input } from 'semantic-ui-react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import get from 'lodash/get';
import './WorkshopsMapBlock.scss';
import InfoCard from './components/InfoCard';
import BlockContainer from '../../BlockContainer';
import { useSyncedCollectionWorkshops } from '../../../workshops/store/workshops.hooks';
import { bem } from '../../../core/design/bem';

type WorkshopEntry = {
  _id: string;
  collection: string;
};

const WorkshopsMapBlock = (props: { config: any; container: any }): false | JSX.Element => {
  const [selected, setSelected] = useState<string | undefined>();
  const [filter, setFilter] = useState('');
  const { config = {}, container } = props;
  const workshops: WorkshopEntry[] = useSyncedCollectionWorkshops(
    config?.collection || 'workshops',
  );
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAUUuKNMZYhn_tZudt8SwQu4XxmiELh4tE', // ToDo: A better place ?
  });

  const hideInfoCard = () => {
    setSelected(undefined);
  };

  const currentWorkshopInfoCard = workshops.find((w) => w._id === selected);

  const options = {
    boxStyle: { width: '200px', borderRadius: '2px' },
    enableEventPropagation: true,
  };

  const lowercaseFilter = filter.toLowerCase();
  const filteredList = workshops.filter(
    (w: any) =>
      w.title?.toLowerCase()?.includes(lowercaseFilter) ||
      w?.address?.city?.toLowerCase()?.includes(lowercaseFilter) ||
      w?.address?.region?.toLowerCase()?.includes(lowercaseFilter),
  );

  const wMap = bem('workshops-map');

  if (!isLoaded) return <></>;

  return (
    <BlockContainer type="basic" {...container} className="block--workshops-map">
      <div className={wMap()}>
        <Sidebar.Pushable as={Segment} className={wMap('sidebar').toString()}>
          <Sidebar animation="push" vertical visible>
            <div className={wMap('sidebar__content')}>
              {config?.sidebar?.title && <h1>{config.sidebar.title}</h1>}
              {config?.sidebar?.searchBar && (
                <Input
                  icon="search"
                  value={filter}
                  onChange={(_e, { value }) => setFilter(value)}
                  fluid
                />
              )}
              <List divided selection size="small">
                {filteredList.map((w: any) => {
                  const active = w?._id === selected;
                  return (
                    <List.Item
                      key={w._id}
                      onClick={() => setSelected(w._id)}
                      active={active}
                      style={{ display: 'flex' }}
                    >
                      {config?.logo?.showInSidebar && (
                        <img
                          src={w[config?.logo?.field]?.uri}
                          alt=""
                          style={{ height: '2em', margin: 'auto 0.5em auto 0' }}
                        />
                      )}
                      <List.Content>
                        <List.Header>{w.title}</List.Header>
                        <List.Description>
                          {w?.address?.city}, {w?.address?.region}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
            </div>
          </Sidebar>
        </Sidebar.Pushable>
        <GoogleMap
          zoom={config?.defaultZoom || 5}
          center={config?.defaultCenter || { lat: 47, lng: 2 }}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
          mapContainerStyle={{
            width: '100%',
            height: '100%',
          }}
        >
          {currentWorkshopInfoCard && (
            <InfoCard
              options={options}
              workshop={currentWorkshopInfoCard}
              hideInfoCard={hideInfoCard}
              config={config}
            />
          )}
          {workshops.map((w: any) => {
            const coordinates = {
              lat: Number(get(w, config?.coordinates?.latKey)),
              lng: Number(get(w, config?.coordinates?.lngKey)),
            };

            return (
              <div>
                <Marker
                  position={coordinates}
                  key={`marker${w._id}`}
                  onClick={() => setSelected(w._id)}
                  title={w._id}
                />
              </div>
            );
          })}
        </GoogleMap>
      </div>
    </BlockContainer>
  );
};

export default WorkshopsMapBlock;
