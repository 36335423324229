/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactPlayer from 'react-player';

import './ReactVideoPlayer.scss';
import { useReactVideoStats } from './useReactVideoStats';

type ReactVideoPlayerProps = {
  _id: string;
  url: string;
  originalUrl: string;
  autoPlay: boolean;
  item?: Record<string, any>;
  block?: Record<string, any>;
  onPlay: () => void;
  onSectionStat: (stat: Record<string, any>) => void;
} & typeof DefaultProps;

const DefaultProps = { item: {}, block: {} };

export const ReactVideoPlayer = (props: ReactVideoPlayerProps): JSX.Element => {
  const { url, autoPlay } = props;
  const playerProps = useReactVideoStats(props);

  return (
    <div className="ReactVideoPlayer">
      <ReactPlayer
        controls
        {...playerProps}
        url={url}
        config={{
          youtube: {
            playerVars: {
              autoplay: autoPlay ? 1 : 0,
              muted: !autoPlay,
            },
          },
        }}
      />
    </div>
  );
};

ReactVideoPlayer.defaultProps = DefaultProps;
