import cx from 'classnames';

const FooterCount = (props: Record<string, any>): JSX.Element | null => {
  const { currentIndex, getStyles, isFullscreen, isModal, views } = props;
  const activeView = currentIndex + 1;
  const totalViews = views.length;

  if (!activeView || !totalViews) return null;

  return (
    <span
      css={getStyles('footerCount', props)}
      className={cx(
        'footer__count',
        isFullscreen && 'footer__count--isFullscreen',
        isModal && 'footer__count--isModal',
      )}
    >
      {activeView} / {totalViews}
    </span>
  );
};

export default FooterCount;
