import flatten from 'lodash/flatten';
import get from 'lodash/get';
import { Button, Header, Icon, Message } from 'semantic-ui-react';
import Swal from 'sweetalert2';

import { getString } from '../utils';
import { sweetAlert } from '../utils/popupUtils';

const translationPrefix = 'workshop-sessions.registration';

function getErrorString(code) {
  switch (code) {
    case 'E_SESSION_FULL':
      return getString(`${translationPrefix}.session-full`);
    case 'E_USER_ALREADY_ATTENDING':
      return getString(`${translationPrefix}.user-already-attending-session`);
    case 'E_MISSING_SESSION':
      return getString(`${translationPrefix}.missing-session`);
    case 'E_CLOSED_MAX_REGISTRATIONS_PER_DAY':
      return getString(`${translationPrefix}.max-registrations-per-day-reached`);
    default:
      return getString(`${translationPrefix}.workshop-session-error`);
  }
}

export function showError(code) {
  return sweetAlert({ icon: 'error', title: getErrorString(code) });
}

export function formatSessionErrors(result, sessions) {
  const errorsWithSessionTitle = result.map((r, idx) => ({
    ...r,
    title: sessions?.[idx]?.title || '',
  }));

  return flatten(
    errorsWithSessionTitle.map((r) =>
      r.errors?.map((e) => ({
        ...e,
        message: `${r.title}: ${getErrorString(e.code) || e.message}`,
        title: r.title,
      })),
    ),
  ).filter((v) => v);
}

export function showSessionErrors(errors) {
  sweetAlert({
    icon: 'error',
    width: '60%',
    html: (
      <Message
        warning
        header={getString(`${translationPrefix}.multiple-errors`)}
        list={errors?.map((e) => e.message)}
      />
    ),
  });
}

export function showSessionRegistrationAlert(config = {}) {
  const link = get(config, ['alert', 'link']);
  function handleClick(e) {
    if (link?.[0] === '/') {
      e.preventDefault();
      e.stopPropagation();
      window.appHistory.push(link);
      Swal.close();
    }
  }
  sweetAlert({
    icon: 'success',
    width: '60%',
    showConfirmButton: !link,
    html: (
      <div className="alert-validation">
        <Header as="h3" className="header">
          {getString(`${translationPrefix}.successful-multi-registration`)}
        </Header>
        <Button as="a" className="button" primary href={link} onClick={handleClick}>
          <Icon name="calendar outline" />
          {getString(`${translationPrefix}.check-my-agenda`)}
        </Button>
      </div>
    ),
  });
}
