import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'semantic-ui-react';
import Blocks from '../components/Blocks';
import { cmsFields } from '../components/cms';
import { useScreenConfig } from '../config/screens.context';
import './cms.scss';

export function injectContainerIfNeeded(block) {
  if (block?.props?.container?.type === 'section') {
    return block;
  }
  return <Container>{block}</Container>;
}

export const CmsBlocks = ({ blocks, injectContainer }) => {
  return (
    <Blocks
      blocks={blocks}
      blockComponents={cmsFields}
      blockWrapper={injectContainer ? injectContainerIfNeeded : undefined}
    />
  );
};
CmsBlocks.defaultProps = {
  blocks: [],
  injectContainer: undefined,
};

CmsBlocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  injectContainer: PropTypes.func,
};

function CmsScreen(props) {
  const { name } = props;
  const { blocks = [] } = useScreenConfig(name);
  return (
    <div className={`screen--type-cms screen--${name}`} style={{ marginTop: 10 }}>
      <CmsBlocks blocks={blocks} />
    </div>
  );
}

CmsScreen.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CmsScreen;
