/* eslint-disable @typescript-eslint/no-explicit-any */
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useDispatch } from 'react-redux';
import { Card, Icon, Label } from 'semantic-ui-react';

import { useTracking } from '../../../../Context';
import { bem } from '../../../../core/design/bem';
import { eventTags } from '../../../../core/trackers/events';
import { uuid } from '../../../../core/trackers/utils';
import { sendMessage } from '../../../../networking/store/networking.actions';
import { useMe } from '../../../../profile/hooks';
import { fromNow } from '../../../../utils/dateUtils';
import { GLOBAL_MEDIA_QUERIES } from '../../../../utils/mediaQueries';
import { UserFlag } from '../../../Avatars/UserFlag';
import Chat from '../../../Chat/Chat';
import ImageIcon from '../../../ImageIcon';
import SquareImage from '../../../SquareImage';
import { useChatMessages } from './hooks';

const css = bem('MessagesPopup__List');
const translationPrefix = 'messages.popup.list';

type MessagesListProps = {
  chatId?: string;
  config?: Record<string, any>;
  chats: Record<string, any>[];
  onStartChat: any;
  onBackToChats: any;
};

type UserField = {
  key: string;
  icon?: Record<string, any> | string;
};

const defaultUserFields: UserField[] = [
  { key: 'role', icon: 'briefcase' },
  { key: 'company', icon: 'building' },
];

export const MessagesList = (props: MessagesListProps): JSX.Element => {
  const { trackEvent } = useTracking();
  const { chatId, chats, config, onStartChat, onBackToChats } = props;
  const { imageProps, countryField = 'country', userFields = defaultUserFields, enabled } =
    config || {};
  const { defaultThumbnail, imageSize = 50, ...otherImageProps } = imageProps || {};

  const me = useMe();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const chatsById = useMemo(() => keyBy(chats, 'id'), [chats]);
  const currentChat = chatId ? chatsById[chatId] : undefined;
  const currentMessages = useChatMessages(currentChat);
  const conversationsWithMessages = useMemo(() => chats.filter((chat) => chat.hasMessages), [
    chats,
  ]);

  if (chats?.length === 0) {
    return (
      <div className={css()}>
        <span className={css('no-messages')}>{t(`${translationPrefix}.no-messages`)}</span>
        {!mobile && enabled && (
          <div className={css('subtitle')}>{t(`${translationPrefix}.subtitle`)}</div>
        )}
      </div>
    );
  }

  if (chatId) {
    return (
      <div className={css('Chat')}>
        <Icon name="arrow alternate circle left" onClick={onBackToChats} />
        <Chat
          me={me._id}
          messages={currentMessages}
          onSendMessage={(message) => {
            const messageId = uuid();
            trackEvent(eventTags.NETWORKING_SEND_MESSAGE, {
              chatId,
              messageId,
              inviterId: currentChat?.inviter,
              invitedId: currentChat?.invited,
            });
            dispatch(sendMessage(currentChat, messageId, message));
          }}
        />
      </div>
    );
  }

  return (
    <div className={css()}>
      {!mobile && (
        <div className={css('subtitle')} style={{ paddingTop: 0 }}>
          {t(`${translationPrefix}.subtitle`)}
        </div>
      )}
      <Card.Group itemsPerRow={1}>
        {conversationsWithMessages.map((chat) => {
          const { unreadCount, fullname, profile, id, updatedAt } = chat;
          const { thumbnail } = profile || {};
          return (
            <Card
              key={`chat-preview-${chat.id}`}
              className={css('Card', { unread: unreadCount > 0 }).toString()}
              onClick={() => onStartChat(id)}
            >
              <Card.Content className={css('Content').toString()}>
                <div className={css('Thumbnail')}>
                  {!!unreadCount && <Label className="unreadCount">{unreadCount}</Label>}
                  <SquareImage
                    as="img"
                    alt={`${fullname}`}
                    src={thumbnail || defaultThumbnail}
                    imageSize={imageSize}
                    {...otherImageProps}
                  />
                  <UserFlag user={profile} field={countryField} />
                </div>
                <div className={css('Info')}>
                  <div className="hour">{fromNow(updatedAt)}</div>
                  <Card.Header as="h4" className="fullname">
                    {fullname}
                  </Card.Header>
                  {userFields.length > 0 && (
                    <Card.Description className="userFields">
                      {(userFields as UserField[])
                        .filter(({ key }) => profile[key])
                        .map(({ key, icon }) => (
                          <div key={key}>
                            {icon && <ImageIcon icon={icon} />}&nbsp;
                            {profile[key]}
                          </div>
                        ))}
                    </Card.Description>
                  )}
                </div>
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>
    </div>
  );
};

MessagesList.defaultProps = {
  chatId: undefined,
  config: {},
};
