import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import CdnImage from '../../../components/CdnImage';
import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { Icons } from '../../../components/Icons/material-icon.types';
import store from '../../../shared/Store';
import { removeFalsy } from '../../../utils/arrayUtils';
import { isVideoMedia, videoCDN } from '../../../utils/videoUtils';
import { useSocialWallConfig } from '../../social-wall-config.context';
import { Publication } from '../../types/SocialWallV2.types';
import { EmoteList } from '../EmoteList';
import { PublicationTextAndComment } from './DetailedPublication/PublicationTextAndComment';
import { DetailedPublicationHeader } from './DetailedPublicationHeader';
import { useApiVideoIframe } from './hooks/useApiVideoIframe';

interface DesktopDetailsPublicationProps {
  publication: Publication;
  comments: Publication[];
  userId: string;
  onClose: () => any;
  onDelete: () => void;
  onEdit: () => void;
  onReport: () => void;
  onAddComment: (message: string) => Promise<Publication>;
  onDeleteComment: (commentId: string) => Promise<void>;
  onInteractionClick: (action: string) => Promise<void>;
  userInteractions: string[];
  onNext: () => void;
  onPrev: () => void;
}

export const DesktopDetailsPublication: FC<DesktopDetailsPublicationProps> = ({
  publication,
  comments,
  userId,
  onClose,
  userInteractions,
  onDelete,
  onEdit,
  onReport,
  onAddComment,
  onDeleteComment,
  onInteractionClick,
  onNext,
  onPrev,
}) => {
  const { author, data, createdAt, interactions } = publication;
  const { media, message, thumbnail } = data;
  const { filename, mimeType, uri, player } = media || {};

  const { reportEnabled } = useSocialWallConfig();

  const { t } = useTranslation();

  const actions = removeFalsy([
    userId === publication.author.userId && {
      label: t('btn.delete'),
      onClick: onDelete,
      icon: 'delete' as Icons,
    },
    userId === publication.author.userId && {
      label: t('btn.edit'),
      onClick: onEdit,
      icon: 'edit_square' as Icons,
    },
    userId !== publication.author.userId &&
      reportEnabled && {
        label: t('btn.report'),
        onClick: onReport,
        icon: 'report' as Icons,
      },
  ]);

  useApiVideoIframe('video-wrapper', media?.videoId);

  const renderMedia = () => {
    if (!media || !mimeType || !uri) return null;
    const isVideo = isVideoMedia({ mimeType, filename: filename || '' });
    const videoUrl = videoCDN(uri);

    return isVideo ? (
      <div
        className={cx('video-wrapper', player && 'video-wrapper--player')}
        id="video-wrapper"
        key={publication._id}
      >
        {!player && (
          <video
            playsInline
            disableRemotePlayback
            disablePictureInPicture
            x-webkit-airplay="deny"
            autoPlay
            controls
            width={250}
            poster={thumbnail?.uri}
          >
            <source src={videoUrl} type={mimeType || 'video/mp4'} />
            {t('common.no-video')}
          </video>
        )}
      </div>
    ) : (
      <CdnImage
        key={publication._id}
        className="image-media"
        src={uri}
        maxWidth={600}
        maxHeight={400}
        loading="lazy"
      />
    );
  };

  return (
    <div className="publication-container">
      <div className="publication-container__content">
        {media ? (
          renderMedia()
        ) : (
          <div className="publication-container__text">
            <span>{message}</span>
          </div>
        )}
        <div
          className={cx(
            'publication-container__header',
            media && 'publication-container__header--gradient',
          )}
        >
          <button
            className={cx('close-button', !media && 'close-button--dark')}
            type="button"
            onClick={onClose}
            aria-label="close-button"
          >
            <MaterialIcon name="close" />
          </button>
        </div>
        <Button className="navigation-button prev-button" onClick={onPrev} icon="angle left" />
        <Button className="navigation-button next-button" onClick={onNext} icon="angle right" />

        <EmoteList
          disabled={!store.isLoggedIn()}
          interactions={interactions}
          userInteractions={userInteractions}
          onEmoteClick={onInteractionClick}
        />
      </div>
      <div className="publication-container__side">
        <DetailedPublicationHeader
          user={author}
          date={createdAt}
          onClose={onClose}
          dark
          closeButton={false}
          actions={actions}
        />
        <PublicationTextAndComment
          disabled={!store.isLoggedIn()}
          publication={{ ...publication, data: media ? publication.data : {} }}
          comments={comments}
          userInteractions={userInteractions}
          style={{
            paddingTop: '60px',
          }}
          withEmote={false}
          onAddComment={onAddComment}
          onDeleteComment={onDeleteComment}
        />
      </div>
    </div>
  );
};
