import get from 'lodash/get';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'semantic-ui-react';

import { useTracking } from '../../../Context';
import { bem } from '../../../core/design/bem';
import { eventTags } from '../../../core/trackers/events';
import { useMe } from '../../../profile/hooks';
import store from '../../../shared/Store';
import BlockContainer from '../../BlockContainer';
import { BlockProps } from '../types';
import './GamificationCodeBlock.scss';

const css = bem('GamificationCodeBlock');

type GamificationCodeBlockProps = BlockProps & {
  text?: string;
};

type GamificationCodeInputProps = {
  blockId?: string;
};

export const GamificationCodeInput: FC<GamificationCodeInputProps> = ({ blockId }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState<string | number>();
  const { trackEvent } = useTracking();

  const handleValidate = async () => {
    setSaving(true);
    setTimeout(() => {
      setSaving(false);
      setValue('');
    }, 2000);
    trackEvent(eventTags.ITEM_CLICK, { actionType: 'validate-code', code: value, blockId });
  };

  return (
    <Form>
      <Form.Field width={16}>
        <Input
          labelPosition="right"
          focus
          onChange={(e) => setValue(e.target.value)}
          value={value}
          placeholder={t('blocks.gamification-code.placeholder')}
          disabled={saving}
          label={
            <Button
              type="submit"
              primary
              disabled={!value || saving}
              onClick={handleValidate}
              loading={saving}
            >
              {t('btn.validate')}
            </Button>
          }
        />
      </Form.Field>
    </Form>
  );
};

export const GamificationCodeBlock: FC<GamificationCodeBlockProps> = (props) => {
  const { t } = useTranslation();
  const { container, className, _id, text = t('blocks.gamification-code.enter-code') } = props;

  return (
    <BlockContainer {...container} className={css({ _id }).mix(className)}>
      <div dangerouslySetInnerHTML={{ __html: text! }} />
      <GamificationCodeInput blockId={_id} />
    </BlockContainer>
  );
};
