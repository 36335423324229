/* eslint-disable react/prop-types */
import React from 'react';
import { Grid } from 'semantic-ui-react';

import Blocks from '../Blocks';
import SidebarBlocks from '../SidebarBlocks';

const ItemGridLayout = ({
  leftSidebar,
  mainBlocks,
  rightSidebar,
  item,
  sharedProps,
  sidebarBlockComponents,
  mainBlockComponents,
}) => {
  const blockProps = { item, ...sharedProps };
  return (
    <Grid columns="equal" stackable>
      <SidebarBlocks
        className="sidebar--left"
        sidebar={leftSidebar}
        blockComponents={sidebarBlockComponents}
        sharedProps={blockProps}
      />
      <Grid.Column>
        <Blocks
          blocks={mainBlocks}
          sharedProps={blockProps}
          blockComponents={mainBlockComponents}
        />
      </Grid.Column>
      <SidebarBlocks
        className="sidebar--right"
        sidebar={rightSidebar}
        blockComponents={sidebarBlockComponents}
        sharedProps={blockProps}
      />
    </Grid>
  );
};

export default ItemGridLayout;
