import { sweetConfirmAlert, sweetAlert } from '../../../utils/popupUtils';
import { getString } from '../../../utils';
import platformService from '../../../core/services/platform.service';
import { eventTags } from '../../../core/trackers/events';
import store from '../../../shared/Store';
import { fetchAppointments } from '../../../store/actions';

import { fetchAgenda } from '../../../agenda/store/agenda.actions';

const translationPrefix = 'appointments';

function getErrorString(code) {
  switch (code) {
    case 'E_MISSING_APPOINTMENT':
      return getString(`${translationPrefix}.missing-appointment`);
    case 'E_UNAVAILABLE_USER':
      return getString(`agenda.unavailable-user`);
    case 'E_NO_AVAILABLE_TIMESLOT':
      return getString(`${translationPrefix}.slots.unavailable-slot`);
    default:
      return getString(`${translationPrefix}.slots.book-appointment-error`);
  }
}

export function showError(code) {
  return sweetAlert({ icon: 'error', title: getErrorString(code) });
}

export function showMessage(message, icon) {
  return sweetAlert({ icon, title: message });
}

export async function showConfirm(title, message) {
  return sweetConfirmAlert({
    title,
    text: message,
    confirmButtonText: getString(`alert.confirm`),
    cancelButtonText: getString(`alert.cancel`),
  });
}
export async function showBookConflictConfirm(event) {
  return showConfirm(
    getString(`${translationPrefix}.slots.force-confirm-title`),
    getString(`${translationPrefix}.slots.force-confirm-message`, {
      name: event.title,
    }),
  );
}

export async function showBookConflict(event) {
  return showMessage(
    getString(`${translationPrefix}.slots.force-confirm-message`, {
      name: event.title,
    }),
    'error',
  );
}

export async function cancelAppointment(appointment, item, { trackEvent }) {
  const confirm = await showConfirm(
    getString(`${translationPrefix}.slots.cancel-confirm`, {
      name: item.name,
    }),
  );
  if (confirm) {
    try {
      const res = await platformService.cancelAppointment(appointment.collection, appointment._id);
      if (res.errors) {
        const { code } = res.errors[0];
        if (code === 'E_MISSING_APPOINTMENT') {
          showError(code);
        } else {
          showError('ERROR');
        }
      } else if (trackEvent) {
        // prettier-ignore
        const unbookEvent =
          item.collection === 'program' ?
            eventTags.PROGRAM_APPOINTMENT_CANCEL :
            eventTags.SPONSOR_APPOINTMENT_CANCEL;
        trackEvent(unbookEvent, {
          userId: store.userId,
          startDate: appointment.startDate,
          item,
        });
      }
      return res;
    } catch (error) {
      console.error(error);
      showError('ERROR');
    } finally {
      store.reduxStore.dispatch(fetchAppointments()); // refresh user appointments
      store.reduxStore.dispatch(fetchAgenda());
    }
  }
  return { success: false };
}
