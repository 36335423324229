import cx from 'classnames';
import { FC, ReactNode } from 'react';

import defaultAvatar from '../../assets/defaulUserAvatar.png';
import { bem } from '../../core/design/bem';
import SquareImage from '../SquareImage';
import './UserAvatar.scss';

interface UserAvatarProp {
  image?: string;
  title: string;
  description?: string;
  children?: ReactNode;
  dark?: boolean;
}
export const UserAvatar: FC<UserAvatarProp> = (props: UserAvatarProp) => {
  const { image, title, description, children, dark } = props;

  const css = bem('user-avatar');

  return (
    <div className={cx(css(), dark && css({ dark: true }))}>
      {image ? (
        <SquareImage src={image} imageSize={40} />
      ) : (
        <img src={defaultAvatar} alt="default-user-avatar" />
      )}
      <div className={css('name')}>
        <span className="title">{title}</span>
        <span className="description">{description}</span>
        {children}
      </div>
    </div>
  );
};
