import React from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'semantic-ui-react';

import CdnImage from '../../../components/CdnImage';
import { bem } from '../../../core/design/bem';
import store from '../../../shared/Store';
import { replaceValues } from '../../../utils/stringUtils';
import { UserRanking } from '../../hooks/leaderboard.hooks';
import { defaultThumbnail } from './Podium';
import './RankingList.scss';

const translationPrefix = 'achievements.leaderboard';
const css = bem('RankingList');

type RankingListProps = {
  showTopThree: boolean;
  users: UserRanking[];
  subtitleTemplate: string;
};

export const RankingList = (props: RankingListProps): JSX.Element => {
  const { t } = useTranslation();
  const { users = [], showTopThree, subtitleTemplate = '{company}' } = props;
  return (
    <List divided className={css({ onlyList: !showTopThree }).toString()}>
      {users.map((user) => {
        const { userId, firstName, lastName, thumbnail, rank, score } = user;
        const company = replaceValues(subtitleTemplate, user);
        return (
          <List.Item key={userId}>
            <List.Description className="rank">{rank}</List.Description>
            <div className="thumbnail">
              <CdnImage className="avatar" src={thumbnail || defaultThumbnail} maxWidth={60} />
              {rank > 3 && userId === store.userId && (
                <div className="you">{t(`${translationPrefix}.you`)}</div>
              )}
            </div>
            <List.Content>
              <List.Header>
                <span className="firstName">{firstName} </span>
                <span className="lastName">{lastName}</span>
              </List.Header>
              {company && <List.Description>{company}</List.Description>}
            </List.Content>
            <List.Description className="score">{score}</List.Description>
          </List.Item>
        );
      })}
    </List>
  );
};
