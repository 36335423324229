/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Icon } from 'semantic-ui-react';

import { CmsBlocks } from '../../../cms/CmsScreen';
import { bem } from '../../../core/design/bem';
import useUrlState from '../../../hooks/useUrlState';

import './SidebarMenuBlock.scss';
import SidebarMenuItem from './components/SidebarMenuItem';
import { SidebarMenuBlockItem } from './types';

const css = bem('SidebarMenuBlock');

type SidebarMenuBlockProps = {
  blocks: SidebarMenuBlockItem[];
};

const SidebarMenuBlock = ({ blocks }: SidebarMenuBlockProps): JSX.Element | null => {
  const { t } = useTranslation();

  const [selected, setSelected] = useUrlState();
  console.log('selected: ', selected);

  if (!blocks?.length) return null;

  const selectedBlock = blocks.find((b) => b._id === selected?.tab);

  return (
    <div className={css.has({ selection: !!selected.tab })}>
      <div className={css('Menu')}>
        {blocks.map((b) => (
          <SidebarMenuItem
            key={b._id}
            item={b}
            active={b._id === selected._id}
            onSelect={() => setSelected({ tab: b._id })}
          />
        ))}
      </div>
      <div className={css('Content')}>
        {selectedBlock && (
          <>
            <a className="button--back" onClick={() => setSelected({ tab: undefined })}>
              <Icon name="angle left" /> {t('btn.back')}
            </a>
            {selectedBlock.content?.header && (
              <Header as="h2">{selectedBlock.content?.header}</Header>
            )}
            <CmsBlocks blocks={selectedBlock.blocks} />
          </>
        )}
      </div>
    </div>
  );
};

export default SidebarMenuBlock;
