import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';

const FlashMessage = ({ type }: { type: string }): JSX.Element | null => {
  const { t } = useTranslation();
  if (!type) return null;

  const icon = t(`flash.${type}.icon`, { defaultValue: 'warning' });
  const title = t(`flash.${type}.title`);
  const message = t(`flash.${type}.message`, { defaultValue: null });

  if (!title && !message) return null;

  return <Message icon={icon} header={title} content={message} />;
};

export default FlashMessage;
