import { throttle } from 'lodash';
import { RefObject, useCallback } from 'react';
import { useClickAway } from 'react-use';

export const useClickOutside = (
  componentRef: RefObject<HTMLElement | null>,
  callback?: () => void,
): void => {
  //The throttled function has to remain the same between re-renderings
  const handleClickOutside = useCallback(
    throttle(() => {
      if (!componentRef) return;
      if (callback) {
        callback();
      }
    }, 1000),
    [componentRef, callback],
  );
  useClickAway(componentRef, handleClickOutside);
};
