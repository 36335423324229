import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import { Card, Header } from 'semantic-ui-react';

import SpeakerCard from '../../components/speakers/SpeakerCard';
import { getEntityString } from '../../utils';

const defaultExtraFields = [{ dataKey: 'email', lineType: 'extra' }];

const Items = (props) => {
  const { className, items, imageSize, itemsPerRow, width, extraFields, variant, ...rest } = props;
  return (
    <Card.Group
      className={className}
      itemsPerRow={width ? undefined : itemsPerRow}
      style={{ marginTop: 0, marginBottom: 0 }}
    >
      {items.map((speaker) => (
        <SpeakerCard
          {...rest}
          key={speaker._id}
          extraFields={extraFields}
          speaker={speaker}
          width={width}
          imageSize={imageSize}
          showLink={false}
          variant={variant}
        />
      ))}
    </Card.Group>
  );
};

const UserGrid = ({ groupBy: groupByField, items, ...props }) => {
  if (groupByField) {
    const groups = groupBy(items, groupByField);
    return Object.entries(groups).map(([group, entries]) => (
      <div key={group} className={`group group--${group}`}>
        <Header>{getEntityString(entries[0], 'participant', groupByField)}</Header>
        <Items {...props} items={entries} />
      </div>
    ));
  }
  return <Items {...props} items={items} />;
};
UserGrid.defaultProps = {
  className: undefined,
  extraFields: defaultExtraFields,
  imageSize: 110,
  itemsPerRow: 3,
  variant: 'light',
  width: undefined,
};
UserGrid.propTypes = {
  className: PropTypes.string,
  extraFields: PropTypes.arrayOf(PropTypes.shape({ dataKey: PropTypes.string })),
  imageSize: PropTypes.number,
  items: PropTypes.array.isRequired,
  itemsPerRow: PropTypes.number,
  variant: PropTypes.string,
  width: PropTypes.number,
};

export default UserGrid;
