import cx from 'classnames';
import { FC } from 'react';
import { Button } from 'semantic-ui-react';

import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { Icons } from '../../../components/Icons/material-icon.types';
import { QueryPopupButton } from '../../../components/QueryPopupButton/QueryPopupButton';
import { PublicationAuthor } from '../../types/SocialWallV2.types';
import { PublicationActions } from '../PublicationActions/PublicationActions';
import { PublicationHeader } from '../PublicationHeader';

interface DetailedPublicationHeaderProps {
  user: PublicationAuthor;
  date?: string;
  onClose: () => void;
  onPrev?: () => void;
  onNext?: () => void;
  dark?: boolean;
  closeButton?: boolean;
  withGradient?: boolean;
  actions?: {
    label: string;
    onClick: () => void;
    icon: Icons;
  }[];
}
export const DetailedPublicationHeader: FC<DetailedPublicationHeaderProps> = ({
  user,
  date,
  onClose,
  dark,
  closeButton = true,
  withGradient = false,
  actions = [],
  onPrev,
  onNext,
}) => {
  return (
    <div
      className={cx(
        'detailed-header',
        withGradient && 'detailed-header--gradient',
        dark && 'detailed-header--dark',
      )}
    >
      <div>
        {closeButton && (
          <button
            className={cx('close-button', dark && 'close-button--dark')}
            type="button"
            onClick={onClose}
            aria-label="close-button"
          >
            <MaterialIcon name="close" />
          </button>
        )}
        <PublicationHeader user={user} date={date} sideDate={false} dark={dark} />
      </div>
      <div className="buttons-wrapper">
        {onPrev && onNext && (
          <div className="navigation-button-container">
            <Button
              className="navigation-button mobile prev-button"
              onClick={onPrev}
              icon="angle left"
            />
            <Button
              className="navigation-button mobile next-button"
              onClick={onNext}
              icon="angle right"
            />
          </div>
        )}
        {actions?.length > 0 && (
          <QueryPopupButton
            icon="more_horiz"
            iconSize={30}
            content={({ onCancel }: any) => (
              <PublicationActions actions={actions} callback={onCancel} />
            )}
            popupClassName="actions-popup"
            panelClassName="actions-panel"
            modalProps={{
              draggable: false,
              steps: [15],
              isRounded: true,
            }}
          />
        )}
      </div>
    </div>
  );
};
