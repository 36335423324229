import PropTypes from 'prop-types';
import React from 'react';
import Media from 'react-media';

import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import './MenuFilter.scss';
import MenuFilters from './MenuFilters';
import MobileFilters from './MobileFilters';

const MenuFilter = React.memo((props) => (
  <Media queries={GLOBAL_MEDIA_QUERIES}>
    {(matches) => (
      <>
        {matches.mobile && <MobileFilters {...props} />}
        {matches.desktop && (
          <div>
            <MenuFilters {...props} />
          </div>
        )}
      </>
    )}
  </Media>
));

MenuFilter.defaultProps = {
  filterConfig: {},
  label: '',
};

MenuFilter.propTypes = {
  label: PropTypes.string,
  filterConfig: PropTypes.object,
  filterList: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MenuFilter;
