import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';

import SidebarBlocks from './SidebarBlocks';

const GridContainer = ({ sidebar, sidebarComponents, sharedProps, children }) => {
  const { position = 'left' } = sidebar;
  return (
    <Grid columns="equal" stackable>
      {position === 'left' && (
        <SidebarBlocks
          sidebar={sidebar}
          blockComponents={sidebarComponents}
          sharedProps={sharedProps}
        />
      )}
      <Grid.Column className="main">{children}</Grid.Column>
      {position === 'right' && (
        <SidebarBlocks
          sidebar={sidebar}
          blockComponents={sidebarComponents}
          sharedProps={sharedProps}
        />
      )}
    </Grid>
  );
};

GridContainer.defaultProps = {
  sidebar: {},
  sidebarComponents: {},
  sharedProps: {},
};

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.object,
  sidebarComponents: PropTypes.object,
  sharedProps: PropTypes.object,
};

export default GridContainer;
