import mapValues from 'lodash/mapValues';
import matches from 'lodash/matches';
import merge from 'lodash/merge';
import { matchPath } from 'react-router-dom';

import { replaceValues } from '../../utils/stringUtils';

function mapEvent(eventMapper, eventPayload) {
  const { tag, payloadMapping, condition } = eventMapper;
  if (condition && !matches(condition)(eventPayload)) {
    return null;
  }

  if (!payloadMapping) {
    return {
      tag: replaceValues(tag, eventPayload),
      payload: eventPayload,
    };
  }

  return {
    tag: replaceValues(tag, eventPayload),
    payload: mapValues(payloadMapping, (v) => replaceValues(v, eventPayload)),
  };
}

/**
 * Compute a {tag, payload} for a specific service from a generic event
 * @param {*} eventName
 * @param {*} payload
 * @param {*} trackerConfig
 */
export function generateEvent(eventName, payload, trackerConfig) {
  const eventMapper = trackerConfig[eventName];
  const defaultMapper = trackerConfig.default;
  // Is this event tracked ?
  if (!eventMapper) return undefined;

  // Found event to track !
  return mapEvent(merge(defaultMapper, eventMapper), payload);
}

const getMenuEntryPage = (m, pathname) => {
  if (m.children) {
    const child = m.children.find((c) => getMenuEntryPage(c, pathname));
    if (child && child.tc) {
      return child;
    }
    if (m.tc) {
      return m;
    }
  }
  if (m.path === pathname) {
    return m;
  }
  return undefined; // None found...
};

export function getCurrentPageDeprecated(menu, pathname) {
  // eslint-disable-next-line no-restricted-syntax
  for (const m of menu) {
    const currentPage = getMenuEntryPage(m, pathname);
    if (currentPage && currentPage.tc) {
      // TODO: shouldn't have tc hardcoded...
      return currentPage;
    }
  }
  return undefined;
}

export function splitPath(path) {
  if (!path) return path;
  const paths = path.split('/').filter((v) => v);
  return { parent: paths?.[0], sub: paths?.[1] };
}

export function getCurrentPage(screens, pathname) {
  // eslint-disable-next-line no-restricted-syntax
  for (const screen of Object.keys(screens)) {
    const currentPage = screens[screen];
    if (currentPage?.path === pathname) return { pageId: screen, page: currentPage }; // parent page
    if (currentPage.path?.includes('/:')) {
      // details page
      const match = matchPath(pathname, { path: currentPage.path, exact: true, strict: false });
      if (match) return { pageId: screen, page: currentPage };
    }
  }
  return undefined;
}

export function getCurrentModal(screens, modalConfig) {
  // eslint-disable-next-line no-restricted-syntax
  for (const screen of Object.keys(screens)) {
    const currentPage = screens[screen];
    if (
      currentPage.pageType === 'modal' &&
      currentPage.type === modalConfig.type &&
      currentPage.modalKey === modalConfig.modalKey
    ) {
      return currentPage;
    }
  }
  return undefined;
}

const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_';

function randomCharacter(string) {
  // eslint-disable-next-line no-bitwise
  return string[(Math.random() * string.length) | 0];
}

export function randomId(length) {
  let str = '';
  for (let i = 0; i < length; i++) {
    str += randomCharacter(characters);
  }
  return str;
}

export function uuid(length = 12) {
  return randomId(length);
}
