import { useMount } from 'ahooks';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { useConfig } from '../../config/config.context';
import {
  fetchAchievements,
  fetchUserAchievements,
} from '../../core/achievements/achievements.actions';
import { computeAchievement, notifyAchievement } from '../../core/achievements/achievements.utils';
import useEvent from '../../core/hooks/useEvent.hook';
import { useGamification } from '../../gamification/hooks/gamification.hook';
import { useMe } from '../../profile/hooks';
import { usePushService } from '../../pubsub/hooks/pubsub.hook';
import store from '../../shared/Store';
import './GamificationProvider.scss';

const EnabledGamification: FC = () => {
  const { gamification } = useConfig();
  const dispatch = useDispatch();
  const me = useMe();

  const { autoCloseNotification, websockets } = gamification || {};

  const refreshAchievements = useEvent(() => {
    dispatch(fetchAchievements());
    dispatch(fetchUserAchievements(me._id));
  });

  useMount(refreshAchievements);
  usePushService(websockets === 'firestore' ? undefined : store.userId);

  const onGamificationMessage = useEvent((type: string, message: any) => {
    // TODO: Better idea than refresh everything ?
    if (gamification.enabled) {
      const computedAchievemnt = computeAchievement(
        message?.payload?.achievement,
        message?.payload?.userAchievement,
      );
      notifyAchievement(computedAchievemnt);
      refreshAchievements();
    }
  });

  useGamification(onGamificationMessage);
  return (
    <>
      <ToastContainer
        className="badge-notification"
        position="bottom-right"
        autoClose={autoCloseNotification as any}
        pauseOnHover
        enableMultiContainer
        containerId="achievement-toast-container"
        newestOnTop
        closeOnClick
      />
    </>
  );
};

const GamificationProvider: FC = () => {
  const { gamification } = useConfig();
  if (!gamification?.enabled) return null;

  return <EnabledGamification />;
};

export { GamificationProvider };
