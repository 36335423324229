import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'semantic-ui-react';
import { bem } from '../../core/design/bem';
import './VerticalMenu.scss';
import ExpiringNavLink from '../ExpiringNavLink';

const cxMenu = bem('vertical-menu');
const cxMenuItem = bem(cxMenu('item').toString());

const VerticalMenu = (props) => {
  const { items, className, onChange } = props;
  if (items.length === 0) return null;

  const withValue = items.find((i) => i.value) !== undefined;
  return (
    <Menu vertical className={cx(cxMenu.toString(), className)}>
      {items.map(({ name, title, value, subtitle, to, exact }) => {
        return (
          <Menu.Item
            key={name}
            as={ExpiringNavLink}
            to={to}
            exact={exact}
            className={cxMenuItem()}
            name={name}
            onChange={() => onChange(name)}
          >
            <h1 className={cxMenuItem('value', { withValue })}>{value}</h1>
            <div className={cxMenuItem('content')}>
              <h3 className={cxMenuItem('title')}>{title}</h3>
              {subtitle && <p className={cxMenuItem('subtitle')}>{subtitle}</p>}
            </div>
            <div className={cxMenuItem('icon')}>
              <Icon name="chevron right" />
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

VerticalMenu.defaultProps = {
  className: '',
  items: [],
  onChange: noop,
};

VerticalMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  onChange: PropTypes.func,
};

export default VerticalMenu;
