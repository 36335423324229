import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTimeout } from 'ahooks';
import { CSSTransition } from 'react-transition-group';
import { Grid } from 'semantic-ui-react';
import { CmsPageLayout } from '../../../cms/CmsPage';
import './SplashScreen.scss';
import { GridColumn } from '../../../components/HeaderBlock/HeaderSlide';

function SplashScreen(props) {
  const { pageId, columns, header, metadata, design, animationDelay } = props;
  const [count, setCount] = useState(0);
  useTimeout(() => {
    if (!animationDelay) return;
    setCount(count + 1);
  }, animationDelay);
  return (
    <CmsPageLayout
      className={cx('page', 'page--splash-screen', pageId)}
      header={header}
      metadata={metadata}
      designOverride={design}
    >
      <Grid stackable widths="equal" verticalAlign="middle" style={{ margin: 0 }}>
        <Grid.Row style={{ padding: 0 }}>
          {columns.map((column, index) => (
            <CSSTransition
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              in={animationDelay ? count > 0 : true}
              appear
              exit
              timeout={{
                appear: 6000,
                enter: 6000,
                exit: 1000,
              }}
              classNames={cx('animation', `column--${index}`)}
              unmountOnExit
            >
              <GridColumn column={column} index={index} />
            </CSSTransition>
          ))}
        </Grid.Row>
      </Grid>
    </CmsPageLayout>
  );
}

SplashScreen.defaultProps = {
  animationDelay: undefined,
  columns: [],
  design: {},
  metadata: undefined,
  header: undefined,
};

SplashScreen.propTypes = {
  animationDelay: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  design: PropTypes.object,
  header: PropTypes.object,
  metadata: PropTypes.object,
  pageId: PropTypes.string.isRequired,
};

export default SplashScreen;
