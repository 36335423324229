/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { RouterParamFilter, SearchFilter, SimpleFilter } from '../../../../core/filter/components';
import { toggleFilter } from '../../../../core/filter/utils';

const FilterComponents = {
  // date: DateFilter,
  // datetime: DateTimeFilter,
  'router-param': (props) => <RouterParamFilter {...props} horizontal />,
  simple: (props) => <SimpleFilter {...props} horizontal />,
  search: SearchFilter,
  // 'select-entity': SelectEntity,
};

const MenuFilters = (props) => {
  const { fieldsToFilter, filterList, filters, filterConfig, onChange } = props;

  const handleSelect = useCallback(
    (filter, value) => {
      onChange(toggleFilter(filters, filter.dataKey, value));
    },
    [filters, onChange],
  );

  return (
    <>
      {filterList.map((filter) => {
        if (filter.type && !(filter.type in FilterComponents)) {
          // eslint-disable-next-line no-console
          console.error('Unknown filter type', filter.type);
          return null;
        }
        const Component = FilterComponents[filter.type];
        if (!Component) {
          console.error('Failed to find component for', filter.type);
          return null;
        }
        return (
          <Component
            key={filter.dataKey}
            filter={filter}
            value={filters[filter.dataKey]}
            onSelect={handleSelect}
          />
        );
      })}
    </>
  );
};

export default MenuFilters;
