import get from 'lodash/get';
import React, { FC } from 'react';

import BlockContainer from '../../components/BlockContainer';
import VideoBlock from '../../components/cms/VideoBlock';

type ItemVideoBlockProps = {
  item: { _id: string } & Record<string, any>;
  field?: string;
  container?: {
    header?: string;
    type: string;
  };
};

const ItemVideoBlock: FC<ItemVideoBlockProps> = ({ item, field = 'video', container }) => {
  const video = get(item, field);
  if (!video) return null;

  return (
    <BlockContainer className="block--item-video" {...container}>
      <VideoBlock _id={video._id} video={video} />
    </BlockContainer>
  );
};

export default ItemVideoBlock;
