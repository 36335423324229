import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, SemanticCOLORS } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { useMe } from '../../hooks';
import './AppointmentTitle.scss';

const css = bem('appointment-title');

// FIXME: real type
type Appointment = Record<string, any>;
type AppointmentTitleProps = {
  event: Appointment;
};

const computeName = (user: any) => {
  if (user.name) {
    return user.name.trim();
  }

  return `${user.lastName || ''} ${user.firstName || ''}`.trim();
};

const getLabelInfo = (users: any) => {
  const hasPendingUsers = users.some(({ status }: { status: string }) => status === 'pending');
  if (hasPendingUsers) {
    return {
      labelKey: 'appointments.slots.meeting-pending',
      color: 'orange' as SemanticCOLORS,
    };
  }
  return {
    labelKey: 'appointments.slots.meeting-accepted',
    color: 'green' as SemanticCOLORS,
  };
};

const AppointmentTitle: FC<AppointmentTitleProps> = ({ event }) => {
  const me = useMe();
  const { t } = useTranslation();
  const { owner, users, group } = event;
  let context;

  const { labelKey, color } = getLabelInfo(users);

  if (owner._id === me._id) {
    const invitee = users?.filter((u: any) => u.userId !== me._id)?.[0];
    const name = computeName(invitee);
    context = {
      name,
      owner,
      entity: { ...invitee, name },
      appointment: event,
    };
  } else {
    const name = computeName(owner);
    context = {
      owner,
      name: group?.name ?? name,
      entity: { ...(group ?? owner), name },
      appointment: event,
    };
  }

  return (
    <div className={css()}>
      {t('appointments.slots.meeting-with', context)}
      <Label basic className="borderless" color={color}>
        {t(labelKey)}
      </Label>
    </div>
  );
};

export { AppointmentTitle };
