/* eslint-disable @typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { useLoginPath, useScreenConfig } from '../../../config/screens.context';
import useTranslations from '../../../hooks/useTranslations';
import store from '../../../shared/Store';
import { sweetAlert } from '../../../utils/popupUtils';
import ProfileActionButton from '../../components/ProfileActionButton';
import ResetPasswordModal from '../../components/ResetPasswordModal';
import UserProfileDetail from '../../components/UserProfileDetail/UserProfileDetail';
import UserProfileGrid from '../../components/UserProfileGrid/UserProfileGrid';

const translationPrefix = 'profile';
const expandField = (field) => (typeof field === 'string' ? { key: field, type: 'string' } : field);
const editingActions = [
  { id: 'updateProfile', position: 'right' },
  { id: 'cancelEditProfile', position: 'right' },
];

const components = {
  simple: UserProfileDetail,
  grid: UserProfileGrid,
};

const UserProfile = (props) => {
  const {
    user,
    fields,
    actions,
    variant,
    profileSharing,
    qrcode,
    file,
    avatarConfig,
    pageId,
  } = props;
  const [userPatch, setUserPatch] = useState({});
  const [isEditing, setEditing] = useState(false);
  const [, setLogout] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { t } = useTranslations();
  const { enableNetworking = false, showResetPasswordButton = true, enableChangeAvatar = true } =
    useScreenConfig(pageId) || {};
  const loginPath = useLoginPath();

  const updateUser = async (u) => {
    try {
      await store.updateUser(u);

      setEditing(false);
      await sweetAlert({
        text: t(`${translationPrefix}.confirm.update-success`),
        icon: 'success',
      });
    } catch (e) {
      await sweetAlert({
        text: t(`${translationPrefix}.errors.update-profile`),
        icon: 'error',
      });
    }
  };

  const updateEditing = (editing) => {
    setEditing(editing);
    if (!editing) {
      setUserPatch(user);
    }
  };

  const handleButtonClick = (id) => {
    switch (id) {
      case 'updatePassword': {
        setShowPasswordModal(true);
        return;
      }
      case 'logout': {
        store.disconnect();
        setLogout(true);
        window.location.reload();
        return;
      }
      case 'updateProfile': {
        updateUser(userPatch);
        return;
      }
      case 'agendaPdf': {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
          document.execCommand('print', false, null);
        } else window.print();

        return;
      }
      case 'editProfile': {
        updateEditing(true);
        return;
      }
      case 'cancelEditProfile': {
        updateEditing(false);
        return;
      }
      default: {
        // eslint-disable-next-line no-console
        console.warn('Unknown action', id);
      }
    }
  };

  const patchedUser = { ...user, ...userPatch };
  const isButtonDisabled = (id) => {
    switch (id) {
      case 'updateProfile':
        return fields.map(expandField).some((f) => f.editable && f.required && !patchedUser[f.key]);
      default:
        return false;
    }
  };

  const getDisplayedActions = () => {
    if (actions?.length && isEditing && actions.some((a) => a.id === 'editProfile')) {
      return [...actions, ...editingActions];
    }

    return actions;
  };

  const onUserFieldChange = (value) => {
    setUserPatch({ ...userPatch, ...value });
  };

  const isActionButtonShown = (action) =>
    (['cancelEditProfile', 'updateProfile'].includes(action.id) && isEditing) ||
    (!['cancelEditProfile', 'updateProfile'].includes(action.id) && !isEditing);

  if (store.mustRedirectToLogin()) {
    // Handle disconnect
    return <Redirect to={loginPath} />;
  }

  const Component = components[variant] || UserProfileDetail;
  return (
    <>
      <Component
        user={{ ...user, ...userPatch }}
        fields={fields}
        editing={isEditing}
        avatarConfig={avatarConfig}
        enableNetworking={enableNetworking}
        enableAvatar={avatarConfig?.enabled ?? enableChangeAvatar}
        onFieldChange={onUserFieldChange}
        onUpdateUser={updateUser}
        qrcode={qrcode}
        file={file}
        showResetPasswordButton={showResetPasswordButton}
        actions={getDisplayedActions()
          .filter(isActionButtonShown)
          .map((action) => (
            <ProfileActionButton
              {...action}
              key={action.key || action._id || action.id}
              id={action.id}
              position={action.position}
              primary={action.id === 'updateProfile'}
              disabled={isButtonDisabled(action.id)}
              onClick={action.to ? undefined : () => handleButtonClick(action.id)}
            />
          ))}
        profileSharing={profileSharing}
      />

      {showPasswordModal && <ResetPasswordModal onClose={() => setShowPasswordModal(false)} />}
    </>
  );
};

UserProfile.defaultProps = {
  actions: [],
  avatarConfig: undefined,
  fields: ['firstName', 'lastName', 'email'],
  file: undefined,
  variant: 'simple',
  qrcode: undefined,
  profileSharing: undefined,
  pageId: 'profile',
};

UserProfile.propTypes = {
  avatarConfig: PropTypes.shape({
    enabled: PropTypes.bool,
    enableChange: PropTypes.bool,
    showPlaceholder: PropTypes.bool,
    placeholderTemplate: PropTypes.string,
  }),
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string,
        type: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
  file: PropTypes.object,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    networking: PropTypes.bool,
    rewards: PropTypes.object,
    thumbnail: PropTypes.object,
  }).isRequired,
  qrcode: PropTypes.shape({
    enabled: PropTypes.bool,
    uri: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  pageId: PropTypes.string,
  variant: PropTypes.oneOf(['simple', 'grid']),
  profileSharing: PropTypes.object,
};

export default UserProfile;
