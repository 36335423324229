import cx from 'classnames';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Header } from 'semantic-ui-react';

import { upcomingEvents } from '../../components/DayCalendar';
import DaysContainer from '../../components/DaysContainer';
import WorkshopGridItem from '../../components/workshops/workshop-templates/grid-item/WorkshopGridItem';
import WorkshopImageItem from '../../components/workshops/workshop-templates/image-item/WorkshopImageItem';
import WorkshopLightItem from '../../components/workshops/workshop-templates/light-item/WorkshopLightItem';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { workshopArrayProptypes } from '../../propTypes';
import { groupEventsByDays } from '../../utils/agendaUtils';
import { isFinished } from '../../utils/dateUtils';
import { useOrderBy } from '../../utils/hooks';
import { useCloudTvSessions } from './CloudTvBlock/cloudtv.hooks';

const templates = {
  light: WorkshopLightItem,
  image: WorkshopImageItem,
  grid: WorkshopGridItem,
};

const defaultOrder = [
  { field: 'startDate', order: 'asc' },
  { field: 'order', order: 'asc' },
];

const WorkshopsContainer = ({ template, itemsPerRow, children, style }) => {
  const isGrid = template === 'grid';
  const className = cx('WorkshopsRegistrationBlock', template);
  if (isGrid) {
    return (
      <Card.Group className={className} itemsPerRow={itemsPerRow} stackable centered style={style}>
        {children}
      </Card.Group>
    );
  }
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

WorkshopsContainer.defaultProps = {
  itemsPerRow: 3,
  style: {},
};

WorkshopsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  itemsPerRow: PropTypes.number,
  style: PropTypes.object,
  template: PropTypes.string.isRequired,
};

function getWorkshops(config = {}, workshops, now) {
  const { showOnlyUpcomingEvents, upcomingEventsCount = 3 } = config;
  if (!showOnlyUpcomingEvents) return workshops;
  return upcomingEvents(workshops, now).slice(0, upcomingEventsCount);
}

function WorkshopsWithRegistrationBlock(props) {
  // TO KNOW: config replaced by itemPros to follow general items config
  //  At this time only sephora and capgemini have used this block so changes should be ok everywhere
  const { itemProps: config, collection, template, itemsPerRow, workshopList, groupBy } = props;
  const { t } = useTranslation();
  const { showPastBelowOthers } = groupBy || {};
  let workshops = useCloudTvSessions({ mode: 'workshops' });
  const { groupByDays = true, orderBy: orderByConfig = defaultOrder } = config || {};

  const Component = templates[template] ?? templates.image;
  workshops = useMemo(() => {
    if (collection) {
      return workshops.filter((ws) => ws.collection === collection);
    }
    return workshops;
  }, [workshops, collection]);
  const orderedWorkshops = useOrderBy(workshops, orderByConfig);
  const now = useAutoRefresh(5000);
  const finalWorkshops = getWorkshops(config, orderedWorkshops, now);

  const { days, eventsByDay } = groupEventsByDays(workshopList || finalWorkshops);

  if (groupByDays) {
    return (
      <div className="WorkshopsWithRegistrationBlock" style={{ padding: '20px 10px' }}>
        {days.map((day) => {
          const dayEvents = eventsByDay[day] || [];
          return (
            <div key={day}>
              <DaysContainer days={[day]} />
              <WorkshopsContainer
                itemsPerRow={itemsPerRow}
                template={template}
                style={{ marginBottom: 10 }}
              >
                {dayEvents.map((workshop) => (
                  <Component key={workshop._id} workshop={workshop} config={config} />
                ))}
              </WorkshopsContainer>
            </div>
          );
        })}
      </div>
    );
  }

  function renderWorkshops(header, innerWorkshops) {
    if (!innerWorkshops?.length) return null;

    return (
      <>
        {header && <Header as="h3">{header}</Header>}
        <WorkshopsContainer
          className="WorkshopsWithRegistrationBlock"
          template={template}
          itemsPerRow={itemsPerRow}
          style={{ padding: '20px 10px' }}
        >
          {innerWorkshops.map((workshop) => (
            <Component key={workshop._id} workshop={workshop} config={config} />
          ))}
        </WorkshopsContainer>
      </>
    );
  }

  const workhopsToDisplay = workshopList || finalWorkshops;

  if (showPastBelowOthers) {
    // TODO: memoize ?
    const [finished, upcoming] = partition(workhopsToDisplay, isFinished);
    return (
      <>
        {renderWorkshops('', upcoming)}
        {renderWorkshops(t('workshops.group.previous-content'), finished)}
      </>
    );
  }

  return renderWorkshops('', workhopsToDisplay);
}

WorkshopsWithRegistrationBlock.defaultProps = {
  collection: undefined,
  itemProps: {},
  itemsPerRow: 3,
  template: 'image',
  workshopList: undefined,
};

WorkshopsWithRegistrationBlock.propTypes = {
  collection: PropTypes.string,
  itemProps: PropTypes.object,
  itemsPerRow: PropTypes.number,
  template: PropTypes.oneOf(['light', 'image', 'grid']),
  workshopList: workshopArrayProptypes,
};

export default WorkshopsWithRegistrationBlock;
