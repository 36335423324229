/* eslint-disable operator-linebreak */
import groupBy from 'lodash/groupBy';

import { UserGroup } from '../../core/services/notifications.service';
import { UserType } from '../../core/users/users.types';
import { ConditionContext } from '../../utils/conditionUtils';

export function generateConditionForGroups(
  users: UserType[],
  groups: UserGroup[],
): ConditionContext {
  const groupsByKey = groupBy(groups, 'userKey');
  const groupFilters = Object.entries(groupsByKey).map(([key, value]) => {
    const { fieldType } = value[0];
    switch (fieldType) {
      case 'multiselect':
        return { key, op: 'hasAnyOf', value: value.map((v) => v.userValue) };
      case 'select':
      default:
        return { key, op: 'isAnyOf', value: value.map((v) => v.userValue) };
    }
  }) as ConditionContext[];

  if (users.length > 0) {
    groupFilters.push({
      key: '_id',
      op: 'isAnyOf',
      value: users.map((u) => u._id),
    } as ConditionContext);
  }
  return {
    op: 'or',
    expressions: groupFilters,
  };
}
