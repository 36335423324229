import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReplayModal from '../../../video/ReplayModal';
import { getString } from '../../../../utils';

type ReplayModalButtonProps = {
  workshop: any;
};

const translationPrefix = 'workshops.workshop';
const ReplayModalButton: FC<ReplayModalButtonProps> = (props) => {
  const { workshop } = props;
  const hasReplay =
    workshop.replays && workshop.replays.length > 0 && workshop.replays[0].value?.uri;
  if (!hasReplay) {
    return null;
  }

  return (
    <ReplayModal
      item={workshop}
      replay={workshop.replays[0]}
      trigger={
        <Button className="replay-button">{getString(`${translationPrefix}.replay`)}</Button>
      }
    />
  );
};

ReplayModalButton.propTypes = {
  workshop: PropTypes.object.isRequired,
};

export { ReplayModalButton };
