/*eslint-disable max-len*/
import { UserType } from '../../core/users/users.types';
import store from '../../shared/Store';
import { replaceValues } from '../../utils/stringUtils';

const { endpoints } = window.__DATA__;

export function getUserBadgeUri(badge: Record<string, any>, user: UserType): string {
  const badgeUrl = `${endpoints.document}/${store.eventId}/badges/users/${user.collection}/${user.privateKey}/badge.pdf`;
  if (badge?.templateUrl) {
    return replaceValues(badge.templateUrl, { url: badgeUrl, user });
  }
  return badgeUrl;
}

export function getUserTemplateDocument(documentTemplateId: string, user: UserType): string | null {
  if (!documentTemplateId) return null;
  return `${endpoints.document}/${store.eventId}/documents/generate-pdf/files/${documentTemplateId}/users/${user.privateKey}`;
}
