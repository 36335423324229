import React, { FC } from 'react';
import { Segment } from 'semantic-ui-react';
import { WorkshopActions } from '../../components/workshops/workshop-templates/actions/WorkshopActions';
import { WorkshopAction } from '../../components/workshops/workshop-templates/actions/action.type';

type WorkshopActionsBlockProps = {
  item: any;
  actions: WorkshopAction[];
};

const WorkshopActionsBlock: FC<WorkshopActionsBlockProps> = (props) => {
  const { item, actions } = props;
  return (
    <Segment className="WorkshopActionsBlock">
      <WorkshopActions workshop={item} actions={actions} />
    </Segment>
  );
};

WorkshopActionsBlock.defaultProps = {};

export default WorkshopActionsBlock;
