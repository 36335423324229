import React from 'react';
import get from 'lodash/get';
import { Step } from './types';
import { useStepContext } from '../../steps/StepContext';
import StepOptions from './components/StepOptions';
import StepSlot from './components/StepSlot';
import StepConfirm from './components/StepConfirm';
import StepPrepare from './components/StepPrepare';

const stepList = {
  'select-options': StepOptions,
  'select-slot': StepSlot,
  'confirm': StepConfirm,
  'prepare': StepPrepare,
};

const Sidebar = (): JSX.Element | null => {
  const {
    appointmentUserRef,
    currentStep,
    currentAppointment,
    freeTimeSlots,
    onCancel,
    onSelectStep,
    onUpdate,
    selectedSlot,
    setStep,
    // @Patrick help ^^'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    steps = [],
  } = useStepContext();

  if (steps?.length === 0) return null;

  const isFinished = currentStep === 'prepare';

  return steps.map((step: Step, index: number) => {
    const { type } = step;

    const Component = get(stepList, type);
    const isActive = currentStep === type;

    return (
      <Component
        key={type}
        active={isActive}
        appointmentUserRef={appointmentUserRef}
        currentAppointment={currentAppointment}
        finish={isFinished}
        freeTimeSlots={freeTimeSlots}
        onCancel={onCancel}
        setStep={setStep}
        onSelectStep={onSelectStep}
        onUpdate={onUpdate}
        orderNumber={index + 1}
        selectedSlot={selectedSlot}
      />
    );
  });
};

export default Sidebar;
