import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Header } from 'semantic-ui-react';

function BlockHeader({ className, title, headerLevel, style }) {
  return (
    <Header as={headerLevel} className={cx('blocks--header', className)} style={style}>
      {title}
    </Header>
  );
}

BlockHeader.defaultProps = {
  className: '',
  headerLevel: 'h3',
  style: {},
  title: '',
};

BlockHeader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  headerLevel: PropTypes.string,
};

export default BlockHeader;
