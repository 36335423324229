import cx from 'classnames';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { Button } from 'semantic-ui-react';

import { bem } from '../../core/design/bem';
import { eventHandler } from '../../utils/event.util';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import { useFormattedEmotes } from '../hooks/useEmotes';
import { useSocialWallConfig } from '../social-wall-config.context';
import './EmoteList.scss';
import { EmotePicker } from './EmotePicker/EmotePicker';

interface EmoteListProp {
  className?: string;
  interactions: Record<string, number>;
  userInteractions?: string[];
  disabled?: boolean;
  display?: {
    defaultEmotes?: boolean;
    allEmotes?: boolean;
  };
  onEmoteClick?: (emote: string) => Promise<void>;
}
const css = bem('emote-list');

export const EmoteList: FC<EmoteListProp> = (props: EmoteListProp) => {
  const {
    className,
    interactions,
    userInteractions,
    disabled = false,
    display = { defaultEmotes: true, allEmotes: true },
    onEmoteClick,
  } = props;

  const [seeAll, setSeeAll] = useState(false);

  const { t } = useTranslation();
  const { defaultInteractions, interactionsEnabled } = useSocialWallConfig();
  const mobile = useMedia(GLOBAL_MEDIA_QUERIES.mobile);
  const NB_MAX_REACTIONS = mobile ? 3 : 6;

  const { formattedInteractions } = useFormattedEmotes(interactions, defaultInteractions, {
    displayAllEmotes: display?.allEmotes,
    displayDefaultEmotes: display?.defaultEmotes,
    maxDisplayed: NB_MAX_REACTIONS,
  });

  const displayedInteractions = useMemo(
    () => (seeAll ? formattedInteractions : formattedInteractions.slice(0, NB_MAX_REACTIONS)),
    [formattedInteractions, seeAll, NB_MAX_REACTIONS],
  );

  const hasSeeMore = formattedInteractions.length > NB_MAX_REACTIONS;

  const isEmoteActive = (emote: string) => userInteractions?.includes(emote);

  const hasActiveInSeeMore = formattedInteractions.slice(NB_MAX_REACTIONS).some(isEmoteActive);

  const handleEmoteClick = (emote: string) => {
    if (onEmoteClick) onEmoteClick(emote);
  };

  const buttonLabel = !seeAll
    ? t('social-wall.see-more', {
        count: formattedInteractions.length - NB_MAX_REACTIONS,
      })
    : t('social-wall.see-less');

  if (!interactionsEnabled) return null;
  return (
    <div className={cx(css(), className)}>
      {displayedInteractions.map((emote) => {
        const count = interactions?.[emote] || 0;

        return (
          <Button
            key={emote}
            className={cx('reaction', isEmoteActive(emote) && 'active')}
            disabled={disabled}
            onClick={(e) => eventHandler(e, () => handleEmoteClick(emote))}
          >
            <span>{emote} </span>
            <span className={css('count')}>{count > 99 ? '99+' : count}</span>
          </Button>
        );
      })}
      {hasSeeMore && (
        <Button
          onClick={(e) => eventHandler(e, () => setSeeAll(!seeAll))}
          className={cx('reaction', hasActiveInSeeMore && !seeAll && 'active')}
        >
          <span className="reaction-see-more-label">{buttonLabel}</span>
        </Button>
      )}
      {!disabled && <EmotePicker onSelect={handleEmoteClick} />}
    </div>
  );
};
