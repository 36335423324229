import React from 'react';
import { Modal } from 'semantic-ui-react';

import meetingRoomService, { VisioSession } from '../../core/services/meetingRoom.service';
import { useMe } from '../../profile/hooks';
import './VideoChatModal.scss';

export type VideoChatModalProps = {
  visio: VisioSession;
  onCancel: () => void;
};

const VideoChatModal = ({ visio, onCancel }: VideoChatModalProps): JSX.Element => {
  const me = useMe();

  return (
    <Modal
      open
      className="modal--videoChatModal"
      basic
      closeOnEscape={false}
      closeIcon
      onClose={onCancel}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <iframe
          title="visio"
          allow="fullscreen *;camera *;microphone *;display-capture *"
          src={meetingRoomService.getVisioRoomUrl(visio, me)}
        />
      </Modal.Content>
    </Modal>
  );
};

export default VideoChatModal;
