import React, { FC, MouseEventHandler } from 'react';
import { Loader } from 'semantic-ui-react';

import { eventHandler } from '../../../utils/event.util';
import './PublicationPanelHeader.scss';

interface PublicationPanelHeaderProps {
  loading?: boolean;
  onPublication: MouseEventHandler<HTMLButtonElement>;
  onClose: () => void;
  disablePublishButton?: boolean;
  label?: string;
  button: {
    label: string;
    action: () => any;
  };
}

export const PublicationPanelHeader: FC<PublicationPanelHeaderProps> = ({
  onClose,
  disablePublishButton,
  loading = false,
  label,
  button,
}) => {
  return (
    <div className="publication-panel-header">
      <div className="close-button-container">
        <button
          className="close-button"
          type="button"
          onClick={onClose}
          aria-label="close-button"
        />
      </div>
      <span>{label}</span>
      <div className="publish-button-container">
        {button && (
          <button
            className="publish-button"
            type="button"
            disabled={loading || disablePublishButton}
            onClick={(ev) => eventHandler(ev, button.action)}
          >
            {loading ? <Loader active inline size="tiny" /> : button.label}
          </button>
        )}
      </div>
    </div>
  );
};
