/* eslint-disable react/prop-types */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';
import { Card, Container, Header } from 'semantic-ui-react';

import { CmsPageLayout } from '../cms/CmsPage';
import { CmsBlocks } from '../cms/CmsScreen';
import NotificationRequestButton from '../components/NotificationRequestButton/NotificationRequestButton';
import { bem } from '../core/design/bem';
import { useNotifications } from '../hooks/useNotifications';
import { useIsAdmin } from '../profile/hooks';
import store from '../shared/Store';
import './NotificationsScreen.scss';
import Notification from './components/Notification';
import SendNotificationButton from './components/SendNotificationButton';

const css = bem('page');

type NotificationsScreenProps = { [x: string]: Record<string, any> };

const NotificationsScreen: FC<NotificationsScreenProps> = (props: NotificationsScreenProps) => {
  const { header, footer, metadata, design, pageHeader, pageFooter } = props;
  const isAdmin = useIsAdmin();
  const { notifications = [] } = useNotifications();
  const { t } = useTranslation();

  useUnmount(() => {
    const now = new Date().toISOString();
    store.updateUser({ 'dates.notificationsReadAt': now });
  });

  return (
    <CmsPageLayout
      className={css({
        notifications: true,
      }).toString()}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        {header && <CmsBlocks blocks={header.blocks} />}
        <div className={css('container')}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Header as="h2" style={{ marginBottom: 0, color: 'white' }}>
              {t('notifications.title')}
            </Header>
          </div>
          <NotificationRequestButton />

          <Card.Group
            style={{
              height: '100%',
              margin: 0,
            }}
          >
            {notifications.map((notification: any) => (
              <Notification {...notification} />
            ))}
          </Card.Group>
        </div>
        {footer && <CmsBlocks blocks={footer.blocks} />}
        {isAdmin && <SendNotificationButton />}
      </Container>
    </CmsPageLayout>
  );
};

export default NotificationsScreen;
