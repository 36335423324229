import NoBadge from './badges/no_badge.png';
import Badge1 from './badges/badge_1.png';
import Badge2 from './badges/badge_2.png';
import Badge3 from './badges/badge_3.png';
import Badge4 from './badges/badge_4.png';

const badgeList = {
  NoBadge,
  Badge1,
  Badge2,
  Badge3,
  Badge4,
};

export default badgeList;