import React from 'react';
import { Header } from 'semantic-ui-react';
import AccessMeetingRoomBlock from '../../sponsors/blocks/BookAppointmentBlock/AccessMeetingRoomBlock';
import { getString } from '../../utils';
import { localeFormat } from '../../utils/date';
import { useStepContext } from './StepContext';

const translationPrefix = 'appointments.steps.prepare';
const t = (key, data) => getString(`${translationPrefix}.${key}`, data);

export const StepPrepareContent = () => {
  const { currentAppointment } = useStepContext();
  if (!currentAppointment) return null;
  return (
    <div>
      <Header as="h3">
        {t('header', { date: localeFormat(currentAppointment.startDate, 'PPPPp') })}
      </Header>
      <p>{t('subheader')}</p>
      <AccessMeetingRoomBlock appointment={currentAppointment} />
    </div>
  );
};
