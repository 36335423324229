import React from 'react';
import { useEvent } from 'react-use';
import { Header, Modal } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import store from '../../../shared/Store';

const css = bem('FeedbackModal');

type FeedbackModalProps = {
  className?: string;
  autoClose?: boolean;
  feedbackUrl?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: Record<string, any>;
  onClose: () => void;
};

const FeedbackModalDefaultProps = {
  className: '',
  autoClose: false,
  feedbackUrl: null,
};

function injectUserIfNeeded(url: string, userId: string): string {
  if (!url) return url;

  if (url.indexOf('userId=') !== -1) return url;

  return `${url}&userId=${userId}`;
}

export const FeedbackModal = ({
  item,
  autoClose,
  feedbackUrl,
  className,
  onClose,
}: FeedbackModalProps): JSX.Element | null => {
  useEvent('message', (evt: MessageEvent<{ type: string; payload: any }>) => {
    if (!evt.data) return;
    const { type, payload } = evt.data;
    if (type === 'feedback:step') {
      if (payload?.step === 'thanks' && autoClose) {
        onClose();
      }
    }
  });

  if (!item || !feedbackUrl) return null;
  return (
    <Modal
      className={css({}).mix(className).toString()}
      open
      basic
      header={item.title}
      closeIcon
      onClose={onClose}
      style={{ maxWidth: 600 }}
    >
      <Modal.Content
        className={css('container').toString()}
        style={{ height: '85vh', padding: '40px 50px' }}
      >
        {item.title && (
          <Header
            as="h4"
            className={css('title').toString()}
            style={{ color: 'white', marginBottom: 6 }}
          >
            {item.title}
          </Header>
        )}
        <iframe
          title="Feedback"
          allowTransparency
          src={injectUserIfNeeded(feedbackUrl, store.userId)}
          style={{ border: 0, height: '100%', width: '100%' }}
        />
      </Modal.Content>
    </Modal>
  );
};

FeedbackModal.defaultProps = FeedbackModalDefaultProps;

export default FeedbackModal;
