import React from 'react';
import { Icon, List } from 'semantic-ui-react';

import CdnImage from '../../components/CdnImage';
import DropzoneFile from '../../components/DropzoneFile';
import { bem } from '../../core/design/bem';
import useTranslations from '../../hooks/useTranslations';

const css = bem('UploadFileBlock');
const translationPrefix = 'profile.components.upload-file-block';

export type Image = {
  id: number;
  _id: string;
  mimeType: string;
  originalFilename: string;
  size: number;
  uri: string;
};

type UploadFileBlockProps = {
  fieldKey: string;
  value: Image[];
  onChange: (key: Record<string, Image[]>) => void;
};

const UploadFileBlock = (props: UploadFileBlockProps): JSX.Element => {
  const { onChange, value, fieldKey } = props;
  const { t } = useTranslations();
  const width = 30;

  return (
    <div className={css()}>
      <List divided verticalAlign="middle">
        {value?.map((file: Image, index: number) => {
          const { uri, originalFilename } = file;

          return (
            <List.Item style={{ height: '3em', lineHeight: '2.2em' }}>
              {!!onChange && (
                <List.Content floated="right" style={{ lineHeight: '2.2em' }}>
                  <Icon
                    color="grey"
                    name="trash"
                    size="small"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const array = [...value];
                      array.splice(index, 1);
                      onChange({ [fieldKey]: array });
                    }}
                  />
                </List.Content>
              )}
              <CdnImage
                src={uri}
                maxWidth={width}
                maxHeight={Math.round((width / 21) * 29.7)}
                cdnOptions={{ bg_colour: 'fafafa' }}
              />
              <List.Content>{originalFilename}</List.Content>
            </List.Item>
          );
        })}
      </List>

      {!!onChange && (
        <DropzoneFile
          name="file"
          placeholder={t(`${translationPrefix}.add-file`)} //todo correct translation
          onAdd={(_name: string, file: Image) => onChange({ [fieldKey]: [...(value || []), file] })}
          style={{ height: 100, minWidth: '100%' }}
          multiple
        />
      )}
    </div>
  );
};

export default UploadFileBlock;
