import React from 'react';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import { Label } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import {
  allChatsList,
  invitesByStatus,
  invitesThatIDidntTreat,
} from '../store/networking.selectors';
import { getUserUnreadCount } from '../store/networking.helpers';

export const NetworkMessagesLabel = (props) => {
  const user = useSelector((state) => state.user?.user);
  const chats = useSelector(allChatsList);
  const pending = useSelector(invitesThatIDidntTreat);

  if (!user?.networking) return null;

  const unreadCount = sumBy(chats, getUserUnreadCount);
  const totalCount = pending.length + unreadCount;
  if (totalCount === 0) return null;
  return <Label {...props}>{totalCount}</Label>;
};

export const NetworkInvitesLabel = ({ type, status, ...props }) => {
  const allInvites = useSelector(invitesByStatus);
  const invites = allInvites[type]?.[status] || [];
  if (invites.length === 0) return null;
  return <Label {...props}>{invites.length}</Label>;
};

NetworkInvitesLabel.defaultProps = {
  type: 'received',
  status: 'invited',
};

NetworkInvitesLabel.propTypes = {
  type: PropTypes.oneOf(['received', 'sent']),
  status: PropTypes.string,
};
