import React from 'react';
import get from 'lodash/get';
import { Button, Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { bem } from '../../core/design/bem';

const css = bem('StandBlock');

type StandBlockType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: Record<string, any>;
  field?: string;
  target?: string;
};

const StandBlock = (props: StandBlockType): JSX.Element | null => {
  const { item, field, target } = props;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const standNumber = get(item, field!);
  if (!standNumber || !target) return null;
  return (
    <div className={css()}>
      <Button primary fluid as={NavLink} to={`${target}?locationId=${standNumber}`}>
        <Icon name="map marker alternate" /> {standNumber}
      </Button>
    </div>
  );
};

StandBlock.defaultProps = {
  field: 'locationId',
  target: undefined,
};

export default StandBlock;
