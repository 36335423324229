/* eslint-disable operator-linebreak */

/* eslint-disable import/no-cycle */

/* eslint-disable class-methods-use-this */
import { Exception } from 'handlebars';
import omitBy from 'lodash/fp/omitBy';
import throttle from 'lodash/throttle';
import encode from 'querystring/encode';
import wretch from 'wretch';

import store from '../../shared/Store';
import { getTimeZone } from '../../utils/date';
import { getJson, postJson } from '../../utils/fetch';

const HTTP_STATUS_UNAUTHORIZED = 401;
const { endpoints } = window.__DATA__;

class PlatformService {
  statusHooks = {
    [HTTP_STATUS_UNAUTHORIZED]: (result) => {
      if (result?.code === 'E_AUTH_EXPIRED') {
        store.disconnect('auth_expired');
      }

      return result;
    },
  };

  api() {
    return wretch().url(window.__DATA__.endpoints.platform).auth(`JWT ${store.token}`);
  }

  async secureGet(url, customHooks) {
    return getJson(url, {
      status: { ...this.statusHooks, ...(customHooks || {}) },
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
  }

  async securePost(url, json) {
    return postJson(url, json, {
      status: this.statusHooks,
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
  }

  async securePatch(url, json) {
    return getJson(url, {
      method: 'PATCH',
      status: this.statusHooks,
      headers: {
        'Authorization': `JWT ${store.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    });
  }

  async secureDelete(url) {
    return getJson(url, {
      method: 'DELETE',
      status: this.statusHooks,
      headers: {
        Authorization: `JWT ${store.token}`,
      },
    });
  }

  async fetchUser() {
    if (store.user?.collection === 'anonymous') {
      return store.user; // Return...
    }
    return this.secureGet(`${endpoints.platform}/users/${store.userId}`);
  }

  async patchUser(payload) {
    return this.securePatch(`${endpoints.platform}/users/${store.userId}`, payload);
  }

  async updatePassword(newPassword, previousPassword) {
    return this.securePost(`${endpoints.platform}/users/${store.userId}/password`, {
      password: newPassword,
      previousPassword,
    });
  }

  async fetchData() {
    return this.secureGet(`${endpoints.platform}/data`);
  }

  async fetchWorkshops() {
    return this.secureGet(`${endpoints.platform}/workshops`);
  }

  async fetchWorkshop(workshopId, options = {}, collection = 'workshops') {
    return this.secureGet(
      `${endpoints.platform}/workshops/collections/${collection}/${workshopId}?${encode(options)}`,
    );
  }

  async fetchSponsors() {
    return this.secureGet(`${endpoints.platform}/sponsors`);
  }

  /**
   * @deprecated
   */
  async fetchSponsor(sponsorId, options = {}) {
    return this.secureGet(`${endpoints.platform}/sponsors/${sponsorId}?${encode(options)}`);
  }

  async fetchPrograms() {
    return this.secureGet(`${endpoints.platform}/programs`);
  }

  /**
   * @deprecated
   */
  async fetchProgram(programId, options = {}) {
    return this.secureGet(`${endpoints.platform}/programs/${programId}?${encode(options)}`);
  }

  async fetchSpeakers() {
    return this.secureGet(`${endpoints.platform}/speakers`);
  }

  async fetchUsers(collection) {
    switch (collection) {
      case 'speakers':
        return this.fetchSpeakers();
      case 'participants':
        return this.fetchParticipants();
      default:
        return this.secureGet(`${endpoints.platform}/users/collections/${collection}`);
    }
  }

  async fetchContributor(collection, userId, options = {}) {
    return this.secureGet(
      `${endpoints.platform}/contributors/${collection}/${userId}?${encode(options)}`,
    );
  }

  async fetchCustomData(collection) {
    return this.secureGet(`${endpoints.platform}/data/collections/${collection}`);
  }

  async fetchRegistrations() {
    if (store.isAnonymous()) return [];
    // Fetch for current user
    return this.secureGet(`${endpoints.platform}/users/${store.userId}/registrations`);
  }

  registerSession(workshopId) {
    const { timeZone: timezone } = getTimeZone() || {};
    return this.securePost(`${endpoints.platform}/workshops/${workshopId}/registrations`, {
      userId: store.userId,
      timezone,
    });
  }

  async unregisterSession(workshopId) {
    await this.secureDelete(
      `${endpoints.platform}/workshops/${workshopId}/registrations/${store.userId}`,
    );
  }

  async fetchTimeSlots(collection, groupId, bookingType, slotFilters = {}, mode) {
    return this.securePost(
      `${endpoints.platform}/booking-slots/${collection}/users/${groupId}/type/${bookingType}`,
      { slotFilters, mode },
    );
  }

  async fetchAppointmentsModalSteps(collection, groupId, bookingType) {
    if (store.isAnonymous()) return {};
    return this.secureGet(
      `${endpoints.platform}/booking-options/${collection}/users/${groupId}/type/${bookingType}`,
    );
  }

  async fetchAppointments() {
    if (store.isAnonymous()) return [];
    return this.secureGet(`${endpoints.platform}/users/${store.userId}/appointments`);
  }

  async bookAppointment(collection, appointment, options) {
    const { bookingType, slotFilters } = options;
    if (store.isAnonymous()) return false;
    return this.securePost(
      `${endpoints.platform}/appointments/${collection}/users/${store.userId}/type/${bookingType}`,
      { ...appointment, slotFilters },
    );
  }

  async createAppointment(collection, appointment) {
    if (store.isAnonymous()) return false;
    return this.securePost(
      `${endpoints.platform}/appointments/collections/${collection}/users/${store.userId}`,
      appointment,
    );
  }

  async cancelAppointment(collection, appointmentId) {
    if (store.isAnonymous()) return false;
    return this.secureDelete(`${endpoints.platform}/appointments/${collection}/${appointmentId}`);
  }

  //TODO : comment on gère le notify ?
  async updateAppointmentStatus(collection, appointmentId, body) {
    if (store.isAnonymous()) return false;
    return this.securePatch(
      `${endpoints.platform}/appointments/${collection}/${appointmentId}/users/${store.userId}`,
      { ...body, notifyUser: false },
    );
  }

  async fetchAgenda() {
    if (store.isAnonymous()) return [];
    return this.secureGet(`${endpoints.platform}/users/${store.userId}/agenda`);
  }

  async sendContactEmail(contact) {
    return this.securePost(`${endpoints.platform}/contact/send-email`, { contact });
  }

  async stats(tag, payload) {
    return this.securePost(`${endpoints.platform}/users/${store.userId}/stats/${tag}`, payload);
  }

  async fetchParticipants() {
    return this.secureGet(`${endpoints.platform}/participants`);
  }

  async searchNetworkingParticipants(filters, currentPage) {
    const query = encode(
      omitBy((v) => !v)({
        ...filters,
        page: currentPage,
      }),
    );
    return this.secureGet(`${endpoints.platform}/networking/participants?${query}`);
  }

  async fetchNetworkingParticipants() {
    return this.secureGet(`${endpoints.platform}/networking/participants`);
  }

  async fetchNetworkingParticipant(id = store.user._id) {
    return this.secureGet(`${endpoints.platform}/networking/participants/${id}`)
      .then((user) => {
        if (!user?._id) return null;
        return user;
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.warn('Failed to load participant', id);
        return null;
      });
  }

  // Prevent too many parallel fetches
  throttledFetchConfig = throttle(
    async () => {
      const hooks = {
        403: () => {
          store.disconnect();
        },
      };
      return this.secureGet(`${endpoints.platform}/config`, hooks);
    },
    1000,
    { leading: true, trailing: true },
  );

  fetchConfig = (_from) => {
    // console.log(new Date().toISOString(), 'fetchConfig', _from);
    return this.throttledFetchConfig();
  };

  async checkSession() {
    return this.secureGet(`${endpoints.platform}/auth/checkSession`);
  }

  async fetchMatching(scoreCriteria = 'positive') {
    // TODO change microservice to retrieve all opportunities (inc. 0-score ones) in a single API request
    const requests = [
      this.secureGet(`${endpoints.platform}/opportunities/matching`),
      scoreCriteria === 'any' && this.secureGet(`${endpoints.platform}/opportunities`),
    ].filter(Boolean);

    const [matchingRes, allRes] = await Promise.all(requests);
    if (matchingRes?.opportunities && allRes?.opportunities) {
      const matchingIds = new Set(matchingRes.opportunities.map((op) => op._id));
      matchingRes.opportunities.push(
        ...allRes.opportunities
          .filter((op) => !matchingIds.has(op._id))
          .map((op) => ({ ...op, score: 0 })),
      );
    }

    return matchingRes;
  }

  async addData(collection, data) {
    return this.securePost(`${endpoints.platform}/data/collections/${collection}`, data);
  }

  async fetchUserCustomData(collection) {
    return this.secureGet(
      `${endpoints.platform}/users/${store.userId}/data/collections/${collection}`,
    );
  }

  buildICSUrl(ics) {
    return `${endpoints.platform}/ics/event.ics?file=${encodeURIComponent(ics)}`;
  }

  buildUserAgendaICSUrl(userId) {
    return `${endpoints.platform}/users/${userId}/agenda.ics`;
  }
}

export default new PlatformService();
