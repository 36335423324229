import PropTypes from 'prop-types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { bem } from '../../../../core/design/bem';
import { workshopProptypes } from '../../../../propTypes';
import EntityLink from '../../../EntityLink';

type SeeMoreButtonProps = {
  workshop: any;
  showOnLive?: boolean;
};

const translationPrefix = 'workshops.workshop';
const SeeParentButton: FC<SeeMoreButtonProps> = (props) => {
  const { workshop } = props;
  const { parentId } = workshop;
  const { t } = useTranslation();

  const parent = useSelector((state: any) => parentId && state.workshops.workshopsById[parentId]);

  if (!parent) {
    return null;
  }

  return (
    <Button
      className={bem('see-more-button').toString()}
      as={EntityLink}
      entity={parent}
      content={t(`${translationPrefix}.see-parent`)}
    />
  );
};

SeeParentButton.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export { SeeParentButton };
