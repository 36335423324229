import React, { useState } from 'react';
import { Button, Form, Header } from 'semantic-ui-react';

import { getString } from '../../utils';
import { localeFormat } from '../../utils/date';
import { useStepContext } from './StepContext';

const translationPrefix = 'appointments.steps';
const t = (key, data) => getString(`${translationPrefix}.${key}`, data);

export const StepConfirmContent = () => {
  const { selectedSlot, confirmData, onConfirm } = useStepContext();
  const [data, setData] = useState(confirmData || {});

  function handleConfirm() {
    // TODO: validate
    onConfirm(data);
  }

  return (
    <div className="step__confirm">
      <p>{t('confirm.date', { date: localeFormat(selectedSlot.startDate, 'PPPPp') })}</p>
      <Form onSubmit={handleConfirm}>
        <Header as="h3">{t('confirm.header')}</Header>
        <Form.TextArea
          name="message"
          rows={5}
          onChange={(e, { value }) => setData({ message: value })}
        />
        <Button primary>{t('confirm.button')}</Button>
      </Form>
    </div>
  );
};
