import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { getString } from '../../utils';
import nl2br from '../../utils/nl2br.tsx';

function ScreenIntro({ type, category }) {
  const title = getString(`${type}.intros.${category || 'all'}.title`);
  const description = getString(`${type}.intros.${category || 'all'}.description`);
  if (!title && !description) return null;
  return (
    <Segment className={cx('intro', 'no-grid-margin', type)}>
      <Header as="h2">
        <Header.Content>
          {title}
          <Header.Subheader>{nl2br(description)}</Header.Subheader>
        </Header.Content>
      </Header>
    </Segment>
  );
}

ScreenIntro.defaultProps = {
  category: 'all',
};

ScreenIntro.propTypes = {
  type: PropTypes.string.isRequired,
  category: PropTypes.string,
};

export default ScreenIntro;
