import PropTypes from 'prop-types';
import React from 'react';

import BlockContainer from '../../components/BlockContainer';
import { containerTypePropTypes } from '../../propTypes';
import { SocialWallV2 } from '../../social-wall-v2/SocialWallV2';
import '../SocialWall.scss';
import SocialWall from '../components/SocialWall';

function SocialWallBlock(props) {
  const { header, containerType, version = 'v1', ...rest } = props;
  return (
    <BlockContainer className="block--socialWall" header={header} type={containerType}>
      {version === 'v1' && <SocialWall {...rest} />}
      {version === 'v2' && <SocialWallV2 {...rest} />}
    </BlockContainer>
  );
}

SocialWallBlock.defaultProps = {
  containerType: 'basic',
  header: undefined,
  masonryConfig: {},
};

SocialWallBlock.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  masonryConfig: PropTypes.shape({ columnWidth: PropTypes.number, columnGutter: PropTypes.number }),
};

export default SocialWallBlock;
