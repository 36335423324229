import sumBy from 'lodash/sumBy';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Label, Tab } from 'semantic-ui-react';

import { getString } from '../../utils';
import { InviteModes } from '../InviteModes';
import { DEFAULT_INVITE_MODE } from '../InviteModes.hooks';
import { getUserUnreadCount } from '../store/networking.helpers';
import {
  allChatsList,
  hasActiveChats,
  invitesThatIDidntTreat,
} from '../store/networking.selectors';
import NetworkingChat from '../tabs/NetworkingChat';
import NetworkingContactSearch from '../tabs/NetworkingContactSearch';
import NetworkingInvites from '../tabs/NetworkingInvites';

const translationRoot = 'networking';
export const tabsFromReduxState = (inviteMode = DEFAULT_INVITE_MODE, page) => (state) => {
  const invites = invitesThatIDidntTreat(state);
  const chats = allChatsList(state);
  const unreadCount = sumBy(chats, getUserUnreadCount);
  const path = page.path || '/networking';
  const tabs = [
    {
      menuItem: {
        as: NavLink,
        id: 'search',
        content: getString(`${translationRoot}.search`),
        to: path,
        exact: true,
        key: 'search',
      },
      render: () => (
        <Tab.Pane key="Search" as="div">
          <NetworkingContactSearch pageId={page._id || 'networking'} />
        </Tab.Pane>
      ),
    },
    inviteMode === InviteModes.REQUEST && {
      menuItem: {
        as: NavLink,
        id: 'invites',
        content: (
          <>
            {getString(`${translationRoot}.my-invites.title`)}
            {invites.length > 0 && <Label color="blue">{invites.length}</Label>}
          </>
        ),
        to: `${path}/invites`,
        exact: true,
        key: 'invites',
      },
      render: () => (
        <Tab.Pane key="Invites" as="div">
          <NetworkingInvites />
        </Tab.Pane>
      ),
    },
  ].filter(Boolean);
  const hasChats = hasActiveChats(state);
  if (hasChats) {
    tabs.push({
      menuItem: {
        as: NavLink,
        id: 'chat',
        content: (
          <>
            {getString(`${translationRoot}.chats`)}
            {!!unreadCount && <Label color="blue">{unreadCount}</Label>}
          </>
        ),
        to: `${path}/chats`,
        key: 'chats',
      },
      render: () => (
        <Tab.Pane key="Chats" as="div" className="NetworkingScreen--ChatContainer">
          <NetworkingChat page={page} />
        </Tab.Pane>
      ),
    });
  }

  return tabs;
};
