import PropTypes from 'prop-types';
import React from 'react';

import AppointmentsBlock from '../../appointments/AppointmentsBlock';
import BlockContainer from '../../components/BlockContainer';

function SlotsBlock({ item, container, collection, ...props }) {
  const { _id, collection: userCollection } = item;
  const appointmentUserRef = { _id, collection: collection || userCollection };
  return (
    <BlockContainer {...container}>
      <AppointmentsBlock {...props} appointmentUserRef={appointmentUserRef} />
    </BlockContainer>
  );
}
SlotsBlock.defaultProps = {
  container: { type: 'segment', header: undefined },
  collection: undefined,
  title: undefined,
};

SlotsBlock.propTypes = {
  container: PropTypes.shape({ header: PropTypes.string, type: PropTypes.string }),
  collection: PropTypes.string,
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default SlotsBlock;
