import { useWindowSize } from '@react-hook/window-size';
import { MasonryScroller, useContainerPosition, usePositioner, useResizeObserver } from 'masonic';
import React from 'react';

import '../SocialWall.scss';
import SocialWallCard from '../SocialWallCard';
import { SocialWallVariant } from '../types/SocialWallTypes';

/**
 * Masonic doesn't support updating/deleting items, this component uses
 * a custom usePositioner that invalidates the cache when items change
 */
const Masonry = ({
  items,
  masonryConfig,
  openLightbox,
  ...props
}: SocialWallVariant): JSX.Element | null => {
  const containerRef = React.useRef(null);
  const [windowWidth, windowHeight] = useWindowSize();
  const { offset, width } = useContainerPosition(containerRef, [windowWidth, windowHeight]);
  const { columnWidth, columnGutter } = masonryConfig || {};
  const positioner = usePositioner(
    { width, columnWidth, columnGutter },
    // This is our dependencies array. When these dependencies
    // change, the positioner cache will be cleared and the
    // masonry component will reset as a result.
    [items.length],
  );
  const resizeObserver = useResizeObserver(positioner);

  return (
    <MasonryScroller
      className="SocialWall--Masonry"
      positioner={positioner}
      resizeObserver={resizeObserver}
      containerRef={containerRef}
      items={items}
      height={windowHeight}
      offset={offset}
      columnWidth={columnWidth}
      columnGutter={columnGutter}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      itemKey={(data) => (data ? data.id : undefined)}
      render={({ data: message }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <SocialWallCard post={message} onClick={() => openLightbox(message.id)} />
      )}
      {...props}
    />
  );
};

export default Masonry;
