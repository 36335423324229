import { parseISO } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import get from 'lodash/get';
import React from 'react';
import { Label } from 'semantic-ui-react';

import store from '../shared/Store';
import { useSocialWallData, useUserSocialWallDocument } from './useSocialWallData';

function computeUnreadCount(messages = [], userId, lastSeen) {
  if (!lastSeen) return messages.length;
  const lastSeenDate = parseISO(lastSeen);
  return messages.filter(
    ({ createdAt, userId: msgUserId }) =>
      msgUserId !== userId && isAfter(parseISO(createdAt), lastSeenDate),
  ).length;
}

export const SocialWallLabel = (props) => {
  const { messages = [] } = useSocialWallData();
  const { user } = useUserSocialWallDocument();
  const lastSeen = get(user, 'lastSeen');

  const unreadCount = computeUnreadCount(messages, store.userId, lastSeen);
  if (unreadCount === 0) return null;
  return <Label {...props}>{unreadCount}</Label>;
};
