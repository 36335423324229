import React, { FC, memo } from 'react';

import { Icons } from './material-icon.types';

export type MaterialIconProps = {
  color?: string;
  name?: Icons;
  size?: number;
  style?: Record<string, any>;
  className?: string;
  type?: string;
};

const MaterialIcon: FC<MaterialIconProps> = ({
  color,
  size,
  name,
  style = {},
  className = '',
  type = 'outlined',
}: MaterialIconProps) => {
  if (!name) return null;

  return (
    <span
      className={`material-symbols-${type} ${className}`}
      style={{ color, ...style, fontSize: size }}
    >
      {name.replace(/-/g, '_')}
    </span>
  );
};

export default memo(MaterialIcon);
