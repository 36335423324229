import cx from 'classnames';
import { isNil } from 'lodash';
import isEqual from 'lodash/isEqual';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { useMedia } from 'react-use';

import { ConfirmationModal } from '../../components/ConfirmationModal/ConfirmationModal';
import GlobalModalManager from '../../components/GlobalModalManager';
import { ModalProps } from '../../components/GlobalModalManager/GlobalModalManager';
import { uploadAssetFile } from '../../components/ImageUploader';
import { KeyboardToolbar } from '../../components/KeyboardToolbar/KeyboardToolbar';
import { QueryModal, QueryModalProps } from '../../components/QueryModal/QueryModal';
import apiVideoService from '../../core/services/apiVideo.service';
import { useBackButton } from '../../hooks/useBackButton';
import { useBodyScroll } from '../../utils/hooks';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import { getVideoStatus } from '../hooks/useSocialWallVideo';
import { Media, Publication } from '../types/SocialWallV2.types';
import { FilesThumbnails } from './FilesThumbnails/FilesThumbnails';
import { PublicationPanelHeader } from './PublicationPanelheader/PublicationPanelHeader';
import './SocialWallPublicationModal.scss';
import { UploadToolbar } from './UploadToolbar/UploadToolbar';

type PublicationModalStatic = {
  open: (props: PublicationModalProps) => Promise<boolean | null>;
};
type PublicationModalProps = {
  createPublication: (
    message: string,
    media?: Media,
    thumbnail?: Media,
  ) => Promise<Publication | undefined>;
  initialPublication?: Publication;
  id?: string;
};

type SocialWallPublicationModalTypes = FC<ModalProps<PublicationModalProps, any>> &
  PublicationModalStatic;

const TEXT_MAX_LENGHT = 600;
const MEGA_BYTE = 1024 * 1024;
const MAX_FILE_UPLOADED_SIZE = 150;
const TEXTAREA_PLACEHOLDER = 'social-wall.create-publication-placeholder';

const hasFileError = (file: File): boolean => {
  if (file.size / MEGA_BYTE > MAX_FILE_UPLOADED_SIZE) {
    return true;
  }

  return false;
};

export const SocialWallPublicationModal: SocialWallPublicationModalTypes = ({
  createPublication,
  onCancel,
  initialPublication,
  ...restProps
}) => {
  const { data } = initialPublication || {};
  const [publicationText, setPublicationText] = useState(data?.message || '');
  const [publicationMedia, setPublicationMedia] = useState<Media | undefined>(data?.media);
  const [publicationThumbnail, setPublicationThumbnail] = useState<Media | undefined>(
    data?.thumbnail,
  );

  useBackButton(onCancel);
  const [loading, setLoading] = useState(false);

  const { disableScrolling, enableScrolling } = useBodyScroll();
  const desktop = useMedia(GLOBAL_MEDIA_QUERIES.desktop);
  const { t } = useTranslation();

  const trimmedPublication = publicationText.trim();
  const canPublish = trimmedPublication.length > 0 || !isNil(publicationMedia);

  const publicationIsEqual =
    isEqual(trimmedPublication, initialPublication?.data.message) &&
    isEqual(publicationMedia, initialPublication?.data.media);

  const onPublicationClick = async () => {
    if (publicationIsEqual) {
      onCancel();
      return;
    }
    if (canPublish) {
      setLoading(true);

      const publication = await createPublication(
        trimmedPublication,
        publicationMedia,
        publicationThumbnail,
      );
      if (publication) {
        onCancel();
      }
      setLoading(false);
    }
  };

  const handleOnUpload = async (files: File[]): Promise<void> => {
    const file = files[0];

    if (!hasFileError(file)) {
      setLoading(true);

      const media = await uploadAssetFile(file);
      if (file.type.includes('video')) {
        const apiVideo = await apiVideoService.uploadVideoUrl(media.uri);

        const { isPlayable, width, height } = await getVideoStatus(apiVideo.videoId);

        if (isPlayable) {
          setPublicationThumbnail({
            ...media,
            width,
            height,
            uri: apiVideo.assets.thumbnail,
            mimeType: file.type,
            key: apiVideo.videoId,
            originalFilename: file.name,
          });
        }

        setPublicationMedia({
          ...media,
          width,
          height,
          uri: apiVideo.assets.mp4,
          mimeType: 'video/mp4',
          key: apiVideo.videoId,
          originalFilename: file.name,
          player: apiVideo.assets.player,
          videoId: apiVideo.videoId,
        });
      } else {
        setPublicationMedia(media);
      }

      setLoading(false);
    } else {
      GlobalModalManager.open(ConfirmationModal, {
        children: (
          <span>{t('social-wall.upload-error.text', { maxFileSize: MAX_FILE_UPLOADED_SIZE })}</span>
        ),
      });
    }
  };

  const handleOnDelete = (): void => {
    setPublicationMedia(undefined);
    setPublicationThumbnail(undefined);
  };

  const headerConfig = initialPublication
    ? {
        label: t('social-wall.edit-publication'),
        button: { label: t('btn.save'), action: onPublicationClick },
      }
    : {
        label: t('social-wall.create-publication'),
        button: { label: t('common.publish'), action: onPublicationClick },
      };

  const modalProps: QueryModalProps = {
    header: ({ onClose }: { onClose: () => void }) => (
      <PublicationPanelHeader
        disablePublishButton={!canPublish}
        onPublication={onPublicationClick}
        onClose={onClose}
        loading={loading}
        {...headerConfig}
      />
    ),
    draggable: false,
    steps: [95],
    onCancel,
    ...restProps,
  };

  const shouldShowConfirmationModal = () => {
    if (initialPublication) return !publicationIsEqual;
    return publicationText.length > 0 || !isNil(publicationMedia);
  };

  const closeConfirmationModalOptions = shouldShowConfirmationModal()
    ? {
        header: <span>{t('social-wall.confirmation-modal.header-title')}</span>,
        children: <span>{t('social-wall.confirmation-modal.confirmation-message')}</span>,
      }
    : undefined;

  const displayToolbar = !publicationMedia && !loading;

  return (
    <QueryModal
      className="create-publication-modal"
      {...modalProps}
      closeConfirmationModalOptions={closeConfirmationModalOptions}
    >
      <div className="created-publication__container">
        <div className="created-publication">
          <TextareaAutosize
            className={cx(!publicationMedia && 'textarea-full')}
            disabled={loading}
            value={publicationText}
            onChange={(e) => setPublicationText(e.target.value)}
            placeholder={t(TEXTAREA_PLACEHOLDER)}
            maxLength={TEXT_MAX_LENGHT}
            onFocus={disableScrolling}
            onBlur={enableScrolling}
          />
          {!displayToolbar && (
            <div className="textarea-informations">
              <span className="character-count">{`${publicationText.length}/${TEXT_MAX_LENGHT}`}</span>
            </div>
          )}
          {publicationMedia ? (
            <FilesThumbnails handleOnDelete={handleOnDelete} media={publicationMedia} />
          ) : null}
        </div>
      </div>
      {displayToolbar ? (
        <KeyboardToolbar>
          <div className="textarea-informations">
            <span className="character-count">{`${publicationText.length}/${TEXT_MAX_LENGHT}`}</span>
          </div>
          <UploadToolbar
            bordered={desktop}
            handleOnUpload={handleOnUpload}
            className={desktop ? 'upload-toolbar--desktop' : ''}
          />
        </KeyboardToolbar>
      ) : null}
    </QueryModal>
  );
};

SocialWallPublicationModal.open = async (props: PublicationModalProps): Promise<boolean | null> => {
  return GlobalModalManager.open(SocialWallPublicationModal, props);
};
