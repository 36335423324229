import { useContext, createContext } from 'react';
import { Workshop, WorkshopSession } from '../types/workshop.types';

type RegistrationConstraints = {
  registrationsPerDay?: Record<string, { min?: number; max?: number }>;
};

type WorkshopSessionContextData = {
  workshop?: Workshop | WorkshopSession;
  constraints?: RegistrationConstraints;
};

const WorkshopSessionContext = createContext<WorkshopSessionContextData>({});

export function useWorkshopSessionContext(): WorkshopSessionContextData {
  return useContext(WorkshopSessionContext);
}

export default WorkshopSessionContext;
