import { useEventListener } from 'ahooks';
import omit from 'lodash/omit';
import React, { useRef } from 'react';
import { Embed } from 'semantic-ui-react';

import { useVideoSectionStats } from '../hooks/useVideoSectionStats';

function useIframeEventListener(url, callback) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  const { origin } = new URL(url);
  useEventListener(
    'message',
    (event) => {
      if (
        !origin ||
        event.origin === origin ||
        event.origin === origin.replace('cn.console.vodalys.studio', 'console.vodalys.studio')
      ) {
        if (callbackRef.current) {
          callbackRef.current(event);
        }
      }
    },
    { target: window, origin },
  );
}

// eslint-disable-next-line react/prop-types
export const VodalysVideoPlayer = ({
  url,
  iframe,
  item,
  block,
  onPlay,
  onSectionStat,
  onVideoEvent,
  ...props
}) => {
  const { play, stop, updatetime } = useVideoSectionStats(url, { item, block }, onSectionStat);

  useIframeEventListener(url, (event) => {
    if (event.data) {
      const { event: type, currentTime: vodalysTime, duration } = event.data;
      const currentTime = vodalysTime / 1000;
      switch (type) {
        case 'play': {
          play(currentTime, duration);

          if (onPlay) {
            onPlay();
          }
          break;
        }
        case 'pause': {
          stop(currentTime, false);
          break;
        }
        case 'timeupdate': {
          // Vodalys passes time as milliseconds instead of seconds
          updatetime(currentTime, duration);
          break;
        }
        case 'ended': {
          stop(currentTime, true);
          break;
        }
        // eslint-disable-next-line no-empty
        default: {
          break;
        }
      }
      if (onVideoEvent) {
        onVideoEvent(event.data); // TODO: normalize ?
      }
    }
  });

  return <Embed {...omit(props, ['visible'])} url={url} iframe={{ ...iframe, src: url }} />;
};
