/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Header } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import { containerTypePropTypes } from '../../propTypes';

function HtmlBlock({ item, name, title, type }) {
  const value = get(item, name);
  if (!value) return null;
  return (
    <BlockContainer type={type}>
      {title && <Header as="h3">{title}</Header>}
      <div style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: value }} />
    </BlockContainer>
  );
}

HtmlBlock.defaultProps = {
  title: undefined,
  type: 'basic',
};

HtmlBlock.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: containerTypePropTypes,
};

export default HtmlBlock;
