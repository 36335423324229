import PropTypes from 'prop-types';
import { FC } from 'react';

import { Workshop, WorkshopSession } from '../../../types/workshop.types';
import { isWorkshopHighlighted } from './list.utils';
import { variants } from './workshop-item-variant';

type WorkshopItemsTemplate = {
  variant?: string;
  minHeight?: number;
};

type WorkshopItemsProps = {
  workshops: Workshop[] | WorkshopSession[];
  filteredWorkshopIds?: string[];
  template?: WorkshopItemsTemplate;
  itemProps?: Record<string, any>;
};

const WorkshopItems: FC<WorkshopItemsProps> = (props) => {
  const { workshops, filteredWorkshopIds, template, itemProps } = props;
  const { variant = 'card', minHeight } = template || {};
  const WorkshopComponent = variants[variant];
  return (
    <>
      {workshops.map((item: any) => (
        <WorkshopComponent
          key={item._id}
          workshop={item}
          isHighlighted={isWorkshopHighlighted(filteredWorkshopIds, item._id)}
          minHeight={minHeight}
          {...itemProps}
        />
      ))}
    </>
  );
};

WorkshopItems.defaultProps = {
  template: { variant: 'card' },
  itemProps: {},
  filteredWorkshopIds: undefined,
};

WorkshopItems.propTypes = {
  workshops: PropTypes.array.isRequired,
  template: PropTypes.object,
  itemProps: PropTypes.object,
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export { WorkshopItems };
