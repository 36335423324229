export type StoredData = Record<string, any>;

class WebStorage {
  // eslint-disable-next-line no-useless-constructor
  constructor(private eventId: string) {}

  getLocalStorageKey(): string {
    return `platform-${this.eventId}`;
  }

  getRgpdLocalStorageKey(): string {
    return `rgpd-${this.eventId}`;
  }

  removeItem(): void {
    localStorage.removeItem(this.getLocalStorageKey());
  }

  getItem(): StoredData | undefined {
    const value = localStorage.getItem(this.getLocalStorageKey());
    if (!value) return undefined;
    if (value[0] === '{') {
      return JSON.parse(value) as StoredData;
    }
    return undefined;
  }

  getRgpdRules(): StoredData | undefined {
    const value = localStorage.getItem(this.getRgpdLocalStorageKey());
    if (!value) return undefined;
    if (value[0] === '{') {
      return JSON.parse(value) as StoredData;
    }
    return undefined;
  }

  setItem(value: StoredData): void {
    const valueToSave = JSON.stringify(value);
    localStorage.setItem(this.getLocalStorageKey(), valueToSave);
  }
}


export default WebStorage;
