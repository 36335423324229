import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import CdnImage from '../../CdnImage';
import { TabEntry, TabPageEntry, TabUrlEntry } from '../tab.types';

const css = bem('Tab');

const TabItem: FC<TabEntry> = (entry: TabEntry) => {
  const { type, _id, icon, image, label } = entry;
  const hasImage = !!image?.uri;
  switch (type) {
    case 'url': {
      const { url, target = '_blank' } = entry as TabUrlEntry;
      return (
        <a data-id={_id} className={css().toString()} href={url} target={target}>
          {hasImage && <CdnImage className={css('Image').toString()} src={image} maxHeight={36} />}
          {!hasImage && !!icon && <Icon name={icon as SemanticICONS} />}
          <div className={css('Label')}>{label}</div>
        </a>
      );
    }
    case 'page': {
      const { to, exact } = entry as TabPageEntry;
      return (
        <NavLink
          data-id={_id}
          activeClassName="active-tab"
          className={css().toString()}
          to={to}
          exact={exact}
        >
          {hasImage && <CdnImage className={css('Image').toString()} src={image} maxHeight={36} />}
          {!hasImage && !!icon && <Icon name={icon as SemanticICONS} />}
          <div className={css('Label')}>{label}</div>
        </NavLink>
      );
    }
    default: {
      console.error('Unknown tab type', type, entry);
      return null;
    }
  }
};

export default TabItem;
