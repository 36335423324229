/* eslint-disable react/no-danger */
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import PropTypes from 'prop-types';
import React from 'react';

import { useCurrentUser } from '../../shared/store-user.hooks';
import { replaceValues } from '../../utils/stringUtils';
import { generateIframeUrl } from '../../utils/urlUtils';
import BlockContainer from '../BlockContainer';
import { BlockContainerPropType } from '../BlockContainer/BlockContainer';
import './iframe-block.scss';

// DEPRECATED: containerType, title
function IframeBlock(props) {
  const {
    autoResize,
    containerType,
    container,
    title,
    src,
    iframeType,
    lang,
    height,
    iframeProps,
    isAnonymous,
  } = props;

  const { scrollToTop } = iframeProps || {};
  const resizeIfNeeded = (e) => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
    if (autoResize && src) {
      iFrameResize(
        {
          log: false,
          heightCalculationMethod: 'lowestElement',
        },
        e.target,
      );
    }
  };

  const user = useCurrentUser();

  if (!src) return null;
  const userLang = lang || user?.lang || window.__DATA__.lang;
  const iframeUrl = replaceValues(
    generateIframeUrl(src, user, { iframeType, isAnonymous, lang: userLang }),
    {
      ...user,
      userId: user?._id,
      lang: userLang,
    },
  );

  const iframeKey = `${src}-${autoResize ? '?resize' : '?no-resize'}`;
  const iframe = (
    <iframe
      title="title"
      key={iframeKey}
      allowTransparency
      onLoad={resizeIfNeeded}
      src={iframeUrl}
      height={height}
      width="100%"
      style={{
        display: 'block',
        height,
        border: 'none',
        overflow: 'auto',
      }}
      scrolling={autoResize ? 'no' : 'yes'}
      allow="camera *;microphone *;fullscreen *;display-capture *"
      {...iframeProps}
    />
  );
  return (
    <BlockContainer
      {...container}
      type={container.type || containerType || 'segment-light'}
      header={container.header || title}
      className={`block--iframe ${container.className || ''}`}
      style={!title ? { padding: 0 } : undefined}
    >
      {iframe}
    </BlockContainer>
  );
}

IframeBlock.defaultProps = {
  autoResize: true,
  containerType: undefined,
  container: {},
  height: undefined,
  iframeProps: undefined,
  title: undefined,
  iframeType: undefined,
  isAnonymous: false,
  lang: undefined,
};

IframeBlock.propTypes = {
  autoResize: PropTypes.bool,
  container: BlockContainerPropType,
  containerType: BlockContainer.propTypes.type,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iframeProps: PropTypes.object,
  lang: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  iframeType: PropTypes.string,
  isAnonymous: PropTypes.bool,
};

export default IframeBlock;
