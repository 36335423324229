/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import platformService from '../../core/services/platform.service';
import store from '../../shared/Store';
import { getTimeZone } from '../../utils/date';

const { endpoints } = window.__DATA__;

class WorkshopSessionService {
  fetchEventWorkshopSessions() {
    return platformService.secureGet(`${endpoints.platform}/workshopSessions`);
  }

  registerMultipleWorkshopSessions(sessions) {
    const { timeZone: timezone } = getTimeZone() || {};
    return platformService.securePost(`${endpoints.platform}/workshops/sessions/registrations`, {
      timezone,
      sessions,
    });
  }

  registerWorkshopSession(collection, workshopId, sessionId, sessionsToUnregister) {
    const { timeZone: timezone } = getTimeZone() || {};
    return platformService.securePost(
      `${endpoints.platform}/workshops/${collection}/${workshopId}/sessions/${sessionId}/users/${store.userId}`,
      { timezone, sessionsToUnregister },
    );
  }

  unregisterFromWorkshopSession(collection, workshopId, sessionId) {
    return platformService.secureDelete(
      `${endpoints.platform}/workshops/${collection}/${workshopId}/sessions/${sessionId}/users/${store.userId}`,
    );
  }

  fetchWorkshopSessions(collection, workshopId) {
    return platformService.secureGet(
      `${endpoints.platform}/workshops/${collection}/${workshopId}/sessions`,
    );
  }
}

export default new WorkshopSessionService();
