import cx from 'classnames';
import { FC, memo } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { useDesignConfig } from '../config/design.context';
import { useIsDesktop } from '../hooks/useMediaQuery';
import { LanguageOption } from '../types/config.types';
import { handleChangeLanguage } from '../utils/urlUtils';
import CdnImage from './CdnImage';
import ExpiringNavLink from './ExpiringNavLink';
import EntitySearchBar from './SearchBar/EntitySearchBar';
import { SecondaryMenuEntry } from './menu/AppSecondaryMenu';

type AppHeaderProps = {
  language?: string;
  languageOptions?: LanguageOption[];
  secondary?: any[];
};

const AppHeader: FC<AppHeaderProps> = ({ language, languageOptions, secondary }) => {
  const { logo, logoProps = {}, logoSecondary, logoSecondaryHeight = 100 } = useDesignConfig();
  const { logoPath = '/', altText = '' } = logoProps;
  const desktop = useIsDesktop();

  if (!logo) return null;

  const hasMultipleLanguages = languageOptions && languageOptions.length > 1;
  return (
    <div className="menu--header">
      <ExpiringNavLink to={logoPath}>
        <CdnImage
          as="img"
          className="logo--main"
          src={logo}
          maxHeight={100}
          alt={altText}
          {...logoProps}
        />
      </ExpiringNavLink>
      {logoSecondary && (
        <CdnImage
          as="img"
          src={logoSecondary}
          className="logo--secondary"
          maxHeight={logoSecondaryHeight}
          alt=""
          {...logoProps}
        />
      )}
      {desktop && (
        <EntitySearchBar
          className={cx('menu--header__search--bar', {
            'menu--header__search--bar-shrink': hasMultipleLanguages,
          })}
        />
      )}
      {hasMultipleLanguages && !secondary?.find((entry) => entry.type === 'languages') && (
        <Dropdown
          className="dropdown--languages"
          placeholder="Language"
          compact
          selection
          options={languageOptions}
          value={language}
          onChange={handleChangeLanguage}
        />
      )}
      {!!secondary?.length && (
        <div className="secondary">
          {secondary.map((entry) => (
            <SecondaryMenuEntry key={entry.name} entry={entry} />
          ))}
        </div>
      )}
    </div>
  );
};

AppHeader.defaultProps = {
  language: 'fr',
  secondary: [],
};

export default memo(AppHeader);
