import React, { FC } from "react";
import { useSelector } from "react-redux";
import { buildMatch } from "../../../../utils/filter.utils";
import { WorkshopSession } from "../../../../types/workshop.types";
import { ReplayModalButton } from "./ReplayModalButton";

type SessionsReplayButtonProps = {
  workshop: any;
  filter: string;
  options: any;
};

const SessionsReplayButton: FC<SessionsReplayButtonProps> = (props) => {
  const { workshop, options = {}, ...rest } = props;
  const { sessions } = workshop;
  const { filter, onlyForRegistered } = options;
  const filterMatch = buildMatch(filter);
  const registrations = useSelector((state: any) => state.registrations.registrationsBySessionId);
  const filteredSessions = (sessions || []).filter((s: WorkshopSession) => (
    filterMatch(s) && (!onlyForRegistered || registrations[s._id])
  ));

  console.log(workshop.title, sessions);

  return (
    <>
      {filteredSessions.map((session: WorkshopSession) => (
        <ReplayModalButton
          key={session._id}
          workshop={session}
          {...rest}
        />
      ))}
    </>
  );
};

export { SessionsReplayButton };
