import { useSetState } from 'ahooks';
import find from 'lodash/find';
import { Button, Form, Message, Modal } from 'semantic-ui-react';

import ResetPasswordFields from '../../authentication/components/ResetPasswordFields';
import { ensureConstraint, validateConstraints } from '../../authentication/utils/passwordUtils';
import { useScreenConfig } from '../../config/screens.context';
import store from '../../shared/Store';
import { getString } from '../../utils';
import './ResetPasswordModal.scss';

const translationPrefix = 'login';

const errorMessageKeysByCode = {
  E_UPDATE_PASSWORD: 'update-password',
  E_INVALID_CREDENTIALS: 'update-password-invalid',
  E_REUSED_PASSWORD: 'update-password-reused',
  DEFAULT: 'error',
};

const ResetPasswordModal = (props) => {
  const [state, setState] = useSetState(() => ({
    error: null,
    password: '',
    confirmPassword: '',
    currentPassword: '',
  }));

  const { canCancel, onClose, requireCurrentPassword } = props;
  const { currentPassword, password, confirmPassword, error } = state;

  const config = useScreenConfig('login');
  const passswordType = config?.resetPassword?.type || 'simple';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ [name]: value, message: null, error: null });
  };

  const handleResetPassword = async () => {
    const res = await store.updatePassword(
      password,
      requireCurrentPassword ? currentPassword : 'mustUpdatePassword',
    );

    if (res.success) {
      onClose();
    } else {
      const messageKey = errorMessageKeysByCode[res.error] || errorMessageKeysByCode.DEFAULT;
      // FIXME: remove this if with check token validity before pressing reset password button
      setState({ error: getString(`${translationPrefix}.errors.${messageKey}`) });
    }
  };

  // check constraints
  const validatedConstraints = validateConstraints(
    password,
    confirmPassword,
    ensureConstraint(passswordType),
  );

  const isPasswordNotValid = !!find(validatedConstraints, { isValid: false });

  return (
    <Modal open size="small" className="reset-password-modal">
      <Modal.Header>{getString(`${translationPrefix}.please-enter-new-password`)}</Modal.Header>
      <Modal.Content>
        <Form size="large" error={!!error}>
          <ResetPasswordFields
            requireCurrentPassword={requireCurrentPassword}
            currentPassword={currentPassword}
            password={password}
            confirmPassword={confirmPassword}
            constraints={validatedConstraints}
            onChange={handleChange}
          />
          {error && (
            <Message
              className="error-message"
              error
              visible
              style={{
                textAlign: 'left',
                background: '#fff6f6',
                boxShadow: '0 0 0 1px #e0b4b4 inset',
                padding: '1em 1.5em',
              }}
              header={getString(`${translationPrefix}.error`)}
              content={error}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {canCancel && <Button onClick={onClose}>{getString('btn.cancel')}</Button>}
        <Button
          className="reset"
          onClick={handleResetPassword}
          primary
          disabled={isPasswordNotValid}
        >
          {getString(`${translationPrefix}.reset-password`)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ResetPasswordModal.defaultProps = {
  canCancel: true,
  requireCurrentPassword: true,
};

export default ResetPasswordModal;
