/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable react/require-default-props */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css as applyCss, jsx } from '@emotion/react';
import { useMemo } from 'react';
import { bem } from '../../../../core/design/bem';
import ItemComponent, { ItemConfig } from '../components/ItemComponent';
import { computeShadow } from '../styles';
import { aspectRatioToPercentage } from '../utils';
import './ImageListVariant.scss';
import { IconGridVariantProps } from './variant.types';

const listCss = bem('IconGridItems');

const ImageListVariant = (
  props: IconGridVariantProps & { itemConfig?: ItemConfig },
): JSX.Element => {
  const { variant, items, itemConfig } = props;

  const emotionCss = useMemo(() => {
    const {
      aspectRatio,
      overlay,
      borderRadius,
      border,
      gridGap,
      cssStyle,
      shadow,
      css: cssCustom,
    } = itemConfig || {};
    return applyCss(
      {
        '.IconGridItem': {
          borderRadius,
          border,
          boxShadow: computeShadow(shadow),
          marginBottom: gridGap,
        },
        '.IconGridItem__Wrapper': {
          paddingBottom: aspectRatioToPercentage(aspectRatio || '4 / 1'),
        },
        '.IconGridItem__Content': {
          background: overlay,
          borderRadius,
        },
      },
      cssCustom,
      cssStyle,
    );
  }, [itemConfig]);

  return (
    <div css={emotionCss} className={listCss({ variant })}>
      {items.map((item) => (
        <ItemComponent key={item._id} variant={variant} item={item} itemConfig={itemConfig} />
      ))}
    </div>
  );
};

ImageListVariant.defaultProps = {
  itemConfig: {},
};

export { ImageListVariant };
