import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Segment } from 'semantic-ui-react';

import defaultUserAvatar from '../../../assets/defaulUserAvatar.png';
import { Publication } from '../../../social-wall-v2/types/SocialWallV2.types';
import MaterialIcon from '../../Icons/MaterialIcon';
import CdnImage from '../../CdnImage';

export const SliderItem: React.FunctionComponent<{
  view: 'grid' | 'list';
  post: Publication;
}> = ({ view, post }) => {
  const { data, author, commentCount, interactions } = post;
  const username = `${author.firstName} ${author.lastName}`;
  const thumbnailUri = author.thumbnail?.uri;
  const interactionsCount = Object.values(interactions).length;
  const imageUri = data.media?.uri;
  const message =
    data.message && data.message.length > 100 ? `${data.message?.slice(0, 100)}...` : data.message;

  return (
    <div className={`slide-card ${view}-view`}>
      {imageUri && <CdnImage className="slide-card__image" src={imageUri} alt="CarouselImage" maxWidth={500} />}
      <div className="slide-card__info">
        <div className="slide-card__info__user">
          <Image src={thumbnailUri || defaultUserAvatar} avatar spaced="right" />
          <span>{username}</span>
        </div>
        {view === 'list' && <div className="slide-card__info__description">{message}</div>}
        <div className="slide-card__info__reaction">
          <span>
            <MaterialIcon name="comment" type="outlined" size={20} /> {commentCount}
          </span>
          <span>
            <MaterialIcon name="add_reaction" type="outlined" size={20} /> {interactionsCount}
          </span>
        </div>
      </div>
    </div>
  );
};
