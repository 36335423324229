/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon, SemanticICONS } from 'semantic-ui-react';

import { bem } from '../../core/design/bem';
import i18n from '../../translations/i18n';
import './FavoritesBlock.scss';

const css = bem('FavoritesBlock');

type Props = {
  buttonProps?: Record<string, any>;
  icon?: SemanticICONS;
  iconFull?: SemanticICONS;
  onToggleFavorites: any;
  showFavorites: boolean;
  title?: string;
};

const FavoritesBlock = (props: Props): JSX.Element => {
  const { icon, iconFull, title, onToggleFavorites, showFavorites, buttonProps } = props;

  return (
    <Button
      fluid
      {...buttonProps}
      className={css().toString()}
      active={showFavorites}
      onClick={onToggleFavorites}
    >
      <Icon name={showFavorites ? iconFull : icon} />
      {title}
    </Button>
  );
};

FavoritesBlock.defaultProps = {
  buttonProps: {},
  icon: 'heart outline',
  iconFull: 'heart',
  title: i18n.t('workshops.sidebar.favorites.show-favorites'),
};

export default FavoritesBlock;
