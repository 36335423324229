import { useRequest } from 'ahooks';
import React, { memo } from 'react';
import { Image } from './cms/types';

const SVGCacheTime = 24 * 3600 * 1000;

type SVGProps = {
  src: Image | string;
  className?: string;
  style?: React.CSSProperties;
};

const SVG = React.forwardRef(
  (props: SVGProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element | null => {
    const { src: image, className, style } = props;
    const src = typeof image === 'string' ? image : image?.uri;

    const { data: svgData } = useRequest(() => fetch(src).then((res) => res.text()), {
      cacheKey: src,
      cacheTime: SVGCacheTime,
      staleTime: SVGCacheTime,
      refreshDeps: [src],
    });

    if (!svgData) return null;

    return (
      <div
        key={src}
        ref={ref}
        className={className}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: svgData }}
        style={style}
      />
    );
  },
);

SVG.defaultProps = {
  className: undefined,
  style: undefined,
};

export default memo(SVG);
