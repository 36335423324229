/* eslint-disable @typescript-eslint/no-explicit-any */

export enum NotificationStatus {
  PENDING = 'pending',
  WORKSHOP_TEMPLATE = 'workshop_template',
  WORKSHOPSESSION_TEMPLATE = 'workshopSession_template',
  WORKSHOP_EMPTY_TEMPLATE = 'workshop_empty_template',
  WORKSHOP_PROGRAMMED = 'workshop_programmed',
  WORKSHOPSESSION_PROGRAMMED = 'workshopSession_programmed',
  PROGRAMMED = 'programmed',
  SENDING = 'sending',
  SENT = 'sent',
  READ = 'read',
}

export type Notification = {
  eventId: string;
  collection: string;
  _id: string;
  status: NotificationStatus;
  sendTo: any;
} & Record<string, any>;
