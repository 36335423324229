import ItemCTATilesBlock from '../../components/cms/ItemCTATilesBlock';
import CTABlock from '../../sidebar/blocks/CTABlock';
import ContactsBlock from '../../sidebar/blocks/ContactsBlock';
import FilesBlock from '../../sidebar/blocks/FilesBlock';
import ItemVoteBlock from '../../sidebar/blocks/ItemVoteBlock';
import BookAppointmentBlock from './BookAppointmentBlock';
import VideoChatRoomBlock from './BookAppointmentBlock/VideoChatRoomBlock';
import OpportunityListBlock from './OpportunityListBlock';
import StandBlock from './StandBlock';
import WebinarLiveBlock from './WebinarLiveBlock/WebinarLiveBlock';

export const sidebarBlockComponents = {
  'bookAppointment': BookAppointmentBlock,
  'opportunityList': OpportunityListBlock,
  'files': FilesBlock,
  'contacts': ContactsBlock,
  'cta': CTABlock,
  'video-chat': VideoChatRoomBlock,
  'webinar-live': WebinarLiveBlock,
  'item/ctaTiles': ItemCTATilesBlock,
  'item/vote': ItemVoteBlock,
  'stand': StandBlock,
};
