import React, { FC } from 'react';

import { useDesignConfig } from '../../config/design.context';

export const ExtraHtml: FC = () => {
  const design = useDesignConfig();
  const html = design.extraHtml?.ConnectedApp;
  if (!html) return null;

  // eslint-disable-next-line react/no-danger
  return <div className="page-extra-html" dangerouslySetInnerHTML={{ __html: html }} />;
};
