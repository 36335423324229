import React, { FC } from "react";
import { useSelector } from "react-redux";
import { buildMatch } from "../../../../utils/filter.utils";
import { SessionButton } from "./SessionButton";
import { WorkshopSession } from "../../../../types/workshop.types";

type SessionsButtonProps = {
  workshop: any;
  filter: string;
  options: any;
};

const SessionsButton: FC<SessionsButtonProps> = (props) => {
  const { workshop, options = {}, ...rest } = props;
  const { sessions } = workshop;
  const { filter } = options;
  const filterMatch = buildMatch(filter);
  const filteredSessions = (sessions || []).filter(filterMatch);

  const registrations = useSelector((state: any) => state.registrations.registrationsBySessionId);
  const registeredSession = sessions.find((s: WorkshopSession) => !!registrations[s._id]);

  return (
    <>
      {filteredSessions.map((session: WorkshopSession) => (
        <SessionButton
          key={session._id}
          session={session}
          registeredSession={registeredSession}
          options={options}
          {...rest}
        />
      ))}
    </>
  );
};

export { SessionsButton };
