import { useTranslation } from 'react-i18next';

import { useMe } from '../../../profile/hooks';
import { Publication } from '../../types/SocialWallV2.types';
import { CommentItem } from './CommentItem';
import './Comments.scss';

interface CommentsProps {
  comments: Publication[];
  onDelete: (commentId: string) => void;
}

export const Comments = ({ comments, onDelete }: CommentsProps): JSX.Element => {
  const { _id: userId } = useMe();
  const { t } = useTranslation();

  return (
    <div className="publication-comments">
      <div className="comments-count">
        <strong>{comments.length}</strong> {t('social-wall.comments')}
      </div>
      <div className="publication-comments__container">
        {comments.map((comment) => (
          <CommentItem key={comment._id} comment={comment} userId={userId} onDelete={onDelete} />
        ))}
      </div>
    </div>
  );
};
