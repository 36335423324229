/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSetState } from 'ahooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Icon, Progress } from 'semantic-ui-react';

import { bem } from '../../core/design/bem';
import { sweetAlert, sweetConfirmAlert } from '../../utils/popupUtils';
import GlobalModalManager from '../GlobalModalManager';
import ImageIcon from '../ImageIcon';
import { uploadAssetFile } from '../ImageUploader';
import SquareImage from '../SquareImage';
import './UserAvatarImageUploader.scss';
import { UserAvatarModal } from './UserAvatarModal';

const imageFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

const css = bem('UserAvatarImageUploader');

const translationPrefix = 'components.user-avatar-uploader';

type Props = {
  onChange: any;
  value?: Record<string, any>;
  avatarConfig?: Record<string, any>;
};

enum Status {
  'IDLE' = 'IDLE',
  'UPLOADING' = 'UPLOADING',
  'FINISHED' = 'FINISHED',
}

type PictoProps = {
  picto?: Record<string, any>;
  onClick?: any;
};

const Picto = ({ picto, onClick }: PictoProps) => {
  if (picto?.uri)
    return (
      <div className="picto" onClick={onClick}>
        <ImageIcon icon={picto} maxHeight={18} />
      </div>
    );
  return <Icon size="large" name="camera" onClick={onClick} />;
};

Picto.defaultProps = {
  picto: undefined,
  onClick: undefined,
};

export const UserAvatarImageUploader = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { onChange, avatarConfig, value } = props;
  const { imageSize = 100, placeholder, picto, enableChange = true } = avatarConfig || {};

  const [state, setState] = useSetState({
    progress: 0,
    status: Status.IDLE,
  });
  const { progress, status } = state;

  const handleRemove = async (onClose: any) => {
    const confirm = await sweetConfirmAlert({
      title: t(`${translationPrefix}.delete-avatar`),
    });
    if (confirm) {
      if (onChange) {
        onChange(null);
        onClose();
      }
    }
  };

  const handleProgress = (percentage: number) => {
    setState({ progress: percentage });
  };

  const handleDrop = async (accepted: any[]) => {
    if (accepted.length > 0) {
      try {
        setState({ progress: 0, status: Status.UPLOADING });
        const json = await uploadAssetFile(
          accepted[0],
          {
            maxWidth: imageSize,
            maxHeight: imageSize,
          },
          { onProgress: handleProgress },
        );

        setState({ status: Status.FINISHED });
        if (onChange) {
          onChange(json);
        }
      } catch (e) {
        setState({ status: Status.IDLE });
        await sweetAlert({ text: t(`errors.file.invalid-file`), icon: 'error' });
        console.error(e);
      }
    } else {
      setState({ status: Status.IDLE });
      console.warn('no images to upload');
    }
  };

  const openModal = () => {
    if (!enableChange) return;

    const modalData = { avatar: value };
    GlobalModalManager.open(
      ({ onCancel }) => (
        <UserAvatarModal
          {...modalData}
          onClose={onCancel}
          onDelete={() => {
            handleRemove(onCancel);
          }}
        />
      ),
      modalData,
    );
  };

  // Nothing to display if no value and can't change...
  if (!enableChange && !value) {
    return null;
  }

  return (
    <div className={css()}>
      {value ? (
        <div
          className={css('Thumbnail', { enableChange })}
          style={{ height: 'initial', width: 'initial' }}
        >
          <SquareImage
            className="userAvatar"
            src={value}
            imageSize={imageSize}
            onClick={openModal}
            style={{ width: imageSize, height: imageSize }}
          />
          {enableChange && <Picto picto={picto} onClick={openModal} />}
        </div>
      ) : (
        <Dropzone
          className={css('Dropzone').toString()}
          accept={imageFormats.join(',')}
          multiple={false}
          onDrop={handleDrop}
          style={{ height: imageSize, width: imageSize }}
        >
          <Picto picto={picto} />
          {status !== Status.UPLOADING && (
            <>
              {placeholder ? (
                <SquareImage
                  className="userAvatar"
                  src={placeholder}
                  imageSize={imageSize}
                  style={{ height: imageSize, width: imageSize }}
                />
              ) : (
                <div className="placeholder"> {t('profile.image-uploader.placeholder')}</div>
              )}
            </>
          )}
          {status === Status.UPLOADING && (
            <Progress size="small" percent={progress} color="green" />
          )}
        </Dropzone>
      )}
    </div>
  );
};

UserAvatarImageUploader.defaultProps = {
  avatarConfig: {},
};
