// eslint-disable operator-linebreak
import { useSelector } from 'react-redux';
import ChatStatus from '../store/ChatStatus';
import { InviteStatuses } from '../blocks/NetworkingParticipantList.helpers';
import { allChatsList } from '../store/networking.selectors';

const evaluateStatus = (self) => (chat) => {
  const wasInvited = self === chat.invited;
  const target = wasInvited ? chat.inviter : chat.invited;
  switch (chat.status) {
    case ChatStatus.accepted:
      return {
        id: target,
        status: InviteStatuses.connected,
      };
    case ChatStatus.rejected:
      return {
        id: target,
        status: InviteStatuses.invitedRefused,
      };
    default:
      return {
        id: target,
        status: InviteStatuses.awaiting,
      };
  }
};

const useAlreadyInContact = () => {
  return useSelector((state) => {
    const self = state.user.user?._id;
    const allChats = allChatsList(state);

    const statuses = allChats.map(evaluateStatus(self));
    return statuses.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item.status,
      }),
      {},
    );
  });
};

export default useAlreadyInContact;
