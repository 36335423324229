import { useEffect } from 'react';

import GlobalModalManager from '../components/GlobalModalManager';
import { ModalProps } from '../components/GlobalModalManager/GlobalModalManager';
import store from '../shared/Store';
import UserRegistrationModal from './components/UserRegistrationModal/UserRegistrationModal';

export const LoginModal = (props: ModalProps<any>): JSX.Element => {
  const { onCancel, onValidate } = props;

  useEffect(() => {
    if (store.isLoggedIn()) {
      onValidate(true);
    }
  }, [store.isLoggedIn()]);

  return <UserRegistrationModal {...props} onClose={onCancel} />;
};

LoginModal.open = async () => {
  await GlobalModalManager.open(LoginModal, {});
};
