import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { Container, Grid } from 'semantic-ui-react';
import { CmsPageLayout } from '../../cms/CmsPage';
import { bem } from '../../core/design/bem';
import DayCalendar from '../../components/DayCalendar';
import { userAgenda } from '../store/agenda.selectors';
import Schedule from '../../profile/components/Schedule';
import ConnexionTestBlock from '../blocks/ConnexionTestBlock';
import GridContainer from '../../components/GridContainer';
import AgendaStats from '../../components/AgendaStats.js';

const pageBlock = bem('page');

const components = {
  colored: DayCalendar,
  table: Schedule,
};

const sidebarComponents = {
  'connexion-test': ConnexionTestBlock,
};

function AgendaPage(props) {
  const { pageId, variant, sidebar, stats } = props;
  const events = useSelector(userAgenda);
  const Component = get(components, variant);
  return (
    <CmsPageLayout className={pageBlock({ type: 'agenda', [pageId]: true }).toString()}>
      <Container>
        <GridContainer sidebar={sidebar} sidebarComponents={sidebarComponents}>
          <Grid.Column>
            <AgendaStats events={events} stats={stats} />
            <Component events={events} {...props} />
          </Grid.Column>
        </GridContainer>
      </Container>
    </CmsPageLayout>
  );
}

AgendaPage.defaultProps = {
  pageId: 'agenda',
  sidebar: {},
  variant: 'table',
  stats: [],
};

AgendaPage.propTypes = {
  pageId: PropTypes.string,
  sidebar: PropTypes.object,
  variant: PropTypes.oneOf(['colored', 'table']),
  stats: PropTypes.arrayOf(PropTypes.object),
};

export default AgendaPage;
