/* eslint-disable @typescript-eslint/no-explicit-any */
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import React, { FC } from 'react';
import ImageIcon from '../../../ImageIcon';
import { WorkshopItems } from '../WorkshopItems';

type ListTemplate = {
  variant?: string;
  minHeight?: number;
};
type VerticalListsProps = {
  groups?: Record<string, any>[];
  centered?: boolean;
  template?: ListTemplate;
  filteredWorkshopIds?: string[];
  itemProps: any;
  iconConfig: any;
};

const VerticalLists: FC<VerticalListsProps> = (props) => {
  const { centered, groups = [], filteredWorkshopIds, iconConfig, itemProps, template } = props;
  return (
    <Grid className="workshops--container vertical" columns="equal" centered={centered}>
      {groups?.map(({ id, items, label, image }) => (
        <Grid.Column key={id} className={`workshops--group workshops--group--${id}`}>
          <div className="category">
            <ImageIcon icon={image} {...iconConfig} />
            <Header as="h3">{label}</Header>
          </div>
          <WorkshopItems
            workshops={items}
            filteredWorkshopIds={filteredWorkshopIds}
            template={template}
            itemProps={itemProps}
          />
        </Grid.Column>
      ))}
    </Grid>
  );
};

VerticalLists.defaultProps = {
  itemProps: undefined,
  centered: false,
  filteredWorkshopIds: undefined,
  template: { variant: 'card' },
  iconConfig: {},
  groups: [],
};

VerticalLists.propTypes = {
  centered: PropTypes.bool,
  itemProps: PropTypes.object,
  filteredWorkshopIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  template: PropTypes.shape({
    variant: PropTypes.string,
    minHeight: PropTypes.number,
  }),
  iconConfig: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export { VerticalLists };
