/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSize } from 'ahooks';
import { partition } from 'lodash';
import React, { FC, useRef, useState } from 'react';
import ExpiringNavLink from '../../../components/ExpiringNavLink';
import { bem } from '../../../core/design/bem';
import './TiltedMenu.scss';

const css = bem('TiltedMenu');

export type ItemType = {
  _id: string;
  title: string;
  to: string;
  column: 'left' | 'right';
  size?: number;
};

export type TiltedMenuProps = {
  header: {
    title: string;
    preTitle?: string;
    postTitle?: string;
  };
  orientation?: number;
  animated?: boolean;
  items: ItemType[];
};

type ItemsProps = {
  side: 'left' | 'right';
  startIndex: number;
  items: ItemType[];
  focusedItem: string | undefined;
  setFocusedItem: (item: string | undefined) => void;
};

const Items: FC<ItemsProps> = ({
  side,
  items = [],
  focusedItem,
  setFocusedItem,
  startIndex = 0,
}) => (
  <ul className={css('links', { side })}>
    {items.map((item: ItemType, index) => (
      <li
        key={item._id || index}
        style={{ fontSize: item.size ? `${item.size}em` : undefined }}
        data-index={startIndex + index}
      >
        <ExpiringNavLink
          to={item.to || '#'}
          className={focusedItem && focusedItem !== item._id ? 'faded' : undefined}
          onMouseOver={() => setFocusedItem(item._id)}
          onFocus={() => setFocusedItem(item._id)}
          onMouseOut={() => setFocusedItem(undefined)}
          onBlur={() => setFocusedItem(undefined)}
        >
          {item.title}
        </ExpiringNavLink>
      </li>
    ))}
  </ul>
);

const TiltedMenu = ({
  header,
  items,
  orientation = 45,
  animated,
}: TiltedMenuProps): JSX.Element => {
  const [focusedItem, setFocusedItem] = useState<string | undefined>(undefined);
  const ref = useRef<any>();
  const size = useSize(ref);
  const { width } = size;
  const [left, right] = partition(items, (i) => i.column === 'left');
  return (
    <div
      ref={ref}
      className={css({ animated })}
      style={{ paddingBottom: '56%', fontSize: width ? (24 * width) / 1000 : undefined }}
    >
      {!!width && (
        <div className={css('container')}>
          <div className={css('rotating')} style={{ transform: `rotate(${orientation || 0}deg)` }}>
            <div
              className={css('infos')}
              style={{
                transform: orientation >= 0 ? 'translate(-50%, -50%) rotate(270deg)' : undefined,
              }}
            >
              {!!header.preTitle && <p>{header.preTitle}</p>}
              <h1>{header.title}</h1>
              {!!header.postTitle && <p>{header.postTitle}</p>}
            </div>
            <nav className="menu">
              <Items
                side="left"
                startIndex={0}
                items={left}
                focusedItem={focusedItem}
                setFocusedItem={setFocusedItem}
              />
              <Items
                side="right"
                startIndex={left.length}
                items={right}
                focusedItem={focusedItem}
                setFocusedItem={setFocusedItem}
              />
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default TiltedMenu;
