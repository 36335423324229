import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Input } from 'semantic-ui-react';

import CdnImage from '../../../components/CdnImage';
import ProgressBar from '../../../components/cms/AchievementsBlock/components/ProgressBar';
import { AchievementBadge } from '../../../components/cms/AchievementsBlock/types';
import {
  computeAchievementBadgesByCategory,
  computePercentage,
} from '../../../components/cms/AchievementsBlock/utils';
import { GamificationCodeInput } from '../../../components/cms/GamificationCodeBlock/GamificationCodeBlock';
import { useAchievements } from '../../../core/achievements/achievements.hook';
import { bem } from '../../../core/design/bem';
import { useMe } from '../../../profile/hooks';
import { AchievementStatus } from '../../../types/achievements.types';
import { replaceValues } from '../../../utils/stringUtils';
import { LeaderboardTabs } from '../../gamification.types';
import { useUserRanking, useUserRankingInOwnTeam } from '../../hooks/leaderboard.hooks';
import { defaultThumbnail } from '../components/Podium';
import './UserRanking.scss';

const translationPrefix = 'achievements.leaderboard.user-ranking';

const css = bem('UserRanking');

type StatProps = {
  name: string;
  value: number;
};

const Stat = ({ name, value }: StatProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={`stat ${name}`}>
      <div className="label">{t(`${translationPrefix}.${name}`)}</div>
      <div className="value">{value === undefined ? <>&nbsp;</> : value}</div>
    </div>
  );
};

type Props = {
  tabs: LeaderboardTabs;
  field: string;
  subtitleTemplate: string;
  showProgress?: boolean;
  gamificationCode?: {
    enabled?: boolean;
  };
  profile?: {
    description?: string;
    hideUserInfo?: boolean;
  };
  config?: {
    badgeSize?: number;
  };
};

export const UserRanking = (props: Props): JSX.Element => {
  const {
    tabs,
    field,
    subtitleTemplate,
    showProgress,
    profile,
    gamificationCode,
    config = {},
  } = props;
  const { description } = profile || {};
  const { t } = useTranslation();
  const user = useMe();
  const { achievements, categories } = useAchievements();
  const userRanking = useUserRanking();
  const teamRanking = useUserRankingInOwnTeam(field, { enabled: tabs?.myteam?.enabled });
  const achievementBadgesListByCategory = computeAchievementBadgesByCategory(
    achievements,
    categories,
  );
  const percentage = computePercentage(achievements);
  const { _id: userId, thumbnail = defaultThumbnail, firstName, lastName } = user;
  const company = replaceValues(subtitleTemplate, user);
  const { score, rank: globalRank } = userRanking;
  const { rank: rankInTeam } = (field && teamRanking?.users?.find((u) => u._id === userId)) || {};

  return (
    <div className={css({ hideUserInfo: profile?.hideUserInfo })}>
      {!profile?.hideUserInfo && (
        <>
          <CdnImage src={thumbnail} className="thumbnail" maxWidth={90} />
          <div className="fullname">
            <span className="firstName">{firstName} </span>{' '}
            <span className="lastName">{lastName}</span>
          </div>
          {company && <div className="company">{company}</div>}
        </>
      )}
      <div className="gamification-stats">
        <div className="stats">
          <Stat name="score" value={score} />
          <Stat name="global-rank" value={globalRank} />
          {field && rankInTeam && <Stat name="team-rank" value={rankInTeam} />}
        </div>
        {showProgress && (
          <div className="progress">
            <div className="label">{t(`${translationPrefix}.score`)}</div>
            <ProgressBar percentage={percentage} icon={undefined} />
            <div className="score">{score}</div>
          </div>
        )}
        {description && (
          // eslint-disable-next-line react/no-danger
          <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {gamificationCode?.enabled && (
          <div className="gamfication-code">
            <GamificationCodeInput blockId="leaderboard-profile" />
          </div>
        )}
        <div className="badges">
          {Object.keys(achievementBadgesListByCategory).map((type) => {
            const badges = achievementBadgesListByCategory[type] as AchievementBadge[];

            return (
              <Grid key={type} className="category-badges" centered columns={3}>
                {badges.map((badge) => {
                  const { _id, image, title, status } = badge;
                  const isReadOnly = status === AchievementStatus.READ_ONLY;
                  return (
                    <Grid.Column className={cx('badge', isReadOnly && 'badge--readonly')} key={_id}>
                      <CdnImage src={image} maxWidth={config?.badgeSize ?? 60} />
                      <div className="title">{title}</div>
                    </Grid.Column>
                  );
                })}
              </Grid>
            );
          })}
        </div>
      </div>
    </div>
  );
};
