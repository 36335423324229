import { FC, useCallback, useState } from 'react';
import { useEvent } from 'react-use';

import { CookieContext } from './CookieContext';
import { getCookieConsent } from './cookie.utils';

// eslint-disable-next-line react/no-unused-prop-types, @typescript-eslint/no-explicit-any
const CookieContextProvider: FC<{ lang: string; eventId: string; children: any }> = ({
  eventId,
  children,
}) => {
  // eslint-disable-next-line no-restricted-globals
  const { cookieBanner } = window.__DATA__;
  const [, setState] = useState(0);
  const refreshCookies = useCallback(() => setState(Date.now()), [setState]);
  useEvent('cc:onConsent', refreshCookies);
  useEvent('cc:onChange', refreshCookies);

  const { categories } = getCookieConsent(eventId);

  return (
    <CookieContext.Provider value={{ hasCookieBanner: !!cookieBanner?.enabled, categories }}>
      {children}
    </CookieContext.Provider>
  );
};

export default CookieContextProvider;
