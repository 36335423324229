import cx from 'classnames';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CdnImage from '../../../../components/CdnImage';
import { TextWithOverflow } from '../../../../components/TextWithOverflow/TextWithOverflow';
import { Label } from '../../../../design-system/Label/Label';
import { isVideoMedia, videoCDN } from '../../../../utils/videoUtils';
import { useSocialWallConfig } from '../../../social-wall-config.context';
import { Publication } from '../../../types/SocialWallV2.types';
import { EmoteList } from '../../EmoteList';
import { useApiVideoIframe } from '../hooks/useApiVideoIframe';
import './PublicationMedia.scss';

interface MobileMediaPublicationProps {
  publication: Publication;
  panelPosition: number;
  minHeight?: number;
  userInteractions: string[];
  onEmoteClick?: (emote: string) => Promise<void>;
}

export const PublicationMedia: FC<MobileMediaPublicationProps> = ({
  publication,
  panelPosition,
  minHeight,
  userInteractions,
  onEmoteClick,
}) => {
  const [textIsOpen, setTextIsOpen] = useState(false);
  const { t } = useTranslation();
  const { commentsEnabled, interactionsEnabled } = useSocialWallConfig();

  const { data, interactions, commentCount } = publication;

  const { media, message, thumbnail } = data;
  const { filename = '', mimeType, uri, player } = media!;

  const isVideo = isVideoMedia({ mimeType, filename });
  const videoUrl = videoCDN(uri);

  useApiVideoIframe('iframe-wrapper', media?.videoId);

  const renderVideo = () => {
    return player ? (
      <div className="iframe-wrapper" id="iframe-wrapper" />
    ) : (
      <video
        playsInline
        disableRemotePlayback
        disablePictureInPicture
        x-webkit-airplay="deny"
        autoPlay
        controls
        width={250}
        poster={thumbnail?.uri}
      >
        <source src={videoUrl} type={mimeType || 'video/mp4'} />
        {t('common.no-video')}
      </video>
    );
  };
  return (
    <>
      <div
        className={cx('media-image-wrapper', textIsOpen && 'media-image-wrapper__dark')}
        style={{
          height: `${window.innerHeight - panelPosition}px`,
          minHeight: `${minHeight || 50}%`,
        }}
      >
        {isVideo ? (
          renderVideo()
        ) : (
          <>
            {/* TODO: filter:blur induce performance issues on mobile --> find solution to set background image with blur without problems
            <CdnImage
              src={thumbnail?.uri || media!.uri}
              maxWidth={600}
              maxHeight={400}
              loading="lazy"
              className="background-image"
            />
            */}
            <div className="test" />
            <CdnImage
              className="main-image"
              src={uri}
              maxWidth={600}
              maxHeight={400}
              loading="lazy"
            />
          </>
        )}
      </div>
      <div className="media-footer" style={{ transform: `translateY(-${panelPosition - 40}px)` }}>
        <div
          className={cx(
            'media-footer__emotes-wrapper',
            !interactionsEnabled && 'media-footer__emotes-wrapper--end',
          )}
        >
          <EmoteList
            interactions={interactions}
            userInteractions={userInteractions}
            onEmoteClick={onEmoteClick}
          />
          {commentsEnabled && (
            <Label
              value={String(commentCount)}
              icon={{ size: 15, name: 'comment', color: 'grey' }}
            />
          )}
        </div>
        {message && (
          <div className="media-text">
            <TextWithOverflow
              text={message}
              lineClamp={1}
              withButton={false}
              toggleOnClick
              onChangeOverflow={setTextIsOpen}
            />
          </div>
        )}
      </div>
    </>
  );
};
