import { useUpdate } from 'ahooks';
import { parse, stringify } from 'query-string';
import type { ParseOptions, StringifyOptions } from 'query-string';
import { useMemo, useRef } from 'react';
import type * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useEvent from '../core/hooks/useEvent.hook';

// From ahooks v3

export interface Options {
  navigateMode?: 'push' | 'replace';
  parseOptions?: ParseOptions;
  stringifyOptions?: StringifyOptions;
}

const baseParseConfig: ParseOptions = {
  parseNumbers: false,
  parseBooleans: false,
  arrayFormat: 'bracket',
};

const baseStringifyConfig: StringifyOptions = {
  skipNull: true,
  skipEmptyString: true,
  arrayFormat: 'bracket',
};

type UrlState = Record<string, any>;

const useUrlState = <S extends UrlState = UrlState>(
  initialState?: S | (() => S),
  options?: Options,
): readonly [UrlState, (state: UrlState) => void] => {
  type State = Partial<{ [key in keyof S]: any }>;
  const { navigateMode = 'push', parseOptions, stringifyOptions } = options || {};

  const mergedParseOptions = { ...baseParseConfig, ...parseOptions };
  const mergedStringifyOptions = { ...baseStringifyConfig, ...stringifyOptions };

  const location = useLocation();
  const history = useHistory();
  const update = useUpdate();

  const initialStateRef = useRef(
    typeof initialState === 'function' ? (initialState as () => S)() : initialState || {},
  );

  const queryFromUrl = useMemo(() => {
    return parse(location.search, mergedParseOptions) as Partial<S>;
  }, [location.search]);

  const targetQuery: State = useMemo(
    () => ({
      ...initialStateRef.current,
      ...queryFromUrl,
    }),
    [queryFromUrl],
  );

  const setState = (s: React.SetStateAction<State>) => {
    const newQuery = typeof s === 'function' ? s(queryFromUrl) : { ...queryFromUrl, ...s };
    const newState = {
      hash: location.hash,
      search: stringify(newQuery, mergedStringifyOptions) || '?',
    };
    history[navigateMode](newState);
    // This second history replace is needed to fix issue about not working update in urlState
    // Issue: When we change state, the first render still display old state, this bug must come from react-router itself
    history.replace(newState);
    update();
  };

  return [targetQuery, useEvent(setState)] as const;
};

export default useUrlState;
