import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import cx from 'classnames';
import { workshopProptypes } from '../../../../propTypes';
import Images from '../../../../utils/Images';
import { getClassName } from '../../../../workshops/utils';
import WorkshopDate from '../../WorkshopDate';
import SpeakerAvatars from '../../../speakers/SpeakerAvatars';
import './WorkshopGridItem.scss';
import { WorkshopActions } from '../actions/WorkshopActions';
import { computeActions } from '../actions/actions.utils';

function WorkshopGridItem({ workshop, config }) {
  const { showSpeakers = true, showDate = true, showEndTime = false } = config;
  const { shortDescription, image, speakers, title, startDate, endDate } = workshop;
  const hasImage = image && image.uri;
  return (
    <Card className={cx('Workshop', getClassName(workshop))}>
      <Card.Content>
        {hasImage && (
          <>
            <div className="image" style={{ backgroundImage: `url(${Images.cdn(image)})` }} />
            <Card.Content style={{ marginTop: 260, borderTop: 0 }} />
          </>
        )}
        <Card.Header className="title">{title}</Card.Header>
        {showDate && (
          <Card.Description className="date">
            <WorkshopDate startDate={startDate} endDate={endDate} showEndTime={showEndTime} />
          </Card.Description>
        )}
        {shortDescription && (
          <Card.Description className="description">{shortDescription}</Card.Description>
        )}
        {showSpeakers && (
          <div className="speakers" style={{ marginTop: 10 }}>
            <SpeakerAvatars size="mini" speakerIds={speakers} />
          </div>
        )}
      </Card.Content>
      <Card.Content extra>
        <WorkshopActions workshop={workshop} actions={computeActions(config)} />
      </Card.Content>
    </Card>
  );
}

WorkshopGridItem.defaultProps = {
  config: {},
};

WorkshopGridItem.propTypes = {
  config: PropTypes.object,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopGridItem;
