import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import MaterialIcon from '../../components/Icons/MaterialIcon';
import { UserAvatar } from '../../components/UserAvatar/UserAvatar';
import { formatDateAgo } from '../../utils/dateUtils';
import { PublicationAuthor } from '../types/SocialWallV2.types';
import './PublicationHeader.scss';

interface PublicationHeaderProps {
  user: PublicationAuthor;
  date?: string;
  description?: string;
  sideDate?: boolean;
  dark?: boolean;
}
export const PublicationHeader: FC<PublicationHeaderProps> = ({
  date,
  user,
  description,
  sideDate,
  dark = true,
}) => {
  const { t } = useTranslation();
  return (
    <div className="publication-header">
      <UserAvatar
        image={user.thumbnail?.uri}
        title={`${user?.firstName || ''} ${user?.lastName || ''}`}
        description={description}
        dark={dark}
      >
        {!sideDate && (
          <div
            className={cx(
              'publication-header__date',
              'publication-header__date-small',
              dark && 'publication-header__date--dark',
            )}
          >
            <MaterialIcon size={16} name="schedule" />
            {date && <span>{formatDateAgo(date, t, true)}</span>}
          </div>
        )}
      </UserAvatar>
      {sideDate && (
        <div className={cx('publication-header__date', dark && 'publication-header__date--dark')}>
          <MaterialIcon size={16} name="schedule" />
          {date && <span>{formatDateAgo(date, t)}</span>}
        </div>
      )}
    </div>
  );
};
