/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useFunctionRef } from '../../utils/hooks';
import { Image } from '../cms/types';
import SVG from '../SVG';
import './ClickableSVG.scss';

export type ClickableItem = {
  id: string;
};

type OnItemClick = (item: ClickableItem) => void;

type ClickableSVGProps = {
  src: Image | string;
  items: ClickableItem[];
  onClick: OnItemClick;
  style?: React.CSSProperties;
};

const ClickableSVG = (props: ClickableSVGProps): JSX.Element => {
  const { src, items, onClick, style } = props;

  const [svg, setSVG] = useState<HTMLDivElement | null>(null);
  const handleClick = useFunctionRef(onClick);

  useEffect(() => {
    const toRemove: Array<() => void> = [];
    if (svg && items) {
      items.forEach((item) => {
        const clickableItem = svg.querySelector(`#${item.id}`);
        if (clickableItem) {
          const fun = () => handleClick.current?.(item);
          toRemove.push(() => clickableItem.removeEventListener('click', fun));
          clickableItem.addEventListener('click', fun);
        } else {
          console.warn('Failed to find', item);
        }
      });
    }
    return () => toRemove.forEach((f) => f());
  }, [handleClick, items, svg]);

  return <SVG ref={setSVG} src={src} className="clickable-svg" style={style} />;
};

ClickableSVG.defaultProps = {
  style: undefined,
};

export default ClickableSVG;
