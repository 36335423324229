import React, { useContext } from 'react';
import noop from 'lodash/noop';

export const StepContext = React.createContext({
  appointmentUserRef: {},
  currentAppointment: undefined,
  currentStep: undefined,
  onCancel: noop,
  onUpdate: noop,
  onSelectStep: noop,
  selectedSlot: undefined,
  setStep: noop,
  freeTimeSlots: [],
});

export function useStepContext() {
  return useContext(StepContext);
}
