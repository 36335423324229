import React, { FC, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Switch } from "react-router-dom";
import ExhibitorPreview from "../exhibitors/ExhibitorPreview";
import PagePreview from "./PagePreview";
import ProgramPreview from "./ProgramPreview";
import SponsorPreview from "./SponsorPreview";

export const PreviewRouter: FC = () => {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route path="/preview/page/:name" exact component={PagePreview} />
        <Route path="/preview/exhibitor" exact component={ExhibitorPreview} />
        <Route path="/preview/sponsor" exact component={SponsorPreview} />
        <Route path="/preview/program" exact component={ProgramPreview} />
      </Switch>
    </QueryClientProvider>
  );
};
