import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';

import EntityLink from '../../../../components/EntityLink';
import { bem } from '../../../../core/design/bem';
import { Workshop } from '../../../../types/workshop.types';
import Images from '../../../../utils/Images';

const translationPrefix = 'workshops.workshop.suggested-sessions';
const css = bem('SuggestedWorkshops');

type Props = {
  workshops: Workshop[];
};

export const ListVariant = (props: Props): JSX.Element => {
  const { workshops } = props;
  const { t } = useTranslation();
  return (
    <Item.Group unstackable className={css({ variant: 'list' }).toString()}>
      {workshops.map((workshop: Workshop) => {
        const { title, _id, image, workshopId, startDate, endDate } = workshop;
        return (
          <Item
            className={css('Item').toString()}
            key={_id}
            as={EntityLink}
            entity={{ ...workshop, _id: workshopId, kind: 'workshops' }}
          >
            {image && image.uri && (
              <Item.Image
                size="small"
                src={Images.cdn(image)}
                style={{ maxWidth: 100, marginRight: 10 }}
              />
            )}
            <Item.Content>
              <Item.Header className="title" as="h3">
                {title}
              </Item.Header>
              {startDate && endDate && (
                <Item.Description className="date">
                  {t(`${translationPrefix}.date`, { startDate, endDate })}
                </Item.Description>
              )}
            </Item.Content>
          </Item>
        );
      })}
    </Item.Group>
  );
};
