import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Icon, SemanticICONS } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { useMe } from '../../../profile/hooks';
import i18n from '../../../translations/i18n';
import './ChatMeetingCard.scss';
import { Appointment, MeetingStatus, UserAppointment } from './types';

type Props = {
  appointment: Appointment;
  messageId: string;
  onUpdateMeeting: (messageId: string, action: string, meeting: Appointment) => void;
};

const css = bem('ChatMeetingCard');

type Status = { status: string; icon: SemanticICONS; header: string };

function getMeetingStatus(users: UserAppointment[]): Status {
  if (users.every((u) => u.status === 'confirmed'))
    return {
      icon: 'check',
      status: 'confirmed',
      header: i18n.t('networking.appointment.confirmed'),
    };
  if (users.some((u) => u.status === 'canceled'))
    return {
      icon: 'calendar times',
      status: 'canceled',
      header: i18n.t('networking.appointment.canceled'),
    };
  if (users.some((u) => u.status === 'refused')) {
    return { icon: 'times', status: 'refused', header: i18n.t('networking.appointment.refused') };
  }
  return {
    icon: 'hourglass half',
    status: 'pending',
    header: i18n.t('networking.appointment.pending'),
  };
}

const Info = ({ name, label, value }: { name: string; label: string; value: string }) => {
  if (!value) return null;
  return (
    <div className={css('Info').mix(name)}>
      <span className="label">{label}</span>
      <span className="value">{value}</span>
    </div>
  );
};

export const ChatMeetingCard: FC<Props> = ({ appointment, messageId, onUpdateMeeting }) => {
  const me = useMe();
  const { t } = useTranslation();

  const { startDate, endDate, location, message, ownerId, users } = appointment;
  const otherUserStatus = users?.find((user) => user.userId !== ownerId)?.status;
  const userIsOwner = me._id === ownerId;

  const { icon, status, header } = getMeetingStatus(users);
  return (
    <Card className={css({ status }).toString()}>
      <Card.Content>
        <Card.Header>
          <Icon name={icon} className={status} /> {header}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Info
          name="date"
          label={`${t('networking.appointment.date')} : `}
          value={t('networking.appointment.date-display', { startDate, endDate })}
        />
        <Info
          name="location"
          label={`${t('networking.appointment.location')} : `}
          value={location}
        />
        <Info name="message" label={`${t('networking.appointment.message')} : `} value={message} />
        {userIsOwner && (
          <div className={css('Footer').mix('owner')}>
            {status === 'pending' && (
              <Button
                className="btn-cancel"
                size="tiny"
                basic
                color="red"
                floated="right"
                onClick={() => onUpdateMeeting(messageId, MeetingStatus.CANCELED, appointment)}
              >
                {t('networking.appointment.cancel-appointment')}
              </Button>
            )}
          </div>
        )}
        {status === 'confirmed' && (
          <>
            <Button
              className="btn-cancel"
              size="tiny"
              basic
              color="red"
              floated="right"
              onClick={() => onUpdateMeeting(messageId, MeetingStatus.CANCELED, appointment)}
            >
              {t('networking.appointment.cancel-appointment')}
            </Button>

            {/* TODO : in another PR */}
            {/*        <AddAppointmentToCalendar
                  className="add-to-calendar"
                  appointment={appointment}
                  sponsor={{ name: 'Rendez-vous avec XXX' }}
                />*/}
          </>
        )}
        {!userIsOwner && status === 'pending' && (
          <div className={css('Footer').mix('invitee')}>
            {!userIsOwner && otherUserStatus === 'pending' && (
              <>
                <Button
                  className="btn-reject"
                  size="tiny"
                  floated="right"
                  onClick={() => onUpdateMeeting(messageId, MeetingStatus.REFUSED, appointment)}
                >
                  {t('btn.refuse')}
                </Button>
                <Button
                  className="btn-confirm"
                  size="tiny"
                  floated="right"
                  onClick={() => onUpdateMeeting(messageId, MeetingStatus.CONFIRMED, appointment)}
                >
                  {t('btn.accept')}
                </Button>
              </>
            )}
          </div>
        )}
      </Card.Content>
    </Card>
  );
};
