import React from 'react';
import PropTypes from 'prop-types';
import SponsorsBlock from '../../components/sponsors/SponsorsBlock';

// type DataSource = { kind: string; collection: string };

const WorkshopSponsorsBlock = ({ item, field, header, dataSource }) => {
  const entries = item[field];
  if (!entries || entries.length === 0) return null;
  return <SponsorsBlock header={header} ids={entries} dataSource={dataSource} />;
};

WorkshopSponsorsBlock.defaultProps = {
  header: undefined,
  field: 'sponsors',
  dataSource: {
    kind: 'users',
    collection: 'sponsors',
  },
};

WorkshopSponsorsBlock.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string,
  header: PropTypes.string,
  dataSource: {
    kind: PropTypes.string,
    collection: PropTypes.string,
  },
};

export default WorkshopSponsorsBlock;
