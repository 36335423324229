import React from 'react';
import { useDesignConfig } from '../../../../config/design.context';
import { bem } from '../../../../core/design/bem';
import Images from '../../../../utils/Images';
import Hexagon from '../../../Hexagon';
import ImageIcon from '../../../ImageIcon';
import { Item } from '../variants/variant.types';
import './HexagonalItem.scss';
import { ItemConfig } from './ItemComponent';

const css = bem('HexagonalItem');

type ItemProps = {
  item: Item;
  variant: string;
  itemConfig: ItemConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>;

const HexagonalItem = ({ item, variant, itemConfig, style }: ItemProps): JSX.Element => {
  const design = useDesignConfig();
  const { primaryColor } = design;
  const { title, icon, url } = item;
  const { iconConfig, background: defaultBackground } = itemConfig || {};

  return (
    <div className={css({ variant })} data-id={item._id} style={style}>
      <Hexagon
        // stroke="#42873f"
        // strokeWidth={5}
        to={url}
        fill={primaryColor}
        backgroundImage={Images.maxWidth(
          item.background?.image?.uri ? item.background?.image : defaultBackground?.image,
          600,
        )}
      />
      <div className={css('Wrapper')}>
        <div className={css('Content')}>
          {!!icon && (
            <div className={css('Icon')}>
              <ImageIcon icon={icon} {...iconConfig} />
            </div>
          )}
          {!!title && <div className={css('Title')}>{title}</div>}
        </div>
      </div>
    </div>
  );
};

export default HexagonalItem;
