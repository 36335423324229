import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useConfig } from '../config/config.context';
import { useLoginPath } from '../config/screens.context';
import platformService from '../core/services/platform.service';
import pubsubService from '../core/websockets/pubsub.service';
import store from '../shared/Store';
import { useDisableFirebase, useFirebase } from './useFirebase';

async function wait(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const useWsSessionCheck = () => {
  const [isValidSession, setIsValidSession] = useState(true);

  const user = store.user || {};
  const id = user._id;
  const resourceType = user.collection;
  const config = useConfig();
  const connectedUserLimit = config?.auth?.connectedUserLimit && !store.impersonator;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!resourceType || !id) return () => {};

    const unsubPromise = Promise.resolve().then(async () => {
      if (!connectedUserLimit) return undefined;

      // Delay by a few seconds to be sure FB is up to date
      await wait(5000);

      async function handleSessionsChange(sessions) {
        // console.log('handleSessionsChange', sessions);
        if (sessions && store.sessionId !== sessions[0]) {
          // Query server...
          const res = await platformService.checkSession();
          if (!res.success) {
            store.disconnect();
            setIsValidSession(false);
          }
        }
      }

      return pubsubService.onDocument(
        `platform/${store.eventId}/versions/${resourceType}/items/${id}`,
        (data) => {
          const { sessions } = data ?? {};
          handleSessionsChange(sessions);
        },
      );
    });

    return () => {
      unsubPromise.then((unsub) => (unsub ? unsub() : undefined));
    };
  }, [resourceType, id, connectedUserLimit]);

  return isValidSession;
};

export const useSessionCheck = () => {
  const [isValidSession, setIsValidSession] = useState(true);
  const firebase = useFirebase();
  const user = store.user || {};
  const id = user._id;
  const resourceType = user.collection;
  const config = useConfig();
  const connectedUserLimit = config?.auth?.connectedUserLimit && !store.impersonator;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!resourceType || !id || !firebase) return () => {};

    const unsubPromise = Promise.resolve().then(async () => {
      if (!connectedUserLimit) return undefined;

      // Delay by a few seconds to be sure FB is up to date
      await wait(5000);

      async function handleSessionsChange(sessions) {
        if (store.sessionId !== sessions[0]) {
          // Query server...
          const res = await platformService.checkSession();
          if (!res.success) {
            store.disconnect();
            setIsValidSession(false);
          }
        }
      }

      return firebase
        .firestore()
        .doc(`platform/${store.eventId}/versions/${resourceType}/items/${id}`)
        .onSnapshot({
          next(snapshot) {
            if (!snapshot.exists) return;
            const { sessions } = snapshot.data() ?? {};
            handleSessionsChange(sessions);
          },
        });
    });

    return () => {
      unsubPromise.then((unsub) => (unsub ? unsub() : undefined));
    };
  }, [resourceType, id, firebase, connectedUserLimit]);

  return isValidSession;
};

export const SessionCheck = () => {
  const disableFirebase = useDisableFirebase();
  const useHook = disableFirebase ? useWsSessionCheck : useSessionCheck;
  const isValidSession = useHook();
  const loginPath = useLoginPath();
  if (isValidSession) return null;

  return <Redirect to={loginPath} />;
};
