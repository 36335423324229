import moment from 'moment/moment';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Segment } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import store from '../../../shared/Store';
import './TypologyBlock.scss';
import { useMe } from "../../../profile/hooks";

type TypologyBlockProps = {
  test: string;
};

const css = bem('TypologyBlock');

const translationPrefix = 'blocks.typology'
const TypologyBlock: FC<TypologyBlockProps> = () => {
  const user = useMe();
  const [typology, setTypology] = useState(user?.typology ?? 'digital');
  const { t } = useTranslation();

  const changeTypology = async (_e: any, _data: any) => {
    const newTypology = typology === 'physical' ? 'digital' : 'physical';
    await store.updateUser({
      typology: newTypology,
      updatedByUserAt: moment().toISOString(),
    });
    setTypology(newTypology);
  };

  const typologyState = t(`${translationPrefix}.state.${typology || 'digital'}`);
  const newTypology = t(`${translationPrefix}.switch-to.${typology === 'physical'? 'digital': 'physical'}`)

  return (
    <Segment className={css()}>
      <span>{typologyState}</span>
      <Button onClick={changeTypology}>{newTypology}</Button>
    </Segment>
  );
};

export { TypologyBlock };
