/* eslint-disable import/no-cycle,@typescript-eslint/no-unused-vars */
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react';

import { TrackingContext } from '../../Context';
import { AddAppointmentToCalendar } from '../../components/AppointmentAddToCalendarBlock/AppointmentAddToCalendarBlock';
import EntityLink from '../../components/EntityLink';
import { EntityTags } from '../../components/EntityTags';
import { AnimatorAccessButton } from '../../components/workshops/workshop-templates/actions/AnimatorAccessButton';
import { WorkshopActions } from '../../components/workshops/workshop-templates/actions/WorkshopActions';
import { cancelAppointment } from '../../sponsors/blocks/BookAppointmentBlock/utils';
import { groupEventsByDays } from '../../utils/agendaUtils';
import { validatesCondition } from '../../utils/conditionUtils';
import { localeFormat } from '../../utils/date';
import { entityForEvent } from '../../utils/hooks';
import { useWorkshopSessionRegistration } from '../../workshop-session/store/workshopSessions.hooks';
import { AddWorkshopToCalendar } from '../../workshops/blocks/WorkshopAddToCalendarBlock';
import { getClassName } from '../../workshops/utils';
import { useWorkshopRegistration } from '../../workshops/utils/workshopRegistrationUtils';
import UserScheduleWorkshopTemplate from '../blocks/UserScheduleWorkshopTemplate';
import { useMe } from '../hooks';
import AgendaCarousel from './AgendaCarousel/AgendaCarousel';
import AgendaWeek from './AgendaWeek';
import './Schedule.scss';
import { Title } from './schedule-event/Title';

export const DeleteEventButton = ({ event, icon = 'remove' }) => {
  const [loading, setLoading] = useState(false);
  const { unregisterWorkshop } = useWorkshopRegistration(event);
  const { unregisterWorkshopSession } = useWorkshopSessionRegistration(event);
  const { trackEvent } = useContext(TrackingContext);

  async function handleCancelEvent() {
    switch (event._type) {
      case 'workshop': {
        unregisterWorkshop();
        break;
      }
      case 'session': {
        unregisterWorkshopSession();
        break;
      }
      case 'appointment': {
        cancelAppointment(event, event.sponsor, { setLoading, trackEvent });
        break;
      }

      default: {
        break;
      }
    }
  }

  return (
    <Button
      color="red"
      className="cancel-button"
      onClick={handleCancelEvent}
      size="tiny"
      icon
      loading={loading}
      disabled={loading}
    >
      <Icon name={icon} />
    </Button>
  );
};

export const EventLocation = ({ location }) => {
  if (!location) return null;
  const isLink = location.indexOf('https://') !== -1 || location.indexOf('http://') !== -1;
  if (isLink) {
    return (
      <a className="location" href={location} target="_blank" rel="noopener noreferrer">
        <Icon name="map marker alternate" /> {location}
      </a>
    );
  }
  return (
    <span className="location">
      <Icon name="map marker alternate" /> {location}
    </span>
  );
};

const EventItem = ({ actions, event, hasPresenterAccess, tagFields }) => {
  const { t } = useTranslation();
  const { _id, appointment, endDate, mandatory, startDate, group, _type, location } = event;

  return (
    <Table.Row key={`schedule-workshop-${_id}`} className={getClassName(event)}>
      <Table.Cell className="date">
        {t(`profile.event-table.item.${!endDate ? 'startDate' : 'range'}`, {
          startDate,
          endDate,
        })}
      </Table.Cell>
      <Table.Cell className="title">
        <EntityLink entity={entityForEvent(event)}>
          <Title event={event} />
        </EntityLink>
        <EntityTags tagFields={tagFields} entity={event} type="workshop" />
        <EventLocation location={location} />
      </Table.Cell>

      {hasPresenterAccess && (
        <Table.Cell>
          <AnimatorAccessButton workshop={event} size="tiny" primary />
        </Table.Cell>
      )}
      <Table.Cell className="actions">
        <WorkshopActions workshop={event} actions={actions} style={{ display: 'flex' }} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {(_type === 'workshop' || _type === 'session') && (
            <AddWorkshopToCalendar workshop={event} primary />
          )}
          {_type === 'appointment' && (
            <AddAppointmentToCalendar appointment={appointment} sponsor={group} primary />
          )}
          &nbsp;
          {!mandatory && <DeleteEventButton event={event} />}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const EventsTable = ({ actions, events, tagFields = [] }) => {
  const { t } = useTranslation();
  const me = useMe();
  const hasPresenterAccess = !!events.find((w) => w.presenters?.includes(me?._id));
  return (
    <Table celled className="hide-mobile-header">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: 140 }}>{t('profile.timetable')}</Table.HeaderCell>
          <Table.HeaderCell>{t('profile.meeting')}</Table.HeaderCell>
          {hasPresenterAccess && (
            <Table.HeaderCell style={{ width: 160 }}>
              {t('profile.workshop-access')}
            </Table.HeaderCell>
          )}
          <Table.HeaderCell style={{ width: actions?.length > 0 ? 370 : 240 }}>
            {t('workshops.workshop.add-to-calendar')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {events.map((event) => (
          <EventItem event={event} hasPresenterAccess={hasPresenterAccess} tagFields={tagFields} />
        ))}
      </Table.Body>
    </Table>
  );
};

EventsTable.defaultProps = {
  actions: undefined,
};
EventsTable.propTypes = {
  actions: PropTypes.array,
  events: PropTypes.array.isRequired,
};

export const AgendaList = ({ actions, events, title, tagFields }) => {
  const { t } = useTranslation();
  const { days, eventsByDay } = groupEventsByDays(events);
  return (
    <Grid.Row stretched>
      <Grid.Column width={16}>
        <Segment className="segment--schedule">
          <Header as="h3">{title}</Header>
          <p className="description">{t(`profile.schedule.description`, '')}</p>
          {days.length === 0 && (
            <div className="empty-schedule" style={{ textAlign: 'center' }}>
              {t('profile.schedule.empty-agenda')}
            </div>
          )}
          {days.map((day) => {
            const dayFormat = !!day && localeFormat(parseISO(day), 'PPPP');
            const footer = t(`profile.schedule.footer-${day}`, '');
            return (
              <>
                <Header as="h4">{dayFormat}</Header>
                <EventsTable actions={actions} events={eventsByDay[day]} tagFields={tagFields} />
                {footer && <p className="schedule__footer">{footer}</p>}
              </>
            );
          })}
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

const variants = {
  list: AgendaList,
  workshops: UserScheduleWorkshopTemplate,
  agenda: AgendaWeek,
  carousel: AgendaCarousel,
};

const Schedule = (props) => {
  const {
    container,
    events,
    title,
    variant,
    _id,
    className,
    itemProps,
    condition: cond,
    ...rest
  } = props;
  const filteredEvents = cond ? events.filter((e) => validatesCondition(cond, e)) : events;
  const Component = variants[variant] || variants.list;
  return (
    <Component
      {...rest}
      _id={_id}
      className={className}
      container={container}
      events={filteredEvents}
      itemProps={itemProps}
      title={title}
    />
  );
};

Schedule.defaultProps = {
  className: undefined,
  container: undefined,
  _id: 'schedule',
  itemProps: undefined,
  condition: undefined,
  title: 'Agenda',
  variant: 'list',
};

Schedule.propTypes = {
  _id: PropTypes.string,
  events: PropTypes.array.isRequired,
  condition: PropTypes.object,
  className: PropTypes.string,
  container: PropTypes.object,
  itemProps: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default Schedule;
