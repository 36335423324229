/* eslint-disable react/no-array-index-key */
import get from 'lodash/get';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Icon } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { UserType } from '../../../core/users/users.types';
import i18n from '../../../translations/i18n';
import { ensureArray } from '../../../utils/arrayUtils';
import { replaceValues } from '../../../utils/stringUtils';
import './UserInfoTab.scss';
import { UpdateProfile } from './actions/UpdateProfile';

const css = bem('UserInfoTab');

type Field = {
  key: string;
  label: string;
  type?: string;
  mapping?: any;
  editable?: boolean;
  itemTemplate?: string;
};

type UserInfoProps = {
  user: UserType;
  fields: Field[];
  config?: Record<string, any>;
};

export const userDefaultFields: Field[] = [
  { key: 'firstName', label: i18n.t('users.field.firstName'), type: 'string', editable: true },
  { key: 'lastName', label: i18n.t('users.field.lastName'), type: 'string', editable: true },
  { key: 'email', label: i18n.t('users.field.email'), type: 'string', editable: true },
];

const renderField = (field: Field, value: any) => {
  const { type = 'string' } = field;
  switch (type) {
    case 'bool':
      return <Checkbox checked={value} readOnly />;
    case 'array': {
      const finalValue = typeof value === 'string' ? [value] : value;
      if (!Array.isArray(finalValue)) return '';
      const { mapping, itemTemplate = '{firstName} {lastName}' } = field;
      const formattedValues = finalValue
        .map((v) => {
          if (typeof v === 'object') {
            return replaceValues(itemTemplate, v);
          }
          if (typeof v !== 'string') return null;
          return get(mapping, v, v);
        })
        .filter((v) => v);
      return (
        <ul>
          {formattedValues.map((v) => (
            <li key={v}>{v}</li>
          ))}
        </ul>
      );
    }
    default:
      return value;
  }
};

export const UserInfoTab: FC<UserInfoProps> = (props) => {
  const { t } = useTranslation();
  const { user, fields = userDefaultFields, config } = props;
  const enableEdition = fields.filter((f) => f.editable).length > 0;
  const handleEditProfile = async () => {
    await UpdateProfile.open({ user, config });
  };
  const { file: fileConfig } = config || {};
  const fileFields = fileConfig?.field
    ? [{ icon: fileConfig.icon, key: fileConfig.field, type: 'file', label: fileConfig.label }]
    : [];
  return (
    <div className={css()}>
      <div className={css('fields')}>
        {fields.map((field) => {
          const { key, label, mapping, type } = field;
          const userValue = get(user, key);
          const value = get(mapping, userValue, userValue);
          if (type === 'header' && !!label) {
            return (
              <div className={css('field', { type })}>
                <h3>{label}</h3>
              </div>
            );
          }
          if (!value) return null;
          return (
            <div className={css('field', { key, type })}>
              <div className="label">{label}</div>
              <div className="value">{renderField(field, value)}</div>
            </div>
          );
        })}
        {fileFields.length > 0 && (
          <div className={css('field', { type: 'files' })}>
            <div className="label">{t('common.labels.files')}</div>
            {fileFields.map((field) => {
              const { key, label, icon } = field;
              const userValue = get(user, key);
              const fileArray = ensureArray(userValue);
              if (!fileArray?.length) return null;
              const hasMultiple = fileArray.length > 1;
              return fileArray?.map((file, index) => (
                <Button
                  key={index}
                  primary
                  basic
                  compact
                  as="a"
                  href={file.uri}
                  target="_blank"
                  fluid
                >
                  <Icon name={icon || 'download'} />
                  {label} {hasMultiple ? ` (${index + 1})` : undefined}
                </Button>
              ));
            })}
          </div>
        )}
      </div>
      {enableEdition && (
        <Button className={css('edit').toString()} primary onClick={handleEditProfile}>
          <Icon name="pencil" />
          {t('profile-v2.actions.edit-profile')}
        </Button>
      )}
    </div>
  );
};
