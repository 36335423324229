import EmojiPicker, { EmojiStyle, SuggestionMode, Theme } from 'emoji-picker-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icons } from '../../../components/Icons/material-icon.types';
import { QueryPopupButton } from '../../../components/QueryPopupButton/QueryPopupButton';
import { eventHandler } from '../../../utils/event.util';
import './EmotePicker.scss';

interface EmotePickerProps {
  onSelect: (emote: string) => void;
  iconSize?: number;
}
export const EmotePicker: FC<EmotePickerProps> = (props: EmotePickerProps) => {
  const { onSelect, iconSize = 16 } = props;
  const { t } = useTranslation();

  const onEmojiClick = (emojiData: { emoji: string }, event: MouseEvent, callback?: () => void) => {
    event.stopPropagation();
    onSelect(emojiData.emoji);
    callback?.();
  };

  return (
    <QueryPopupButton
      content={({ onCancel }: any) => (
        //This event handler is necessary to avoid trigger click on card when we click on emijo picker element
        <div onClick={(e) => eventHandler(e, () => null)} className="emote-picker__wrapper">
          <EmojiPicker
            height={350}
            width="unset"
            onEmojiClick={(data, e) => onEmojiClick(data, e, onCancel)}
            previewConfig={{ showPreview: false }}
            autoFocusSearch={false}
            theme={Theme.AUTO}
            lazyLoadEmojis
            suggestedEmojisMode={SuggestionMode.RECENT}
            searchPlaceHolder={t('filters.search')}
            skinTonesDisabled
            emojiStyle={EmojiStyle.NATIVE}
          />
        </div>
      )}
      icon={'add_reaction' as Icons}
      iconSize={iconSize}
      popupClassName="emote-picker__popup"
      panelClassName="emote-picker__panel"
    />
  );
};
