import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

import SendNotificationModal from './SendNotificationModal';

const SendNotificationButton: FC = () => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      data-action="send"
      className="Notification--Button button--mobile-action"
      onClick={() => SendNotificationModal.open()}
    >
      <Icon name="plus" />
      <span className="button--label">{t('notifications.send.button')}</span>
    </button>
  );
};

export default SendNotificationButton;
