import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useDataStore(collection) {
  return useSelector((state) => state.data.collections[collection]);
}

export function useDataIdList(collection, ids) {
  const dataStore = useDataStore(collection);
  return useMemo(() => {
    if (!dataStore || !ids) return [];
    const { dataById } = dataStore;
    return ids?.map((id) => dataById[id]).filter((v) => v);
  }, [dataStore, ids]);
}

export function useData(collection, id) {
  return (useDataStore(collection)?.dataById || {})[id];
}

export function useDataCollection(collection) {
  return useSelector((state) => state.data.collections[collection]?.data || []);
}
