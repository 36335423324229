/* eslint-disable @typescript-eslint/no-explicit-any */
import { bem } from '../../../core/design/bem';
import { useGlobalRanking } from '../../hooks/leaderboard.hooks';
import { CompleteRankingList } from '../components/CompleteRankingList';

const css = bem('Leaderboard__GlobalRanking');

type Props = {
  showTopThree?: boolean;
  subtitleTemplate: string;
};

export const GlobalRanking = (props: Props): JSX.Element => {
  const { showTopThree = true, subtitleTemplate } = props;
  const globalRanking = useGlobalRanking();

  return (
    <div className={css()}>
      <CompleteRankingList
        users={globalRanking}
        showTopThree={showTopThree}
        subtitleTemplate={subtitleTemplate}
      />
    </div>
  );
};

GlobalRanking.defaultProps = {
  showTopThree: false,
};
