/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';

type SocialNetworkType = {
  _id: string;
  icon: SemanticICONS;
  url: string;
};

type SocialNetworksType = {
  socialNetworks?: SocialNetworkType[];
};

const SocialNetworks = ({ socialNetworks = [] }: SocialNetworksType): JSX.Element | null => {
  if (!socialNetworks.length) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any, url: string) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, '_blank');
  };

  return (
    <div className="speaker-card--social-network">
      {socialNetworks.map(({ _id, icon, url }) => (
        <a key={_id} target="_blank" rel="noreferrer" onClick={(e) => handleClick(e, url)}>
          <Icon name={icon} size="big" />
        </a>
      ))}
    </div>
  );
};

SocialNetworks.defaultProps = {
  socialNetworks: [],
};

export default SocialNetworks;
