import { useQuery } from 'react-query';

import apiVideoService from '../../core/services/apiVideo.service';
import { delay } from '../../utils/asyncUtils';
import { UploadedVideo } from '../types/SocialWallV2.types';

interface VideoStatus {
  isPlayable: boolean;
  width?: number | string | null;
  height?: number | string | null;
}
const REFETCH_INTERVAL = 20000;

export const useSocialWallVideos = (): UploadedVideo[] => {
  const { data = [] } = useQuery(
    ['social-wall', 'videos'],
    async () => apiVideoService.getVideos(),
    {
      refetchInterval: REFETCH_INTERVAL,
    },
  );
  return data;
};

export const getVideoStatus = async (videoId: string): Promise<VideoStatus> => {
  for (let failSafe = 0; failSafe < 30; failSafe++) {
    //eslint-disable-next-line no-await-in-loop
    const uploadStatus = await apiVideoService.checkUploadStatus(videoId);
    //Usefull to debounce for loop
    //eslint-disable-next-line no-await-in-loop
    await delay(1000);

    const { playable, metadata } = uploadStatus?.encoding;
    const { width, height } = metadata || {};

    if (playable === true) {
      failSafe = 30;
      return { isPlayable: true, width, height };
    }
  }
  return { isPlayable: false };
};
