import ItemGalleryBlock from '../../../blocks/ItemGalleryBlock';
import ItemVideoBlock from '../../../blocks/ItemVideoBlock';
import WorkshopReplayBlock from '../../../blocks/ReplayBlock';
import { SuggestedWorkshops } from '../../../blocks/SuggestedWorkshops/SuggestedWorkshops';
import Description from './Description';
import Details from './Details';
import IframeRegistration from './IframeRegistration';
import Speakers from './Speakers';

export const workshopModalblockComponents = {
  'details': Details,
  'description': Description,
  'speakers': Speakers,
  'video': ({ session, ...rest }) => <ItemVideoBlock {...rest} item={session} />,
  'gallery': ({ session, ...rest }) => <ItemGalleryBlock {...rest} item={session} />,
  // eslint-disable-next-line react/prop-types
  'replay': ({ session, ...rest }) => <WorkshopReplayBlock {...rest} item={session} />,
  'iframe-registration': IframeRegistration,
  'suggestedWorkshops': ({ session, ...rest }) => <SuggestedWorkshops {...rest} item={session} />,
};
