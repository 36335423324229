import React, { FC, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { Button, Popup } from 'semantic-ui-react';

import { eventHandler } from '../../utils/event.util';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import GlobalModalManager from '../GlobalModalManager';
import MaterialIcon from '../Icons/MaterialIcon';
import { Icons } from '../Icons/material-icon.types';
import { QueryModal, QueryModalProps } from '../QueryModal/QueryModal';

interface QueryPopupButtonProps {
  content?: FC<{ onCancel?: () => void }>;
  icon?: Icons;
  iconSize?: number;
  popupClassName?: string;
  panelClassName?: string;
  modalProps?: QueryModalProps;
}

export const QueryPopupButton: FC<QueryPopupButtonProps> = (props: QueryPopupButtonProps) => {
  const { content, icon, iconSize, panelClassName, popupClassName, modalProps } = props;

  const [open, setOpen] = useState(false);

  const isMobile = useMedia(GLOBAL_MEDIA_QUERIES.mobile);

  const openModal = () => {
    GlobalModalManager.open(
      ({ onCancel, ...restProps }) => (
        <QueryModal
          className={panelClassName}
          draggable
          open
          onCancel={onCancel}
          closeOnDimmerClick
          {...modalProps}
          {...restProps}
        >
          {content && content({ onCancel })}
        </QueryModal>
      ),
      {},
    );
  };

  useEffect(() => {
    if (!isMobile) setOpen(false);
  }, [isMobile]);

  const TriggerIcon = () => <MaterialIcon name={icon} size={iconSize} />;
  return (
    <>
      {isMobile ? (
        <Button className="popup-trigger" onClick={(e) => eventHandler(e, openModal)}>
          {icon && <TriggerIcon />}
        </Button>
      ) : (
        <Popup
          className={popupClassName}
          on="click"
          basic
          position="bottom left"
          open={open}
          onClose={(e) => eventHandler(e, () => setOpen(false))}
          onOpen={(e) => eventHandler(e, () => setOpen(true))}
          trigger={<Button className="popup-trigger">{icon && <TriggerIcon />}</Button>}
        >
          {content && content({ onCancel: () => setOpen(false) })}
        </Popup>
      )}
    </>
  );
};
