import padStart from 'lodash/padStart';
import { FC } from 'react';

import { getString } from '../../utils';
import { CountdownItem, CountdownTimeLeft, defaultItemList } from '../Countdown/countdown.types';
import Flip from './Flip';
import './FlipCountdown.scss';


const translationPrefix = 'blocks.countdown';

type FlipCountdownProps = {
  digitStyle: 'single' | 'combined';
  itemList: CountdownItem[];
  timeLeft: CountdownTimeLeft;
  color?: string;
  background?: string;
  numberColor?: string;
};

const FlipCountdown: FC<FlipCountdownProps> = ({
  timeLeft,
  itemList = defaultItemList,
  digitStyle = 'single',
  background,
  color,
  numberColor,
}) => {
  return (
    <div
      className="flipClock"
      style={
        {
          '--ac-countdown-flip-background-color': background || undefined,
          '--ac-countdown-flip-number-color': numberColor || undefined,
          '--ac-countdown-flip-label-color': color || undefined,
        } as any
      }
    >
      {itemList.map((item) => {
        const { key, label } = item;
        return (
          <Flip
            key={key}
            digitStyle={digitStyle}
            value={padStart(`${timeLeft?.[key]}`, 2, '0')}
            label={label || getString(`${translationPrefix}.${key}`)}
          />
        );
      })}
    </div>
  );
};

export default FlipCountdown;
