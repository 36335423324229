import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { containerTypePropTypes } from '../../propTypes';
import BlockHeader from '../BlockHeader';
import ImageIcon from '../ImageIcon';
import './BlockContainer.scss';
import { generateDataAttributes } from './utils';

export const HtmlHeader = ({ content, ...props }) => {
  if (!content) return null;
  if (content.indexOf('</') !== -1) {
    // Oops, html
    return (
      <Header {...props}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </Header>
    );
  }
  return <Header {...props} content={content} />;
};

HtmlHeader.defaultProps = {
  content: undefined,
};

HtmlHeader.propTypes = {
  content: PropTypes.string,
};

const BlockContainer = ({
  id,
  className,
  childWrapperClassName,
  type,
  header,
  headerLevel = 'h3',
  children,
  data,
  icon,
}) => {
  const containerClassName = cx(`block-container block-container--${type}`, className);
  const defaultContainerProps = { className: containerClassName, ...generateDataAttributes(data) };

  if (type === 'segment') {
    return (
      <Segment.Group id={id} {...defaultContainerProps}>
        {header && (
          <Segment className="segment--header">
            <HtmlHeader as={headerLevel} content={header} />
            {icon && <ImageIcon icon={icon} />}
          </Segment>
        )}
        <Segment className={cx('segment--content', childWrapperClassName)}>{children}</Segment>
      </Segment.Group>
    );
  }
  if (type === 'segment-light') {
    return (
      <Segment id={id} {...defaultContainerProps}>
        {header && <HtmlHeader as={headerLevel} content={header} />}
        {children}
      </Segment>
    );
  }
  if (type === 'basic') {
    return (
      <div id={id} {...defaultContainerProps}>
        {header && <BlockHeader title={header} headerLevel={headerLevel} />}
        {children}
      </div>
    );
  }
  if (type === 'section')
    return (
      <div id={id} {...defaultContainerProps}>
        {header && <BlockHeader title={header} headerLevel={headerLevel} />}
        {children}
      </div>
    );

  return (
    <div id={id} {...defaultContainerProps}>
      {children}
    </div>
  );
};

BlockContainer.defaultProps = {
  childWrapperClassName: undefined,
  className: undefined,
  data: undefined,
  id: undefined,
  header: undefined,
  headerLevel: undefined,
  type: 'segment',
  icon: undefined,
};

BlockContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  childWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.object,
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  header: PropTypes.string,
  headerLevel: PropTypes.string,
  type: containerTypePropTypes,
};

export const BlockContainerPropType = PropTypes.shape({
  childWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  headerLevel: PropTypes.string,
  type: containerTypePropTypes,
});

export default BlockContainer;
