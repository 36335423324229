/* eslint-disable operator-linebreak */
/* eslint-disable react/require-default-props */
import React from 'react';
import { ItemConfig } from '../components/ItemComponent';
import { GridLayoutVariant } from './GridLayoutVariant';
import { IconGridVariantProps } from './variant.types';

const GridVariant = (props: IconGridVariantProps & { itemConfig?: ItemConfig }): JSX.Element => {
  const { items, itemConfig } = props;
  const { itemsPerRow = 3 } = itemConfig || {};

  const currentPos = { x: 0, y: 0 };
  function computePos(width = 1) {
    if (currentPos.x + width > itemsPerRow) {
      // Doesn't fit
      currentPos.x = 0;
      currentPos.y++;
    }
    const { x, y } = currentPos;
    currentPos.x += width;
    if (currentPos.x >= itemsPerRow) {
      currentPos.x = 0;
      currentPos.y++;
    }
    return { x, y, w: width, h: 1 };
  }

  const gridItems = items.map((item) => ({
    ...item,
    gridConfig: computePos(item.width || 1),
  }));

  return <GridLayoutVariant {...props} items={gridItems} />;
};

GridVariant.defaultProps = {
  itemConfig: {},
};

export { GridVariant };
