import { validatesCondition } from '@appcraft/common-utils';
import { keyBy } from 'lodash';
import React, { FC, useMemo } from 'react';

import useUrlState from '../../../hooks/useUrlState';
import { useMe } from '../../../profile/hooks';
import { ConditionContext } from '../../../utils/conditionUtils';
import BlockContainer from '../../BlockContainer';
import { TabbedMenu } from '../../TabbedMenu/TabbedMenu';
import IframeBlock from '../IframeBlock';
import { BlockProps } from '../types';

type InteractionsBlockProps = BlockProps & {
  tabs: {
    _id: string;
    type: string;
    url: string;
    visibleBy?: ConditionContext;
    label?: string;
    lang?: string;
    isAnonymous?: boolean;
  }[];
};

export const InteractionsBlock: FC<InteractionsBlockProps> = (props) => {
  const { container, className, tabs, color, height } = props;
  const user = useMe();

  const filteredTabs = useMemo(() => {
    return tabs.filter((tab) => {
      return tab.visibleBy ? validatesCondition(tab.visibleBy, user) : true;
    });
  }, [tabs, user]);

  const [urlState, setUrlState] = useUrlState(
    {
      activeTab: filteredTabs?.[0]?._id || undefined,
    },
    { navigateMode: 'replace' },
  );
  const { activeTab } = urlState;

  const formattedTabs = filteredTabs.map((tab) => ({
    ...tab,
    name: tab._id,
    title: tab?.label || tab.type,
  }));
  const tabsById = keyBy(filteredTabs, '_id');

  const selectedIFrame = useMemo(() => {
    const selectedTab = tabsById[activeTab];

    return {
      autoResize: selectedTab?.type === 'feedback',
      src: selectedTab?.url,
      iframeType: selectedTab?.type,
      height,
      ...selectedTab,
    };
  }, [activeTab, tabsById]);

  return (
    <BlockContainer {...container} className={className}>
      <TabbedMenu
        color={color}
        activeTab={activeTab}
        tabs={formattedTabs}
        onClick={(e: any, { name }: { name: string }) => setUrlState({ activeTab: name })}
      />
      <IframeBlock {...selectedIFrame} />
    </BlockContainer>
  );
};
