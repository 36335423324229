import cx from 'classnames';
import React, { useMemo } from 'react';
import { useMedia } from 'react-media';
import { matchPath } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useAvailableScreenPaths } from '../../../config/screens.context';
import { bem } from '../../../core/design/bem';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import BlockContainer from '../../BlockContainer';
import CarouselBlock from '../CarouselBlock';
import CTATileBlock from '../CTATileBlock';
import { ContainerProps, Image } from '../types';
import './CTATilesBlock.scss';

const css = bem('CTATilesBlock');

type CTATileAnimation = 'scale';
type CTATileVariant = 'segment' | 'square';

export type CTATileBlockProps = {
  _id: string;
  animation?: CTATileAnimation;
  backgroundImage?: Image;
  doubling: boolean;

  icon?: string | Record<string, any>;
  subtitle?: string;
  title: string;
  to?: string;
  variant?: CTATileVariant;
};

type CTATilesBlockProps = {
  _id: string;
  animation?: CTATileAnimation;
  blocks?: CTATileBlockProps[];
  className?: string;
  container?: ContainerProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridProps?: any;
  imageProps?: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemGridProps?: any;
  itemsPerRow?: number;
  style?: Record<string, string>;
  target?: string;
  variant?: CTATileVariant;
  onClick?: (block: CTATileBlockProps) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const CTATilesBlock = ({
  _id,
  animation,
  variant,
  container,
  blocks,
  className,
  gridProps,
  itemGridProps,
  itemsPerRow,
  imageProps,
  onClick,
  ...rest
}: CTATilesBlockProps): JSX.Element | null => {
  const { desktop } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  // Keep only visible pages
  const screensByPath = useAvailableScreenPaths();

  const hasOnClick = !!onClick;
  const visibleBlocks = useMemo(() => {
    if (!blocks) return null;

    if (hasOnClick) {
      return blocks; // No need to filter, keep everything
    }

    const dynamicPaths = Object.keys(screensByPath).filter((path) => path.includes(':'));
    return blocks.filter((b) => {
      if (b.to?.startsWith('/')) {
        // Found local path
        if (b.to in screensByPath) return true;

        // Not found, check if it matches something ?
        return !!dynamicPaths.find((path) => matchPath(b.to || '', { path, exact: true }));
      }
      return true; // Other type of link
    });
  }, [blocks, screensByPath, hasOnClick]);

  if (!visibleBlocks || visibleBlocks.length < 0) return null;
  const { mobile = {}, showMoreButton = false, stretched = false } = rest;
  const { variant: mobileVariant, carousel } = mobile;

  const centeredGrid = itemsPerRow && visibleBlocks.length < itemsPerRow;
  const blockClassname = css({ id: _id, variant })
    .mix(className || container?.className)
    .toString();

  if (!desktop && mobileVariant === 'carousel') {
    return (
      <CarouselBlock
        _id={_id}
        carousel={carousel}
        className={blockClassname}
        container={container}
        items={visibleBlocks}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component={(block: any) => (
          <CTATileBlock
            animation={animation}
            variant={variant}
            {...itemGridProps}
            {...block}
            maxImageWidth={itemsPerRow && itemsPerRow >= 2 ? 600 : 1200}
            aspectRatio
            imageProps={imageProps}
            showMoreButton={showMoreButton}
          />
        )}
      />
    );
  }
  return (
    <BlockContainer {...container} className={blockClassname}>
      <Grid
        stretched={stretched}
        className={className}
        doubling
        columns={itemsPerRow}
        centered={centeredGrid}
        {...gridProps}
      >
        {visibleBlocks.map((block) => (
          <Grid.Column key={block._id} className={cx({ doubling: block.doubling })}>
            <CTATileBlock
              animation={animation}
              variant={variant}
              {...itemGridProps}
              {...block}
              onClick={onClick ? () => onClick(block) : undefined}
              maxImageWidth={itemsPerRow && itemsPerRow >= 2 ? 600 : 1200}
              imageProps={imageProps}
              showMoreButton={showMoreButton}
            />
          </Grid.Column>
        ))}
      </Grid>
    </BlockContainer>
  );
};

CTATilesBlock.defaultProps = {
  animation: undefined,
  blocks: [],
  container: {},
  className: undefined,
  imageProps: {},
  itemGridProps: {},
  itemsPerRow: 2,
  gridProps: {},
  onClick: undefined,
  target: undefined,
  style: undefined,
  variant: undefined,
};

export default CTATilesBlock;
