export const wrapStopPropagation = <E extends Pick<Event, 'preventDefault' | 'stopPropagation'>, R>(
  fn?: (e: E) => R,
) => {
  return (e: E): R | void => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if (typeof fn === 'function') {
      return fn(e);
    }
    return undefined;
  };
};

export const stopPropagation = wrapStopPropagation();
