import get from 'lodash/get';
import { FC } from 'react';
import { Container } from 'semantic-ui-react';

import AppHeader from '../../../components/AppHeader';
import AppMenu from '../../../components/menu/AppMenu';
import AppSecondaryMenu from '../../../components/menu/AppSecondaryMenu';
import MenuMobile from '../../../components/menu/MenuMobile';
import { useConfig } from '../../../config/config.context';
import { useDesignConfig } from '../../../config/design.context';
import { useLoginPath } from '../../../config/screens.context';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useMe } from '../../../profile/hooks';
import { MenuItem } from '../../../types/config.types';
import BurgerMenu from '../../VideoBackground/components/BurgerMenu';

type HorizontalMenuProps = {
  menu: MenuItem[];
};

// TODO : transformer en Menus ?
export const HorizontalMenu: FC<HorizontalMenuProps> = ({ menu }) => {
  const user = useMe();

  const { mobile, desktop } = useMediaQuery();

  const { lang, languages, menus, mobileLanguagePosition, disconnectionMode } = useConfig();
  const loginPath = useLoginPath();

  const design = useDesignConfig();
  const { menu: menuConfig = {} } = design;

  if (mobile || menuConfig.mobileOnly) {
    return (
      <MenuMobile
        menu={[
          ...menu?.map((m) => ({ ...m, menuType: 'main' })),
          ...(menus?.secondary?.entries ?? []).map((m) => ({ ...m, menuType: 'secondary' })),
        ].filter((m) => m.type !== 'search')}
        language={lang}
        languageOptions={languages}
        languagePosition={mobileLanguagePosition}
        design={design}
        user={user}
        loginPath={loginPath}
        disconnectionMode={disconnectionMode}
      />
    );
  }

  return (
    <div>
      {desktop && (
        <div className="menu-secondary-wrapper">
          <Container>
            <AppSecondaryMenu menu={menus?.secondary} />
          </Container>
        </div>
      )}
      <div
        className={`menu-wrapper menu-wrapper--${get(menuConfig, 'wrapper.variant', 'logoTop')}`}
      >
        <Container>
          <AppHeader
            language={lang}
            languageOptions={
              menus?.secondary?.entries.find((e) => e.type === 'languages') ? undefined : languages
            }
          />
          {menuConfig?.variant === 'burger' ? (
            <BurgerMenu menu={menu} user={user} menuConfig={menuConfig} loginPath={loginPath} />
          ) : (
            <AppMenu
              menu={menu}
              menuConfig={menuConfig}
              variant={menuConfig?.entries?.variant}
              loginPath={loginPath}
              disconnectionMode={disconnectionMode}
            />
          )}
        </Container>
      </div>
    </div>
  );
};

HorizontalMenu.defaultProps = {
  menu: [],
};
