import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Progress, Segment } from 'semantic-ui-react';
import { chunk } from 'lodash';
import BadgeList from '../../data/badge';

const maxColumnByRow = 16;

const GridList = ({
  name,
  title,
  description,
  image = 'NoBadge',
  points,
  rewards,
  columnByRow,
}) => {
  return (
    <Grid.Column width={maxColumnByRow / columnByRow} style={{ textAlign: 'center' }}>
      <h3>
        {points} <Icon name="star" />{' '}
      </h3>
      <img src={rewards[name] ? BadgeList[image] : BadgeList.NoBadge} alt="badge not owned yet" />
      <br />
      <div>
        <h3>{title}</h3>
        <span>{description}</span>
        <br />
      </div>
    </Grid.Column>
  );
};

GridList.defaultProps = {
  title: 'No Title Provided',
  description: 'No Description provided',
  image: 'No Badge',
  rewards: {},
  columnByRow: 4,
};

GridList.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  points: PropTypes.number.isRequired,
  rewards: PropTypes.object,
  columnByRow: PropTypes.number,
};
const getProgressPercent = ({ rewards }, missionList) => {
  const totalPoints = missionList.reduce((acc, item) => acc + item.points || 0, 0);
  return missionList.reduce((acc, item) => {
    let currentAccount = acc;
    if (rewards[item.name]) {
      currentAccount += Math.round((item.points * 100) / totalPoints || (1 * 100) / totalPoints);
    }
    return currentAccount;
  }, 0);
};

const getmissionFinished = (rewards = {}) =>
  (rewards && Object.values(rewards).filter((v) => v === true)) || [];

const Rewards = ({ rewards, missionList, user, columnByRow }) => (
  <Grid.Row stretched>
    <Grid.Column width={16}>
      <h2>
        <b>
          {getmissionFinished(rewards).length} / {missionList.length} Trophées{' '}
          <Progress percent={getProgressPercent(user, missionList)} progress color="blue" />
        </b>
      </h2>
    </Grid.Column>
    <Grid.Column width={16}>
      <Segment>
        <Grid celled>
          {chunk(missionList, columnByRow).map((missions) => (
            <Grid.Row columns={columnByRow}>
              {missions.map((m) => (
                <GridList {...m} rewards={rewards} columnByRow={columnByRow} />
              ))}
            </Grid.Row>
          ))}
        </Grid>
      </Segment>
    </Grid.Column>
  </Grid.Row>
);

Rewards.propTypes = {
  missionList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      points: PropTypes.number,
      image: PropTypes.string,
    }),
  ).isRequired,
};

export default Rewards;
