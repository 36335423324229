import { FC } from 'react';
import { Button } from 'semantic-ui-react';
import { getString } from '../../../../utils';
import {
  useRegistrationsById,
  useWorkshopRegistration,
} from '../../../../workshops/utils/workshopRegistrationUtils';

type UnregisterButtonProps = {
  workshop: any;
};

const translationPrefix = 'workshops.workshop';
const UnregisterButton: FC<UnregisterButtonProps> = (props) => {
  const { workshop } = props;
  const { unregisterWorkshop, loading } = useWorkshopRegistration(workshop);
  const { mandatory, _id, workshopId } = workshop;

  const registrations = useRegistrationsById(!!workshopId);
  const userRegistration = registrations[workshopId] || registrations[_id];

  if (mandatory || !userRegistration) {
    return null;
  }

  return (
    <Button
      color="red"
      onClick={unregisterWorkshop}
      loading={loading}
      disabled={loading}
      size="tiny"
      style={{ width: '100%', marginTop: 5 }}
    >
      {getString(`${translationPrefix}.unregister`)}
    </Button>
  );
};

UnregisterButton.defaultProps = {};

export { UnregisterButton };
