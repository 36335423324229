import { ContactsProps } from '../components/Contacts';
import { ContactsDesign } from '../types';
import { ContactsVariantBadge } from './ContactsVariantBadge';
import './ContactsVariantContactCard.scss';
import { ContactsVariantCompact } from './ContactsVariantDefault';
import ContactsVariantInfocard from './ContactsVariantInfocard';
import ContactsVariantLegacy from './ContactsVariantLegacy';
import ContactsVariantMaterial from './ContactsVariantMaterial';
import ContactsVariantVisitcard from './ContactsVariantVisitcard';

export type ContactVariants =
  | 'legacy'
  | 'default'
  | 'compact'
  | 'badge'
  | 'contact-card'
  | 'visit-card'
  | 'info-card'
  | 'material'
  | 'material-high';

export const variants: Record<
  string,
  (props: ContactsProps & { design: ContactsDesign }) => JSX.Element
> = {
  'legacy': ContactsVariantLegacy,
  'default': ContactsVariantCompact,
  'compact': ContactsVariantCompact,
  'badge': ContactsVariantBadge,
  'contact-card': ContactsVariantBadge,
  'visit-card': ContactsVariantVisitcard,
  'info-card': ContactsVariantInfocard,
  'material': ContactsVariantMaterial,
  'material-high': ContactsVariantMaterial,
};
