/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-danger */
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { Modal, Label } from 'semantic-ui-react';

import './DataModal.scss';

import CdnImage from '../../../components/CdnImage';
import { bem } from '../../../core/design/bem';
import { getEntityString } from '../../../utils';
import { DataItem } from '../../types';

const css = bem('DataModal');
const extraFieldCss = bem('extraField');

type DataProps = {
  item: DataItem;
  onClose: any;
};

type Field = {
  key: string;
  label?: string;
  type: string;
} & Record<string, any>;

type FieldContainerProps = {
  field: Field;
  children: any;
};

const FieldContainer = ({ field, children }: FieldContainerProps) => {
  const { key, style } = field;
  const className = extraFieldCss({ key }).toString();
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

const DataModal = (props: DataProps): JSX.Element | null => {
  const { item, onClose, ...config } = props;
  const { extraFields = [] } = config as any;
  const { name, shortDescription, description, collection } = item;

  const renderField = (field: Field) => {
    const { key, type, ...rest } = field;
    const value = get(item, key);
    if (!value) return null;
    if (type === 'image')
      return (
        <FieldContainer field={field}>
          <CdnImage src={value} maxHeight={80} maxWidth={150} {...rest} />
        </FieldContainer>
      );
    if (type === 'date')
      return (
        <FieldContainer field={field}>
          {moment(value).format(rest.format || 'YYYY-MM-DD')}
        </FieldContainer>
      );
    return (
      <FieldContainer field={field}>
        <div dangerouslySetInnerHTML={{ __html: value }} />
      </FieldContainer>
    );
  };

  return (
    <Modal open size="tiny" onClose={onClose} className={css().toString()} closeIcon>
      <Modal.Header as="h1" className={css('name').toString()}>
        {name}
      </Modal.Header>
      <Modal.Content scrolling className={css('content').toString()}>
        <Modal.Description>
          <Label className="category">{getEntityString(item, collection, 'category')}</Label>
          <p className="shortDescription">{shortDescription}</p>
        </Modal.Description>
        {description && (
          <Modal.Description
            className="fullDescription"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {extraFields.length > 0 && (
          <div className={css('extra')}>{extraFields?.map(renderField)}</div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default DataModal;
