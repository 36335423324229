import React from 'react';

import { UserRanking } from '../../hooks/leaderboard.hooks';
import { Podium } from './Podium';
import { RankingList } from './RankingList';

type Props = {
  showTopThree: boolean;
  users: UserRanking[];
  subtitleTemplate: string;
};
export const CompleteRankingList = (props: Props): JSX.Element => {
  const { showTopThree, users = [], subtitleTemplate } = props;
  const topThree = users.slice(0, 3);
  const showPodium = showTopThree && topThree.length === 3;
  const listUsers = showPodium ? users.slice(3) : users;
  const showList = listUsers.length > 0;
  return (
    <>
      {showPodium && <Podium users={topThree} subtitleTemplate={subtitleTemplate} />}
      {showList && (
        <RankingList
          showTopThree={showTopThree}
          users={listUsers}
          subtitleTemplate={subtitleTemplate}
        />
      )}
    </>
  );
};
