import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import VideoBlock from '../../components/cms/VideoBlock';

function Video({ item, name, ...props }) {
  const video = get(item, name);
  return <VideoBlock {...props} video={video} item={item} />;
}

Video.defaultProps = {
  name: 'video',
};

Video.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string,
};

export default Video;
