import { areIntervalsOverlapping, parseISO } from 'date-fns';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';

import { momentTz } from './date';

export function findOverlappingEvents(events, selectedEvent) {
  if (!selectedEvent.startDate || !selectedEvent.endDate) return false;
  const rightInterval = {
    start: parseISO(selectedEvent.startDate),
    end: parseISO(selectedEvent.endDate),
  };
  return events.filter((event) => {
    if (!event.startDate || !event.endDate) return false; // Invalid date
    const leftInterval = {
      start: parseISO(event.startDate),
      end: parseISO(event.endDate),
    };
    try {
      return areIntervalsOverlapping(leftInterval, rightInterval);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e, leftInterval, rightInterval);
      return false;
    }
  });
}

export function findOverlappingEvent(events, selectedEvent) {
  return findOverlappingEvents(events, selectedEvent)[0];
}

export function checkUserAvailability(events, selectedEvent) {
  return !findOverlappingEvent(events, selectedEvent);
}

export function groupEventsByDays(events) {
  const eventsByDay = groupBy(events, (event) => {
    if (!event.startDate) return '';
    return momentTz(parseISO(event.startDate)).format('YYYY-MM-DD');
  });
  const days = keys(eventsByDay).sort();
  return { days, eventsByDay };
}
