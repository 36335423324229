import React from 'react';

import { useComponentOptions } from '../../../config/design.context';
import { bem } from '../../../core/design/bem';
import SquareImage from '../../SquareImage';
import { ActionItem, ContactItem, InfoProps } from '../types';
import { ContactVariants } from '../variants';
import Actions from './Actions';
import './Contact.scss';
import ItemInfos from './ItemInfos';

const css = bem('Contact');

export type ContactBlockProps = {
  item: ContactItem;
  variant: ContactVariants;
  actions: ActionItem[];
  infos: InfoProps[];
};

const Contact = ({ item, variant, actions, infos }: ContactBlockProps): JSX.Element => {
  const imageProps = useComponentOptions('thumbnails');
  return (
    <div className={css({ variant })}>
      <div className={css('Wrapper')}>
        <div className={css('Profile')}>
          <div className={css('Thumbnail')}>
            <SquareImage src={item.thumbnail} imageSize={200} {...imageProps} />
          </div>
          <ItemInfos item={item} infos={infos} />
        </div>
        <div className={css('Actions')}>
          <Actions item={item} actions={actions} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
