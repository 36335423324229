/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from '@firebase/app';
import { eventChannel } from 'redux-saga';
import { all, call, put, race, select, take, takeEvery } from 'redux-saga/effects';

import store from '../../shared/Store';
import { getFirestore } from '../../store/effects/firebase.effects';
import { pipeChannel } from '../../store/effects/sagas.effects';
import {
  messageDeleted,
  networkingChatMessagesLoaded,
  networkingChatStarted,
  networkingChatStopped,
} from './networking.actions';
import {
  extractDataFromDoc,
  extractOtherProfile,
  getInitialDocsAndStartQueryAfter,
} from './networking.helpers';
import {
  CHAT_DELETE_MESSAGE,
  CHAT_SEND_MEETING_REQUEST,
  CHAT_SEND_MESSAGE,
  CHAT_SEND_VISIO_ENDED,
  CHAT_SEND_VISIO_REQUEST,
  CHAT_UPDATE_MEETING_REQUEST,
  NetworkingChatsCollectionKey,
  NetworkingCollectionKey,
  NetworkingMessagesCollectionKey,
  START_CHAT,
  STOP_CHAT,
} from './networking.types';

function formatChatMessages(snapshot) {
  return snapshot.docs.map((messageDoc) => {
    return { id: messageDoc.id, ...messageDoc.data() };
  });
}

function* createChatAndMessagesRef(chatId) {
  const firestore = yield getFirestore();
  const { eventId } = store;
  const chatRef = firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(chatId);
  const messagesRef = chatRef.collection(NetworkingMessagesCollectionKey);

  return {
    chatRef,
    messagesRef,
  };
}

function flushChannelReadCount(chatRef) {
  chatRef.update({ [`unreadCount.${store.userId}`]: 0 });
}

function* createChatChannel(chatId) {
  try {
    const { chatRef, messagesRef } = yield* createChatAndMessagesRef(chatId);

    const { docs, query } = yield getInitialDocsAndStartQueryAfter(
      messagesRef.orderBy('createdAt', 'asc'),
    );

    const initialMessages = docs.map(extractDataFromDoc);
    flushChannelReadCount(chatRef);

    yield put(networkingChatMessagesLoaded(chatId, initialMessages));

    const channel = yield eventChannel((emit) => {
      return query.onSnapshot({
        next(snapshot) {
          const messages = formatChatMessages(snapshot);
          emit(networkingChatMessagesLoaded(chatId, messages));
          flushChannelReadCount(chatRef);
        },
      });
    });

    yield pipeChannel(channel);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('failed', e);
  }
}

function* subscribeToChat(startEvent) {
  const { chatId } = startEvent.payload;
  try {
    yield put(networkingChatStarted(chatId));
    yield race({ task: call(createChatChannel, chatId), stop: take(STOP_CHAT) });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to subscribe to chat ${chatId}`, e);
  } finally {
    yield put(networkingChatStopped(chatId));
  }
}

function* sendMessage(event) {
  const firestore = yield getFirestore();
  const self = yield select((state) => state.user.user);
  const { eventId } = self;
  const { chat, messageId, message } = event.payload;
  const chatDoc = firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(chat.id);

  yield chatDoc.collection(NetworkingMessagesCollectionKey).add({
    id: messageId,
    chatId: chat.id,
    emitter: self._id,
    createdAt: new Date().toISOString(),
    text: message,
  });
  // Increment unread count
  const otherUser = extractOtherProfile(chat);
  const increment = firebase.firestore.FieldValue.increment(1);
  chatDoc.update({
    updatedAt: new Date().toISOString(),
    [`unreadCount.${otherUser._id}`]: increment,
  });
}
function* sendMeetingRequest(event) {
  const firestore = yield getFirestore();
  const self = yield select((state) => state.user.user);
  const { eventId } = self;
  const { chat, appointment } = event.payload;
  const chatDoc = firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(chat.id);
  yield chatDoc.collection(NetworkingMessagesCollectionKey).add({
    chatId: chat.id,
    emitter: self._id,
    createdAt: new Date().toISOString(),
    type: 'appointment',
    appointment,
  });
  // Increment unread count
  const otherUser = extractOtherProfile(chat);
  const increment = firebase.firestore.FieldValue.increment(1);
  chatDoc.update({
    [`unreadCount.${otherUser._id}`]: increment,
  });
}

function* updateMeetingRequest(event) {
  const firestore = yield getFirestore();
  const self = yield select((state) => state.user.user);
  const { eventId } = self;
  const { chat } = event.payload;

  const chatDoc = firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(chat.id);

  // Increment unread count
  const otherUser = extractOtherProfile(chat);
  const increment = firebase.firestore.FieldValue.increment(1);
  chatDoc.update({
    [`unreadCount.${otherUser._id}`]: increment,
  });
}

function* sendVisioRequest(event) {
  const firestore = yield getFirestore();
  const self = yield select((state) => state.user.user);
  const { eventId } = self;
  const { chat, visio } = event.payload;
  const chatDoc = firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(chat.id);
  yield chatDoc.collection(NetworkingMessagesCollectionKey).add({
    chatId: chat.id,
    emitter: self._id,
    createdAt: new Date().toISOString(),
    type: 'visio-request',
    visio,
  });
  // Increment unread count
  const otherUser = extractOtherProfile(chat);
  const increment = firebase.firestore.FieldValue.increment(1);
  chatDoc.update({
    [`unreadCount.${otherUser._id}`]: increment,
  });
}

function* sendVisioEnded(event) {
  const firestore = yield getFirestore();
  const self = yield select((state) => state.user.user);
  const { eventId } = self;
  const { chat, messageId } = event.payload;
  const chatDoc = firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(chat.id);
  yield chatDoc
    .collection(NetworkingMessagesCollectionKey)
    .doc(messageId)
    .update({ 'visio.state': 'finished' });
}

function* deleteMessage(event) {
  const firestore = yield getFirestore();
  const self = yield select((state) => state.user.user);
  const { eventId } = self;
  const { chat, messageId } = event.payload;
  const chatDoc = firestore
    .collection(NetworkingCollectionKey)
    .doc(eventId)
    .collection(NetworkingChatsCollectionKey)
    .doc(chat.id);
  yield chatDoc.collection(NetworkingMessagesCollectionKey).doc(messageId).delete();
  yield put(messageDeleted(chat.id, messageId));
}

export default function* chatsSagas() {
  yield all([
    takeEvery(START_CHAT, subscribeToChat),
    takeEvery(CHAT_SEND_MESSAGE, sendMessage),
    takeEvery(CHAT_SEND_VISIO_REQUEST, sendVisioRequest),
    takeEvery(CHAT_SEND_VISIO_ENDED, sendVisioEnded),
    takeEvery(CHAT_DELETE_MESSAGE, deleteMessage),
    takeEvery(CHAT_SEND_MEETING_REQUEST, sendMeetingRequest),
    takeEvery(CHAT_UPDATE_MEETING_REQUEST, updateMeetingRequest),
  ]);
}
