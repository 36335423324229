/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List, Popup, SemanticICONS } from 'semantic-ui-react';

import { useScreenConfig } from '../../config/screens.context';
import { bem } from '../../core/design/bem';
import { useChatContext } from '../../networking/context/chat.context';
import GlobalModalManager from '../GlobalModalManager';
import { AddMeetingRequestModal } from './Appointments/AddMeetingRequestModal';
import { MeetingRequest } from './Appointments/types';
import { createDefaultMeeting } from './Appointments/utils';
import './ChatOptions.scss';

const css = bem('ChatOptions');

type Props = {
  onStartVideoCall: any;
  onAddMeeting?: (meeting: MeetingRequest) => void;
};

export const ChatOptions: FC<Props> = (props) => {
  const { onStartVideoCall, onAddMeeting } = props;
  const { otherUsers, pageId } = useChatContext();
  const networkingConfig = useScreenConfig(pageId);
  const appointmentConfig = networkingConfig?.chat?.appointmentConfig ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const openRequestMeetingModal = async () => {
    if (!onAddMeeting) return;

    setIsOpen(false);
    const meeting = await GlobalModalManager.open(AddMeetingRequestModal, {
      defaultRequest: createDefaultMeeting(otherUsers[0], appointmentConfig),
      appointmentConfig,
    });
    if (meeting) {
      onAddMeeting(meeting);
    }
  };
  const configList = [
    {
      onClick: onStartVideoCall,
      icon: 'video camera' as SemanticICONS,
      label: t('networking.options.video-call'),
    },
    {
      onClick: onAddMeeting && openRequestMeetingModal,
      icon: 'calendar alternate outline' as SemanticICONS,
      label: t('networking.options.make-appointment'),
    },
  ].filter((v) => v.onClick);

  if (configList.length === 0) return null;
  return (
    <Popup
      on="click"
      pinned
      position="top right"
      trigger={<Button circular basic icon="plus" />}
      className={css().toString()}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Popup.Content>
        <List divided className={css('List').toString()}>
          {configList.map(({ onClick, icon, label }) => (
            <List.Item onClick={onClick}>
              <List.Icon name={icon} verticalAlign="middle" />
              <List.Content>
                <List.Description>{label}</List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Popup.Content>
    </Popup>
  );
};
