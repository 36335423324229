import { useScroll } from 'ahooks';
import { Target } from 'ahooks/lib/useScroll';
import { useState } from 'react';

interface UseIsScrolled {
  offset?: number;
}

export function useIsScrolled(target?: Target, options: UseIsScrolled = {}): boolean {
  const { offset = 32 } = options;
  const [isScrolled, setIsScrolled] = useState(false);

  useScroll(target, ({ top }) => {
    const scrolled = top > offset;

    // Only re-render when scrolling state changes
    if (scrolled !== isScrolled) {
      setIsScrolled(scrolled);
    }
    // Always return false, we want to control when we re-render
    return false;
  });
  return isScrolled;
}
