import cx from 'classnames';
import React, { FC, ReactElement, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import { Modal, ModalProps } from 'semantic-ui-react';

import { useClickOutside } from '../../hooks/useClickOutside';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import { BottomPanel, BottomPanelProps } from '../BottomPanel/BottomPanel';
import { ConfirmationModal, ConfirmationModalProps } from '../ConfirmationModal/ConfirmationModal';
import GlobalModalManager from '../GlobalModalManager';

export type QueryModalProps = BottomPanelProps &
  ModalProps & {
    closeConfirmationModalOptions?: {
      header: ReactElement;
      children: ReactElement;
    };
  };

export const QueryModal: FC<QueryModalProps> = (props: QueryModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const mobile = useMedia(GLOBAL_MEDIA_QUERIES.mobile);
  const {
    draggable,
    isRounded,
    id,
    children,
    header: headerRenderer,
    className,
    style,
    onCancel,
    onValidate,
    steps,
    initialStep,
    closeConfirmationModalOptions,
    closeOnDimmerClick,
    scrolling,
    actions,
    ...restProps
  } = props;

  const panelProps = {
    draggable,
    isRounded,
    id,
    children,
    className,
    steps,
    initialStep,
  };

  const modalProps = {
    className,
    children,
    closeOnDimmerClick,
    ...restProps,
  };

  const closeFunction = mobile
    ? () => {
        setIsOpen(false);

        setTimeout(() => {
          onCancel();
        }, 500);
      }
    : onCancel;

  const openConfirmationModal = async (
    headerComponent: ReactElement,
    ChildrenComponent: ReactElement,
  ): Promise<boolean | undefined> => {
    return GlobalModalManager.open(ConfirmationModal, {
      header: headerComponent,
      children: ChildrenComponent,
    } as ConfirmationModalProps);
  };

  const closeModal = async () => {
    if (closeConfirmationModalOptions) {
      const { header, children: ChildrenComponent } = closeConfirmationModalOptions;
      const confirm = await openConfirmationModal(header, ChildrenComponent);

      if (!confirm) return;
    }
    closeFunction();
  };

  const panelElementRef = useRef<HTMLDivElement | null>(null);

  const isFrontModal = GlobalModalManager.isLastModalOpen(id || '');

  useClickOutside(panelElementRef, () => {
    if (!isFrontModal) return;
    closeModal();
  });

  return mobile ? (
    <div className={cx('panel-dimmer', isOpen && `panel-dimmer--open`)} style={style}>
      <BottomPanel
        {...panelProps}
        onClose={closeModal}
        header={() => (headerRenderer ? headerRenderer({ onClose: closeModal, onValidate }) : null)}
        open={isOpen}
        forwardedRef={panelElementRef}
      />
    </div>
  ) : (
    <Modal
      {...modalProps}
      open
      onClose={closeModal}
      className={cx('rounded-modal', className)}
      dimmer={{ style }}
    >
      {headerRenderer && (
        <Modal.Header>{headerRenderer({ onClose: closeModal, onValidate })}</Modal.Header>
      )}
      <Modal.Content scrolling={scrolling}>{children}</Modal.Content>
      {actions}
    </Modal>
  );
};
