/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactPlayer from 'react-player';

import { useReactVideoStats } from './useReactVideoStats';

type ReactVideoPlayerProps = {
  _id: string;
  wrapper?: any;
  controls?: boolean;
  url: string | { src: string; type: string }[];
  originalUrl: string;
  autoPlay: boolean;
  item?: Record<string, any>;
  block?: Record<string, any>;
  onPlay: () => void;
  onSectionStat: (stat: Record<string, any>) => void;
} & typeof DefaultProps;

const DefaultProps = { item: {}, block: {} };

const HtmlVideoPlayer = (props: ReactVideoPlayerProps): JSX.Element => {
  const {
    _id: videoId,
    url: urlProp,
    originalUrl,
    item,
    block,
    onPlay,
    onSectionStat,
    controls,
    wrapper,
    ...attributes
  } = props;
  const url = typeof urlProp === 'string' ? urlProp : urlProp[0].src;
  const playerProps = useReactVideoStats(props);

  return (
    <ReactPlayer
      controls={controls}
      wrapper={wrapper}
      {...playerProps}
      url={url}
      config={{
        file: {
          attributes,
        },
      }}
    />
  );
};

HtmlVideoPlayer.defaultProps = DefaultProps;

export default HtmlVideoPlayer;
