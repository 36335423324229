/* eslint-disable operator-linebreak */
/* eslint-disable react/no-danger */
import { useMount } from 'ahooks';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Card, Header, Segment } from 'semantic-ui-react';
import CdnImage from '../../../components/CdnImage';
import ClickmeetingAccessButton from '../../../components/ClickmeetingAccessButton';
import { makeFilter } from '../../../components/ExhibitorGroup';
import QuotaField from '../../../components/workshops/QuotaField';
import WorkshopDate from '../../../components/workshops/WorkshopDate';
import { bem } from '../../../core/design/bem';
import { fetchUsersByCollection } from '../../../core/users/users.actions';
import { useUserCollection } from '../../../core/users/users.hooks';
import store from '../../../shared/Store';
import { replaceValues } from '../../../utils/stringUtils';
import { AddWorkshopToCalendar } from '../../../workshops/blocks/WorkshopAddToCalendarBlock';
import WorkshopSessionRegistrationButton from '../WorkshopSessionRegistrationButton';
import WorkshopSessionsModal from '../WorkshopSessionsModal';
import './WorkshopGroup.scss';

// import { AddWorkshopToCalendar } from '../../../workshops/blocks/WorkshopAddToCalendarBlock';

const translationPrefix = 'workshop-sessions';
const cxGroup = bem('workshop-group');
const cxCards = bem(cxGroup('cards').toString());
const cxCard = bem(cxCards('card').toString());

function getItemInfo(workshop, config) {
  const {
    title: titleTemplate = '{title}',
    subtitle: subtitleTemplate = '{subtitle}',
    description: descriptionTemplate = '{description}',
    image: imageField = 'image',
  } = config;
  const title = replaceValues(titleTemplate, workshop);
  const subtitle = replaceValues(subtitleTemplate, workshop);
  const description = replaceValues(descriptionTemplate, workshop);
  const image = get(workshop, imageField);
  return { title, subtitle, description, image };
}

export function useWorkshopGroup(entity, groupId) {
  const users = useUserCollection(entity?.collection);
  useMount(() => {
    if (!entity) return undefined;
    return store.reduxStore.dispatch(fetchUsersByCollection(entity?.collection));
  });
  return useMemo(() => {
    if (!entity) return undefined;
    const usersById = keyBy(users, '_id');
    return get(usersById, groupId);
  }, [users, entity, groupId]);
}

export const WorkshopWithSessions = ({ workshop, config }) => {
  const { item: itemConfig = {}, entity, showCheckbox } = config;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { nextSession, sessionCount, _id: workshopId, groupId } = workshop;
  const group = useWorkshopGroup(entity, groupId);
  const finalWorkshop = { ...workshop, group };
  const registration = useSelector((state) => state.registrations.registrationsById[workshopId]);
  if (groupId && !group?.visible) return null;
  const hasSessions = sessionCount > 1;
  const { title, subtitle, description, image } = getItemInfo(finalWorkshop, itemConfig);
  const buttonSession = hasSessions
    ? { ...registration, _id: registration?.sessionId }
    : { ...nextSession, title };
  return (
    <Card className={cxCard({ simple: true }).toString()}>
      <div className="content">
        <div className="image-title">
          {image && <CdnImage src={image} className="image" maxHeight={40} maxWidth={80} />}
          {title && (
            <Header as="h3" className="title">
              {title}
            </Header>
          )}
        </div>
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {description && (
          <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
      <div className="registration">
        {!registration ? (
          <>
            {hasSessions && (
              <div className="session-count">
                {t(`${translationPrefix}.session-count`, { count: sessionCount })}
              </div>
            )}
            {nextSession && (
              <div className="next-session">
                {hasSessions && (
                  <div className="label">{t(`${translationPrefix}.next-session`)}</div>
                )}
                <div className="time">
                  {t(`${translationPrefix}.at-time`, { startDate: nextSession.startDate })}
                </div>
                <QuotaField {...nextSession} />
              </div>
            )}
          </>
        ) : (
          <>
            <WorkshopDate startDate={registration.startDate} />
            <AddWorkshopToCalendar workshop={registration} primary />
            <ClickmeetingAccessButton event={registration} />
          </>
        )}
        {hasSessions && !registration ? (
          <Button className="view-sessions" primary onClick={() => setIsOpen(true)}>
            {t(`${translationPrefix}.view-sessions`)}
          </Button>
        ) : (
          <WorkshopSessionRegistrationButton session={buttonSession} showCheckbox={showCheckbox} />
        )}
        {isOpen && <WorkshopSessionsModal workshop={workshop} onClose={() => setIsOpen(false)} />}
      </div>
    </Card>
  );
};

WorkshopWithSessions.defaultProps = {
  config: {},
};

WorkshopWithSessions.propTypes = {
  config: PropTypes.object,
  workshop: PropTypes.object.isRequired,
};

const components = {
  simple: WorkshopWithSessions,
};

function WorkshopGroup(props) {
  const { workshops, title, subtitle, template, ...config } = props;
  const { itemsPerRow = 4, filter } = config;
  const filteredWorkshops = workshops.filter(makeFilter(filter));
  const Workshop = get(components, template) || 'div';
  return (
    <Segment className={cxGroup({ template }).toString()}>
      {title && (
        <Header className={cxGroup('title').toString()} as="h3">
          {title}
        </Header>
      )}
      {subtitle && <p className={cxGroup('subtitle')}>{subtitle}</p>}
      <Card.Group
        itemsPerRow={itemsPerRow}
        stackable
        className={cxGroup('cards').mix('compact').toString()}
      >
        {filteredWorkshops.map((workshop) => (
          <Workshop key={workshop._id} workshop={workshop} config={config} />
        ))}
      </Card.Group>
    </Segment>
  );
}

WorkshopGroup.defaultProps = {
  config: {},
  workshops: [],
  subtitle: undefined,
  title: undefined,
  template: 'simple',
};

WorkshopGroup.propTypes = {
  config: PropTypes.object,
  workshops: PropTypes.arrayOf(PropTypes.object),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  template: PropTypes.oneOf(['simple', 'session']),
};

export default WorkshopGroup;
