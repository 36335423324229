import React from 'react';

import { bem } from '../../../../core/design/bem';
import CdnImage from '../../../CdnImage';
import { Image } from '../../types';
import './ProgressBar.scss';

const css = bem('ProgressBar');

type ProgressBarProps = {
  percentage: number;
  icon: Image | undefined;
};

// inspiration ==> https://gist.github.com/WeiChiaChang/abe92ca2e8da86ef69560b63903e8764
const ProgressBar = ({ percentage = 0, icon }: ProgressBarProps): JSX.Element => {
  return (
    <div className={css('wrapper')}>
      <div className={css('progress-bar')}>
        <span
          className={css('progress-bar-fill')}
          style={{
            width: `${percentage}%`,
          }}
        />
      </div>
      {icon && (
        <CdnImage
          className={css('progress-bar--icon').toString()}
          src={icon}
          maxHeight={55}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          style={{  marginLeft: `calc(${percentage}% - 0.3em)` }}
        />
      )}
    </div>
  );
};

export default ProgressBar;
