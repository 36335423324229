/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Dimmer, Loader } from 'semantic-ui-react';

import { NoItemsPlaceholder } from '../../components/NoItemsPlaceholder/NoItemsPlaceholder';
import useEvent from '../../core/hooks/useEvent.hook';
import { Publication } from '../types/SocialWallV2.types';
import { PublicationCard } from './PublicationCard';

type ListItemProps = {
  index: number;
  key: string;
  parent: any;
  style: Record<string, any>;
};

type SocialWallFeedPageProps = {
  isLoading: boolean;
  publications: Publication[];
  addPublication: () => void;
  userInteractions: Record<string, string[]>;
  openPublication: (publicationId: string) => void;
  onInteractionClick: (publicationId: string, emote: string) => Promise<void>;
};

export const SocialWallFeedPage: FC<SocialWallFeedPageProps> = ({
  publications,
  isLoading,
  addPublication,
  userInteractions,
  openPublication,
  onInteractionClick,
}) => {
  const { t } = useTranslation();
  const listRef = useRef<List>()

  const keyMapper = useEvent((rowIndex: number) => publications[rowIndex]?._id || rowIndex);

  const [cache] = useState(
    () =>
      new CellMeasurerCache({
        defaultHeight: 360,
        fixedWidth: true,
        defaultWidth: 400,
        keyMapper,
      }),
  );

  useEffect(() => {
    if (listRef.current){
      cache.clearAll()
      listRef.current.forceUpdateGrid();
    }
  }, [publications, userInteractions]);

  const handleOpen = useEvent(openPublication);
  const handleInteraction = useEvent(onInteractionClick);

  const itemRenderer = useEvent(({ index, key, parent, style }: ListItemProps) => {
    const { _id, data, createdAt, commentCount, ...restData } = publications[index];
    return (
      <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
        {({ registerChild }: { registerChild?: any }) => (
          <div className="publication-card__wrapper" ref={registerChild} style={style}>
            <PublicationCard
              message={data.message}
              thumbnail={data.thumbnail}
              media={data.media}
              date={createdAt}
              commentCount={commentCount}
              userInteractions={userInteractions[_id]}
              onClick={() => handleOpen(_id)}
              onInteractionClick={(emote: string) => handleInteraction(_id, emote)}
              {...restData}
            />
          </div>
        )}
      </CellMeasurer>
    );
  });

  if (!publications?.length) {
    if (isLoading) {
      return (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      );
    }

    return (
      <NoItemsPlaceholder
        buttonOptions={{ onClick: addPublication, label: t('social-wall.add-new-post') }}
        title={t('social-wall.no-post.title')}
        description={t('social-wall.no-post.description')}
        icon="collections"
      />
    );
  }

  return (
    <div className="publication-list">
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef as any}
            className="virtualized-list"
            rowCount={publications.length}
            height={height}
            rowHeight={cache.rowHeight}
            deferredMeasurementCache={cache}
            rowRenderer={itemRenderer}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  );
};
