import React from 'react';
import { Icon } from 'semantic-ui-react';

const QuotaIndicator = ({
  usersCount = 0,
  quota,
}: {
  usersCount: number;
  quota: number;
}): JSX.Element => {
  return (
    <div>
      <Icon name="users" style={{ marginRight: 8 }} />
      {Math.max(usersCount, quota)}/{quota}
    </div>
  );
};

export default QuotaIndicator;
