import React, { FC } from 'react';
import { buildMatch } from '../../../../utils/filter.utils';
import { WorkshopSession } from '../../../../types/workshop.types';
import { useRegistrationsById } from '../../../../workshops/utils/workshopRegistrationUtils';
import { AddToCalendarButton } from './AddToCalendarButton';

type AddToCalendarButtonProps = {
  workshop: any;
  options: any;
} & Record<string, any>;

const AddToCalendarButtons: FC<AddToCalendarButtonProps> = (props) => {
  const { workshop, options = {}, ...rest } = props;
  const { filter } = options;
  const hasSessions = workshop?.sessions || workshop?.workshopId;
  const items = workshop.sessions || [workshop] || [];
  const filterMatch = buildMatch(filter);
  const filteredSessions = items.filter(filterMatch);

  const registrations = useRegistrationsById(hasSessions);
  const registeredItems = filteredSessions.filter((s: WorkshopSession) => !!registrations[s._id]);

  return (
    <>
      {registeredItems.map((item: any) => (
        <AddToCalendarButton key={item._id} item={item} options={options} {...rest} />
      ))}
    </>
  );
};

export { AddToCalendarButtons };
