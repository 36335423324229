/* eslint-disable @typescript-eslint/no-unused-vars */
import achievementService from '../services/achievements.service';
import { LOADING, SET_ACHIEVEMENTS, SET_USER_ACHIEVEMENTS } from './achievements.types';

export const setAchievements = (achievements, categories) => ({
  type: SET_ACHIEVEMENTS,
  achievements,
  categories,
});

export const setUserAchievements = (userAchievements) => ({
  type: SET_USER_ACHIEVEMENTS,
  userAchievements,
});

export function fetchAchievements() {
  return async (dispatch) => {
    dispatch({ type: LOADING });
    const props = await achievementService.fetchAchievements();
    const { achievements, categories } = props;
    if (achievements && categories) {
      dispatch(setAchievements(achievements, categories));
    }
  };
}

export function fetchUserAchievements(userId) {
  return async (dispatch) => {
    dispatch({ type: LOADING });
    const { userAchievements } = await achievementService.fetchUserAchievements(userId);
    if (userAchievements) {
      dispatch(setUserAchievements(userAchievements));
    }
  };
}
