import { CSSProperties, FC, useEffect, useState } from 'react';
import Dropzone, { ImageFile } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Progress } from 'semantic-ui-react';

import { Image } from '../profile/components/UploadFileBlock';
import { sweetAlert } from '../utils/popupUtils';
import { uploadAssetFile } from './ImageUploader';

export const styles: Record<string, CSSProperties> = {
  color: {
    width: 36,
    height: 14,
    borderRadius: 2,
  },
  swatch: {
    padding: 5,
    background: '#fff',
    borderRadius: 1,
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  dropzoneContainer: {
    cursor: 'pointer',
    width: 50,
    height: 50,
    display: 'table',
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'rgba(0, 0, 0, 0.35)',
    borderRadius: 0,
  },
  dropzoneActive: {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee',
  },
  dropzoneRejected: {
    borderStyle: 'solid',
    borderColor: '#c66',
    backgroundColor: '#eee',
  },
  dropzoneText: {
    display: 'table-cell',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 12,
  },
};

type File = {
  state: string;
  progress: number;
  file: ImageFile;
};

type DropZoneFileProps = {
  name: string;
  onAdd: (name: string, file: Image) => void;
  placeholder: string;
  multiple: boolean;
  style: CSSProperties;
  formats?: string[];
  textStyle?: CSSProperties;
  uploadOptions?: Record<string, any>;
  privacyMode?: boolean;
};

const DropzoneFile: FC<DropZoneFileProps> = (props) => {
  const {
    uploadOptions,
    privacyMode,
    onAdd,
    name,
    style = {},
    placeholder = '',
    formats = [],
    textStyle = {},
    multiple = false,
  } = props;
  const { t } = useTranslation();

  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);

  useEffect(() => {
    checkUploads();
  }, [uploadingFiles]);

  const handleDrop = async (accepted: ImageFile[]) => {
    if (accepted.length > 0) {
      setUploadingFiles([
        ...uploadingFiles,
        ...accepted.map((file) => ({ state: 'IDLE', progress: 0, file })),
      ]);
    }
  };

  const checkUploads = async () => {
    if (!uploadingFiles.length) return;

    const fileToUpload = uploadingFiles[0];
    if (fileToUpload.state !== 'IDLE') {
      return;
    }

    // Start uploading !
    setFileState(0, { progress: 0, state: 'UPLOADING' });

    try {
      const res = await uploadAssetFile(fileToUpload.file, undefined, {
        onProgress: (progress: number) => setFileState(0, { progress }),
        privacyMode,
        ...uploadOptions,
      });
      if (onAdd) {
        onAdd(name, res);
      }
      // Remove from list of files to upload, and check uploads
      setUploadingFiles(uploadingFiles.slice(1));
    } catch (e) {
      console.error('error', e);
      await sweetAlert({
        text: t('components.dropzone-file.file-invalid'),
        icon: 'error',
      });
      // Flush everything
      setUploadingFiles([]);
    }
  };

  const setFileState = (index: number, newState: Partial<File>) => {
    const files = [...uploadingFiles];
    files[index] = { ...files[index], ...newState };
    setUploadingFiles(files);
  };

  return (
    <Dropzone
      style={{ ...styles.dropzoneContainer, ...style }}
      activeStyle={styles.dropzoneActive}
      rejectStyle={styles.dropzoneRejected}
      accept={formats.join(', ')}
      multiple={multiple}
      onDrop={handleDrop}
    >
      <div style={{ ...styles.dropzoneText, padding: 16, ...textStyle }}>
        {uploadingFiles.length === 0 && <div>{placeholder}</div>}
        {uploadingFiles.length > 0 && (
          <div>
            {t('components.dropzone-file.remaining-file', { count: uploadingFiles.length })}
          </div>
        )}
        {uploadingFiles[0]?.state === 'UPLOADING' && (
          <Progress size="small" percent={uploadingFiles[0]?.progress} color="green" />
        )}
      </div>
    </Dropzone>
  );
};

export default DropzoneFile;
