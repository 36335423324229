import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import { StepContainer } from '../../Step';
import { getString } from '../../../../utils';

const translationPrefix = 'appointments.steps.select-options';
const t = (key: string, data?: any) => getString(`${translationPrefix}.${key}`, data);

type StepOptionsProps = {
  active: boolean;
  finish: boolean;
  setStep: (selection: string) => void;
  orderNumber: string;
};

const StepOptions = (props: StepOptionsProps): JSX.Element | null => {
  const { active, finish, setStep, orderNumber } = props;
  if (finish) {
    return null;
  }
  if (!active) {
    return (
      <StepContainer label={orderNumber}>
        <Button className="link" onClick={() => setStep('select-options')}>
          {t('sidebar-label')}
        </Button>
      </StepContainer>
    );
  }
  return (
    <StepContainer label={orderNumber} active>
      <Header as="h4">{t('sidebar-label')}</Header>
    </StepContainer>
  );
};

StepOptions.defaultProps = {};

export default StepOptions;
