import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';

import { bem } from '../../core/design/bem';
import { userProptypes } from '../../propTypes';
import { evalValue } from '../../utils/constraints';
import Blocks from '../Blocks';
import ImageIcon from '../ImageIcon';
import './Tabs.scss';

const cxTabs = bem('tabs');
const cxTab = bem(cxTabs('tab').toString());

const Tab = ({ tab, item, blockComponents }) => {
  const { blocks = [] } = tab;
  return (
    <div className={cxTab}>
      <Blocks blocks={blocks} blockComponents={blockComponents} sharedProps={{ item }} />
    </div>
  );
};

Tab.propTypes = {
  blockComponents: PropTypes.object.isRequired,
  tab: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

const Tabs = ({ tabs, item, blockComponents }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.key || undefined);
  if (!tabs.length) return null;
  const visibleTabs = tabs
    .map((tab) => ({
      ...tab,
      visible: tab.condition ? evalValue(tab?.condition, { item }) : true,
    }))
    .filter((v) => v.visible);
  const tabsByKey = keyBy(visibleTabs, 'key');
  const tab = get(tabsByKey, activeTab);
  const handleItemClick = (_e, { name }) => setActiveTab(name);
  return (
    <div className={cxTabs()}>
      <Menu pointing secondary>
        {visibleTabs.map(({ key, label, icon, className }) => (
          <Menu.Item
            className={cxTab().mix(className)}
            key={key}
            name={key}
            active={key === activeTab}
            onClick={handleItemClick}
          >
            <ImageIcon icon={icon} style={{ marginRight: 8 }} /> {label}
          </Menu.Item>
        ))}
      </Menu>
      {tab && <Tab tab={tab} item={item} blockComponents={blockComponents} />}
    </div>
  );
};

Tabs.defaultProps = {
  blockComponents: {},
  tabs: [],
  item: undefined,
};

Tabs.propTypes = {
  blockComponents: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.shape(userProptypes),
};

export default Tabs;
