import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useEvent from '../core/hooks/useEvent.hook';

export function useBackButton(onBackPressed: () => void): void {
  const callback = useEvent(onBackPressed);
  const history = useHistory();
  useEffect(() => {
    const unblock = history.block((_location, action) => {
      if (action === 'POP') {
        callback();
      } else {
        unblock();
      }
      return false;
    });
    return unblock;
  }, [history, callback]);
}
