import React from 'react';
import PropTypes from 'prop-types';
import HtmlTemplate from '../../../../components/HtmlTemplate';

const Description = ({ session }) => {
  const { description } = session;
  if (!description) return null;
  return <HtmlTemplate className="description" template={description} />;
};

Description.propTypes = {
  session: PropTypes.object.isRequired,
};

export default Description;
