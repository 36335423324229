import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { bem } from '../../../../core/design/bem';
import { workshopProptypes } from '../../../../propTypes';
import EntityLink from '../../../EntityLink';

type SeeMoreButtonProps = {
  workshop: any;
  showOnLive?: boolean;
};

const translationPrefix = 'workshops.workshop';
const SeeMoreButton: FC<SeeMoreButtonProps> = (props) => {
  const { workshop, showOnLive = false } = props;
  const { _id: workshopId, pageHidden, pageLiveOnlyForRegistered } = workshop;
  const { t } = useTranslation();

  const isUserRegistered = useSelector(
    (state: any) => state.registrations.registrationsById[workshopId],
  );

  const isDisplayed = !pageHidden && (isUserRegistered || !pageLiveOnlyForRegistered);

  const hasLive =
    workshop.liveStreams && workshop.liveStreams.length > 0 && !!workshop.liveStreams[0].uri;

  if (!isDisplayed || (!showOnLive && hasLive)) {
    return null;
  }

  return (
    <Button
      className={bem('see-more-button').toString()}
      as={EntityLink}
      autoHide
      entity={workshop}
      content={t(`${translationPrefix}.see-more`)}
    />
  );
};

SeeMoreButton.defaultProps = {
  showOnLive: false,
};

SeeMoreButton.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
  showOnLive: PropTypes.bool,
};

export { SeeMoreButton };
