import PropTypes from 'prop-types';
import React from 'react';

import { useIsMobile } from '../hooks/useMediaQuery';
import { imageProptypes } from '../propTypes';
import Images from '../utils/Images';
import { videoCDN } from '../utils/videoUtils';
import './page-background.scss';

function getPageBackgroundStyle({ image, color, position }) {
  const isGradient = image && typeof image === 'string' && image.includes('gradient');
  const hasImage = isGradient || Images.exists(image);
  if (!hasImage && !color) return null;
  if (hasImage)
    return {
      backgroundImage: !isGradient ? `url(${Images.maxWidth(image, 1920)})` : image,
      backgroundPosition: position,
    };
  if (color) return { backgroundColor: color };
  return {};
}

function PageBackground({ image, video, color, position, mobile: mobileBackground }) {
  const mobile = useIsMobile();
  const backgroundImage = mobile && mobileBackground?.image ? mobileBackground.image : image;
  const backgroundVideo = mobile && mobileBackground?.video ? mobileBackground.video : video;

  if (backgroundVideo && backgroundVideo.uri) {
    return (
      <div className="page-background page-background--video">
        <video title="background-video" muted autoPlay loop playsInline>
          <source src={videoCDN(backgroundVideo.uri)} type="video/mp4" />
        </video>
      </div>
    );
  }
  if (backgroundImage && backgroundImage.type === 'video') {
    return (
      <div className="page-background page-background--video">
        <iframe title="background-video" src={backgroundImage.uri} allow="autoplay" />
      </div>
    );
  }
  const style = getPageBackgroundStyle({ image: backgroundImage, color, position });
  if (!style) return null;
  return <div className="page-background" style={style} />;
}

PageBackground.defaultProps = {
  color: undefined,
  image: undefined,
  position: 'center center',
  video: undefined,
  mobile: undefined,
};

PageBackground.propTypes = {
  color: PropTypes.string,
  image: imageProptypes,
  mobile: PropTypes.object,
  position: PropTypes.string,
  video: PropTypes.shape({ uri: PropTypes.string }),
};

export default PageBackground;
