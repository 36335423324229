import cx from 'classnames';
import React, { FC, useMemo } from 'react';

import { useConfig } from '../../config/config.context';
import { useSelectedAchievements } from '../../core/achievements/achievements.hook';
import { useMe } from '../../profile/hooks';
import Images from '../../utils/Images';
import { replaceValues } from '../../utils/stringUtils';
import AchievementBadge from '../AchievementBadge';
import BlockContainer from '../BlockContainer';
import './HtmlBlock.scss';

type HtmlBlocksProps = {
  _id: string;
  id?: string;
  achievements?: { ids: string[] };
  title?: string;
  content?: string;
  containerType?: string;
};

type HtmlProps = {
  content: string;
  data?: Record<string, any>;
} & Record<string, any>;

const useHtmlData = () => {
  const user = useMe();
  const { domain } = useConfig();
  return { user, domain };
};

export const Html: FC<HtmlProps> = ({ content, data, ...props }) => {
  const html = useMemo(() => {
    let value = Images.applyCDNToCSS(content);
    if (data) value = replaceValues(value, data);
    return value;
  }, [content, data]);

  return <div {...props} dangerouslySetInnerHTML={{ __html: html }} />;
};

const HtmlBlock: FC<HtmlBlocksProps> = (props) => {
  const { id, achievements, title, content, containerType = 'segment-light', _id } = props;

  const data = useHtmlData();
  const { ids: achievementsIds = [] } = achievements || {};
  const blockAchievements = useSelectedAchievements(achievementsIds);
  if (!content) return null;

  return (
    <BlockContainer id={id} type={containerType} header={title} className={cx('block--html', _id)}>
      {blockAchievements && (
        <div className="achievements-badge-container" style={{ display: 'flex' }}>
          {blockAchievements.map((a) => (
            <AchievementBadge
              key={a._id}
              className={cx('achievement-image', a._id)}
              achievement={a}
              height={40}
            />
          ))}
        </div>
      )}
      <Html content={content} data={data} />
    </BlockContainer>
  );
};

export default HtmlBlock;
