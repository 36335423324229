/* eslint-disable react/prop-types */
import { useMount } from 'ahooks';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { Button, Header, Icon, Message } from 'semantic-ui-react';

import { withConfig } from '../config/config.context';
import { withDesign } from '../config/design.context';
import { useLoginPath } from '../config/screens.context';
import AuthService from '../core/services/auth.service';
import useTranslations from '../hooks/useTranslations';
import store, { getParameterByName } from '../shared/Store';
import { getString } from '../utils';
import LoginLayout from './LoginLayout';
import ResetPasswordFields from './components/ResetPasswordFields';
import { ensureConstraint, validateConstraints } from './utils/passwordUtils';

const translationPrefix = 'login';

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      success: false,
      token: getParameterByName('token'),
    };
  }

  componentDidMount() {
    const { token } = this.state;
    AuthService.checkResetPasswordToken(token)
      .then((c) => {
        if (!c.success) {
          this.setState((state) => ({
            ...state,
            error: c.errors,
          }));
        }
      })
      .catch((e) =>
        this.setState((state) => ({
          ...state,
          error: e,
        })),
      );
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    // eslint-disable-next-line react/no-unused-state
    this.setState({ [name]: value, message: null, error: null });
  };

  // eslint-disable-next-line consistent-return
  handleResetPassword = async () => {
    const { password, token } = this.state;
    const { config } = this.props;
    const autoLogin = config?.screens?.login?.resetPassword?.autoLogin;
    const stayConnected = config?.screens?.login?.stayConnected;

    const res = await store.resetPassword(token, password, autoLogin, stayConnected);
    if (res.success) {
      this.setState({ success: true, error: null });
    }
  };

  render() {
    const { design, config } = this.props;
    const { variant, path, resetPassword: { type: passwordType = 'simple', ctaSection } = {} } =
      config?.screens?.login || {};
    const { password, confirmPassword, error, success, token } = this.state;
    const loginPath = path || '/login';
    // check constraints
    const validatedConstraints = validateConstraints(
      password,
      confirmPassword,
      ensureConstraint(passwordType),
    );
    const isPasswordNotValid = !!find(validatedConstraints, { isValid: false });

    // check if exhibitor is already connected
    const currentStorage = store.storage.getItem();

    if (success || error || !token || (currentStorage && currentStorage !== '{}')) {
      // redirect to login screen
      return (
        <Redirect
          to={{
            pathname: loginPath,
            state: error ? { tokenExpired: true } : undefined,
          }}
        />
      );
    }

    const { primaryColor: color } = design;
    return (
      <LoginLayout screen="reset-password" variant={variant} sideSection={ctaSection}>
        <Header as="h2" style={{ color }} textAlign="center">
          {getString(`${translationPrefix}.please-enter-new-password`)}
        </Header>
        <ResetPasswordFields
          password={password}
          confirmPassword={confirmPassword}
          constraints={validatedConstraints}
          onChange={this.handleChange}
        />
        <Button
          style={{ backgroundColor: color, color: 'white' }}
          fluid
          size="large"
          onClick={this.handleResetPassword}
          disabled={isPasswordNotValid}
        >
          {getString(`${translationPrefix}.reset-password`)}
        </Button>
      </LoginLayout>
    );
  }
}

ResetPasswordScreen.propTypes = {
  config: PropTypes.object.isRequired,
  design: PropTypes.object.isRequired,
};

const AutoLogin = (props) => {
  const { config } = props;
  const token = getParameterByName('token');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(token ? undefined : 'auto-login.error-missing-token');
  const { t } = useTranslations(translationPrefix);
  const loginPath = useLoginPath();
  useMount(async () => {
    if (!token) return;

    const { stayConnected = true } = config?.screens?.login || {};
    const res = await store.autoLogin(token, stayConnected);
    if (res && res.success) {
      setSuccess(true);
    } else {
      setError('auto-login.error-invalid-token');
    }
  });

  const { layout } = config?.screens?.login || {};
  const variant = layout?.variant;

  const { design } = props;
  const { primaryColor: color } = design;

  if (success || !token) {
    return <Redirect to={loginPath} />;
  }

  return (
    <LoginLayout variant={variant} screen="auto-login">
      <Header as="h2" style={{ color }} textAlign="center">
        {t('auto-login.title')}
      </Header>
      {error && (
        <Message
          negative
          icon="warning circle"
          style={{ textAlign: 'left' }}
          header={t('error')}
          content={t(error)}
        />
      )}
      {!error && (
        <Message
          style={{ textAlign: 'left' }}
          icon={{ name: 'refresh', loading: true }}
          content={t('auto-login.description')}
        />
      )}{' '}
      <p className="link--back-to-login" style={{ marginTop: '0.5rem', textAlign: 'left' }}>
        <Link className="link--secondary" to="/login">
          <Icon name="chevron left" /> {getString('btn.connect')}
        </Link>
      </p>
    </LoginLayout>
  );
};

export const AutoLoginScreen = withConfig(withDesign(AutoLogin));

const ResetOrActiveScreen = (props) => {
  const { config } = props;
  const { mode } = config?.screens?.login || {};
  if (mode === 'otp') {
    return <AutoLogin {...props} />;
  }
  return <ResetPasswordScreen {...props} />;
};

ResetOrActiveScreen.propTypes = {
  config: PropTypes.object.isRequired,
  design: PropTypes.object.isRequired,
};

export default withConfig(withDesign(ResetOrActiveScreen));
