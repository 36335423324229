import momentTz from 'moment-timezone';

import { evalCondition } from '../../utils/conditionUtils';

const { eventTimezone, forceFor } = window.__DATA__?.options?.timezone || {};

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER': {
      if (eventTimezone && forceFor) {
        if (evalCondition(forceFor, action.user)) {
          momentTz.tz.setDefault(eventTimezone);
        }
      }
      return { user: action.user };
    }
    default:
      return state;
  }
};

export function setStoreUser(user) {
  return {
    type: 'SET_USER',
    user,
  };
}

export default userReducer;
