/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cx from 'classnames';
import { Form } from 'semantic-ui-react';

type UserCheckboxFieldProps = {
  disabled?: boolean;
  field: string;
  label?: string;
  onChange: (key: string, checked?: boolean) => void;
  style?: Record<string, string>;
  toggle?: boolean;
  value: any;
};

const UserCheckboxField = ({
  field,
  label,
  onChange,
  style,
  toggle,
  disabled,
  value,
}: UserCheckboxFieldProps): JSX.Element => {
  return (
    <label htmlFor={field} style={style} className={cx(field, { disabled })}>
      <Form.Checkbox
        disabled={disabled}
        toggle={toggle}
        value={value}
        checked={value}
        style={{ marginRight: 10 }}
        onChange={(_e, { checked }) => onChange(field, checked)}
      />
      {label}
    </label>
  );
};

UserCheckboxField.defaultProps = {
  disabled: false,
  label: '',
  style: {},
  toggle: false,
};

export default UserCheckboxField;
