import flatten from 'lodash/flatten';
import NukaCarousel from 'nuka-carousel';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Icon } from 'semantic-ui-react';

import CdnImage from '../../../../components/CdnImage';
import QRCode from '../../../../components/QRCode';
import { useDesignConfig } from '../../../../config/design.context';
import { bem } from '../../../../core/design/bem';
import { UserType } from '../../../../core/users/users.types';
import { replaceValues } from '../../../../utils/stringUtils';
import { formatUsername } from '../../../../utils/userUtils';
import { getUserBadgeUri } from '../../utils';

type UserQRCodesProps = {
  user: UserType;
  qrcode?: Record<string, any>;
};

const css = bem('QRcodeAction');

function useListUserChildren(user: UserType) {
  return useMemo(() => {
    const children = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(user)) {
      if (Array.isArray(value) && user[key].every((u: Record<string, any>) => !!u.parentId)) {
        children.push(user[key]);
      }
    }
    return flatten(children);
  }, [user]);
}

const UserQRcode: FC<UserQRCodesProps> = React.memo(({ user, qrcode }) => {
  const { t } = useTranslation();
  const { primaryColor } = useDesignConfig();
  const { uri, color, backgroundColor, size, config, badge = {} } = qrcode || {};
  const badgeUrl = getUserBadgeUri(badge, user);

  return (
    <div className={css()}>
      <div className={css('qrcode')}>
        {qrcode?.uri ? (
          <CdnImage src={replaceValues(uri, user)} />
        ) : (
          <QRCode
            width={size}
            height={size}
            data={user._id}
            qrcodeConfig={{
              color: color || primaryColor,
              backgroundColor: backgroundColor || '#FFF',
              ...(config as any),
            }}
          />
        )}
      </div>
      <div className={css('text')}>
        <Header as="h3">{formatUsername(user)}</Header>
      </div>
      {badge?.enabled && !!badgeUrl && (
        <Button className={css('badge').toString()} primary as="a" target="_blank" href={badgeUrl}>
          <Icon className="icon" name="id badge outline" />
          <span>{t('profile.download-badge')}</span>
        </Button>
      )}
    </div>
  );
});

export const UserQRCodes: FC<UserQRCodesProps> = (props) => {
  const { qrcode, user } = props;
  const { t } = useTranslation();
  const { showChildren = true } = qrcode ?? {};
  const userChildren = useListUserChildren(user);
  const allUsers = !showChildren ? [user] : [user, ...userChildren];
  const isSingle = allUsers.length === 1;
  return (
    <div className="Action">
      <div className={css('text')}>{t('profile-v2.actions.qrcode.text')}</div>
      {isSingle ? (
        <UserQRcode user={user} qrcode={qrcode} />
      ) : (
        <NukaCarousel
          slidesToShow={1}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => (
            <Icon
              className="left-control"
              name="chevron circle left"
              onClick={previousSlide}
              disabled={currentSlide === 0}
            />
          )}
          renderCenterRightControls={({ nextSlide, currentSlide }) => (
            <Icon
              className="right-control"
              onClick={nextSlide}
              name="chevron circle right"
              disabled={currentSlide === allUsers.length - 1}
            />
          )}
        >
          {allUsers.map((u) => (
            <UserQRcode key={u._id} user={u} qrcode={qrcode} />
          ))}
        </NukaCarousel>
      )}
    </div>
  );
};
