import cx from 'classnames';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { SemanticICONS, Tab } from 'semantic-ui-react';

import { LoginModal } from '../authentication/LoginModal';
import { CmsPageLayout } from '../cms/CmsPage';
import { useConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import useEvent from '../core/hooks/useEvent.hook';
import useUrlState from '../hooks/useUrlState';
import { useMe } from '../profile/hooks';
import store from '../shared/Store';
import i18n from '../translations/i18n';
import { GLOBAL_MEDIA_QUERIES } from '../utils/mediaQueries';
import './SocialWallv2.scss';
import { LoadingDetailedPublicationModal } from './components/DetailedPublicationModal/LoadingDetailedPublicationModal';
import { SocialWallFeedPage } from './components/SocialWallFeed';
import { SocialWallMediaPage } from './components/SocialWallMediaPage';
import { SocialWallFeedPageContent } from './components/SocialWallPageContent';
import { SocialWallPublicationModal } from './components/SocialWallPublicationModal';
import { useSocialWallEmotes } from './hooks/useSocialWallEmotes';
import { useSocialWallPublications } from './hooks/useSocialWallPublications';
import { SocialWallConfigProvider } from './social-wall-config.context';

type PanesTypes = { type: 'newsfeed' | 'media'; enabled: boolean };

interface SocialWallProps {
  tabs: PanesTypes[];
  comments: { enabled: boolean };
  publications: { enabled: boolean };
  interactions: { enabled: boolean; defaultEmotes?: string[] };
  report: { enabled: boolean };
  design?: Record<string, any>;
  metadata?: Record<string, any>;
  pageFooter?: Record<string, any>;
  pageHeader?: Record<string, any>;
}

const defaultTabs: Record<string, PanesTypes> = {
  newsfeed: { enabled: true, type: 'newsfeed' },
  media: { enabled: true, type: 'media' },
};

const useHasBottomTabs = () => {
  const { menus } = useConfig();
  const mobile = useMedia(GLOBAL_MEDIA_QUERIES.mobile);
  return (
    menus?.bottomTabs?.enabled &&
    ((!mobile && menus?.bottomTabs?.media?.desktop) || (mobile && menus?.bottomTabs?.media?.mobile))
  );
};

export const SocialWallV2: FC<SocialWallProps> = (props: SocialWallProps) => {
  const {
    tabs = defaultTabs,
    comments = { enabled: true },
    publications = { enabled: true },
    interactions = { enabled: true },
    report = { enabled: false },
    metadata,
    design,
    pageHeader,
    pageFooter,
  } = props;

  const { t } = useTranslation();

  const [{ postId }, setUrlState] = useUrlState({ postId: null }, { navigateMode: 'replace' });
  const setPostId = (newPostId?: string) => setUrlState({ postId: newPostId });

  const { primaryColor } = useDesignConfig();
  const { _id: userId, collection: userCollection } = useMe();

  const { publications: posts, isLoading, createPublication } = useSocialWallPublications(
    publications.enabled,
  );

  const hasBottomTabs = useHasBottomTabs();

  // TO DO: use store to get/set publications/comments/interactions
  const { toggleInteraction, userInteractions } = useSocialWallEmotes({ userId, userCollection });

  const onAddPublicationClick = async () => {
    if (!store.isLoggedIn()) {
      const loggedIn: unknown = await LoginModal.open();
      if (!loggedIn) return;
    }
    await SocialWallPublicationModal.open({ createPublication });
  };

  const currentPublicationIndex = useMemo(() => posts.findIndex((post) => post._id === postId), [
    postId,
    posts,
  ]);

  const onGoNext = useEvent(() => {
    const nextId = (posts[currentPublicationIndex + 1] || posts[0])._id;
    setPostId(nextId);
  });

  const onGoPrev = useEvent(() => {
    const nextId = (posts[currentPublicationIndex - 1] || posts[posts.length - 1])._id;
    setPostId(nextId);
  });
  const buttonOptions = {
    label: t('social-wall.add-message'),
    icon: 'plus' as SemanticICONS,
    color: primaryColor,
    onClick: posts.length ? onAddPublicationClick : undefined,
    higher: hasBottomTabs,
  };

  const panesConfig = {
    newsfeed: {
      menuItem: i18n.t('social-wall.tabs.newsfeed'),
      render: () => (
        <SocialWallFeedPageContent
          key="feed-page"
          buttonOptions={buttonOptions}
          className={cx('social-wall__content', !posts.length && 'social-wall__content--empty')}
        >
          <SocialWallFeedPage
            isLoading={isLoading}
            publications={posts}
            addPublication={onAddPublicationClick}
            userInteractions={userInteractions}
            openPublication={(id: string) => setPostId(id)}
            onInteractionClick={toggleInteraction}
          />
        </SocialWallFeedPageContent>
      ),
    },
    media: {
      menuItem: i18n.t('social-wall.tabs.media'),
      render: () => (
        <SocialWallFeedPageContent
          key="media-page"
          buttonOptions={buttonOptions}
          className={cx('social-wall__content', !posts.length && 'social-wall__content--empty')}
        >
          <SocialWallMediaPage
            publications={posts}
            userInteractions={userInteractions}
            openPublication={(id: string) => setPostId(id)}
          />
        </SocialWallFeedPageContent>
      ),
    },
  };
  const panes: any[] = Object.entries(tabs)
    .map(([tabType, tab]) => {
      if (!tab.enabled) return null;
      return panesConfig[tab.type || tabType];
    })
    .filter((v) => v);

  const detailedPublicationProps = {
    onCancel: () => setPostId(''),
    publicationId: postId,
    userId,
    userCollection,
    config: {
      comments,
      publications,
      interactions,
      report,
    },
    onGoPrev,
    onGoNext,
  };

  return (
    <CmsPageLayout
      withFooter={false}
      fullheight
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <SocialWallConfigProvider
        comments={comments}
        isLoading={isLoading}
        publications={publications}
        interactions={interactions}
        report={report}
      >
        {panes.length > 1 ? (
          <div className="social-wall-wrapper">
            <Tab
              defaultActiveIndex={0}
              renderActiveOnly
              className="social-wall"
              menu={{ attached: false, tabular: false, pointing: true, secondary: true }}
              panes={panes}
            />
          </div>
        ) : (
          <>{panes[0]?.render() || panesConfig.newsfeed.render()}</>
        )}
      </SocialWallConfigProvider>
      {postId && <LoadingDetailedPublicationModal posts={posts} {...detailedPublicationProps} />}
    </CmsPageLayout>
  );
};
