import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SpeakerAvatars from '../../../../components/speakers/SpeakerAvatars';

const translationPrefix = 'workshops.workshop.modal';

const Speakers = ({ session, config }) => {
  const { speakers = [] } = session;
  const { showSpeakers, speakersConfig } = config;
  const { t } = useTranslation();
  if (!showSpeakers || speakers?.length === 0) return null;
  return (
    <div className="speakers">
      <span className="with-speakers" style={{ minWidth: 50 }}>
        {t(`${translationPrefix}.with`)}
      </span>
      <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />
    </div>
  );
};

Speakers.propTypes = {
  config: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

export default Speakers;
