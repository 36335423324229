import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';

import { bem } from '../../core/design/bem';
import { useUserCollection } from '../../core/users/users.hooks';
import BlockContainer from '../BlockContainer';
import { ContainerProps } from '../cms/types';
import Contacts, { ContactsProps } from './components/Contacts';
import { defaultActions, defaultInfos } from './data';
import { ContactItem, ContactsDesign } from './types';
import { variants } from './variants';

const css = bem('ContactsBlock');

export type ContactsBlockProps = {
  _id: string;
  className?: string;
  container?: ContainerProps;
  categoryFilter?: string;
} & ContactsProps & { design: ContactsDesign };

const ContactsBlock = (props: ContactsBlockProps): JSX.Element | null => {
  const { _id, className, container, variant, actions, infos, categoryFilter, ...rest } = props;
  const items = useUserCollection('contacts');

  if (!items?.length) return null;
  const ContactsVariant = variants[variant] || Contacts;

  let filteredItems = items;
  if (categoryFilter) {
    filteredItems = items.filter((item: ContactItem) => item.category === categoryFilter);
  }

  return (
    <BlockContainer {...container} className={css({ id: _id, variant }).mix(className)}>
      <ContactsVariant
        variant={variant}
        actions={actions}
        infos={infos}
        {...rest}
        items={filteredItems}
      />
    </BlockContainer>
  );
};

ContactsBlock.defaultProps = {
  variant: 'default',
  actions: defaultActions,
  infos: defaultInfos,
};

export default ContactsBlock;
