/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Modal } from 'semantic-ui-react';

import { replaceAll } from '../../../../utils/stringUtils';
import './modal.scss';

// eslint-disable-next-line max-len
const pdfViewerIframe = `https://cdn.appcraft.events/projects/pdf-viewer/1.0.1/web/viewer.html`;

const OBJECT_STORAGE_STATIC =
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001/events';

const CDN = 'https://cdn.appcraft.events/events';
function applyCDNToPDF(pdf) {
  return replaceAll(pdf, OBJECT_STORAGE_STATIC, CDN);
}

const PDFModal = ({ file, onClose }) => {
  const { title, originalFilename, uri } = file;
  // const { trackEvent } = useTracking();

  // useEffect(() => {
  //   trackEvent(eventTags.VIDEO_VIEW, { video });
  // }, []);

  const cdnUrl = applyCDNToPDF(uri);

  return (
    <Modal open onClose={onClose} className="fullscreen--fixed">
      <Modal.Header>
        <Icon name="close" style={{ float: 'right', cursor: 'pointer' }} onClick={onClose} />
        {title || originalFilename}
      </Modal.Header>
      <Modal.Content style={{ padding: 0 }}>
        <iframe
          title={title}
          src={`${pdfViewerIframe}?file=${encodeURIComponent(cdnUrl)}`}
          style={{ width: '100%', height: '100%', border: 0 }}
        />
      </Modal.Content>
    </Modal>
  );
};
PDFModal.defaultProps = {};

PDFModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  file: PropTypes.shape({
    title: PropTypes.string,
    originalFilename: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default PDFModal;
