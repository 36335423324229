import React from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';

import { AddAppointmentToCalendar } from '../../../../components/AppointmentAddToCalendarBlock/AppointmentAddToCalendarBlock';
import { getString } from '../../../../utils';
import { localeFormat } from '../../../../utils/date';
import { StepContainer } from '../../Step';

const translationPrefix = 'appointments.steps.prepare';
const t = (key: string, data?: any) => getString(`${translationPrefix}.${key}`, data);

type StepPrepareProps = {
  active: boolean;
  appointmentUserRef: any;
  currentAppointment: any;
  onCancel: () => void;
  onUpdate: () => void;
  orderNumber: string;
};

const StepPrepare = (props: StepPrepareProps): JSX.Element | null => {
  const { active, appointmentUserRef, currentAppointment, onCancel, onUpdate, orderNumber } = props;
  if (!active) {
    return (
      <StepContainer label={orderNumber} top>
        {t('sidebar-label')}
      </StepContainer>
    );
  }
  return (
    <StepContainer label={<Icon name="check" />} top success>
      <Header as="h4">{t('congrats')}</Header>
      <p>{t('side-note', { date: localeFormat(currentAppointment.startDate, 'PPPPp') })}</p>
      {currentAppointment && appointmentUserRef && (
        <AddAppointmentToCalendar
          appointment={currentAppointment}
          sponsor={appointmentUserRef}
          primary
        />
      )}
      <div style={{ height: '2em' }} />
      <Button className="link" size="small" style={{ display: 'block' }} onClick={onUpdate}>
        {getString('btn.edit')}
      </Button>
      <p style={{ marginTop: '1em' }}>
        <Button className="link" size="small" style={{ display: 'block' }} onClick={onCancel}>
          {getString('btn.cancel')}
        </Button>
      </p>
    </StepContainer>
  );
};

StepPrepare.defaultProps = {};

export default StepPrepare;
