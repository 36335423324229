import SquareImage from '../../components/SquareImage';
import { bem } from '../../core/design/bem';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { SocialWallMessage } from '../types/SocialWallTypes';
import HoverTile from './HoverTile';

const css = bem('Grid');

type TileProps = {
  item: SocialWallMessage;
  openLightbox: (id: string) => void;
};

const imageSizes = {
  mobile: 150,
  desktop: 500,
};

const ImageTile = ({ item, openLightbox }: TileProps): JSX.Element | null => {
  const { mobile } = useMediaQuery();
  return (
    <div
      role="button"
      tabIndex={0}
      className={css('tile')}
      onClick={() => {
        openLightbox(item.id);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          openLightbox(item.id);
        }
      }}
    >
      <HoverTile item={item} />
      <div className={css('message')}>
        <SquareImage
          src={item.image}
          imageSize={mobile ? imageSizes.mobile : imageSizes.desktop}
          srcSet
        />
      </div>
    </div>
  );
};

export default ImageTile;
