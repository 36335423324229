/* eslint-disable react/prop-types */

/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import moment from 'moment/moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Card, Label } from 'semantic-ui-react';

import { NotificationStatus } from '../../types/Notification.types';
import './Notification.scss';

type NotificationProps = {
  kind: string;
  data?: {
    body: string;
    title: string;
    data?: Record<string, any>;
  };
  createdAt: string;
  status: NotificationStatus;
};

const translationPrefix = 'notifications';

const getKindLabel = (kind: string, t: any) => {
  switch (kind) {
    case 'reminder':
      return t('notifications.kind.reminder');
    default:
      return t('notifications.kind.push');
  }
};

const Notification: FC<NotificationProps> = (props) => {
  const { data, createdAt, status, kind } = props;
  const { t } = useTranslation();
  const { body, title, data: otherProps = {} } = data || {};
  const { openUrl } = otherProps;
  const unread = status !== NotificationStatus.READ;
  return (
    <Card
      fluid
      className="notification"
      as={openUrl ? NavLink : undefined}
      to={openUrl || undefined}
    >
      <Card.Content className={cx('content', { unread })}>
        <div className="type-and-status">
          <div className="type">{getKindLabel(kind, t)}</div>
          {unread && (
            <Label size="small" className="unread">
              {t(`${translationPrefix}.status.unread`)}
            </Label>
          )}
        </div>
        <Card.Header className="title">{title}</Card.Header>
        {body && <Card.Description className="description">{body}</Card.Description>}
        {createdAt && <Card.Meta className="date">{moment(createdAt).fromNow()}</Card.Meta>}
      </Card.Content>
    </Card>
  );
};

export default Notification;
