/* eslint-disable react/no-danger */
import { useSetState } from 'ahooks';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Dimmer, Grid, Header, Loader } from 'semantic-ui-react';

import { useTracking } from '../Context';
import { CmsBlocks } from '../cms/CmsScreen';
import BlockContainer from '../components/BlockContainer';
import { bem } from '../core/design/bem';
import { cancelAppointment } from '../sponsors/blocks/BookAppointmentBlock/utils';
import {
  useCurrentAppointment,
  useDaysWithSlots,
  useFreeTimeSlots,
  useSteps,
} from './appointments.hooks';
import Sidebar from './components/Sidebar';
import { StepConfirmContent } from './steps/Confirm';
import { StepPrepareContent } from './steps/Prepare';
import { StepSelectOptionsContent } from './steps/SelectOptions';
import { StepSelectSlotContent } from './steps/SelectSlot';
import { StepContext } from './steps/StepContext';
import { bookAppointment } from './utils';

const css = bem('appointment');

const steps = {
  'select-options': StepSelectOptionsContent,
  'select-slot': StepSelectSlotContent,
  'confirm': StepConfirmContent,
  'prepare': StepPrepareContent,
};

function AppointmentsBlock(props) {
  const {
    header,
    footer,
    container,
    main,
    days,
    appointmentUserRef,
    extraBookingData,
    slotFilters: slotFitlersProps,
    onCancel,
    bookingType,
  } = props;

  const { _id: groupId, collection } = appointmentUserRef;
  const { header: mainHeader, details } = main;
  const { steps: stepList = [], loading: stepLoading } = useSteps(collection, groupId, bookingType);

  const [state, setState] = useSetState({
    confirmData: {},
    selectedSlot: null,
    slotFilters: slotFitlersProps,
    step: '',
  });
  const { confirmData, selectedSlot, slotFilters, step } = state;
  const { trackEvent } = useTracking();
  const currentAppointment = useCurrentAppointment(collection, groupId);
  // userId remplacé par groupId car platfom front s'attend a un groupID
  const timeSlots = useFreeTimeSlots(collection, groupId, {
    bookingType,
    slotFilters,
  });

  const { freeTimeSlots, loading } = timeSlots;
  const daysWithSlots = useDaysWithSlots(days, freeTimeSlots);
  const Content = steps[step];
  const firstStep = stepList.length > 0 ? stepList[0].type : '';
  useEffect(() => {
    setState({ step: currentAppointment ? 'prepare' : firstStep });
  }, [currentAppointment?._id, firstStep]);

  function handleSelectStep(nextStep, { slot, filters }) {
    const data = { step: nextStep };
    if (filters) {
      data.slotFilters = { ...slotFilters, ...filters };
    }
    if (slot) {
      data.selectedSlot = slot;
    }
    setState(data);
  }

  async function handleSetStep(selectedStep) {
    setState({ step: selectedStep });
  }

  async function handleConfirm(data) {
    const { startDate, endDate } = selectedSlot;
    const appointmentToBook = {
      startDate,
      endDate,
      groupId,
      message: data.message,
      ...extraBookingData,
    };

    await bookAppointment(
      appointmentUserRef,
      appointmentToBook,
      {
        setStep: handleSetStep,
        previousAppointment: currentAppointment,
      },
      bookingType,
      slotFilters,
    );
    setState({ confirmData: data });
  }

  async function handleCancel() {
    if (!currentAppointment) return;
    const res = await cancelAppointment(currentAppointment, appointmentUserRef, { trackEvent });
    if (res?.success) {
      onCancel();
    }
  }

  function handleUpdate() {
    setState({ step: firstStep });
  }

  return (
    <div className="appointments-block">
      {header && <CmsBlocks blocks={header.blocks} />}
      <BlockContainer {...container}>
        {mainHeader && <Header as="h1">{mainHeader}</Header>}
        {details && <div dangerouslySetInnerHTML={{ __html: details }} />}
        <StepContext.Provider
          value={{
            days: daysWithSlots,
            onSelectStep: handleSelectStep,
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            onUpdate: handleUpdate,
            appointmentUserRef,
            setStep: handleSetStep,
            freeTimeSlots,
            selectedSlot,
            confirmData,
            currentAppointment,
            currentStep: step,
            steps: stepList,
          }}
        >
          {loading || stepLoading ? (
            <Dimmer active inverted>
              <Loader inverted content="Loading" />
            </Dimmer>
          ) : (
            <Grid
              stackable
              style={{ marginTop: '1em', marginBottom: '1em' }}
              className={css({ hasAppointment: !!currentAppointment, step }).toString()}
            >
              <Grid.Column width={4}>
                <Sidebar />
              </Grid.Column>
              <Grid.Column width={12}>{Content && <Content />}</Grid.Column>
            </Grid>
          )}
        </StepContext.Provider>
      </BlockContainer>
      {footer && <CmsBlocks blocks={footer.blocks} />}
    </div>
  );
}

AppointmentsBlock.defaultProps = {
  appointmentUserRef: {},
  bookingType: 'appointments',
  container: { type: 'segment' },
  days: [],
  extraBookingData: {},
  footer: undefined,
  header: undefined,
  main: {},
  onCancel: undefined,
  slotFilters: undefined,
};

AppointmentsBlock.propTypes = {
  appointmentUserRef: PropTypes.shape({
    collection: PropTypes.string.isRequired,
    _id: PropTypes.string,
  }),
  bookingType: PropTypes.string,
  container: PropTypes.object,
  days: PropTypes.arrayOf(PropTypes.object),
  extraBookingData: PropTypes.object,
  footer: PropTypes.object,
  header: PropTypes.object,
  main: PropTypes.object,
  onCancel: PropTypes.func,
  slotFilters: PropTypes.object,
};

export default AppointmentsBlock;
