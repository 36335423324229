import { useMemo } from 'react';

import BottomTabs from '../../../../components/BottomTabs';
import { TabEntry } from '../../../../components/BottomTabs/tab.types';
import { useScreensConfig } from '../../../../config/screens.context';
import { notEmpty } from '../../../../utils/arrayUtils';

type PageMenuEntry = {
  type: 'page';
  pageId: string;
};
type UrlMenuEntry = {
  type: 'url';
  url: string;
  target?: string;
};

export type TabMenuEntry = {
  _id: string;
  icon: string;
  label: string;
} & (PageMenuEntry | UrlMenuEntry);

export type BottomTabsMenuProps = {
  enabled?: boolean;
  entries: TabMenuEntry[];
};

const BottomTabsMenu = ({ enabled, entries }: BottomTabsMenuProps): JSX.Element | null => {
  const screens = useScreensConfig();

  const tabs = useMemo(
    () =>
      entries
        ?.map((entry) => {
          switch (entry.type) {
            case 'url': {
              const { url } = entry;
              if (!url) return null;

              return entry;
            }
            case 'page':
            default: {
              const { pageId } = entry;
              const page = screens[pageId];
              if (!page) return null;
              return {
                ...entry,
                type: 'page',
                exact: true,
                to: page.path,
              } as TabEntry;
            }
          }
        })
        ?.filter(notEmpty),
    [entries, screens],
  );
  if (!enabled || !entries) return null;

  return <BottomTabs tabs={tabs} />;
};

export default BottomTabsMenu;
