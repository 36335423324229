import { IconGridVariants } from '../types';
import { CircularIconVariant } from './CircularIconVariant';
import './common.scss';
import { GridLayoutVariant } from './GridLayoutVariant';
import { GridVariant } from './GridVariant';
import { HexagonalVariant } from './HexagonalVariant';
import { ImageListVariant } from './ImageListVariant';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const variants: Record<IconGridVariants, any> = {
  circular: CircularIconVariant,
  imageList: ImageListVariant,
  gridLayout: GridLayoutVariant,
  hexagonal: HexagonalVariant,
  grid: GridVariant,
};
