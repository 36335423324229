import React from 'react';
import PropTypes from 'prop-types';
import iFrameResize from 'iframe-resizer/js/iframeResizer';

function ResizableIframe(props) {
  const { src, autoResize, width, height, style, ...rest } = props;
  // iframe key used to force refresh iframe/reload when url or hasAutoResize changes
  const iframeKey = `${src}-${autoResize ? '?resize' : '?no-resize'}`;

  const resizeIfNeeded = (e) => {
    if (autoResize && src) {
      iFrameResize({ log: false }, e.target);
    }
  };
  return (
    <iframe
      title="frame"
      key={iframeKey}
      src={src}
      onLoad={resizeIfNeeded}
      allowTransparency
      style={{
        display: 'block',
        width: width || 1,
        minWidth: width ? undefined : '100%',
        maxWidth: width ? '100%' : undefined,
        height: height || 'calc(100vh - 60px)',
        border: 'none',
        overflow: 'auto',
        ...style,
      }}
      {...rest}
    />
  );
}

ResizableIframe.defaultProps = {
  autoResize: true,
  height: undefined,
  src: '',
  style: undefined,
  width: undefined,
};

ResizableIframe.propTypes = {
  autoResize: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  src: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ResizableIframe;
