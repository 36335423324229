import React from 'react';
import { SemanticSIZES } from 'semantic-ui-react';
import { useUserIdList } from '../../core/users/users.hooks';
import Avatars from './Avatars';

type UserAvatarProps = {
  collection: string;
  imageSize?: number;
  imageKey?: string;
  maxCount?: number;
  size?: SemanticSIZES;
  showModal?: boolean;
  template?: string;
  userIds: string[];
  variant?: 'simple' | 'square' | 'details' | 'text';
} & typeof UserAvatarDefaultProps;

const UserAvatarDefaultProps = {
  imageKey: 'thumbnail',
  imageSize: 28,
  maxCount: undefined,
  size: undefined,
  showModal: undefined,
  template: undefined,
  variant: 'simple',
};

const UserAvatars = ({
  userIds,
  size,
  imageSize,
  collection,
  imageKey,
  ...rest
}: UserAvatarProps): JSX.Element | null => {
  const users = useUserIdList(collection, userIds);
  if (!users || users.length === 0) return null;
  return <Avatars users={users} size={size} imageKey={imageKey} imageSize={imageSize} {...rest} />;
};

UserAvatars.defaultProps = UserAvatarDefaultProps;

export default UserAvatars;
