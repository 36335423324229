import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icons } from '../../../components/Icons/material-icon.types';
import { removeFalsy } from '../../../utils/arrayUtils';
import { Publication } from '../../types/SocialWallV2.types';
import { DetailedPublicationHeader } from '../DetailedPublicationModal/DetailedPublicationHeader';
import './Comments.scss';

interface CommentItemProps {
  comment: Publication;
  userId: string;
  onDelete: (commentId: string) => void;
}

export const CommentItem: FC<CommentItemProps> = ({ comment, userId, onDelete }) => {
  const { author, createdAt, data } = comment;
  const { message } = data;
  const { t } = useTranslation();

  const actions = removeFalsy([
    userId === author.userId && {
      label: t('btn.delete'),
      onClick: () => onDelete(comment._id),
      icon: 'delete' as Icons,
    },
  ]);

  return (
    <div className="comment-container">
      <DetailedPublicationHeader
        user={author}
        date={createdAt}
        onClose={() => ({})}
        dark
        withGradient={false}
        closeButton={false}
        actions={actions}
      />
      <div className="comment-content">{message}</div>
    </div>
  );
};
