/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RefObject, useRef } from 'react';
import ReactPlayer from 'react-player';

import { useVideoSectionStats } from '../hooks/useVideoSectionStats';

interface OnProgressProps {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

type ReactVideoStatsProps = {
  _id: string;
  wrapper?: any;
  controls?: boolean;
  url: string | { src: string; type: string }[];
  originalUrl: string;
  autoPlay: boolean;
  item?: Record<string, any>;
  block?: Record<string, any>;
  onPlay: () => void;
  onSectionStat: (stat: Record<string, any>) => void;
};

export function useReactVideoStats(
  props: ReactVideoStatsProps,
): {
  ref: RefObject<ReactPlayer>;
  onPlay: () => void;
  onProgress: (event: OnProgressProps) => void;
  onPause: () => void;
  onEnded: () => void;
} {
  const { _id: videoId, url: urlProp, originalUrl, item, block, onPlay, onSectionStat } = props;

  const ref = useRef<ReactPlayer>(null);
  const url = typeof urlProp === 'string' ? urlProp : urlProp[0].src;
  const { play, stop, updatetime } = useVideoSectionStats(
    url,
    { item, block, originalUrl, videoId },
    onSectionStat,
  );

  const onPlayerPlay = () => {
    const seconds = ref.current!.getCurrentTime();
    const duration = ref.current!.getDuration();
    play(seconds, duration);
    if (onPlay) {
      onPlay();
    }
  };

  const onProgress = (event: OnProgressProps) => {
    const { playedSeconds } = event;
    const duration = ref.current!.getDuration();
    updatetime(playedSeconds, duration);
  };

  const onPause = () => {
    const seconds = ref.current!.getCurrentTime();
    stop(seconds, false);
  };

  const onEnded = () => {
    const seconds = ref.current!.getCurrentTime();
    stop(seconds, true);
  };

  return { ref, onPlay: onPlayerPlay, onProgress, onPause, onEnded };
}
