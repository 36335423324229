/* eslint-disable @typescript-eslint/no-explicit-any */
export enum AccommodationRoomStatus {
  PENDING = 'PENDING',
  VALID = 'VALID',
  QUOTA_ERROR = 'QUOTA_ERROR',
  REJECTED = 'REJECTED',
}

export enum AccommodationRoomUserStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  QUOTA_ERROR = 'QUOTA_ERROR',
}

export type Owner = {
  _id: string;
  collection: string;
};

export type RoomUser = {
  _id: string;
  userId: string;
  status: string;
};

export type RoomType = {
  _id: string;
  collection: string;
  accommodationId: string;
  levelId: string;
  owner: Owner;
  status: AccommodationRoomStatus;
  users: RoomUser[];
  data?: Record<string, any>;
};

export type LevelWithCount = {
  _id: string;
  name: string;
  quota?: number;
  maxUsers?: number;
  type: string;
  remainingRooms?: number;
};

type User = { _id: string; firstName: string; lastName: string; hasValidRoom?: boolean };

export type RoomingAccommodation = {
  eventId: string;
  _id: string;
  collection: string;
  title: string;
  levels: LevelWithCount[];
};
export interface RoomingSession {
  title: string;
  roomingSession: {
    _id: string;
    configId: string;
  };
  accommodations: RoomingAccommodation[];
  accommodationLevels: LevelWithCount[];
  candidateRoommates: User[];
  enableRoomCreation: boolean;
}

export type RoomRequest = {
  accommodationId?: string;
  accommodationLevelId?: string;
  accommodationLevelType?: string;
  users?: string | string[];
  comment?: string;
};
