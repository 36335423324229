import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, SemanticCOLORS } from 'semantic-ui-react';

export type ConfirmationModalProps = {
  children: JSX.Element;
  header?: JSX.Element;
  onValidate: (data: any) => void;
  onCancel: () => void;
  style?: CSSProperties;
  buttonOptions?: {
    color?: SemanticCOLORS;
    label?: string;
  };
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  children,
  onCancel,
  onValidate,
  header,
  style,
  buttonOptions,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open
      className="rounded-modal"
      dimmer={{ style: { ...style, justifyContent: 'center' } }}
    >
      {header ? <Modal.Header>{header}</Modal.Header> : null}
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button type="button" title="close" onClick={onCancel}>
          {t('alert.cancel')}
        </Button>
        <Button
          type="button"
          title="validate"
          onClick={() => onValidate(true)}
          color={buttonOptions?.color || 'red'}
        >
          {buttonOptions?.label || t('alert.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
