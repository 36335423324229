import { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackingContext } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';
import { useEntityUrl } from '../../utils/hooks';
import { replaceObjectValues } from '../../utils/stringUtils';
import { joinUsers } from '../../utils/userUtils';
import { useEntityIcal } from '../../workshops/blocks/WorkshopAddToCalendarBlock';
import AddToCalendar from '../AddToCalendar';
import { Appointment } from '../Chat/Appointments/types';

type Exhibitor = {
  _id: string;
  name: string;
  shortDescription?: string;
};

type AddAppointmentToCalendarProps = {
  appointment: Appointment;
  sponsor: Exhibitor;
} & Record<string, any>;

export const AddAppointmentToCalendar: FC<AddAppointmentToCalendarProps> = ({
  appointment,
  sponsor,
  ...props
}) => {
  const { t } = useTranslation();
  const url = useEntityUrl({ kind: 'users', ...sponsor }, true);
  const icalConfig = useEntityIcal('appointments', appointment.collection);
  const { ownerId, users = [], owner } = appointment;

  const appointmentWith = joinUsers(users.filter((u) => u.userId !== ownerId));
  const entity = sponsor.name ? sponsor : { name: appointmentWith };
  const eventOwner = owner ?? users.filter((u) => u.userId !== ownerId)[0];
  const { trackEvent } = useContext(TrackingContext);

  const event = {
    title: t('appointments.slots.meeting-with', {
      name: sponsor.name || appointmentWith,
      entity,
      appointment,
      owner: eventOwner,
    }),
    description: `${sponsor?.shortDescription || ''}. \n${url}`,
    ...replaceObjectValues(icalConfig, { parent: sponsor, appointment, owner: eventOwner }),
    endDate: appointment.endDate,
    startDate: appointment.startDate,
    // timezone: 'Europe/Paris', // Don't force the timezone unless you force it when formatting time
  };

  function onAdd({ calendarType }: { calendarType: string }) {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.userId,
      item: appointment,
      kind: 'appointments',
      entityType: 'addToCalendar',
      calendarType,
    });
  }

  function onOpen() {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.userId,
      item: appointment,
      kind: 'appointments',
      entityType: 'listCalendarOptions',
    });
  }

  return <AddToCalendar event={event} onAdd={onAdd} onOpen={onOpen} {...props} iconOnly={false} />;
};

export const AppointmentAddToCalendarBlock: FC<AddAppointmentToCalendarProps> = memo(
  ({ appointment, sponsor, ...props }) => (
    <div className="WorkshopAddToCalendarBlock--Container">
      <AddAppointmentToCalendar appointment={appointment} sponsor={sponsor} {...props} />
    </div>
  ),
);
