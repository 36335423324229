/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */ /* eslint-disable operator-linebreak */
/* eslint-disable react/require-default-props */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css as applyCss, jsx } from '@emotion/react';
import { useMemo } from 'react';
import { bem } from '../../../../core/design/bem';
import HexagonalItem from '../components/HexagonalItem';
import './HexagonalVariant.scss';
import { IconGridVariantProps, Item } from './variant.types';

const css = bem('HexagonalItems');
const hexaGrid = bem('HexGrid');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function splitHexagones(items: Item[], itemsPerRow: number): any[] {
  const itemsSplit = [];
  let i = 0;
  while (i < items.length) {
    itemsSplit.push(items.slice(i, i + itemsPerRow));
    i += itemsPerRow;
    if (i < items.length) {
      itemsSplit.push(items.slice(i, i + itemsPerRow - 1));
      i += itemsPerRow - 1;
    }
  }
  return itemsSplit;
}

// Top margin per item per row
// Warning : this wasn't computed but just adjusted by hand...
// const topMargins = [
//   undefined, // Doesn't happen
//   undefined, // 1 per line, doesn't happen either
//   '-15.9%', // 2 per line
//   '-10.3%', // 3 per line
//   '-7.925%', // 4 per line
// ];

const fontSizesItemsPerRow = [undefined, 20, 18, 12, 10, 8];

const HexagonalVariant = (props: IconGridVariantProps): JSX.Element => {
  const { items, variant, itemConfig } = props;
  const menuSplit = splitHexagones(items, itemConfig.itemsPerRow);

  const emotionCss = useMemo(() => {
    // const { primaryColor } = design;
    const {
      gridGap = 8,
      itemsPerRow = 3,
      // border,
      // shadow,
      cssStyle,
      // overlay,
      css: cssCustom,
      // underlineTitle = false,
    } = itemConfig || {};

    const gapWithUnits = typeof gridGap === 'number' ? `${gridGap}px` : gridGap;
    const width = `(100% - (${gapWithUnits} * ${itemsPerRow - 1})) / ${itemsPerRow}`;

    return applyCss(cssCustom, cssStyle, {
      '.HexagonalItem': {
        'width': `calc(${width})`,
        'marginBottom': gapWithUnits,
        '&:not(:last-child)': {
          marginRight: gapWithUnits,
        },
      },
      '.HexagonalItem__Content': {
        fontSize: fontSizesItemsPerRow[itemsPerRow],
      },
      '.HexGrid__Row': {
        '&:nth-child(1n + 2)': {
          // Weird calculation but we're playing with CSS calc's limits...
          marginTop: `calc((${width}) * -285 / 1000)`,
          // marginTop: topMargins[itemsPerRow],
        },
        '&:nth-child(even) > :first-child': {
          marginLeft: `calc((${width}) / 2 + ${gapWithUnits} / 2)`,
        },
      },
    });
  }, [itemConfig]);

  return (
    <div css={emotionCss} className={`${css({ variant }).toString()} ${hexaGrid().toString()}`}>
      {menuSplit.map((row, rowIndex) => {
        return (
          <div className={hexaGrid('Row')} key={`row-${rowIndex}`}>
            {row.map((item: Item) => {
              return (
                <HexagonalItem
                  key={item._id}
                  item={item}
                  itemConfig={itemConfig}
                  variant={variant}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export { HexagonalVariant };
