import { matchPath, useLocation } from 'react-router-dom';
import values from 'lodash/values';

export function useCurrentMenuEntry() {
  const location = useLocation();
  const { menu = [] } = window.__DATA__;
  return menu.find(
    (menuEntry) => location && matchPath(location.pathname, { path: menuEntry.path, exact: false }),
  );
}

export function useCurrentPage() {
  const location = useLocation();
  const { screens = [] } = window.__DATA__;
  if (!location) return undefined;

  return values(screens).find(
    (screen) => screen.path && matchPath(location.pathname, { path: screen.path, exact: true }),
  );
}
