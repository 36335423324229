import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import { WorkshopAddToCalendarBlock } from './WorkshopAddToCalendarBlock';
import { getString } from '../../utils';
import { WorkshopActions } from '../../components/workshops/workshop-templates/actions/WorkshopActions';
import { WorkshopAction } from '../../components/workshops/workshop-templates/actions/action.type';
import { Workshop } from '../../types/workshop.types';

const translationPrefix = 'workshops.workshop';

type WorkshopActionsBlockProps = {
  actions: WorkshopAction[];
  loading: boolean;
  mandatory: boolean;
  unregisterWorkshop: () => void;
  workshop: Workshop;
};

const WorkshopActionsBlock: FC<WorkshopActionsBlockProps> = (props: WorkshopActionsBlockProps) => {
  const { actions, loading, mandatory, unregisterWorkshop, workshop } = props;
  if (!actions?.length) {
    return (
      <>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore*/}
        <WorkshopAddToCalendarBlock workshop={workshop} />
        {!mandatory && (
          <Button
            color="red"
            onClick={unregisterWorkshop}
            loading={loading}
            disabled={loading}
            size="tiny"
            style={{ width: '100%' }}
          >
            {getString(`${translationPrefix}.unregister`)}
          </Button>
        )}
      </>
    );
  }
  return <WorkshopActions workshop={workshop} actions={actions} />;
};

WorkshopActionsBlock.defaultProps = {};

export default WorkshopActionsBlock;
