/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable operator-linebreak */

/* eslint-disable @typescript-eslint/no-explicit-any */
import every from 'lodash/every';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

import {
  AccommodationRoomStatus,
  AccommodationRoomUserStatus,
  LevelWithCount,
  RoomRequest,
  RoomType,
  RoomingAccommodation,
} from './types';

export function getUserFullname(userId: string, usersById: Record<string, any>): string {
  const user = get(usersById, userId);
  if (!user) return '';
  return `${user.firstName || ''} ${user.lastName || ''}`;
}

export const iconForStatus = {
  [AccommodationRoomUserStatus.ACCEPTED]: {
    name: 'check',
    backgroundColor: 'var(--ac-color-success-pale)',
    color: 'var(--ac-color-success)',
  },
  [AccommodationRoomUserStatus.PENDING]: {
    name: 'hourglass half',
    backgroundColor: 'var(--ac-color-warning-pale)',
    color: 'var(--ac-color-warning)',
  },
  [AccommodationRoomUserStatus.REJECTED]: {
    name: 'times',
    backgroundColor: 'var(--ac-color-error-pale)',
    color: 'var(--ac-color-error)',
  },
  [AccommodationRoomUserStatus.CANCELED]: {
    name: 'times',
    backgroundColor: 'var(--ac-color-error-pale)',
    color: 'var(--ac-color-error)',
  },
  [AccommodationRoomUserStatus.QUOTA_ERROR]: {
    name: 'exclamation',
    backgroundColor: '#f5cf9a',
    color: '#d37e06',
  },
};

export function areAllRoomsRejected(rooms: RoomType[]): boolean {
  return every(
    rooms,
    ({ status }) =>
      status === AccommodationRoomStatus.REJECTED || status === AccommodationRoomStatus.QUOTA_ERROR,
  );
}
export type LevelType = {
  type: string;
  name: string;
  remainingRooms: number;
  levels: { _id: string; accommodationId: string; type: string; name: string }[];
};

export function computeLevelTypes(accommodations: RoomingAccommodation[] = []): LevelType[] {
  const groups = groupBy(
    flatten(
      accommodations?.map((acc) =>
        acc.levels?.map((level) => ({
          _id: level._id,
          accommodationId: acc._id,
          type: level.type || level.name,
          name: level.name,
          remainingRooms: level.remainingRooms,
        })),
      ),
    ),
    'type',
  );
  return Object.entries(groups).map(([type, levels]) => ({
    type,
    name: levels[0].name,
    remainingRooms: sumBy(levels, 'remainingRooms'),
    levels,
  }));
}

export function computeInitialRoom(accommodations: RoomingAccommodation[]) {
  const types = computeLevelTypes(accommodations);

  const defaultAccommodation = accommodations?.length === 1 ? accommodations[0]._id : undefined;
  const defaultAccommodationType = types.length === 1 ? types[0].type : undefined;
  const defaultLevel =
    accommodations?.length === 1 && accommodations[0].levels?.length === 1
      ? accommodations[0].levels[0]._id
      : undefined;
  return {
    accommodationId: defaultAccommodation,
    accommodationLevelType: defaultAccommodationType,
    accommodationLevelId: defaultLevel,
  };
}

export function findAccommodationAndLevel(
  accommodations: RoomingAccommodation[],
  room: RoomRequest,
): {
  accommodation?: RoomingAccommodation;
  accommodationLevel?: LevelWithCount;
} {
  if (!room.accommodationId) return {};

  const accommodation = accommodations.find((acc) => acc._id === room.accommodationId);
  if (!accommodation) return {};

  const accommodationLevel = accommodation.levels?.find((l) => l._id === room.accommodationLevelId);
  return { accommodation, accommodationLevel };
}
