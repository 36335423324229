/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import { bem } from '../../../../core/design/bem';
import '../FaqBlock.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Highlighter = require('react-highlight-words');

const QuestionRender = ({
  title,
  onClick,
  styles,
  icon,
  searchWords,
}: {
  title: string;
  onClick?: any;
  styles: Record<string, any>;
  icon: SemanticICONS;
  searchWords: string[];
}): JSX.Element => {
  const { bgColor, rowTitleColor, arrowColor } = styles;
  const css = bem('FaqBlock');

  return (
    <div className={css('question')} style={{ backgroundColor: bgColor }}>
      <button type="button" className="title" style={{ color: rowTitleColor }} onClick={onClick}>
        <Highlighter searchWords={searchWords} autoEscape textToHighlight={title} />
      </button>
      <Icon style={{ color: arrowColor || rowTitleColor }} name={icon} />
    </div>
  );
};

QuestionRender.defaultProps = {
  onClick: undefined,
};

export default QuestionRender;
