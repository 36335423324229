/*eslint-disable react-hooks/exhaustive-deps*/
import cx from 'classnames';
import React, { FC, MutableRefObject, ReactNode, createRef, useEffect, useState } from 'react';
import { Divider } from 'semantic-ui-react';

import './BottomPanel.scss';
import { useDraggableBottomPanel } from './utils/useDraggableBottomPanel';

export interface BottomPanelProps {
  header?: FC<any>;
  isRounded?: boolean;
  children?: ReactNode;
  draggable?: boolean;
  onClose?: () => void;
  steps?: number[];
  initialStep?: number;
  open?: boolean;
  forwardedRef?: MutableRefObject<HTMLDivElement | null>;
  className?: string;
  positionCallback?: (position: number) => any;
  forcePosition?: number;
  disabled?: boolean;
}

export const BottomPanel: FC<BottomPanelProps> = (props) => {
  const {
    isRounded = false,
    children,
    header: HeaderComponent,
    draggable = false,
    onClose,
    open,
    steps = [50, 90],
    initialStep,
    forwardedRef,
    className,
    positionCallback,
    forcePosition,
    disabled,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const panelElementRef = forwardedRef || createRef();

  const { onMouseDown, setPanelPosition, containerProps } = useDraggableBottomPanel({
    onClose,
    options: { steps, initialStep },
    panelElementRef,
    positionCallback,
  });

  useEffect(() => {
    if (isOpen && !open) {
      setPanelPosition(0);
    }
    if (open !== undefined) setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (typeof forcePosition === 'number') setPanelPosition(forcePosition);
  }, [forcePosition]);

  return (
    <div
      ref={panelElementRef}
      className={cx(
        'bottom-panel',
        isRounded && 'bottom-panel__rounded',
        containerProps.className,
        className,
      )}
      style={containerProps.style}
    >
      {draggable && (
        <div
          className={cx('panel-draggable-element', disabled && 'panel-draggable-element--disabled')}
          onMouseDown={!disabled ? onMouseDown : undefined}
          onTouchStart={!disabled ? onMouseDown : undefined}
        />
      )}
      {!!HeaderComponent && HeaderComponent({}) && (
        <>
          <div className="panel-header-container">
            <HeaderComponent />
          </div>
          <Divider />
        </>
      )}
      <div className="bottom-panel__content">{children}</div>
    </div>
  );
};
