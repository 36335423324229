import cx from 'classnames';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import { CmsBlocks } from '../../../cms/CmsScreen';

type ModalPage = { blocks: ({ _id: string; type: string } & Record<string, any>)[] };

type CmsPageModalProps = {
  className: string;
  header: string;
  page: ModalPage;
  onCancel: () => void;
  modalProps: unknown;
};

const CmsPageModal = ({
  className,
  header,
  page,
  modalProps,
  onCancel,
}: CmsPageModalProps): JSX.Element => {
  const { blocks } = page;

  return (
    <Modal
      className={cx('page-modal', className)}
      open
      // basic
      onClose={onCancel}
      closeIcon="close"
      closeOnEscape
      closeOnDimmerClick
      {...modalProps}
    >
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>
        <CmsBlocks blocks={blocks} />
      </Modal.Content>
    </Modal>
  );
};

export default CmsPageModal;
