import merge from 'lodash/merge';
import QRCodeStyling from 'qr-code-styling';
import { useEffect, useRef } from 'react';

import Images from '../utils/Images';
import { Image } from './cms/types';

type Props = {
  width?: number;
  height?: number;
  data?: string;
  qrcodeConfig?: QRCodeConfig;
} & typeof defaultProps;

export type QRCodeConfig = {
  color?: string;
  backgroundColor?: string;
  image?: Image;
  shape?: string;
} & Record<string, any>;

const defaultProps = {
  width: 200,
  height: 200,
  data: 'url',
  qrcodeConfig: {
    color: '#000',
    backgroundColor: '#fff',
    shape: 'square',
  },
};

function generateColors(allOptions: Record<string, any>, defaultColor: string) {
  if (!allOptions) return { color: defaultColor };
  const { color = defaultColor, color2, colorType = 'color', gradient } = allOptions;
  if (colorType === 'color') {
    return {
      color,
    };
  }
  return {
    gradient: {
      type: gradient?.type,
      ...gradient,
      rotation: ((gradient?.rotation || 0) / 180) * Math.PI,
      colorStops: [
        { offset: 0, color },
        { offset: 1, color: color2 || color },
      ],
    },
  };
}

const QRCode = (props: Props): JSX.Element => {
  const { width, height, data, qrcodeConfig } = props;
  const ref: any = useRef(<div />);

  useEffect(() => {
    const {
      backgroundOptions = {},
      cornersSquareOptions = {},
      cornersDotOptions = {},
      image,
      dotsOptions = {},
      ...otherOptions
    } = qrcodeConfig;
    const color = dotsOptions.color || qrcodeConfig?.color || '#000';
    const backgroundColor = backgroundOptions.color || qrcodeConfig?.backgroundColor || '#fff';

    const config = merge(
      {
        dotsOptions: {
          colorType: dotsOptions.colorType,
          type: dotsOptions.type,
          ...generateColors(dotsOptions, color),
        },
        backgroundOptions: {
          colorType: backgroundOptions.colorType,
          type: backgroundOptions.type,
          ...generateColors(backgroundOptions, backgroundColor),
        },
        cornersSquareOptions: {
          type: cornersSquareOptions.type,
          color: cornersSquareOptions.color,
        },
        cornersDotOptions: {
          type: cornersDotOptions.type,
        },
        image: image?.uri && Images.cdn(image),
        imageOptions: {
          crossOrigin: 'anonymous',
        },
      },
      otherOptions,
    ) as any;
    const qrCode = new QRCodeStyling({
      data,
      width,
      height,
      ...config,
    });
    qrCode.append(ref.current);
    return () => {
      if (ref.current) {
        ref.current.removeChild(ref.current.firstChild);
      }
    };
  }, [qrcodeConfig, data, width, height]);

  return <div ref={ref} />;
};

QRCode.defaultProps = defaultProps;

export default QRCode;
