import React, { FC, useEffect, useState } from 'react';

import './KeyboardToolbar.scss';

export const KeyboardToolbar: FC = ({ children }) => {
  const [height, setHeight] = useState(window.visualViewport?.height || 0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    const scrollListener = () => {
      setWindowHeight(window.innerHeight);
    };
    const resizeListener = () => {
      setHeight(window.visualViewport?.height || 0);
    };

    window.addEventListener('scroll', scrollListener);

    window.visualViewport?.addEventListener('resize', resizeListener);
    window.addEventListener('orientationchange', resizeListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
      window.visualViewport?.removeEventListener('resize', resizeListener);
      window.removeEventListener('orientationchange', resizeListener);
    };
  }, []);

  return (
    <div
      className="keyboard-toolbar"
      style={{
        transform: `translateY(-${windowHeight - height}px)`,
      }}
    >
      {children}
    </div>
  );
};
