import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Modal } from 'semantic-ui-react';

import { useDesignConfig } from '../../config/design.context';
import { bem } from '../../core/design/bem';
import CdnImage from '../CdnImage';
import PWAPrompt from '../PWAPrompt';
import './InstallPWA.scss';
import useIosInstallPrompt from './utils/useIosInstallPrompt';
import { getInstallPrompt } from './utils/useShouldShowPrompt';
import useWebInstallPrompt from './utils/useWebInstallPrompt';

const css = bem('InstallPWA');

const InstallPWA: FC = () => {
  const { pwa } = useDesignConfig();
  const { enabled = false, name = '', icon } = pwa || {};

  const [disablePrompt, setDisablePrompt] = useState(false);
  const { t } = useTranslation();

  const { disablePrompt: alwaysDisablePrompt, lastPrompt } = getInstallPrompt();

  const [iosInstallPrompt] = useIosInstallPrompt();
  const [
    webInstallPrompt,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
  ] = useWebInstallPrompt();

  if (!enabled || alwaysDisablePrompt || window.mobileApp) {
    return null;
  }

  if (iosInstallPrompt) {
    return <PWAPrompt />;
  }
  if (!webInstallPrompt) {
    return null;
  }

  return (
    <Modal open className={css()} size="tiny" centered={false}>
      <Modal.Content className={css('content').toString()}>
        <span className={css('label').toString()}>{t('components.PWAPrompt.install-app')}</span>
        <span className={css('logo-container').toString()}>
          <CdnImage
            className={css('logo').toString()}
            maxWidth={45}
            src={icon}
            as="img"
            alt="App icon"
          />
          {name}
        </span>
        {lastPrompt && (
          <Checkbox
            className={css('checkbox').toString()}
            label={t('components.PWAPrompt.not-display-again')}
            checked={disablePrompt}
            onClick={(_e, { checked }) => {
              setDisablePrompt(checked as boolean);
            }}
          />
        )}

        <div className={css('action-buttons').toString()}>
          <a onClick={() => handleWebInstallDeclined(disablePrompt)}>{t('btn.cancel')}</a>
          <a onClick={handleWebInstallAccepted}>{t('btn.install')}</a>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default React.memo(InstallPWA);
