/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars, max-len, no-param-reassign */
import { SerializedStyles, css as applyCss, jsx } from '@emotion/react';
import cx from 'classnames';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { FC, useMemo } from 'react';
import { Card, Icon } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import CdnImage from '../../components/CdnImage';
import { AddToCalendarButton } from '../../components/workshops/workshop-templates/actions/AddToCalendarButton';
import { useMe } from '../../profile/hooks';
import { TemplateNode, jsxEval } from './json-jsx';

const env = {
  cx,
  moment,
  momentTz,
};

// TODO: correct type...
const components: Record<string, FC | any> = {
  CdnImage,
  Icon,
  Card,
  AddToCalendarButton,
};

function injectStyles(template: TemplateNode, css: SerializedStyles): TemplateNode {
  if (!css) return template;

  if (template.type === 'Component') {
    return {
      ...template,
      props: [
        ...template.props,
        { type: 'JSXAttribute', name: 'css', value: { type: 'Value', value: css } },
      ],
    };
  }
  return template;
}

export type TemplateProps = JsonTemplate;

export type JsonTemplate = {
  template: {
    type: string;
    version: string;
    template: string;
    json: TemplateNode;
  };
  data: Record<string, any>; // Data data can be used to customize the templates via the schema. Ex : images
  design: {
    type: 'emotion';
    colors?: Record<string, any>;
    cssStyle?: Record<string, any>;
    css?: string;
  };
  schema: unknown[];
};

const TemplateRenderer = ({
  props,
  template,
}: {
  props: any;
  template: JsonTemplate;
}): JSX.Element | null => {
  const { template: temp, data, design } = template;
  const me = useMe();
  const { trackEvent } = useTracking();

  // TODO: memoize for whole template ?
  const templateWithStyles = useMemo(() => {
    if (!temp) return null;
    const styles = applyCss(design?.css, design?.cssStyle);
    return injectStyles(temp?.json as TemplateNode, styles);
  }, [temp, design]);

  if (!templateWithStyles) return null;
  // const render = Handlebars.compile(template.content);

  // const html = render({ item: workshop, user: me, data, design, now });
  // if (!html) return null;

  return jsxEval(
    templateWithStyles,
    components,
    { active: props.index === 0, ...props, user: me, data, design, trackEvent },
    { createElement: jsx, env },
  );
};

export default TemplateRenderer;
