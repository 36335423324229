import React from 'react';
import PropTypes from 'prop-types';

const Count = ({ value }) => <span className="count">{value}</span>;

Count.defaultProps = {
  value: undefined,
};

Count.propTypes = {
  value: PropTypes.number,
};

export default Count;
