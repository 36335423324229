import React, { FC } from "react";
import { AppointmentTitle } from './AppointmentTitle';

type TitleProps = {
  event: any;
}

const Title: FC<TitleProps> = ({ event }) => {
  const { type } = event;
  switch (type) {
    case 'appointment':
      return <AppointmentTitle event={event} />;
    default:
      return <>{event.title}</>;
  }
};

export {
  Title
}
