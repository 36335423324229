import { Card, Icon, Label } from 'semantic-ui-react';

import { isExternalLink } from '../../../ExpiringNavLink';
import SocialNetworks from './SocialNetworks';

export const CardInfo = ({ icon, style, children, ...props }) => (
  <Card.Description {...props} style={{ textAlign: 'left', ...style }}>
    {icon && <Icon name={icon} />} {children}
  </Card.Description>
);

export const CardMail = ({ icon, style, children, ...props }) => {
  return (
    <Card.Description {...props} style={{ textAlign: 'left', ...style }}>
      {icon && <Icon name={icon} />}{' '}
      <a style={{ color: style?.color }} href={`mailto:${children}`}>
        {children}
      </a>
    </Card.Description>
  );
};

export const CardPhone = ({ icon, style, children, ...props }) => {
  return (
    <Card.Description {...props} style={{ textAlign: 'left', ...style }}>
      {icon && <Icon name={icon} />}{' '}
      <a style={{ color: style?.color }} href={`tel:${children}`}>
        {children}
      </a>
    </Card.Description>
  );
};

export const CardBoolTag = ({ icon, label, color, style, ...props }) => (
  <Card.Description {...props} style={{ textAlign: 'left', marginTop: 8, ...style }}>
    <Label color={color} icon={icon} content={label} />
  </Card.Description>
);

export const CardSocial = ({ icon, dataKey, children, type, style }) => {
  if (!children || !isExternalLink(children)) return null;
  const socialIcon = type || dataKey;
  return (
    <a target="_blank" rel="noreferrer" href={children} style={style}>
      {socialIcon ? <Icon name={icon} size="big" /> : children}
    </a>
  );
};

export const cardMetaComponents = {
  extra: Card.Meta,
  description: Card.Description,
  info: CardInfo,
  boolTag: CardBoolTag,
  phone: CardPhone,
  mail: CardMail,
  social: CardSocial,
};

export { SocialNetworks };
