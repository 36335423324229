import { useQuery } from 'react-query';

import notificationsService, { UserGroup } from '../../core/services/notifications.service';

const key = ['notifications', 'groups'];

export function useNotificationGroups(): UserGroup[] {
  const { data } = useQuery(
    key,
    async () => {
      const res = await notificationsService.getNotificationGroups();
      return res?.groups;
    },
    {},
  );
  return data ?? [];
}
