/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon, Menu, SemanticICONS } from 'semantic-ui-react';

import i18n from '../../../translations/i18n';
import { bem } from '../../design/bem';

const css = bem('FavoriteFilter');

type Filter = {
  title?: string;
  buttonProps?: Record<string, any>;
  icon?: SemanticICONS;
  iconFull?: SemanticICONS;
};
type Props = {
  onToggleFavorites: any;
  isFavoriteToggled: boolean;
  filter: Filter;
};

export const FavoriteFilter = (props: Props): JSX.Element => {
  const { onToggleFavorites, isFavoriteToggled, filter } = props;
  const {
    icon = 'star outline',
    iconFull = 'star',
    title = i18n.t('sponsors.sponsor.favorites.sidebar.my-favorites'),
    buttonProps,
  } = filter;

  return (
    <Menu.Item className={css('Item').toString()}>
      <Button
        basic
        fluid
        {...buttonProps}
        className={css().toString()}
        active={isFavoriteToggled}
        onClick={onToggleFavorites}
      >
        <Icon name={isFavoriteToggled ? iconFull : icon} />
        {title}
      </Button>
    </Menu.Item>
  );
};
