/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';

import { CmsPageLayout } from '../cms/CmsPage';
import { CmsBlocks } from '../cms/CmsScreen';
import Blocks from '../components/Blocks';
import GridContainer from '../components/GridContainer';
import HtmlBlock from '../components/cms/HtmlBlock';
import { useScreenConfig } from '../config/screens.context';
import { bem } from '../core/design/bem';
import './Profile.scss';
import ProfileFiles from './blocks/ProfileFiles';
import Rewards from './blocks/Rewards';
import UserProfile from './blocks/UserProfile/UserProfile';
import UserSchedule from './blocks/UserSchedule';
import UserProfileNetworkingBlock from './components/UserProfileNetworkingBlock';
import { useIsAnonymous, useMe } from './hooks';

const defaultBlocks = [{ type: 'userProfile' }, { type: 'agenda' } /* , {type: "rewards"} */];

const blockComponents = {
  'agenda': UserSchedule,
  'userProfile': UserProfile,
  'profile:files': ProfileFiles,
  'rewards': Rewards, // <Rewards columnByRow missionList rewards user={Store.user} />
  'html': HtmlBlock,
  'cms': CmsBlocks,
  'networking': UserProfileNetworkingBlock,
};

const Profile = ({ user, pageId }) => {
  const { blocks = defaultBlocks } = useScreenConfig(pageId);
  return (
    <Grid className="profile--block">
      <Blocks
        blocks={blocks}
        blockComponents={blockComponents}
        sharedProps={{
          user,
          pageId,
        }}
      />
    </Grid>
  );
};

Profile.defaultProps = {
  pageId: 'profile',
  user: {
    firstName: '',
    lastName: '',
    email: '',
    thumbnail: null,
    networking: false,
  },
};

Profile.propTypes = {
  pageId: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    networking: PropTypes.bool,
    rewards: PropTypes.object,
    thumbnail: PropTypes.object,
  }),
};

const defaultSidebarComponents = {
  userProfile: UserProfile,
};

const ConnectedProfile = connect((state) => ({
  ...state.user,
}))(Profile);

const css = bem('page');
export const ProfileScreen = ({
  header,
  metadata,
  design,
  pageHeader,
  pageFooter,
  pageId,
  sidebar,
  sidebarComponents,
  ...rest
}) => {
  const { redirectTo = '/' } = rest;
  const isAnonymous = useIsAnonymous();
  const user = useMe();

  if (isAnonymous) {
    return <Redirect to={redirectTo} />;
  }
  return (
    <CmsPageLayout
      className={css({
        type: 'profile',
        id: pageId,
      }).toString()}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <GridContainer
          sidebar={sidebar}
          sidebarComponents={sidebarComponents}
          sharedProps={{ user }}
        >
          <ConnectedProfile pageId={pageId} />
        </GridContainer>
      </Container>
    </CmsPageLayout>
  );
};

ProfileScreen.defaultProps = {
  design: {},
  header: {},
  metadata: undefined,
  pageId: 'profile',
  pageFooter: undefined,
  pageHeader: undefined,
  sidebar: undefined,
  sidebarComponents: defaultSidebarComponents,
};

ProfileScreen.propTypes = {
  _id: PropTypes.string.isRequired,
  design: PropTypes.object,
  header: PropTypes.object,
  metadata: PropTypes.object,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  pageId: PropTypes.string,
  sidebar: PropTypes.object,
  sidebarComponents: PropTypes.object,
};

export default ConnectedProfile;
