/* eslint-disable operator-linebreak */
import useUrlState from '@ahooksjs/use-url-state';
import get from 'lodash/get';
import React from 'react';
import { Input } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import i18n from '../../../translations/i18n';
import './SearchBlock.scss';

const css = bem('SearchBlock');

type SearchBlockProps = {
  content: string;
  placeholder?: string;
};

const SearchBlock = (props: SearchBlockProps): JSX.Element => {
  const { placeholder, content = 'faq' } = props;
  const [urlState, setUrlState] = useUrlState({ [content]: '' });
  const value = get(urlState, content);

  return (
    <div className={css()}>
      <div style={{ flex: 1 }} />
      <Input
        className={css('input').toString()}
        placeholder={placeholder}
        value={value || ''}
        autoFocus
        onChange={(_e, { value: inputValue }) => setUrlState({ [content]: inputValue })}
        icon={
          value
            ? {
                name: 'close',
                style: { cursor: 'pointer', pointerEvents: 'all' },
                onClick: () => setUrlState({ [content]: '' }),
              }
            : 'search'
        }
      />
    </div>
  );
};

SearchBlock.defaultProps = {
  placeholder: i18n.t('filters.search'),
};

export default SearchBlock;
