import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MeetingRequest, MeetingStatus } from '../../components/Chat/Appointments/types';
import platformService from '../../core/services/platform.service';
import { sweetAlert, sweetConfirmAlert } from '../../utils/popupUtils';
import { ChatChannel } from '../context/chat.context';
import { sendMeetingRequest, updateMeetingRequest } from '../store/networking.actions';

export function useMeetingHandlers(
  currentChat: ChatChannel | null,
  messages: any[],
  { enabled }: { enabled?: boolean },
): {
  handleSendMeetingRequest?: (meeting: MeetingRequest) => Promise<void>;
  handleUpdateMeeting?: (messageId: string, action: string, meeting: any) => Promise<void>;
} {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId: string = useSelector((state: any) => state.user.user?._id);

  if (!currentChat || !enabled) {
    return {};
  }

  const { inviter, invited, id } = currentChat;
  const otherUserId = inviter === userId ? invited : inviter;

  async function handleSendMeetingRequest(meeting: MeetingRequest): Promise<void> {
    //check if chat already has appointments
    const appointmentMessages = messages.filter((m) => m.type === 'appointment');
    const alreadyHasAppointmentRequest = appointmentMessages.some((a) => {
      const { appointment, emitter } = a;
      const { users } = appointment;
      const emitterStatus = users.find((u: any) => u.userId === emitter).status;
      const invitedStatus = users.find((u: any) => u.userId !== emitter).status;
      return (
        (emitterStatus === 'confirmed' && invitedStatus === 'pending') ||
        (emitterStatus === 'confirmed' && invitedStatus === 'confirmed')
      );
    });
    if (alreadyHasAppointmentRequest) {
      await sweetAlert({
        text: t(`networking.appointment.meeting-invite-already-launched`),
        icon: 'error',
      });
      return;
    }

    const { date, startTime, endTime, location, message } = meeting;
    const users = [
      { userId, status: 'confirmed' },
      { userId: otherUserId, status: 'pending' },
    ];

    const newAppointment = omitBy(
      {
        startDate: moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm').toISOString(),
        endDate: moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm').toISOString(),
        users,
        location,
        message,
        // TODO: chatMessageId
      },
      isUndefined,
    );

    const res = await platformService.createAppointment('networking', newAppointment);
    if (res.success) {
      const appointment = pick(res?.appointment, [
        'startDate',
        'endDate',
        'users',
        'ownerId',
        'location',
        'message',
        '_id',
      ]);
      dispatch(sendMeetingRequest(currentChat, appointment));
    }
  }

  async function handleUpdateMeeting(messageId: string, action: string, meeting: any) {
    if (action === MeetingStatus.REFUSED || action === MeetingStatus.CANCELED) {
      const confirm = await sweetConfirmAlert({
        title: t('networking.appointment.confirm-message'),
      });
      if (!confirm) return;
    }

    const otherMeetingAnswer = meeting.users.find((u: any) => u.userId === otherUserId);
    const users = [otherMeetingAnswer, { userId, status: action }];

    const res = await platformService.updateAppointmentStatus('networking', meeting._id, {
      action,
      chatId: id,
      messageId,
      usersStatus: users,
    });

    if (res.success) {
      dispatch(updateMeetingRequest(currentChat));
    }
  }

  return { handleSendMeetingRequest, handleUpdateMeeting };
}
