/* eslint-disable react/no-array-index-key */
import get from 'lodash/get';
import { FC, useState } from 'react';
import { Button, Icon, SemanticICONS } from 'semantic-ui-react';

import PDFModal from '../../../components/cms/FilesBlock/components/PDFModal';
import { isPDF } from '../../../components/cms/utils';
import { UserType } from '../../../core/users/users.types';
import { ensureArray } from '../../../utils/arrayUtils';
import './UserFiles.scss';

export type UserFileConfig = {
  field: string;
  label: string;
  icon?: SemanticICONS;
};

type UserFilesProps = {
  user: UserType;
  fileConfig?: UserFileConfig;
  buttonProps?: Record<string, any>;
};

type FileType = {
  uri: string;
};

const UserFiles: FC<UserFilesProps> = ({ user, fileConfig, buttonProps }) => {
  const [pdfToShow, setShowPDF] = useState<FileType | null>(null);

  if (!fileConfig) return null;

  const { field, icon, label } = fileConfig;
  const fileArray = ensureArray(get(user, field)).filter((f) => f.uri);

  if (!fileArray?.length) return null;

  const handleFileClick = (fileValue: FileType) => {
    if (isPDF(fileValue.uri)) {
      setShowPDF(fileValue);
    } else {
      window.open(fileValue.uri, '_blank');
    }
  };

  const hasMultiple = fileArray.length > 1;
  return (
    <>
      <div className="button-group button-group--files">
        {fileArray?.map((fileValue, index) => (
          <Button
            key={index}
            primary
            basic
            compact
            {...buttonProps}
            onClick={() => handleFileClick(fileValue)}
          >
            {icon && <Icon name={icon} />}
            {label} {hasMultiple ? ` (${index + 1})` : undefined}
          </Button>
        ))}
      </div>
      {pdfToShow && <PDFModal file={pdfToShow} onClose={() => setShowPDF(null)} />}
    </>
  );
};

export default UserFiles;
