import cx from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Button, Menu } from 'semantic-ui-react';

import { ensureArray } from '../../../utils/arrayUtils';
import { computeOptions } from './MultiSelectFilter';

const TagsFilter = ({ filter, value, onChange }) => {
  const { title, countForAll, horizontal, values, size } = filter;
  const options = useMemo(() => computeOptions(values), [values]);
  const handleChange = (v, searchValue) => {
    const toggleOffValue = searchValue.includes(v);
    if (toggleOffValue) {
      const filtered = searchValue.filter((valueToKeep) => valueToKeep !== v);
      onChange(filter, [...filtered]);
      return;
    }
    onChange(filter, [...searchValue, ensureArray(v)] || undefined);
  };
  if (!options || options.length === 0) return null; // Nothing to show
  if (options.length === 1 && options[0].count === countForAll) return null; // Useless filter
  return (
    <Menu.Item className={cx('menu-filter--tag', filter.dataKey || '')}>
      {horizontal ? <Menu.Item header>{title}</Menu.Item> : <Menu.Header>{title}</Menu.Header>}
      {options.map((option) => {
        const { value: v, text } = option;
        const searchValue = ensureArray(value);
        const isActive = searchValue.includes(v);
        return (
          <Button
            key={v}
            size={size}
            active={isActive}
            className={`tags__item tags__item--${v}`}
            onClick={() => handleChange(v, searchValue)}
          >
            {text}
          </Button>
        );
      })}
    </Menu.Item>
  );
};

TagsFilter.defaultProps = {
  value: undefined,
};

TagsFilter.propTypes = {
  filter: PropTypes.shape({
    countForAll: PropTypes.number,
    dataKey: PropTypes.string.isRequired,
    horizontal: PropTypes.bool,
    name: PropTypes.string,
    orderBy: PropTypes.string,
    size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
    title: PropTypes.string.isRequired,
    values: PropTypes.array,
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
};

export default TagsFilter;
