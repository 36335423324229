/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable react/no-unused-prop-types */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer, Masonry, MasonryProps, createMasonryCellPositioner } from 'react-virtualized';

import useEvent from '../../core/hooks/useEvent.hook';
import { Publication } from '../types/SocialWallV2.types';
import { MediaPageMeasurer } from '../utils/MediaPageMeasurer';
import { MediaCard } from './MediaCard';

const masonryOptions = { columnCount: 2, columnWidth: 120, spacer: 12 };

type MasonryItemProps = {
  index: number;
  key: string;
  parent: any;
  style: Record<string, any>;
};

interface SocialWallMediaPageProps {
  className?: string;
  publications: Publication[];
  userInteractions: Record<string, string[]>;
  openPublication: (publicationId: string) => void;
}

function computeMasonryOptions(width: number) {
  let columnCount = 2;
  if (width >= 1200) columnCount = 4;
  else if (width >= 872) columnCount = 3;

  return {
    columnCount,
    columnWidth: Math.floor((width - (columnCount - 1) * masonryOptions.spacer) / columnCount),
  };
}

type ResizableMasonryProps = Omit<MasonryProps, 'cellRenderer' | 'cellMeasurerCache'> & {
  medias: Publication[];
  cellMeasurerCache: MediaPageMeasurer;
  userInteractions: Record<string, string[]>;
  openPublication: (publicationId: string) => void;
};

const ResizableMasonry: FC<ResizableMasonryProps> = ({
  width,
  height,
  cellPositioner,
  medias,
  cellMeasurerCache,
  userInteractions,
  openPublication,
  ...props
}) => {
  const masonryRef = useRef<Masonry>();

  // Remove outer padding from size measurements
  // Using -8 because of scrollbar, what should we do ?
  const { columnCount, columnWidth } = computeMasonryOptions(width - 2 * 12 - 8);

  cellMeasurerCache.setColumnWidth(columnWidth);

  useEffect(() => {
    if (masonryRef.current) {
      masonryRef.current.forceUpdate();
    }
  }, [medias, userInteractions]);

  // Flush cache if needed
  useEffect(() => {
    cellPositioner.reset({ columnCount, columnWidth, spacer: masonryOptions.spacer });
    masonryRef.current?.recomputeCellPositions();
  }, [cellPositioner, columnCount, columnWidth]);

  const itemRenderer = useEvent(({ index, parent, style }: MasonryItemProps) => {
    const media = medias[index];
    const { data, _id, interactions } = media;
    const { width: imgWidth, height: imgHeight } = data.media!;
    const isPortrait = !!imgWidth && !!imgHeight && imgWidth >= imgHeight;
    const cardWidth = cellMeasurerCache.getWidth();
    const cardHeight = cellMeasurerCache.getHeight(index);

    // Used to notify masonry to re-render... or else doesn't render at the correct position
    if (!cellMeasurerCache.has(index, 0)) {
      parent.invalidateCellSizeAfterRender({ rowIndex: index });
    }
    return (
      <div
        className="media-card__wrapper"
        key={_id}
        style={{ ...style, width: cardWidth, height: cardHeight }}
      >
        <MediaCard
          className={isPortrait ? 'portrait-mode' : ''}
          key={_id}
          url={data?.thumbnail ? data?.thumbnail?.uri : data?.media?.uri}
          mimeType={data?.media?.mimeType}
          userInteractions={userInteractions[_id]}
          interactions={interactions}
          onClick={() => openPublication(media._id)}
        />
      </div>
    );
  });

  return (
    <Masonry
      {...props}
      ref={(ref) => {
        masonryRef.current = ref!;
      }}
      cellCount={medias.length}
      cellRenderer={itemRenderer}
      cellMeasurerCache={cellMeasurerCache}
      cellPositioner={cellPositioner}
      autoHeight={false}
      height={height}
      width={width}
    />
  );
};

export const SocialWallMediaPage: FC<SocialWallMediaPageProps> = ({
  publications,
  userInteractions,
  openPublication,
}) => {
  const handleOpen = useEvent(openPublication);

  const medias = useMemo(() => publications.filter((p) => !!p.data.media), [publications]);
  const keyMapper = useEvent((rowIndex: number) => medias[rowIndex]?._id || rowIndex);

  const [cache] = useState(
    () =>
      new MediaPageMeasurer({
        medias,
        columnWidth: 120,
      }),
  );

  // Our masonry layout will use 3 columns with a 10px gutter between
  const [cellPositioner] = useState(() =>
    createMasonryCellPositioner({ ...masonryOptions, cellMeasurerCache: cache }),
  );

  return (
    <div className="social-wall-media-page">
      <AutoSizer>
        {({ height, width }) =>
          // Only render if we have a size...
          !!width &&
          !!height && (
            <ResizableMasonry
              className="virtualized-list"
              medias={medias}
              cellCount={medias.length}
              cellMeasurerCache={cache}
              cellPositioner={cellPositioner}
              userInteractions={userInteractions}
              openPublication={handleOpen}
              autoHeight={false}
              keyMapper={keyMapper}
              height={height}
              width={width}
            />
          )
        }
      </AutoSizer>
    </div>
  );
};
