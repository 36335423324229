export function hasExtension(filename, ext) {
  return filename.slice(filename.length - ext.length).toLowerCase() === ext;
}

export function isImage(filename) {
  if (!filename) return false;
  return (
    hasExtension(filename, '.bmp') ||
    hasExtension(filename, '.jpg') ||
    hasExtension(filename, '.jpeg') ||
    hasExtension(filename, '.png') ||
    hasExtension(filename, '.gif')
  );
}

export function isVideo(filename) {
  if (!filename) return false;
  return (
    filename.indexOf('vimeo') !== -1 ||
    hasExtension(filename, '.mp4') ||
    hasExtension(filename, '.m4v') ||
    hasExtension(filename, '.mkv') ||
    hasExtension(filename, '.avi') ||
    hasExtension(filename, '.mov') ||
    hasExtension(filename, '.mpeg')
  );
}

export function isPDF(filename) {
  if (!filename) return false;
  return hasExtension(filename, '.pdf');
}

export function iconForFilename(filename) {
  if (!filename) return 'file';

  if (hasExtension(filename, '.pdf')) return 'file pdf outline';
  if (hasExtension(filename, '.doc') || hasExtension(filename, '.docx')) return 'file word outline';
  if (hasExtension(filename, '.xls') || hasExtension(filename, '.xlsx'))
    return 'file excel outline';
  if (hasExtension(filename, '.ppt') || hasExtension(filename, '.pptx'))
    return 'file powerpoint outline';
  if (isImage(filename)) return 'file image outline';
  if (isVideo(filename)) return 'file video outline';

  return 'file outline';
}

export function mapIds(idList, obj) {
  if (!obj || !idList) return [];
  return idList.map((key) => obj[key]).filter((v) => v);
}
