import { ActionItem, InfoProps } from './types';

export const defaultActions: ActionItem[] = [
  {
    _id: 'phoneNumber',
    field: 'phoneNumber',
    uri: 'tel:{phoneNumber}',
    label: 'Phone number',
    icon: 'phone',
  },
  {
    _id: 'email',
    field: 'email',
    uri: 'mailto:{email}',
    label: 'Email',
    icon: 'mail',
  },
];

export const defaultInfos: InfoProps[] = [
  {
    name: 'title',
    as: 'h2',
    blocks: [{ field: 'firstName' }, { field: 'lastName' }],
  },
  {
    name: 'company',
    blocks: [{ field: 'company' }],
  },
];
