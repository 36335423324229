import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import './avatars.scss';
import { DetailsVariant, SimpleVariant, TextVariant } from './variants';
import { bem } from '../../core/design/bem';
import SquareVariant from './variants/SquareVariant';

const css = bem('AvatarsBlock');

const componentVariants = {
  simple: SimpleVariant,
  square: SquareVariant,
  details: DetailsVariant,
  text: TextVariant,
};

const Avatars = ({
  users,
  size,
  imageSize,
  imageKey,
  maxCount,
  variant,
  showModal,
  template,
  ...rest
}) => {
  const finalUsers = maxCount ? users.slice(0, maxCount) : users;
  const AvatarComponent = get(componentVariants, variant, SimpleVariant);

  return (
    <div className={css({ variant }).mix('avatars')}>
      <AvatarComponent
        users={finalUsers}
        size={size}
        imageSize={imageSize}
        imageKey={imageKey}
        showModal={showModal}
        template={template}
        {...rest}
      />
    </div>
  );
};

Avatars.defaultProps = {
  imageKey: 'thumbnail',
  maxCount: undefined,
  imageSize: 28,
  size: undefined,
  showModal: false,
  template: undefined,
  variant: 'simple',
};

Avatars.propTypes = {
  imageKey: PropTypes.string,
  imageSize: PropTypes.number,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
  maxCount: PropTypes.number,
  showModal: PropTypes.bool,
  template: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.oneOf(['simple', 'square', 'details', 'text']),
};

export default Avatars;
