import React from 'react';
import { Button } from 'semantic-ui-react';

import CdnImage from '../../components/CdnImage';
import { Image } from '../../components/cms/types';
import useTranslations from '../../hooks/useTranslations';
import './SSOButtons.scss';

const {
  AppleLoginButton,
  FacebookLoginButton,
  GithubLoginButton,
  GoogleLoginButton,
  LinkedInLoginButton,
  TwitterLoginButton,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
} = require('react-social-login-buttons');

type SSOItem = {
  type: string;
  label: string;
  image: Image;
  url: string;
};

type DefaultSSOType = Omit<SSOItem, 'type'>;

const DefaultSSOButton = ({ image, label, url }: DefaultSSOType) => {
  const { t } = useTranslations();
  return (
    //Deprecate `sso--button`, left for backwards compatibility
    <Button basic className="sso-button sso--button" fluid href={url} key={label}>
      {image && (
        <CdnImage
          className="sso--logo"
          as="img"
          src={image}
          maxHeight={45}
          maxWidth={130}
          alt="event logo"
        />
      )}
      <span>{t('login.sso.connect-with', { label })}</span>
    </Button>
  );
};

const ssoList: Record<string, typeof AppleLoginButton> = {
  apple: AppleLoginButton,
  facebook: FacebookLoginButton,
  github: GithubLoginButton,
  linkedin: LinkedInLoginButton,
  google: GoogleLoginButton,
  twitter: TwitterLoginButton,
};

export const SSOButton = ({ image, label, url, type }: SSOItem): JSX.Element => {
  const { t } = useTranslations();
  if (ssoList[type]) {
    const Component = ssoList[type];
    return (
      <Component
        className={`sso-button sso-button--social sso-button--${type}`}
        onClick={() => window.location.replace(url)} // can't use href on react-social-login
      >
        <span className="content--text">{t(`sso.social.${type}`)}</span>
      </Component>
    );
  }
  return <DefaultSSOButton image={image} label={label} url={url} />;
};
