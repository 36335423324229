/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

import CdnImage from '../../../../components/CdnImage';
import ImageUploader from '../../../../components/ImageUploader';
import { bem } from '../../../../core/design/bem';
import { replaceValues } from '../../../../utils/stringUtils';
import './UserProfileAvatar.scss';

const css = bem('UserInfo');

type User = {
  _id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
} & Record<string, any>;

type UserProfileAvatarProps = {
  user: User;
  onUpdateUser: (patch: Record<string, any>) => void;
  enableChange?: boolean;
  inverted?: boolean;
  showPlaceholder?: boolean;
  placeholderTemplate?: string;
  size?: number;
};

function defaultPlaceholder(user: User) {
  return `${user.firstName?.[0] || ''}${user.lastName?.[0] || ''}`.trim();
}

function computePlaceholder(user: User, placeholderTemplate?: string) {
  if (placeholderTemplate) {
    const placeholder = replaceValues(placeholderTemplate, user);
    if (placeholder) return placeholder;
  }
  return defaultPlaceholder(user);
}

const UserProfileAvatar = (props: UserProfileAvatarProps): JSX.Element | null => {
  const { t } = useTranslation();
  const {
    user,
    onUpdateUser,
    enableChange,
    showPlaceholder,
    placeholderTemplate,
    size,
    inverted,
  } = props;
  const { thumbnail } = user;

  if (enableChange) {
    return (
      <div>
        {!thumbnail && (
          <Header as="h5" style={{ marginBottom: 4 }}>
            {t('profile.avatar')}
          </Header>
        )}
        <ImageUploader
          field="thumbnail"
          onChange={(json: any) => onUpdateUser({ thumbnail: json })}
          placeholder={t('profile.image-uploader.placeholder')}
          size="small"
          style={{ height: size, width: size, marginRight: 8 }}
          value={thumbnail}
        />
      </div>
    );
  }

  if (thumbnail) {
    return (
      <div className={css('thumbnail')}>
        <CdnImage src={thumbnail} maxWidth={size} />
      </div>
    );
  }
  if (showPlaceholder) {
    return (
      <div className={css('thumbnail')}>
        <div
          className={css('avatar', { placeholder: true, inverted })}
          style={{ width: size, height: size, lineHeight: `${size}px` }}
        >
          {computePlaceholder(user, placeholderTemplate)}
        </div>
      </div>
    );
  }
  return null;
};

UserProfileAvatar.defaultProps = {
  enableChange: false,
  size: 60,
  showPlaceholder: false,
  placeholderTemplate: undefined,
  inverted: false,
};

export default UserProfileAvatar;
