import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Progress } from 'semantic-ui-react';

import './QuotaField.scss';

const translationPrefix = 'workshops.card';

const QuotaField = ({ quota, usersCount }) => {
  const { t } = useTranslation();
  if (!quota) return null;

  const fillingRate = Math.min(Math.round(Math.min(usersCount / quota, 1) * 100), 100);
  const available = Math.max(0, quota - usersCount);
  const availableRate = Math.round(100 - fillingRate);

  // prettier-ignore
  const label =
    availableRate <= 0 ?
      t(`${translationPrefix}.workshop-full`) :
      t(`${translationPrefix}.available`);

  const values = { available, usersCount, quota, fillingRate, availableRate };
  return (
    <Card.Meta className="card__progress">
      <div className={cx('details', { 'workshop-full': availableRate === 0 })}>
        <div className="label">{label}</div>
        {availableRate !== 0 && (
          <div className="value">{t(`${translationPrefix}.available-value`, values)}</div>
        )}
      </div>
      <Progress percent={availableRate} indicating size="tiny" />
    </Card.Meta>
  );
};

QuotaField.defaultProps = {
  quota: undefined,
};

QuotaField.propTypes = {
  quota: PropTypes.number,
  usersCount: PropTypes.number.isRequired,
};

export default QuotaField;
