/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ClickableSVG from '../../../components/ClickableSVG';
import { ClickableItem } from '../../../components/ClickableSVG/ClickableSVG';
import { Image } from '../../../components/cms/types';
import './ClickableMap.scss';

export type ClickableMapProps = {
  backgroundImage: Image;
  overlayImage: Image;
  onClick: (item: ClickableItem) => void;
  items: ClickableItem[];
};

const ClickableMap = (props: ClickableMapProps): JSX.Element => {
  const { backgroundImage, overlayImage, items, onClick } = props;

  return (
    <div className="ClickableMap">
      {backgroundImage?.uri && <img alt="background" src={backgroundImage.uri} />}
      <ClickableSVG src={overlayImage} items={items} onClick={onClick} />
    </div>
  );
};

export default ClickableMap;
