// eslint-disable-next-line import/no-cycle
import platformService from '../services/platform.service';
import { SET_COLLECTION } from './data.types';

export const setData = (collection, data) => ({
  type: SET_COLLECTION,
  collection,
  data,
});

export function fetchDataByCollection(collection) {
  return async (dispatch) => {
    const data = await platformService.fetchCustomData(collection);
    if (Array.isArray(data)) {
      dispatch(setData(collection, data));
    }
  };
}
