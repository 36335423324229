import { matches, pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import { extractUsers } from '../../core/users/users.select';

function buildMatch(where) {
  return matches(pickBy(where, (v, _k) => v !== undefined));
}

function extractCount(state, entity) {
  if (!state || !entity) return undefined;

  const { kind, collection, category } = entity;

  switch (kind) {
    case 'users': {
      const { users } = extractUsers(state, collection);
      return users.filter(buildMatch({ category })).length;
    }
    case 'data': {
      if (collection === 'opportunities') {
        return state.matching?.matching.opportunities?.length;
      }
      return undefined;
    }
    case 'workshops': {
      const { workshops } = state.workshops || [];
      return workshops.filter(buildMatch({ collection })).length;
    }
    default:
      return undefined;
  }
}

export function useSidebarValues(sidebar, blocksOverride) {
  const { blocks = [] } = sidebar;
  const state = useSelector((s) => s);
  return {
    ...sidebar,
    blocks: blocks.map((baseBlock) => {
      let block = baseBlock;
      if (blocksOverride?.['*'] || blocksOverride?.[block.type]) {
        block = { ...block, ...blocksOverride['*'], ...blocksOverride[block.type] };
      }
      if (block.type !== 'vertical-menu') return block;
      const { items = [] } = block;
      const newItems = items.map((i) => ({
        ...i,
        value: i.value || extractCount(state, i.entity),
      }));
      return { ...block, items: newItems };
    }),
  };
}
