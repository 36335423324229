import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TabDate = ({ value }: { value: string }): JSX.Element => {
  const { t } = useTranslation();
  const date = moment(value, 'YYYY-MM-DD');
  const line1 = t('program.tabs.line1', { date });
  const line2 = t('program.tabs.line2', { date });
  return (
    <div className="tab--date">
      {!!line1 && <div className="tab--date--1">{line1}</div>}
      {!!line2 && <div className="tab--date--2">{line2}</div>}
    </div>
  );
};

export default TabDate;
